import React, { useRef } from "react";
import SwitchInput from "common/SwitchInput";
import { getFormData, rankAndParseFields } from "../utils";
import VLANs from "./VLANs";
import IPRanges from "./IPRanges";

const NetworksSettings = ({ onInputChange, defaults }) => (
  <>
    <h2 className="card-inside-title">Bypass IPv4/IPv6</h2>
    <p>If enabled, the bypassed traffic will not get any optimization</p>
    <SwitchInput
      defaultChecked={defaults.ipv4}
      className={"sw-input"}
      name="ipv4"
      value={true}
      label={(checked) => `Bypass IPv4: ${checked ? "enabled" : "disabled"}`}
      onChange={onInputChange}
    />
    <SwitchInput
      defaultChecked={defaults.ipv6}
      className={"sw-input"}
      name="ipv6"
      value={true}
      label={(checked) => `Bypass IPv6: ${checked ? "enabled" : "disabled"}`}
      onChange={onInputChange}
    />
  </>
);

const BypassSettings = ({ onChange, defaults = {} }) => {
  const form = useRef(null);
  const onInputChange = () => {
    const values = getFormData(form.current);
    onChange(rankAndParseFields(values, defaults));
  };
  const onVLANSChange = ({ list, untagged }) =>
    onChange({ vlans: list, untaggedVLANs: untagged });
  const onIPRangesChange = ({ list }) => onChange({ ipRanges: list });
  return (
    <>
      <form ref={form}>
        <NetworksSettings defaults={defaults} onInputChange={onInputChange} />
      </form>
      <VLANs
        defaults={{
          list: defaults.vlans,
          untagged: defaults.untaggedVLANs,
        }}
        onChange={onVLANSChange}
      />
      <IPRanges
        defaults={{
          list: defaults.ipRanges,
        }}
        onChange={onIPRangesChange}
      />
    </>
  );
};

export default BypassSettings;
