export function parseLinkStateResponse(response){
  const attrs = [];
  const lines = response.split('\n');
  for(const line of lines){
    const words = line.trim().split(/:\s+|\s{2,}/);

    for(let i = 0; i<words.length; i+=2){
      if(words[i]){
        attrs.push({label: words[i], value: words[i+1]})
      }
    }
  }

  const firstElement = attrs[0];
  attrs.splice(0, 1, {label: 'Flags', value: firstElement.value})
  attrs.splice(0, 0, {label: 'Name', value: firstElement.label})

  return attrs;
}
  
export function parseLinkStateDeviceResponse(response){
    const attrs = [];
    const lines = response.split('\n');
    for(const line of lines){
      const words = line.trim().split(/:(.*)/);
  
      for(let i = 0; i<words.length; i+=2){
        if(words[i]){
          attrs.push({label: words[i], value: words[i+1]})
        }
      }
    }
    return attrs;
  }

const upOrDown = value =>
  value === 'up' ? true :
  value === 'down' ? false :
  undefined

const yesOrNo = value =>
  value === 'yes' ? true :
  value === 'no' ? false :
  undefined


const parseInterfaceLine = line => {
  const [name, type, mac, enabled, connected, management] = line.split(/\s+/);
  return {
    name, type, mac,
    enabled: upOrDown(enabled),
    connected: yesOrNo(connected),
    management: yesOrNo(management),
  }
}

export const parseInterfaces = response =>
  response.trim('\n').split('\n').map(parseInterfaceLine);
