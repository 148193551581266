import React from "react";
import styled from "styled-components";
import SelectInput from "common/SelectInput";
import PasswordInput from "common/PasswordInput";
import { VoidControl } from "common/layouts/ArrangeInColumns";

const Wrapper = styled.div`
  > div {
    height: auto;
    margin-bottom: 20px;
  }
`;

const wisproBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer ID", value: "customer-id" },
  { label: "Name", value: "name" },
  { label: "Login", value: "login" },
  { label: "MAC", value: "mac" },
];

const dictionary = {
  'user-name': 'name',
  'calling-station-id': 'mac',
}

function translate(input){
  return dictionary[input] ?? input;
}

const WisproBillingSettings = ({ onChange, defaults = {} }) => (
  <>
    <PasswordInput
      defaultValue={defaults.apikey}
      className="input-group"
      name="apikey"
      label="API key"
      onChange={onChange}
    />
    <VoidControl />
    <Wrapper>
      <SelectInput
        label="Subscriber-ID source"
        name="subscriberIdSource"
        className="input-group"
        onChange={onChange}
        defaultValue={defaults.subscriberIdSource ==="n/a" || defaults.subscriberIdSource === undefined ? "unchanged" : translate(defaults.subscriberIdSource)}
        options={wisproBillingSubscribers}
      />
    </Wrapper>
  </>
);

export default WisproBillingSettings;
