import React, { useRef, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useDimensions } from "common/hooks";
import RegressionLine from "./RegressionLine";
import { getInRangeItems } from "views/Status/Subscribers/Report/TimeEvolutionMetrics/utils";

const TooltipWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 6px;
  transform: translate(-50%, -50%);
  font-family: "AllianceNo2-Regular" !important;
`;

const TooltipLabel = styled.div`
  margin: 0 0px;
  width: 100%;
  min-width: 120px;
  @media only screen and (max-width: 768px) {
    min-width: 90px;
    padding-right: 5px;
  }
`;

const TooltipValues = styled.div`
  display: flex;
  flex-direction: column;
}
`;

const TooltipValueWrapper = styled.div`
  min-width: 140px;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: block;
     min-width: 80px;
  }
`;

const TooltipTimeValueWrapper = styled.div`
  width: 100%;
  min-width: 140px;
  margin-bottom: 5px;
`;

const TooltipValue = styled.div`
  min-width: 100px;
  font-family: "AllianceNo2-SemiBold" !important;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    
    .scatterplotBackground {
        fill:none;
        stroke: var(--chart-inner-border-color, #000);
        stroke-opacity: 0.75;
      }
    .scatterplotAxis {
      fill: var(--chart-axis-text-color);
      color: #929295;
      font-family: var(--chart-font-family, Arial, sans-serif);
      font-size: 9px !important;

        &.axisSelect {
          cursor: pointer;
        }
      }
      .scatterplotGrid {
        stroke: var(--chart-grid-color);
        stroke-opacity: 0.75;

        &.yAxis {
          stroke-opacity: 1;
        }
      }
  }
`;

const formatDate = d3.timeFormat("%m/%d %H:%M");

const yAxisEntry = ({ x, heightChart, label, showTick }) => {
  return (
    <>
      {showTick ? (
        <line
          x1={x}
          x2={x}
          y1={heightChart - 2}
          y2={heightChart + 4}
          stroke="var(--chart-axis-text-color)"
        />
      ) : null}
      <text
        x={x}
        y={heightChart + 20}
        fontSize={9}
        textAnchor="middle"
        className="scatterplotAxis"
      >
        {label}
      </text>
    </>
  );
};

const XAxisEntry = ({ x, heightChart, label, showTick }) => {
  return (
    <>
      {showTick ? (
        <line
          x1={x}
          x2={x}
          y1={0}
          y2={heightChart}
          className="scatterplotGrid"
        />
      ) : null}
      <text
        x={x}
        y={heightChart + 10}
        fontSize={9}
        textAnchor="middle"
        className="scatterplotAxis"
      >
        {label}
      </text>
    </>
  );
};

const Y_PADDING_CHART = 40;
const X_PADDING_CHART = 10;

const getPosTooltip = (xPos, width) => {
  if (xPos > width - 140) {
    return xPos - 140;
  }
  if (xPos >= 140) {
    return xPos - 140;
  }

  return xPos;
};

const Tooltip = ({
  interactionData,
  width,
  height,
  unitX,
  unitY,
  tooltipLabelX,
  tooltipLabelY,
}) => {
  if (!interactionData) {
    return null;
  }

  const { xPos, yPos, xValue, yValue, time } = interactionData;

  return (
    <div
      style={{
        height,
        width: width,
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      <TooltipWrapper
        style={{
          position: "absolute",
          width: "auto",
          left: getPosTooltip(xPos, width),
          top: yPos - 40,
        }}
      >
        <TooltipValues>
          <TooltipTimeValueWrapper key="time-value">
            <TooltipValue>
              {formatDate(time)}
            </TooltipValue>
          </TooltipTimeValueWrapper>
          {["Y", "X"].map((entry) => {
            return (
              <TooltipValueWrapper key={`${entry}-value`}>
                <TooltipLabel>{eval(`tooltipLabel${entry}`)}</TooltipLabel>
                <TooltipValue>
                  {eval(`${entry.toLowerCase()}Value`)} {eval(`unit${entry}`)}
                </TooltipValue>
              </TooltipValueWrapper>
            );
          })}
        </TooltipValues>
      </TooltipWrapper>
    </div>
  );
};

function getTimeValuesMap (items) {
  if (items && items.length === 0) {
    return {};
  }
  return items.reduce((acc, value) => {
    const { x, y, time } = value;
    const timeKey = formatDate(time);
    acc[timeKey] = { xValue:x, yValue:y, time };
    return acc;
  }, {});
};

function getHoverData(interactionData, timeValuesMap, xScale, yScale) {
  if (interactionData === null) {
    return null;
  }
  const timeHovered = formatDate(interactionData.time);
  const valuesHovered = timeValuesMap[timeHovered];
  if(valuesHovered === undefined) {
    return null;
  }
  const hoverComplete = {
    ...valuesHovered,
    xPos: xScale(valuesHovered.xValue),
    yPos: yScale(valuesHovered.yValue),
  };
  return hoverComplete;
}

const HoveredItem = ({ item, onInteractionData }) => {
  if (item === null) {
    return null;
  }

  const { time, xPos, yPos } = item;

  return (
    <g className="scatterplotHovered">
      <circle
        key="hovered"
        r={5}
        cx={xPos}
        cy={yPos}
        opacity={1}
        fill={"#fc3136"}
        fillOpacity={1}
        strokeWidth={1}
        onMouseEnter={() => {
          onInteractionData({
            time: time,
          });
        }}
        onMouseLeave={() => onInteractionData(null)}
      />
    </g>
  );
};


const ScatterPlot = ({
  margin = { top: 0, right: 40, bottom: 50, left: 60 },
  items,
  contentY,
  contentX,
  interactionData,
  onInteractionData,
}) => {
  const chartRef = useRef(null);
  const { width, height } = useDimensions(chartRef);
  const widthChart = width - margin.left - margin.right;
  const heightChart = height - margin.top - margin.bottom;

  const timeValuesMap = useMemo(() => {
    return getTimeValuesMap(items);
  }, [JSON.stringify(items)]);

  const [minXVal = 0, maxXVal = 0] = useMemo(
    () => d3.extent(items, (d) => d.x),
    [JSON.stringify(items)]
  );
  const [minYVal = 0, maxYVal = 0] = useMemo(
    () => d3.extent(items, (d) => d.y),
    [JSON.stringify(items)]
  );

  const itemsSortedX = useMemo(() => {
    return [...items].sort((a, b) => {
      return a.x - b.x;
    });
  }, [JSON.stringify(items)]);

  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .range([X_PADDING_CHART, widthChart - X_PADDING_CHART])
      .domain([Math.floor(minXVal), Math.ceil(maxXVal)]);
  }, [JSON.stringify(items), widthChart]);

  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .range([heightChart - Y_PADDING_CHART / 2, Y_PADDING_CHART / 2])
      .domain([Math.floor(minYVal), Math.ceil(maxYVal)]);
  }, [JSON.stringify(items), heightChart]);

  const yTicks = useMemo(
    () =>
      yScale.ticks(4).map((tick, i) => {
        return (
          <g key={`${i}-yAxis`}>
            <line
              x1={0}
              x2={widthChart}
              y1={yScale(tick)}
              y2={yScale(tick)}
              className="scatterplotGrid yAxis"
            />
            <text
              x={-2}
              y={yScale(tick) + 4}
              fontSize={9}
              textAnchor="end"
              className="scatterplotAxis"
            >
              {tick}
            </text>
          </g>
        );
      }),
    [widthChart, yScale]
  );

  const xTicks = useMemo(()=>xScale.ticks(5).map((tick, i) => {
    const showLine =
      xScale(tick) > 30 && xScale(tick) < widthChart - 50 ? true : false;
    return (
      <XAxisEntry
        x={xScale(tick)}
        heightChart={heightChart}
        label={tick}
        showTick={showLine}
        key={tick}
      />
    );
  }), [heightChart, widthChart, xScale]);

  const allShapes = items.map((d, i) => {
    return (
      <circle
        key={i}
        r={5}
        cx={xScale(d.x)}
        cy={yScale(d.y)}
        opacity={1}
        fill={"#006AB7"}
        fillOpacity={0.5}
        strokeWidth={1}
        onMouseEnter={() => {
          onInteractionData({
            time: d.time,
          });
        }}
        onMouseLeave={() => onInteractionData(null)}
      />
    );
  });

  const yLabelDisplay = useMemo(()=>(
    <text
      x={0}
      y={0}
      fontSize={9}
      textAnchor="middle"
      className="scatterplotAxis"
      transform={`translate(-${margin.left - 9},${
        heightChart / 2
      }) rotate(270)`}
    >
      {contentY.yAxisLabel}
    </text>
  ), [heightChart]);

  const xLabelDisplay = useMemo(()=>(
    <text
      x={widthChart / 2}
      y={heightChart + 40}
      fontSize={9}
      textAnchor="middle"
      className="scatterplotAxis"
    >
      {contentX.xAxisLabel}
    </text>
  ), [widthChart, heightChart]);

  const hoverComplete = getHoverData(interactionData, timeValuesMap, xScale, yScale);

  return (
    <Wrapper ref={chartRef}>
      <svg>
        <g
          width={widthChart}
          height={heightChart}
          transform={`translate(${[margin.left, margin.top].join(",")})`}
        >
          <g className="scatterplotAxis">{xTicks}</g>
          <g className="scatterplotAxis">{yTicks}</g>
          <g className="scatterplotYAxisLabel">{yLabelDisplay}</g>
          <g className="scatterplotXAxisLabel">{xLabelDisplay}</g>
          <g className="scatterplotData">{allShapes}</g>
          <HoveredItem item={hoverComplete} onInteractionData={onInteractionData}/>
          <RegressionLine xScale={xScale} yScale={yScale} items={items} width={widthChart} />
        </g>
        <g className="scatterplotBackground">
          <rect
            width={widthChart}
            height={heightChart}
            transform={`translate(${[margin.left, margin.top].join(",")})`}
            rx="9"
          ></rect>
        </g>
      </svg>
      <Tooltip
        interactionData={hoverComplete}
        width={width}
        height={height}
        unitX={contentX.tooltipUnit}
        tooltipLabelX={contentX.tooltipLabel}
        unitY={contentY.tooltipUnit}
        tooltipLabelY={contentY.tooltipLabel}
      />
    </Wrapper>
  );
};

export default ScatterPlot;

