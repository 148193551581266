import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import NotForOperators from "common/NotForOperators";

import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import * as d3 from "d3";

const doesNothing = () => {};

const StatusResultLayout = styled.div`
  .fact {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    color: #777;
    .field {
      text-align: left;
    }
    .value {
      text-align: left;
    }
    .field, .value {
      font-size: 1em;
      flex: 3ch 1 0;
    }
    &.warning {
      color: red;
    }
  }
`;
const ReconnectionLayout = styled.div`
  .inline {
    display: inline;
  }
  .error {
    color: red;
    font-size: 0.9em;
  }
  .btn {
    margin-top: 20px;
  }
`;

const higherLabelLength = (max, { label = "" }) =>
  max > label.length ? max : label.length;

const Reconnection = ({ reconnect = doesNothing, ready = true }) => {
  const [isBusy, setBusy] = useState(ready === false);
  const doReconnect = () => {
    if (isBusy) {
      console.warn("already reconnecting");
      return;
    }
    setBusy(true);
    reconnect();
  };
  return (
    <ReconnectionLayout>
      <ReconnectButton
        disabled={isBusy}
        label={isBusy ? "Syncing..." : "Sync now"}
        onClick={doReconnect}
      />
    </ReconnectionLayout>
  );
};

const classForFact = (label, value) =>
  label === "Failures since last sync" && parseInt(value) > 0 ? "warning" : "";

const formatDate = d3.timeFormat("%Y-%m-%dT%H:%M:%S%Z");

const StatusResult = ({
  facts = [],
  className = "",
  reconnect = doesNothing,
  moment = undefined,
  ready = true,
}) => {
  return (
    <>
      <NotForOperators>
        <Reconnection reconnect={reconnect} ready={ready} />
      </NotForOperators>
      <StatusResultLayout
        maxLabelChars={1 + facts.reduce(higherLabelLength, 0)}
      >
        <ColumnsGrid columns={2} className={className} maxWidth="15rem" gap="2.5px" minWidth="6cm" rowGap="0">
          {facts.map(({ label, value }) => (
            <div className={`fact ${classForFact(label, value)}`} key={label}>
              <span className="field">{label}:</span>
              <span className="value">{value}</span>
            </div>
          ))}
          <div className={`fact`}>
            <span className="field">Date and time of status:</span>
            <span className="value">{formatDate(moment)}</span>
          </div>
        </ColumnsGrid>
      </StatusResultLayout>
    </>
  );
};
const Bold = styled.span`
  color: gray;
  font-weight: bold;
  margin-top: 25px;
`;
const ReportAPIDisabled = () => (
  <ColumnsGrid columns={1} maxWidth="15rem">
    <Bold>Disabled</Bold>
  </ColumnsGrid>
);

const ReconnectButton = ({ onClick, disabled = false, label = "Sync now" }) => (
  <button
    type="button"
    onClick={disabled === true ? null : onClick}
    disabled={disabled === true}
    className="btn btn-default waves-effect shadow-none hidden-to-operators"
  >
    <i className="material-icons">swap_vert</i>
    <span>{label}</span>
  </button>
);

const Status = ({ provider, reconnect }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    const doingRetrieval = provider().catch((error) => error);
    setRequest(doingRetrieval);
  };
  const doReconnect = () => {
    const doingReconnect = reconnect();
    setRequest(doingReconnect);
    return doingReconnect;
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", () => doLoad());
  }, []);
  return request === null ? null : (
    <Request during={request}>
      {({ facts = null, moment, ready }) => (
        <>
          {facts === null ? (
            <ReportAPIDisabled />
          ) : (
            <StatusResult
              reconnect={doReconnect}
              facts={facts}
              moment={moment}
              ready={ready}
            />
          )}
        </>
      )}
    </Request>
  );
};

export default Status;
