import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import styled from "styled-components";

const HelpLayout = styled.div`
  .material-icons {
    vertical-align: middle;
    font-size: 1.1em;
  }
`;

const LatencyDPIHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Average Internet Latency Per Service"
      onClose={doClose}
      content={() => (
        <HelpLayout>
          <p>
            {" "}
            Table of average Internet latencies (RTT), measured from the BQN to
            the servers in each service. The "quot;all-average"quot; category
            shows the average for all services.
          </p>
          <p>
            For every category, you can get the distribution of the latencies
            (percentage of RTT samples in each latency bin) clicking on the{" "}
            <i className="material-icons hyperlink-generic">equalizer&nbsp;</i>{" "}
            icon. You can also see how that distribution changes over time, i.e.
            the percentage of samples in each bin at different times, by
            clicking on the{" "}
            <i className="material-icons hyperlink-generic">
              multiline_chart&nbsp;
            </i>{" "}
            icon.
          </p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month.
            </li>
            <li>
              <i className="material-icons valign-icon">format_line_spacing</i>{" "}
              Selects the number of services displayed in the table.
            </li>
            <li>
              <i className="material-icons">call_split</i> Select &quot;All
              UDRs&quot; to show the DPI data from all DPI records. Select
              &quot;Only auto UDRs&quot; to show the data for only those DPI
              records that are produced with automatic sampling, excluding DPI
              records produced by monitoring policies.
            </li>
            <li className="f-dpi-per-user">
              <b>Access IP:</b> you can filter the latency results to include
              only traffic from certain subscriber(s), described as an IP
              address or a range of IP addresses.
            </li>
            <li>
              <b>Internet IP:</b> you can filter the latency results to include
              traffic coming only from certain Internet IP address or IP address
              range.
            </li>
            <li>
              Click <code>Filter</code> button to update table.
            </li>
          </ul>
          <p>
            Click on <i className="material-icons valign-icon">refresh</i> icon
            to refresh information.
          </p>
        </HelpLayout>
      )}
    />
  );
};
export default LatencyDPIHelpModal;
