const _ACM_DEFAULTS = { enabled: false };
const _SHAPING_DEFAULTS = {
  perFlow: true,
  perSubscriber: true,
  ratePerSubscriber: false,
};
const _TCP_DEFAULTS = { tcp: false };

const _DEFAULTS = {
  acm: _ACM_DEFAULTS,
  shaping: _SHAPING_DEFAULTS,
  ..._TCP_DEFAULTS,
};

const acmSettingsWarnings = (
  previous = _DEFAULTS,
  { acm = _ACM_DEFAULTS, shaping = _SHAPING_DEFAULTS }
) =>
  (previous.acm.enabled !== acm.enabled ||
    previous.shaping.ratePerSubscriber !== shaping.ratePerSubscriber) &&
  acm.enabled === true &&
  shaping.ratePerSubscriber === false
    ? ['ACM will not work if "Subscriber rate limiting" is not enabled']
    : [];

const tcpSettingsWarnings = (
  previous = _DEFAULTS,
  { tcp = false, shaping = _SHAPING_DEFAULTS }
) =>
  (previous.tcp !== tcp || previous.shaping.perFlow !== shaping.perFlow) &&
  tcp === true &&
  shaping.perFlow === false
    ? [
        'TCP Optimization will work better if "Individual flow shaping" is enabled.',
      ]
    : [];

const getWarningsDefaulted = (previous, settings) => [
  ...acmSettingsWarnings(previous, settings),
  ...tcpSettingsWarnings(previous, settings),
];
export const getWarnings = (previous = {}, settings = {}) =>
  getWarningsDefaulted(
    { ..._DEFAULTS, ...previous },
    { ..._DEFAULTS, ...settings }
  );
