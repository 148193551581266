import React from "react";
import ListWires from "views/Status/Interfaces/DataWires/List";

const doesNothing = () => {};

const ReverseButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">swap_horiz</i>
  </a>
);

const DeleteButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const Actions = ({ wire, doReverse = doesNothing, doDelete = doesNothing }) => (
  <>
    <DeleteButton onClick={() => doDelete(wire.__id)} />
    <ReverseButton onClick={() => doReverse(wire.__id)} />
  </>
);

const notDeleted = ({ deleted = false }) => deleted !== true;

const ListWiresWithActions = ({ items = [], ...others }) => (
  <ListWires filter={notDeleted} items={items} {...others} Actions={Actions} />
);

export default ListWiresWithActions;
