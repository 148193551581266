import React, { useContext } from "react";
import styled from "styled-components";
import Logging from "../Logging";
import ActionsContext from "common/ActionsContext";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const provider = ({ lines }) =>
  ifCl.run(`show system log kernel lines ${lines}`);

const KernelLogs = () => (
  <div id="viewLogsKernel" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div className="card">
      <HeaderDiv className="header">
        <h2 className="title">Kernel logs</h2>
        <ContextMenu />
      </HeaderDiv>
      <div className="body flex-panel-column">
        <Logging name="kernel" provider={provider} />
      </div>
    </div>
  </div>
);

export default KernelLogs;
