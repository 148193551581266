import React, { useEffect, useReducer } from "react";
const doesNothing = () => {};
import CreateUser from "./Create";
import ListUsers from "./List";

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;

function usersReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.user,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...user }) =>
          __id !== action.id
            ? [{ __id, stored, ...user }]
            : stored === true
            ? [{ __id, stored, ...user, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const validates = (candidate, users = []) => {
  const { username, password } = candidate;
  if (username.length === 0) {
    throw "Username can not be empty.";
  } else {
    if (users.some((user) => user.deleted !== true && user.user === user)) {
      throw "Entry with the same user name already exists.";
    }
  }
  if (password.length === 0) {
    throw "Password can not be empty.";
  }
  if (password.length > 31) {
    throw "Password is not allowed to have more than 31 characters.";
  }
};

const Users = ({ initial = [], onChange = doesNothing }) => {
  const [users, dispatch] = useReducer(usersReducer, {
    changed: false,
    list: [...initial],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createUser = (user) => {
    dispatch({
      type: "added",
      user,
    });
  };
  useEffect(() => {
    users.changed === true && onChange(users.list);
  }, [users]);

  const doValidate = (user) => validates(user, users.list);

  return (
    <>
      <ListUsers items={users.list} onDelete={handleDeleteId} />
      <CreateUser doValidate={doValidate} doSave={createUser} />
    </>
  );
};

export default Users;
