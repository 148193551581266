import IpV4 from "common/ipv4";

const parseFirewallItem = (line, fields) =>
  Object.fromEntries(
    line
      .trim(" ")
      .split(/\s+/)
      .map((value, index) => [fields[index], value])
  );

const firewallListTranslations = {
  IFACE: "interface",
};

const firewallListFieldsFrom = (head) =>
  head
    .trim(" ")
    .split(/\s+/)
    .map((field) => firewallListTranslations[field] || field.toLowerCase());

export const parseFirewallList = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const fields = firewallListFieldsFrom(head);
  return rows.map((line) => parseFirewallItem(line, fields));
};

const ntpListTranslations = {
  REMOTE: "range",
};

const deviceIPistTranslations = {
  'IP-ADDR': "range",
};

const deviceIPListFieldsFrom = (head) =>
  head
    .trim(" ")
    .split(/\s+/)
    .map((field) => deviceIPistTranslations[field] || null);



export const getLicenseServers = () =>
  Promise.resolve([
    { range: "46.26.190.166/32" },
    { range: "146.59.206.4/32" },
  ]);

const ntpListFieldsFrom = (head) =>
  head
    .trim(" ")
    .split(/\s+/)
    .map((field) => ntpListTranslations[field] || null);

const parseNTPItem = (line, fields) =>
  Object.fromEntries(
    line
      .trim(" ")
      .split(/\s+/)
      .flatMap((value, index) =>
        fields[index] !== null ? [[fields[index], value]] : []
      )
  );

const appendRangeSuffix =
  (suffix) =>
  ({ range, ...rest }) => ({
    ...rest,
    range: range.includes("/") ? range : `${range}/${suffix}`,
  });

export const parseNTPRanges = (input, suffix = 32) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const fields = ntpListFieldsFrom(head);
  return rows
    .map((line) => parseNTPItem(line, fields))
    .map(appendRangeSuffix(suffix));
};

const parseDeviceIPItem = (line, fields) =>
  Object.fromEntries(
    line
      .trim(" ")
      .split(/\s+/)
      .flatMap((value, index) =>
        fields[index] !== null ? [[fields[index], value]] : []
      )
  );

export const parseDeviceIPRanges = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const fields = deviceIPListFieldsFrom(head);
  return rows
    .map((line) => parseDeviceIPItem(line, fields))
};

export const parseWireNames = (input) =>
  input
    .trim("\n")
    .split("\n")
    .flatMap((row) => row.split(/\s+/).filter((_val, index) => index !== 0));

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const lowerWord = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalize(word);

const asJSAttribute = (input) => input.split(/\s|-+/).map(lowerWord).join("");
const managementTranslation = {
  "IP address": "address",
  "Default gateway": "gateway",
};
const addressAndMask = (input) => {
  const [address, mask] = input.split("/");
  return [
    ["address", address],
    ["mask", mask],
  ];
};
const interfaceAndVLAN = (input) => {
  const [name, vlan] = input.split(".");
  return [
    ["interface", name],
    ["vlan", vlan === undefined ? "" : vlan],
  ];
};

const mayBeNA = (value) => (value === "n/a" ? null : value);

const propertyPerLine = (line) => {
  const [fieldSrc, value] = line.split(/:\s+/);
  const field = managementTranslation[fieldSrc] || asJSAttribute(fieldSrc);
  return field === "address"
    ? addressAndMask(value)
    : field === "interface"
    ? interfaceAndVLAN(value)
    : [[field, mayBeNA(value)]];
};
const intoMIProperties = (settings = {}, [field, value]) => ({
  ...settings,
  [field]: value,
});

export const _DEFAULT_SETTINGS = {
  vlan: "",
};
export const parseManagementFirewall = (input) =>
  input
    .trim("\n")
    .split("\n")
    .flatMap(propertyPerLine)
    .reduce(intoMIProperties, _DEFAULT_SETTINGS);

const notSuggested = ({ from = undefined }) => from === undefined;

const partition = (list, filter) =>
  list.reduce(
    ([accepted, rejected], item) =>
      filter(item)
        ? [[...accepted, item], rejected]
        : [accepted, [...rejected, item]],
    [[], []]
  );

const subnetCollisionsOn = (list) => {
  if (list.length === 0) {
    return () => false;
  }
  const targets = rangesOf(list);
  return ({ range }) =>
    targets.find((target) => IpV4.isSubnetOf(target, range)) !== undefined;
};

export const areChangesColliding = (previous, { ranges = [] }) => {
  const [users, suggested] = partition(ranges, notSuggested);

  const foundOnUsers = subnetCollisionsOn(users);
  return suggested.filter(foundOnUsers);
};

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const significantCode = (code) =>
  code !== null && code !== undefined && code.length > 0;

const newSetCall = (name, ...args) =>
  `${["set", name, ...args]
    .filter(significantCode)
    .map((code) => code.trim(" "))
    .join(" ")}`;

const newCleartCall = (name, ...args) =>
  `${["clear", name, ...args]
    .filter(significantCode)
    .map((code) => code.trim(" "))
    .join(" ")}`;

const rangesOf = (list) => list.map(({ range }) => range);

const applyRanges = (previous, { ranges = [], ...settings }) =>
  ranges.length === 0
    ? newCleartCall("interface", settings.interface, "firewall", "input")
    : newSetCall(
        "interface",
        settings.interface,
        "firewall",
        "input",
        ...rangesOf(ranges)
      );

const notDeleted = ({ deleted = false }) => deleted !== true;

export const composeApplyCommand = (previous, { ranges = [], ...settings }) =>
  applyRanges(previous, { ...settings, ranges: ranges.filter(notDeleted) });
