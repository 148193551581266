/* global views, showModalError, functionality, components, rulesFlow  */

import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import FilteringControls from "./FilteringControls";
import ActionsContext from "common/ActionsContext";
import { parseTotalFlows } from "./api";
import FlowsPerSubscriberHelpModal from "./Help";
import { safeStr } from "common/api";
import useProfile from "common/hooks/useProfile";
import { TitleWithNav } from "common/TitleWithNav";

const doesNothing = () => {};

const useFlowsData = ({ lines, subnet, policy }) => {
  const [request, setRequest] = useState(null);
  const doLoad = useCallback( ()=>{
    setRequest(doRequest({lines, subnet, policy}));
  } , [lines, subnet, policy])
  return [request, doLoad];
}

const Report = ({
  setTotal = doesNothing,
  subnet,
  lines,
  policy,
  ..._otherSettings
}) => {
  const actions = useContext(ActionsContext);
  const [request, doLoad] = useFlowsData({lines, subnet, policy});

  const doLoadTotals = () =>
    ifCl.run("show flow usage").then(parseTotalFlows).then(setTotal);

  const update = () => {
    doLoad();
    doLoadTotals();
  };

  useEffect(() => {
    update();
    return actions.recv("do-load", () => {
      update();
    });
  }, [doLoad]);

  return request === null ? null : (
    <Request during={request}>{(result) => <ShowFlows {...result} />}</Request>
  );
};

const ShowTotal = ({ total = null }) =>
  total === null ? null : (
    <div className="row">
      <div className="margin-l-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <span>Total Active flows&nbsp;&nbsp;</span>
        <span>{total}</span>
      </div>
    </div>
  );

const FlowsPerSubscriber = ({ returnView, policy }) => {
  const [total, setTotal] = useState(null);
  return (
    <div id="viewFlowsPerSubs">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="header block-header">
              <TitleWithNav returnView={returnView}>
                <h2>Flows Per Subscriber</h2>
              </TitleWithNav>
              <ContextMenu returnView={returnView} />
            </div>
            <div className="body table-responsive">
              <ShowTotal total={total} />
              <FilteringControls policy={policy}>
                {(settings) => <Report {...settings} setTotal={setTotal} />}
              </FilteringControls>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowsPerSubscriber;

const doRequest = ({ subnet, lines, policy }) =>
  ifCl
    .run(
      `show  flow all ${subnet || "all"}${
        policy !== "all" ? " policy " + safeStr(policy) : ""
      } full lines ${lines}`
    )
    .then((response) => ({ response }));

const ContextMenu = ({ returnView }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const returnTo = "viewFlowsPerSubs";
const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

const doEditPolicy = (policy) => {
  ifCl
    .run(`show policy ${safeStr(policy)}`)
    .then((response) => {
      const flowPolicies = rulesFlow.data.policies;
      const policy = flowPolicies.parsePolicy(response);
      views.doKeep(returnTo);
      flowPolicies.editPolicy(policy, returnTo);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const openSubscriberDetails = ({ addr, subsId }, row) => {
  views.doKeep(returnTo);
  globalNavigate("viewSubsDetail", {
    addr,
    subsId,
    returnView: returnTo,
  });
};

const getColumns = ({hideRates=false, dpiEnabled=false}) => [
    Column.Text({
      label: "SUBS-ID",
      idx: 11,
      onClick: (subsId, _row, _trigger) =>
        openSubscriberDetails({ subsId }),
      cellClassName: "hyperlink-text",
    }),
    Column.Text({
      label: "TYPE",
      idx: 0,
    }),
    Column.IPAddress({
      label: "ACC-ADDR",
      idx: 1,
      onClick: (addr, _row, _trigger) => openSubscriberDetails({ addr }),
    }),
    Column.NumberOrNA({
      label: "APORT",
      idx: 2,
      thousands: false,
      colClassName: "align-right",
    }),
    Column.IPAddress({
      label: "INET-ADDR",
      idx: 3,
    }),
    Column.NumberOrNA({
      label: "IPORT",
      idx: 4,
      thousands: false,
      colClassName: "align-right",
    }),
    Column.NumberOrNA({
      label: "PROTO",
      idx: 5,
    }),
    Column.Text({
      label: "ST",
      idx: 6,
    }),
    Column.Text({
      label: "INS",
      idx: 7,
    }),
    ...(dpiEnabled === true
      ? [
          Column.Text({
            label: "FLOW-POLICY",
            idx: 8,
            cellClassName: hideRates ? null : "hyperlink-text",
            onClick: hideRates ? null : doEditPolicy,
          }),
        ]
      : []),
    Column.Number({
      label: "MBYTES_DN",
      idx: 9,
      colClassName: "align-right",
    }),
    Column.Number({
      label: "MBYTES_UP",
      idx: 10,
      colClassName: "align-right",
    }),
    Column.Time({
      label: "LIFETIME",
      idx: 12,
      colClassName: "align-right",
    }),
    ...(dpiEnabled === true
      ? [
          Column.Text({
            label: "DPI-DOMAIN",
            idx: 13,
          }),
        ]
      : []),
  ];
/*****************************************************
 * Flows Table
 * ****************************************************/
const ShowFlows = ({ response }) => {
  const profile = useProfile();
  return profile === null ? null : (
  <>
    <TableContainer>
      <DataTable
        pageLength={100}
        content={response}
        exportAsCSV={true}
        columns={getColumns(profile)}
      />
    </TableContainer>
    <FlowsPerSubscriberHelpModal />
  </>
);
}

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;
