import React, { useEffect, useReducer } from "react";
import CreateCommunity from "./Create";
import ListCommunities from "./List";
import { validateIPv4 } from "common/api";

const doesNothing = () => {};

const enumerate =
  (start = 1, excommunityarams) =>
  (item, index) => ({ ...item, __id: start + index, ...excommunityarams });

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;

const doVerification = (item, list) => {
  const { nms, name } = item;

  if (name.length === 0) {
    throw "Community name string must contain a value.";
  }
  if (list.find((item) => item.name === name) !== undefined) {
    throw "NMS already exists.";
  }
  if (nms.length === 0) {
    if (list.find((community) => community.nms === "") !== undefined) {
      throw "Already exists a community entry without addresses.";
    }
  } else {
    if (validateIPv4(nms) !== true) {
      throw "NMS is not a valid IPv4 address";
    }
  }
};

function communitiesReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.community,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...community }) =>
          __id !== action.id
            ? [{ __id, stored, ...community }]
            : stored === true
            ? [{ __id, stored, ...community, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const Communities = ({ initial = [], onChange = doesNothing }) => {
  const [communities, dispatch] = useReducer(communitiesReducer, {
    changed: false,
    list: [...initial].map(enumerate(1, { stored: true })),
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createCommunities = (community) => {
    doVerification(community, communities.list);
    dispatch({
      type: "added",
      community,
    });
  };
  const validateNewItem = (community) => {
    doVerification(community, communities.list);
  };
  useEffect(() => {
    communities.changed === true && onChange(communities.list);
  }, [communities]);

  return (
    <>
      <ListCommunities items={communities.list} onDelete={handleDeleteId} />
      <CreateCommunity
        doSave={createCommunities}
        doValidate={validateNewItem}
      />
    </>
  );
};

export default Communities;
