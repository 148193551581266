const parseKeys = (head, translations = {}) =>
  head.split(/\s+/).map((key) => translations[key] || key.toLowerCase());

const cpuUsage = (row, keys) =>
  Object.fromEntries(
    row
      .trim(" ")
      .split(/\s+/)
      .map((value, index) => [keys[index], index === 0 ? value : Number(value)])
  );

export const parseSystemCPUUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const translations = { "IO-WA": "ioWait" };
  const keys = parseKeys(head.trim(" "), translations);
  return rows.map((row) => cpuUsage(row, keys));
};
