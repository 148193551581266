/*globals login*/
import React from "react";
import NVHorizontalBarChart, {
  calcBarHeight,
  formats,
} from "common/graphs/NVHorizontalBarChart";
import styled from "styled-components";

const UsageGraphLayout = styled.div`
  .centered {
    text-align: center;
  }
`;

const getAspects = () => [
  {
    label: "User",
    color: "#006ab7",
    field: "user",
  },
  {
    label: "Nice",
    color: "#FFA500",
    field: "nice",
  },
  {
    label: "System",
    color: "#228B22",
    field: "sys",
  },
  {
    label: "IO-Wait",
    color: "#FA8072",
    field: "ioWait",
  },
  {
    label: "IRQ",
    color: "#F522F5",
    field: "irq",
  },
  {
    label: "Soft-IRQ",
    color: "#DC143C",
    field: "soft",
  },
  {
    label: "Steal",
    color: "#556B2F",
    field: "steal",
  },
  {
    label: "Guest",
    color: "#696969",
    field: "guest",
  },
  {
    label: "Idle",
    color: login.isTheme('dark') ? "#3a3a3e": "#bcbcbc",
    field: "idle",
    rounded: true,
  },
];

const notAllCPU = ({ cpu }) => cpu !== "all";
const notCPUField = ({ field }) => field !== "cpu";
const cpusUsageByAspects = (cpus = [], aspects) =>
  aspects.filter(notCPUField).map(({ label, color, field, rounded=true }) => ({
    key: label,
    label,
    color,
    rounded,
    values: cpus
      .filter(notAllCPU)
      .map(({ cpu, ...values }) => ({
        label: `CPU${cpu}`,
        value: values[field],
      })),
  }));

const Result = ({ cpus = [] }) => {
  const aspects = getAspects();
  const items = cpusUsageByAspects(cpus, aspects)
  return (
    <UsageGraphLayout>
      <NVHorizontalBarChart
        valueUnits=""
        tooltipValueFormatter={(value) => `${formats.threeDecimals(value)} %`}
        valueFormat={(value) => `${formats.noDecimals(value)} %`}
        items={items}
        barHeight={calcBarHeight(cpus.length)}
        showRoundCorners={true}
        showColors={true}
        valuePadding={30}
      />
    </UsageGraphLayout>
  );
};

export default Result;
