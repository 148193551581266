import {safeStr} from "common/api"

const _WARNING_HEAD = "%WARN-ENOENT: ";

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const lowerWord = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalize(word);

const asJSAttribute = (input) => input.split(/\s+/).map(lowerWord).join("");

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

/************************************************
 * Status
 ************************************************/
const parseSystemStatusRow = (row) => {
  const [fieldSrc, valueSrc] = row.trim().split(/:\s+/);
  if (fieldSrc === "State") {
    const enabled = valueSrc === 'ready' ? true: false;
    return [
      ['state', valueSrc],
      ['enabled', enabled],
    ]
  }
  const field = asJSAttribute(fieldSrc);
  const value = field === "port"
      ? valueSrc === "n/a"
        ? ""
        : parseInt(valueSrc)
      : valueSrc === "n/a"
      ? ""
      : valueSrc;
  return [ [field, value] ];
};

const includeSystemStatusRow = (current, [field, value]) => ({
  ...current,
  [field]: value,
});

const parseSystemStatus = (input) => {
  const rows = input.trim("\n").split("\n");
  return rows.flatMap(parseSystemStatusRow).reduce(includeSystemStatusRow, {});
};

const parseParameterRow = (row) => {
  const [fieldSrc, valueSrc] = row.trim().split(/:\s+/);
  const field = asJSAttribute(fieldSrc);
  const value =
    field === "subscriberIDSource"
      ? valueSrc === "api"
        ? "customer-id"
        : valueSrc
      : valueSrc;
  return [field, value];
};

const preventNoEnt =
  (func, placeholder = []) =>
  (input, ...args) =>
    input.startsWith(_WARNING_HEAD) ? placeholder : func(input, ...args);

const parseClientItem = (row) => {
  const [address, authentication] = row.trim().split(/\s+/);
  return {
    address,
    authentication: authentication === "yes" ? true : false,
  };
};

const parseClientsItems = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseClientItem);
};

const parseUserItem = (row) => {
  const [username, password, restricted, authBasic] = row.trim().split(/\s+/);
  return {
    username,
    password,
    restricted: restricted === "yes" ? true : false,
    authBasic,
  };
};

const parseUsersItems = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseUserItem);
};

const removeNetworkMask = (input) => input.replace(/\/.*$/, "");

const parseManagementDetailRow = (row) => {
  const [fieldSrc, valueSrc] = row.trim().split(/:\s+/);
  const field = fieldSrc === "IP address" ? "address" : asJSAttribute(fieldSrc);
  const value =
    field === "address"
      ? removeNetworkMask(valueSrc)
      : valueSrc === "n/a"
      ? ""
      : valueSrc;
  return [field, value];
};

const includeManagementDetailRow =
  (skipped = []) =>
  (current, [field, value]) =>
    skipped.includes(field)
      ? { ...current }
      : {
          ...current,
          [field]: value,
        };

const _MANAGEMENT_DEFAULT_ = {
  port: 3443,
  enabled: false,
  interface: undefined,
  address: undefined,
};

const parseManagementDetailItems = (input) => {
  const rows = input.trim("\n").split("\n");
  const skipped = ["defaultGateway", "nameserver"];
  return rows
    .map(parseManagementDetailRow)
    .reduce(includeManagementDetailRow(skipped), _MANAGEMENT_DEFAULT_);
};

export const parseUsers = preventNoEnt(parseUsersItems);

export const parseClients = preventNoEnt(parseClientsItems);

export const parseStatus = preventNoEnt(parseSystemStatus);

export const parseManagementDetail = preventNoEnt(parseManagementDetailItems);

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newRESTConfigurationBlock = (...commands) =>
  newBlock("configure", "api rest", ...commands, "commit", "end").trim("\n");

const disableCommand = (previous, _settings) =>
  newRESTConfigurationBlock(
    `no address ${previous.address} ${previous.port}`,
    `no interface ${previous.interface}`
  ).trim("\n");

const ensureSomeSettingsChanged = (...parts) => {
  if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
    throw "No changes available to commit.";
  }
  return parts;
};

const composeSaveClients = (clients) =>
  clients.map(({ address }) => newBlock(`client ${address}`, "exit"));

const composeClearClients = (clients) =>
  clients.map(({ address }) => `no client ${address}`);

const keepSpaces = { preserveSpaces: true }; 

const composeSaveUsers = (users) =>
  users.map(({ username, password }) => `user ${safeStr(username)} ${safeStr(password, keepSpaces)}`);

const composeClearUsers = (users) =>
  users.map(({ username, password }) => `no user ${safeStr(username)} ${safeStr(password, keepSpaces)}`);

const isNotSavedOrDeleted = ({ stored, deleted }) =>
  stored !== true && deleted !== true;

const isSavedAndDeleted = ({ stored, deleted }) =>
  stored === true && deleted === true;

const composeClientsUpdate = (previous, clients) => [
  ...composeClearClients(clients.filter(isSavedAndDeleted)),
  ...composeSaveClients(clients.filter(isNotSavedOrDeleted)),
];

const composeUsersUpdate = (previous, users) => [
  ...composeClearUsers(users.filter(isSavedAndDeleted)),
  ...composeSaveUsers(users.filter(isNotSavedOrDeleted)),
];

const clientsSettings = (previous, { clients = [] }) => {
  return composeClientsUpdate(previous.clients || [], clients);
};

const usersSettings = (previous, { users = [] }) => {
  if (users.filter(({ deleted }) => deleted !== true).length === 0) {
    throw "At least one REST API user must be supplied";
  }
  return composeUsersUpdate(previous.users || [], users);
};

const enableServer = (previous, settings) =>
  previous.enabled !== settings.enabled
    ? [
        `address ${settings.address} ${settings.port}`,
        `interface ${settings.interface}`,
      ]
    : previous.port !== settings.port
    ? [`address ${settings.address} ${settings.port}`]
    : [];

const enableCommand = (previous, settings) =>
  newRESTConfigurationBlock(
    ...ensureSomeSettingsChanged(
      ...enableServer(previous, settings),
      ...clientsSettings(previous, settings),
      ...usersSettings(previous, settings)
    )
  );

export const composeApplyCommand = (previous, settings) =>
  settings.enabled === false && previous.enabled === true
    ? disableCommand(previous, settings)
    : enableCommand(previous, settings);
