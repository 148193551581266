import React, { useRef } from "react";
import NumberInput from "common/NumberInputWithSpinner";
import FoldablePanel from "common/FoldablePanel";
import SwitchInput from "common/SwitchInput";
import { getFormData, rankAndParseFields } from "./utils";

const RTTISettings = ({ defaults = {}, onInputChange }) => (
  <>
    <h2 className="card-inside-title">Internet Side RTT Parameters</h2>
    <p>
      TCPO Measurements are classified into 3 groups depending on the internet
      side RTT (round-trip time). The RTTi-small and RTTi-large parameters are
      the thresholds that divide the internet traffic into small, medium and
      large groups.k
    </p>
    <NumberInput
      name="rtti.small"
      label="RTTi-small"
      defaultValue={defaults.small}
      backspaceValue="0.010"
      onChange={onInputChange}
      onBlur={onInputChange}
      className="input-control align-left"
      autocomplete="off"
      units="seconds"
      min={0}
      step={0.01}
      precision={2}
      hideArrows={false}
    />
    <NumberInput
      name="rtti.large"
      label="RTTi-large"
      defaultValue={defaults.large}
      backspaceValue="0.060"
      onChange={onInputChange}
      onBlur={onInputChange}
      className="input-control align-left"
      autocomplete="off"
      units="seconds"
      min={0}
      step={0.01}
      precision={2}
      hideArrows={false}
    />
  </>
);

const AIWSettings = ({ defaults = {}, onInputChange }) => (
  <>
    <SwitchInput
      defaultChecked={defaults.enabled}
      className={"sw-input"}
      name="adaptativeInitialWindow.enabled"
      label={(checked) =>
        `Adaptative initial window: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onInputChange}
    />
    <NumberInput
      name="adaptativeInitialWindow.size"
      label="Initial window size"
      defaultValue={defaults.size}
      onChange={onInputChange}
      className="input-control align-left"
      autocomplete="off"
      units="packets"
      min={0}
      precision={0}
      hideArrows={false}
      backspaceValue={10}
    />
  </>
);

let tcpParametersOpen = false;

const AdvancedParameters = ({ defaults, onInputChange }) => (
  <FoldablePanel
    title="Advanced Parameters"
    opened={tcpParametersOpen}
    onChange={(value) => (tcpParametersOpen = value)}
  >
    <AIWSettings
      defaults={defaults.adaptativeInitialWindow}
      onInputChange={onInputChange}
    />
    <RTTISettings defaults={defaults.rtti} onInputChange={onInputChange} />
  </FoldablePanel>
);

const ACMSettings = ({ defaults, onInputChange }) => (
  <>
    <h2 className="card-inside-title">Automatic Congestion Management</h2>
    <p>
      If disabled, no policies will apply ACM. If enabled, only policies with
      ACM enabled will apply ACM.
    </p>
    <SwitchInput
      defaultChecked={defaults.enabled}
      className={"sw-input"}
      name="acm.enabled"
      label={(checked) =>
        `Global ACM Status: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onInputChange}
    />
  </>
);

const ShapingSettings = ({ defaults, onInputChange }) => (
  <>
    <h2 className="card-inside-title">Shaping</h2>
    <p>
      If disabled, no shaping or rate limit will be applied, even if configured.
    </p>
    <SwitchInput
      defaultChecked={defaults.perFlow}
      className={"sw-input"}
      name="shaping.perFlow"
      label={(checked) =>
        `Individual flow shaping (per flow): ${
          checked ? "enabled" : "disabled"
        }`
      }
      onChange={onInputChange}
    />
    <SwitchInput
      defaultChecked={defaults.perSubscriber}
      className={"sw-input"}
      name="shaping.perSubscriber"
      label={(checked) =>
        `Aggregated flow shaping (per subscriber): ${
          checked ? "enabled" : "disabled"
        }`
      }
      onChange={onInputChange}
    />
    <SwitchInput
      defaultChecked={defaults.ratePerSubscriber}
      className={"sw-input"}
      name="shaping.ratePerSubscriber"
      label={(checked) =>
        `Subscriber rate limiting: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onInputChange}
    />
    <SwitchInput
      defaultChecked={defaults.ratePerSubscriberGroup}
      className={"sw-input"}
      name="shaping.ratePerSubscriberGroup"
      label={(checked) =>
        `Subscriber-group rate limiting: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onInputChange}
    />
  </>
);
const SystemSettings = ({ onChange, defaults = {}, state = {} }) => {
  const form = useRef(null);
  const onInputChange = () => {
    const values = getFormData(form.current);
    onChange(rankAndParseFields(values, defaults));
  };
  return (
    <form ref={form}>
      <h2 className="card-inside-title">TCP Parameters</h2>
      <p>Global TCP related parameters.</p>
      <SwitchInput
        defaultChecked={defaults.tcp}
        className={"sw-input"}
        name="tcp"
        label={(checked) =>
          `Overall TCP Optimization: ${checked ? "enabled" : "disabled"}`
        }
        onChange={onInputChange}
      />
      <AdvancedParameters
        defaults={defaults}
        state={state}
        onInputChange={onInputChange}
      />
      <ACMSettings
        defaults={defaults.acm}
        state={state}
        onInputChange={onInputChange}
      />
      <ShapingSettings
        defaults={defaults.shaping}
        state={state.shaping}
        onInputChange={onInputChange}
      />
    </form>
  );
};

export default SystemSettings;
