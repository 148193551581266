import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import styled from "styled-components";

const ListWithIcons = styled.ul`
  & li span,
  i.material-icons {
    line-height: 24px;
    vertical-align: middle;
  }
  i.material-icons {
    margin-right: 0.2em;
  }
`;

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);

  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title="Subscriber Groups"
      onClose={doClose}
      content={() => (
        <>
          <p>
          This page shows metrics for all subscribers groups. It includes a chart of a metric evolution over time and its associated heat-map. The page also includes a table at the bottom with subscriber group data.
          </p>
          <h5>
            Selecting what to display
          </h5>
          <p>
            A set of widgets at the top of the page allow the control of the information shown in the page:
          </p>
          <ListWithIcons>
            <li>
              <span>
                <i className="material-icons">import_export</i>
                <b>Direction</b>: metrics in the downlink or in the uplink traffic directions.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">devices</i>
                <b>Filtered IP address</b>: if specified, the page shows only the subscriber groups with this IP address as a member.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">date_range</i>
                <b>Period</b>: time period to considered. One day by default.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">insert_chart</i>
                <b>Metric</b>: the metric to show. Average speed by default.
              </span>
            </li>
            <li>
              <span>
                <i className="material-icons">label_outline</i>
                <b>How to use the filtered IP Address</b>: "Use filtered IP for metrics" uses the filtered IP address directly in the shown metrics. "Use subs-id for filtered IP" shows metrics based on the subscriber ID of the filtered IP address.
              </span>
            </li>
          </ListWithIcons>
          <p>
          When changes are made to what information to display, the page with refresh automatically.
          In any case, click on the refresh icon on the top right to refresh the whole page whenever necessary.
          </p>
          <h5>
            Some useful operations
          </h5>
          <ul>
            <li>
            To go to a subscriber group dashboard, click on the subscriber name label in the heat-map or in the table. Another alternative is right-cliciking on the label in the chart legend.
            </li>
            <li>
            To go to the metrics of the members of a group, click on its SUBS-ACTIVE total in the table.
            </li>
            <li>
            To show/hide a subscriber group in the chart, click on its heat-map line.        
            </li>
            <li>
            To see a subscriber group alone, double-click on its heat-map line.  
            </li>
            <li>
            To change the set of subscribers groups shown in the chart and heat-map, use the tick-box in the table PLOT column. A maximum of 50 subscriber groups can be shown at the same time.  
            </li>
            <li>
            To zoom in and out of the metric value range, drag the limits of the range bar to the left of the heat-map.  
            </li>
            <li>
            To zoom in an out of a time period, drag the time bar at the bottom of the heat-map. 
            </li>
            <li>
            To see the the exact value of a chart or heat-map point, place the mouse over it.  
            </li>
          </ul>
          <h5>
          Subscriber group editing
          </h5>
          <ul>
            <li>
            To add a new subscriber group, press Add Subscriber Group... on the page top.
            </li>
            <li>
            To edit the group subscriber members and set rate limits, click on the edit icon in the table ACTIONS column.
            </li>
            <li>
            To delete a subscriber group, click on the delete icon in the table ACTIONS column.      
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
