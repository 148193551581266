import React from "react";
import styled from "styled-components";
import SelectInput from "common/SelectInput";
import PasswordInput from "common/PasswordInput";

const splynxBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer ID", value: "customer-id" },
  { label: "Login", value: "login" },
  { label: "Username", value: "username" },
];

const dictionary = {
  'user-name': 'username',
}

function translate(input){
  return dictionary[input] ?? input;
}


const SplynxBillingSettingsInputs = ({ onChange, defaults = {} }) => (
  <>
    <PasswordInput
      defaultValue={defaults.apikey}
      className="input-group"
      name="apikey"
      label="API key"
      onChange={onChange}
    />
    <PasswordInput
      defaultValue={defaults.apisecret}
      className="input-group"
      name="apisecret"
      label="API secret"
      onChange={onChange}
    />
    <SelectInput
      label="Subscriber-ID source"
      name="subscriberIdSource"
      className="input-group"
      onChange={onChange}
      defaultValue={defaults.subscriberIdSource ==="n/a" || defaults.subscriberIdSource === undefined ? "unchanged" : translate(defaults.subscriberIdSource)}
      options={splynxBillingSubscribers}
    />
  </>
);

export default SplynxBillingSettingsInputs;
