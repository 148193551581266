import { InvalidFieldValue } from "common/SettingsView";
import { safeStr } from "common/api"; 

const _WARNING_HEAD = "%WARN-ENOENT: ";

const _DEFAULT_SETTINGS = { enabled: false, type: "azotel" };

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input.split(/\s+/).map(lowerFirstUpperOthers).join("");

const fieldTranslations = {
  "Auto-Congestion-Management": "acmEnabled",
  "Subscriber ID source": "subscriberIdSource",
  "Subscriber group data": "subscriberGroupData",
  "API Key": "key",
  "Rate-Limit percentage": "rateLimitPercentage",
  "Sonar non-blocking status": "sonarNonBlockingStatus",
  "Sonar policy name from Sonar": "sonarPolicyName",
  "Dual-stack subscriber-group": "dualStackSubscriberGroup"
};

const composeFieldAndValue = (previous, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);

  return {
    ...previous,
    [fieldTranslations[srcField] || asJSAttribute(srcField)]: srcValue,
  };
};

const applyPowercodeContraints = ({
  username,
  password,
  mysqlUsername,
  mysqlPassword,
  ...rest
}) => ({
  ...rest,
  username,
  password,
  ...(mysqlUsername === username ? {} : { mysqlUsername }),
  ...(mysqlPassword === password ? {} : { mysqlPassword }),
});

const applyContraints = ({ type, ...rest }) =>
  type === "powercode"
    ? applyPowercodeContraints({ type, ...rest })
    : { type, ...rest };

export const parseFromTabs = (input) =>
  applyContraints(
    input.startsWith(_WARNING_HEAD)
      ? { ..._DEFAULT_SETTINGS }
      : input
          .trim("\n")
          .split("\n")
          .reduce(composeFieldAndValue, { enabled: true })
  );

const yesOrNo = (input) =>
  input === "yes" ? true : input === "no" ? false : null;

const _defaultSettings = {
  acmEnabled: true,
  blocking: true,
  rateLimitPercentage: 100,
};

const enumerate =
  (start = 1, extraParams) =>
  (item, index) => ({
    ...item,
    __id: start + index,
    ...extraParams,
  });

const asStatus = (name) => ({ name, fixed: name === "Active" ? true : false });

const toStatusList = (source = "") =>
  source === "n/a"
    ? []
    : source
        .trim(" ")
        .split(/\s+/)
        .map(asStatus)
        .map(enumerate(1, { stored: true }));

const expectingAPIKey = new Set(["powercode-api", "sonar", "splynx", "wispro"]);
const expectingAPISecret = new Set(["splynx"]);
const expectingClientIDAndSecret = new Set(["visp"]);

export const configToForm = ({
  type,
  key,
  id,
  secret,
  enabled,
  equipmentCategory,
  serverPort,
  serverName,
  serverAddress,
  acmEnabled,
  subscriberIdSource,
  subscriberGroupData,
  rateLimitPercentage,
  sonarNonBlockingStatus,
  sonarDelinquent,
  sonarPolicyName,
  blocking,
  vispBlockingBasedOnStatus,
  dualStackSubscriberGroup,
  ...rest
}) => ({
  ..._defaultSettings,
  ...rest,
  type,
  enabled,
  port: serverPort,
  server: serverName === "n/a" ? serverAddress : serverName,
  ...((subscriberIdSource !== undefined && { subscriberIdSource }) || {}),
  ...((blocking !== undefined && { blocking: yesOrNo(blocking) }) || {}),
  ...((rateLimitPercentage !== undefined && {
    rateLimitPercentage: parseInt(rateLimitPercentage),
  }) ||
    {}),
  ...((expectingAPIKey.has(type) && { apikey: key }) || {}),
  ...((expectingAPISecret.has(type) && { apisecret: secret }) || {}),
  ...((expectingClientIDAndSecret.has(type) && {
    clientid: id,
    clientsecret: secret,
  }) ||
    {}),
  ...(acmEnabled !== undefined && { acmEnabled: acmEnabled === "enabled" }),
  ...(equipmentCategory !== undefined && {
    equipmentCategories: equipmentCategory,
  }),
  ...((type === "sonar" && {
    sonarDelinquent: yesOrNo(sonarDelinquent),
    sonarNonBlockingStatus: toStatusList(sonarNonBlockingStatus),
    sonarPolicyName: sonarPolicyName === "enabled",
    dualStackSubscriberGroup: dualStackSubscriberGroup ? dualStackSubscriberGroup.replace('username', 'name') : "disabled",
  }) ||
    {}),
  ...((type === "visp" && {
    vispBlockingBasedOnStatus: vispBlockingBasedOnStatus === "enabled",
  }) ||
    {}),
  ...((subscriberGroupData !== undefined && {
    subscriberGroupData: subscriberGroupData === "enabled",
  }) ||
    {}),
  ...((type === "splynx" && {
      dualStackSubscriberGroup: dualStackSubscriberGroup ?? "disabled",
    }) ||
      {}),
});

export const defaultPort = (type) =>
  type === "azotel"
    ? 443
    : type === "powercode-api"
    ? 444
    : type === "powercode"
    ? 22
    : type === "splynx"
    ? 443
    : type === "sonar"
    ? 443
    : type === "wispro"
    ? 443
    : type === "visp"
    ? 443
    : undefined;

const setPort = (type, input) => {
  if (input === undefined || input.length === 0) {
    return "";
  }
  const port = parseInt(input);
  if (isNaN(port) === true) {
    throw new InvalidFieldValue("Port", `value "${input}" is not a number`);
  }
  if (port > 65535 || port < 1) {
    throw new InvalidFieldValue(
      "Port",
      `value "${port}" is not between 0 and 65535`
    );
  }
  return port === defaultPort(type) || port === undefined ? "" : ` ${port}`;
};

const setTarget = ({ type, server, port }) => {
  if (server === undefined || server.length === 0) {
    throw new InvalidFieldValue("server", "is empty");
  }
  const filedServer = server.trim(" ");
  if (filedServer.includes(" ") === true) {
    throw new InvalidFieldValue(
      "server",
      `value "${server}" contains white spaces`
    );
  }
  return ` server ${filedServer}${setPort(type, port)}`;
};

const setPowercodeAPISubscriber = (previous, { subscriberIdSource }) =>
  subscriberIdSource === undefined
    ? ""
    : subscriberIdSource === "unchanged"
    ? " no subscriber-id"
    : ` subscriber-id ${subscriberIdSource}`;

const setAzotelSubscriber = setPowercodeAPISubscriber; //For now
const setSplynxSubscriber = setPowercodeAPISubscriber; //For now
const setVispSubscriber = setPowercodeAPISubscriber; //For now
const setPowercodeSubscriber = setPowercodeAPISubscriber;

const EQ_CAT_MIN_VALUE = 0;
const EQ_CAT_MAX_VALUE = 4294967295;

const parseCategory = (input) => {
  try {
    const result = BigInt(input);
    if (result < EQ_CAT_MIN_VALUE) {
      throw new InvalidFieldValue(
        "equipment category",
        `value ${result} is below ${EQ_CAT_MIN_VALUE}`
      );
    }
    if (result > EQ_CAT_MAX_VALUE) {
      throw new InvalidFieldValue(
        "equipment category",
        `value ${result} is above ${EQ_CAT_MAX_VALUE}`
      );
    }
    return result;
  } catch (error) {
    if (error instanceof InvalidFieldValue) {
      throw error;
    }
    const parsingFailed = error.message.match(
      /Cannot convert (.*) to a BigInt/
    );
    const reason =
      parsingFailed === null
        ? error.message
        : `value "${parsingFailed[1]}" is not a number`;
    throw new InvalidFieldValue("equipment category", reason);
  }
};

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

const hasSomeChars = (val) => val !== undefined && val.length > 0;

const setEquipmentCategories = (input) => {
  if (input === undefined || input.length === 0) {
    return "";
  }
  const list = input
    .trim(/\s/)
    .split(/\s+/)
    .filter(hasSomeChars)
    .map(parseCategory);
  return list.length === 1 && list[0] == 1
    ? ""
    : list.map((value) => `category ${value}`).join("\n");
};

const clearCategories = (input) => {
  if (input === undefined || input.length === 0) {
    return "";
  }
  const list = input
    .trim(/\s/)
    .split(/\s+/)
    .filter(hasSomeChars)
    .map(parseCategory);
  return list.length === 1 && list[0] == 1
    ? ""
    : list.map((value) => `no category ${value}`).join("\n");
};

const keepSpaces = {preserveSpaces: true}; 
const setPowercodeAPICredentials = (
  previous,
  { apikey, equipmentCategories }
) => {
  if (apikey === undefined || apikey.length === 0) {
    throw new InvalidFieldValue("API key", `value cannot be empty`);
  }
  return newBlock(
    clearCategories(previous.equipmentCategories),
    setEquipmentCategories(equipmentCategories),
    `key ${safeStr(apikey, keepSpaces)}`
  );
};

const ensureValidField = (value, label) => {
  if (value === undefined || value.length === 0) {
    throw new InvalidFieldValue(label, `value cannot be empty`);
  }
  if (value.includes(" ") === true) {
    throw new InvalidFieldValue(label, `cannot contain spaces`);
  }
};

const ensureValidPassword = (value, label) => {
  if (value === undefined || value.length === 0) {
    throw new InvalidFieldValue(label, `value cannot be empty`);
  }
};

const setCompulsoryUsernameAndPassword = (username, password) => {
  const filedUsername = username.trim(" ");
  ensureValidField(filedUsername, "User");
  ensureValidPassword(password, "Password");
  return newBlock(`username ${safeStr(filedUsername)}\npassword ${safeStr(password, keepSpaces)}\n`);
};

const setCompulsoryClientCredentials = (clientid, clientsecret) => {
  ensureValidPassword(clientsecret, "Client Secret");
  ensureValidField(clientid, "Client ID");
  return newBlock(`id ${safeStr(clientid)}\nsecret ${safeStr(clientsecret, keepSpaces)}\n`);
};

const setAzotelCredentials = (previous, { username, password }) =>
  newBlock(setCompulsoryUsernameAndPassword(username, password));

const setVispCredentials = (
  previous,
  { username, password, clientid, clientsecret }
) =>
  newBlock(
    setCompulsoryUsernameAndPassword(username, password),
    setCompulsoryClientCredentials(clientid, clientsecret)
  );

const mayClearPreviousUsername = ({ mysqlUsername }) =>
  mysqlUsername === undefined || mysqlUsername === 0
    ? [] // No need to clear
    : [`no mysql username ${safeStr(mysqlUsername)}`];

const mayClearPreviousPassword = ({ mysqlPassword }) =>
  mysqlPassword === undefined || mysqlPassword === 0
    ? [] // No need to clear
    : [`no mysql password ${safeStr(mysqlPassword)}`];

const setMySQLCredentials = (
  previous,
  { mysqlUsername, mysqlPassword, username, password }
) => {
  if (mysqlUsername !== undefined && mysqlUsername.includes(" ") === true) {
    throw new InvalidFieldValue(
      "Mysql Username",
      `value "${mysqlUsername}" contains white spaces`
    );
  }
  return [
    ...(mysqlUsername === undefined ||
    mysqlUsername.length === 0 ||
    mysqlUsername === username
      ? mayClearPreviousUsername(previous)
      : [`mysql username ${safeStr(mysqlUsername)}`]),
    ...(mysqlPassword === undefined ||
    mysqlPassword.length === 0 ||
    mysqlPassword === password
      ? mayClearPreviousPassword(previous)
      : [`mysql password ${safeStr(mysqlPassword, keepSpaces)}`]),
  ];
};

const setPowercodeCredentials = (previous, { username, password, ...rest }) => {
  return newBlock(
    setCompulsoryUsernameAndPassword(username, password),
    ...setMySQLCredentials(previous, { username, password, ...rest })
  );
};

const setSplynxCredentials = (previous, { apikey, apisecret }) => {
  if (apisecret === undefined || apisecret.length === 0) {
    throw new InvalidFieldValue("API Secret", `value cannot be empty`);
  }
  if (apikey === undefined || apikey.length === 0) {
    throw new InvalidFieldValue("API Key", `value cannot be empty`);
  }

  return newBlock(`key ${safeStr(apikey)}\nsecret ${safeStr(apisecret)}\n`);
};

const setSonarCredentials = (previous, { apikey }) => {
  if (apikey === undefined || apikey.length === 0) {
    throw new InvalidFieldValue("API Key", `value cannot be empty`);
  }
  return newBlock(`key ${safeStr(apikey)}\n`);
};

const setWisproCredentials = (previous, { apikey }) => {
  if (apikey === undefined || apikey.length === 0) {
    throw new InvalidFieldValue("API Key", `value cannot be empty`);
  }
  return newBlock(`key ${safeStr(apikey)}\n`);
};

const setRateLimitPercentage = (previous, { rateLimitPercentage }) => {
  if (
    rateLimitPercentage === undefined ||
    rateLimitPercentage === previous.rateLimitPercentage
  ) {
    return undefined;
  }
  if (rateLimitPercentage < 1) {
    throw new InvalidFieldValue(
      "Rate-limit-percentage",
      `value "${rateLimitPercentage}" cannot be lower than 1`
    );
  }
  if (rateLimitPercentage > 200) {
    throw new InvalidFieldValue(
      "Rate-limit-percentage",
      `value "${rateLimitPercentage}" cannot be higher than 200`
    );
  }
  return newBlock(`rate-limit-percentage ${rateLimitPercentage}\n`);
};

const setCredentials = (previous, expected) =>
  expected.type === "powercode-api"
    ? setPowercodeAPICredentials(previous, expected)
    : expected.type === "sonar"
    ? setSonarCredentials(previous, expected)
    : expected.type === "wispro"
    ? setWisproCredentials(previous, expected)
    : expected.type === "powercode"
    ? setPowercodeCredentials(previous, expected)
    : expected.type === "azotel"
    ? setAzotelCredentials(previous, expected)
    : expected.type === "splynx"
    ? setSplynxCredentials(previous, expected)
    : expected.type === "visp"
    ? setVispCredentials(previous, expected)
    : "";

const setSonarDelinquent = (previous, enabled) =>
  enabled === true && previous !== enabled
    ? ["sonar delinquent"]
    : enabled === false && previous === true
    ? ["no sonar delinquent"]
    : [];

const setSonarPolicyFromSonar = (previous, enabled) =>
  enabled === true && previous !== enabled
    ? ["sonar policy-name-from-sonar"]
    : enabled === false && previous === true
    ? ["no sonar policy-name-from-sonar"]
    : [];

const isNotSavedOrDeleted = ({ stored, deleted }) =>
  stored !== true && deleted !== true;

const isSavedAndDeleted = ({ stored, deleted }) =>
  stored === true && deleted === true;

const composeClearNonBlockingStatus = (list) =>
  list.map(({ name }) => `no sonar status non-block ${safeStr(name)}`);

const notEmpty = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can't be empty";
  }
  return value;
};
const containsNoSpaces = (value) => {
  if (value.includes(" ") === true) {
    throw "can't contain spaces";
  }
  return value;
};

const verified = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

export const validBlockingStatusName = (value) => notEmpty(value);

const composeSaveNonBlockingStatus = (list = []) =>
  list.map(
    ({ name }) =>
      `sonar status non-block ${safeStr(verified(
        `Received blocking status "${name}"`,
        name,
        validBlockingStatusName
      ), keepSpaces)}`
  );

const setSonarNonBlockingStatus = (previous, list = []) => [
  ...composeClearNonBlockingStatus(list.filter(isSavedAndDeleted)),
  ...composeSaveNonBlockingStatus(list.filter(isNotSavedOrDeleted)),
];

const setSonarSettings = (
  previous,
  { sonarDelinquent, sonarNonBlockingStatus, sonarPolicyName }
) => [
  ...setSonarDelinquent(previous.sonarDelinquent, sonarDelinquent),
  ...setSonarPolicyFromSonar(previous.sonarPolicyName, sonarPolicyName),
  ...setSonarNonBlockingStatus(
    previous.sonarNonBlockingStatus,
    sonarNonBlockingStatus
  ),
];

const setACM = (previous, { acmEnabled }) =>
  acmEnabled === true
    ? "auto-congestion-management"
    : acmEnabled === false && previous.acmEnabled === true
    ? "no auto-congestion-management"
    : undefined;

const setBlocking = (previous, { blocking }) =>
  blocking === true
    ? "blocking"
    : blocking === false && previous.blocking === true
    ? "no blocking"
    : undefined;

const groupDataTypes = new Set([
  "sonar",
  "powercode",
  "wispro",
  "visp",
  "azotel",
]);

export const isTypeSubscriberGroupDataAllowed = (type) =>
  groupDataTypes.has(type);

const setSubscriberGroupData = (previous, { subscriberGroupData }) =>
  subscriberGroupData === true
    ? "subscriber-group"
    : subscriberGroupData === false && previous.subscriberGroupData === true
    ? "no subscriber-group"
    : undefined;

const dualStackSubscriberGroupCmds = {
  splynx: {
    'disabled': 'no splynx dual-stack-name',
    'username': 'splynx dual-stack-name username',
    'login': 'splynx dual-stack-name login',
    'customer-id': 'splynx dual-stack-name customer-id',
    'username + customer-id': 'splynx dual-stack-name username customer-id',
    'login + customer-id': 'splynx dual-stack-name login customer-id',
  },
  sonar:{
    'disabled': 'no sonar dual-stack-name',
    'name': 'sonar dual-stack-name name',
    'customer-id': 'sonar dual-stack-name customer-id',
    'name + customer-id': 'sonar dual-stack-name name customer-id',
  }
}

const setDualStackSubscriberGroup = (type, previous, { dualStackSubscriberGroup }) =>
  dualStackSubscriberGroupCmds[type][dualStackSubscriberGroup]

const setVispSettings = (previous, { vispBlockingBasedOnStatus }) =>
  vispBlockingBasedOnStatus === true && previous.vispBlockingBasedOnStatus !== true
    ? ['visp block-based-on-status']
    : vispBlockingBasedOnStatus === false && previous.vispBlockingBasedOnStatus === true
    ? ['no visp block-based-on-status']
    : [];

const updatePreviousConfig = (previous, expected) => {
  return newBlock(
    "api billing",
    `type ${expected.type}`,
    setACM(previous, expected),
    setCredentials(previous, expected),
    setTarget(expected),
    setRateLimitPercentage(previous, expected),
    expected.type === "azotel"
      ? setAzotelSubscriber(previous, expected)
      : expected.type === "splynx"
      ? setSplynxSubscriber(previous, expected)
      : expected.type === "visp"
      ? setVispSubscriber(previous, expected)
      : expected.type === "powercode-api"
      ? setPowercodeAPISubscriber(previous, expected)
      : setPowercodeSubscriber(previous, expected),
    ...(expected.type === "sonar" ? setSonarSettings(previous, expected) : []),
    ...(expected.type === "visp" ? setVispSettings(previous, expected) : []),
    setBlocking(previous, expected),
    isTypeSubscriberGroupDataAllowed(expected.type) &&
      setSubscriberGroupData(previous, expected),
    ['splynx', 'sonar'].includes(expected.type) && setDualStackSubscriberGroup(expected.type, previous, expected)
  );
};

const isVoid = (target) =>
  typeof target === "object" && Object.keys(target).length === 0;

const requireClear = (previous = {}, expected = {}) =>
  expected.enabled !== true ||
  (isVoid(previous) !== true && previous.type !== expected.type)
    ? "no api billing"
    : "";

export const composeApplyCommand = (previous, expected) => {
  const clears =
    (previous.enabled && requireClear(previous, expected)) || false;
  previous = clears.length > 0 ? {} : previous;

  const directive =
    expected.enabled !== true
      ? newBlock("configure", clears, "commit")
      : newBlock(
          "configure",
          clears,
          updatePreviousConfig(previous, expected),
          "commit"
        );
  return directive.trimLeft("\n");
};
