import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help-details", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Subscriber Dashoard"
      onClose={doClose}
      content={() => (
        <>
          Shows current information and statistics for the subscriber with the
          selected IP address or Subscriber ID.
          <ul>
            <li>
              Click on the refresh{" "}
              <i className="material-icons valign-icon">refresh</i> icon to
              refresh the data. The icon in the upper right refreshes the whole
              page. Each dashboard panel has its own individual refresh.
            </li>
            <li>
              Click on the print{" "}
              <i className="material-icons valign-icon">print</i> icon to print
              the dashboard.
            </li>
          </ul>
          <br />
          <h5>Top panel</h5>
          <p>
            The first panel shows the policies applied to the subscriber and
            some additional details. If the subscriber ID has several IP
            addresses or an IP address is handled by different processes
            (instances), there will be an option menu to choose between them.
          </p>
          <br />
          <h5>Current Metrics </h5>
          <p>
            This panel displays the latest metrics for the subscriber. The life
            mode shows those metrics evolving in real time.
          </p>
          <br />
          <h5>Historical Metrics</h5>
          <p>
            The panel shows the evolution over the selected period of time of a
            number of metrics:
            <ul>
              <li>
                The average speed (measured over 10-minute intervals) in the
                downlink and uplink directions.
              </li>
              <li>
                The maximum speed recorded in every 10 minute interval in the
                downlink and uplink directions.
              </li>
              <li>
                The average access latency, from this node to the subscriber,
                not counting the path from this node to the content services in
                Internet. As a reference, the average latency for all the
                network subscribers is included.
              </li>
              <li>
                The average packet retransmissions on the access side. As a
                reference, the average retransmissions for all the network
                subscribers are included.
              </li>
              <li>
                The average reduction in access latency thanks to the the ACM
                functionality, in milliseconds
              </li>
              <li>
                The average reduction in access retransmissions thanks to the
                the ACM functionality, in absolute percent (e.g. a reduction
                from 7% to 5% retransmissions will appear as a 2% reduction).
              </li>
              <li>
                The average percent of downlink traffic that: goes at a speed
                near the maximum, it is detected as congested and it is limited
                by the ACM functionality.
              </li>
              <li>
                The number of active flows and the number of flows created in 10
                minute intervals
              </li>
              <li>
                Latency vs Average Speed: scatter plot of latency-speed pairs.
              </li>
              <li>
                Packet Retransmissions vs Average Speed: scatter plot of
                retransmissions-speed pairs.
              </li>
              <li>
                Congestion vs Average Speed: scatter plot of congestion-speed
                pairs.
              </li>
            </ul>
          </p>
          <br />
          <h5>DPI Panel</h5>
          <p>This panel shows Deep-Packet Inspection (DPI) information. </p>
          <p>
            Select the time period, the number of services to show (those of
            highest usage) and the traffic direction.
          </p>
          <p>
            The DPI information includes:
            <ul>
              <li>
                The evolution over time of the observed service composition.
              </li>
              <li>
                Each service average latency. It is measured between this node
                and the content servers.
              </li>
            </ul>
          </p>
          <p>
            If you click on a latency bar, the latency distribution for that
            service is shown. From there, a Distributon by Time link leads to
            the evolution over time of that time distribution.
          </p>
          <p>
            Please, be aware that DPI information comes from traffic samples ,
            so if a subscriber has little traffic, the information may not be
            very precise.
          </p>
          <br />
          <h5>Active Flows</h5>
          <p>
            A table displays all the active flows of the subscriberEach:
            <ul>
              <li>The DPI category of the flow. </li>
              <li>The DPI DOMAIN, if any. </li>
              <li>The assigned FLOW-POLICY. </li>
              <li>
                Downlink and uplink speeds during the last second before the
                refresh (MBPS-DN and MBPS-UP).
              </li>
              <li>
                Downlink and uplink traffic volumes (MBYTES-DN and MBYTES-UP).
              </li>
              <li>
                Flow identification information: protocol (PROTO), the
                access-side port (APORT), the Internet-side address (INET-ADDR)
                and Internet-side port (IPORT).
              </li>
              <li>
                The state of the flow: OP (TCP optimized/accelerated), TR
                (tracked, not accelerated to serve as reference in acceleration
                measurements), HS (TCP in initial hanshake), FD (forwarded, UDP
                and ICMP flows and TCP flows not being optimized nor tracked).
              </li>
              <li>The DURATION of the flow in hours:minutes:seconds.</li>
            </ul>
          </p>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
