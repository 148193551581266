import React from "react";
import NVHorizontalBarChart, {
  formats,
} from "common/graphs/NVHorizontalBarChart";
import styled from "styled-components";

const UsageGraphLayout = styled.div`
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
  .centered {
    text-align: center;
  }
`;
const Result = ({ used, free }) => {
  const barColor = "#006AB7";
  
  return (
    <UsageGraphLayout>
      <h4>Root disk partition</h4>
      <NVHorizontalBarChart
        valueUnits=""
        tooltipValueFormatter={(value) => `${formats.oneDecimal(value)} GB`}
        items={[
          {
            key: "Used",
            color: barColor,
            values: [
              { label: "", value: used },
            ],
          },
          {
            key: "Free",
            color: `${login.isTheme("light")?"#cacaca":"#3A3A3E"}`,
            values: [
              { label: "", value: free },
            ],
          },
        ]}
        showRoundCorners = {true}
        showColors = {true}
        height = "20px" 
      />
      <div className="centered">Gbytes</div>
    </UsageGraphLayout>
  );
};

export default Result;
