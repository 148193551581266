import styled from "styled-components";
import { BackButton } from "./BackButton";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TitleWithNav = function ({ children, returnView, backButton }) {
  if (!returnView && !backButton) {
    return children;
  }

  return (
    <FlexDiv>
      <BackButton className="margin-r-15" />
      {children}
    </FlexDiv>
  );
};
