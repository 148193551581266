import React, { useContext } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import SubscriberThroughputLiveReport from "./Report";
import ThroughputLiveHelpModal from "./Help";

const RedText = styled.span`
  color: var(--signal-red);
`;

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
      </li>
    </ul>
  );
};

const SubscriberThroughputLive = () => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="header block-header">
          <h2>Subscriber Throughput Live</h2>
          <small>
            <RedText>Right-click</RedText> on the legend items or inside the
            chart to get the corresponding subscriber dashboard.
          </small>
          <ContextMenu/>
        </div>
        <div className="body flex-panel-row">
          <SubscriberThroughputLiveReport />
        </div>
        <ThroughputLiveHelpModal />
      </div>
    </div>
  );
};

export default SubscriberThroughputLive;
