//* globals functionality, login, rulesFlow, views, showModalError, showModalConfirm */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext
} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import FlowPoliciesHelpModal from "./Help";
import { handleWarnings } from "./api";
import { safeStr } from 'common/api';
import ActionsContext from "common/ActionsContext";
import { ActionsRow, someColumnsMayBeCensored } from "views/Status/Policies/Common";
import useProfile from "common/hooks/useProfile";

const doesNothing = () => {};

const returnView = "viewFlowPolicies";

function onAddPolicy(){
  rulesFlow.data.policies.addPolicy(returnView);
}

const ContextMenu = ({ doLoad = doesNothing, doAction = doesNothing }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={onAddPolicy}>Add Policy...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export const PoliciesList = () => {
  const [request, setRequest] = useState(null);

  const doRequest = () => ifCl.run("show policy flow statistics web").then(response => ({response}))

  const doLoad = () => {
    setRequest(doRequest());
  };

  const enableViewReload = (main) => {
    if (main === null || main.current === null) {
      return;
    }
    const view = main.closest(`#${returnView}`);
    $(view).one("view:show", () => doLoad());
    console.log("enabled reload on show", main);
  };
  const assignMain = useCallback(enableViewReload, []);

  useEffect(() => doLoad(), []);

  return request === null ? null : (
    <div className="row clearfix" ref={enableViewReload}>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="card">
          <div className="header block-header">
            <h2>Flow Policies</h2>
            <ContextMenu doLoad={doLoad} />
          </div>
          <div className="body table-responsive">
            <ActionsRow doCreatePolicy={onAddPolicy} />
            <Request during={request}>
              {(result) => <ShowPolicies {...result} reload={doLoad} />}
            </Request>
          </div>
        </div>
      </div>
    </div>
  );
};

const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

/*****************************************************
 * Policies Table
 * ****************************************************/

const flowPolicyActions = ([name, ...row], whenDone) => [
  {
    id: "edit-config",
    label: "Edit",
    icon: "edit",
    onClick: (_value, [name, _id, _source, ..._rest]) =>
      doEditPolicy(name, whenDone),
  }, ...( name === 'flow-default'
    ? []
    : [{
        id: "delete-config",
        label: "Delete",
        icon: "delete",
        onClick: (_value, [name, _id, _source, ..._rest]) =>
          doDeletePolicy(name, whenDone),
      }]
 )
];
const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const optionallyBlocking = (dpiEnabled) =>
  dpiEnabled
    ? [
        Column.Text({
          label: "BLOCK",
          idx: 1,
        }),
      ]
    : [];

const optionallyCompression = () =>
  functionality.isEnabled("compression")
    ? [
        Column.Text({
          label: "COMPRESS",
          idx: 3,
        }),
      ]
    : [];

const mutableOptimization = () =>
  login.isPlatform("cambium")
    ? [
        Column.Value((d) => (d === "no" ? "no" : "yes"), {
          label: "TCPO",
          idx: 2,
        }),
      ]
    : [
        Column.NumberOrYesNo({
          label: "TCPO",
          percent: true,
          idx: 2,
          colClassName: "align-right"
        }),
      ];


const openThroughputPerPolicy = (policyType='flow', directions='downSpeed') => {
  views.doKeep(returnView)
  globalNavigate('viewThroughputPerPolicy', {policyType, directions, returnView});
}

const hideRatesCensorLabels = new Set([
  "BLOCK",
  "SHAPE-SUBS-DN",
  "SHAPE-SUBS-UP",
  "SHAPE-FLOW-DN",
  "SHAPE-FLOW-UP",
  "PRIO",
  "SKIP-QUOTA",
  "SKIP-RATE",
  "SKIP-SUBGR",
])

const getColumns = ({hideRates=false, dpiEnabled=false}, {reloadWhenDone=doesNothing}) => [
  Column.Text({
    label: "NAME",
    idx: 0,
    cellClassName: hideRates ? null : "hyperlink-text",
    onClick: hideRates ? null : doEditPolicy,
  }),
  Column.NumberOrNA({
    label: "FLOWS",
    idx: 15,
    precision: 0,
    cellClassName: "hyperlink-text align-right",
    onClick: navigateToFlowsPerSubscriber,
  }),
  Column.NumberOrNA({
    label: "MBYTES-DN",
    idx: 16,
    precision: 3,
    cellClassName: "align-right hyperlink-text",
    onClick: () => {
      openThroughputPerPolicy('flow', 'downSpeed');
    },
  }),
  Column.NumberOrNA({
    label: "MBYTES-UP",
    idx: 17,
    precision: 3,
    cellClassName: "align-right hyperlink-text",
    onClick: () => {
      openThroughputPerPolicy('flow', 'upSpeed');
    },
  }),
  ...someColumnsMayBeCensored(hideRates ? hideRatesCensorLabels : null)(
    ...optionallyBlocking(dpiEnabled),
    ...mutableOptimization(),
    ...optionallyCompression(),
    Column.SpeedOrNo({
      label: "SHAPE-SUBS-DN",
      idx: 4,
      colClassName: "align-right"
    }),
    Column.SpeedOrNo({
      label: "SHAPE-SUBS-UP",
      idx: 5,
      colClassName: "align-right"
    }),
    Column.SpeedOrNo({
      label: "SHAPE-FLOW-DN",
      idx: 6,
      colClassName: "align-right"
    }),
    Column.SpeedOrNo({
      label: "SHAPE-FLOW-UP",
      idx: 7,
      colClassName: "align-right"
    }),
    Column.Number({
      label: "PRIO",
      idx: 8,
      colClassName: "align-right",
    }),
    Column.Text({
      label: "DROP",
      idx: 9,
    }),
    Column.Value(
      input => input === 'yes' ? 'no' : 'yes',
      {
        label: "SKIP-QUOTA",
        idx: 12,
      }),
    Column.Text({
      label: "SKIP-RATE",
      idx: 13,
    }),
    Column.Text({
      label: "SKIP-SUBGR",
      idx: 14,
    }),
  ),
  Column.Actions({
    label: "ACTIONS",
    idx: 0,
    are: hideRates ? [] : (source, row) => flowPolicyActions(row, reloadWhenDone),
    colClassName: "text-center action",
    cellClassName: "hides-content-to-operators",
  }),
]

const ShowPolicies = ({ response, reload = doesNothing }) =>  {
  const profile  = useProfile(); 
  return profile === null ? null : (<>
  <TableContainer>
    <DataTable
      pageLength={50}
      exportAsCSV={true}
      content={response}
      columns={getColumns(profile, {reloadWhenDone: reload})}
    />
  </TableContainer>
  <FlowPoliciesHelpModal />
</>
)
}

const doEditPolicy = (policy) => {
  ifCl
    .run(`show policy ${safeStr(policy)}`)
    .then((response) => {
      const ratePolicies = rulesFlow.data.policies;
      const policy = ratePolicies.parsePolicy(response);
      views.doKeep(returnView);
      ratePolicies.editPolicy(policy, returnView);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const doDeletePolicy = (name, whenDone = doesNothing) =>
  showModalConfirm(
    `Remove "${name}" policy?`,
    'WARNING: All policy data will be lost and all subscribers assigned to this policy will be reassigned to a default flow policy following the BQN configured rules (normally default policy is "flow-default").',
    "delete_forever",
    () => {
      ifCl
        .run(`clear policy flow ${safeStr(name)}`)
        .then(handleWarnings)
        .then(whenDone)
        .catch((error) => showModalError(error));
    }
  );

function navigateToFlowsPerSubscriber(_, [policy]){
  globalNavigate("viewFlowsPerSubs", {returnView: 'viewFlowPolicies', policy});
}

