import React, { useReducer, useEffect, useRef } from "react";
import styled from "styled-components";
import { onlyNumbersKeyHandler } from "./NumberInput";

const InputLayout = styled.div`
  align-items: middle;
  & .form-line {
  }
  & .form-line + .input-group-addon {
    padding-right: 10px;
  }
`;

const insideRange =
  (min = undefined, max = undefined) =>
  (value) =>
    min !== undefined && value < min
      ? min
      : max !== undefined && value > max
      ? max
      : value;

const roundTo = (precision = undefined) =>
  precision === undefined
    ? (value) => value
    : (value) =>
        +(Math.round(Number(value) + `e+${precision}`) + `e-${precision}`);

const numericStepper = (
  initial,
  step = 1,
  min = undefined,
  max = undefined,
  precision = undefined,
  backspaceValue
) => {
  const inRange = insideRange(min, max);
  const inPrecision = roundTo(precision);
  const fitting = (value) => inPrecision(inRange(value));
  return [
    function reducer(state, action) {
      switch (action.type) {
        case "update":
          return fitting(action.value);
        case "increase":
          return fitting(Number(state) + step);
        case "decrease":
          return fitting(Number(state) - step);
        case "reset":
          return backspaceValue ?? initial;
        default:
          throw Error("Unknown action.");
      }
    },
    initial,
  ];
};
const doesNothing = () => {};
const disabledStepper = (defaultValue) => [() => defaultValue, defaultValue];

const NumberInput = ({
  name,
  label = null,
  className = "",
  defaultValue = 0,
  disabled = false,
  units = null,
  step = 1,
  min = undefined,
  max = undefined,
  precision = undefined,
  onChange = doesNothing,
  onBlur = doesNothing,
  backspaceValue,
}) => {
  const target = useRef(null);
  const isFirst = useRef(true);
  const [value, dispatch] = useReducer(
    ...(disabled === false
      ? numericStepper(defaultValue, step, min, max, precision, backspaceValue)
      : disabledStepper(defaultValue))
  );
  const handleValueChange = (_event, value) => {
    dispatch({ type: "update", value });
  };
  const resetValue = () => dispatch({ type: "reset" });
  const doIncrease = () => dispatch({ type: "increase" });
  const doDecrease = () => dispatch({ type: "decrease" });
  useEffect(() => {
    if (target.current === null || isFirst.current === true) {
      isFirst.current = false;
      return;
    }
    target.current.value = value;
    onChange({ target: target.current });
  }, [value]);
  return (
    <InputLayout
      className={`input-group spinner margin-0 ${className}`}
      data-trigger="spinner"
    >
      {label === null ? null : (
        <span className="label-wrapper input-group-addon padding-0">
          {label}:
        </span>
      )}
      <div className="form-line">
        <input
          name={name}
          ref={target}
          type="text"
          className="form-control align-right min-width-45"
          disabled={disabled}
          data-precision="0"
          data-min={min}
          data-max={max}
          data-step="1"
          data-rule="quantity"
          defaultValue={value}
          {...onlyNumbersKeyHandler(handleValueChange)}
          onBlur={() => {
            if (precision && target.current.value.indexOf('.') !== -1) {
              target.current.value = Number(target.current.value).toFixed(
                precision
              );
            }
            onBlur();
          }}
        />
      </div>
      {units === null ? null : (
        <span className="input-group-addon">{units}&emsp;</span>
      )}
      <span className="input-group-addon">
        <a
          href="javascript:void(0);"
          onClick={doIncrease}
          className="spin-up"
          data-spin="up"
        >
          <i className="glyphicon glyphicon-chevron-up"></i>
        </a>
        <a
          href="javascript:void(0);"
          onClick={doDecrease}
          className="spin-down"
          data-spin="down"
        >
          <i className="glyphicon glyphicon-chevron-down"></i>
        </a>
      </span>
      <span className="input-group-addon">
        &nbsp;
        <a
          href="javascript:void(0);"
          onClick={resetValue}
          title="Reset Default"
        >
          <i className="material-icons">backspace</i>
        </a>
      </span>
    </InputLayout>
  );
};
export default NumberInput;
