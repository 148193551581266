import React from "react";

const doesNothing = () => {};

const DeleteClientButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const ClientItem = ({
  id,
  address = "",
  deleted,
  even = false,
  onDelete = doesNothing,
}) => {
  if (deleted === true) {
    return null;
  }
  const doClientDelete = () => {
    onDelete(id);
  };

  return (
    <React.Fragment>
      <tr className={`${even ? "even" : "odd"}`}>
        <td>{address}</td>
        <td className="text-center hides-content-to-operators">
          <DeleteClientButton onClick={doClientDelete} />
        </td>
      </tr>
    </React.Fragment>
  );
};
const ClientsList = ({ items = [], onDelete = doesNothing }) => (
  <table className="table table-striped">
    <thead>
      <tr>
        <th>ADDRESS</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {items
        .filter(({ deleted }) => deleted !== true)
        .map(({ __id, ...client }, index) => (
          <ClientItem
            id={__id}
            key={__id}
            {...client}
            even={(index + 1) % 2 === 0}
            onDelete={() => onDelete(__id)}
          />
        ))}
    </tbody>
  </table>
);
export default ClientsList;
