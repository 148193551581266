import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";

const goTo = (target) => globalNavigate(target.replace(/^#/, ""), {});
const StyledAnchor = styled.a`
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
`;

const Link = ({ href, children }) => (
  <StyledAnchor onClick={() => goTo(href)}>{children}</StyledAnchor>
);

export default Link;
