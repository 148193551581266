/*global nv*/

import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import LegendsList, {LegendsAndGraph, useSelection} from "./Legends";

const WrapperDIV = styled.div`
  & > svg {
    width: 100%;
    height: 100%;
    .nv-background rect {
      stroke: #000;
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    .nv-slice{
      path{
        cursor: pointer;
      }
      &.disabled path{
        cursor: initial;
      }
    }
    text.threshold-label {
      text-anchor: end;
      dominant-baseline: text-after-edge;
    }
`;
const formats = {
  noDecimals: d3.format(".0f"),
  oneDecimal: d3.format(".1f"),
  twoDecimals: d3.format(".2f"),
};

const defaultYAxisFormat = (d) =>
  d === 0 || d >= 10.0
    ? formats.noDecimals(d)
    : d >= 1.0
    ? formats.oneDecimal(d)
    : formats.twoDecimals(d);

const NVPieChart = ({
  className,
  items,
  tooltipContentGenerator,
  yAxisFormat = defaultYAxisFormat,
  yTooltipFormat = null,
  xFrom = (d) => d.label,
  yFrom = (d) => d.value,
  onClick = null,
  disabledElements = [],
  showLegend = false,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];

  let resizeObserver = null;
  let chart = null;
  useEffect(() => {
    const target = wrapper.current;
    nv.addGraph(function () {
      if (chart === null) {
        chart = nv.models
          .pieChart()
          .x(xFrom)
          .y(yFrom)
          .showLegend(showLegend) // Display pie labels
          .labelThreshold(0.05) // Minimum slice size for labels to show up
          .labelType((d) => xFrom(d.data))
          .donut(true) // Turn on Donut mode
          .donutRatio(0.35) // Donut hole size ratio
          .growOnHover(false)
          .valueFormat(yAxisFormat)
          .noData(NO_DATA_AVAILABLE);
        chart.tooltip.enabled(true);
        chart.tooltip.hideDelay(0);
        chart.tooltip.gravity("w"); // 's', 'n', 'w', 'e'
        if (typeof tooltipContentGenerator === "function") {
          chart.tooltip.contentGenerator(tooltipContentGenerator);
        } else if (yTooltipFormat !== null) {
          chart.tooltip.valueFormatter(yTooltipFormat);
        }
      }
      d3.select(canvas.current).datum(items);
      const updateChart = () => {
        const legends = d3.select(canvas.current).select(".nv-legendWrap").node();
        const legendDimmensions = legends.getBBox();
        typeof chart.update === "function" && chart.update();
        const height = Math.max(150, canvas.current.querySelector('.nv-pieWrap').getBBox().height);
        const requiredHeight = (height + legendDimmensions.height + 20 );
        const available = canvas.current.getBoundingClientRect().height;
        Math.abs(available - requiredHeight) > 10
          ? canvas.current.setAttribute('height', requiredHeight)
          : null;
      }
      if (resizeObserver === null) {
        resizeObserver = new ResizeObserver(updateChart);
        resizeObserver.observe(target);
      }
      if (onClick !== null) {
        chart.pie.dispatch.on("elementClick", ({ color, data }) =>
          disabledElements.includes(data.name) ? null : onClick({ color, ...data })
        );
      }
      const main = d3.select(canvas.current);
      main.call(chart);
      main.selectAll('.nv-slice').attr('class', ({data}) =>
        onClick === null || disabledElements.includes(data.name)
          ? 'disabled nv-slice' : 'nv-slice'
      );
      return chart;
    });

    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  }, [wrapper, canvas, items]);
  useEffect(() => {
    //d3.selectAll('.nvtooltip').remove();
    return () => {
      console.log("nv-lineChart destroy");
      if (chart !== null) {
        chart.tooltip.hidden(true);
      }
    };
  }, []);
  return (
    <WrapperDIV ref={wrapper} className={className}>
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

const NVPieChartWithLegends = ({ items, onClick, className, previousLegends=[], ...rest }) => {
  const [selection, doToggle, doElect] = useSelection(items);
  return (
    <LegendsAndGraph className={className}>
      <LegendsList items={selection}  
       doToggle={doToggle} 
       doElect={doElect}
       previous={previousLegends} />
      <NVPieChart items={selection} {...rest} showLegend={false} onClick={onClick}/>
    </LegendsAndGraph>
  );
};

export default NVPieChartWithLegends;
