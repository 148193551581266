import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import List from "./List";

const Status = ({ provider }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    const doingRetrieval = provider().catch((error) => error);
    setRequest(doingRetrieval);
  };

  const onInterfaceClick = (action, params) =>
    actions.send(`show-interface-${action}`, params);

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", () => doLoad());
  }, []);
  return request === null ? null : (
    <Request during={request}>
      {({ wires }) => (
        <List items={wires} onInterfaceClick={onInterfaceClick} />
      )}
    </Request>
  );
};

export default Status;
