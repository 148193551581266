import React, { useState, useEffect, useContext } from "react";
import { parseParameters, composeApplyCommand } from "./api";
import ActionsContext from "common/ActionsContext";
import SettingsView from "common/SettingsView";
import SynSettings from "./SynSettings";
import DownlinkVolumeSettings from "./DownlinkVolumeSettings";
import useProfile from "common/hooks/useProfile";
import styled from "styled-components";

const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
   > div {
    width: 46%;
   }

   @media screen and (max-width: 1400px) {
    flex-direction: column;

    > div {
      width: 100%;
      margin-bottom: 40px;
     }
   }
`

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getParameters = () =>
  ifCl.run("show pkteng parameters").then(parseParameters);

const getDoSSettings = () => getParameters().catch(console.error);

const DoSContextMenu = ({ doLoad = doesNothing }) => {
  const [optionsEnabled, setOptionsEnabled] = useState(true);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("state-changed", (state) =>
        setOptionsEnabled(state.enabled === true)
      ),
    []
  );

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      {optionsEnabled === true ? (
        <li className="dropdown hidden-to-operators">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a onClick={() => actions.send("new-client")}>Add Client...</a>
            </li>
          </ul>
        </li>
      ) : null}
    </ul>
  );
};

const DoSConfig = ({ ...params }) => {
  const load = () => getDoSSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(() => composeApplyCommand(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={DoSContextMenu}
      title="DoS settings"
      titleClassName="case-sensitive"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowDoSSettings />
    </SettingsView>
  );
};

const ShowDoSSettings = ({ defaults, state, onStateUpdate = doesNothing }) => {
  const { isOperator } = useProfile() || {};
  const updateSettings = (update) => {
    onStateUpdate(update);
  };

  return (
    <div className="body">
      <div className="row">
        <SettingsContainer>
          <div>
            <SynSettings
              isOperatorProfile={isOperator}
              onChange={(update) => updateSettings({handshakeFailRateDown: update.handshakeFailRateDown, handshakeFailRateUp:update.handshakeFailRateUp})}
              defaults={defaults}
              state={state}
            />
          </div>
          <div>
            <DownlinkVolumeSettings
              isOperatorProfile={isOperator}
              onChange={(update) => updateSettings({volumeMinRate: update.volumeMinRate, volumeRateMult:update.volumeRateMult})}
              defaults={defaults}
              state={state}
            />
          </div>
        </SettingsContainer>
      </div>
    </div>
  );
};

export default DoSConfig;
