/* global login, license, window */
import React, { createContext, useEffect, useState } from "react";

const ProfileContext = createContext(null);

const userAndProfile = (row) => {
  const [name, profile] = row.trim(" ").split(/\s+/);
  return [name, profile];
};

export const findUserProfile = (user) => (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  const [_name, profile] =
    rows.map(userAndProfile).find(([name, _profile]) => user === name) || [];
  return profile || "unknown";
};
const _PKT_ENG_DEFAULTS = {
  hideRates: false,
  dpiEnabled: false,
};

//TODO: port license to it's own hook
const isBaseLicense = () => window.license.get("bta-base") !== undefined;

const isDPIEnabled = () => window.functionality.isEnabled("dpi-per-user");

const intoPktEngProps = (settings, row) => {
  const [field, value] = row.split(/:\s?/);
  return field === "Hide policy rate details for operator"
    ? {
        ...settings,
        hideRates: ( settings.isOperator === true && value === "yes" ),
      }
    : field == "DPI-per-user for operator"
    ? {
        ...settings,
        dpiEnabled:
          isBaseLicense() !== true &&
          isDPIEnabled() === true &&
          ( settings.isAdmin === true || value === "yes" ),
      }
    : settings;
};
const extendWithPktParamsGiven = (profile) => (input) => {
  const rows = input.trim("\n").split("\n");
  return rows.reduce(intoPktEngProps, { ..._PKT_ENG_DEFAULTS, ...profile });
};

const doRequestPktEngParams = () => ifCl.run("show pkteng parameters");

const doRequestUserProfile = (userName) =>
  ifCl.run("show users").then(findUserProfile(userName));

export const asProfileParams = (profile) => ({
  isOperator: profile === "operator",
  isAdmin: profile === "administrator",
});

const useAcquireProfile = () => {
  const [profile, setProfile] = useState(null);
  const [username, setUsername] = useState(
    (window.login && window.login.user) || null
  );
  useEffect(() => {
    username !== null &&
      doRequestUserProfile(username)
        .then(asProfileParams)
        .then((profile) =>
          doRequestPktEngParams().then(extendWithPktParamsGiven(profile))
        )
        .then(setProfile);
  }, [username]);
  return profile;
};
export const ProfileContextProvider = ({ children }) => {
  const profile = useAcquireProfile();
  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
