import React, { useEffect, useReducer } from "react";
import CreateIPRange from "./Create";
import ListIPRanges from "./List";
import { validateIPv4WithMask } from "common/api";

const doesNothing = () => {};

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;
const anyAddressCollisionWith = (__id, address) => (existing) =>
  existing.address === address && existing.__id !== __id;

const doVerification = (item, list) => {
  const { address, __id } = item;
  if (validateIPv4WithMask(address) !== true) {
    throw "Must be a valid IPv4 address with mask";
  }
  if (list.find(anyAddressCollisionWith(__id, address)) !== undefined) {
    throw "range address already exists.";
  }
};

function ipRangesReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.ipRange,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...ipRange }) =>
          __id !== action.id
            ? [{ __id, stored, ...ipRange }]
            : stored === true
            ? [{ __id, stored, ...ipRange, deleted: true }]
            : []
        ),
      };
    }
    case "unaddressged": {
      return {
        changed: true,
        unaddressged: action.enable,
        list,
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const IPRanges = ({ defaults = { list: [] }, onChange = doesNothing }) => {
  const [ipRanges, dispatch] = useReducer(ipRangesReducer, {
    changed: false,
    list: [...defaults.list],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createIPRanges = (ipRange) => {
    doVerification(ipRange, ipRanges.list);
    dispatch({
      type: "added",
      ipRange,
    });
  };
  const validateNewItem = (ipRange) => {
    doVerification(ipRange, ipRanges.list);
  };
  useEffect(() => {
    ipRanges.changed === true && onChange(ipRanges);
  }, [ipRanges]);

  return (
    <>
      <h2 className="card-inside-title">
        Bypass Packets in these IP Address Ranges
      </h2>
      <CreateIPRange doSave={createIPRanges} doValidate={validateNewItem} />
      <ListIPRanges items={ipRanges.list} onDelete={handleDeleteId} />
    </>
  );
};

export default IPRanges;
