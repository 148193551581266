import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import Request from "common/Request";
import NVLineChart from "common/graphs/NVLineChart";
import RangeSliderInput from "common/RangeSliderInput";
import ActionsContext from "common/ActionsContext";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import Charts from "./Charts";
import { getInRangeItems, getMetricDir, retrieveRange, parseStatsResponse, getSampleSize, hoursByTimePeriodMap, PERIOD_TO_LINES, safeStrStats, heatmapFormatMap, metricMap } from "../../Subscribers/Report/TimeEvolutionMetrics/utils";

const TileContainer = styled.div`
  min-height: 10cm;
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    min-height: inherit;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 2cm;
  }
  .input-group {
    max-width: 20ch;
  }
`;

const GraphHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
`;

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const timePeriodMap = {
  oneDay: 10,
  oneWeek: 10,
  oneMonth: 60,
  threeMonths: 60,
};

const tooltipUnitMap = {
  averageSpeed: "Mbps",
  activeFlows: "flows",
  flowsCreated: "flows/minute",
  latency: "ms",
  retransmissions: "%",
  congestion: "%",
  traffic: "%",
};

const yAxisUnitMap = {
  averageSpeed: "Mbps",
  activeFlows: "Flows",
  flowsCreated: "Flows-per-minute",
  latency: "ms",
  retransmissions: "% retransimissions",
  congestion: "% congestion",
  traffic: "% traffic at max speed",
};

const ReloadButton = styled.div`
  grid-column: -2 / span 1;
  text-align: right;
`;

const IconRefresh = styled.i`
  color: #999;
  &:hover {
    color: #000;
  }
  transition: all 0.5s;
`;

const returnView = "viewStatusSubscriberGroups";

export function SubscriberGroupsChartsReport({
  direction,
  subsGroupsToPlot,
  refreshSignal,
  metric,
  timePeriod,
  ipAddress,
  use
}) {
  const windowWidth= window.innerWidth;
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState();
  const [initialRange, setInitialRange] = useState();
  const fields = useRef();
  const subsIdRef = useRef(null);
  const yAxisUnits = yAxisUnitMap[metric];
  const POINTS_PER_TILE_CHART = windowWidth / 5;
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  const doLoad = () => {
    if (subsGroupsToPlot === null) { return null }
    setRequest(loadStatistics());
  };

  const openDashboard = (label) => {
    views.doKeep(returnView);
    const hasSubscriberGroupPrefix = label.match(/^\[sg\]/)

    if(hasSubscriberGroupPrefix){
      globalNavigate("viewStatusSubscriberGroups", {
        returnView,
        subscriberGroup: label.replace(/\[sg\]/, ''),
        direction, timePeriod, metric, ipAddress
      });
    }else if(label === ipAddress){
      globalNavigate("viewStatusSubscriberGroups", {
        direction, timePeriod, metric, addr: label
      });
    }else if(label === subsIdRef.current){
      globalNavigate("viewStatusSubscriberGroups", {
        direction, timePeriod, metric, subsId: label
      });
    }else{
      globalNavigate("viewStatusSubscriberGroups", {
        returnView,
        subscriberGroup: label,
        direction, timePeriod, metric, ipAddress
      });
    }
  };

  useEffect(() => {
    removeTooltips();
  }, [request]);

  useEffect(() => {
    return setRange(initialRange);
  }, [initialRange]);

  useEffect(() => {
    doLoad();
  }, [metric, direction, timePeriod, subsGroupsToPlot, use]);

  const loadStatistics = async () => {
    subsIdRef.current = null;

    const hours = hoursByTimePeriodMap[timePeriod];
    if (subsGroupsToPlot === null ) { 
      return Promise.resolve({fields: [], items: []})
    }
    const groupNames = subsGroupsToPlot.map(name => {
      return safeStrStats(name)
    });
    const metricDir = getMetricDir(metric, direction);

    if(groupNames.length===0){
      return Promise.resolve({fields: [], items: []})
    }

    let subsId = null;
    if(ipAddress && use === 'subsId'){
      try{
        const subsIdResult = await ifCl.run(`show api subscriber  ${ipAddress}`)
        const matchResult = subsIdResult.match(/Subscriber ID:\s+(\S+)\n/);
        if(matchResult && matchResult[1] !== 'n/a'){
          subsId = matchResult[1];
          subsIdRef.current = subsId;
        }
      }catch(error){}
    }

    return retrieveRange(hours).then(({ from, to }) => {
      setInitialRange({ from, to });

      const promises = [
        ifCl
        .run(
          `show statistics subscriber-groups name '${groupNames.join(",")}' ${metricDir} hours ${hours} interval ${
            timePeriodMap[timePeriod]
          }`
        )
      ]

      if(ipAddress){
        const target = subsId ? `id '"${subsId}"'`: `'${ipAddress}'`
        promises.push(
          ifCl.run(`show statistics subscribers ${metricDir} ${target} hours ${hours} interval ${
            timePeriodMap[timePeriod]
          }`))
      }

      return Promise.all(promises)
        .then(([groupResponse, subscriberResponse]) => {
          return subscriberResponse ? mergeColumns(subscriberResponse, groupResponse) : groupResponse
        })
        .then((response) => {
          return parseStatsResponse(response, metric, from, to, colorsSet, "groups")
        })

        .catch((error)=>showModalError("Error:", error));
    });
  }

  function mergeColumns(subscriberResponse, groupResponse){
    const [headSubscriber, ...linesSubscriber] = subscriberResponse.trim("\n").split("\n");
    const [headGroup, ...linesGroup] = groupResponse.trim("\n").split("\n");

    const prefixedGroups = headGroup.replace(/^\S+\s+/, '').split(/\s+/).map(name => `[sg]${name}`).join(' ')

    const headOutput = headSubscriber + ' ' + prefixedGroups
    const linesOutput = [];

    linesSubscriber.forEach((lineSubscriber, index) => {
      const line = lineSubscriber + linesGroup[index].replace(/^\S+\s+/, ' ')
      linesOutput.push(line);
    })

    return [headOutput, ...linesOutput].join('\n')
  }

  function removeTooltips() {
    for (const element of document.getElementsByClassName(
      "nvtooltip xy-tooltip"
    )) {
      element.remove();
    }
  }

  const formatUnit = (metric) => (value) =>
    value === null || value === undefined
      ? "n/a"
      : `${["averageSpeed"].includes(metric) ? value.toFixed(3) : value} ${
          tooltipUnitMap[metric]
        }`;

  return (
    <div className="subscriberGroups-chart">
      <GraphHeaderDiv>
        <small className="m-t-30">
          <code>right-click</code> in the lines inside the chart or in the labels for each subscriber-group to be inspected in the dashboard, or <code>left-click</code> on the heat-map labels.
        </small>
        <ReloadButton className="context-menu header-dropdown m-r--5 button-reload-subs-groups-table">
          <a
            onClick={() => {
              doLoad();
            }}
            data-toggle="cardloading"
            data-loading-effect="pulse"
            title="Refresh"
          >
            <IconRefresh className="material-icons">refresh</IconRefresh>
          </a>
        </ReloadButton>
      </GraphHeaderDiv>

      <TileContainer>
        <div className="graph">
          {request === null ? null : (
            <Request during={request}>
              {({ items, fields, limits: initLimits }) => {
                const selectedRange = range || initialRange;
                const sampleSize = getSampleSize(
                  selectedRange,
                  POINTS_PER_TILE_CHART,
                  timePeriod
                );
                const { items: inRangeItems, limits, sampledValues } = getInRangeItems(
                  items,
                  selectedRange,
                  fields,
                  sampleSize,
                  initLimits
                );
                return (
                  <Charts
                    inRangeItems={inRangeItems}
                    yAxisUnits={yAxisUnits}
                    tooltipUnits={tooltipUnitMap[metric]}
                    xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                    yTooltipFormat={formatUnit(metric)}
                    fields={fields}
                    onSeriesInspect={(key) => openDashboard(key)}
                    rangeY={
                      limits?.isZeroOrNull ? [0, 1] : [0, limits ? limits.max : 1]
                    }
                    sampledValues={sampledValues}
                    heatmapFormat={heatmapFormatMap[metric]}
                    metric={metricMap[metric]}
                  />
                );
              }}
            </Request>
          )}
        </div>
      </TileContainer>
      <TileContainer className="full-width can-compress margin-t-20 margin-b-30" style={{paddingLeft: 190, marginRight: -15}}>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </TileContainer>
    </div>
  );
}
