/* global showModalInfo, views, components */
import React, { useState, useEffect, useContext, useRef } from "react";
import ListSubscriberGroups from "./ListSubscriberGroups";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import { getSubscriberGroups } from "../api/getSubscriberGroups";
import { SubscriberGroupsChartsReport } from "./SubscriberGroupsChartsReport";
import { validateIPv4IPv6, safeStr } from "common/api";
import {
  doUpdateShaping,
  validate as validateShaping,
} from "../../SubscriberGroupsEdit/Shaping";
import UpdateShapingValueModal from "../../SubscriberGroupsEdit/Shaping/Update";
import useFirstTime from "common/hooks/firstTime";
import useDebouncer from "common/hooks/debouncer";

const applyShaping =
  (onSaveDone = doesNothing) =>
  ({ field, value, subscriberGroup }) =>
    doUpdateShaping({ subscriberGroup, direction: field, value }).then(
      onSaveDone
    );

const subsGroupsToPlotLimit = 50;

const createPlaceholder = () =>
  `SUBSCRIBER-GROUP         SUBS-ACTIVE  FL-ACTIVE FL-CREATED         MBYTES  CURR-Mbps  MEAN-Mbps    RTT-ms      RTX MAX-SPEED  CONGEST   LIFETIME`;

const ensurePlaceholder = () => () => {
  return { response: createPlaceholder() };
};

const allSubscribersPlaceholder = `
Permanent direct subscriber-groups: n/a
Non-permanent direct subscriber-groups: all-subscribers
Permanent indirect subscriber-groups: n/a
Non-permanent indirect subscriber-groups: n/a
`;

const missingIPsBelongToAllSubscribers = (error) => {
  if (error.match(/ERR-EINVAL: Cannot find ".+" subscriber/) !== null) {
    return allSubscribersPlaceholder;
  }
  throw error;
};

const openSubscriberGroupDashboard = (subscriberGroup, settings) => {
  views.doKeep("viewStatusSubscriberGroups");
  globalNavigate("viewStatusSubscriberGroups", {
    returnView: "viewStatusSubscriberGroups",
    subscriberGroup,
    ...settings,
  });
};

const openSubscriberGroupEdit = (subscriberGroup, settings) => {
  globalNavigate("viewStatusSubscriberGroupsEdit", {
    returnView: "viewStatusSubscriberGroups",
    subscriberGroup,
    ...settings,
  });
};

function openRatePolicy(name, assignment, settings) {
  console.warn('openRatePolicy', name, assignment, settings) ||
  ifCl
    .run(`show policy ${safeStr(name)}`)
    .then((response) => {
      const policy = rulesRate.data.policies.parsePolicy(response);

      return assignment === "assigned"
        ? rulesRate.data.policies.showDynPolicy(policy, response, true)
        : assignment === "configured"
        ? rulesRate.data.policies.editPolicy(
            policy,
            "viewStatusSubscriberGroups",
            settings
          )
        : assignment === "undefined"
        ? offerDefineIfNotFound(name, settings)
        : null;
    })
    .catch(() => offerDefineIfNotFound(name, settings));
}

function offerDefineIfNotFound(name) {
  confirmCreatePolicy()
    .then(() => {
      rulesRate.data.policies.addPolicy(
        "viewStatusSubscriberGroups",
        name,
        settings
      );
    })
    .catch(() => null);
}

function confirmCreatePolicy() {
  return new Promise((resolve, reject) =>
    showModalConfirm(
      `Confirm before continue`,
      `Warning: This policy is undefined, and has been assigned to some subscribers or subscriber-groups. Those subscribers are now getting a default policy from the configured rules, while thos subscriber-groups are getting no rate limitation. If you click "Define", you can now define this policy and then the assigned subscribers and groups will get it.`,
      "warning",
      () => resolve(),
      () => reject(),
      "DEFINE",
      "RETURN"
    )
  );
}

const asListOfNames = ({ name }) => name;
const Report = (settings) => {
  const actions = useContext(ActionsContext);
  const { direction, timePeriod, metric, ipAddress, use } = settings;
  const isFirstTime = useFirstTime();
  const debounce = useDebouncer(2100 /*ms*/);
  const [selected, setSelected] = useState(null);

  const updateSelected = (newSelection) => {
    const assign = () => {
      if (newSelection.length > subsGroupsToPlotLimit) {
        showModalInfo(
          `Cannot select more than ${subsGroupsToPlotLimit} subscriber groups to plot. De-select some subscriber groups to select new ones.`
        );
        return;
      }
      setSelected(newSelection.map(asListOfNames));
    };
    isFirstTime() ? assign() : debounce(assign);
  };
  const [subscriberGroupsRequest, setSubscriberGroupsRequest] = useState(null);
  const [showCreateSubscriberGroupModal, setShowCreateSubscriberGroupModal] =
    useState(false);
  const refreshSignal = useRef(false);
  const permanentSubsGroups = useRef([]);
  const nonPermanentSubsGroups = useRef([]);

  useEffect(
    () =>
      actions.recv(
        "subscriber-group-edit-clicked",
        ({ subscriberGroup }) => {
          openSubscriberGroupEdit(subscriberGroup, settings);
        },
        true
      ),
    [settings]
  );

  useEffect(
    () =>
      actions.recv(
        "subscriber-group-policy-rate-clicked",
        ({ policyName, assignment }) => {
          openRatePolicy(policyName, assignment, settings);
        }
      ),
    [settings]
  );

  useEffect(() => {
    doLoad();
  }, [direction, ipAddress]);

  const doLoad = () => {
    setSubscriberGroupsRequest(doSubscriberMetricsRequest());
  };

  useEffect(() => {
    return actions.recv("do-load", () => {
      doLoad();
    });
  }, [direction, ipAddress]);

  useEffect(
    () =>
      actions.recv("create-group", () =>
        setShowCreateSubscriberGroupModal(true)
      ),
    []
  );

  const parseSubsGroupsNames = (input) => {
    const lines = input.trim("\n").split("\n");
    const names = lines.map((line) => line.replace(/\s.*$/, ""));
    return { names };
  };

  const parseSubsGroupsFromSubscriberResponse = (input) => {
    const lines = input.trim("\n").split("\n");
    let output = [];
    const subsGroupSet = new Set();
    nonPermanentSubsGroups.current = [];
    permanentSubsGroups.current = [];

    for (const line of lines) {
      const isNonPermanentSubsGroupsLine =
        line.search(
          /Non-permanent direct subscriber-groups:|Non-permanent indirect subscriber-groups:/
        ) !== -1;
      const isPermanentSubsGroupsLine =
        line.search(
          /Permanent direct subscriber-groups:|Permanent indirect subscriber-groups:/
        ) !== -1;

      if (isNonPermanentSubsGroupsLine || isPermanentSubsGroupsLine) {
        const words = line.split(/\s+/);
        for (const subsGroup of words
          .slice(3)
          .filter((word) => word !== "n/a")) {
          if (!subsGroupSet.has(subsGroup)) {
            subsGroupSet.add(subsGroup);
            output.push(subsGroup);

            if (isNonPermanentSubsGroupsLine) {
              nonPermanentSubsGroups.current.push(subsGroup);
            } else if (isPermanentSubsGroupsLine) {
              permanentSubsGroups.current.push(subsGroup);
            }
          }
        }
      }
    }

    return output;
  };

  const doSubscriberMetricsRequest = async () => {
    let subscriberGroupsCommand;

    if (ipAddress) {
      if (ipAddress.length === 0 || validateIPv4IPv6(ipAddress) === true) {
        subscriberGroupsCommand = `show api subscriber ${ipAddress} subscriber-group`;
      } else {
        subscriberGroupsCommand = `show api subscriber-id name ${safeStr(
          ipAddress
        )} subscriber-group`;
      }
    }

    try {
      let subsGroups = [];
      const allSubscribersGroup = "all-subscribers";
      if (subscriberGroupsCommand) {
        try {
          const subsGroupsResponse = await ifCl.run(subscriberGroupsCommand);
          subsGroups = parseSubsGroupsNames(subsGroupsResponse).names;

          if (!subsGroups.includes(allSubscribersGroup)) {
            subsGroups.unshift(allSubscribersGroup);
          }
        } catch (e) {
          subsGroups = [allSubscribersGroup];
        }
      }

      const response = await ifCl.run(
        `show subscriber-group all metrics-${direction}`
      );

      if (subsGroups.length > 0) {
        const lines = response.split("\n");
        const filteredRows = [
          lines[0],
          ...lines.filter((line) => subsGroups.includes(line.split(/\s+/)[0])),
        ];
        return {
          response: filteredRows.join("\n"),
        };
      } else {
        return { response };
      }
    } catch (e) {
      return ensurePlaceholder();
    }
  };

  function editSubscriberGroup(subscriberGroup) {
    globalNavigate("viewStatusSubscriberGroupsEdit", {
      returnView: "viewStatusSubscriberGroups",
      subscriberGroup,
    });
  }

  return subscriberGroupsRequest === null ? null : (
    <>
      <UpdateShapingValueModal
        doValidate={validateShaping}
        doSave={applyShaping(doLoad)}
      />
      <SubscriberGroupsChartsReport
        direction={direction}
        timePeriod={timePeriod}
        metric={metric}
        subsGroupsToPlot={selected}
        refreshSignal={refreshSignal.current}
        ipAddress={ipAddress}
        use={use}
      />
      <ListSubscriberGroups
        request={subscriberGroupsRequest}
        onPlotSelection={updateSelected}
        direction={direction}
        permanent={permanentSubsGroups.current}
        nonpermanent={nonPermanentSubsGroups.current}
        onRemoved={() => doLoad()}
        timePeriod={timePeriod}
        metric={metric}
      />
      <CreateSubscriberGroupModal
        open={showCreateSubscriberGroupModal}
        onOk={editSubscriberGroup}
        onClose={() => setShowCreateSubscriberGroupModal(false)}
      />
    </>
  );
};

const SubscriberGroupName = ({ defaultValue = "", onChange }) => (
  <div className="form-group margin-b-0">
    <label>Subscriber Group Name:</label>
    <div className="input-group margin-b-0">
      <span className="input-group-addon">
        <i className="material-icons">location_on</i>
      </span>
      <div className="form-line">
        <input
          type="text"
          name="name"
          className="form-control align-left"
          autoFocus
          onChange={(event) => onChange(event.target.value)}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  </div>
);

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const doesNothing = () => {};
const CreateSubscriberGroupModal = ({
  open,
  onOk = doesNothing,
  onClose = doesNothing,
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);

  if (open === false) {
    return null;
  }

  const checkWontCollide = ({ name }) =>
    getSubscriberGroups().then((others) => {
      if (others.includes(name)) {
        throw "This subscriber group already exist.";
      }
      return { name };
    });

  const validateName = ({ name }) => {
    if (name.length === 0) {
      throw "Invalid name: can't be empty";
    }
    if (name.length > 63) {
      throw "Invalid name: can't exceed 63 characters";
    }
    if (name.includes(" ")) {
      throw "Invalid name: can't contain whitespaces";
    }
    return { name };
  };
  const submit = ({ name }) => {
    onOk(name);
    onClose();
  };

  const reportError = (error) => {
    setError(error);
    throw error;
  };

  const doValidate = () => {
    setError(null);
    const currently = getFormData(form.current);
    return Promise.resolve(currently)
      .then(validateName)
      .then(checkWontCollide)
      .catch(reportError);
  };

  const doApply = () => {
    doValidate().then(submit);
  };

  const handleChange = () => doValidate();
  return open === true ? (
    <Modal
      title="Create subscriber group"
      superIcon="add_circle"
      content={() => (
        <form ref={form}>
          <SubscriberGroupName onChange={handleChange} />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  ) : null;
};

export default Report;
