/* global functionality */

import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import useProfile from "common/hooks/useProfile";

const TotalVolumeDPIHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  const profile = useProfile() || {};
  const areClientsInspectable = profile.dpiEnabled === true;
  const targetLabel = areClientsInspectable
    ? "main clients and servers"
    : "main servers";

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="DPI TOTAL STATISTICS"
      onClose={doClose}
      content={() => (
        <>
          <p>
            {" "}
            Total traffic by destination category. Please note that these
            calculations come from a sample of all downloads, so the volume
            numbers for each hour do not reflect the total volume downloaded.
          </p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month.
            </li>
            <li>
              <i className="material-icons valign-icon">format_line_spacing</i>{" "}
              Selects the number of categories displayed in the chart.
            </li>
            <li>
              <i className="material-icons valign-icon">import_export</i>{" "}
              Selects the direction of traffic (Uplink, Downlink or Both).
            </li>
            <li>
              <i className="material-icons">call_split</i> Select &ldquo;All
              UDRs&ldquo; to show the DPI data from all DPI records. Select
              &ldquo;Only auto UDRs&ldquo; to show the data for only those DPI
              records that are produced with automatic sampling, excluding DPI
              records produced by monitoring policies.
            </li>
            <li className="f-dpi-per-user">
              IP-Addr/Subs-Id. When present, filters the output for the
              specified IP address (range) or subscriber Id. Click{" "}
              <code>Filter</code> button to update chart.
            </li>
            <li className="f-dpi-category-inspection">
              <i className="material-icons valign-icon">insert_chart</i>
              Click on any of the areas of the pie-chart to see the main{" "}
              <span id="inspectableCategoriesHelp">{targetLabel}</span> of that
              content category
            </li>
          </ul>
          <p>
            Click on <i className="material-icons valign-icon">refresh</i> icon
            to refresh information.
          </p>
        </>
      )}
    />
  );
};
export default TotalVolumeDPIHelpModal;
