import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { NVLineChart } from "common/graphs/NVLineChart";
import HeatMap from "common/graphs/HeatMap/HeatMap";
import Legend from "common/graphs/Legend";
import { threeSignificantDigitsFormat, unitToPrecisionMap } from "common/utils";
import { ColorLegendVertical } from "common/graphs/HeatMap/ColorLegendVertical";
import { tooltipContentGeneratorForUnits, getToppedValue } from "../../Subscribers/Report/TimeEvolutionMetrics/utils";
import { getDataValues } from "common/graphs/HeatMap/utils";

const CHART_HEIGHT = 380;
const CHART_MARGIN = { top: 20, right: 40, bottom: 30, left: 240 };
const Y_AXIS_TO_GAP = 0;

const ChartContainer = styled.div`
  height: ${(props) => props.height + "px"};
  position: relative;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const HeatMapContainer = styled.div`
  margin-top: 0px;
`;

const LegendContainer = styled.div`
  width: 100%;
  padding: 30px 0 0 240px;
`;

const ColorLegendContainer = styled.div`
  height: 330px;
  width: 40px;
  position: absolute;
  left: 100px;
  bottom: 30px;
`;

const HighlightThing = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 12px;
  background-color: ${(props) => props.color};
  left: ${(props) => props.xPos - 8 + "px"};
  top: ${(props) => props.yPos - 8 + "px"};
`;

const Charts = ({
  inRangeItems,
  yAxisUnits,
  fields,
  yTooltipFormat,
  xAxisFormat,
  onSeriesInspect,
  rangeY,
  tooltipUnits,
  sampledValues,
  heatmapFormat,
  metric,
}) => {
  const [newFields, setNewFields] = useState([]);
  const [legendFieldsSet, setLegendFieldsSet] = useState(new Set());
  const [colorScaleMax, setColorScaleMax] = useState();
  const [colorScaleMin, setColorScaleMin] = useState();
  const [hoveredCell, setHoveredCell] = useState(null);
  const [origValues, setOrigValues] = useState();
  const dataValues = getDataValues(inRangeItems, legendFieldsSet);
  const [min = 0, max = 0] = d3.extent(dataValues);
  const legendHeightWrapper =
    CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom;
  const legendHeight =
    legendHeightWrapper - legendHeightWrapper * Y_AXIS_TO_GAP;
  const yScaleLegend = d3
    .scaleLinear()
    .range([legendHeight, 0])
    .domain([0, max]);
  const yScaleLegendSpec = d3.scaleLinear().range([legendHeight, 0]).domain([colorScaleMin || 0, colorScaleMax || max]);
  const maxMax = colorScaleMax || max;
  const topGap = yScaleLegend(maxMax) - yScaleLegend(maxMax + maxMax * Y_AXIS_TO_GAP);
  const topGapSpec = yScaleLegendSpec(maxMax) - yScaleLegendSpec(maxMax + maxMax * Y_AXIS_TO_GAP);
  const yScaleLegendInverse = d3
    .scaleLinear()
    .range([max, 0])
    .domain([0, legendHeight]);
  const {
    yColor,
    xPosHighLight,
    value: highlightValue,
  } = hoveredCell || {};
  const yPosHightlight = highlightValue ? getToppedValue({value: Number(highlightValue),min: colorScaleMin, max:  colorScaleMax, yScale: yScaleLegendSpec, height: legendHeight, topGap: topGapSpec, totalHeight: legendHeightWrapper}) + CHART_MARGIN.top : null;

  useEffect(() => {
    if (fields && fields.length > 0) {
      const fieldsSet = new Set(fields.map(({ name }) => name));
      setLegendFieldsSet(fieldsSet);
      const newFields = fields.filter(({ name }) => fieldsSet.has(name));
      setNewFields(newFields);
    }
  }, [fields]);

  const nItems = inRangeItems ? inRangeItems.length : 0;

  useEffect(() => {
    setColorScaleMax(undefined);
    setColorScaleMin(undefined);
    setOrigValues(undefined);
  }, [nItems, metric]);

  const handleMinMaxValue = (value, type) => {
    setOrigValues({
      yScale: yScaleLegend,
      yScaleInverse: yScaleLegendInverse,
      min,
      max,
    });
    if (type === "max") {
      setColorScaleMax(value);
    } else {
      setColorScaleMin(value);
    }
  };

  return (
    <>
      <LegendContainer>
        <Legend
          fields={newFields}
          value={legendFieldsSet}
          onChange={(filterSet) => {
            setLegendFieldsSet(filterSet);
          }}
          onSeriesInspect={onSeriesInspect}
          itemWidth={120}
          margin={{ top: 0, right: CHART_MARGIN.right, bottom: 0, left: 0 }}
        />
      </LegendContainer>
      <ChartContainer height={CHART_HEIGHT}>
        {fields && fields.length > 0 ? (
          <ColorLegendContainer>
            <ColorLegendVertical
              width={10}
              height={legendHeight}
              min={origValues ? origValues.min : min}
              max={origValues ? origValues.max : max}
              yScale={origValues ? origValues.yScale : yScaleLegend}
              yScaleInverse={
                origValues ? origValues.yScaleInverse : yScaleLegendInverse
              }
              onMinChanged={(value) => handleMinMaxValue(value, "min")}
              onMaxChanged={(value) => handleMinMaxValue(value, "max")}
              precision={unitToPrecisionMap[yAxisUnits]}
              yAxisTopGap={Y_AXIS_TO_GAP}
              colorScaleMaxOverride={colorScaleMax}
              colorScaleMinOverride={colorScaleMin}
              tickFormat={(value) =>
                threeSignificantDigitsFormat(value, yAxisUnits)
              }
              interactionData={hoveredCell}
            />
          </ColorLegendContainer>
        ) : null}
        {hoveredCell !== null && hoveredCell.value !== undefined ? (
          <HighlightThing
            color={yColor}
            xPos={xPosHighLight}
            yPos={yPosHightlight}
          />
        ) : null}
        <NVLineChart
          showLegend={false}
          xField="time"
          items={inRangeItems}
          yAxisUnits={yAxisUnits}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          yAxisFormat={(value) =>
            threeSignificantDigitsFormat(value, yAxisUnits)
          }
          fields={newFields.filter(({ name }) => legendFieldsSet.has(name))}
          yAxisTopGap={Y_AXIS_TO_GAP}
          yAxisTicksNumber={5}
          yTooltipFormat={yTooltipFormat}
          onSeriesInspect={onSeriesInspect}
          margin={CHART_MARGIN}
          yMaxValue={colorScaleMax}
          yMinValue={colorScaleMin}
          tooltipContentGenerator={tooltipContentGeneratorForUnits(
            yAxisUnits,
            yTooltipFormat
          )}
        />
      </ChartContainer>
        <HeatMapContainer>
          <HeatMap
            margin={{
              top: 0,
              right: CHART_MARGIN.right,
              bottom: 15,
              left: CHART_MARGIN.left,
            }}
            fields={fields}
            legendSet={legendFieldsSet}
            items={inRangeItems}
            unit={tooltipUnits}
            setLegendFieldsSet={setLegendFieldsSet}
            onSeriesInspect={onSeriesInspect}
            precision={unitToPrecisionMap[yAxisUnits]}
            onMinChanged={(value) => setColorScaleMin(value)}
            onMaxChanged={(value) => setColorScaleMax(value)}
            colorScaleMaxOverride={colorScaleMax}
            colorScaleMinOverride={colorScaleMin}
            hideColorLegend={true}
            tickFormat={(value) =>
              threeSignificantDigitsFormat(value, yAxisUnits)
            }
            sampledValues={sampledValues}
            heatmapFormat={heatmapFormat}
            metric={metric}
            hoveredCell={hoveredCell}
            setHoveredCell={setHoveredCell}
          />
        </HeatMapContainer>
    </>
  );
};

export default Charts;
