/* global DPI_STATS_TMOUT_MS, views */
import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import DPILatencyHelpModal from "./Help";
import {
  expressLatencyTarget,
  expressDestination,
  parseServiceLatencies,
} from "./api";
import ReportContextControls from "./Controls";
import Request from "common/Request";
import Result from "./Result";
import { TitleWithNav } from "common/TitleWithNav";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const doLatenciesRetrieval = (
  target,
  hours = 72,
  categories = 10,
  destination = null,
  filter = null
) =>
  expressLatencyTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics dpi latency top hours ${hours} categories ${categories}\
     ${expressedTarget} \
     ${expressDestination(destination)} \
     ${filter === "only" ? "udrs-auto" : ""}`,

      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const retrieval = ({ target, hours, categories, destination, filter }) =>
  doLatenciesRetrieval(target, hours, categories, destination, filter)
    .then(parseServiceLatencies)
    .then((latencies) => ({ latencies }))
    .catch((error) => console.error(error) || error);

const ReportRequest = ({
  hours,
  filter,
  categories,
  destination,
  target,
  retrieval,
  ...rest
}) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);
  const doLoad = () => {
    const doingRetrieval = retrieval({
      hours,
      categories,
      target,
      destination,
      filter,
    });
    setRequest(doingRetrieval);
  };
  const context = {
    hours,
    categories,
    target,
    destination,
    filter,
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [hours, filter, categories, target, destination]);
  return request === null ? null : (
    <Request during={request}>
      {(result) => <Result {...result} {...rest} {...context} />}
    </Request>
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
  categories: 10,
  filter: "all",
  destination: null,
  target: { subsId: null, addr: null },
};
    
const Report = ({
  provider,
  actions,
  enabled,
  defaultContext = _defaultContext,
  ...rest
}) => {
  const [context, setContext] = useState(defaultContext);
  const updateContext = ({ hours, categories, filter, target, destination }) =>
    setContext((prev) => ({
      ...prev,
      ...(hours === undefined ? {} : { hours }),
      ...(categories === undefined ? {} : { categories }),
      ...(filter === undefined ? {} : { filter }),
      ...(destination === undefined ? {} : { destination }),
      ...(target === undefined ? {} : { target }),
    }));

  return (
    <>
      <ReportContextControls
        enabled={enabled}
        {...context}
        onChange={updateContext}
      >
        <ReportRequest
          {...context}
          retrieval={provider}
          actions={actions}
          {...rest}
        />
      </ReportContextControls>
    </>
  );
};

const DPILatency = ({ returnView = null, ...settings }) => {
  return (
    <div className="container-l g">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="card">
          <HeaderDiv className="header block-header">
            <TitleWithNav returnView={returnView}>
              <h2 className="title">Average internet latency per service</h2>
            </TitleWithNav>
            <ContextMenu />
          </HeaderDiv>
          <div className="body flex-panel-column">
            <Report
              defaultContext={{ ..._defaultContext, ...settings }}
              provider={retrieval}
              {...settings}
            />
          </div>
          <DPILatencyHelpModal />
        </div>
      </div>
    </div>
  );
};

export default DPILatency;
