import React, { useState, useRef } from "react";
import Fields from "./Fields";
import Modal from "common/Modal";

const doesNothing = () => {};

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const asSettings = (form, target = {}) => {
  const { timeout, size, pppoe, vlan, ...rest } = getFormData(form);
  return {
    ...target,
    ...rest,
    timeout: parseInt(timeout),
    size: parseInt(size),
    includePPPoE: pppoe === "on",
    includeVLAN: vlan === "on",
  };
};

function validateSettings({ filter, size, timeout, ...others }) {
  if (isNaN(size) || !size) {
    throw "Invalid size";
  }

  if (Number(size) < 1) {
    throw "Capture size must be greater than 1";
  }

  if (Number(size) > 500) {
    throw "Capture size must lower than 500";
  }

  if (isNaN(timeout) || !timeout) {
    throw "Invalid capture timeout";
  }

  if (Number(timeout) < 1) {
    throw "Timeout must be be at least 1 second";
  }

  if (Number(timeout) > 600) {
    throw "Timeout must be lower than 600 seconds";
  }

  if (filter && filter.search('"') !== -1) {
    throw "Filter does not allow double quotes";
  }

  if (filter && filter.length > 512) {
    throw "Filter too long";
  }

  return { filter, size, timeout, ...others };
}

const CaptureSettingsModal = ({
  target = {},
  onApply = doesNothing,
  doClose = doesNothing,
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const doApply = () => {
    Promise.resolve(asSettings(form.current, target))
      .then(validateSettings)
      .then(onApply)
      .catch(setError);
  };
  return (
    <Modal
      superIcon="search"
      title={`Capture packets in ${target.name}`}
      id="interface-status-capture"
      large={false}
      content={() => (
        <form ref={form}>
          <Fields onChange={clearError} />
        </form>
      )}
      applyLabel={"OK"}
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={doClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};
export default CaptureSettingsModal;
