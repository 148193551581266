import React, { useState } from "react";
import NVHorizontalBarChart, {
  formats,
} from "common/graphs/NVHorizontalBarChart";
import styled from "styled-components";

const UsageGraphLayout = styled.div`
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
  .centered {
    text-align: center;
  }
  .bars > div {
    height: ${({usageCount}) => (1 + usageCount) * 60 }px !important;
  }
`;

const getAspects = () => [
  {
    label: "Tx",
    color: "#1f77b4",
    field: "tx",
  },
  {
    label: "Rx",
    color: "#ffa500",
    field: "rx",
  },
];

const throughputByAspects = (usage = [], aspects) =>
  aspects.map(({ label, color, field }) => ({
    key: label,
    label,
    color,
    values: usage.map((usage) =>
      usage === null
        ? { label: "", value: new Number(undefined) }
        : {
            label: usage.name,
            value: new Number(usage[field]),
          }
    ),
  }));

const GroupedOrStached = ({ ...rest }) => {
  const [stacked, useStacked] = useState(false);
  const doSetStacked = () => useStacked(true);
  const doSetGrouped = () => useStacked(false);
  const controls = [
    {
      label: "Grouped",
      key: "Grouped",
      color: "#444444",
      doToggle: doSetGrouped,
      disabled: stacked === true,
      field: "grouped",
    },
    {
      label: "Stacked",
      key: "Stacked",
      color: "#444444",
      doToggle: doSetStacked,
      disabled: stacked !== true,
      field: "grouped",
    },
  ];
  return (
    <NVHorizontalBarChart
      prependLegends={controls}
      stacked={stacked}
      {...rest}
    />
  );
};

const Result = ({ usage = [] }) => {
  const aspects = getAspects();
  return (
    <UsageGraphLayout usageCount={usage.length}>
      <GroupedOrStached
        tooltipValueFormatter={(value) => `${formats.twoDecimals(value)} Mbps`}
        valueFormat={formats.twoDecimals}
        items={throughputByAspects(usage, aspects)}
        showRoundCorners={true}
        showColors={false}
        valuePadding={30}
      />
      <div className="centered">Mbps</div>
    </UsageGraphLayout>
  );
};

export default Result;
