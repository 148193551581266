const unitScale = (unit) =>
  unit === "G"
    ? 1
    : unit === "T"
    ? 1000
    : unit === "M"
    ? 0.001
    : unit === "k"
    ? 0.000001
    : 0;

const parseVolume = (input, defaultUnit = "M") => {
  const matches = input.match(/(\d+\.?\d*)([MGT])?/);
  if (matches === null) {
    return null;
  }
  const [_all, value, unit] = matches;
  return parseFloat(value) * unitScale(unit || defaultUnit);
};

const parseKeys = (head, translations = {}) =>
  head.split(/\s+/).map((key) => translations[key] || key.toLowerCase());

const memSectionsContents = (line) =>
  line.startsWith("Mem:") ? [line.replace(/Mem:\s+/, "")] : [];

const storeInFields =
  (keys, skipped = []) =>
  (result, line) =>
    Object.fromEntries(
      line
        .split(/\s+/)
        .flatMap((value, index) =>
          skipped.includes(keys[index])
            ? []
            : [[keys[index], parseVolume(value, "k")]]
        )
    );

export const parseSystemMemoryUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const translations = { free: "free", used: "used" };
  const keys = parseKeys(head.trim(" "), translations);
  const skipped = keys.filter(
    (key) => Object.values(translations).includes(key) === false
  );
  return rows
    .flatMap(memSectionsContents)
    .reduce(storeInFields(keys, skipped), {});
};

const parseFields =
  (keys, skipped = [], unit = undefined) =>
  (line) =>
    Object.fromEntries(
      line
        .trim(" ")
        .split(/\s+/)
        .flatMap((value, index) =>
          skipped.includes(keys[index])
            ? []
            : [
                [
                  keys[index],
                  unit === undefined
                    ? parseFloat(value)
                    : parseVolume(value, unit),
                ],
              ]
        )
    );

const sumUpPools = ({ size = 0, allocated = 0, used = 0 }, row) => ({
  size: size + row.size,
  allocated: allocated + row.allocated,
  used: used + row.used,
});

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

const calcFreeAndUsed = ({ size = 0, allocated = 0, used = 0 }) => {
  used *= size / allocated / 1024 / 1024;
  const total = size / 1024 / 1024;
  const free = total - used;
  return { used: fixedPrecision(used), free: fixedPrecision(free) };
};

export const parsePoolMemoryUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const translations = {
    POOLSIZEKB: "size",
    CHUNKALLOC: "allocated",
    CHUNKUSED: "used",
  };
  const keys = parseKeys(head.trim(" "), translations);
  const skipped = keys.filter(
    (key) => Object.values(translations).includes(key) === false
  );
  return calcFreeAndUsed(
    rows.map(parseFields(keys, skipped)).reduce(sumUpPools, {})
  );
};

const calcDPDKFreeAndUsed = ({ total, used }) => ({
  free: total - used,
  used,
});
const sumUpDPDKPools = ({ total = 0, used = 0 }, row) => ({
  total: total + row.total,
  used: used + row.used,
});
export const parseDPDKMemoryUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const translations = { "SIZE-USED-MB": "used", "SIZE-TOTAL-MB": "total" };
  const keys = parseKeys(head.trim(" "), translations);
  const skipped = keys.filter(
    (key) => Object.values(translations).includes(key) === false
  );
  return calcDPDKFreeAndUsed(
    rows.map(parseFields(keys, skipped, "M")).reduce(sumUpDPDKPools, {})
  );
};
