import React, { useReducer, useRef, useEffect } from "react";
import Datatable, { Column } from "./DataTable";
import styled from "styled-components";

const Wrapper = styled.div`
width: 100%;
position: relative;
`

const SelectButtonsWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 125px;
    font-size 12px;
    top: 0px;
    z-index: 12;

    button {
      margin: 0 !important;
    }

    button:first-child {
      margin: 0 -10px 0 10px !important;
    }

    @media (max-width: 900px) {
         left: -10px;
         top: 0px;
    }
`;

const PageRowsSelectionWrapper = styled.div`
    padding-top: 7px;
    @media (max-width: 900px) {
      padding-top: 50px;
    }
`;

const doesNothing = () => {};

const matchingTo = (target) => {
  const targetRepr = Object.entries(target).toString();
  return (current) => targetRepr === Object.entries(current).toString();
};

const selectionReducer = ({ ready, list = [] }, action) => {
  switch (action.type) {
    case "clear": {
      return {
        ready,
        list: [],
      };
    }
    case "fill": {
      return {
        ready,
        list: [...list, ...action.elements],
      };
    }
    case "fillpage": {
      return {
        ready,
        list: [...action.elements],
      };
    }
    case "ready": {
      return {
        ready: true,
        list,
      };
    }
    case "toggle": {
      const { target } = action;
      const foundAt = list.findIndex(matchingTo(target));
      return {
        ready,
        list:
          foundAt > -1
            ? list.filter((elem, idx) => idx !== foundAt)
            : [...list, target],
      };
    }
    case "select": {
      const { target } = action;
      const foundAt = list.findIndex(matchingTo(target));
      return {
        ready,
        list: foundAt > -1 ? list : [...list, target],
      };
    }
  }
  throw Error("Unknown action: " + action.type);
};

const asValue = (value) => ({ value });

const getDataTable = (tableBody) => $(tableBody.parentElement).DataTable();

const PersistentDatatable = React.memo(Datatable, (_prev, _next) => true);

const isOnTheFirst = limit => {
  let count = 0
  return () => {
    count += 1;
    return count <= limit;
  } 
} 

const PageRowsSelection = ({
  selectionClass,
  asObject = asValue,
  selected = [],
  select = doesNothing,
  clear = doesNothing,
  ready = doesNothing,
  selectFirst = false,
  children,
  fill,
  fillpage
}) => {
  const wrapper = useRef(null);
  const selectedRef = useRef(selected);

  const isSelected = (target) =>
    selectedRef.current.find(matchingTo(target)) !== undefined;

  const updateIcon = (row, selected) => {
    const selectionCheck = row.querySelector(
      `.action.selection .${selectionClass} i`
    );
    if (selectionCheck === null) {
      return;
    }
    selectionCheck.innerText = selected
      ? "check_box"
      : "check_box_outline_blank";
  };

  const enableHeader = (elements=[], someSelected=false) => {
    const tableHead = wrapper.current.querySelector("thead");
    const header = tableHead.querySelector(`tr th.${selectionClass}`);
    const actionButton = header.querySelector('.action');
    const selectButtons = wrapper.current.querySelector('.select-buttons');
    const selectAllBulkButton = wrapper.current.querySelector('.select-all');
    const selectNoneBulkButton = wrapper.current.querySelector('.select-none');
    if (selectAllBulkButton !== null) {
      selectAllBulkButton.remove();
    }
    if (selectAllBulkButton !== null) {
      selectNoneBulkButton.remove();
    }
    selectButtons.innerHTML = `<button class="btn btn-link waves-effect select-all">
            Select all to plot
          </button>
          <button class="btn btn-link waves-effect select-none">
            Deselect all to plot
          </button>`;
    const controla = selectButtons.querySelector('.select-all');
    controla.addEventListener("click", () => fillpage(elements));
    const controlb = selectButtons.querySelector('.select-none'); 
    controlb.addEventListener("click", () => clear());
    if (actionButton !== null) {
      actionButton.remove();
    }
    if (!someSelected) {
      header.innerHTML =
        header.innerHTML +
        `
      <a class="action ${selectionClass}-all-fill" title="select all"
        data-toggle="tooltip" data-placement="top">
        <i class="material-icons actions-icon-color">check_box_outline_blank</i>
      </a>`;
      const control1 = header.querySelector(`a.${selectionClass}-all-fill`);
      control1.addEventListener("click", () => fill(elements));
    } else {
        header.innerHTML =
        header.innerHTML +
        `
        <a class="action ${selectionClass}-all-delete" title="de-select all"
          data-toggle="tooltip" data-placement="top">
          <i class="material-icons actions-icon-color">indeterminate_check_box</i>
        </a>`;
      const control2 = header.querySelector(`a.${selectionClass}-all-delete`);
      control2.addEventListener("click", () => clear());
    }
  };

  const forAllRowsAndDataDo = (callback) => {
    const tableBody = wrapper.current.querySelector("tbody");
    const datatable = getDataTable(tableBody);
    const getRowDataRow = (row) => datatable.row(row).data();
    tableBody
      .querySelectorAll("tr")
      .forEach((row) => {
        const data = getRowDataRow(row);
        data !== undefined && callback(row, asObject(data.__source__ || data));
      });
  };

  const updateRows = () => {
    let elements = [];
    let someSelected = false;
    forAllRowsAndDataDo((row, data) => {
      if(isSelected(data)){
        someSelected = true;
      }
      elements.push({ ...data });
      updateIcon(row, isSelected(data));
    });
    enableHeader(elements, someSelected);
  };

  const selectCurrentRows = (selection) => {
    const thisToo = selection === true
      ? () => true //first whole page
      : isOnTheFirst(selectFirst) //first N elements
    forAllRowsAndDataDo((row, data) => thisToo() && select(data));
    enableHeader([], selectFirst ? true : false);
  };

  useEffect(() => {
    if (wrapper.current === null) {
      return;
    }
    selectedRef.current = selected;
    updateRows();
  }, [selected]);

  useEffect(() => {
    if (wrapper.current === null) {
      return;
    }
    const tableBody = wrapper.current.querySelector("tbody");
    const datatable = getDataTable(tableBody);
    datatable.on("draw", updateRows);
    selectFirst !== false
      ? selectCurrentRows(selectFirst) // will trigger update later
      : updateRows();
    ready();
  }, [wrapper]);

  return (
    <Wrapper ref={wrapper}>
      <SelectButtonsWrapper className="select-buttons" />
      <PageRowsSelectionWrapper>
        {children}
      </PageRowsSelectionWrapper>
    </Wrapper>
  );
};

const SelectionColumn = ({
  field,
  label,
  onCellRender = doesNothing,
  idx = 0,
  ...rest
}) => ({
  ...Column.Value(
    () => `
        <a class="action selection-${field}" title="${label}"
          data-toggle="tooltip" data-placement="top">
          <i class="material-icons actions-icon-color">check_box_outline_blank</i>
        </a>
      `,
    {
      idx,
      label,
      ...rest,
    }
  ),
  exportable: false,
  idx,
  onCellRender,
});

const SelectableTable = ({
  asObject = asValue,
  columns = [],
  onSelection = doesNothing,
  selectionLabel,
  selectionField,
  selectFirst = false,
  paginator = undefined,
  ...params
}) => {
  const [selection, dispatch] = useReducer(selectionReducer, {
    ready: false,
    selected: [],
  });
  const toggle = (target) => dispatch({ type: "toggle", target });
  const select = (target) => dispatch({ type: "select", target });
  const clear = () => dispatch({ type: "clear" });
  const fill = (elements) => dispatch({ type: "fill", elements});
  const fillpage = (elements) => dispatch({ type: "fillpage", elements});
  const ready = () => dispatch({ type: "ready" });
  React.useEffect(() => {
    selection.ready === true && onSelection(selection.list);
  }, [selection]);

  const selectionClass = `selection-${selectionField}`;
  const selectionColumn = SelectionColumn({
    idx: 0,
    colClassName: `text-center action selection ${selectionClass}`,
    label: selectionLabel,
    field: selectionField,
    onClick: (_value, row) => toggle(asObject(row)),
  });
  const allColumns = [...columns, selectionColumn];


  return (
    <PageRowsSelection
      selectionClass={selectionClass}
      asObject={asObject}
      selected={selection.list}
      select={select}
      clear={clear}
      ready={ready}
      fill={fill}
      fillpage={fillpage}
      selectFirst={selectFirst}
      {...params}
    >
      <PersistentDatatable
        columns={allColumns}
        className="selectable-table"
        paginator={paginator //parsed paginator data has to be extended
          ? paginator.injectColumn(selectionColumn, null)
          : undefined}
        {...params}
      />
    </PageRowsSelection>
  );
};

export default SelectableTable;

export { Column };