/* global DPI_STATS_TMOUT_MS */
import React, { useContext } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/TotalVolume/component";
import ActionsContext from "common/ActionsContext";
import DPITotalVolumeHelpModal from "./Help";
import CategoryDetailsModal from "../CategoryDetails";
import { expressTarget } from "common/api";
import { parseVolumesAddTotals } from "../api";
import * as d3 from "d3";
import useProfile from "common/hooks/useProfile";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;
const wholeInterval = (hours, base=60) =>
  hours * base;

const doSpeedTotalsRetrieval = (
  target,
  hours = 72,
  categories = 20,
  directions = "all",
  filter = "all"
) =>
  expressTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics dpi volume ${expressedTarget} hours ${
        hours} interval ${wholeInterval(hours)} categories ${
        categories - 1
      } direction ${directions} ${filter === "auto" ? "udrs-auto" : ""}`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const retrieval = ({ target, hours, categories, directions, filter }) =>
  doSpeedTotalsRetrieval(target, hours, categories, directions, filter)
    .then(parseVolumesAddTotals)
    .then((totals) => ({
      totals,
    }))
    .catch((error) => console.error(error) || error);

const formatThousandsAndDecimals = d3.format(",.2f");

const formatDecimals = (value) =>
  value === undefined ? "--" : formatThousandsAndDecimals(value);

const TotalVolumeVsDPI = ({ total, dpi }) => {
  return (
    <h4 className="align-left">
      Total volume: {formatDecimals(total)} GB - DPI sample:{" "}
      {formatDecimals(dpi)} GB
    </h4>
  );
};

const enabled = (dpiPerUserEnabled) => ({
  policyType: false,
  hours: true,
  directions: true,
  categories: true,
  filter: true,
  target: dpiPerUserEnabled === true,
  output: false,
});

const DPITotalVolume = () => {
  const profile = useProfile() || {};
  const areClientsInspectable = profile.dpiEnabled === true;
  const targetLabel = areClientsInspectable
    ? "main clients and servers"
    : "main servers";
  const targetTooltip = `Click to see ${targetLabel}`;
  const actions = useContext(ActionsContext);
  return (
    <div id="viewDPITotalVolume" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">DPI volume statistics</h2>
          <ContextMenu/>
          <small id="inspectableCategories">
            Click on the area in the pie-chart for each service to see main{" "}
            <span id="inspectableTargets">{targetLabel}</span>
          </small>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            graphTitle={null}
            itemsValueFrom="volume"
            valueIsShare={false}
            provider={retrieval}
            actions={actions}
            Totals={TotalVolumeVsDPI}
            enabled={enabled(areClientsInspectable)}
            targetTooltip={targetTooltip}
          />
        </div>
        <DPITotalVolumeHelpModal targetLabel={targetLabel} />
        <CategoryDetailsModal sourceView="viewDPITotalVolume" />
      </div>
    </div>
  );
};

export default DPITotalVolume;
