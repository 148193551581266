import React, { useEffect, useReducer, useContext } from "react";
import CreateClient from "./Create";
import EditClient from "./Edit";
import ListClients from "./List";
import ActionsContext from "common/ActionsContext";
import { validateIPv4 } from "common/api";
import { nextId } from "common/utils";

const doesNothing = () => {};

const anyAddressCollisionWith = (__id, address) => (existing) =>
  console.warn(address, __id, existing) ||
  console.warn("address", existing.address === address) ||
  console.warn(
    "id matchs",
    typeof existing.__id,
    typeof __id,
    existing.__id !== __id
  ) ||
  (existing.address === address && existing.__id !== __id);

const doVerification = (item, list) => {
  const { address, description = "", __id, secret = "" } = item;

  if (validateIPv4(address) !== true) {
    throw "address is not a valid IPv4 address";
  }
  if (list.find(anyAddressCollisionWith(__id, address)) !== undefined) {
    throw "address already exists.";
  }

  if (description.includes(" ")) {
    throw "Description must not include white spaces";
  }
  if (secret.length > 127) {
    throw "secret's maximun length is 127 characters.";
  }
};
const notEqual = (client, other) =>
  client.address !== other.address ||
  client.descriptiong !== other.descriptiong ||
  client.secret !== other.secret;

function clientsReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.client,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "updated": {
      return {
        changed: true,
        list: list.map(({ __id, ...client }) =>
          __id !== action.client.__id
            ? { __id, ...client }
            : { ...action.client, updated: notEqual(action.client, client) }
        ),
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...client }) =>
          __id !== action.id
            ? [{ __id, stored, ...client }]
            : stored === true
            ? [{ __id, stored, ...client, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const Clients = ({ initial = [], onChange = doesNothing }) => {
  const actions = useContext(ActionsContext);
  const [clients, dispatch] = useReducer(clientsReducer, {
    changed: false,
    list: [...initial],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createClients = (client) => {
    doVerification(client, clients.list);
    dispatch({
      type: "added",
      client,
    });
  };
  const updateClient = (client) => {
    doVerification(client, clients.list);
    dispatch({
      type: "updated",
      client,
    });
  };
  const editClientId = (id) => {
    const client = clients.list.find(({ __id }) => id === __id);
    if (client !== undefined) {
      actions.send("edit-client", client);
    }
  };
  const validateNewItem = (client) => {
    doVerification(client, clients.list);
  };
  useEffect(() => {
    clients.changed === true && onChange(clients.list);
  }, [clients]);

  return (
    <>
      <ListClients
        items={clients.list}
        onDelete={handleDeleteId}
        onEdit={editClientId}
      />
      <CreateClient doSave={createClients} doValidate={validateNewItem} />
      <EditClient doSave={updateClient} doValidate={validateNewItem} />
    </>
  );
};

export default Clients;
