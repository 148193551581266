import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SysStatsMemHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="System Memory Usage Over Time"
      onClose={doClose}
      content={() => (
        <>
          <p>System memory usage over time.</p>
          <ul>
            <li>
              <b>System</b>: System memory.
            </li>
            <li>
              <b>Mem-Pool</b>: Pool memory.
            </li>
            <li>
              <b>DPDK</b>: DPDK memory.
            </li>
          </ul>
          <p>
            Click on the coloured dots on the top-right corner of the graph to
            optionally select or de-select system memory usage category.
          </p>
          <p>
            Double-click on the coloured dots on the top-right corner of the
            graph to optionally isolate the selected category.
          </p>
          <p>
            Click on the <i class="material-icons valign-icon">refresh</i> icon
            to refresh the memory usage.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SysStatsMemHelpModal;
