import { validateIPv4, safeStr } from "common/api";

const _WARNING_HEAD = "%WARN-ENOENT: ";

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const lowerWord = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalize(word);

const asJSAttribute = (input) => input.split(/\s|-+/).map(lowerWord).join("");

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

const parsePercent = (input) => parseInt(input.replace(/\s%/, ""));
const parseNumberOrNA = (input) =>
  input === "n/a" ? null : parsePercent(input);
const parseValueOrNA = (input) => (input === "n/a" ? null : input);

/************************************************
 * Status
 ************************************************/
const parseSettingsRow = (row) => {
  const [fieldSrc, valueSrc] = row.trim().split(/:\s+/);
  const field = fieldSrc === "State" ? "enabled" : asJSAttribute(fieldSrc);
  const value =
    field === "enabled"
      ? valueSrc === "ready"
        ? true
        : false
      : field === "autoCongestionManagement"
      ? valueSrc === "enabled"
      : field === "rateLimitAVPRatePercentage" ||
        field === "radiusDisconnectUDPPort"
      ? parseNumberOrNA(valueSrc)
      : field === "subscriberIDSource"
      ? valueSrc === "username"
        ? "user-name"
        : parseValueOrNA(valueSrc)
      : parseValueOrNA(valueSrc);
  return [field, value];
};

const parseModeRow = (row) => {
  const [fieldSrc, valueSrc] = row.trim().split(/:\s+/);
  return fieldSrc !== "Mode"
    ? []
    : valueSrc === "disabled"
    ? [["enabled", false]]
    : [
        ["enabled", true],
        ["radiusMode", valueSrc],
      ];
};
const _DEFAULT_SETTINGS = {};

const _DEFAULT_MODE_SETTINGS = { enabled: false, radiusMode: "server" };
const _DEFAULT_SYSTEM_SETTINGS = {
  radiusDisconnectUDPPort: 1700,
  subscriberIDSource: "leave",
};

const includeSetting = (settings, [field, value]) => ({
  ...settings,
  [field]: value === null ? settings[field] : value,
});

const parseMode = (row) =>
  parseModeRow(row).reduce(includeSetting, _DEFAULT_MODE_SETTINGS);

const parseSettings = (rows) =>
  rows.map(parseSettingsRow).reduce(includeSetting, _DEFAULT_SYSTEM_SETTINGS);

const asBoolYesNo = (value) =>
  value === "no" || value === "n/a"
    ? false
    : value === "yes"
    ? true
    : undefined;

export const avpNameAsField = {
  "Mikrotik-Rate-Limit": "mikrotikRateLimitAVP",
  "Mikrotik-Total-Limit": "mikrotikTotalLimitAVP",
  "Mikrotik-Recv-Limit": "mikrotikRecvLimitAVP",
  "Mikrotik-Xmit-Limit": "mikrotikXmitLimitAVP",
  "Ascend-Data-Rate": "ascendDataRateAVP",
  "Ascend-Xmit-Rate": "ascendXmitRateAVP",
  "Mikrotik-Address-List": "mikrotikAddressListAVP",
  "Cisco-AVPair-Policy": "ciscoAVPairPolicyAVP",
  Class: "classAVP",
  "Connect-Info": "connectInfoAVP",
  "Session-Timeout": "sessionTimeoutAVP",
  "Session-Timeout-If-Zero": "sessionTimeoutIfZeroAVP",
  "NAS-ID": "nasIdAVP",
  "NetElastic-QoS-Profile-Name": "netelasticQoSProfileNameAVP",
  "Filter-Id": "filterId",
  "Huawei-Input-Peak-Rate": "huaweiInputPeakRate",
  "Huawei-Output-Peak-Rate": "huaweiOutputPeakRate",
};

export const avpFieldAsCLIName = {
  mikrotikRateLimitAVP: "mikrotik-rate-limit",
  mikrotikTotalLimitAVP: "mikrotik-total-limit",
  mikrotikRecvLimitAVP: "mikrotik-recv-limit",
  mikrotikXmitLimitAVP: "mikrotik-xmit-limit",
  ascendDataRateAVP: "ascend-data-rate",
  ascendXmitRateAVP: "ascend-xmit-rate",
  mikrotikAddressListAVP: "mikrotik-address-list",
  ciscoAVPairPolicyAVP: "cisco-avpair-policy",
  classAVP: "class",
  connectInfoAVP: "connect-info",
  sessionTimeoutAVP: "session-timeout",
  sessionTimeoutIfZeroAVP: "session-timeout zero",
  netelasticQoSProfileNameAVP: "netelastic-qos-profile-name",
  nasIdAVP: "nas-id",
  filterId: "filter-id",
  huaweiInputPeakRate: "huawei-input-peak-rate",
  huaweiOutputPeakRate: "huawei-output-peak-rate",
};

const avpFieldsIgnoreAsConsider = new Set([
  "session-timeout",
  "session-timeout zero",
  "mikrotik-total-limit",
  "mikrotik-xmit-limit",
  "mikrotik-recv-limit",
  "filter-id",
  "nas-id",
]);

export const avpFieldAsName = Object.fromEntries(
  Object.entries(avpNameAsField).map(([name, field]) => [field, name])
);

const parseAVPItem = (row) => {
  const [name, ignores, removes] = row.trim(/\s/).split(/\s+/);
  if (avpNameAsField[name] === undefined) {
    console.warn(`AVP setting "${name}" not expected`);
    return null;
  }
  return [
    avpNameAsField[name],
    {
      ignore: asBoolYesNo(ignores),
      remove: asBoolYesNo(removes),
    },
  ];
};

export const parseAVPSettings = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows
    .map(parseAVPItem)
    .filter((item) => item !== null)
    .reduce(includeSetting, _DEFAULT_SETTINGS);
};

const parseParametersItems = (input) => {
  const [modeRow, ...settingsRows] = input.trim("\n").split("\n");
  return {
    ...parseMode(modeRow),
    ...parseSettings(settingsRows),
  };
};

const preventNoEnt =
  (func, placeholder = []) =>
  (input, ...args) =>
    input.startsWith(_WARNING_HEAD) ? placeholder : func(input, ...args);

const addrDescAndPassRE = /([^ ]*)\s+([^ ]*)\s+(.*)$/;

const parseClientItem = (row) => {
  const match = addrDescAndPassRE.exec(row);
  if (match === null) {
    console.warn(`invalid RADIUS client: ${row}`);
    return {};
  }
  const [_all, address, description, secret] = match;
  return {
    address,
    description,
    secret,
  };
};

const parseClientsItems = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseClientItem);
};

const radiusSettingsRowRE = /([^\s]+)\s+([^\s]+)\s+([^\s]+)\s+(.*)/;

const parseServersItem = (input) => {
  const [_head, row] = input.trim("\n").split("\n");
  const match = row === undefined ? null : row.match(radiusSettingsRowRE);
  if (match === null) {
    return { radiusServerAddress: "", radiusServerSecret: "" };
  }
  const [_all, address, _pAuth, _pAcct, secret] = match;
  return { radiusServerAddress: address, radiusServerSecret: secret };
};

export const parseClients = preventNoEnt(parseClientsItems);
export const parseParameters = preventNoEnt(parseParametersItems);
export const parseServers = preventNoEnt(parseServersItem);

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newRADIUSConfigurationBlock = (...commands) =>
  newBlock(
    "set api radius proxy",
    "configure",
    "api radius",
    ...commands,
    "commit",
    "end"
  ).trim("\n");

const disableCommand = (_previous, _settings) =>
  newBlock("clear api radius proxy").trim("\n");

const ensureSomeSettingsChanged = (permisive = false) =>
  permisive === true
    ? (...parts) => parts
    : (...parts) => {
        if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
          throw "No changes available to commit.";
        }
        return parts;
      };
const keepSpaces = { preserveSpaces: true }; 

const composeSaveClients = (clients = []) =>
  clients.map(({ address, description = "", secret }) =>
    newBlock(
      `client ${address}`,
      `secret ${safeStr(secret, keepSpaces)}`,
      description.length > 0 ? `description ${safeStr(description, keepSpaces)}` : undefined,
      "exit"
    )
  );

const composeUpdateClients = composeSaveClients;

const composeClearClients = (clients) =>
  clients.map(({ address }) => `no client ${address}`);

const isNotSavedOrDeleted = ({ stored, deleted }) =>
  stored !== true && deleted !== true;

const isSavedAndDeleted = ({ stored, deleted }) =>
  stored === true && deleted === true;

const isUpdatedAndSavedNotDeleted = ({ stored, updated, deleted }) =>
  updated === true && stored === true && deleted !== true;

const composeClientsUpdate = (previous, clients) => [
  ...composeUpdateClients(clients.filter(isUpdatedAndSavedNotDeleted)),
  ...composeClearClients(clients.filter(isSavedAndDeleted)),
  ...composeSaveClients(clients.filter(isNotSavedOrDeleted)),
];

const applyClientsSettings = (previous, { clients = [] }) => {
  return composeClientsUpdate(previous.clients || [], clients);
};
const applySubscriberIDParameter = (previous, { subscriberIDSource }) =>
  subscriberIDSource === undefined ||
  subscriberIDSource === previous.subscriberIDSource
    ? []
    : subscriberIDSource === "leave"
    ? ["no subscriber-id"]
    : [`subscriber-id ${subscriberIDSource}`];

const validRateLimit = (value) => {
  if (value === undefined || value.length === 0) {
    return value;
  }
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    throw "is not a number";
  }
  if (parsed < 1) {
    throw "can't be below 1";
  }
  if (parsed > 200) {
    throw "can't be above 200";
  }
  return parsed;
};

const validPortNumberDefaultsTo =
  (defaultPort) =>
  (value, ...settings) =>
    value === undefined || value.length === 0
      ? defaultPort
      : validPortNumber(value, ...settings);

const validPortNumber = (value, min = 1, max = 65535) => {
  if (value === undefined || value.length === 0) {
    return value;
  }
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    throw "is not a number";
  }
  if (parsed < min || parsed > max) {
    throw `must be between ${min} and ${max}`;
  }
  return parsed;
};

const applyRateLimitParameter = (previous, { rateLimitAVPRatePercentage }) =>
  rateLimitAVPRatePercentage === undefined ||
  rateLimitAVPRatePercentage === previous.rateLimitAVPRatePercentage
    ? []
    : [
        `avp rate-limit-percentage ${ofField(
          "Rate-limit scaling value",
          rateLimitAVPRatePercentage,
          validRateLimit
        )}`,
      ];

const applyACMParameter = (previous, { autoCongestionManagement }) =>
  autoCongestionManagement === false
    ? ["no auto-congestion-management"]
    : autoCongestionManagement === true
    ? ["auto-congestion-management"]
    : [];

const applyParametersSettings = (previous, settings) => [
  ...applySubscriberIDParameter(previous, settings),
  ...applyRateLimitParameter(previous, settings),
  ...applyACMParameter(previous, settings),
];

const notEmpty = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can't be empty";
  }
  return value;
};
const ofField = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

const validIPAddress = (value) => {
  if (notEmpty(value) && validateIPv4(value)) {
    return value;
  }
  throw "is not valid IPv4";
};

const applyUDPPort = ({ radiusDisconnectUDPPort = 1700 }) =>
  radiusDisconnectUDPPort === undefined
    ? []
    : [
        `port disconnect ${ofField(
          "Server disconnect port",
          radiusDisconnectUDPPort,
          validPortNumberDefaultsTo(1700)
        )}`,
      ];
const applyProxyMode = ({
  radiusServerAddress,
  radiusServerSecret,
  ...settings
}) => [
  `server ${ofField(
    "Radius server address",
    radiusServerAddress,
    validIPAddress
  )}`,
  `secret ${safeStr(ofField("Radius server secret", radiusServerSecret, notEmpty), keepSpaces)}`,
  "exit",
  ...applyUDPPort(settings),
];

const disableProxyMode = (previous) => [
  `no server ${previous.radiusServerAddress}`,
];
const applyMode = (previous, { radiusMode, ...settings }) =>
  radiusMode === "proxy"
    ? applyProxyMode(settings)
    : previous.radiusMode === "proxy" && radiusMode === "server"
    ? disableProxyMode(previous)
    : [];

const previouslyAVPWas = (previous, field, column) => 
  previous && previous[field] !== undefined && previous[field][column] || undefined

const thoseAVPChangedFrom =
  (previous) =>
  ({ field, column, enabled }) =>
    //Wont disable if previously not enabled
    (enabled === false && previouslyAVPWas(previous, field, column) === true) ||
    //Wont enable if previously enabled
    (enabled === true && previouslyAVPWas(previous, field, column) !== enabled);

const _AVP_COLUMNS = ["ignore", "remove"];

const projectFieldAndColumns = ([field, settings]) =>
  _AVP_COLUMNS.map((column) => ({
    field,
    column,
    enabled: settings[column],
  }));

const setAVPToConsider = (_ignore, field, notToConsider) =>
  `${notToConsider === false ? "" : "no "}avp ${field} consider`;

const setAVPToIgnoreOrRemove = (column, field, enabled) =>
  `${enabled === true ? "" : "no "}avp ${field} ${column}`;

const jsFieldToCLIField = ({ field, ...item }) => ({
  ...item,
  field: avpFieldAsCLIName[field],
});
const applyAVPSettings = (previous, { enabledAVP = {} }) =>
  Object.entries(enabledAVP)
    .flatMap(projectFieldAndColumns)
    .filter(thoseAVPChangedFrom(previous.enabledAVP))
    .map(jsFieldToCLIField)
    .map(({ field, column, enabled }) =>
      avpFieldsIgnoreAsConsider.has(field) && column === "ignore"
        ? setAVPToConsider(column, field, enabled)
        : setAVPToIgnoreOrRemove(column, field, enabled)
    );

const enableCommand = (previous, settings) =>
  newRADIUSConfigurationBlock(
    ...ensureSomeSettingsChanged(previous.enabled !== settings.enabled)(
      ...applyMode(previous, settings),
      ...applyParametersSettings(previous, settings),
      ...applyAVPSettings(previous, settings),
      ...applyClientsSettings(previous, settings)
    )
  );

export const composeApplyCommand = (previous, settings) =>
  settings.enabled === false && previous.enabled === true
    ? disableCommand(previous, settings)
    : enableCommand(previous, settings);

const extractModeFlags = (mode) => {
  const key = mode === "proxy" ? "remove" : "ignore";
  return ([field, values]) => [field, values[key]];
};

export const avpSettingsForMode = (mode = "server", settings = {}) =>
  mode === null
    ? {}
    : Object.fromEntries(Object.entries(settings).map(extractModeFlags(mode)));
