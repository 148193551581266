import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="DoS ATTACKS OVER TIME"
      onClose={doClose}
      content={() => (
        <>
          <p>Detected DoS SYN attacks.</p>
          <ul>
            <li>
              <b>TIME</b>: Time of detected attack.
            </li>
            <li>
              <b>IP-ADDRESS</b>: IP address receiving or originating attack.
            </li>
            <li>
              <b>SIDE</b>: Side on which the IP address lies (Access or
              Internet).
            </li>
            <li>
              <b>DIRECTION</b>: direction of the attack (ingress and egress).
            </li>
            <li>
              <b>FAIL-SYN</b>: Total count of failed TCP handshakes (not only
              this attack)
            </li>
            <li>
              <b>RATE</b>: Attack rate, in failed handshakes per second.
            </li>
            <li>
              <b>DURATION</b>: Attack duration.
            </li>
            <li>
              <b>TOP-CONTRIBUTOR</b>: Top contributor IP address.
            </li>
            <li>
              <b>TOP-FAIL-SYN</b>: Total count of failed TCP handshakes in top
              attack
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
