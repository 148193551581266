// Legacy code, credits to Juan Carlos Casado.
var IpV4 = (function() {
  var _decode = function(s) {
    return s.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
  };

  var _decodeCidr = function(s) {
    return s.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)\/(\d+)$/);
  };

  var _isValid = function(items) {
    return items != null &&
      items[1] <= 255 && items[2] <= 255 &&
      items[3] <= 255 && items[4] <= 255;
  };

  var _isValidCidr = function(items) {
    return items != null &&
      items[1] <= 255 && items[2] <= 255 &&
      items[3] <= 255 && items[4] <= 255 &&
      items[5] <= 32;
  };

  var _parse = function(items) {
    // Validate arguments
    //console.log('_parse.items[%d] %s', items.length, items);
    if (items.length == 5) {
      if (!_isValid(items)) return null;
    } else {
      if (!_isValidCidr(items)) return null;
    }
    var octStr = items.slice(1), // Remove 1rst item (the original address)
      i, len,
      octets = [];
    for (i = 0, len = octStr.length; i < len; i++) {
      octets.push(parseInt(octStr[i]));
    }
    return octets;
  };

  var _maskFromPrefix = function(prefixLen) {
    var idx = 0,
      octets = [0, 0, 0, 0],
      byteMaskValue = [0, 128, 192, 224, 240, 248, 252, 254, 255];

      idx = Math.floor(prefixLen / 8);
      for (var i = 0; i < idx; i++) {
        octets[i] = 255;
        prefixLen -= 8;
      }
      octets[idx] = byteMaskValue[prefixLen];
      return octets;
  };

  var _normalizeCidr = function(items) {
    // Validate arguments
    //console.log('_parse.items[%d] %s', items.length, items);
    if (items.length == 5) {
      return null;
    } else {
      if (!_isValidCidr(items)) return null;
    }
    var octets = [0, 0, 0, 0, 0],
      prefix = items[5],
      mask = _maskFromPrefix(prefix);
    for (var i = 0; i < 4; i++) {
      octets[i] = items[i+1] & mask[i];
    }
    octets[i] = prefix;
    return octets;
  };

  const publicMethods = {
    isValid: function(s) {
      return _isValid(_decode(s));
    },
    isValidCidr: function(s) {
      return _isValidCidr(_decodeCidr(s));
    },
    isValidCidrOrAddr: function(s) {
      return (_isValidCidr(_decodeCidr(s)) || _isValid(_decode(s)));
    },
    parse: function(s) {
      return _parse(_decode(s));
    },
    parseCidr: function(s) {
      return _parse(_decodeCidr(s));
    },
    normalizeCidr: function(s) {
      if (s.indexOf('/') < 0) return s+'/32';
      var normalized = _normalizeCidr(_decodeCidr(s));
      return (normalized.slice(0, 4).join('.') + '/' + normalized[4]);
    },
    toCidr: function(s) {
      if (_decode(s)) {
        return s+'/32';
      } else if (_decodeCidr(s)) {
        return s;
      } else {
        return null;
      }
    },
    match: function(addrStr, rangeStr) {
      // Optimized for preformance
      var items = _decode(addrStr),
        itemsCidr = _decodeCidr(rangeStr);
      // If addrStr is a cidr normalize it
      if (items === null) {
        items = _decodeCidr(addrStr);
        if (_isValidCidr(items) && items[5] == 32) {
          items.splice(5, 1);
        } else {
          return null;
        }
      }
      if (!_isValid(items) || !_isValidCidr(itemsCidr)) {
        return false;
      }
      var addr = _parse(items),
        cidr = _parse(itemsCidr),
        cidrBits = cidr[4],
        i, shift;

      for (i = 0; cidrBits > 0; i++) {
        if (cidrBits >= 8) {
          //console.log('Byte: %d, %d', addr[i], cidr[i]);
          if (addr[i] != cidr[i]) return false;
        } else {
          shift = 8 - cidrBits;
          //console.log('Byte: %d:%d, %d:%d', addr[i], shift, cidr[i], shift);
          if ((addr[i] >> shift) != (cidr[i] >> shift)) return false;
        }
        cidrBits -= 8;
      }
      return true;
    },
    isSubnetOf: function(rangeA, rangeB) {
      // Optimized for clarity
      var itemsA = _decodeCidr(rangeA),
        itemsB = _decodeCidr(rangeB);
      if (!_isValidCidr(itemsA) || !_isValidCidr(itemsB)) {
        return false;
      }
      var cidrA = _parse(itemsA),
        cidrB = _parse(itemsB),
        cidrABits = cidrA[4],
        cidrBBits = cidrB[4],
        i, j, mask;

      for (i = 0; i < 4; i++) {
          for (j = 0, mask= 0x80; j < 8; j++, mask >>= 1) {
            if ((cidrA[i] & mask) != (cidrB[i] & mask)) return false;
            cidrABits--;
            cidrBBits--;
            if (cidrBBits === 0) return true;
            if (cidrABits === 0) return false;
          }
      }
      return true;
    }
  };

  return publicMethods;
})();

export default IpV4;
