import React, { useState, useEffect, useRef, useContext } from "react";
import Form from "common/Form";
import styled from "styled-components";
import Request from "common/Request";
import { validateIPv4IPv6, parseNames } from "common/api";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const _defaultSettings = {
  lines: 100000,
  subnet: "",
  policy: "all",
};

const doesNothing = () => {};


const _linesOptions = [
  { label: "100", value: 100 },
  { label: "1000", value: 1000 },
  { label: "10000", value: 10000 },
  { label: "100000", value: 100000 },
  { label: "1000000", value: 1000000 },
];

const ControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 3cm;
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  & > .input-group {
    flex: 0 1 25%;
  }
  & > .full-width {
    flex: 0 0 100%;
  }
`;
const ControlsAndResultsLayout = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const allPolicies = { label: "All", value: "all" };

const InlineInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;

  & .input-group {
    width: inherit;
    margin-bottom: 0;
  }
  & .input-group,
  & .input-group-add {
    flex-shrink: 1;
  }
  & .input-group {
    flex-grow: 1;
  }
  & .break {
    flex: 1 0 100%;
  }
  & .error {
    font-size: 0.9em;
    color: red;
  }
  & .input-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    flex-grow: 1;
  }
  @media only screen and (max-width: 400px) {
    .input-section {
      flex-direction: column;
    }
  }
`;

const validateIPv4IPv6Range = (input) =>
  validateIPv4IPv6(input.replace(/\/.*$/, ""));

const isValidIPValue = (value) =>
  value.length === 0 || validateIPv4IPv6Range(value) === true;

const AddressInput = ({
  value,
  name = "value",
  className = "",
  onChange = doesNothing,
}) => {
  const input = useRef(null);
  const [error, setError] = useState(false);
  const updateAddr = () => {
    const newAddress = (input.current !== null && input.current.value) || "";
    if (isValidIPValue(newAddress)) {
      onChange({ target: input.current });
    }
  };
  const handleKeyPressed = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      error === false && updateAddr();
    }
  };
  const handleKeyReleased = (event) => {
    const newAddress = (input.current !== null && input.current.value) || "";
    if (isValidIPValue(newAddress)) {
      setError(false);
    } else {
      setError("Value is not a valid IP value");
    }
  };
  useEffect(() => {
    if (input.current === null) {
      return;
    }
    const target = input.current;
    target.addEventListener("keydown", handleKeyPressed);
    target.addEventListener("keyup", handleKeyReleased);
    return () => {
      target.removeEventListener("keydown", handleKeyPressed);
      target.removeEventListener("keyup", handleKeyReleased);
    };
  }, [input]);

  return (
    <InlineInputDiv className={className}>
      <div className="input-section">
        <div className="input-group">
          <span className="input-group-addon">
            <i className="material-icons" title="Address range">
              devices
            </i>
          </span>
          <div className="form-line">
            <input
              type="text"
              ref={input}
              name={name}
              defaultValue={value}
              className="form-control"
              placeholder="Ex: 10.1.0.0/16 or 2001:db8:80::/48"
            />
          </div>
        </div>
        <div className="input-group-add">
          <button
            id="subsMetricsSubnetBtn"
            className="btn btn-link waves-effect"
            onClick={updateAddr}
            disabled={error !== false}
          >
            Apply
          </button>
        </div>
      </div>
      {error !== false && <div className="error break">{error}</div>}
    </InlineInputDiv>
  );
};

const FilteringControls = ({
  defaults = {},
  subnet = undefined,
  children,
  policy = undefined,
}) => {
  defaults = {
    ..._defaultSettings,
    ...defaults,
    ...(subnet === undefined ? {} : { subnet }),
    ...(policy === undefined ? {} : { policy }),
  };
  const [settings, setSettings] = useState(defaults);
  const [flowPolicies, setFlowPolicies] = useState([]);

  useEffect(() => {
    loadPolicies();
  }, []);

  const onChange = (event) => {
    const { target } = event;
    const value =
      target.type !== "checkbox"
        ? target.value
        : target.value === "on" && target.checked === true;
    setSettings((settings) => ({
      ...settings,
      [target.name]: value,
    }));
  };

  async function loadPolicies() {
    try {
      const result = await ifCl.run("show policy flow");
      setFlowPolicies(parseNames(result).names);
    } catch (e) {
      return;
    }
  }

  return (
    <ControlsAndResultsLayout>
      <ColumnsGrid columns={4} minWidth="30rem" rowGap="0">
        <SelectInputWithIcon
          title="Lines"
          name="lines"
          icon="format_line_spacing"
          selected={settings.lines}
          onChange={onChange}
          options={_linesOptions}
        />
        <AddressInput
          value={settings.subnet}
          name="subnet"
          onChange={onChange}
        />
        {flowPolicies.length > 0 && (
          <SelectInputWithIcon
            title="Policies"
            name="policy"
            icon="folder_open"
            selected={settings.policy}
            onChange={onChange}
            options={[
              ...flowPolicies.map((policy) => {
                return { label: policy, value: policy };
              }),
              { label: "All flow policies", value: "all" },
            ]}
          />
        )}
      </ColumnsGrid>
      {children(settings)}
    </ControlsAndResultsLayout>
  );
};
export default FilteringControls;
