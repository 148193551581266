/* globals showModalConfirm */
import React, { useContext } from "react";
import {
  parseManagementInterface,
  composeApplyCommand,
  areChangesDisruptive,
} from "./api";
import SettingsView from "common/SettingsView";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import InterfaceAndVLANSettings from "./InterfaceAndVLANSettings";
import IPAndDNSSettings from "./IPAndDNSSettings";
import useProfile from "common/hooks/useProfile";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getSelectedParameters = () =>
  ifCl.run("show interface management detail").then(parseManagementInterface);

const getManagementInterfaceSettings = () => getSelectedParameters();

class RequiresConfirmation extends Error {
  constructor(reason) {
    super();
    this.reason = reason;
  }
}
class UserDeclined extends Error {}

const confirmWarning = (message, content) => {
  const done = new Promise((resolve) =>
    $("#modalConfirm").on("hidden.bs.modal", resolve)
  );
  return new Promise((resolve, reject) =>
    showModalConfirm(
      message,
      content,
      "warning",
      () => done.then(() => resolve(true)),
      () => done.then(() => reject(new UserDeclined(content))),
      "OK",
      "CANCEL"
    )
  );
};

const ManagementInterfaceContextMenu = ({ doLoad = doesNothing }) => (
  <ul className="context-menu header-dropdown m-r--5">
    <li>
      <a
        onClick={doLoad}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </li>
  </ul>
);
const mayRequireConfirmation = (warning) => {
  console.error("mayRequireConfirmation", warning);
  if (warning instanceof RequiresConfirmation) {
    return confirmWarning("Do you want to proceed?", warning.reason);
  }
  throw warning;
};

const checkForDisruptiveChanges = (previous, settings) => {
  if (areChangesDisruptive(previous, settings)) {
    const reason =
      "Please be aware that any changes in the management IP address, " +
      "if there are firewall rules that do not include the new IP address, " +
      "can result in the access to the node management being blocked.";
    throw new RequiresConfirmation(reason);
  }
  return true;
};

const checkForEmptyGateway = (previous, { gateway }) => {
  if (gateway === undefined || gateway.length === 0) {
    const reason = "No gateway defined.";
    throw new RequiresConfirmation(reason);
  }
  return true;
};

const doChecksOnBeforeProceed = (originalSettings, settings) =>
  (commands) =>
    Promise.resolve(commands)
      .then(() => checkForEmptyGateway(originalSettings, settings))
      .catch(mayRequireConfirmation)
      .then(() => checkForDisruptiveChanges(originalSettings, settings))
      .catch(mayRequireConfirmation)
      .then(() => commands);

const ManagementInterfaceConfig = () => {
  const load = () => getManagementInterfaceSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    if (error instanceof UserDeclined) {
      return { error: null };
    }
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(() => composeApplyCommand(originalSettings, settings))
      .then(doChecksOnBeforeProceed(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={ManagementInterfaceContextMenu}
      title="Management interface settings"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowManagementInterfaceSettings />
    </SettingsView>
  );
};

const ShowManagementInterfaceSettings = ({
  defaults,
  state,
  onStateUpdate = doesNothing,
}) => {
  const { isOperator } = useProfile() || {};
  const updateSettings = (update) => {
    onStateUpdate(update);
  };

  return (
    <div className="body">
      <div className="row">
        <ArrangeInColumns alignToTop={true}>
          <div>
            <InterfaceAndVLANSettings
              isOperatorProfile={isOperator}
              onChange={updateSettings}
              defaults={defaults}
              state={state}
            />
          </div>
          <div>
            <IPAndDNSSettings
              isOperatorProfile={isOperator}
              onChange={updateSettings}
              defaults={defaults}
              state={state}
            />
          </div>
        </ArrangeInColumns>
      </div>
    </div>
  );
};

export default ManagementInterfaceConfig;
