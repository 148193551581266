import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { validateIPv4IPv6Range } from "common/api";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const doesNothing = () => {};

const _targetTypeChoices = [
  { value: "address", label: "IP-Addr filter" },
  { value: "subs_id", label: "Subs-ID filter" },
];
const _defaultTargetValue = { addr: null, subsId: null };

const TargetInputWrapper = styled.div`
  grid-column: span 3;
  display: flex;
  flex-flow: row wrap;
  justify-contents: center;
  & > input,
  & > select,
  & > .input-group-addon {
    width: auto !important;
    display: flex;
  }
  .floating.error {
    position: absolute;
    top: 2em;
    color: red;
    z-index: 3;
  }

  select.form-control {
    margin-right: 20px !important;

  }
`;

const TargetInput = ({
  defaultValue = _defaultTargetValue,
  onChange = doesNothing,
  submitButton = true,
}) => {
  const { subsId = null, addr = null } = defaultValue;
  const [error, setError] = useState(false);
  const [type, setType] = useState(subsId !== null ? "subs_id" : "address");
  const [value, setValue] = useState(subsId || addr || "");
  const updateValue = (event) => setValue(event.target.value);
  const updateValidAddress = (address) => {
    if (address.length === 0 || validateIPv4IPv6Range(address) === true) {
      setValue(address);
      setError(false);
    } else {
      setValue(false);
      setError("Value is not a valid IP address");
    }
  };
  const updateAddressValue = (event) => {
    const address = event.target.value;
    updateValidAddress(address);
  };
  const submit = () =>
    console.warn("submit", value, type) ||
    ((submitButton === false || error === false) &&
      onChange(
        error === true || value === false || value.length === 0
          ? { target: { subsId: null, addr: null } }
          : type === "address"
          ? { target: { subsId: null, addr: value } }
          : { target: { subsId: value, addr: null } }
      ));
  const handleKeyPressed = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      submit();
    }
  };
  const handleTypeChanged = (event) => {
    const targetType = event.target.value;
    setType(targetType);
    if (targetType === "address") {
      updateValidAddress(value);
    } else {
      setError(false);
    }
  };
  useEffect(() => {
    submitButton === false && submit();
  }, [value]);

  return (
    <TargetInputWrapper className="input-group">
      <SelectInputWithIcon
          title="filter"
          name="filter"
          selected={type}
          onChange={handleTypeChanged}
          options={_targetTypeChoices}
        />
      <span className="input-group-addon">
        <i className="material-icons">
          {type === "address" ? "devices" : "person_outline"}
        </i>
      </span>
      {type === "address" ? (
        <input
          type="text"
          name="addressFilterValue"
          className="form-control"
          placeholder="Ex: 10.1.0.0/16 or 2001:db8:80::/48"
          onKeyUp={updateAddressValue}
          onKeyDown={handleKeyPressed}
          defaultValue={addr}
        />
      ) : null}
      {type === "subs_id" ? (
        <input
          type="text"
          name="subsIdFilterValue"
          className="form-control"
          placeholder="Ex: subscriber name"
          onKeyUp={updateValue}
          onKeyDown={handleKeyPressed}
          defaultValue={subsId}
        />
      ) : null}
      {submitButton ? (
        <button
          className="btn btn-link waves-effect"
          disabled={
            error !== false || value === undefined || value.length === 0
          }
          onClick={submit}
        >
          Filter
        </button>
      ) : null}
      {error !== false && <div className="floating error">{error}</div>}
    </TargetInputWrapper>
  );
};

export default TargetInput;