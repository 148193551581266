import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import ActionsContext from "common/ActionsContext";
import NVLineChart from "common/graphs/NVLineChart";
import Request from "common/Request";
import { retrieveRange, isInsideRange, getSampleSize } from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import { useScreenSize } from "common/hooks";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
  periodScopeChoices,
} from "common/constants";
import { parseStatsRtxData} from "./api";
import { getInRangeItems } from "../utils";


const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const ChartContainer = styled.div`
  display: flex;
  min-height: 500px;
  margin-top: 20px;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const RangeContainer = styled.div`
  margin: 20px 0;
`;

const defaultContext = "Day";

export const useStatsRtxData = (
  context = defaultContext,
  firstRequest = null
) => {
  const [request, setRequest] = useState(firstRequest);
  const [initialRange, setInitialRange] = useState();
  const doRequest = (context) => {
    const hours = PERIOD_TO_HOURS[context];
    return retrieveRange(hours).then(({ from, to }) => {
      setInitialRange({ from, to });
      return ifCl
        .run(`show statistics retransmission lines ${PERIOD_TO_LINES[context]}`)
        .then((response) => {
          return parseStatsRtxData(response, context, from, to);
        });
    });
  };

  const doLoad = () => {
    setRequest(doRequest(context));
  };

  useEffect(() => {
    doLoad();
  }, [JSON.stringify(context)]);
  return { request, doLoad, initialRange };
};

const StatsRtxReport = () => {
  const actions = useContext(ActionsContext);
  const [period, setPeriod] = useState("Day");
  const { width: windowWidth } = useScreenSize();
  const { request, doLoad, initialRange } = useStatsRtxData(period, null);
  const [range, setRange] = useState();
  const POINTS_PER_TILE_CHART = windowWidth / 2;

  useEffect(() => {
    setRange(undefined);
    doLoad();

    return actions.recv("do-load", doLoad);
  }, [period]);

  return (
    <Wrapper>
      <ArrangeInColumns rowGap="0">
        <SelectWrapper>
          <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={period}
            onChange={({ target }) => setPeriod(target.value)}
            options={periodScopeChoices}
          />
        </SelectWrapper>
      </ArrangeInColumns>
      <ChartContainer>
        <Request during={request}>
          {({ items, fields }) => {
            const selectedRange = range || initialRange;
            const sampleSize = getSampleSize(selectedRange, POINTS_PER_TILE_CHART, period);
            const inRangeItems = getInRangeItems(items, selectedRange, fields, sampleSize);
            
            return (
              <NVLineChart
                xField="time"
                items={inRangeItems}
                yAxisUnits={"%"}
                yAxisFormat={d3.format(".1f")}
                xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                fields={["Rtx%-Access"].map((field) => {
                  return { name: field, label: field };
                })}
                yAxisTopGap={0.05}
                yAxisTicksNumber={8}
                xAxisTicksNumber={8}
                yTooltipFormat={(d) => d3.format(".2f")(d) + " %"}
                fixedYRange={[0, 1]}
              />
            );
          }}
        </Request>
      </ChartContainer>
      <RangeContainer>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </RangeContainer>
    </Wrapper>
  );
};

export default StatsRtxReport;
