import React from "react";
import styled from "styled-components";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import TargetInput from "common/TargetInput";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {hoursScopeChoices, categoryChoices} from "common/constants"
import { useSearchParams } from "react-router-dom";
import { toObjectLiteral } from "common/utils";

const doesNothing = () => {};

const _policyTypeChoices = [
  { value: "rate", label: "Rate Policies" },
  { value: "monitor", label: "Monitor Policies" },
];

const _outputScopeChoices = [
  { value: "ip-addresses", label: "IP-Addr" },
  { value: "subscriber-ids", label: "Subs-Ids" },
];

const _directionChoices = [
  { value: "all", label: "Both." },
  { value: "uplink", label: "Uplink" },
  { value: "downlink", label: "Downlink" },
];

const _filterChoices = [
  { value: "all", label: "All UDRs." },
  { value: "auto", label: "Only auto UDR" },
];

const ReportContextControlsLayout = styled.div`
  margin: 0.5em 0;

  .input-group {
    margin-bottom: 0;
  }
`;
const _enabledByDefault = {
  filter: true,
  categories: true,
  directions: false,
  target: true,
  policyType: false,
};
const ReportContextControls = ({
  hours,
  categories,
  directions,
  filter,
  target,
  output,
  policyType,
  className,
  enabled = _enabledByDefault,
  settings = {},
  onChange = doesNothing,
  children,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  enabled = { ..._enabledByDefault, ...enabled };
  
  const handleChange = ({ target }) => {
    const { value, name } = target;

    if (name === "hours" || name === "categories") {
      onChange({ [name]: parseInt(value) });
    } else {
      onChange({ [name]: value });
    }

    setSearchParams({...toObjectLiteral(searchParams), [name]: value})
  };

  const handleTargetChange = (value) =>{
    const searchParamsUpdate = toObjectLiteral(searchParams);

    if(value.target.addr){
      searchParamsUpdate.addr = value.target.addr;
      delete searchParamsUpdate.subsId;
    }else if(value.target.subsId){
      searchParamsUpdate.subsId = value.target.subsId;
      delete searchParamsUpdate.addr;
    }
    setSearchParams(searchParamsUpdate)
    onChange(value);
  }

  return (
    <>
      <ReportContextControlsLayout>
        <ColumnsGrid
          columns={12}
          className={className}
          maxWidth="15rem"
          minWidth="4cm"
          rowGap="10px"
        >
          {enabled.policyType !== false ? (
            <SelectInputWithIcon
              title="Policy types"
              name="policyType"
              icon="folder_open"
              selected={policyType || "rate"}
              onChange={handleChange}
              options={settings.policyType ? settings.policyType.options : _policyTypeChoices}
            />
          ) : null}
          <SelectInputWithIcon
            title="Date Range"
            name="hours"
            icon="date_range"
            selected={hours || 24}
            onChange={handleChange}
            options={settings.hours ? settings.hours.options : hoursScopeChoices}
          />
          {enabled.categories !== false ? (
            <SelectInputWithIcon
              title="Categories"
              name="categories"
              icon="format_line_spacing"
              selected={categories || 20}
              onChange={handleChange}
              options={categoryChoices}
            />
          ) : null}
          {enabled.directions !== false ? (
            <SelectInputWithIcon
              title="Direction"
              name="directions"
              icon="import_export"
              selected={directions || "all"}
              onChange={handleChange}
              options={settings.directions ? settings.directions.options : _directionChoices}
            />
          ) : null}
          {enabled.filter !== false ? (
            <SelectInputWithIcon
              title="filter"
              name="filter"
              icon="import_export"
              selected={filter || "all"}
              onChange={handleChange}
              options={_filterChoices}
            />
          ) : null}
          {enabled.output !== false ? (
            <SelectInputWithIcon
              title="Outcome grouping"
              name="output"
              selected={output || "ip-addresses"}
              onChange={handleChange}
              options={_outputScopeChoices}
            />
          ) : null}
          {enabled.target !== false ? (
            <TargetInput defaultValue={target} onChange={handleTargetChange} />
          ) : null}
        </ColumnsGrid>
      </ReportContextControlsLayout>
      {children}
    </>
  );
};

export default ReportContextControls;