import React from "react";

const doesNothing = () => {};

const DeleteVLANButton = ({ onClick = doesNothing }) => (
  <a className="action waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const VLANItem = ({
  id,
  tag = "",
  deleted,
  even = false,
  onDelete = doesNothing,
}) => {
  if (deleted === true) {
    return null;
  }
  const doVLANDelete = () => {
    onDelete(id);
  };
  return (
    <React.Fragment>
      <tr className={`${even ? "even" : "odd"}`}>
        <td>{tag}</td>
        <td className="text-center hides-content-to-operators">
          <DeleteVLANButton onClick={doVLANDelete} />
        </td>
      </tr>
    </React.Fragment>
  );
};
const VLANsList = ({
  items = [],
  onDelete = doesNothing,
  onEdit = doesNothing,
}) => (
  <table className="table">
    <thead>
      <tr>
        <th>TAGS TO BYPASS</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {items
        .filter(({ deleted }) => deleted !== true)
        .map(({ __id, ...client }, index) => (
          <VLANItem
            id={__id}
            key={__id}
            {...client}
            even={(index + 1) % 2 === 0}
            onDelete={() => onDelete(__id)}
            onEdit={() => onEdit(__id)}
          />
        ))}
    </tbody>
  </table>
);
export default VLANsList;
