import React, { useState, useEffect, useContext } from "react";
import {
  parseClients,
  parseParameters,
  parseAVPSettings,
  composeApplyCommand,
  parseServers,
} from "./api";
import SwitchInput from "common/SwitchInput";
import ActionsContext from "common/ActionsContext";
import ConfigRADIUSHelpModal from "./Help";
import SystemSettings from "./SystemSettings";
import Clients from "./Clients";
import SettingsView from "common/SettingsView";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SelectInput from "common/SelectInput";
import { enumerate } from "common/utils";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getParameters = () =>
  ifCl.run("show api radius parameters").then(parseParameters);

const getAVPSettings = () =>
  ifCl.run("show api radius avp").then(parseAVPSettings);

const getClients = () =>
  ifCl
    .run("show api radius client")
    .then(parseClients)
    .then(enumerate(1, { stored: true }));

const getServer = () => ifCl.run("show api radius server").then(parseServers);

const arrangeIntoSettings = ([
  settings = {},
  clients = [],
  serverSettings = {},
  enabledAVP = {},
]) => ({
  ...settings,
  ...serverSettings,
  clients,
  enabledAVP,
});

const getRADIUSSettings = () =>
  Promise.all([getParameters(), getClients(), getServer(), getAVPSettings()])
    .then(arrangeIntoSettings)
    .catch(console.error);

const RADIUSContextMenu = ({ doLoad = doesNothing }) => {
  const [optionsEnabled, setOptionsEnabled] = useState(true);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("state-changed", (state) =>
        setOptionsEnabled(state.enabled === true)
      ),
    []
  );

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      {optionsEnabled === true ? (
        <li className="dropdown hidden-to-operators">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a onClick={() => actions.send("new-client")}>Add Client...</a>
            </li>
          </ul>
        </li>
      ) : null}
    </ul>
  );
};

const RADIUSConfig = () => {
  const load = () => getRADIUSSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(() => composeApplyCommand(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={RADIUSContextMenu}
      title="RADIUS settings"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowRADIUSSettings />
      <ConfigRADIUSHelpModal />
    </SettingsView>
  );
};

const radiusModeTypes = [
  { label: "Accounting Server", value: "server" },
  { label: "RADIUS Proxy", value: "proxy" },
];

const RadiusModeSelect = ({ defaults = {}, onChange }) => (
  <SelectInput
    name="radiusMode"
    label="RADIUS proxy/Accounting server"
    defaultValue={defaults.radiusMode}
    options={radiusModeTypes}
    onChange={(event) => onChange(event.target.value)}
  />
);

const ShowRADIUSSettings = ({
  isOperatorProfile = false,
  defaults,
  state,
  onStateUpdate = doesNothing,
  local = {},
  setLocal,
}) => {
  const handleEnabledSwitch = (event) => {
    const { type } = event.target;
    let { value } = event.target;
    value = type === "checkbox" ? event.target.checked === true : value;
    onStateUpdate({ enabled: value });
  };

  const updateClients = (update) => {
    onStateUpdate({ clients: update });
  };

  const updateSystemSettings = (update) => {
    onStateUpdate({ ...update });
  };
  const updateRadiusMode = (update) => {
    onStateUpdate({ radiusMode: update });
  };

  return (
    <div className="body">
      <div className="row">
        <ArrangeInColumns alignToTop={true}>
          <SwitchInput
            defaultChecked={defaults.enabled}
            disabled={isOperatorProfile}
            className={"sw-input"}
            name="enabled"
            label={`RADIUS API Service: ${
              state.enabled === false ? "disabled" : "enabled"
            }`}
            onChange={handleEnabledSwitch}
          />
          {state.enabled === true ? (
            <RadiusModeSelect defaults={defaults} onChange={updateRadiusMode} />
          ) : null}
        </ArrangeInColumns>
        {state.enabled === true ? (
          <SystemSettings
            onChange={updateSystemSettings}
            defaults={defaults}
            state={state}
            local={local}
            setLocal={setLocal}
          />
        ) : null}
      </div>
      {state.enabled === true ? (
        <div>
          <h4>Clients table</h4>
          <Clients initial={defaults.clients} onChange={updateClients} />
        </div>
      ) : null}
    </div>
  );
};

export default RADIUSConfig;
