import React, { useState } from "react";
import styled from 'styled-components';

const MaskedLayout = styled.span`
  line-height: 1em;
  vertical-align: middle;
  font-family: monospace;
  overflow-wrap: break-word;
  a {
    margin-left: .2em; 
    vertical-align: middle;
    line-height: 14px;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
`;

const Masked = ({children, hidden=true, symbol='•'}) =>{
  const [masked, setMask] = useState(hidden);
  const toggle = () => setMask( previous => previous !== true);
  const maskedOrNot = children => 
    masked === true
      ? symbol.repeat(String(children).length)
      : children
  return (
    <MaskedLayout>
      <span className={masked ? 'masked' : 'clear'}>{maskedOrNot(children)}</span>
      <a onClick={toggle}>
        <i className="material-icons">{masked ? 'visibility' : 'visibility_off'}</i>
      </a>
    </MaskedLayout>
  )
}

export default Masked;
