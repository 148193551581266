import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import TextInput from "common/TextInput";
import SelectInput from "common/SelectInput";

const doesNothing = () => {};

const CreateTrap = ({
  others = [],
  doSave = doesNothing,
  doValidate = doesNothing,
}) => {
  const actions = useContext(ActionsContext);
  const [trapCreation, setTrapCreation] = useState(false);
  const showTrapCreation = () => setTrapCreation(true);
  const hideTrapCreation = () => setTrapCreation(false);

  useEffect(() => {
    actions.recv("new-trap", () => showTrapCreation());
  }, []);

  return (
    <React.Fragment>
      {trapCreation === true ? (
        <CreateTrapModal
          active={trapCreation}
          others={others}
          doSave={doSave}
          doValidate={doValidate}
          onClose={hideTrapCreation}
        />
      ) : null}
    </React.Fragment>
  );
};

const trapTypes = [
  { label: "--Select a trap type--", value: "" },
  { label: "SNMPv1", value: "trap-v1" },
  { label: "SNMPv2c", value: "trap-v2c" },
];

const CreateTrapModalContent = ({valuesForm, setValuesForm, doValidate, error, setError}) => {
  const {community, nms, type} = valuesForm;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValues = {...valuesForm, [name]: value};
    setValuesForm(newValues);
  };

    useEffect(()=> {
    try {
      doValidate(valuesForm);
      setError(null);
    } catch (error) {
      setError(error);
    }
  }, [community, nms, type])

  return (
    <form>
      <AskTrapCommunity onChange={handleChange} />
      <AskTrapNMS onChange={handleChange} />
      <AskTrapType options={trapTypes} onChange={handleChange} />
    </form>
  );
};

const CreateTrapModal = ({
  doSave = doesNothing,
  onClose = doesNothing,
  doValidate = doesNothing,
}) => {
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const [valuesForm, setValuesForm] = useState({community: '', nms: '', type: ''})
  const {community, nms, type} = valuesForm;

  const doClose = () => setOpen(false);

  const doApply = () => {
    try {
      doSave(valuesForm);
      doClose();
    } catch (error) {
      setError(error);
    }
  };

  if (open === false) {
    return null;
  }

  return (
    <Modal
      title="Create Trap"
      superIcon="add_circle"
      large={false}
      content={() => (
        <CreateTrapModalContent
          doValidate={doValidate}
          error={error}
          setError={setError}
          doApply={doApply}
          valuesForm={valuesForm}
          setValuesForm={setValuesForm}
        />
      )}
      applyLabel="OK"
      onApply={doApply}
      doValidate={doValidate}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

const AskTrapNMS = ({ trapNms, onChange }) => (
  <>
    <TextInput
      type="text"
      name="nms"
      label="NMS IP Address:"
      className="input-control"
      defaultValue={trapNms}
      onChange={onChange}
      iconOption={true}
      icon={"cloud_queue"}
    />
  </>
);

const AskTrapType = ({ trapType, options, onChange }) => (
  <SelectInput
    name="type"
    label="Type"
    placeHolder={"Select a trap type"}
    defaultValue={trapType}
    options={options}
    onChange={onChange}
    iconOption={true}
    icon={"assignment_late"}
  />
);

const AskTrapCommunity = ({ trapCommunity, onChange }) => (
  <>
    <TextInput
      type="text"
      name="community"
      label="Community:"
      className="input-control"
      defaultValue={trapCommunity}
      onChange={onChange}
      iconOption={true}
      icon={"assignment_ind"}
    />
  </>
);

export default CreateTrap;
