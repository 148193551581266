import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

/*****************************************************
 * Help Modal
 * ****************************************************/
const ConfigRESTHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("show-busy-warning", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="SYNCHRONIZATION IN PROGRESS"
      onClose={doClose}
      content={() => (
        <>
          <p>Please try again later.</p>
        </>
      )}
    />
  );
};

export default ConfigRESTHelpModal;
