import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import SelectInput from "common/SelectInput";
import DataTable, { Column } from "common/DataTable";

const doesNothing = () => {};

const hoursOptions = [
  { label: "1 Day", value: 24 },
  { label: "1 Week", value: 168 },
  { label: "1 Month", value: 744 },
];

const HoursInput = ({ defaultValue = 24, onChange = doesNothing }) => {
  return (
      <SelectInput
        label="Most recent"
        name="hours"
        onChange={(event) => onChange({hours: event.target.value})}
        defaultValue={defaultValue}
        options={hoursOptions}
      />
  );
};

const ReportContextControlsLayout = styled.div`
  margin: 0.5em 0px 30px 0px;
`;
const ReportContextControls = ({
  hours,
  className,
  onChange = doesNothing,
  children,
}) => (
  <>
  <ReportContextControlsLayout>
    <ColumnsGrid columns={4} className={className} maxWidth="15rem" minWidth="9cm" rowGap="0">
      <HoursInput defaultValue={hours} onChange={onChange} />
    </ColumnsGrid>
  </ReportContextControlsLayout>
    {children}
  </>
);

const ReportResult = ({ response, columns, order }) => (
  <div className="body table-responsive">
    <DataTable exportAsCSV={true} columns={columns} order={order} content={response} />
  </div>
);

const ReportRequest = ({ hours, provider, actions, ...rest }) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    const doingRetrieval = provider({ hours }).catch((error) => error);
    setRequest(doingRetrieval);
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [hours]);
  return request === null ? null : (
    <Request during={request.then((response) => ({ response }))}>
      {(result) => <ReportResult {...result} {...rest} />}
    </Request>
  );
};

const _defaultContext = {
  hours: 24,
};

const ReportContext = ({ provider, actions, ...rest }) => {
  const [context, setContext] = useState(_defaultContext);
  const updateContext = ({ hours }) =>
    setContext((prev) => ({
      ...prev,
      ...(hours === undefined ? {} : { hours }),
    }));

  return (
    <>
      <ReportContextControls {...context} onChange={updateContext}>
        <ReportRequest
          {...context}
          provider={provider}
          actions={actions}
          {...rest}
        />
      </ReportContextControls>
    </>
  );
};

export default ReportContext;
