/*globals views, globalNavigate*/
import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  forwardRef,
  useRef,
} from "react";
import styled from "styled-components";
import * as d3 from "d3";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import { useDimensions } from "common/hooks";
import {
  safeStr,
  doSystemDateRetrieval,
  parseSystemDate,
  calcRangeExtent,
  isInsideRange,
} from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import { TileContainer } from "common/graphs/FacetGraph";
import { ConnectedLineChart } from "common/graphs/ConnectedLineChart";
import NVLineChartWithLegends from "common/graphs/NVLineChart";
import ActionsContext from "common/ActionsContext";
import ScatterPlot from "common/graphs/ScatterPlot/ScatterPlot";
import formats from "common/graphs/formats";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import FacetGraph from "common/graphs/ConnectedFacetGraph";
import {
  parseSubscriberVolume,
  parseSubscriberFlows,
  calcFlowsCreationPerMinute,
  parseSubscriberMaxSpeeds,
  parseSubscriberLatencies,
  parseSubscriberRetransmissions,
  parseSubscriberCongestion,
} from "./api";
import RatePolicy from "../../Status/SubscriberGroupsEdit/RatePolicy";

const doesNothing = () => {};
const MARGIN_PLOT = { top: 0, right: 40, bottom: 50, left: 60 };
const PADDING_CHART_CONTAINER = 30;

export const doStatsRetrieval = ({
  facet = "volume",
  hours = 24,
  interval = 10,
  subscriberGroup,
}) =>
  ifCl.run(
    `show statistics subscriber-groups name ${safeStr(
      subscriberGroup
    )} ${facet} hours ${hours} interval ${interval}`
  );

const _hoursScopeChoices = [
  { value: 24, label: "1 Day" },
  { value: 24 * 7, label: "1 Week" },
  { value: 24 * 30, label: "1 Month" },
  { value: 24 * 30 * 3, label: "3 Months" },
];

const ControlsTileContainer = styled.div`
  min-height: 10cm;
  @media print {
    & {
      height: 8cm;
      &.can-compress {
        height: auto;
      }
      width: 100%;
      display: block:
    }
  }
  display: flex;
  flex-flow: row wrap;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    margin-bottom: 0px;
  }
  .dont-grow-tall {
    height: auto;
  }
`;
const ReloadButton = styled.div`
  cursor: pointer;

  a {
    display: flex;
    text-decoration: none;
  }

  .material-icons {
    font-size: 20px;
  }
`;

const IconRefresh = styled.i`
  color: #999;
  &:hover {
    color: #000;
  }
  transition: all 0.5s;
`;

const RatePolicyContainer = styled.div`
  padding-top: 2px;

  .rate-policy-title {
    font-size: 12px;
  }

  button {
    font-size: 12px;
  }

  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;

  .material-icons {
    padding-right: 10px;
  }
`;

const HoursScopeSelect = ({
  selected = 24,
  onChange = doesNothing,
  ...settings
}) => (
  <SelectInputWithIcon
    title="Date Range"
    name="hours"
    icon="date_range"
    {...settings}
    selected={selected}
    onChange={(event) => onChange({ hours: event.target.value })}
    options={_hoursScopeChoices}
  />
);

const returnView = "viewSubscriberGroupDashboard";

function hoursToTimePeriod(hours) {
  switch (hours) {
    case 24:
      return "oneDay";
    case 24 * 7:
      return "oneWeek";
    case 24 * 30:
      return "oneMonth";
    case 24 * 30 * 3:
      return "threeMonths";
  }
}

const ContextControls = ({
  range,
  hours,
  onChange = doesNothing,
  children,
  subscriberGroup,
  settingsSubscriberGroups,
}) => {
  const actions = useContext(ActionsContext);
  const handleSelectChange = (event) => {
    const hours = parseInt(event.target.value);
    onChange({ hours });
  };

  return (
    <>
      <ControlsTileContainer className="full-width can-compress spread">
        <HoursScopeSelect
          selected={hours}
          onChange={onChange}
          className="dont-grow-tall"
        />
        <div className="row-wrap">
          <RatePolicyContainer>
            <RatePolicy
              nameGroup={subscriberGroup}
              returnView="viewStatusSubscriberGroups"
              settings={settingsSubscriberGroups}
              className="subscriberdashboardPolicyRate"
            />
          </RatePolicyContainer>
          <div className="input-group">
            <StyledButton
              type="button"
              className="btn btn-link waves-effect"
              onClick={() => {
                views.doKeep(returnView);
                globalNavigate("viewStatusSubscriberGroups", {
                  returnView,
                  subscriberGroup,
                  ...settingsSubscriberGroups,
                });
              }}
            >
              <span className="input-group-addon" title="Date Range">
                <i className="material-icons">multiline_chart</i>
              </span>
              See main subscribers in group
            </StyledButton>
          </div>
          <div className="input-group">
            <StyledButton
              type="button"
              className="btn btn-link waves-effect"
              onClick={() => {
                globalNavigate("viewStatusSubscriberGroups", {
                  returnView: "viewSubscriberGroupDashboard",
                  subscriberGroup,
                  edit: true,
                  ...settingsSubscriberGroups,
                });
              }}
            >
              <span className="input-group-addon" title="Date Range">
                <i className="material-icons">edit</i>
              </span>
              Edit subscriber group
            </StyledButton>
          </div>
          <div className="input-group">
            <ReloadButton>
              <a
                onClick={() => actions.send("do-load")}
                data-toggle="cardloading"
                data-loading-effect="pulse"
                title="Refresh"
              >
                <IconRefresh className="material-icons">refresh</IconRefresh>
              </a>
            </ReloadButton>
          </div>
        </div>
      </ControlsTileContainer>
      {children}
      <TileContainer className="full-width can-compress">
        <RangeSliderInput {...range} onChange={onChange} />
      </TileContainer>
    </>
  );
};

const ScatterPlotLatencySpeed = forwardRef(
  ({ items, interactionData, onInteractionData }, ref) => {
    return (
      <TileContainer className="chart-container" ref={ref}>
        <h4 className="chart-title lowercase">Latency vs Average Speed</h4>
        <div className="graph">
          <ScatterPlot
            items={items}
            contentY={{
              yAxisLabel: "Access latency (ms)",
              tooltipLabel: "Latency",
              tooltipUnit: "ms",
            }}
            contentX={{
              xAxisLabel: "Average downlink speed (Mbps)",
              tooltipLabel: "Average Speed",
              tooltipUnit: "Mbps",
            }}
            interactionData={interactionData}
            onInteractionData={onInteractionData}
          />
        </div>
      </TileContainer>
    );
  }
);

const parseAndCalcFlowsCreationPerMinute = (response) =>
  calcFlowsCreationPerMinute(parseSubscriberFlows(response));

const formatMillions = (value) =>
  value < 1000
    ? value
    : value < 1000000
    ? `${(value / 1000).toFixed(1)}K`
    : `${(value / 1000000).toFixed(1)}M`;

const scatterItemsXSpeed = (yItems, speedItems, yLabel) => {
  return speedItems.reduce((acc, item, index) => {
    const { downMbps, time } = item;
    if (yItems[index][yLabel] !== null && downMbps !== null)
      acc.push({ y: yItems[index][yLabel], x: downMbps, time });
    return acc;
  }, []);
};

const formatDate = d3.timeFormat("%m/%d %H:%M");

function getTimeValuesMaps(itemsCharts) {
  return Object.keys(itemsCharts).reduce((acc, itemsChart) => {
    const mapKey = `${itemsChart}Map`;
    const items = itemsCharts[itemsChart];
    if (items && items.length === 0) {
      acc[mapKey] = {};
    }
    const mapValues = items.reduce((acc, value) => {
      const { time } = value;
      const timeKey = formatDate(time);
      acc[timeKey] = value;
      return acc;
    }, {});
    acc[mapKey] = mapValues;
    return acc;
  }, {});
}

const Dashboard = ({
  target,
  volumes,
  itemsLatency,
  itemsFlows,
  itemsRetransmission,
  itemsCongestion,
  itemsMaxSpeed,
  range: initialRange,
  interval = 10,
  hours = 24,
  onHoursChange = doesNothing,
  subscriberGroup,
  settingsSubscriberGroups,
}) => {
  const scatterPlotRef = useRef();
  const dimensions = useDimensions(scatterPlotRef);
  const widthChart =
    dimensions.width -
    MARGIN_PLOT.left -
    MARGIN_PLOT.right -
    PADDING_CHART_CONTAINER;
  const [range, setRange] = useState(initialRange);
  const applyRange = (newRange) => setRange(newRange);
  const [interactionData, setInteractionData] = useState(null);
  const applyChange = ({ hours, ...range }) => {
    if (hours !== undefined) {
      onHoursChange(hours);
    } else {
      applyRange(range);
    }
  };

  const {
    volumesMap,
    itemsLatencyMap,
    itemsFlowsMap,
    itemsRetransmissionMap,
    itemsCongestionMap,
    itemsMaxSpeedMap,
  } = useMemo(() => {
    return getTimeValuesMaps({
      volumes,
      itemsLatency,
      itemsFlows,
      itemsRetransmission,
      itemsCongestion,
      itemsMaxSpeed,
    });
  }, [
    volumes,
    itemsLatency,
    itemsFlows,
    itemsRetransmission,
    itemsCongestion,
    itemsMaxSpeed,
  ]);

  const xScale = useMemo(() => {
    return d3
      .scaleTime()
      .range([0, widthChart])
      .domain([volumes[0].time, volumes[volumes.length - 1].time]);
  }, [volumes, widthChart]);

  return (
    <ColumnsGrid gap="1rem" columns={2} minWidth="9cm" rowGap="0">
      <ContextControls
        target={target}
        className="full-width"
        range={initialRange}
        hours={hours}
        onChange={applyChange}
        subscriberGroup={subscriberGroup}
        settingsSubscriberGroups={settingsSubscriberGroups}
      >
        <FacetGraph
          title="Average Speed Over Time"
          items={volumes.filter(isInsideRange(range))}
          facet="speed"
          yAxisUnits="Mbps"
          fields={[
            { name: "downMbps", label: "Down-Mbps" },
            { name: "upMbps", label: "Up-Mbps" },
          ]}
          yTooltipFormat={formats.decimals(4)}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={volumesMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Flows Over Time"
          items={itemsFlows.filter(isInsideRange(range))}
          yAxisUnits="Flows"
          fields={[
            { name: "flCreatedPerMinute", label: "Fl-Created-per-minute" },
            { name: "flActive", label: "Fl-Active" },
          ]}
          yAxisFormat={formatMillions}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsFlowsMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Latency Over Time"
          items={itemsLatency.filter(isInsideRange(range))}
          yAxisUnits="milliseconds"
          fields={[
            { name: "subsAccessRttMs", label: "Subs-Access-Rtt-Ms" },
            { name: "networkAccessRttMs", label: "Network-Access-Rtt-Ms" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsLatencyMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Packet retransmissions Over Time"
          items={itemsRetransmission.filter(isInsideRange(range))}
          yAxisUnits="% retransmissions"
          fields={[
            { name: "subsAccessRtx", label: "Subs-Access-Rtx-%" },
            { name: "networkAccessRtx", label: "Network-Access-Rtx-%" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsRetransmissionMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Congestion Over Time"
          items={itemsCongestion.filter(isInsideRange(range))}
          yAxisUnits="% congestion"
          fields={[{ name: "congestion", label: "Congestion-%" }]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsCongestionMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="% Traffic at Max Speed Over Time"
          items={itemsMaxSpeed.filter(isInsideRange(range))}
          yAxisUnits="% traffic at max speed"
          fields={[{ name: "maxSpeed", label: "% Traffic at Max Speed" }]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsMaxSpeedMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <ScatterPlotLatencySpeed
          items={scatterItemsXSpeed(
            itemsLatency,
            volumes,
            "subsAccessRttMs"
          ).filter(isInsideRange(range))}
          interactionData={interactionData}
          onInteractionData={setInteractionData}
          ref={scatterPlotRef}
        />
        <TileContainer className="chart-container">
          <h4 className="chart-title lowercase">
            Packet Retransmissions vs Average Speed
          </h4>
          <div className="graph">
            <ScatterPlot
              items={scatterItemsXSpeed(
                itemsRetransmission,
                volumes,
                "subsAccessRtx"
              ).filter(isInsideRange(range))}
              contentY={{
                yAxisLabel: "Retransmissions (%)",
                tooltipLabel: "Retransmissions",
                tooltipUnit: "%",
              }}
              contentX={{
                xAxisLabel: "Average downlink speed (Mbps)",
                tooltipLabel: "Average Speed",
                tooltipUnit: "Mbps",
              }}
              interactionData={interactionData}
              onInteractionData={setInteractionData}
            />
          </div>
        </TileContainer>
        <TileContainer className="chart-container">
          <h4 className="chart-title lowercase">Congestion vs Average Speed</h4>
          <div className="graph">
            <ScatterPlot
              items={scatterItemsXSpeed(
                itemsCongestion,
                volumes,
                "congestion"
              ).filter(isInsideRange(range))}
              contentY={{
                yAxisLabel: "Congested traffic (%)",
                tooltipLabel: "Congestion",
                tooltipUnit: "%",
              }}
              contentX={{
                xAxisLabel: "Average downlink speed (Mbps)",
                tooltipLabel: "Average Speed",
                tooltipUnit: "Mbps",
              }}
              interactionData={interactionData}
              onInteractionData={setInteractionData}
            />
          </div>
        </TileContainer>
      </ContextControls>
    </ColumnsGrid>
  );
};

const systemTimeRetrieval = () => doSystemDateRetrieval().then(parseSystemDate);

const promisesInfo = [
  { facet: "mbps", parse: parseSubscriberVolume },
  { facet: "latency", parse: parseSubscriberLatencies },
  { facet: "flows", parse: parseAndCalcFlowsCreationPerMinute },
  { facet: "retransmission", parse: parseSubscriberRetransmissions },
  { facet: "congest", parse: parseSubscriberCongestion },
  { facet: "max-speed", parse: parseSubscriberMaxSpeeds },
];

const promises = ({ subscriberGroup, target, hours, interval }) => {
  return Promise.all(
    promisesInfo.map(({ facet, parse }) => {
      return doStatsRetrieval({
        facet,
        target,
        hours,
        interval,
        subscriberGroup,
      })
        .then(parse)
        .catch((error) => console.error(error) || []);
    })
  );
};

const retrieval = ({ subscriberGroup, target, hours, interval }) => {
  return Promise.all([
    systemTimeRetrieval().then((systemDate) =>
      calcRangeExtent(systemDate, hours)
    ),
    promises({ subscriberGroup, target, hours, interval }),
  ]).then(
    ([
      range,
      [
        volumes,
        itemsLatency,
        itemsFlows,
        itemsRetransmission,
        itemsCongestion,
        itemsMaxSpeed,
      ],
    ]) => {
      return {
        range,
        volumes,
        itemsLatency,
        itemsFlows,
        itemsRetransmission,
        itemsCongestion,
        itemsMaxSpeed,
      };
    }
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
};

const hoursByTimePeriodMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30 * 3,
};

const intervalForHours = (hours, interval = 10) =>
  hours > 7 * 24 ? 60 : interval;

const SubscriberGroupDashboard = ({
  target,
  children,
  subscriberGroup,
  settingsSubscriberGroups,
}) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const initContext = {
    ..._defaultContext,
    hours: hoursByTimePeriodMap[settingsSubscriberGroups.timePeriod],
  };

  const [context, setContext] = useState(initContext);

  const setHoursInContext = (hours) =>
    setContext({ hours, interval: intervalForHours(hours) });
  const doLoad = () => {
    setRequest(retrieval({ subscriberGroup, target, ...context }));
  };

  const { hours, interval } = context;
  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";
  const contextStr = "" + hours + interval;

  useEffect(() => {
    setRequest(null);
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [targetStr, contextStr]);

  return request === null ? null : (
    <Request during={request}>
      {(result) => (
        <Dashboard
          {...result}
          {...context}
          target={target}
          onHoursChange={setHoursInContext}
          subscriberGroup={subscriberGroup}
          settingsSubscriberGroups={{
            ...settingsSubscriberGroups,
            timePeriod: hoursToTimePeriod(hours),
          }}
        />
      )}
    </Request>
  );
};
export default SubscriberGroupDashboard;
