import React, { useState, useEffect, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import { WEEKDAYS } from "../../api";
import TimeInput from "./TimeInput";
import RangeDays from "./RangeDays";
import styled from "styled-components";

const doesNothing = () => {};
const DEFAULT_NEW_RANGE = {
  starts: "00:00",
  ends: "23:59",
  days: WEEKDAYS.join(", "),
};

const TimeProfileInputRangesWrapper = styled.div`
  .btn-group {
    box-shadow: none;

    button {
      border-radius: 5px !important;
    }
  }
`

const minutesDifference = (starts, ends) =>
  (ends.hour - starts.hour) * 60 + (ends.minute - starts.minute);

export const CreateRangeModal = ({
  profiles = [],
  doSave = doesNothing,
  onClose = doesNothing,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [index, setIndex] = useState(null);
  const [range, setRange] = useState({ ...DEFAULT_NEW_RANGE });
  const actions = useContext(ActionsContext);
  actions.recv("create-range", function (index) {
    setProfile(index === null ? null : { ...profiles[index] });
    setIndex(index);
    setOpen(index !== null);
    setError(null);
  });

  useEffect(() => {
    const period = minutesDifference(range.starts, range.ends);
    if (range.days.length === 0) {
      setError("At least one day has to be selected");
    } else if (period <= 0) {
      setError("Period has to be greated than 0.");
    } else {
      setError(null);
    }
  }, [range]);
  const updateField = (field) => (source) => {
    const value = source.target === undefined ? source : source.target.value;
    setRange({ ...range, [field]: value });
  };
  const doClose = () => {
    setOpen(false);
    setIndex(null);
    onClose();
  };
  const doApply = (_event) => {
    if (error === null) {
      const updatedProfile = {
        ...profile,
        modified: true,
        ranges: [...profile.ranges, { ...range }],
      };
      doSave(index, updatedProfile);
      doClose();
    }
  };
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title={`Create Range On Profile ${profile.name}`}
      onClose={doClose}
      superIcon="access_time"
      content={() => (
        <>
          <div className="row">
            <div className="col-md-6">
              <div
                className="input-group"
                data-toggle="tooltip"
                data-placement="top"
              >
                <span className="input-group-addon">&emsp;Starts</span>
                <TimeInput
                  defaultValue={range.starts}
                  onChange={updateField("starts")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="input-group"
                data-toggle="tooltip"
                data-placement="top"
              >
                <span className="input-group-addon">&emsp;Ends</span>
                <TimeInput
                  defaultValue={range.ends}
                  onChange={updateField("ends")}
                />
              </div>
            </div>
          </div>
          <TimeProfileInputRangesWrapper>
            <div className="input-group">
              <span className="input-group-addon margin-r-15">&emsp;Active days</span>
              <RangeDays
                value={range.days}
                disabled={false}
                onChange={updateField("days")}
              />
            </div>
          </TimeProfileInputRangesWrapper>
        </>
      )}
      onApply={doApply}
      applyLabel={"Update"}
      applyDisabled={error !== null}
      closeLabel={"Discard Changes"}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};
