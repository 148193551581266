import { validateIPv4WithMask, validateIPv4 } from "common/api";
import IpV4 from "common/ipv4";

const significantCode = (code) =>
  code !== null && code !== undefined && code.length > 0;

const newScriptCall = (name, ...args) =>
  `\n${["set", name, ...args]
    .filter(significantCode)
    .map((code) => code.trim(" "))
    .join(" ")}\n`;

const asBoolYesNo = (value) =>
  value === "no" || value === "n/a"
    ? false
    : value === "yes"
    ? true
    : undefined;

const flagFields = ["link", "management"];

const parseValueOf = (field, value) =>
  flagFields.includes(field) ? asBoolYesNo(value) : value;

const parseInterfaceItem = (line, fields) =>
  Object.fromEntries(
    line
      .trim(" ")
      .split(/\s+/)
      .map((value, index) => [value, fields[index]])
      .map(([value, field]) => [field, parseValueOf(field, value)])
  );

const onlyAvailableLinksOmiting = (wireNames = []) => {
  const areCards = ({ type }) => type !== "loopback";
  return wireNames.length === 0
    ? (link) => areCards(link)
    : (link) => areCards(link) && wireNames.includes(link.name) === false;
};
export const parseInterfaceList = (input, wireNames = []) => {
  const head = ["name", "type", "mac", "state", "link", "management"];
  const lines = input.trim("\n").split("\n");
  return lines
    .map((line) => parseInterfaceItem(line, head))
    .filter(onlyAvailableLinksOmiting(wireNames));
};

export const parseWireNames = (input) =>
  input
    .trim("\n")
    .split("\n")
    .flatMap((row) => row.split(/\s+/).filter((_val, index) => index !== 0));

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const lowerWord = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalize(word);

const asJSAttribute = (input) => input.split(/\s|-+/).map(lowerWord).join("");
const managementTranslation = {
  "IP address": "address",
  "Default gateway": "gateway",
};
const addressAndMask = (input) => {
  const [address, mask] = input.split("/");
  return [
    ["address", address],
    ["mask", mask],
  ];
};
const interfaceAndVLAN = (input) => {
  const [name, vlan] = input.split(".");
  return [
    ["interface", name],
    ["vlan", vlan === undefined ? "" : vlan],
  ];
};

const mayBeNA = (value) => (value === "n/a" ? null : value);

const propertyPerLine = (line) => {
  const [fieldSrc, value] = line.split(/:\s+/);
  const field = managementTranslation[fieldSrc] || asJSAttribute(fieldSrc);
  return field === "address"
    ? addressAndMask(value)
    : field === "interface"
    ? interfaceAndVLAN(value)
    : [[field, mayBeNA(value)]];
};
const intoMIProperties = (settings = {}, [field, value]) => ({
  ...settings,
  [field]: value,
});

export const _DEFAULT_SETTINGS = {
  vlan: "",
};
export const parseManagementInterface = (input) =>
  input
    .trim("\n")
    .split("\n")
    .flatMap(propertyPerLine)
    .reduce(intoMIProperties, _DEFAULT_SETTINGS);

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newManagementInterfaceConfiguration = (...commands) =>
  newScriptCall("interface", ...commands).trim("\n");

const ensureSomeSettingsChanged = (permisive = false) =>
  permisive === true
    ? (...parts) => parts
    : (...parts) => {
        if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
          throw "No changes available to commit.";
        }
        return parts;
      };

const notEmpty = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can't be empty";
  }
  return value;
};

const verified = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

const validIPAddressRange = (value) => {
  if (notEmpty(value) && validateIPv4WithMask(value)) {
    return value;
  }
  throw "is not a valid IPv4 range";
};

const validIPAddressOrEmpty = (value) => {
  if (value && value.length > 0 && IpV4.isValid(value) !== true) {
    throw "is not a valid IPv4 address";
  }
  return value;
};

const validVLAN = (value) => {
  const parsed = parseInt(value, 10);
  if (isNaN(parsed) !== false) {
    throw "has to be a number";
  }
  if (1 > parsed || parsed > 4094) {
    throw "must be between 1 and 4094 or left empty if no tag";
  }
  return value;
};
const applyInterfaceSettings = (previous, { vlan, ...settings }) => [
  `${settings.interface}`,
  "management",
  vlan !== undefined && vlan.length > 0
    ? verified("VLAN ID", vlan, validVLAN)
    : "0",
];

export const areChangesDisruptive = (previous, settings) =>
  settings.interface !== previous.interface ||
  settings.gateway !== previous.gateway ||
  settings.address !== previous.address ||
  settings.mask !== previous.mask;

const applyAddressArgument = (previous, { address, mask }) =>
  address || mask
    ? verified(
        `Received interface IP subnet "${address}/${mask}"`,
        `${address}/${mask}`,
        validIPAddressRange
      )
    : null;

const areOnSameNetwork = ({ address, mask }, target) => {
  const subnet = `${address}/${mask}`;
  if (IpV4.match(target, subnet) !== true) {
    throw `"${target}" is not inside management subnet ${subnet}`;
  }
  return target;
};

const validGatewayIPAddressOnNetwork = (address, mask) => (gateway) => {
  validIPAddressOrEmpty(gateway);
  if (gateway === undefined || gateway.length === 0) {
    return gateway;
  }
  return areOnSameNetwork({ address, mask }, gateway);
};

const validDNSIPAddressOnNetwork = (gateway, address, mask) => (nameserver) => {
  validIPAddressOrEmpty(nameserver);
  if (nameserver === undefined || nameserver.length === 0) {
    return nameserver;
  }
  return gateway && gateway.length > 0
    ? nameserver
    : areOnSameNetwork({ address, mask }, nameserver);
};

const applyGatewayArgument = (previous, { gateway, address, mask }) =>
  `gateway ${verified(
    "Received gateway address",
    gateway,
    validGatewayIPAddressOnNetwork(address, mask)
  )}`;

const applyDNSArgument = (previous, { nameserver, address, mask, gateway }) =>
  previous.nameserver === nameserver
    ? null
    : nameserver && nameserver.length > 0
    ? `nameserver ${verified(
        "Received nameserver address",
        nameserver,
        validDNSIPAddressOnNetwork(gateway, address, mask)
      )}`
    : " ";

const applyIPSettings = (previous, settings = {}) =>
  ensureSomeSettingsChanged(previous.interface !== settings.interface)(
    applyAddressArgument(previous, settings),
    applyGatewayArgument(previous, settings),
    applyDNSArgument(previous, settings)
  );

export const composeApplyCommand = (previous, settings) =>
  newManagementInterfaceConfiguration(
    ...applyInterfaceSettings(previous, settings),
    ...applyIPSettings(previous, settings)
  );
