import React from "react";

const doesNothing = () => {};

const DeleteTrapButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const TrapItem = ({
  id,
  nms = [],
  type,
  community,
  even = false,
  onDelete = doesNothing,
}) => {
  const [_first, ...rest] = nms;
  const doTrapDelete = () => {
    onDelete(id);
  };

  return (
    <tr
      className={`${even ? "even" : "odd"} ${
        rest.length === 0 ? "single" : "has-many"
      }`}
    >
      <td>{nms}</td>
      <td>{type === "trap-v1" || type === "SNMPv1" ? "SNMPv1" : "SNMPv2c"}</td>
      <td>{community}</td>
      <td className="text-center hides-content-to-operators">
        <DeleteTrapButton onClick={doTrapDelete} />
      </td>
    </tr>
  );
};
const TrapsList = ({ items = [], onDelete = doesNothing }) => (
  <table id="SNMPTrapDestinationTbl" className="table table-striped">
    <thead>
      <tr>
        <th>NMS</th>
        <th>TRAP-TYPE</th>
        <th>COMMUNITY</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {items
        .filter(({ deleted }) => deleted !== true)
        .map(({ deleted, __id, ...trap }, index) => (
          <TrapItem
            key={__id}
            id={__id}
            {...trap}
            deleted={deleted}
            even={(index + 1) % 2 === 0}
            onDelete={() => onDelete(__id)}
          />
        ))}
    </tbody>
  </table>
);
export default TrapsList;
