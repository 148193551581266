const unitScale = (unit) =>
  unit === "G" ? 1 : unit === "T" ? 1000 : unit === "M" ? 0.001 : 0;

const parseVolume = (input) => {
  const matches = input.match(/(\d+\.?\d*)([MGT])/);
  if (matches === null) {
    return null;
  }
  const [_all, value, unit] = matches;
  return parseFloat(value) * unitScale(unit);
};

const parseKeys = (head, translations = {}) =>
  head.split(/\s+/).map((key) => translations[key] || key.toLowerCase());

const mountsOnRoot = (line) => line.endsWith(" /");
const storeInFields =
  (keys, skipped = []) =>
  (result, line) =>
    Object.fromEntries(
      line
        .split(/\s+/)
        .flatMap((value, index) =>
          skipped.includes(keys[index])
            ? []
            : [[keys[index], parseVolume(value)]]
        )
    );

export const parseRootUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const translations = { Avail: "free", Used: "used" };
  const keys = parseKeys(head.trim(" "), translations);
  const skipped = keys.filter(
    (key) => Object.values(translations).includes(key) === false
  );
  return rows.filter(mountsOnRoot).reduce(storeInFields(keys, skipped), {});
};
