/*globals views, rulesRate*/
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { handleWarnings } from "./api";
import ActionsContext from "common/ActionsContext";
import RatePoliciesHelpModal from "./Help";
import { safeStr } from "common/api";
import { ActionsRow, someColumnsMayBeCensored } from "views/Status/Policies/Common";
import useProfile from "common/hooks/useProfile";

const doesNothing = () => {};
const returnView = "viewRatePolicies";

const RatePolicies = () => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doRequest = () =>
    ifCl.run("show policy api full").then((response) => ({ response }));

  const doLoad = () => {
    setRequest(doRequest());
  };

  const enableViewReload = (main) => {
    if (main === null || main.current === null) {
      return;
    }
    const view = main.closest(`#${returnView}`);
    $(view).one("view:show", () => doLoad());
    console.log("enabled reload on show", main);
  };

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, []);

  return request === null ? null : (
    <div className="row clearfix" ref={enableViewReload}>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="card">
          <div className="header block-header">
            <h2>Rate Policies</h2>
            <ContextMenu doLoad={doLoad} />
          </div>
          <div className="body table-responsive">
            <ActionsRow doCreatePolicy={onAddPolicy} />
            <Request during={request}>
              {(result) => <ShowPolicies {...result} />}
            </Request>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatePolicies;

const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;


function onAddPolicy() {
  views.doKeep(returnView);
  rulesRate.data.policies.addPolicy(returnView);
}

const ContextMenu = ({ doLoad = doesNothing, doAction = doesNothing }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={onAddPolicy}>Add Policy...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const openThroughputPerPolicy = (
  policyType = "rate",
  directions = "downSpeed"
) => {
  views.doKeep(returnView);
  globalNavigate("viewThroughputPerPolicy", {
    policyType,
    directions,
    returnView,
  });
};
/*****************************************************
 * Policies Table
 * ****************************************************/

const configuredOrAssigned = (d) =>
  d === "configured" ? "yes" : d === "assigned" ? "no" : d;

const isSource = target => ([_name, _subs, _subsAc, _mbDw, _mbUp, _id, source]) =>
  source === target

const isSourceAssigned = isSource('assigned');

const isDefaultPolicy = ([name, ...row])  =>
  name === "rate-default"

const hideRatesCensorLabels = new Set([
  "BLOCK",
  "RATE-LIMIT-DOWN",
  "RATE-LIMIT-UP",
  "FLOW-LIMIT"
])

const ratePolicyActions = (row, whenDone) => [
  {
    id: "edit-config",
    label: "Edit",
    icon: isSourceAssigned(row) ? 'search' : 'edit',
    onClick: (_value, row) =>
      doClickPolicy(row),
  }, 
  ...(isDefaultPolicy(row)
    ? []
    : [
        {
          id: "delete-config",
          label: "Delete",
          icon: "delete",
          onClick: (_value, row) =>
            doDeletePolicy(row, whenDone),
        },
      ]
  ),

];
const getColumns = ({hideRates=false, dpiEnabled=false}, {reloadWhenDone=doesNothing}) => [
  Column.Text({
    label: "POLICY",
    idx: 0,
    onClick: hideRates ? null : (_value, row) => doClickPolicy(row),
    cellClassName: hideRates ? null : (value, row) =>
      `hyperlink-text ${
        row[6] === "undefined" ? " col-red  cell-with-icon-left" : ""
      }`,
    icon: (row) => {
      return row[6] === "undefined" ? "warning" : null;
    },
  }),
  Column.NumberOrNA({
    label: "SUBS-PROVISIONED",
    idx: 8,
    precision: 0,
    cellClassName: "hyperlink-text align-right",
    onClick: doOpenProvisionedubscribers,
  }),
  Column.NumberOrNA({
    label: "SUBS-ACTIVE",
    idx: 9,
    precision: 0,
    cellClassName: "hyperlink-text align-right",
    onClick: doOpenActiveSubscribers,
  }),
  Column.NumberOrNA({
    label: "MBYTES-DOWNLINK",
    idx: 10,
    precision: 3,
    cellClassName: "align-right hyperlink-text",
    onClick: () => {
      openThroughputPerPolicy("rate", "downSpeed");
    },
  }),
  Column.NumberOrNA({
    label: "MBYTES-UPLINK",
    idx: 11,
    precision: 3,
    cellClassName: "align-right hyperlink-text",
    onClick: () => {
      openThroughputPerPolicy("rate", "upSpeed");
    },
  }),
  ...someColumnsMayBeCensored(hideRates ? hideRatesCensorLabels : null)(
    Column.Text({
      label: "ID",
      idx: 1,
      precision: 0,
    }),
    Column.Value(configuredOrAssigned, {
      label: "CONFIGURED",
      idx: 2,
    }),
    Column.Text({
      label: "BLOCK",
      idx: 3,
    }),
    Column.SpeedOrNo({
      label: "RATE-LIMIT-DOWN",
      idx: 5,
      colClassName: "align-right",
    }),
    Column.SpeedOrNo({
      label: "RATE-LIMIT-UP",
      idx: 6,
      colClassName: "align-right",
    }),
    Column.Value(
    (flowLimit) => (flowLimit === 'n/a' || flowLimit === 'no') ? 'no': flowLimit,
    {
      label: "FLOW-LIMIT",
      idx: 7,
      colClassName: "align-right",
    }),
    Column.Text({
      label: "ACM",
      idx: 4,
    }),
  ),
  Column.Actions({
    label: "ACTIONS",
    idx: 0,
    are: (source, row) => ratePolicyActions(row, reloadWhenDone),
    colClassName: "text-center action",
    cellClassName: "hides-content-to-operators",
  }),
]
const ShowPolicies = ({ response }) => {
  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");
  const profile  = useProfile(); 
  return profile === null ? null : (
    <>
      <TableContainer>
        <DataTable
          pageLength={50}
          exportAsCSV={true}
          content={response}
          columns={getColumns(profile, {reloadWhenDone})}
        />
      </TableContainer>
      <RatePoliciesHelpModal />
    </>
  );
};

const doClickPolicy = (row) => {
  const [name, _subs, _subsAc, _mbDw, _mbUp, _id, source] = row
  if (source === "assigned") {
    doShowPolicy(name);
  } else if (source === "configured") {
    doEditPolicy(name);
  } else if (source === "undefined") {
    doAddPolicy(name);
  } else {
    return false;
  }
};

const requestPolicy = policy => 
  ifCl
    .run(`show policy ${safeStr(policy)}`)

const doEditPolicy = (policy) => {
    requestPolicy(policy).then((response) => {
      const ratePolicies = rulesRate.data.policies;
      const policy = ratePolicies.parsePolicy(response);
      views.doKeep(returnView);
      ratePolicies.editPolicy(policy, returnView);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const supressErrPrefix = input =>
  input.replace ? input.replace(/%ERR-.*:\s?/, ''): input;

const doShowPolicy = policy =>
  requestPolicy(policy)
    .then(response => {
      if (response.indexOf("%ERR") > -1) {
        throw(resp);
      }
      const policy = rulesRate.data.policies.parsePolicy(response);
      rulesRate.data.policies.showDynPolicy(policy, response)
    })
    .catch( error => showModalError(`Error: ${supressErrPrefix(error)}`));

const confirmCreatePolicy = (policy) =>
  new Promise((resolve, reject) =>
    showModalConfirm(
      `Confirm before continue`,
      `Warning: This policy is undefined, and has been assigned to some subscribers. Those subscribers are now getting a default policy from the configured rules. If you click "Define", you can now define this policy and then the assigned subscribers will get it.`,
      "warning",
      () => resolve(policy),
      () => reject(),
      "DEFINE",
      "RETURN"
    )
  );

const doAddPolicy = (policy) => {
  requestPolicy(policy)
    .then( _resp => {
      showModalError("Error:", "Already defined policy " + policy);
      throw `Policy already defined ${policy}`;
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        return { missing: missingName };
      }
      showModalError("Error:", error);
      throw error;
    })
    .then(confirmCreatePolicy)
    .then(({ missing }) => {
      if (missing !== undefined) {
        views.doKeep(returnView);
        rulesRate.data.policies.addPolicy(returnView, missing);
      } else {
        console.error("missing policy to create is missing");
      }
    });
};

const doDeletePolicy = (row, whenDone = doesNothing) => {
  const [name, _subs, _subsAc, _mbDw, _mbUp, _id, source] = row;
  showModalConfirm(
    `Remove "${name}" policy?`,
    "WARNING: All policy data will be lost and all subscribers assigned to this policy will now have an undefined policy, so the will be reassigned to a configured rate policy (normally rate-default).",
    "delete_forever",
    () => {
      const cmd = (source === "configured" || source === "undefined")
        ? `clear policy rate ${safeStr(name)}`
        : `clear api policy ${safeStr(name)}`;
      ifCl
        .run(cmd)
        .then(handleWarnings)
        .then(whenDone)
        .catch((error) => showModalError(error));
    }
  );
}

const doOpenProvisionedubscribers = (allSubs, row) => {
  const name = row.at(0);
  if (Number(allSubs) > 0) {
    radiusRestSubs.setSelectors(name, "");
    globalNavigate("viewSubscriberAttributes", { returnView, policy: name });
  }
};

const doOpenActiveSubscribers = (activeSubs, row) => {
  const name = row.at(0);
  if (Number(activeSubs) > 0) {
    globalNavigate("viewStatusSubscribers", { returnView, policy: name });
  }
};

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;
