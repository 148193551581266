import React, { useState } from "react";
import styled from "styled-components";

const CheckboxInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & input[type="checkbox"] {
    display: none;
  }
`;

const CheckboxInput = ({
  onChange,
  name,
  label = "Checkbox",
  disabled = false,
  className = "",
  defaultValue,
}) => {
  const [checked, setChecked] = useState(defaultValue);
  const toggle = () => setChecked((previous) => !previous);
  return (
    <CheckboxInputDiv className="input-group">
      <span className="input-group-addon">
        <label>{label}:</label>
      </span>
      <input
        type="checkbox"
        name={name}
        id={`${name}-checkbox`}
        checked={checked}
        onChange={onChange}
        className="sw-input"
      />
      <label htmlFor={`${name}-checkbox`} className="min-width-0">
        <i className="material-icons hyperlink" onClick={toggle}>
          {checked ? "check_box" : "check_box_outline_blank"}
        </i>
      </label>
    </CheckboxInputDiv>
  );
};

export default CheckboxInput;
