/* globals views, rulesRate, rulesFlow, rulesMonitor */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { parseSubscriberInformation } from "./api";
import { expressTarget, parsePoliciesIntoSets } from "common/api";
import { TargetInput } from "./TargetContext";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { safeStr } from "common/api";
import useProfile from "common/hooks/useProfile";
import { useSearchParams } from "react-router-dom";

const noIPv6Mask = { ipv6Mask: false };
const returnTo = "viewSubsDetail";

const doSubcriberInformationRetrieval = (target) =>
  expressTarget(target, noIPv6Mask).then((expressedTarget) =>
    ifCl.run(`show subscriber ${expressedTarget} short`)
  )
    .then(parseSubscriberInformation)
    .catch(error => console.error("error doing show subscriber <target> short", error));

const doRequestPolicy = (name) =>
  ifCl.run(`show policy ${safeStr(name)}`)
    .catch( error => console.error(`error retrieving ${name}: ${error}`) || null);

const SubscriberInformationFieldsLayout = styled.div`
  padding-left: 3rem;
  padding-right: 1rem;
  .fact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 265px;
    color: #777;
    .field {
      font-size: 1em;
      text-align: left;
    }
    .value {
      text-align: right;
      font-weight: 700;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
    }
    &.set-1.odd {
      grid-column: 1/2;
    }
    &.set-1.even {
      grid-column: 2/3;
    }
    @media only screen and (max-width: 600px) {
      &.set-1.odd,
      &.set-1.even {
        grid-column: 1/2;
      }
    }
  }
  .grid-dense {
    grid-auto-flow: row dense;
  }
`; //

const CenteredDiv = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubscriberInformationContextMenu = ({ doReload = doesNothing }) => {
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={doReload}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};
const NothingToShow = ({ targetType = "Subscriber", onLoaded, target }) => {
  useEffect(() => {
    onLoaded();
  }, [target])

  return <CenteredDiv>
    <h4>{targetType} currently not active.</h4>
  </CenteredDiv>
}

const SubscriberInformationHeaderDiv = styled.div`
  &.header {
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0 0 1rem 1rem;
    align-items: center;
    justify-content: space-between;
    align-items: self-start;
    & .header-dropdown {
      position: initial;
      top: 0;
      right: 0;
      margin: 0 0 0 1rem;
    }

    @media print {
      display: none;
    }
  }
  & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
    text-transform: uppercase;
  }
`;

const FactsColumns = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 115px;
`;

const FactsRow = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  column-gap: 115px;
`;

const AdditionalDetailsStyled = styled.div`
  width: 145px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const doesNothing = () => {};

const ShowField = ({ field }) => {
  if (!field) {
    return <div className="fact"></div>;
  }

  return (
    <div className="fact" key={field.label}>
      <span className="field">{field.label}:</span>
      <span className="value">{field.value}</span>
    </div>
  );
};

const properParserFor = (name, policies) =>
  policies.types.rate.has(name)
    ? response => rulesRate.data.policies.parsePolicy(response)
    : policies.types.flow.has(name)
    ? response => rulesFlow.data.policies.parsePolicy(response)
    : response => rulesMonitor.data.policies.parsePolicy(response);

const properOutcome = (name, policies, onLeavingPage, params) =>
  ({policy, response}) =>  {
    if (policies.sources.static.has(name) !== true) {
      rulesRate.data.policies.showDynPolicy(policy, response);
    } else if (policies.types.rate.has(name)) {
      onLeavingPage();
      rulesRate.data.policies.editPolicy(policy, returnTo, params);
    } else if (policies.types.flow.has(name)) {
      onLeavingPage();
      rulesFlow.data.policies.editPolicy(policy, returnTo, params);
    } else {
      onLeavingPage();
      rulesMonitor.data.policies.editPolicy(policy, returnTo, params);
    }
  }


const ShowPolicyField = ({
  field,
  type,
  providePolicy,
  openPolicy = doesNothing,
}) => {
  const profile = useProfile();
  if (!field) {
    return <div className="fact"></div>;
  }
  if (field.value === "n/a") {
    return (
      <div className="fact" key={field.label}>
        <span className="field">{field.label}:</span>
        <span className="value">n/a</span>
      </div>
    );
  }
  return profile === null ? null : (<Request during={providePolicy(field.value)}>
  {(policy) => (
    <div className="fact" key={field.label}>
      <span className="field">{field.label}:</span>
      <span
        className={`value ${policy !== null && profile.hideRates !== true ? "hyperlink-text" : ""}`}
        onClick={profile.hideRates ? null : () => openPolicy(field.value, type)}
      >
        {field.value}
      </span>
    </div>
  )}
</Request>);
};

const ShowSubsGroupField = ({ field, ipAddress, onLeavingPage, target, dpiEnabled, timePeriod }) => {
  if (!field) {
    return <div className="fact"></div>;
  }
  return (
    <div className="fact" key={field.label}>
      <span className="field">{field.label}:</span>
      <span
        className="value hyperlink-text"
        onClick={() => {
          onLeavingPage();
          globalNavigate("viewStatusSubscriberGroups", {
            returnView: returnTo,
            ipAddress,
            ...target,
            dpiEnabled,
            timePeriod,
            use: target.subsId ? 'subsId' : 'ipAddress'
          });
        }}
      >
        yes
      </span>
    </div>
  );
};

const ShowSubsGroupLink = ({ field, ipAddress, onLeavingPage, target, dpiEnabled, timePeriod }) => {
  if (!field) {
    return <div className="fact"></div>;
  }
  return (
    <div className="fact" key={field.label}>
      <span
        className="value hyperlink-text"
        onClick={() => {
          onLeavingPage();
          globalNavigate("viewStatusSubscriberGroups", {
            returnView: returnTo,
            ipAddress,
            ...target,
            dpiEnabled,
            timePeriod,
            use: target.subsId ? 'subsId' : 'ipAddress'
          });
        }}
      >
        View subscriber groups
      </span>
    </div>
  );
};

const ShowQuotaField = ({ field, ipAddress, onLeavingPage, target, dpiEnabled }) => {
  if (!field) {
    return <div className="fact"></div>;
  }
  return (
    <div className="fact" key={field.label}>
      <span className="field">{field.label}:</span>
      <span
        className={`value ${field.value === "yes" ? "hyperlink-text" : ""}`}
        onClick={() => {
          if (field.value === "no") {
            return;
          }
          onLeavingPage();
          globalNavigate("viewSubscriberQuotas", {
            returnView: returnTo,
            subnet: ipAddress,
            ...target,
            dpiEnabled,
          });
        }}
      >
        {field.value}
      </span>
    </div>
  );
};

let showAdditionalDetails = false;

const ShowFacts = ({ items, policies, providePolicy, onLeavingPage, target, dpiEnabled, timePeriod }) => {
  const openPolicy = (name, type) => {
    const params = {...target, dpiEnabled};
    providePolicy(name).then(properOutcome(name, policies, onLeavingPage, params))
  };

  useEffect(() => {
    if(showAdditionalDetails){
      $('#subcriberInformation').collapse('show')
    }
  }, [])

  return (
    <>
      <FactsColumns>
        <FactsColumns>
          <ShowField field={items[0]} />
          <ShowField field={items[1]} />
        </FactsColumns>
        <FactsColumns>
          <ShowPolicyField field={items[3]} type="monitor" providePolicy={providePolicy} openPolicy={openPolicy} />
          <ShowPolicyField field={items[4]} type="rate" providePolicy={providePolicy} openPolicy={openPolicy} />
        </FactsColumns>
      </FactsColumns>
      <FactsRow>
        <AdditionalDetailsStyled onClick={() => showAdditionalDetails = !showAdditionalDetails}>
          <a
            role="button"
            className="collapsed-arrow collapsed btn-link"
            data-toggle="collapse"
            href="#subcriberInformation"
            aria-expanded="true"
            aria-controls="subcriberInformation"
          >
            Additional details
          </a>
        </AdditionalDetailsStyled>
        <ShowSubsGroupLink 
          field ={items[20]} 
          ipAddress={items[0].value} 
          onLeavingPage={onLeavingPage} 
          target={target} 
          dpiEnabled={dpiEnabled} 
          timePeriod={timePeriod}/>
      </FactsRow>

      <div id="subcriberInformation" className="panel-collapse collapse">
        <FactsColumns>
          <FactsColumns>
            <div><ShowField field={items[2]}/></div>
            <div><ShowField /></div>
          </FactsColumns>
          <FactsColumns>
            <div><ShowField /></div>
            <div><ShowField /></div>
          </FactsColumns>
        </FactsColumns>
        <FactsColumns>
          <FactsColumns>
            <div>
              <ShowField field={items[5]} />
              <ShowField field={items[7]} />
              <ShowField field={items[9]} />
              <ShowField field={items[11]} />
            </div>
            <div>
              <ShowField field={items[6]} />
              <ShowField field={items[8]} />
              <ShowField field={items[10]} />
              {login.isPlatform('gs-kr') ? null :<ShowField field={items[12]} />}
            </div>
          </FactsColumns>
          <FactsColumns>
            <div>
              {login.isPlatform('gs-kr') ? null :<ShowField field={items[13]} />}
              <ShowField field={items[15]} />
              <ShowField field={items[17]} />
              <ShowQuotaField field={items[19]} ipAddress={items[0].value} onLeavingPage={onLeavingPage} target={target} dpiEnabled={dpiEnabled} />
            </div>
            <div>
              {login.isPlatform('gs-kr') ? null :<ShowField field={items[14]} />}
              <ShowField field={items[16]} />
              <ShowField field={items[18]} />
              <ShowSubsGroupField field ={items[20]} ipAddress={items[0].value} onLeavingPage={onLeavingPage} target={target} dpiEnabled={dpiEnabled} timePeriod={timePeriod}/>
            </div>
          </FactsColumns>
        </FactsColumns>
      </div>
    </>
  );
};
ShowFacts.displayName = 'ShowFacts'

const ShowSingleDevice = ({ item, policies, onLeavingPage, providePolicy, target, dpiEnabled, onLoaded, timePeriod }) => (
  <ExpandQuotaAndGroupsFacts items={item.facts} onLoaded={onLoaded}>
    {(items) =>
      <ShowFacts
        items={items}
        policies={policies}
        providePolicy={providePolicy}
        onLeavingPage={onLeavingPage}
        target={target}
        dpiEnabled={dpiEnabled}
        timePeriod={timePeriod}
      />}
  </ExpandQuotaAndGroupsFacts>
);

const DevicesSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .input-group {
    width: auto;
    margin: 0 1.5em;
  }
`;

const parseQuotaAndGroups = (response) => {
  let subsGroups, quota;
  try {
    const lines = response.trim("\n").split("\n");
    for (const line of lines) {
      const [key, value] = line.split(/\:\s+/);
      if (
        (key === "Permanent direct subscriber-groups" ||
          key === "Non-permanent direct subscriber-groups" ||
          key === "Permanent indirect subscriber-groups" ||
          key === "Non-permanent indirect subscriber-groups") &&
        value !== "n/a"
      ) {
        subsGroups += value;
      } else if (key === "Quota state" && value !== "n/a") {
        quota = value;
      }

      if (subsGroups && quota) {
        break;
      }
    }
  } catch (e) {}
  return [
    { label: "Quota", value: quota ? "yes" : "no" },
    { label: "Subscriber groups", value: subsGroups ? "yes" : "no" },
  ];
};

const doSubscriberApiRequest = ipAddress =>
  ipAddress === null
  ? null
  : ifCl.run(`show api subscriber ${ipAddress}`)
      .then(parseQuotaAndGroups)
      .catch( error => {
        console.error("show api subscriber error:",error);
        return [
          { label: "Quota", value: "no" },
          { label: "Subscriber groups", value: "no" }
        ];
      })
      .then(extraItems => ({extraItems}));

const ExpandQuotaAndGroupsFacts = ({items=[], children, onLoaded}) => {
  const [request, setRequest] = useState(null);

  useEffect(() => {
    if(request){
      request.then(() => onLoaded())
    }
  }, [request])

  useEffect( () => {
    const ipAddress = items.length > 0 ? items[0].value : null;
    setRequest(doSubscriberApiRequest(ipAddress))
  }, [items])

  return request === null
    ? children(items)
    : <Request during={request}>
        {({extraItems}) => children([...items, ...extraItems])}
      </Request>
}

const ShowDevices = ({
  items = [],
  defaultPosition = 0,
  policies,
  providePolicy,
  onLeavingPage,
  target,
  dpiEnabled,
  onLoaded,
  timePeriod
}) => {
  const [position, setPosition] = useState(defaultPosition);

  const options = items.map(({ device }, index) => {
    return { value: index, label: device };
  });

  const handleChange = ({target})=> {
    setPosition(parseInt(target.value));
  }

  return (
    <>
      <DevicesSelection className="input-section">
        <span>Showing IP Address:</span>
        <SelectInputWithIcon
            title="IP Address"
            name="IP Address"
            selected={position}
            onChange={handleChange}
            options={options}
          />
        <span> from a total of {items.length}</span>
      </DevicesSelection>
      <ExpandQuotaAndGroupsFacts items={items[position].facts} onLoaded={onLoaded}>
        {(items) =>
          <ShowFacts
            items={items}
            policies={policies}
            providePolicy={providePolicy}
            onLeavingPage={onLeavingPage}
            target={target}
            dpiEnabled={dpiEnabled}
            timePeriod={timePeriod}
          />}
      </ExpandQuotaAndGroupsFacts>
    </>
  );
};

const typeOfTarget = ({ addr = null, subsId = null }) =>
  addr !== null ? "IP Address" : "Subscriber";

const doRequestPolicyTypes = () =>
  ifCl.run("show policy").then(parsePoliciesIntoSets)

const doRequestFactsAndPolicies = ({target, ...params}) =>
  target === undefined? null
  : Promise.all([
    doSubcriberInformationRetrieval(target),
    doRequestPolicyTypes()
  ]).then(([items=null, policies=null]) => ({items, policies, ...params}))


const SubscriberInformation = ({ target, onLeavingPage=doesNothing, setLiveMode, liveMode, dpiEnabled, onLoaded, onBeforeLoad}) => {
  const [request, setRequest,] = useState(null);
  const actions = useContext(ActionsContext);
  const onLeavingThisPage = React.useCallback(onLeavingPage, []);
  const [policyRequests, setPolicyRequests] = useState({});
  let [searchParams, _] = useSearchParams();

  const timePeriod = searchParams.get('timePeriod');

  const providePolicyGiven = policies =>
    (name) =>
      policyRequests[name] || new Promise( (resolve) => {
        const request = doRequestPolicy(name)
          .then(response => ({
            policy: properParserFor(name, policies)(response),
            response
          }))
        setPolicyRequests( previous => ({
          ...previous,
          [name]: request,
        }))
        resolve(request)
      })

  const doLoad = (loadTarget) =>
    setRequest(doRequestFactsAndPolicies({target: loadTarget}))

  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";

  useEffect(() => {
    doLoad(target);
  }, [targetStr]);

  const doSubmit = (target) => {
    if((target.addr ? target.addr : target.subsId) === targetStr){
      return;
    }

    onBeforeLoad();
    actions.send("do-load-details", target);
    if (!liveMode) {
      setLiveMode(undefined);
    }
  };
  return (<>
    <SubscriberInformationHeaderDiv className="header">
      <TargetInput target={target} onSubmit={doSubmit} />
      <SubscriberInformationContextMenu doReload={() => doLoad(target)} />
    </SubscriberInformationHeaderDiv>
    {request === null ? null : (
      <Request during={request}>
        { ({policies, items})  =>
          <SubscriberInformationFieldsLayout className="subscriber-info-fields-container">
            {items === null || items.length === 0 ? (
              <NothingToShow targetType={typeOfTarget(target)} onLoaded={onLoaded} target={target}/>
            ) : items.length === 1 ? (
              <ShowSingleDevice
                item={items[0]}
                policies={policies}
                onLeavingPage={onLeavingThisPage}
                providePolicy={providePolicyGiven(policies)}
                target={target}
                dpiEnabled={dpiEnabled}
                onLoaded={onLoaded}
                timePeriod={timePeriod}
              />
            ) : (
              <ShowDevices
                items={items}
                policies={policies}
                onLeavingPage={onLeavingThisPage}
                providePolicy={providePolicyGiven(policies)}
                target={target}
                dpiEnabled={dpiEnabled}
                onLoaded={onLoaded}
                timePeriod={timePeriod}
              />
            )}
          </SubscriberInformationFieldsLayout>
        }
      </Request>)
    }
  </>);
};

export default SubscriberInformation;
