import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { getIPv6Prefix } from "common/api";
import ListSubscribers from "./ListSubscribers";
import ActionsContext from "common/ActionsContext";
import { safeStr } from "common/api";

const statePolicy = (policy) => (policy === "all" ? "" : `policy ${safeStr(policy)}`);

const createPlaceholder = () =>
  "ADDRESS         SUBSCRIBER-ID   USER-NAME       CALL-STATION-ID NAS-IDENTIFIER  NAS-IP-ADDRESS  NAS-PORT ACCT-SESS-ID  SOURCE   BLOCK POLICY                    QUOTA                  LIMIT-TIME      LIMIT-VOLUME CREATED              UPDATED\n";

const ensurePlaceholder = () => (_error) => {
  return { response: createPlaceholder() };
};

const ensureCIDR = (subnet) =>
  Promise.resolve(
    subnet.length === 0 || subnet.includes("/") ? subnet : addCIDR(subnet)
  );

const addCIDR = (subnet) =>
  subnet.includes(":")
    ? /*ipv6*/ getIPv6Prefix().then((prefix) => `${subnet}/${prefix}`)
    : `${subnet}/32`;

const doRequest = ({ policy, subnet, lines }) =>
  ensureCIDR(subnet).then((cidrSubnet) =>
    ifCl
      .run(
        `show api ${statePolicy(policy)} subscriber ${
          cidrSubnet.length === 0 ? "" : cidrSubnet
        } web lines ${lines}`
      )
      .then((response) => {
        return { response };
      })
      .catch(ensurePlaceholder())
  );

const ResultsLayout = styled.div`
  flex: 100%;
  overflow: hidden; /*list will scroll*/
`;

const Report = ({ policy, subnet, lines, ...otherSettings }) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [policy, subnet, lines]);
  const doLoad = () => setRequest(doRequest({ policy, subnet, lines }));
  return request === null ? null : (
    <>
      <ResultsLayout>
        <ListSubscribers request={request} subnet={subnet} {...otherSettings} />
      </ResultsLayout>
    </>
  );
};

export default Report;
