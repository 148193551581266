import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";

const doesNothing = () => {};

const TickOrCross = ({ ok = false }) => (
  <i className={`material-icons ${ok ? "col-green" : "col-red"}`}>
    {ok ? "check" : "close"}
  </i>
);

const Management = ({ is = false }) =>
  is !== true ? null : <i className="material-icons">radio_button_checked</i>;

const InterfaceName = ({ name, type, onClick = doesNothing }) => (
  <a
    className="hyperlink-text"
    onClick={() => onClick("details", { name, type })}
  >
    {name}
  </a>
);

const Captures = ({ name, type = "ethernet", onClick = doesNothing }) => (
  <a
    className="action margin-l-15"
    title="Capture"
    onClick={() => onClick("capture", { name, type })}
  >
    <i className="material-icons">search</i>
  </a>
);

const InterfaceRow = ({
  name,
  type,
  mac,
  enabled,
  connected,
  management,
  onClick = doesNothing,
}) => (
  <tr>
    <td>
      <InterfaceName name={name} type={type} onClick={onClick} />
    </td>
    <td>{type}</td>
    <td>{mac}</td>
    <td className="text-center">
      <TickOrCross ok={enabled} />
    </td>
    <td className="text-center">
      <TickOrCross ok={connected} />
    </td>
    <td className="text-center">
      <Management is={management} />
    </td>
    <td className="text-center actions">
      {type === "loopback" ? null : (
        <Captures {...{ name, type }} onClick={onClick} />
      )}
    </td>
  </tr>
);
const ListInterfaces = ({
  interfaces = [],
  onInterfaceClick = doesNothing,
}) => (
  <table className="table table-striped margin-t-15 margin-b-0">
    <thead>
      <tr>
        <th>NAME</th>
        <th>TYPE</th>
        <th>MAC</th>
        <th className="text-center">UP</th>
        <th className="text-center">LINK</th>
        <th className="text-center">MGT</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {interfaces.map((current) => (
        <InterfaceRow
          key={current.name}
          {...current}
          onClick={onInterfaceClick}
        />
      ))}
    </tbody>
  </table>
);

const Status = ({ provider }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    const doingRetrieval = provider().catch((error) => error);
    setRequest(doingRetrieval);
  };

  const onInterfaceClick = (field, params) =>
    actions.send(`show-interface-${field}`, params);

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", () => doLoad());
  }, []);
  return request === null ? null : (
    <Request during={request}>
      {({ ...result }) => (
        <ListInterfaces {...result} onInterfaceClick={onInterfaceClick} />
      )}
    </Request>
  );
};

export default Status;
