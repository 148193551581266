export const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};
const parse = (value) =>
  value === "on"
    ? true
    : value === "off"
    ? false
    : parseAsNumber(value) || value;

const parseAsNumber = (value, delimiter = ".") => {
  const parsed = value.includes(delimiter)
    ? parseFloat(value)
    : parseInt(value);
  return isNaN(parsed) ? value : parsed;
};

const intoStructure = (settings, [[field, ...rest], value]) => ({
  ...settings,
  ...{
    [field]:
      rest.length === 0
        ? parse(value)
        : intoStructure(settings[field], [rest, value]),
  },
});

export const rankAndParseFields = (source, defaults) =>
  Object.entries(source)
    .map(([field, value]) => [field.split("."), value])
    .reduce(intoStructure, defaults);
