import React, { useRef } from "react";
import TextInput from "common/TextInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const SystemSettings = ({ onChange, defaults = {} }) => {
  const form = useRef(null);
  const onInputChange = () => {
    const settings = getFormData(form.current);
    onChange(settings);
  };
  return (
    <form ref={form}>
      <ArrangeInColumns rowGap="0">
        <TextInput
          type="text"
          name="name"
          label="Name:"
          defaultValue={defaults.name}
          onChange={onInputChange}
          className="input-control"
          disabled={false}
          autocomplete={"off"}
        />
        <TextInput
          type="text"
          name="description"
          label="Description:"
          defaultValue={defaults.description}
          onChange={onInputChange}
          className="input-control"
          disabled={false}
          autocomplete={"off"}
        />
        <TextInput
          type="text"
          name="contact"
          label="Contact:"
          defaultValue={defaults.contact}
          onChange={onInputChange}
          className="input-control"
          disabled={false}
          autocomplete={"off"}
        />
        <TextInput
          type="text"
          name="location"
          label="Location:"
          defaultValue={defaults.location}
          onChange={onInputChange}
          className="input-control"
          disabled={false}
          autocomplete={"off"}
        />
      </ArrangeInColumns>
    </form>
  );
};

export default SystemSettings;
