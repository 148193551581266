import React, { useContext } from "react";
import WiresSettings from "./Wires";
import ActionsContext from "common/ActionsContext";
import DataWiresConfigHelpModal from "./Help";
import { load } from "views/Status/Interfaces/DataWires/component";
import { composeApplyCommand } from "./api";
import DetailModal from "views/Status/Interfaces/LinkState/Detail";
import CaptureModal from "views/Status/Interfaces/LinkState/Capture/index.jsx";
import SettingsView from "common/SettingsView";

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const ContextMenu = ({ goBack = null }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      {goBack === null ? null : (
        <a
          onClick={goBack}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Back to list"
        >
          <i className="material-icons">
            <small>exit_to_app</small>
          </i>
        </a>
      )}
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={() => actions.send("new-wire")}>New Wire...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};
const ShowDataWiresSettings = ({
  defaults,
  state,
  onStateUpdate,
  ..._others
}) => (
  <div className="body">
    <div className="row">
      <WiresSettings
        wires={defaults.wires}
        interfaces={defaults.interfaces}
        onChange={(wires) => onStateUpdate({ wires })}
      />
    </div>
  </div>
);

const DataWiresConfig = () => {
  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(() => composeApplyCommand(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <>
      <SettingsView
        className="container-lg"
        load={load}
        apply={apply}
        clear={clear}
        ContextMenu={ContextMenu}
        title="Wires Configuration"
        applyActionClassName="hidden-to-operators"
        wrapIntoForm={false}
        subtitle={
          <small>
            Press <code>Apply</code> button at the end of the page to set the
            new configuration values.
          </small>
        }
      >
        <ShowDataWiresSettings />
      </SettingsView>
      <DataWiresConfigHelpModal />
      <CaptureModal />
      <DetailModal />
    </>
  );
};

export default DataWiresConfig;
