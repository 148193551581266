const appliedStrategy = (file, strategy) =>
  strategy === "merge_file"
    ? `${file} merge`
    : strategy === "restore_local"
    ? `${file} restore`
    : file;

export const composeApplyCommand = (
  _originalSettings,
  { strategy, filePath }
) =>
  `configure
  load config ${appliedStrategy(filePath, strategy)}
  commit
  end`;

const thereAreErrors = (input) => {
  const match = input.match(/\((\d+)\serrors\)/);
  if (match === null) {
    return false;
  }
  const [_all, counter] = match;
  return counter !== "0";
};

export const checkWarnings = (response) => {
  let match = null;
  const warningExpr = /,?%(WARN|ERR-ENOENT):\s([^\n]+)/g;
  while ((match = warningExpr.exec(response))) {
    const [all, type, message] = match;
    if (type === 'ERR-ENOENT' || thereAreErrors(message, type)) {
      throw message;
    }
    response = response.replace(all, "");
  }
  return response;
};
