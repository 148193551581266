import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import NVStackedAreaChart, {
  tooltipContentGeneratorForUnits,
  formats,
} from "common/graphs/NVStackedAreaChart";
import RangeSliderInput from "common/RangeSliderInput";
import * as d3 from "d3";
import ReportContextControls from "../ReportContextControls";
import { retrieveRange } from "common/api";
import { useSearchParams } from "react-router-dom";
import { toObjectLiteral } from "common/utils";

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const getFieldsOf = (groupName) => {
  const getGroupFields = ([item, ...rest]) =>
    item === undefined
      ? []
      : groupName in item
      ? item[groupName].map(({ name }) => ({ name, label: name }))
      : getGroupFields([...rest]);
  return getGroupFields;
};

const getFieldsLanes = (groupName, valueName) => (items) =>
  items.map(({ time, ...content }) =>
    Object.fromEntries([
      ["time", time],
      ...(content[groupName] || []).map(({ name, ...values }) => [
        name,
        values[valueName],
      ]),
    ])
  );

const UsageGraphLayout = styled.div`
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
`;
const CenteredMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;
const NoDataFound = ({ message = "No data found" }) => (
  <CenteredMessage>
    <h4>{message}</h4>
  </CenteredMessage>
);

const ReportResult = ({
  Totals = NoTotals,
  from = "speeds",
  itemsFrom,
  itemsValueFrom,
  graphTitle = null,
  yAxisUnits,
  yAxisPrecision,
  range: wholeRange = null,
  context = {},
  noDataMessage = undefined,
  participants = undefined,
  onSeriesInspect = undefined,
  ...result
}) => {
  const data = result[from];
  const [range, setRange] = useState(wholeRange);
  const onChange = (value) => setRange(value);
  const onInspect = onSeriesInspect === undefined
    ? undefined
    : (series) => onSeriesInspect(series, context)
  return data && data.length > 0 ? (
    <>
      <UsageGraph
        items={data}
        range={range}
        itemsFrom={itemsFrom}
        itemsValueFrom={itemsValueFrom}
        graphTitle={graphTitle}
        yAxisUnits={yAxisUnits}
        yAxisPrecision={yAxisPrecision}
        yAxisFormat={yAxisPrecision === 0 ? formats.noDecimals : undefined}
        participants={participants}
        context={context}
        onSeriesInspect={onInspect}
      />
      <RangeSliderInput
        {...wholeRange}
        className="full-width margin-t-20 margin-b-20"
        onChange={onChange}
      />
      <Totals {...result} />
    </>
  ) : (
    <NoDataFound message={noDataMessage} />
  );
};

const BigGraph = styled.div`
  min-height: 12cm;
  & svg {
    min-height: 500px;
  }
  @media print {
    width: 100% !important;
    overflow: hidden;
  }
`;
const NoTotals = () => console.log("no totals configured ") || null;
const alongsideRange = (range, items) =>
  range === null || items.length === 0
    ? items
    : extentToRangeLimits(range, items);

const fixToTime = (moment, item) => 
({
  ...item,
  time: moment,
})

const extentToRangeLimits = (range, items) => [
  fixToTime(range.from, items.at(0)),
  ...items.filter(isInsideRange(range)),
  fixToTime(range.to, items.at(-1)),
];

const evaluateContext = (context, from) =>
  typeof from === "function" ? from(context) : from;

const asNameLabel = (names) => names.map((name) => ({ name, label: name }));
const UsageGraph = ({
  items,
  range,
  participants,
  itemsFrom = "services",
  itemsValueFrom = "speed",
  graphTitle = null,
  yAxisUnits = "Mbps",
  yAxisFormat = undefined,
  context = undefined,
  onSeriesInspect = undefined,
}) => {
  const [itemsToRender, setItemsToRender] = useState(null);

  useEffect(() => {
    setItemsToRender(
      alongsideRange(
        range,
        getFieldsLanes(
          itemsFrom,
          evaluateContext(context, itemsValueFrom),
          participants
        )(items)
      )
    );
  }, [range]);

  return (
    <UsageGraphLayout className="group margin-t-20">
      {graphTitle === null ? null : <h4>{graphTitle}</h4>}
      <BigGraph className="big graph">
        <NVStackedAreaChart
          items={itemsToRender}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          xField="time"
          fields={
            participants === undefined
              ? getFieldsOf(itemsFrom)(items)
              : asNameLabel(participants)
          }
          yAxisUnits={yAxisUnits}
          yAxisFormat={yAxisFormat}
          tooltipContentGenerator={tooltipContentGeneratorForUnits(
            yAxisUnits,
            yAxisFormat
          )}
          yAxisTopGap={0.05}
          onSeriesInspect={onSeriesInspect}
        />
      </BigGraph>
    </UsageGraphLayout>
  );
};

const ReportRequest = ({
  children,
  actions,
  provider,
  hours,
  directions,
  categories,
  filter,
  output,
  target,
  policyType,
  ...rest
}) => {
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState(null);
  const doLoad = () => {
    const doingRetrieval = retrieveRange(hours)
      .then((range) => setRange(range) || range)
      .then(() =>
        provider({
          hours,
          directions,
          categories,
          filter,
          output,
          target,
          policyType,
          range,
        })
      );
    setRequest(doingRetrieval);
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [
    hours,
    directions,
    categories,
    filter,
    output,
    output,
    target,
    policyType,
  ]);
  const context = {
    hours,
    directions,
    categories,
    filter,
    output,
    target,
    policyType,
  };
  return request === null ? null : (
    <Request during={request}>
      {(result) => children({ ...result, ...rest, range, context })}
    </Request>
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
  categories: 20,
  directions: "all",
  output: "ip-addresses",
  target: { subsId: null, addr: null },
  policyType: "rate",
};

const buildInitialContext = (settings, searchParams, defaults) =>
  Object.fromEntries(
    Object.entries(defaults).map(([field, defaultValue]) => [
      field,
      (field in settings && settings[field].selected) || 
      (field==='target'?{ subsId: searchParams.subsId, addr: searchParams.addr }:searchParams[field]) || 
      defaultValue,
    ])
  );
const ReportContext = ({
  provider,
  actions,
  enabled,
  settings = {},
  ...rest
}) => {
  let [searchParams, _] = useSearchParams();

  const [context, setContext] = useState(
    buildInitialContext(settings, toObjectLiteral(searchParams), _defaultContext)
  );
  const updateContext = ({
    hours,
    directions,
    categories,
    filter,
    output,
    target,
    policyType,
  }) =>
    setContext((prev) => ({
      ...prev,
      ...(hours === undefined ? {} : { hours }),
      ...(directions === undefined ? {} : { directions }),
      ...(categories === undefined ? {} : { categories }),
      ...(filter === undefined ? {} : { filter }),
      ...(output === undefined ? {} : { output }),
      ...(target === undefined ? {} : { target }),
      ...(policyType === undefined ? {} : { policyType }),
    }));
  return (
    <>
      <ReportContextControls
        {...context}
        settings={settings}
        onChange={updateContext}
        enabled={enabled}
      >
        <ReportRequest
          provider={provider}
          {...context}
          actions={actions}
          {...rest}
        >
          {(result) => <ReportResult context={context} {...result} {...rest} />}
        </ReportRequest>
      </ReportContextControls>
    </>
  );
};

export default ReportContext;
