import React from "react";
import styled from "styled-components";
import provideUserFile from "common/ProvideFile";
import SettingsView, { NoContextMenu } from "common/SettingsView";
import TextInput from "common/TextInput";
import { requestMoment } from "views/Status/Billing/api";

const Interactive = styled.div`
  margin: 1em;
  & > * {
    margin: 1em 0;
  }
  & .error,
  & .success {
    text-align: center;
    margin: 0.5em;
  }
  & .error {
    color: red;
  }
  input[type="file"] {
    display: none;
  }
`;

const DownloadSettings = ({ onChange }) => (
  <Interactive>
    <b>NOTE:</b> File will be stored in the default Downloads directory.
    <TextInput
      type="text"
      name="filename"
      label="File Name:"
      required={true}
      icon="folder"
      placeHolder={"Desired file name"}
      className="input-control"
      defaultValue={""}
      onChange={onChange}
    />
  </Interactive>
);

const BackupSave = () => {
  const provideFileAs = (filename) => (content) =>
    provideUserFile(filename, content);

  const doRequestConfig = () => ifCl.run("show config running all");

  const apply = ({ filename }, _originalSettings = {}) => {
    return doRequestConfig().then(provideFileAs(filename));
  };
  const load = () => requestMoment();
  return (
    <SettingsView
      load={load}
      apply={apply}
      ContextMenu={NoContextMenu}
      title="Save backup configuration file"
      applyActionClassName="hidden-to-operators"
      noDiscard={true}
      PendingChangesIndicator={null}
      applyLabel="SAVE"
    >
      <DownloadSettings />
    </SettingsView>
  );
};

export default BackupSave;
