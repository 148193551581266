/*globals views, components */
import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import Request from "common/Request";
import BarPlot from "common/graphs/BarPlot";
import { expressTarget } from "common/api";
import styled from "styled-components";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import useProfile from "common/hooks/useProfile";

const parseCategoryScope = (response) => {
  const [_head, ...rows] = response.trim("\n").split("\n");
  const labelAndValue = (line) => {
    const [label, valueStr] = line.split(/\s+/);
    return [label, Number(valueStr)];
  };
  const items = rows.map(labelAndValue);
  const [_totalLabel, total] = items.pop();
  return { items, total };
};
const expressTargetRole = (role, grouping) =>
  role === "servers"
    ? "servers"
    : grouping === "ip-addresses"
    ? "client-ips"
    : grouping === "subscriber-ids"
    ? "subscribers-ids"
    : "client-ips";

const doRequest = ({
  domain,
  target,
  hours = 1,
  role = "servers",
  direction = "all",
  lines = 10,
  dpiFilter = "",
  grouping = "ip-addresses",
}) =>
  expressTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics dpi domain ${domain} ${expressedTarget} ${expressTargetRole(
        role,
        grouping
      )} hours ${hours} lines ${lines} direction ${direction} ${dpiFilter}`
    )
  );

const hideModal = () =>
  $("#categoryInspectionModal").modal('hide');

const showModal = () =>
  $("#categoryInspectionModal").modal('show');

const labelPercentWithPrecission =
  (precision = 2) =>
  (d) =>
    d.value === null ? "--" : `${d.value.toFixed(precision)}%`;

const labelPercent = labelPercentWithPrecission(2);
const labelPercentNoDecimals = labelPercentWithPrecission(0);

const asKeyAndPercent = ({ items = [], total = 1 }) =>
  items.map(([key, value]) => ({ key, value: (value / total) * 100 }));

const _MARGIN = { top: 20, right: 1, bottom: 70, left: 75 };

const ScopeGraph = ({
  category,
  params,
  role,
  grouping = "",
  onClick = null,
}) => {
  const [request, setRequest] = useState(null);
  const doLoad = () =>
    setRequest(
      doRequest({ domain: category.name, role, grouping, ...params })
        .then(parseCategoryScope)
        .catch(console.error)
    );
  useEffect(() => {
    doLoad();
  }, [category, params, role, grouping]);
  return request === null ? null : (
    <Request during={request}>
      {(result) => (
        <BarPlot
          className="bar-plot-graph"
          key={"label"}
          grid={true}
          valueLabel={labelPercent}
          yAxisLabel={labelPercentNoDecimals}
          items={asKeyAndPercent(result)}
          allYValues={[0, 100]}
          margin={_MARGIN}
          onClick={onClick}
        />
      )}
    </Request>
  );
};

const ScopeGraphLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  svg rect.bar {
    fill: ${(props) => props.color};
  }
`;

const clickOnServerGiven =
  (returnTo) =>
  ({ key: addr }) => {
    views.doKeep(returnTo);
    globalNavigate("viewDPILatency", {
      returnView: returnTo,
      destination: addr,
    });
    hideModal();
  };

const ServersScopeGraph = ({ category = {}, sourceView, ...params }) => (
  <ScopeGraphLayout color={category.color}>
    <h4>Main servers</h4>
    <small>Click on each IP address to see uplink latency information</small>
    <ScopeGraph
      role="servers"
      category={category}
      {...params}
      onClick={clickOnServerGiven(sourceView)}
    />
  </ScopeGraphLayout>
);

const _groupingChoices = [
  { value: "ip-addresses", label: "Clients by IP" },
  { value: "subscriber-ids", label: "Clients by Subs-ID" },
];

const ClientGroupingSelection = ({
  children,
  selected = "ip-addresses",
  ...params
}) => {
  const [grouping, setGrouping] = useState(selected);

  const handleChange = ({target}) => {
    setGrouping(target.value)
  }

  return (
    <>
      {children({ ...params, grouping })}
      <div className="input-group centered">
        <SelectInputWithIcon
          title="Output grouping"
          name="Output grouping"
          selected={grouping}
          onChange={handleChange}
          options={_groupingChoices}
        />
      </div>
    </>
  );
};
const clickOnClientGiven =
  (grouping, returnTo) =>
  ({ key }) => {
    const target = grouping === "subscriber-ids" ? "subsId" : "addr";
    views.doKeep(returnTo);
    globalNavigate("viewSubsDetail", {returnView: returnTo, [target]: key});
    hideModal();
  };
const ClientsScopeGraph = ({ category = {}, sourceView, ...params }) => (
  <ScopeGraphLayout color={category.color}>
    <h4>Main clients</h4>
    <small>
      Click on each IP address or subscriber-id to see detailed subscriber
      information.
    </small>
    <ClientGroupingSelection>
      {({ grouping }) => (
        <ScopeGraph
          role="clients"
          category={category}
          {...params}
          grouping={grouping}
          onClick={clickOnClientGiven(grouping, sourceView)}
        />
      )}
    </ClientGroupingSelection>
  </ScopeGraphLayout>
);

const CategoryDetailsModal = ({ sourceView }) => {
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState(null);
  const actions = useContext(ActionsContext);
  const profile = useProfile() || {};
  const areClientsInspectable = profile.dpiEnabled === true;

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-category", function ({ category, params }) {
      setOpen(category);
      setDomain({ category, params });
      $(`#${sourceView}`).on('view:show', ()=>{
        setOpen(category);
        setDomain({ category, params });
        showModal();
      })
    });
  }, []);
  return open === false ? null : (
    <Modal
      id="categoryInspectionModal"
      large={true}
      title={
        areClientsInspectable
        ? `PERCENTAGE OF TRAFFIC FOR MAIN CLIENTS AND SERVERS IN ${domain.category.name}`
        : `PERCENTAGE OF TRAFFIC FOR MAIN SERVERS IN ${domain.category.name}`
      }
      subTile={
        areClientsInspectable
        ? "Clients are IP addresses on the access side and servers are IP addresses on the Internet side"
        : "Servers are IP addresses on the Internet side"
      }
      onClose={doClose}
      content={() => (
        <>
          <ServersScopeGraph
            doClose={doClose}
            {...domain}
            sourceView={sourceView}
          />
          {areClientsInspectable !== true ? null : (
            <ClientsScopeGraph
              doClose={doClose}
              {...domain}
              sourceView={sourceView}
            />
          )}
        </>
      )}
    />
  );
};
export default CategoryDetailsModal;
