import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {
  calcRangeExtent,
  getSampleSize,
} from "common/api";
import NVLineChart from "common/graphs/NVLineChart";
import RangeSliderInput from "common/RangeSliderInput";
import ActionsContext from "common/ActionsContext";
import Loading from "common/Loading";
import { isInsideRange } from "common/api";
import { useScreenSize } from "common/hooks";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
  periodScopeChoices,
} from "common/constants";
import {
  getMemoryStatsLines,
  memoryUsageSystemDateRetrieval,
  parseStatsMemData,
} from "./api";
import { getInRangeItems } from "../utils";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const ChartContainer = styled.div`
  display: flex;
  min-height: 500px;
  margin-top: 20px;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const TotalsContainer = styled.div`
display: inline-flex;
  font-weight: 100;
  margin: 20px 0;
}
`;

const RangeContainer = styled.div`
  margin: 20px 0;
`;

const Total = styled.div`
  font-family: "AllianceNo2-SemiBold" !important;
  margin-right: 10px;
`;

function tooltipContentGenerator(totals) {
  return (d) => {
    const date = d3.timeFormat("%Y-%m-%d  %H:%M:%S")(new Date(d.value));
    let html = '<b style="white-space: pre;"> ' + date + "  </b><br/><table>";
    const ROW_MAX = 16;
    let value,
      rowIdx,
      rowCnt = d.series.length < ROW_MAX ? d.series.length : ROW_MAX,
      row = [];

    for (var i = 0; i < rowCnt; i++) {
      row[i] = "<tr>";
    }

    for (var i = 0; i < d.series.length; i++) {
      value = d.series[i].value;
      if (isNaN(value) || value === null) continue;
      rowIdx = i % ROW_MAX;
      row[rowIdx] +=
        '<td class="legend-color-guide"><div style="background-color:' +
        d.series[i].color +
        ';"></div></td>';
      row[rowIdx] +=
        "<td>" +
        d.series[i].key +
        '</td><td align="right"><b>' +
        d3.format(".1f")(value) +
        " " +
        "%" +
        "</b></td>";
      row[rowIdx] +=
        '<td align="right"><b>' +
        d3.format(".3f")((value * totals[i]) / 102400) +
        " GB" +
        "</b></td>";
    }

    for (var i = 0; i < rowCnt; i++) {
      row[i] += "</tr>";
      html += row[i];
    }
    html += "</table>";

    return html;
  };
}

const SysStatsMemReport = () => {
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("Day");
  const [totals, setTotals] = useState({ System: null, Mpool: null, Dpdk: [] });
  const [data, setData] = useState({ fields: [], items: [] });
  const [initialRange, setInitialRange] = useState();
  const [range, setRange] = useState();
  const { width: windowWidth } = useScreenSize();
  const POINTS_PER_TILE_CHART = windowWidth / 2;
  const actions = useContext(ActionsContext);
  const { System, Mpool, Dpdk } = totals;

  const doLoad = () => {
    setLoading(true);
    setTotals({ System: null, Mpool: null, Dpdk: [] });
    setData({ fields: [], items: [] });
    memoryUsageSystemDateRetrieval()
      .then(([System, Mpool, Dpdk, SysDate]) => {
        setTotals({ System, Mpool, Dpdk });
        setInitialRange(calcRangeExtent(SysDate, PERIOD_TO_HOURS[period]));
      })
      .catch((error) => {
        console.warn(
          `Memory usage retrieval failed: ${
            !error.message ? "unknown reason" : error.message
          }`
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setRange(undefined);
    doLoad();

    return actions.recv("do-load", doLoad);
  }, [period]);

  useEffect(() => {
    if (System !== null && Mpool !== null) {
      getMemoryStatsLines(PERIOD_TO_LINES[period])
        .then((response) => {
          return parseStatsMemData(response, period, {
            System,
            Mpool,
            Dpdk,
          });
        })
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((error) =>
          console.warn(
            `Memory statistics lines retrieval failed: ${
              !error.message ? "unknown reason" : error.message
            }`
          )
        );
    }
  }, [System, Mpool, Dpdk]);

  const { fields, items } = data;
  const selectedRange = range || initialRange;
  const sampleSize = selectedRange
    ? getSampleSize(selectedRange, POINTS_PER_TILE_CHART, period)
    : 1;
  const inRangeItems = selectedRange
    ? getInRangeItems(items, selectedRange, fields, sampleSize)
    : [];

  return (
    <Wrapper>
      <ArrangeInColumns rowGap="0">
        <SelectWrapper>
          <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={period}
            onChange={({ target }) => setPeriod(target.value)}
            options={periodScopeChoices}
          />
        </SelectWrapper>
      </ArrangeInColumns>
      <ChartContainer>
        {loading ? <Loading /> : null}
        {fields.length !== 0 && !loading ? (
          <NVLineChart
            xField="time"
            items={inRangeItems}
            yAxisUnits={"%"}
            xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
            fields={fields.map((field) => {
              return { name: field, label: field };
            })}
            yAxisTopGap={0.05}
            yAxisTicksNumber={5}
            xAxisTicksNumber={8}
            tooltipContentGenerator={tooltipContentGenerator([
              System,
              Mpool,
              ...Dpdk,
            ])}
            fixedYRange={[0, 1]}
          />
        ) : null}
      </ChartContainer>
      <RangeContainer>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </RangeContainer>
      <TotalsContainer>
        <Total>System Total: {(System / 1024).toFixed(2)} GB</Total>
        <Total>MPool Total: {(Mpool / 1024).toFixed(2)} GB</Total>
        {Dpdk.map((mem, index) => {
          return (
            <Total>
              Dpdk{index} Total: {(mem / 1024).toFixed(2)} GB
            </Total>
          );
        })}
      </TotalsContainer>
    </Wrapper>
  );
};

export default SysStatsMemReport;
