import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="TCP CONNECTIONS ACCELERATION OVER TIME"
      onClose={doClose}
      content={() => (
        <>
          <p>
            Measured TCP acceleration over time. Acceleration is the average TCP
            conection speed percentage increase of accelerated ("TCPO") vs non
            accelerated ("No TCPO") connections. The TCP acceleration values are
            calculated globally and also broken down by the Internet-side
            latency (measured from the BQN node to the servers). The thresholds
            for small, medium and large Internet latency are defined in the
            Configuration->General Settings page.
          </p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <b>DOWNLOADS</b>: Selects data for TCP downloads.
            </li>
            <li>
              <b>UPLOADS</b>: Selects data for TCP uploads.
            </li>

            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh the TCP acceleration values.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SubscriberDetailHelpModal;
