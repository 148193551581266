import { validateIPv4WithMask } from "common/api";

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const downcase = (word) => word.charAt(0).toLowerCase() + word.slice(1);

const lowerWord = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalize(word);

const asJSAttribute = (input) => input.split(/\s|-+/).map(lowerWord).join("");

const significantCode = (code) =>
  code !== null && code !== undefined && code.length > 0;

const newBlock = (...codes) =>
  `\n${codes
    .filter(significantCode)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

const _DEFAULT_BYPASS_SETTINGS = {
  ipv4: false,
  ipv6: false,
  untaggedVLANs: false,
  vlans: [],
  ipRanges: [],
};
const includeBypassVLANSettings = ({ vlans = [] }, value) =>
  value === "untagged"
    ? { untaggedVLANs: true }
    : { vlans: [...vlans, { tag: parseInt(value) }] };

const includeBypassIPRangeSettings = ({ ipRanges = [] }, value) => ({
  ipRanges: [...ipRanges, { address: value }],
});

const includeBypassSetting = (settings, [field, value]) => ({
  ...settings,
  ...(field === "vlan"
    ? includeBypassVLANSettings(settings, value)
    : field === "iprange"
    ? includeBypassIPRangeSettings(settings, value)
    : { [field]: asBoolYesNo(value) }),
});

const _DEFAULT_SHAPING_SETTINGS = {
  perFlow: false,
  perSubscriber: false,
  ratePerSubscriber: false,
  ratePerSubscriberGroup: false,
};
const _DEFAULT_ACM_SETTINGS = { enabled: false };
const _DEFAULT_RTTI_SETTINGS = { small: 0.0, large: 0.0 };
const _DEFAULT_TCS_SETTINGS = { enabled: false, size: 0 };
export const _DEFAULT_SETTINGS = {
  tcp: false,
  adaptativeInitialWindow: _DEFAULT_TCS_SETTINGS,
  rtti: _DEFAULT_RTTI_SETTINGS,
  acm: _DEFAULT_ACM_SETTINGS,
  shaping: _DEFAULT_BYPASS_SETTINGS,
};
const parseBypassItem = (row) => {
  const [name, value] = row.trim(/\s/).split(/\s+/);
  return [name, value];
};
const expectedBypassFields = ["ipv4", "ipv6", "iprange", "vlan"];

export const parseBypassParameters = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows
    .map(parseBypassItem)
    .filter(([name]) => expectedBypassFields.includes(name))
    .reduce(includeBypassSetting, _DEFAULT_BYPASS_SETTINGS);
};

const includeRTTiParameter = (
  { rtti = _DEFAULT_RTTI_SETTINGS },
  field,
  value
) => ({
  rtti: {
    ...rtti,
    [field === "speedRTTILarge" ? "large" : "small"]: Number(value).toFixed(3),
  },
});

const includeTCSParameter = (
  { adaptativeInitialWindow = _DEFAULT_TCS_SETTINGS },
  field,
  value
) => ({
  adaptativeInitialWindow: {
    ...adaptativeInitialWindow,
    ...(field === "tcsInitialWindow"
      ? { size: parseInt(value) }
      : field === "tcsAdaptiveInitialWindow"
      ? { enabled: asBoolYesNo(value) }
      : {}),
  },
});
const includeACMParameter = ({ acm = _DEFAULT_ACM_SETTINGS }, value) => ({
  acm: {
    ...acm,
    enabled: value === "enabled",
  },
});
const includeShapingParameters = (
  { shaping = _DEFAULT_SHAPING_SETTINGS },
  field,
  value
) => ({
  shaping: {
    ...shaping,
    [downcase(field.replace(/^shaping/, ""))]: asBoolYesNo(value),
  },
});

const includeParameters = (settings, [field, value]) => ({
  ...settings,
  ...(field.startsWith("tcsOptimization")
    ? { tcp: value === "yes" }
    : field.startsWith("tcs")
    ? includeTCSParameter(settings, field, value)
    : field.startsWith("speed")
    ? includeRTTiParameter(settings, field, value)
    : field.startsWith("subscriberACM")
    ? includeACMParameter(settings, value)
    : field.startsWith("shaping")
    ? includeShapingParameters(settings, field, value)
    : { [field]: value }),
});

const expectedParameterFields = [
  "tcsOptimization",
  "subscriberACM",
  "tcsAdaptiveInitialWindow",
  "tcsInitialWindow", //size
  "speedRTTILarge",
  "speedRTTISmall",
  "shapingPerFlow",
  "shapingPerSubscriber",
  "shapingRatePerSubscriber",
  "shapingRatePerSubscriberGroup",
];

const involvedParameterFields =
  () =>
  ([name]) =>
    expectedParameterFields.includes(name);

const parseParameterItem = (row) => {
  const [name, value] = row.trim(/\s/).split(/:\s+/);
  return [asJSAttribute(name.split(" ").join("-")), value];
};

export const parseParameters = (input) => {
  return input
    .trim("\n")
    .split("\n")
    .map(parseParameterItem)
    .filter(involvedParameterFields())
    .reduce(includeParameters, {});
};

const asBoolYesNo = (value) =>
  value === "no" || value === "n/a"
    ? false
    : value === "yes"
    ? true
    : undefined;

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newOptimizationConfigurationBlock = (...commands) =>
  newBlock("configure", "pkteng", ...commands, "commit").trim("\n");

const ensureSomeSettingsChanged = (permisive = false) =>
  permisive === true
    ? (...parts) => parts
    : (...parts) => {
        if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
          throw "No changes available to commit.";
        }
        return parts;
      };

const isNotSavedOrDeleted = ({ stored, deleted }) =>
  stored !== true && deleted !== true;

const isSavedAndDeleted = ({ stored, deleted }) =>
  stored === true && deleted === true;

const notEmpty = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can't be empty";
  }
  return value;
};

const verified = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

const validIPAddressRange = (value) => {
  if (notEmpty(value) && validateIPv4WithMask(value)) {
    return value;
  }
  throw "is not a valid IPv4 range";
};

const validVLANTag = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    throw "has to be a number";
  }
  if (1 > parsed || parsed > 4094) {
    throw "can't be below 1 or above 4094";
  }
  return value;
};

const applyBypassMainSettings = (previous, { ipv4, ipv6 }) => [
  ...(ipv4 === previous.ipv4
    ? []
    : ipv4 === true
    ? ["bypass ipv4"]
    : ipv4 === false
    ? ["no bypass ipv4"]
    : []),
  ...(ipv6 === previous.ipv6
    ? []
    : ipv6 === true
    ? ["bypass ipv6"]
    : ipv6 === false
    ? ["no bypass ipv6"]
    : []),
];

const composeSaveBypassVLANs = (vlans = []) =>
  vlans.map(
    ({ tag }) =>
      `bypass vlan ${verified(`Received VLAN tag "${tag}"`, tag, validVLANTag)}`
  );

const composeClearBypassVLANs = (vlans) =>
  vlans.map(({ tag }) => `no bypass vlan ${tag}`);

const composeBypassVLANsUpdate = (previous, vlans = []) => [
  ...composeClearBypassVLANs(vlans.filter(isSavedAndDeleted)),
  ...composeSaveBypassVLANs(vlans.filter(isNotSavedOrDeleted)),
];

const applyBypassVLANSettings = (previous, { vlans, untaggedVLANs }) => [
  ...(untaggedVLANs === previous.untaggedVLANs
    ? []
    : untaggedVLANs === true
    ? ["bypass vlan untagged"]
    : untaggedVLANs === false
    ? ["no bypass vlan untagged"]
    : []),
  ...composeBypassVLANsUpdate(previous.vlans, vlans),
];

const composeSaveBypassIPRanges = (ipRanges = []) =>
  ipRanges.map(
    ({ address }) =>
      `bypass iprange ${verified(
        `Received IP Range "${address}"`,
        address,
        validIPAddressRange
      )}`
  );

const composeClearBypassIPRanges = (ipRanges) =>
  ipRanges.map(({ address }) => `no bypass iprange ${address}`);

const composeBypassIPRangesUpdate = (previous, ipRanges = []) => [
  ...composeClearBypassIPRanges(ipRanges.filter(isSavedAndDeleted)),
  ...composeSaveBypassIPRanges(ipRanges.filter(isNotSavedOrDeleted)),
];

const applyBypassSettings = (
  previous,
  { vlans, ipRanges, untaggedVLANs, ...settings }
) => [
  ...applyBypassMainSettings(previous, settings),
  ...applyBypassVLANSettings(previous, { vlans, untaggedVLANs }),
  ...composeBypassIPRangesUpdate(previous.ipRanges, ipRanges),
];

const applyTCPSettings = (previous, { tcp }) => [
  previous.tcp === tcp
    ? null
    : tcp === true
    ? "tcs optimization"
    : tcp === false
    ? "no tcs optimization"
    : null,
];

const applyACMSettings = (previous, { enabled = false }) => [
  previous.enabled === enabled
    ? null
    : enabled === true
    ? "subscriber acm"
    : enabled === false
    ? "no subscriber acm"
    : null,
];

const shapingFieldAsCLI = {
  perFlow: "per-flow",
  perSubscriber: "per-subscriber",
  ratePerSubscriber: "subscriber-rate-limits",
  ratePerSubscriberGroup: "per-subscriber-group",
};

const applyShapingSettings = (previous, settings) =>
  Object.entries(settings).flatMap(([field, enabled]) =>
    previous[field] === enabled
      ? []
      : [`${enabled ? "" : "no "}shaping ${shapingFieldAsCLI[field]}`]
  );

const validIWSize = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    throw "has to be a number";
  }
  if (0 > parsed) {
    throw "can't be 0 or below";
  }
  if (100 < parsed) {
    throw "can't be above 100";
  }
  return value;
};
const applyAIWSettings = (previous, { enabled, size }) => [
  ...(enabled === previous.enabled
    ? []
    : enabled === true
    ? ["no tcs no-adaptive-init-wnd"]
    : enabled === false
    ? ["tcs no-adaptive-init-wnd"]
    : []),
  ...(size === previous.size
    ? []
    : size === 10
    ? [`no tcs init-wnd ${previous.size}`]
    : [
        `tcs init-wnd ${verified(
          "Received Initial window",
          size,
          validIWSize
        )}`,
      ]),
];

const validRTTIValue = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    throw "has to be a float number";
  }
  if (0 > parsed) {
    throw "can't be 0 or below";
  }
  return value;
};

const applyRTTISettings = (previous, { small, large }) => [
  ...(small === previous.small
    ? []
    : [
        `speed rtti-small ${verified(
          "Received RTTi-small parameter",
          small,
          validRTTIValue
        )}`,
      ]),
  ...(large === previous.large
    ? []
    : [
        `speed rtti-large ${verified(
          "Received RTTi-large parameter",
          large,
          validRTTIValue
        )}`,
      ]),
];

export const composeApplyCommand = (previous, settings) =>
  newOptimizationConfigurationBlock(
    ...ensureSomeSettingsChanged(previous.enabled !== settings.enabled)(
      ...applyTCPSettings(previous, settings),
      ...applyACMSettings(previous.acm, settings.acm),
      ...applyShapingSettings(previous.shaping, settings.shaping),
      ...applyAIWSettings(
        previous.adaptativeInitialWindow,
        settings.adaptativeInitialWindow
      ),
      ...applyRTTISettings(previous.rtti, settings.rtti),
      ...applyBypassSettings(previous.bypass, settings.bypass)
    )
  );
