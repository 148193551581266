import React from "react";
import Masked from 'common/Masked';

const doesNothing = () => {};

const DeleteUserButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const UserItem = ({
  id,
  username = "",
  password = "",
  even = false,
  onDelete = doesNothing,
}) => {
  const doUserDelete = () => {
    onDelete(id);
  };

  return (
    <tr className={`${even ? "even" : "odd"}`}>
      <td>{username}</td>
      <td><Masked>{password}</Masked></td>
      <td className="text-center hides-content-to-operators">
        <DeleteUserButton onClick={doUserDelete} />
      </td>
    </tr>
  );
};
const UsersList = ({ items = [], onDelete = doesNothing }) => (
  <table className="table table-striped">
    <thead>
      <tr>
        <th>USER</th>
        <th>PASSWORD</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {items
        .filter(({ deleted }) => deleted !== true)
        .map(({ deleted, __id, ...trap }, index) => (
          <UserItem
            key={__id}
            id={__id}
            {...trap}
            deleted={deleted}
            even={(index + 1) % 2 === 0}
            onDelete={() => onDelete(__id)}
          />
        ))}
    </tbody>
  </table>
);
export default UsersList;
