import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import TCPOGraph from "./TCPOGraph";
import Link from "common/Link";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const GridLayoutDiv = styled.div`
  display: grid;
  grid-template-areas:
    "side main-top main-top main-top main-top"
    "side main-bottom main-bottom main-bottom main-bottom";
  gap: 10px;
  min-height: 60vh;
  & > div {
    padding: 2px 0;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50vh;
  align-items: center;
`;

const NothingToShow = () => <CenteredDiv>No data to show.</CenteredDiv>;

const isDataNull = ({ track, opt }) => track === null && opt === null;

const Span = styled.span`
  font-size: 14px;

  a{
    font-size: 14px;
  }
`;

const NetworkAvgTitle = () => (
  <Span>
    Network Average (Mbps)
  </Span>
);

const RTTTitle = () => (
  <Span>
    Broken down by connection{" "}
    <Link href="#viewDPILatency">Internet-side latency</Link>{" "}
    (Mbps, independent scales)
  </Span>
);

const ServicesTitle = () => (
  <Span>
    For <Link href="#viewDPITotalVolume">main services</Link>{" "}
    (Mbps)
  </Span>
);

const TCPOGraphs = ({ average, rtti, services, columns = [] }) => (
  <GridLayoutDiv>
    <div style={{ gridArea: "side" }}>
      <TCPOGraph
        title={<NetworkAvgTitle />}
        items={
          isDataNull(average)
            ? []
            : [{ nane: "average", label: false, ...average }]
        }
        missingText="Not enough samples"
        columns={columns}
      />
    </div>
    <div style={{ gridArea: "main-top" }}>
      <TCPOGraph
        title={<ServicesTitle />}
        items={services}
        missingText="Not enough samples for any service"
        columns={columns}
        independentScales={true}
      />
    </div>
    <div style={{ gridArea: "main-bottom" }}>
      <TCPOGraph
        title={<RTTTitle />}
        items={rtti}
        missingText="Not enough samples for any latency range"
        columns={columns}
        independentScales={true}
      />
    </div>
  </GridLayoutDiv>
);

const LegendsDiv = styled.div`
  display: flex;
  flex-layout: row;
  justify-content: space-between;
  padding: 0.2em 0.5em;
`;

const SamplesDiv = styled.div`
  display: flex;
  flex-layout: row;
  & > ul {
    list-decoration: none;
    display: inline-block;
  }
  & > ul > li {
    list-decoration: none;
    display: inline-block;
    margin: 0 0.5em;
  }
  & .color-sample {
    width: 2em;
    height: 15px;
    display: inline-block;
    margin: 0 0.2em;
  }
  & > ul > li,
  & .color-sample {
    line-height: 1.5em;
    vertical-align: middle;
  }
`;

const Sample = ({ legend, color, borderColor }) => (
  <li style={{ fontSize: '14px' }}>
    <div className="color-sample" style={{ backgroundColor: color, border: `1px solid ${borderColor}` }}>
      {" "}
    </div>
    {legend}
  </li>
);

const ExplanationDiv = styled.div`
  flex: 50% 1 1;
`;

const ResultLegends = ({ columns = [] }) => (
  <LegendsDiv>
    <ExplanationDiv>
      <code>n/s</code>: not statistically significant difference between the
      speed with and without TCPO (because of variability or not enough
      samples).
    </ExplanationDiv>
    <SamplesDiv>
      <ul>
        {columns.map((column) => (
          <Sample key={column.name} {...column} />
        ))}
      </ul>
    </SamplesDiv>
  </LegendsDiv>
);

const columns = [
  { field: "track", color: "var(--no-bta-color)", legend: "No TCPO", borderColor: "var(--no-bta-border-color)" },
  { field: "opt", color: "var(--bta-color)", legend: "TCPO", borderColor: "var(--bta-border-color)" },
];

const gotNoData = ({ average, rtti, services }) =>
  average === null && rtti.length === 0 && services.length === 0;

const MaybeTCPOGraphs = ({ columns, ...section }) =>
  gotNoData(section) ? (
    <NothingToShow />
  ) : (
    <TCPOGraphs {...section} columns={columns} />
  );

const Result = ({
  result,
  timeOptions,
  hours = 24,
  onHoursChange = doesNothing,
  activeTab = "download",
  onTabChange = doesNothing,
}) => {
  const { download, upload } = result;

  return (
    <>
      <ul className="nav nav-tabs" role="tablist">
        <li
          role="presentation"
          className={activeTab === "download" ? "active" : null}
        >
          <a
            onClick={() => onTabChange("download")}
            href="#tcpo_result_download"
            data-toggle="tab"
            aria-expanded={activeTab === "download" ? "true" : "false"}
          >
            DOWNLOADS
          </a>
        </li>
        <li
          role="presentation"
          className={activeTab === "upload" ? "active" : null}
        >
          <a
            onClick={() => onTabChange("upload")}
            href="#tcpo_result_upload"
            data-toggle="tab"
            aria-expanded={activeTab === "upload" ? "true" : "false"}
          >
            UPLOADS
          </a>
        </li>
      </ul>
      <div className="row margin-t-20">
        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 margin-b-10">
          <SelectInputWithIcon
            title="Time"
            name="time"
            icon="date_range"
            selected={hours}
            onChange={(e) => onHoursChange(e.target.value)}
            options={timeOptions}
          />
        </div>
      </div>
      {gotNoData(download) && gotNoData(upload) ? (
        <NothingToShow />
      ) : (
        <>
          <div className="tab-content">
            {activeTab === "download" ? (
              <div
                role="tabpanel"
                className="tab-pane fade active in"
                id="tcpo_result_download"
              >
                <MaybeTCPOGraphs {...download} columns={columns} />
              </div>
            ) : (
              <div
                role="tabpanel"
                className="tab-pane fade active in"
                id="tcpo_result_upload"
              >
                <MaybeTCPOGraphs {...upload} columns={columns} />
              </div>
            )}
          </div>
          <ResultLegends columns={columns} />
        </>
      )}
    </>
  );
};

export default Result;
