const numberOrNA = (input) => (input === "n/a" ? null : parseInt(input));

const stringOrNA = (input) => (input === "n/a" ? null : String(input));

const _LIST_FIELDS = [{ name: "name", parse: (d) => String(d) }];

const fieldValue = (source, { name, parse }) => [name, parse(source)];

const notNull = (value) => value !== null;

const asPolicyListItem = (row) =>
  Object.fromEntries(
    row
      .trim("s")
      .split(/\s+/)
      .map((source, index) =>
        index in _LIST_FIELDS ? fieldValue(source, _LIST_FIELDS[index]) : null
      )
      .filter(notNull)
  );

export const parsePolicyList = (response) => {
  const [_head, ...rows] = response.trim("\n").split("\n");
  return rows.map(asPolicyListItem);
};

const yesWithRangeRE = new RegExp("yes \\(([0-9.]+)\\%\\-([0-9.]+)\\%\\)");

const asRangeEnd = ([_all, _start, end, ...rest]) => parseFloat(end);

const getRangeValues = (input) => {
  const match = input.match(yesWithRangeRE);
  return match === null ? "yes" : asRangeEnd(match);
};

const rangeEndOrYesNo = (input) =>
  input === "no" ? "no" : getRangeValues(input);

const _DETAIL_FIELDS = [
  {
    from: "Source",
    name: "source",
    parse: (d) => (d === "static" ? "config" : String(d)),
  },
  { from: "Blocking", name: "blocking", parse: (d) => String(d) },
  { from: "Optimization", name: "optimization", parse: rangeEndOrYesNo },
  { from: "Auto-Congestion-Management", name: "acm", parse: (d) => String(d) },
  {
    from: "Shaping-per-subscriber downlink",
    name: "shapingPerSubscriberDown",
    parse: (d) => String(d),
  },
  {
    from: "Shaping-per-subscriber uplink",
    name: "shapingPerSubscriberUp",
    parse: (d) => String(d),
  },
  {
    from: "Shaping-per-flow downlink",
    name: "shapingPerFlowDown",
    parse: (d) => String(d),
  },
  {
    from: "Shaping-per-flow uplink",
    name: "shapingPerFlowUp",
    parse: (d) => String(d),
  },
  {
    from: "Quota",
    name: "quota",
    parse: (d) => String(d),
  },
  {
    from: "  Rate",
    name: (_value, prev) =>
      // Asumes that rate will be inmediatelly after RatePerSubs <Any>.
      prev.shapingPerSubscriberDown === "yes"
        ? "shapingPerSubscriberDown"
        : prev.shapingPerSubscriberUp === "yes"
        ? "shapingPerSubscriberUp"
        : prev.shapingPerFlowDown === "yes"
        ? "shapingPerFlowDown"
        : prev.shapingPerFlowUp === "yes"
        ? "shapingPerFlowUp"
        : "rate",
    parse: (d) => (isNaN(Number(d)) ? d : Number(d)),
  },
  {
    from: "Drop Internet TCP SYN",
    name: "dropTCPSyn",
    parse: (d) => d,
  },
  {
    from: "Drop Internet UDP",
    name: "dropUDP",
    parse: (d) => d,
  },
  {
    from: "Drop Internet IP-Other",
    name: "dropOtherIP",
    parse: (d) => d,
  },
  {
    from: "Compression",
    name: "compression",
    parse: (d) => d,
  },
];

const stateField = ({ name, parse }, value, prev) => {
  return {
    [typeof name === "function" ? name(value, prev) : name]: parse(value, prev),
  };
};

const asTuple = (line) => line.split(/:\s*/);

const joinDrops = ({ dropTCPSyn, dropUDP, dropOtherIP, ...details }) => ({
  dropIncoming:
    dropTCPSyn === "yes" && dropUDP === "yes" && dropOtherIP === "yes"
      ? "yes"
      : "no",
  ...details,
});

const givenFields = (fields = _DETAIL_FIELDS) => {
  const fieldByFrom = Object.fromEntries(
    fields.map(({ from, ...field }) => [from, field])
  );
  const fillFields = (prev, [from, value]) =>
    from in fieldByFrom === false
      ? prev
      : { ...prev, ...stateField(fieldByFrom[from], value, prev) };
  const extract = (response) =>
    joinDrops(
      response.trim("\n").split("\n").map(asTuple).reduce(fillFields, {})
    );
  return { extract };
};

export const parsePolicyDetails = (response) => givenFields().extract(response);

const warningRE = new RegExp("%WARN-EINVAL:\\s(.*)");

export const handleWarnings = (input) => {
  const match = input.match(warningRE);
  if (match !== null) {
    const [_all, message] = match;
    throw message;
  }
  return input;
};
