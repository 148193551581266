import React, { useEffect, useContext } from "react";
import SettingsView from "common/SettingsView";
import { collectAsProfiles, composeApplyCommand, enumerate } from "./api";
import ShowProfiles from "./Profiles";
import ConfigProfileTimeHelpModal from "./Help";
import ActionsContext from "common/ActionsContext";

const doesNothing = () => {};

const ContextMenu = ({ doLoad = doesNothing }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right hidden-to-operators">
          <li>
            <a onClick={() => actions.send("new-profile")}>Add Profile...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clearConfigAttempt = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const ConfigProfileTime = ({returnView}) => {
  const doLoad = () =>
    ifCl
      .run("show profile time full")
      .then(collectAsProfiles)
      .then(enumerate(1, { stored: true }))
      .then((profiles) => ({ profiles }));
  useEffect(() => {
    doLoad();
  }, []);

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () =>
      composeApplyCommand(originalSettings.profiles, settings.profiles);

    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };
  return (
    <SettingsView
      load={doLoad}
      apply={apply}
      ContextMenu={ContextMenu}
      title="Time Profiles"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      returnView={returnView}
      id="viewProfTime"
    >
      <ShowProfiles />
      <ConfigProfileTimeHelpModal />
    </SettingsView>
  );
};

export default ConfigProfileTime;
