import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useRef,
  } from "react";
  import styled from "styled-components";
  import NVLineChartWithLegends from "common/graphs/NVLineChart";
  import colors from "common/graphs/colors";
  import colorsDark from "common/graphs/colorsDark";
  
  const formatDate = d3.timeFormat("%m/%d %H:%M");
  
  export const TileContainer = styled.div`
    position: relative;
    min-height: 10cm;
    @media print {
      & {
        height: 8cm;
        &.can-compress {
          height: auto;
        }
        width: 100%;
        display: block:
      }
    }
    display: flex;
    flex-direction: column;
    & > h4 {
      text-align: center;
      margin: 20px 0 0 0;
    }
    & > .graph {
      flex: 1 1 100%;
      .nv-y.nv-axis .nv-axisMaxMin {
        display: none;
      }
    }
    &.spread {
      justify-content: space-between;
      padding: 1em;
      align-content: flex-start;
    }
    &.can-compress {
      min-height: 0;
    }
    .input-group {
      max-width: 20ch;
    }
  `;
  
  const TooltipWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 90px 55px 50px 75px;
    z-index: 10000;
  `;
  
  const TooltipWrapperInside = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `;
  
  const TooltipContentWrapper = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    font-size: 12px;
    padding: 6px;
    transform: translate(-50%, -50%);
    font-family: "AllianceNo2-Regular" !important;
  `;
  
  const TooltipLabel = styled.div`
    margin: 0 0px;
    width: 100%;
    min-width: 180px;
    display: flex;
    @media only screen and (max-width: 768px) {
      min-width: 90px;
      padding-right: 5px;
    }
  `;
  
  const TooltipValues = styled.div`
    display: flex;
    flex-direction: column;
  }
  `;
  
  const TooltipValueWrapper = styled.div`
    display: flex;
  `;
  
  const TooltipTimeValueWrapper = styled.div`
    width: 100%;
    margin-bottom: 5px;
    min-width: 120px;
  `;
  
  const TooltipValue = styled.div`
    min-width: 60px;
    font-family: "AllianceNo2-SemiBold" !important;
  `;
  
  const TooltipColor = styled.div`
    background-color: ${(props) => props.bgColor};
    width: 12px;
    height: 12px;
    border: 1px solid #999;
    margin-right: 10px;
  `;
  
  const getPosTooltip = (xPos, width) => {
    if (xPos > width - 140) {
      return xPos - 140;
    }
    if (xPos >= 140) {
      return xPos - 140;
    }

    return xPos;
  };

  const timeCoeffMap = {
    24: 1000 * 60 * 10,
    168: 1000 * 60 * 10,
    720: 1000 * 60 * 60,
    2160: 1000 * 60 * 60,
  }
  
  const Tooltip = ({ values, fields, xScale, widthChart }) => {
    if (!values) {
      return null;
    }
    const colorsSet = login.isTheme("light") ? colors : colorsDark;
    const { time } = values;
    const xPos = getPosTooltip(xScale(time), widthChart);
  
    return (
      <TooltipWrapper>
        <TooltipWrapperInside>
          <TooltipContentWrapper
            style={{
              position: "absolute",
              width: "auto",
              left: xPos,
              top: 60,
            }}
          >
            <TooltipValues>
              <TooltipTimeValueWrapper key="time-value">
                <TooltipValue>{formatDate(time)}</TooltipValue>
              </TooltipTimeValueWrapper>
              {fields.map(({ name, label }, index) => {
                return (
                  <div key={`${label}-value`}>
                    {values[name] ? (
                      <TooltipValueWrapper key={`${label}-value`}>
                        <TooltipLabel><TooltipColor bgColor={colorsSet[index]}></TooltipColor>{label}</TooltipLabel>
                        <TooltipValue>{values[name]}</TooltipValue>
                      </TooltipValueWrapper>
                    ) : null}
                  </div>
                );
              })}
            </TooltipValues>
          </TooltipContentWrapper>
        </TooltipWrapperInside>
      </TooltipWrapper>
    );
  };
  
  const FacetGraph = ({
    title,
    items,
    facet,
    yAxisUnits,
    fields,
    yAxisFormat,
    yTooltipFormat,
    onInteractionData,
    interactionData,
    valuesMap,
    xScale,
    widthChart,
    hours
  }) => {
    const highlight =
      interactionData !== null
        ? {
            synthetic: true,
            mouseX: null, // not zero value, is not being used
            mouseY: null,
            pointXValue: interactionData.time,
          }
        : null;
  
    return (
      <TileContainer className="chart-container">
        <h4 className="chart-title">{title}</h4>
        <NVLineChartWithLegends
          className="graph"
          items={items}
          yAxisUnits={yAxisUnits}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          xField="time"
          yAxisTopGap={0.1}
          yAxisTicksNumber={5}
          fields={fields}
          yAxisFormat={yAxisFormat}
          yTooltipFormat={yTooltipFormat}
          onHighlight={(event) => {
            if (event === null) {
              onInteractionData(null);
            } else {
              const time = event.pointXValue;
              const coeff = timeCoeffMap[hours];
              
              const roundedTime = new Date(
                Math.round(time.getTime() / coeff) * coeff
              );
              onInteractionData({ time: roundedTime });
            }
          }}
          highlight={highlight}
          tooltipEnabled={false}
        />
        {interactionData !== null ? (
          <Tooltip
            values={valuesMap[formatDate(interactionData.time)]}
            fields={fields}
            xScale={xScale}
            widthChart={widthChart}
          />
        ) : null}
      </TileContainer>
    );
  };
  
  export default FacetGraph;
  