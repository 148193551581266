/* globals showModalConfirm */
import React, { useContext } from "react";
import {
  parseFirewallList,
  composeApplyCommand,
  areChangesColliding,
} from "./api";
import { parseManagementInterface } from "views/Config/Interfaces/Management/api";
import SettingsView from "common/SettingsView";
import useProfile from "common/hooks/useProfile";
import Ranges from "./Ranges";
import ActionsContext from "common/ActionsContext";
import ManagementFirewallConfigHelpModal from "./Help";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getFirewallList = () =>
  ifCl
    .run("show interface firewall")
    .then(parseFirewallList)
    .then(enumerate(1, { stored: true }));

const enumerate =
  (start = 1, extraParams) =>
  (list) =>
    list.map((item, index) => ({
      ...item,
      __id: start + index,
      ...extraParams,
    }));

const getManagementFirewallSettings = () =>
  getFirewallList().then((ranges) => ({ ranges }));

class RequiresConfirmation extends Error {
  constructor(reason) {
    super();
    this.reason = reason;
  }
}
class UserDeclined extends Error {}

const confirmWarning = (message, content) => {
  const done = new Promise((resolve) =>
    $("#modalConfirm").on("hidden.bs.modal", resolve)
  );
  return new Promise((resolve, reject) =>
    showModalConfirm(
      message,
      content,
      "warning",
      () => done.then(() => resolve(true)),
      () => done.then(() => reject(new UserDeclined(content))),
      "OK",
      "CANCEL"
    )
  );
};

const ManagementFirewallContextMenu = ({ doLoad = doesNothing }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={() => actions.send("new-range")}>
              Add IP Address Range...
            </a>
          </li>
          <li>
            <a onClick={() => actions.send("load-suggestions")}>
              Load Suggestions...
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const mayRequireConfirmation = (warning) => {
  console.error("mayRequireConfirmation", warning);
  if (warning instanceof RequiresConfirmation) {
    return confirmWarning("Do you want to proceed?", warning.reason);
  }
  throw warning;
};

const checkForCollidingChanges = (previous, settings) => {
  const collisions = areChangesColliding(previous, settings);
  if (collisions.length > 0) {
    const reason =
      `The specified IP address ranges will permanently ` +
      `<a class="col-red">block</a> traffic from one or more needed sources. ` +
      `To avoid that press <code>cancel</code> button and select ` +
      `<code>Reload suggestions</code> option.`;
    throw new RequiresConfirmation(reason);
  }
  return true;
};
const passInterface = (result) => ({ interface: result.interface });
const getManagementInterface = () =>
  ifCl
    .run("show interface management detail")
    .then(parseManagementInterface)
    .then(passInterface);

const doChecksOnBeforeProceed = (originalSettings, settings) => (commands) =>
  Promise.resolve(commands)
    .then(() => checkForCollidingChanges(originalSettings, settings))
    .catch(mayRequireConfirmation)
    .then(() => commands);

const ManagementFirewallConfig = () => {
  const load = () => getManagementFirewallSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    if (error instanceof UserDeclined) {
      return { error: null };
    }
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(getManagementInterface)
      .then((interfaceSettings) =>
        composeApplyCommand(originalSettings, {
          ...interfaceSettings,
          ...settings,
        })
      )
      .then(doChecksOnBeforeProceed(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={ManagementFirewallContextMenu}
      title="Management interface firewall"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowManagementFirewallSettings />
    </SettingsView>
  );
};

const ShowManagementFirewallSettings = ({
  defaults,
  onStateUpdate = doesNothing,
}) => {
  const { isOperator } = useProfile() || {};

  const updateRanges = (ranges) => {
    onStateUpdate({ ranges });
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <Ranges
            isOperator={isOperator}
            initial={defaults.ranges}
            onChange={updateRanges}
          />
        </div>
      </div>
      <ManagementFirewallConfigHelpModal />
    </>
  );
};

export default ManagementFirewallConfig;
