
export { CreateRangeModal } from "./Create";
export { EditRanges } from "./Edit";
import React from "react";
import RangeDays from "./RangeDays";

export const RangeAsRow = ({ starts, ends, days }, _index) => (
  <React.Fragment>
    <td className="align-right">{starts}</td>
    <td className="align-right">{ends}</td>
    <td className="align-center">
      <RangeDays value={days} disabled={true} />
    </td>
  </React.Fragment>
);
