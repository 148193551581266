import React, {
  useState,
  useEffect,
  useContext
} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import Modal from "common/Modal.jsx";
import { handleWarnings } from "./api";
import { safeStr } from "common/api";
import ActionsContext from "common/ActionsContext";
import { ActionsRow } from "views/Status/Policies/Common";

const doesNothing = () => {};

const returnView = "viewMonitoringPolicies";

const HelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="MONITORING POLICIES"
      onClose={doClose}
      content={() => (
        <>
          <p>Subscriber monitoring policies. Define actions to take on a subscriber basis. Subscribers are identified as a unique access-side IP address. </p>
          <ul>
            <li><b>ACTIVE-SUBSCRIBERS</b>: Number of active subscribers.</li>
            <li><b>MBYTES-DOWNLINK</b>: Traffic in the downlink direction.</li>
            <li><b>MBYTES-UPLINK</b>: Traffic in the uplink direction.</li>
            <li><b>UDR-GENERATION-%</b>: Percentage of monitorings generating UDR (Usage Data Record). Used by DPI reporting.</li>
            <li><b>ACTIONS</b>: Allowed operations on this policy.</li>
          </ul>
          <p>Click on the <i className="material-icons valign-icon">delete</i> icon to delete an already configured policy.</p>
          <p>Click on the <i className="material-icons valign-icon">refresh</i> icon to refresh policies information.</p>
        </>
      )}
    />
  );
};

function onAddPolicy(){
  rulesMonitor.data.policies.addPolicy(returnView);
}
const ContextMenu = ({ doLoad = doesNothing }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={onAddPolicy}>Add Policy...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export const PoliciesList = () => {
  const [request, setRequest] = useState(null);

  const doRequest = () => ifCl.run("show policy monitor statistics web").then(response => ({response}))

  const doLoad = () => {
    setRequest(doRequest());
  };

  const enableViewReload = (main) => {
    if (main === null || main.current === null) {
      return;
    }
    const view = main.closest(`#${returnView}`);
    $(view).one("view:show", () => doLoad());
    console.log("enabled reload on show", main);
  };
  useEffect(() => doLoad(), []);

  return request === null ? null : (
    <div className="row clearfix" ref={enableViewReload}>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="card">
          <div className="header block-header">
            <h2>Monitoring Policies</h2>
            <ContextMenu doLoad={doLoad} />
          </div>
          <div className="body table-responsive">
            <ActionsRow doCreatePolicy={onAddPolicy} />
            <Request during={request}>
              {(result) => <ShowPolicies {...result} reload={doLoad} />}
            </Request>
          </div>
        </div>
      </div>
    </div>
  );
};

const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

/*****************************************************
 * Policies Table
 * ****************************************************/

const monitoringPolicyActions = ([name, ...row], whenDone) => [
  {
    id: "edit-config",
    label: "Edit",
    icon: "edit",
    onClick: (_value, [name, _id, _source, ..._rest]) =>
      doEditPolicy(name, whenDone),
  },
  ...( 
    name === 'monitor-default'
      ? []
      : [{
          id: "delete-config",
          label: "Delete",
          icon: "delete",
          onClick: (_value, [name, _id, _source, ..._rest]) =>
            doDeletePolicy(name, whenDone),
        }]
  )
];
const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const openThroughputPerPolicy = (policyType='monitor', directions='downSpeed') => {
  views.doKeep(returnView)
  globalNavigate('viewThroughputPerPolicy', {policyType, directions, returnView});
}

const ShowPolicies = ({ response, reload = doesNothing }) => 
<>
  <TableContainer>
    <DataTable
      pageLength={50}
      exportAsCSV={true}
      content={response}
      columns={[
        Column.Text({
          label: "NAME",
          idx: 0,
          cellClassName: "hyperlink-text",
          onClick: doEditPolicy,
        }),
        Column.NumberOrNA({
          label: "ACTIVE-SUBSCRIBERS",
          idx: 4,
          precision: 0,
          cellClassName: "align-right",
        }),
        Column.NumberOrNA({
          label: "MBYTES-DOWNLINK",
          idx: 5,
          precision: 3,
          cellClassName: "align-right hyperlink-text",
          onClick: () => {
            openThroughputPerPolicy('monitor', 'downSpeed');
          },
        }),
        Column.NumberOrNA({
          label: "MBYTES-UPLINK",
          idx: 6,
          precision: 3,
          cellClassName: "align-right hyperlink-text",
          onClick: () => {
            openThroughputPerPolicy('monitor', 'upSpeed');
          },
        }),
        Column.Text({
          label: "UDR-GENERATION-%",
          idx: 3,
          cellClassName: "align-right",
        }),
        Column.Actions({
          label: "ACTIONS",
          idx: 0,
          are: (source, row) => monitoringPolicyActions(row, reload),
          colClassName: "text-center action",
          cellClassName: "hides-content-to-operators",
        }),
      ]}
    />
  </TableContainer>
  <HelpModal />
  </>

const doEditPolicy = (policy) => {
  ifCl
    .run(`show policy ${safeStr(policy)}`)
    .then((response) => {
      const policies = rulesMonitor.data.policies;
      const policy = policies.parsePolicy(response);
      views.doKeep(returnView);
      policies.editPolicy(policy, returnView);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const doDeletePolicy = (name, whenDone = doesNothing) =>
  showModalConfirm(
    `Remove "${name}" policy?`,
    'WARNING: All policy data will be lost and all subscribers assigned to this policy will be reassigned to a default monitoring policy following the BQN configured rules (normally default policy is "monitor-default").',
    "delete_forever",
    () => {
      ifCl
        .run(`clear policy monitor ${safeStr(name)}`)
        .then(handleWarnings)
        .then(whenDone)
        .catch((error) => showModalError(error));
    }
  );

