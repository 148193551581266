import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const HourlyVolumeDPIHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="DPI HOURLY STATISTICS"
      onClose={doClose}
      content={() => (
        <>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part.
            </li>
            <li>
              <i className="material-icons valign-icon">format_line_spacing</i>{" "}
              Selects the number of categories displayed in the chart.
            </li>
            <li>
              <i className="material-icons valign-icon">import_export</i>{" "}
              Selects the direction of traffic (Uplink, Downlink or Both).
            </li>
            <li>
              <i className="material-icons">call_split</i> Select "All UDRs" to
              show the DPI data from all DPI records. Select "Only auto UDRs" to
              show the data for only those DPI records that are produced with
              automatic sampling, excluding DPI records produced by monitoring
              policies.
            </li>
            <li className="f-dpi-per-user">
              IP-Addr/Subs-Id. When present, filters the output for the
              specified IP address (range) or subscriber Id. Click{" "}
              <code>Filter</code> button to update chart.
            </li>
          </ul>

          <p>
            Click on the coloured dots on the top-right corner of the graph to
            optionally select or de-select which categories are shown.
          </p>
          <p>
            Double-click on the coloured dots on the top-right corner of the
            graph to optionally isolate the selected category.
          </p>
          <p>
            Click on <i className="material-icons valign-icon">refresh</i> icon
            to refresh information.
          </p>
        </>
      )}
    />
  );
};
export default HourlyVolumeDPIHelpModal;
