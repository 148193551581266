import {
  dynamicHeadersParsing,
  namePolicies,
  parseKeys,
} from "views/Statistics/Flows/api";

export const parseSystemDate = (input) => new Date(input);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseFloatOrNull = (value, precision = undefined) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? fixedPrecision(parseFloat(value.trim("%")) / 100, 6)
    : (precision !== undefined && precision > 0) || value.includes(".")
    ? parseFloat(value)
    : parseInt(value);

export const parsePoliciesThroughput = (input, settings = {}) => {
  const skipped = settings.ignore || [];
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [policiesHead, head, ...rows] = lines;
  const translations = {
    TIME: "time",
    SUBSCRIB: "subscribers",
    FLOWS: "flows",
    "MBPS-RX-UP": "upSpeed",
    "MBPS-RX-DOWN": "downSpeed",
  };
  const policies = parseKeys(policiesHead.trim(" "), translations);
  const keys = parseKeys(head.trim(" "), translations, skipped);
  const { attrsPerPolicy = 3 } = settings;
  const digestDynamicHeaders = dynamicHeadersParsing(
    policies,
    attrsPerPolicy - skipped.length /*keys per policy*/,
    keys,
    skipped,
    translations
  );
  const start = new Date().getTime();
  const items = rows.flatMap(digestDynamicHeaders.parseRow).map(namePolicies);
  const end = new Date().getTime();
  console.log(`took ${(end - start) / 1000} sec for ${rows.length} records`);
  return { timeline: items, participants: digestDynamicHeaders.getActivePolicies(items) };
};

export const parseThroughputPerPolicyIgnoringSubscribers = (response) =>
  parsePoliciesThroughput(response, {
    ignore: [],
  });
