import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import MetricsHelpModal from "./Help";
import FilteringControls from "./FilteringControls";
import Report from "./Report";
import Actions from "./Actions";
import AdvancedSettings from "./AdvancedSettings";
import { TitleWithNav } from "common/TitleWithNav";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  & .title {
    text-transform: uppercase;
  }
  & .context-menu {
    display: flex;
    flex-direction: row;
  }
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const Subscribers = ({ subnet, returnView, addr, subsId, dpiEnabled }) => {
  const actions = useContext(ActionsContext);
  useEffect(() => () => actions.clear(), []);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <TitleWithNav returnView={returnView}>
            <h2 className="title">Subscriber Quotas</h2>
          </TitleWithNav>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column subscriber-quotas panel-group">
          <Actions />
          <AdvancedSettings />
          <FilteringControls subnet={subnet}>
            {(settings) => <Report {...settings} />}
          </FilteringControls>
        </div>
        <MetricsHelpModal />
      </div>
    </div>
  );
};

export default Subscribers;
