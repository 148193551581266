/* global DPI_STATS_TMOUT_MS, views, components, functionality */
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/TotalVolume/component";
import ExportButton from "./Export";
import ActionsContext from "common/ActionsContext";
import SubscribersTotalVolumeHelpModal from "./Help";
import {
  expressTarget,
} from "common/api";
import { parseSubscriberBandwidthShare } from "../api";
import * as d3 from "d3";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;
const wholeInterval = (hours, base=60/*minutes*/) =>
  hours * base;

const doSpeedTotalsRetrieval = (
  target,
  hours = 72,
  categories = 20,
  directions = "all",
  output = "ip-addresses"
) =>
  expressTarget(target).then( expressedTarget =>
    ifCl.run(
      `show statistics subscribers top ${
         expressedTarget
      } ${output} direction ${directions} categories ${
        categories - 1
      } hours ${hours} interval ${wholeInterval(hours)}`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const parseSubscriberBandwidthShareTotals = (input) =>
  parseSubscriberBandwidthShare(input, { omitTimeline: true });

const retrieval = ({ target, hours, categories, directions, output }) =>
  doSpeedTotalsRetrieval(target, hours, categories, directions, output)
    .then(parseSubscriberBandwidthShareTotals)
    .catch((error) => console.error(error) || error);

const formatThousandsAndDecimals = d3.format(",.2f");

const formatDecimals = (value) =>
  value === undefined ? "--" : formatThousandsAndDecimals(value);

const TotalVolumeVsDPI = ({ volume = 0 }) => (
  <h4 className="align-left">Total volume: {formatDecimals(volume)} GB</h4>
);
const thisViewUrl = "viewSubscribersTotalVolume";

const SubscriberDetailsView = () => {
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-category", function ({ category, params }) {
      const { name } = category;
      const [addr, subsId] =
        params.output === "ip-addresses" ? [name, null] : [null, name];
      views.doKeep(thisViewUrl);
      globalNavigate("viewSubsDetail", {
        addr,
        subsId,
        returnView: thisViewUrl
      });
    });
  }, []);
  return null;
};

const SubscribersTotalVolume = () => {
  const actions = useContext(ActionsContext);

  return (
    <div id="viewSubscribersTotalVolume" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Top subscribers</h2>
          <ContextMenu />
          <small id="inspectableCategories">
            Click on the area in the pie-chart to see subscriber details
          </small>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            itemsFrom="subscribers"
            itemsTotalFrom="volume"
            itemsValueFrom="share"
            valueIsShare={true}
            provider={retrieval}
            actions={actions}
            enabled={{ output: true, filter: false, directions: true }}
            Totals={TotalVolumeVsDPI}
            targetTooltip="Click to see subscriber details"
            graphTitle={null}
            Export={ExportButton}
          />
        </div>
        <SubscribersTotalVolumeHelpModal />
        <SubscriberDetailsView />
      </div>
    </div>
  );
};

export default SubscribersTotalVolume;
