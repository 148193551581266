/*globals views*/
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SubscriberAnalytics from "./SubscriberAnalytics";
import DPIAnalytics from "./DPIAnalytics";
import TargetContext from "./TargetContext";
import SubscriberFlows from "./SubscriberFlows";
import SubscriberInformation from "./SubscriberInformation";
import LiveMetrics from "./LiveMetrics";
import ActionsContext from "common/ActionsContext";
import SubscriberDetailHelpModal from "./Help";
import CheckLicenseFirst from "common/CheckLicenseFirst";
import { TitleWithNav } from "common/TitleWithNav";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const ContextMenu = ({ setLiveMode,onBeforeLoad }) => {
  const actions = useContext(ActionsContext);
  return (
    <div className="context-menu">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help-details")}
      >
        <i className="material-icons">
          <small>help_outline</small>
        </i>
      </a>
      <a
        onClick={() => {
          setLiveMode(undefined);
          onBeforeLoad();
          actions.send("do-load-details");
        }}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">
          <small>refresh</small>
        </i>
      </a>
      <a
        onClick={() => window.prepareAndPrint()}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Print"
        className="margin-l-15"
      >
        <i className="material-icons">
          <small>print</small>
        </i>
      </a>
    </div>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
    text-transform: uppercase;
    color: #666;
  }
  & .context-menu {
    display: flex;
    flex-direction: row;
    a {
      color: #949494;
      font-size: 0.8em;
    }
  }
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const Panel = React.memo(({ children, ...props }) => (
  <div className="card">
    <div className={`body ${props.className || ""}`}>
      {children({ ...props })}
    </div>
  </div>
));
Panel.displayName = "Panel";

const PanelNoStyle = React.memo(({ children, ...props }) => (
    <div>
      {children({ ...props })}
    </div>
));
PanelNoStyle.displayName = "PanelNoStyle";

const LiveModePanel = React.memo(({ children, ...props }) => (
    <>
      {children({ ...props })}
    </>
));
LiveModePanel.displayName = "LiveModePanel";

const SubcriberDetail = ({ addr, subsId, dpiEnabled = false, returnView, timePeriod }) => {
  const [liveMode, setLiveMode] = useState();
  const [subscriberInformationReady, setSubscriberInformationReady] = useState(false);

  useEffect(() => {
    $('html')[0].scrollTop = 0
  }, [])

  useEffect(() => {
    if (!liveMode) {
      $("#live-switch").prop("checked", false);
    }
  }, [liveMode]);

  useEffect(() => {
    setSubscriberInformationReady(false);
  }, [addr, subsId])

  function subscriberFlowsPanel(props) {
    return (
      <Panel name="flows" {...props}>
        {(props) => (
          <>
            <SubscriberFlows
              {...props}
              setLiveMode={(value) => {
                setLiveMode(value);
              }}
              dpiEnabled={dpiEnabled}
            />
          </>
        )}
      </Panel>
    );
  }

  return (
    <div class="viewSubsDetail">
      <HeaderDiv className="block-header">
        <TitleWithNav returnView={returnView}>
          <h2 className="title">Subscriber dashboard</h2>
        </TitleWithNav>
        <p className="padding-t-20"></p>
        <ContextMenu
          setLiveMode={setLiveMode}
          onBeforeLoad={() => setSubscriberInformationReady(false)}
        />
      </HeaderDiv>

      <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <TargetContext addr={addr} subsId={subsId}>
          <Panel name="information">
            {(props) => (
              <SubscriberInformation
                {...props}
                liveMode={liveMode}
                setLiveMode={setLiveMode}
                onLeavingPage={() => setLiveMode(false)}
                dpiEnabled={dpiEnabled}
                onLoaded={() => setSubscriberInformationReady(true)}
                onBeforeLoad={() => setSubscriberInformationReady(false)}
                timePeriod={timePeriod}
              />
            )}
          </Panel>
          <PanelNoStyle>
          {(props) => {
            if(!subscriberInformationReady){
              return <></>
            }
            
            return <>
            <LiveModePanel {...props}>
                {(props) => (
                  <LiveMetrics
                    {...props}
                    liveMode={liveMode}
                    setLiveMode={(value) => {
                      setLiveMode(value);
                    }}
                    dpiEnabled={dpiEnabled}
                  />
                )}
              </LiveModePanel>
              <Panel name="analytics" {...props}>
                {(props) => (
                    <SubscriberAnalytics {...props}/>
                  )
                }
              </Panel>
              {dpiEnabled === true ? (
                <Panel name="dpi" {...props}>{(props) => <DPIAnalytics {...props}/>}</Panel>
              ) : null}
              {subscriberFlowsPanel(props)}
            </>
          }}
          </PanelNoStyle>
        </TargetContext>
        <SubscriberDetailHelpModal />
      </ViewContainer>
    </div>
  );
};

const VerifiedSubcriberDetail = ({ ...args }) => (
  <CheckLicenseFirst {...args}>
    <SubcriberDetail {...args} />
  </CheckLicenseFirst>
);

export default VerifiedSubcriberDetail;
