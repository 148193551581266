const colors = [
    "#006ab7",
    "#FFA500",
    "#228B22",
    "#FA8072",
    "#F522F5",
    "#DC143C",
    "#556B2F",
    "#696969",
    "#483D8B",
    "#00008B",
    "#8FBC8F",
    "#B03060",
    "#1DF11D",
    "#48E2AA",
    "#8B4513",
    "#16F8F8",
    "#1A1AF1",
    "#B7EC64",
    "#DA70D6",
    "#7F007F",
    "#008B8B",
    "#F4F436",
    "#90EE90",
    "#ADD8E6",
    "#F81B93",
    "#7B68EE",
    "#FFDEAD",
    "#D6D6D6",
    "#7F0000",
    "#556B2F",
    "#FF4500",
    "#4682B4",
    "#40E0D0",
    "#FF6347",
    "#98FB98",
    "#FFD700",
    "#FF1493",
    "#00CED1",
    "#8A2BE2",
    "#FFE4B5",
    "#32CD32",
    "#D2691E",
    "#87CEFA",
    "#FF69B4",
    "#C71585",
    "#708090",
    "#BDB76B",
    "#FF7F50",
    "#6A5ACD",
    "#2E8B57",
    "#CD5C5C",
    "#1E90FF",
    "#D8BFD8",
    "#8B4513",
    "#00FF7F",
    "#BC8F8F",
    "#FF00FF",
    "#5F9EA0",
    "#A52A2A",
    "#F08080",
    "#E0FFFF"
];
export default colors; 
