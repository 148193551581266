export const parseMode = (input) => {
  const [_field, mode] = input.trim("\n").split(/:\s+/);
  return { mode };
};
const parseBypassRow = (fields) => (row) =>
  Object.fromEntries(
    row
      .trim(" ")
      .split(/\s+/)
      .map((value, index) => [fields[index], value])
  );

const bypassTraslations = {
  MODEL: "model",
  "SERIAL-NUMBER": "sn",
  MODE: "mode",
  "LINKS-IN-BYPASS": "links",
};
const fieldsForHead = (head) =>
  head
    .trim(" ")
    .split(/\s+/)
    .map((key) => bypassTraslations[key] || null);
export const parseBypassList = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const fields = fieldsForHead(head);
  return rows.map(parseBypassRow(fields));
};

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

const newHIDConfigurationBlock = (...commands) =>
  newBlock("configure", "high-availability", ...commands, "commit", "end").trim(
    "\n"
  );

const ensureSomeSettingsChanged = (permisive = false) =>
  permisive === true
    ? (...parts) => parts
    : (...parts) => {
        if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
          throw "No changes available to commit.";
        }
        return parts;
      };

const applyMode = (previously, { mode }) =>
  previously.mode === mode
    ? [null]
    : mode === "disabled"
    ? ["no hid"]
    : [`hid ${mode}`];

export const composeApplyCommand = (previous, settings) =>
  newHIDConfigurationBlock(
    ...ensureSomeSettingsChanged(previous.enabled !== settings.enabled)(
      ...applyMode(previous, settings)
    )
  );
