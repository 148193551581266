import * as d3 from "d3";

export function getColorScaleReds(min, max){
    return d3
    .scaleSequential()
    .interpolator(d3.interpolateReds)
    .domain([min, max]);
}

export function getColorScale(min, max, colorMin, colorMax){
    return d3
    .scaleSequential()
    .range([colorMin, colorMax])
    .domain([min,max]);
}

export function getDataValues(items, legendSet){
    return items.flatMap((item) => {
        return Object.keys(item).reduce((acc, value) => {
          if (value !== "time" && legendSet.has(value)) {
            acc.push(item[value]);
          }
          return acc;
        }, []);
      });
}

export const getShortName = (name) => {
  const start = name.substring(0, 13);
  const end = name.substr(name.length - 13);
  if (name.length === 27) {
    return `${start}.${end}`;
  } else if (name.length === 28){
    return `${start}..${end}`;
  }
  return `${start}...${end}`;
};


export const getColorValue = ({value, min, max, colorScale, colorEmpty, colorMin, colorMax}) => {
  if (value === "n/a") {
    return colorEmpty;
  }
  if (value > max) {
    return colorMax;
  }
  if (value < min) {
    return colorMin;
  }
  return colorScale(value);
};