import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

/*****************************************************
 * Help Modal
 * ****************************************************/
const ConfigRESTHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="REST API CONFIGURATION"
      onClose={doClose}
      content={() => (
        <>
          <p>Allowed REST API client IP addressess configuration.</p>
          <ul>
            <li>
              <b>ADDRESS</b>: REST API client IP address.
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on this policy (e.g. delete).
            </li>
          </ul>
          <p>REST API users configuration.</p>
          <ul>
            <li>
              <b>USER</b>: REST API user name.
            </li>
            <li>
              <b>PASSWD</b>: REST API user password.
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on this policy (e.g. delete).
            </li>
          </ul>
          <p>In order to change port, REST API must be set to off.</p>
          <p>
            At least one REST API user must be supplied in order to set to on.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete the corresponding entry.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add Client...</b> option to add a new
            REST API client entry.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add User...</b> option to add a new REST
            API user entry.
          </p>
        </>
      )}
    />
  );
};

export default ConfigRESTHelpModal;
