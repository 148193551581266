import styled from "styled-components";
import colors from './colors';
import colorsDark from "./colorsDark";

const StyledContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  ${props => props.margin.left !== undefined
    ? `padding-left: ${props.margin.left}px`
    : '' };
  align-items: center;
  width: auto;
  flex-flow: row wrap;

  .field {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    ${props => props.itemWidth ? `width: ${props.itemWidth}px; ` : ''}
    ${props => props.itemMaxWidth ? `max-width: ${props.itemMaxWidth}px; ` : ''}
    .square {
      width: 12px;
      min-width: 12px;
      height: 12px;
      border: 2px solid;
      border-radius: 6px;
    }

    label {
      user-select: none;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: normal;
      color: var(--chart-text-color);
      cursor: pointer;
      font-family: "AllianceNo2-Regular";
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

function LegendItem({ color, label, name, isSelected, onSelect, onSeriesInspect }) {
  const field = { label, name, color };

  const handleContextClick = (e)=> {
    if (onSeriesInspect){
      e.preventDefault();
      onSeriesInspect(name);
    }
  }

  const labelDisplayed = label.replace(/\[sg\]/, '');

  return (
    <div
      className="field"
      onClick={() => onSelect(field, "single")}
      onDoubleClick={() => onSelect(field, "double")}
      onContextMenu={handleContextClick}
      data-toggle="tooltip" data-placement="top" title={labelDisplayed}
    >
      <div
        className="square"
        style={{
          backgroundColor: isSelected ? color : "transparent",
          borderColor: color,
        }}
      ></div>
      <label> {labelDisplayed}</label>
    </div>
  );
}

export default function Legend({ fields, onChange, value, onSeriesInspect, margin={}, itemWidth=undefined, itemMaxWidth=undefined }) {
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  function handleSelect(field, selection) {
    if (selection === "single") {
      if (value.size === 1) {
        const newFilterSet = new Set(fields.map((field) => field.name));
        onChange(newFilterSet);
      } else {
        const newFilterSet = new Set(value);
        if (newFilterSet.has(field.name)) {
          newFilterSet.delete(field.name);
        } else {
          newFilterSet.add(field.name);
        }
        onChange(newFilterSet);
      }
    } else {
      const newFilterSet = new Set([field.name]);
      onChange(newFilterSet);
    }
  }
  return (

    <StyledContainer margin={margin} itemWidth={itemWidth} itemMaxWidth={itemMaxWidth}>
      {fields.map((field, index) => {
        const { name, label, color } = field;
        
        return (
          <LegendItem
            key={label}
            label={label}
            color={color || colorsSet[index]}
            name={name}
            isSelected={value.has(name)}
            onSelect={handleSelect}
            onSeriesInspect={onSeriesInspect}
          />
        );
      })}
    </StyledContainer>
  );
}
