import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import TextInput from "common/TextInput";

const doesNothing = () => {};

const CreateUser = ({
  others = [],
  doSave = doesNothing,
  doValidate = doesNothing,
}) => {
  const actions = useContext(ActionsContext);
  const [userCreation, setUserCreation] = useState(false);
  const showUserCreation = () => setUserCreation(true);
  const hideUserCreation = () => setUserCreation(false);

  useEffect(() => {
    actions.recv("new-user", () => showUserCreation());
  }, []);

  return (
    <React.Fragment>
      {userCreation === true ? (
        <CreateUserModal
          active={userCreation}
          others={others}
          doSave={doSave}
          doValidate={doValidate}
          onClose={hideUserCreation}
        />
      ) : null}
    </React.Fragment>
  );
};

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const CreateUserModal = ({
  doSave = doesNothing,
  onClose = doesNothing,
  doValidate = doesNothing,
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);

  const doClose = () => setOpen(false);

  const handleChange = () => {
    try {
      const currently = getFormData(form.current);
      doValidate(currently);
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    try {
      const currently = getFormData(form.current);
      doSave(currently);
      doClose();
    } catch (error) {
      setError(error);
    }
  };

  if (open === false) {
    return null;
  }

  return (
    <Modal
      title="Add REST API User"
      superIcon="add_circle"
      large={false}
      content={() => (
        <form ref={form}>
          <AskUsername onChange={handleChange} />
          <AskPassword onChange={handleChange} />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      doValidate={doValidate}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

const AskPassword = ({ password, onChange }) => (
  <>
    <TextInput
      type="text"
      name="password"
      label="Password:"
      className="input-control"
      defaultValue={password}
      onChange={onChange}
      icon={"lock"}
    />
  </>
);

const AskUsername = ({ username, onChange }) => (
  <>
    <TextInput
      type="text"
      name="username"
      label="User:"
      className="input-control"
      defaultValue={username}
      onChange={onChange}
      icon={"person"}
    />
  </>
);

export default CreateUser;
