import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseSubscriberVolume = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberFlows = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberLatencies = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

const calcTimeLength = ([first, second]) =>
  (second.time.getTime() - first.time.getTime()) / 1000; /*ms*/

const divideCreationByTimeLength =
  (timelength) =>
  ({ flCreated, ...rest }) => ({
    flCreatedPerMinute: Math.round((flCreated * 60) / timelength),
    ...rest,
  });

export const calcFlowsCreationPerMinute = (source) =>
  source.map(divideCreationByTimeLength(calcTimeLength(source)));

export const parseSubscriberMaxSpeeds = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "DOWN-MBPS": "downMbPerSec",
    "UP-MBPS": "upMbPerSec",
  };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const parseSubscriberRetransmissions = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "SUBS-ACCESS-RTX-%": "subsAccessRtx",
    "NETWORK-ACCESS-RTX-%": "networkAccessRtx",
  };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

export const toTransformGiven = (interval, attributes = [], scale = 1) => {
  const transform = (volume) =>
    volume === undefined
      ? undefined
      : volume === null
      ? null
      : fixedPrecision((volume * 8 * scale) / interval, 4);
  const convertToSpeed = ({ time, ...item }) =>
    Object.fromEntries([
      ["time", time],
      ...attributes.map(({ from, to }) => [to, transform(item[from])]),
    ]);
  return { convertToSpeed };
};

export const parseSubscriberBandwidth = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n").slice(0, -1);
  const translations = { GIGABYTES: "volume" };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  const skipped = ["rest"];
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseSubscriberBandwidthShare = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [head, ...rows] = lines.slice(0, -1); //Omits totals
  const translations = { GIGABYTES: "volume" };
  const keys = head
    .split(/\s+/)
    .map((key) => translations[key] || asJSAttribute(key));
  const skipped = ["usage", "volume"];
  const appendToservices = (services, key, value) =>
    services === undefined || services.length === 0
      ? [{ name: key, share: value }]
      : [...services, { name: key, share: value }];
  const toServicesList = (reading, [key, value]) =>
    skipped.includes(key) === true
      ? reading
      : key === "time"
      ? { ...reading, time: value }
      : {
          ...reading,
          services: appendToservices(reading.services, key, value),
        };

  return rows.map((row) =>
    timeAndNumbers(row, keys, skipped).reduce(toServicesList, {})
  );
};

const addVolumeField = (volume) => (share) => ({
  ...share,
  volume: fixedPrecision(volume * share.share, 4),
});

const combineVolumeWithShare = (volume, share) => ({
  ...volume,
  services: share.services.map(addVolumeField(volume.volume)),
});

export const projectShareInVolume = (volumes, share) =>
  volumes.length === 0
    ? []
    : share.length === 0
    ? volumes
    : extractAndCombine(volumes, share);

const extractAndCombine = ([current, ...rest], [share, ...nextShare]) =>
  share.time.getTime() === current.time.getTime()
    ? [
        combineVolumeWithShare(current, share),
        ...projectShareInVolume(rest, nextShare),
      ]
    : [current, ...projectShareInVolume(rest, [share, ...nextShare])];

const addSpeedField = (speed) => (share) => ({
  ...share,
  speed: fixedPrecision(speed * share.share, 4),
});

const combineSpeedWithShare = (hourlySpeed, share) => ({
  ...hourlySpeed,
  services: share.services.map(addSpeedField(hourlySpeed.speed)),
});

export const projectShareInSpeed = (speeds, share) =>
  speeds.length === 0
    ? []
    : share.length === 0
    ? speeds
    : extractAndCombineSpeed(speeds, share);

const extractAndCombineSpeed = ([current, ...rest], [share, ...nextShare]) =>
  share.time.getTime() === current.time.getTime()
    ? [
        combineSpeedWithShare(current, share),
        ...projectShareInSpeed(rest, nextShare),
      ]
    : [current, ...projectShareInSpeed(rest, [share, ...nextShare])];

const serviceAndLatency = (row) => {
  const [service, latency, ..._rest] = row.split(/\s+/);
  return [
    ["service", service],
    ["latency", parseFloatOrNull(latency)],
  ];
};

export const parseServiceLatencies = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map((row) => Object.fromEntries(serviceAndLatency(row)));
};

const latenciesByRTT = (row) => {
  const [upRttBin, share] = row.split(/\s+/);
  return [
    ["bin", upRttBin],
    ["share", fixedPrecision(parseFloatOrNull(share) / 100, 4)],
  ];
};

export const parseLatenciesByRTT = (input) => {
  if (input.length === 0) {
    return [];
  }
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map((row) => Object.fromEntries(latenciesByRTT(row)));
};

const latenciesByTime = (row, [_time, ...bins]) => {
  const [time, ...binValues] = row.split(/\s+/);
  const values = bins.map((bin, index) => ({
    bin,
    share: fixedPrecision(parseFloatOrNull(binValues[index]), 4),
  }));
  return [
    ["time", parseSystemDate(time)],
    ["values", values],
  ];
};

const mayIncludeInstance = (device, facts) => {
  const instanceFact = device.includes("of instance")
    ? undefined
    : facts.find(({ label }) => label === "Instance");
  return instanceFact === undefined
    ? device
    : `${device} of instance ${instanceFact.value}`;
};

const addFactsTo = (target, newFacts) => {
  const { device, facts = [] } = target;
  return {
    device: mayIncludeInstance(device, newFacts),
    facts: [...facts, ...newFacts],
  };
};
export const parseLatenciesByTime = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/);
  return rows.map((row) => Object.fromEntries(latenciesByTime(row, keys)));
};

const asFact = (label, value) => ({ label, value: value.trim(" ") });
const decouple = (fact) => asFact(...fact.split(/\:\s+/));
const extractDevicesAndFacts = (result, line) => {
  const deviceMatch = line.match(/^IP Address\:\s+(.+)$/);
  if (deviceMatch !== null) {
    const [_all, deviceAddress] = deviceMatch;
    const addressFact = { label: "IP Address", value: deviceAddress };
    return [{ device: deviceAddress, facts: [addressFact] }, ...result]; // Will reverse later
  }
  const factsMatch = line.match(/[^\s{0}]([^:]+)\:\s+([^ ]+)\s*/g);
  if (factsMatch !== null) {
    const newFacts = factsMatch.map(decouple);
    const [target, ...others] = result;
    return [addFactsTo(target, newFacts), ...others];
  }
  return result;
};

export const parseSubscriberInformation = (input) => {
  const lines = input.trim("\n").split("\n");
  return lines.reduce(extractDevicesAndFacts, []).reverse();
};

const customValueParsing = (row, keys, parserByKey = {}, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            parserByKey[keys[index]] ? parserByKey[keys[index]](value) : value,
          ]
    )
    .filter((item) => item !== null);

export const parseTrafficCongestion = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "CONGESTION-%": "congestion",
    "BYTES-DN-NEAR-LIMIT-%": "maxSpeed",
    "ACM-%": "acm",
  };
  const skipped = [
    "REDUCTION-RTT-MS",
    "",
    "REDUCTION-RTX-%",
    "REDUCTION-SPEED-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseLatencyReduction = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "REDUCTION-RTT-MS": "reduction",
  };
  const skipped = [
    "CONGESTION-%",
    "ACM-%",
    "REDUCTION-RTX-%",
    "REDUCTION-SPEED-%",
    "BYTES-DN-NEAR-LIMIT-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseRetransmissionsReduction = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "REDUCTION-RTX-%": "reduction",
  };
  const skipped = [
    "CONGESTION-%",
    "ACM-%",
    "REDUCTION-RTT-MS",
    "REDUCTION-SPEED-%",
    "BYTES-DN-NEAR-LIMIT-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const getServiceFields = ([item, ...rest]) =>
  item === undefined
    ? []
    : "services" in item
    ? item.services.map(({ name }) => ({ name, label: name }))
    : getServiceFields([...rest]);

export const servicesSpeedAsFields = (items) =>
  items.map(({ time, services = [] }) =>
    Object.fromEntries([
      ["time", time],
      ...services.map(({ name, speed }) => [name, speed]),
    ])
  );