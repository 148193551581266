/* global DPI_STATS_TMOUT_MS, components, views, functionality */
import React, { useContext } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/Hourly/component";
import ActionsContext from "common/ActionsContext";
import SubscribersHourlyVolumeHelpModal from "./Help";
import { expressTarget } from "common/api";
import { parseSubscribersSpeed } from "../api";
import * as d3 from "d3";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const doSpeedShareRetrieval = (
  target,
  hours = 72,
  categories = 20,
  directions = "all",
  output = "ip-addresses"
) =>
  expressTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics subscribers top ${expressedTarget} ${output} direction ${directions} categories ${
        categories - 1
      } hours ${hours}`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const retrieval = ({ target, hours, categories, directions, output }) =>
  doSpeedShareRetrieval(target, hours, categories, directions, output)
    .then(parseSubscribersSpeed)
    .catch((error) => console.error(error) || error);

const formatOnlyDecimals = d3.format(",.2f");

const formatDecimals = (value) =>
  value === undefined ? "--" : formatOnlyDecimals(value);

const TotalVolume = ({ totals }) => (
  <h4 className="align-left">
    Total volume: {formatDecimals(totals.volume)} GB
  </h4>
);
const hoursScopeChoices = [
  { value: 24, label: "1 Day" },
  { value: 24 * 2, label: "2 Day" },
  { value: 24 * 3, label: "3 Days" },
  { value: 24 * 7, label: "Week" },
];

const settings = { hours: { options: hoursScopeChoices } };

const enabled = {
  hours: true,
  directions: true,
  categories: true,
  target: true,
  output: true,
  filter: false,
};

const returnTo = "viewSubscribersHourlyVolume";

const openSubscriberDetail = ({ key }, { output = "ip-addresses" }) => {
  const target = output === "subscriber-ids" ? "subsId" : "addr";
  views.doKeep(returnTo);
  globalNavigate("viewSubsDetail", {returnView: returnTo, [target]: key});
};

const SubscribersHourlyVolume = () => {
  const actions = useContext(ActionsContext);
  return (
    <div id="viewSubscribersHourlyVolume" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Top subscribers by time</h2>
          <ContextMenu />
          <small>
            <code>right-click</code>
            on the area for a subscriber inside the chart, or in the labels
            above the chart, to get detailed subscriber information
          </small>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            graphTitle={null}
            from="timeline"
            itemsFrom="subscribers"
            itemsValueFrom="speed"
            provider={retrieval}
            actions={actions}
            settings={settings}
            enabled={enabled}
            Totals={TotalVolume}
            onSeriesInspect={openSubscriberDetail}
          />
        </div>
        <SubscribersHourlyVolumeHelpModal />
      </div>
    </div>
  );
};

export default SubscribersHourlyVolume;
