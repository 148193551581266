/* globals login */
import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import { YYYYMMDDHHmmssSSS } from "common/utils";
import CaptureSettingsModal from "./Settings";
import CaptureProcessModal from "./Process";

const emptyTarget = { name: "", type: "" };

function buildCapturingFilter(filter, includeVlan, includePppoe) {
  // "Ex. (tcp and host 192.168.1.102) or (vlan and (tcp and host 192.168.1.102))"
  // "Ex. (host 172.60.63.73 and port 443) or (vlan and pppoes and host 172.60.63.73 and port 443)"
  let result = "";
  if (filter) {
    if (includeVlan && includePppoe) {
      result = `filter "vlan and pppoes and (${filter})"`;
    } else if (includeVlan) {
      result = `filter "vlan and (${filter})"`;
    } else if (includePppoe) {
      result = `filter "pppoes and (${filter})"`;
    } else {
      result = `filter "${filter}"`;
    }
  }
  return result;
}

const getCaptureFilename = ({ filter, size, timeout }) => {
  const timestamp = YYYYMMDDHHmmssSSS(new Date());
  const file = `capture-${name}${
    filter ? "-" + filter.replaceAll(" ", "_") : ""
  }-${size}-${timeout}-${timestamp}.pcap`;
  return file;
};

const getCaptureFilepath = (filename) => {
  return `/home/${login.user}/${filename}`;
};

let timer = null;

const stopAfterTimeout = (timeout, cancel) => {
  const stop = () => {
    return cancel().finally(() => {
      timer !== null && clearTimeout(timer);
      timer = null;
    });
  };
  timer = setTimeout(() => {
    return cancel();
  }, timeout * 1000 /*ms*/);
  return stop;
};

const doCapture = ({
  name,
  filter,
  size,
  timeout,
  includeVLAN,
  includePPPoE,
}) => {
  const filename = getCaptureFilename({ filter, size, timeout });
  const filepath = getCaptureFilepath(filename);
  const formattedFilter = buildCapturingFilter(
    filter,
    includeVLAN,
    includePPPoE
  );
  const command = `system interface ${name} capture ${formattedFilter} file ${filepath} ${size}`;
  const { promise, cancel } = ifCl.runWithCancel(command, {
    cancellable: true,
    timeout: 601000,
  });
  const stop = stopAfterTimeout(timeout, cancel);
  return {
    request: promise,
    stop,
    filepath,
    filename,
  };
};

const LinkCaptureModal = () => {
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(emptyTarget);
  const [capturing, setCapturing] = useState(null);
  const actions = useContext(ActionsContext);
  const doOpen = () => setOpen(true);
  useEffect(
    () =>
      actions.recv("show-interface-capture", (params) => {
        setTarget(params);
        setCapturing(null);
        doOpen();
      }),
    []
  );
  const doClose = () => {
    capturing !== null && capturing.stop();
    setOpen(false);
  };

  const startCapturing = (processing) => {
    const { request } = processing;
    setCapturing(processing);
    return request.catch(() => setCapturing(null));
  };

  const doApply = (settings) => startCapturing(doCapture(settings));

  return open !== true ? null : capturing === null ? (
    <CaptureSettingsModal target={target} onApply={doApply} doClose={doClose} />
  ) : (
    <CaptureProcessModal target={target} doClose={doClose} {...capturing} />
  );
};

export default LinkCaptureModal;
