import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import styled from "styled-components";
import { getDateWithOffset } from "common/api";
import { useDimensions } from "common/hooks";
import NVStackedAreaChartLive from "views/Statistics/SubscriberDetail/NVStackedAreaChartLive";
import {
  getItemsUpdate,
  dataMapKey,
} from "views/Statistics/SubscriberDetail/livemetrics.utils";
import { default as LiveLegend } from "views/Statistics/SubscriberDetail/DPIAnalyticsLiveLegend";
import Tooltip from "./Tooltip";

const Wrapper = styled.div`
  width: 100%;
`;

const LegendContainer = styled.div`
  > div {
    display: block;
  }
`;

const Title = styled.h4`
  font-weight: 100;
  font-size: var(--chart-title-font-size) !important;
  margin: 20px 0 15px;
`;

const ChartContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }

  .border {
    position: absolute;

    &.top {
      width: 100%;
      height: 55px;
      min-height: 55px;
      top: -50px;
      left: 0;
    }
    &.right {
      width: 45px;
      height: 100%;
      top: 0;
      right: 5px;
    }

    &.bottom {
      min-height: 70px;
      width: 100%;
      height: 30px;
      bottom: -40px;
      left: 0;
    }

    &.left {
      width: 50px;
      height: 100%;
      top: 0;
      left: 20px;
    }
  }
`;

const Chart = ({
  value,
  timeOffset,
  fields,
  fieldsMap,
  timeWindow,
  title,
  onMouseEvent,
  mouseEvent,
  liveMode,
  output,
  times,
}) => {
  const [items, setItems] = useState([]);
  const [window, setWindow] = useState(0);
  const [filterFields, setFilterFields] = useState(new Set());
  const [filteredFields, setFilteredFields] = useState([]);
  const firstValue = useRef(false);
  const fieldsSet = useRef({});
  const chartRef = useRef(null);
  const dataMap = useRef({});
  const { width } = useDimensions(chartRef);

  useEffect(() => {
    if (liveMode) {
      setItems([]);
      setWindow(0);
      setFilterFields(new Set());
      setFilteredFields([]);
      firstValue.current = false;
      fieldsSet.current = {};
      dataMap.current = {};
    }
  }, [liveMode, output]);

  useEffect(() => {
    if (!value) {
      return;
    }

    const now = getDateWithOffset(timeOffset);
    const newValue = { ...value };

    if (!firstValue.current) {
      firstValue.current = newValue;
    }

    const fieldNames = Object.keys(fieldsMap);
    const addedFields = fieldNames.filter((field) => !fieldsSet.current[field]);
    const filterFieldsWithoutRemoved = [...filterFields].filter(
      (fieldName) => fieldsSet.current[fieldName]
    );
    setFilterFields(new Set([...filterFieldsWithoutRemoved, ...addedFields]));
    fieldsSet.current = fieldsMap;

    const timeFrame =
      newValue.time.getTime() - firstValue.current.time.getTime();
    let { itemsUpdate, window: windowNew } = getItemsUpdate(
      items,
      now,
      fields,
      timeOffset,
      timeFrame,
      timeWindow,
      newValue,
      window
    );
    setWindow(windowNew);
    const lastValue = itemsUpdate[itemsUpdate.length - 1];
    if (
      lastValue?.time &&
      newValue?.time &&
      dataMapKey(lastValue.time) !== dataMapKey(newValue.time)
    ) {
      delete dataMap.current[dataMapKey(itemsUpdate[0].time)];
      const totalSpeedValues = Object.values(fieldsMap).reduce(
        (acc, fieldValue) => {
          acc.up = acc.up + fieldValue.totalUp;
          acc.down = acc.down + fieldValue.totalDown;
          return acc;
        },
        { up: 0, down: 0 }
      );
      dataMap.current[dataMapKey(newValue.time)] = {
        time: newValue.time,
        fields: [...fields],
        value: newValue,
        totalUp: totalSpeedValues.up,
        totalDown: totalSpeedValues.down,
      };
      setItems([...itemsUpdate.slice(1), newValue]);
    }
  }, [value]);

  useEffect(() => {
    if (filterFields) {
      setFilteredFields(fields.filter((field) => filterFields.has(field.name)));
    }
  }, [filterFields]);

  const top20Fields = useMemo(
    () =>
      Object.entries(fieldsMap)
        .map(([key, { total, color }]) => {
          return {
            label: key,
            name: key,
            color,
            total,
          };
        })
        .sort((a, b) => b.total - a.total)
        .slice(0, 21),
    [JSON.stringify(fieldsMap)]
  );

  const hasRestValue = fieldsMap["rest"]
    ? fieldsMap["rest"].total >= top20Fields[top20Fields.length - 1].total
    : false;

  function removeTooltip() {
    if (mouseEvent) {
      onMouseEvent(null);
    }
  }

  const openDashboard = (label, output) => {
    if(label === 'rest'){
      return;
    }
    
    const target =
      output === "ip-addresses"
        ? { addr: label, subsId: null }
        : { addr: null, subsId: label };
    globalNavigate("viewSubsDetail", {
      ...target,
      returnView: "viewSubscriberThroughput",
    });
  };

  return (
    <Wrapper>
      <Title className="chart-title">{title}</Title>
      {items.length > 0 ? (
        <LegendContainer>
          <LiveLegend
            fields={top20Fields}
            onChange={(filter) => setFilterFields(filter)}
            value={filterFields}
            rest={hasRestValue ? fieldsMap["rest"] : false}
            onContextMenu={(name) => openDashboard(name, output)}
            maxWidthField="240"
          />
        </LegendContainer>
      ) : null}
      <ChartContainer ref={chartRef}>
        {mouseEvent && (
          <Tooltip
            dataPoint={
              dataMap.current[dataMapKey(mouseEvent.pointXValue)] || {}
            }
            mouseX={mouseEvent.mouseX}
            mouseY={mouseEvent.mouseY}
            title={mouseEvent.pointXValue}
            mouseEvent={mouseEvent}
            chartWidth={width}
            fieldsMap={fieldsMap}
            filterFields={filterFields}
            times={times}
            type={title}
          />
        )}
        {mouseEvent && (
          <>
            <div
              className="border top"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border right"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border bottom"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
            <div
              className="border left"
              onMouseEnter={removeTooltip}
              onMouseLeave={removeTooltip}
            ></div>
          </>
        )}
        <NVStackedAreaChartLive
          items={items}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          fields={filteredFields.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })}
          yAxisUnits="Mbps"
          yAxisTopGap={0.05}
          margin={{ top: 5, right: 50, bottom: 30, left: 70 }}
          chartWidth={width}
          onHighlight={(e) => {
            if (e) {
              onMouseEvent(e);
            }
          }}
          highlight={mouseEvent}
          onSeriesInspect={({name}) => openDashboard(name, output)}
        />
      </ChartContainer>
    </Wrapper>
  );
};

export default Chart;
