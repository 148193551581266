import React, { useContext } from "react";
import styled from "styled-components";
import Report from "../Report";
import ActionsContext from "common/ActionsContext";
import DoSSynStatsHelpModal from "./Help";
import { Column } from "common/DataTable";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
`;

const provider = ({ hours }) =>
  ifCl.run(`show statistics dos syn hours  ${hours}`);

const columns = [
  Column.Text({
    label: "TIME",
    idx: 0,
    colClassName: "nowrap",
    expand: true,
    width: "20em",
  }),
  Column.IPAddress({
    label: "IP-ADR",
    idx: 1,
    onClick: false,
  }),
  Column.Text({
    label: "SIDE",
    idx: 2,
    precision: 0,
  }),
  Column.Text({
    label: "DIRECTION",
    idx: 3,
    precision: 0,
  }),
  Column.Number({
    label: "FAIL-SYN",
    idx: 4,
    precision: 0,
    colClassName: "align-right",
  }),
  Column.Number({
    label: "MAX-RATE",
    idx: 5,
    precision: 0,
    colClassName: "align-right",
  }),
  Column.TimeAgo({
    label: "DURATION",
    idx: 6,
    precision: 0,
    colClassName: "align-right",
  }),
  Column.IPAddressOrNA({
    label: "TOP-CONTRIBUTOR",
    idx: 7,
    onClick: false,
  }),
  Column.Number({
    label: "TOP-FAIL-SYN",
    idx: 8,
    colClassName: "align-right",
  }),
];

const order = [["TIME", "desc"]];

const DoSSynStats = () => {
  const actions = useContext(ActionsContext);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title case-sensitive">DoS syn attacks over time</h2>
          <ContextMenu/>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            provider={provider}
            actions={actions}
            columns={columns}
            order={order}
          />
        </div>
        <DoSSynStatsHelpModal />
      </div>
    </div>
  );
};

export default DoSSynStats;
