import React, { useRef, useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useDimensions } from "common/hooks";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import Chart from "./Chart";
import Tooltip from "./Tooltip";
import ColorLegend from "./ColorLegend";
import { getColorScale, getColorScaleReds, getDataValues } from "./utils";

const _targetTypeChoices = [
  { value: "address", label: "IP-Addr filter" },
  { value: "subs_id", label: "Subs-ID filter" },
];

const entityMap = {
  "subscriber-id": "Subscriber ID",
  "ip-address": "IP Address"
};

const COLOR_LEGEND_HEIGHT = 40;
const MIN_HEIGHT_CELL = 18;
const MAX_HEIGHT_CELL = 100;
const MIN_HEIGHT_CHART = 400;

const getHeight = (fieldsNr) => {
  const minHeightCnt = Math.floor(MIN_HEIGHT_CHART / MAX_HEIGHT_CELL);
  const maxHeightCnt = Math.floor(MIN_HEIGHT_CHART / MIN_HEIGHT_CELL);

  if (fieldsNr > 0 && fieldsNr <= minHeightCnt) {
    return fieldsNr * MAX_HEIGHT_CELL;
  } else if (fieldsNr > minHeightCnt && fieldsNr <= maxHeightCnt) {
    return MIN_HEIGHT_CHART;
  } else {
    return fieldsNr * MIN_HEIGHT_CELL;
  }
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position relative;

  svg {
    width: 100%;
    height: 100%;

    .heatmapBackground {
      fill:none;
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }

    .heatMapAxis {
      fill: var(--chart-axis-text-color);
      color: #929295;
      font-family: var(--chart-font-family, Arial, sans-serif);
      font-size: 9px;

      &.axisSelect {
        cursor: pointer;
      }
    }

    .heatMapAxisCircle {
      &.axisSelect {
        cursor: pointer;
      }
    }
  }
`;

const LegendWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeadersWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin: 15px 0 5px 79px;
`;

const EntityHeader = styled.div`
  width: 120px;
  text-align: right;
  text-transform: uppercase;
  font-size: 11px !important;
  padding-top: 2px;
`;

const MetricHeader = styled.div`
  font-size: 13px !important;
  padding-top: 0px;
  width: 100%;
  text-align: center;
  font-family: "AllianceNo2-SemiBold" !important;
`;

const MeanHeaderClickArea = styled.div`
  width: 50px;
  color: #929295;
  margin-left: 15px;
  cursor: pointer;

  div {
    background-repeat: no-repeat;
    background-position: top right;
    padding-right: 15px;
    padding-top: 2px;
    font-size: 11px !important;
  }

  .sort-default {
    background-image: url(../images/sort_both.png);
  }
  .sort-desc {
    background-image: url(../images/sort_desc.png);
  }
  .sort-asc {
    background-image: url(../images/sort_asc.png);
  }
`;

const MeanHeader = styled.div`
  width: 100%;
  height: 100%;
`;

const HeatMap = ({
  margin = { top: 0, right: 40, bottom: 15, left: 105 },
  unit,
  items,
  fields,
  legendSet,
  setLegendFieldsSet,
  onSeriesInspect,
  precision,
  onMinChanged,
  onMaxChanged,
  colorScaleMaxOverride,
  colorScaleMinOverride,
  sampledValues,
  heatmapFormat,
  entity,
  metric,
  hoveredCell,
  setHoveredCell
}) => {
  const chartRef = useRef(null);
  const dimensions = useDimensions(chartRef);
  const [colorScaleMax, setColorScaleMax] = useState(null);
  const [colorScaleMin, setColorScaleMin] = useState(null);
  const { width } = dimensions;
  const [meanSorting, setMeanSorting] = useState("default");

  useEffect(() => {
    if (
      colorScaleMaxOverride !== undefined &&
      colorScaleMaxOverride !== colorScaleMax
    ) {
      setColorScaleMax(colorScaleMaxOverride);
    }
  }, [colorScaleMaxOverride]);

  useEffect(() => {
    if (
      colorScaleMinOverride !== undefined &&
      colorScaleMinOverride !== colorScaleMin
    ) {
      setColorScaleMin(colorScaleMinOverride);
    }
  }, [colorScaleMinOverride]);

  if (!items || !fields || items.length === 0 || fields.length === 0) {
    return null;
  }

  const height = getHeight(fields.length);
  const dataValues = getDataValues(items, legendSet);
  const [min = 0, max = 0] = d3.extent(dataValues);

  const minScale = colorScaleMin ? colorScaleMin : min;
  const maxScale = colorScaleMax ? colorScaleMax : max;
  const colorScaleLight = getColorScaleReds(minScale, maxScale);
  const colorScaleDark = getColorScale(
    minScale,
    maxScale,
    "#031d49",
    "#cdd8f3"
  );
  const colorScale = login.isTheme("light") ? colorScaleLight : colorScaleDark;
  const filteredSampledValues = Object.entries(sampledValues).reduce((acc, [key, value]) => {
    const newValues = value.map((v) => {
      if (v > maxScale) {
        return maxScale;
      }
      if (v < minScale) {
        return minScale;
      }
      return v;
    });
    acc[key] = newValues;
    return acc;
  }, {})

  const handleMeanClick = () => {
    switch (meanSorting) {
      case "default":
        setMeanSorting("asc");
        break;
      case "asc":
        setMeanSorting("desc");
        break;
      case "desc":
        setMeanSorting("default");
        break;
      default:
        setMeanSorting("default");
        break;
    }
  };

  return (
    <>
      <HeadersWrapper>
        <EntityHeader>{entityMap[entity]}</EntityHeader>
        <MeanHeaderClickArea onClick={handleMeanClick}>
          <MeanHeader className={`sort-${meanSorting}`}>
            MEAN
          </MeanHeader>
        </MeanHeaderClickArea>
        <MetricHeader>{`${metric.toUpperCase()} (${unit})`} </MetricHeader>
      </HeadersWrapper>
      <Wrapper
        ref={chartRef}
        style={{ height }}
      >
        <Chart
          width={width}
          height={height - COLOR_LEGEND_HEIGHT}
          items={items}
          fields={fields}
          setHoveredCell={setHoveredCell}
          colorScale={colorScale}
          margin={margin}
          legendSet={legendSet}
          setLegendFieldsSet={setLegendFieldsSet}
          onSeriesInspect={onSeriesInspect}
          sampledValues={filteredSampledValues}
          heatmapFormat={heatmapFormat}
          sorting={meanSorting}
        />
        <Tooltip
          interactionData={hoveredCell}
          width={width}
          height={height - COLOR_LEGEND_HEIGHT}
          unit={unit}
        />
      </Wrapper>
    </>
  );
};

export default HeatMap;
