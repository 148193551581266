import React, { useRef, useEffect, useState } from "react";
import NumberInput from "common/NumberInput";
import { getFormData, rankAndParseFields } from "./utils";
import { parseInterfaceList, parseWireNames } from "./api";
import SelectInput from "common/SelectInput";
import Request from "common/Request";

const getDevicesList = (wires) =>
  ifCl
    .run("show interface raw")
    .then((response) => parseInterfaceList(response, wires));

const getWireNames = () =>
  ifCl.run("show config running | match wire").then(parseWireNames);

const getDevices = () => getWireNames().then((wires) => getDevicesList(wires));

const asLabelAndValue = ({ name }) => ({
  label: name,
  value: name,
});

const InterfaceSelection = ({
  onChange,
  name,
  label,
  defaultValue = undefined,
}) => {
  const [request, setRequest] = useState(null);
  useEffect(() => {
    setRequest(getDevices().then((list) => ({ list })));
  }, []);
  return request === null ? null : (
    <Request during={request}>
      {({ list }) => (
        <SelectInput
          name={name}
          label={label}
          defaultValue={defaultValue}
          onChange={onChange}
          options={list.map(asLabelAndValue)}
          className="input-control align-left"
        />
      )}
    </Request>
  );
};
const SynSettings = ({
  onChange,
  defaults = {},
  isOperatorProfile = false,
}) => {
  const form = useRef(null);
  const onInputChange = (event) => {
    const values = getFormData(form.current);
    const {value, name } = event.target;
    if (name==='interface'){
      onChange({...defaults, ...values, [name]:value});
    } else {
      onChange({...defaults, ...values});
    }
  };
  return (
    <form ref={form}>
      <h2 className="card-inside-title">Network Interface</h2>
      <p>Management network interface</p>
      <InterfaceSelection
        name="interface"
        label="Interface"
        defaultValue={defaults.interface}
        onChange={onInputChange}
        disabled={isOperatorProfile}
      />
      <h2 className="card-inside-title">VLAN</h2>
      <p>Management network Interface VLAN ID</p>
      <NumberInput
        name="vlan"
        label="VLAN ID"
        defaultValue={defaults.vlan}
        onChange={onInputChange}
        className="input-control align-left"
        placeHolder="1-4096, empty for none"
        precision={0}
        disabled={isOperatorProfile}
        type="text"
      />
    </form>
  );
};

export default SynSettings;
