export {mergeStateOfDevices} from "views/Status/Interfaces/DataWires/api";

const pCapAsCode = (access, inet) => (Number(access) << 1) | Number(inet);

const asWireDefinition = ({ acc, inet }) =>
  `${acc.iface} ${inet.iface} ${pCapAsCode(acc.pcap, inet.pcap)}`;

const notDeleted = ({ deleted = false }) => deleted !== true;

const requiresAtLeast = (min = 0, items, message = "unknown error") => {
  if (items.length < min) {
    throw message;
  }
  return items;
};

const wiresSettingCommand = (previous, wires) =>
  [
    "set wire",
    ...requiresAtLeast(
      1,
      wires.filter(notDeleted).map(asWireDefinition),
      "At least one wire is mandatory"
    ),
  ].join(" ");

export const composeApplyCommand = (previous, { wires }) =>
  wiresSettingCommand(previous.wires, wires).trim("\n");
