import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import Fields from "./Fields";

const doesNothing = () => {};

const CreateClient = ({
  others = [],
  doSave = doesNothing,
  doValidate = doesNothing,
}) => {
  const actions = useContext(ActionsContext);
  const [clientCreation, setClientCreation] = useState(false);
  const showClientCreation = () => setClientCreation(true);
  const hideClientCreation = () => setClientCreation(false);

  useEffect(() => {
    actions.recv("new-client", () => showClientCreation());
  }, []);

  return (
    <React.Fragment>
      {clientCreation === true ? (
        <CreateClientModal
          active={clientCreation}
          others={others}
          doSave={doSave}
          doValidate={doValidate}
          onClose={hideClientCreation}
        />
      ) : null}
    </React.Fragment>
  );
};

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const CreateClientModal = ({
  doValidate = doesNothing,
  doSave = doesNothing,
  onClose = doesNothing,
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const doClose = () => setOpen(false);

  if (open === false) {
    return null;
  }

  const handleChange = () => {
    try {
      const currently = getFormData(form.current);
      doValidate(currently);
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    try {
      const currently = getFormData(form.current);
      doSave(currently);
      doClose();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Modal
      title="Create Client"
      superIcon="add_circle"
      large={false}
      content={() => (
        <form ref={form}>
          <Fields onChange={handleChange} />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};
export default CreateClient;
