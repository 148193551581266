/* globals showModalInfo */
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Radio from "common/Radio.jsx";
import SettingsView, { NoContextMenu } from "common/SettingsView";
import { composeApplyCommand, checkWarnings } from "./api";
import requestUserFile from "./ProvideFile";
import ActionsContext from "common/ActionsContext";
import { requestMoment } from "views/Status/Billing/api";
import { YYYYMMDDHHmmssSSS } from "common/utils";

const strategyOptions = [
  { value: "load_remote", label: "Load backup from another server." },
  { value: "restore_local", label: "Restore backup from this server." },
  {
    value: "merge_file",
    label: "Merge configuration file with this server configuration.",
  },
];

const Interactive = styled.div`
  & > * {
    margin: 0.25em 1em;
  }
`;

const StrategyOption = styled.div`
  label {
    font-size: 14px !important;
  }
`;

const Warnings = ({ strategy }) => (
  <p>
    WARNING:
    <ul>
      {strategy !== "restore_local" ? null : (
        <li>
          Do not restore a backup saved from another server, use load instead:
          in a restore the full configuration is replaced, including the
          management interface and wire settings, so the node will most likely
          be unreachable and the service may be interrupted.
        </li>
      )}
      {strategy !== "merge_file" ? null : (
        <li>
          Merge a configuration file that contains strictly the sections you
          want to merge (for example profile, policy and rule commands). Merging
          a full configuration will generate conflicts, for example, the
          management default gateway will be replaced and the server may be left
          unreacheable.
        </li>
      )}
      {strategy !== "load_remote" ? null : (
        <li>
          This option replaces the current configuration with another one,
          except for those configuration commands specific for this server (like
          interface names or management IP address), which are kept from the
          current configuration. If you just want to add a few configuration
          commands, use the merge option.
        </li>
      )}
    </ul>
  </p>
);

const LoadSettings = ({ state, onChange }) => (
  <>
    <Interactive>
      <Radio
        options={strategyOptions}
        name="strategy"
        defaultValue={state.strategy}
        itemWrapper={StrategyOption}
        onChange={onChange}
      />
    </Interactive>
    <Warnings strategy={state.strategy} />
  </>
);

const reportSuccess = () =>
  showModalInfo("Success", "Configuration file loaded successfully");

const TMP_CONF_PATH = "/tmp/reload_config_";
const createFilePath = () => TMP_CONF_PATH + YYYYMMDDHHmmssSSS(new Date());


const mock = ( ) => Promise.resolve(`
%ERR-ENOENT: Cannot find \"en0p0s20f2\" wire network interface
`)

const saveToCLI = (filepath, contents) =>
  ifCl.transfer(contents, filepath)
    .catch((error, reason) => {
      throw `Error uploading configuration file: ${reason}`;
    });

const provideFile = () =>
  requestUserFile().then((contents) => saveToCLI(createFilePath(), contents));

const BackupLoadView = ({ defaults = {} }) => {
  const actions = useContext(ActionsContext);
  const [isBusy, setBusy] = useState(false);
  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  React.useEffect(() => {
    return actions.recv("do-load", () =>
      console.warn("Backup load got do load")
    );
  }, []);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const markAsBusy = (response) => setBusy(true) || response;

  const markAsReady = (response) => setBusy(false) || response;

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = (filePath) =>
      composeApplyCommand(originalSettings, { filePath, ...settings });

    return provideFile()
      .then(markAsBusy)
      .then((filePath) => buildCommand(filePath))
      .then(submitCommand)
      .then(checkWarnings)
      .then(reportSuccess)
      .catch(notifyAndRevert)
      .finally(markAsReady);
  };
  const load = () =>
    requestMoment().then((moment) => ({
      ...defaults,
      moment,
      strategy: "load_remote",
      file: "",
      pristine: false,
    }));
  return (
    <SettingsView
      load={load}
      apply={apply}
      applyLabel={isBusy !== true ? "SELECT FILE" : "APPLYING..."}
      ContextMenu={NoContextMenu}
      noDiscard={true}
      title="Load backup configuration file"
      applyActionClassName="hidden-to-operators"
      PendingChangesIndicator={null}
      subtitle={<small>Loading a configuration file may take some time.</small>}
      id="viewBackupLoad"
    >
      <LoadSettings />
    </SettingsView>
  );
};

export default BackupLoadView;
