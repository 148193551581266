import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import * as d3 from "d3";
import ActionsContext from "common/ActionsContext";
import CongestionHelpModal from "./Help";
import CongestionReport from "./Report";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const doesNothing = () => {};

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">
          help_outline
        </i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">
          refresh
        </i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  & .title {
    text-transform: uppercase;
  }
  & .context-menu {
    display: flex;
    flex-direction: row;
  }
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const Panel = ({ children, ...props }) => (
  <div className="card">
    <div className="body">{children({ ...props })}</div>
  </div>
);

const SubcriberDetail = ({ addr, returnView }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 viewCongestionACM">
      <div className="card">
        <div className="header block-header">
          <h2>Congestion & ACM</h2>
          <ContextMenu />
        </div>
        <div className="body flex-panel-row">
          <CongestionReport />
        </div>
      </div>
      <CongestionHelpModal />
    </div>
  );
};

export default SubcriberDetail;
