import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ServiceDetail from "./Detail";
import Carrousel from "common/layouts/Carrousel";
import NVHorizontalBarChart, {
  calcBarHeight,
  formats,
} from "common/graphs/NVHorizontalBarChart";
import colors from "common/graphs/colors";
import ExportButton from './Export';

const Scrolled = styled.div`
  overflow-y: auto;
  .table tbody tr td {
    padding: 0 10px;
    vertical-align: middle;
  }

  @media print {
    width: 100% !important;
    .dataTables_length,
    .dataTables_filter {
      display: none;
    }

    table {
      width: 100% !important;
    }
  }

`;

const CarrouselWrapper = styled.div`
  padding: 0 20px;
`

const doesNothing = () => {};

const UsageGraphLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
  .center {
    text-align: center;
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .legend {
    font-size: 0.85em;
    min-height: unset;
  }
  svg .nv-axis.nv-x .tick text {
    font-size: 1.15em;
    &.clickable {
      text-decoration: underline;
    }
  }
`;

const HeaderWithControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  h4 {
    margin-top: 0px !important;
    padding-left: 0px !important;
  }
`;
const asKeysValue = ({ service, latency }, index) => {
  const colorIndex = index % colors.length;
  return {label: service, value: latency, color: colors[colorIndex]}
}

const latencyKeyWithValues = (values) => [
  {
    key: "Latency",
    color: "#006AB7",
    values,
  },
];

const _defaultMargin = { top: 30, right: 9, bottom: 30, left: 70 };

const whichIsMaxLength = (max, { service }) =>
  max > service.length ? max : service.length;

const calcLabelsSpace = (latencies) => {
  const max = latencies.reduce(whichIsMaxLength, 0);
  return (max || 10) * 8;
};

const MainLatencies = ({ latencies, onSelect = doesNothing, context = {} }) => {
  const items = latencies.map(asKeysValue);
  return (
    <Scrolled className="latency-charts">
      <UsageGraphLayout>
        <HeaderWithControls className="latency-charts-header">
          <ExportButton className="align-right link" context={context} />
        </HeaderWithControls>
        <small>
          <code>Click</code> on any of the services bars or labels to access
          distribution and temporal evolution
        </small>
        <NVHorizontalBarChart
          className="has-rounded-corners"
          valueUnits=""
          margin={{ ..._defaultMargin, left: calcLabelsSpace(latencies) }}
          tooltipValueFormatter={(value) => `${formats.twoDecimals(value)} ms`}
          valueFormat={formats.twoDecimals}
          items={latencyKeyWithValues(items)}
          onClick={(service) => onSelect("distribution", service)}
          showBorder={true}
          showValues={true}
          showColors={true}
          showRoundCorners={true}
          hideLegends={true}
          barHeight={calcBarHeight(items.length)}
        />
        <div className="center legend">Latency (ms)</div>
      </UsageGraphLayout>
    </Scrolled>
  );
} 

const LatenciesMainAndDetail = ({ latencies, ...context }) => {
  const [selected, setSelected] = useState({ service: null, graph: null });
  const [slide, setSlide] = useState(0);
  const open = (graph, service) => setSelected({ graph, service });
  const showChronology = () =>
    setSelected((previous) => ({
      ...previous,
      graph: "chronology",
    }));
  const back = () =>
    setSelected(({ graph, ...previous }) => ({
      ...previous,
      graph: graph === "chronology" ? "distribution" : null,
    }));
  useEffect(() => {
    setSlide(selected.graph === null ? 0 : 1);
  }, [selected]);
  return (
    <CarrouselWrapper>
      <Carrousel slide={slide} minHeight="60vh">
        <div className="slide">
          <Scrolled>
            <MainLatencies latencies={latencies} onSelect={open} context={context}/>
          </Scrolled>
        </div>
        <div className="slide">
          <Scrolled>
            <ServiceDetail
              back={back}
              next={showChronology}
              {...selected}
              {...context}
            />
          </Scrolled>
        </div>
      </Carrousel>
    </CarrouselWrapper>
  );
};

const LatenciesLayout = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
    margin-top: 30px;
  }
  & .message {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 4cm;
  }
  .no-data {
    font-size: 18px;
  }
  .align-right {
    float: right;
  }
`;

const MainResult = ({ latencies, ...context }) => (
  <LatenciesLayout>
    {latencies.length === 0 ? (
      <div className="message">
        <span className="no-data">No latency records</span>
      </div>
    ) : (
      <LatenciesMainAndDetail latencies={latencies} {...context} />
    )}
  </LatenciesLayout>
);

export default MainResult;
