import Subscribers from "./component";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
import { styled } from "@mui/material/styles";

const MainLayer = styled("div")(({addr, subsId}) => ({
    opacity: (addr || subsId) ? 0:1,
    transition: 'opacity 0.12s ease-out !important'
  }));
  
  const DetailsLayer = styled("div")(() => ({
    position: 'absolute',
  }));
  
  export function SubscribersAndDetails({ addr, subsId, dpiEnabled = false, returnView, subnet, policy, subscriberGroup, timePeriod, metric, direction }){
    const showDetailView = addr || subsId;
    return <>
    <MainLayer style={{ opacity:  showDetailView ? 0:1, transition: 'opacity 0.12s ease-out 0.5s'}}>
      <Subscribers subnet={subnet} policy={policy} subscriberGroup={subscriberGroup} timePeriod={timePeriod} metric={metric} direction={direction} returnView={returnView}/>
    </MainLayer>
    {showDetailView && <DetailsLayer className="col-lg-12 col-md-12 col-sm-12 col-xs-12"><VerifiedSubcriberDetail addr={addr} subsId={subsId} dpiEnabled={dpiEnabled} returnView={returnView} timePeriod={timePeriod}/></DetailsLayer>}
    </>
  }