/* globals views*/
import React, { useContext } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/Hourly/component";
import ActionsContext from "common/ActionsContext";
import PoliciesThroughputHelpModal from "./Help";
import { parseThroughputPerPolicyIgnoringSubscribers } from "./api";
import { safeStr } from "common/api";
import { TitleWithNav } from "common/TitleWithNav";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`

  & .title {
    text-transform: uppercase;
  }
`;

const doFlowsShareRetrieval = (hours = 24, type = "flow") =>
  ifCl.run(`show statistics policy ${safeStr(type)} speed lines ${hours * 12}`);

const retrieval = ({ hours, policyType }) =>
  doFlowsShareRetrieval(hours, policyType)
    .then(parseThroughputPerPolicyIgnoringSubscribers)
    .catch((error) => console.error(error) || error);

const enabled = {
  hours: true,
  directions: true,
  policyType: true,
  categories: false,
  target: false,
  output: false,
  filter: false,
};

const policyTypeChoices = [
  { value: "flow", label: "Flow Policies" },
  { value: "rate", label: "Rate Policies" },
  { value: "monitor", label: "Monitor Policies" },
];

const directionsChoices = [
  { value: "downSpeed", label: "Downlink" },
  { value: "upSpeed", label: "Uplink" },
];

const settingsFrom = ({directions = 'downSpeed', policyType = 'flow'}) => ({
  directions: { options: directionsChoices, selected: directions },
  policyType: { options: policyTypeChoices, selected: policyType },
});

const PoliciesThroughputView = ({ returnView = null, ...params}) => {
  const actions = useContext(ActionsContext);
  const settings = settingsFrom(params);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <TitleWithNav returnView={returnView}>
            <h2 className="title">Policies throughput over time</h2>
          </TitleWithNav>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            from="timeline"
            itemsFrom="policies"
            itemsValueFrom={({ directions }) =>
              directions || settings.directions.defautValue
            }
            yAxisUnits="Mbps"
            provider={retrieval}
            actions={actions}
            settings={settings}
            enabled={enabled}
            noDataMessage="No throughput per policies data"
          />
        </div>
        <PoliciesThroughputHelpModal />
      </div>
    </div>
  );
}

export default PoliciesThroughputView;
