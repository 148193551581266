import React from "react";
import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

export const parseTrafficCongestion = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "BYTES-DN-NEAR-LIMIT-%": "near",
    "BYTES-DN-CONGESTED-%": "congested",
    "BYTES-DN-ACM-ACTIVE-%": "acm",
  };
  const skipped = [
    "REDUCTION-RTT-MS",
    "",
    "REDUCTION-RTX-%",
    "REDUCTION-SPEED-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseLatencyReduction = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "REDUCTION-RTT-MS": "reduction",
  };
  const skipped = [
    "BYTES-DN-NEAR-LIMIT-%",
    "BYTES-DN-CONGESTED-%",
    "BYTES-DN-ACM-ACTIVE-%",
    "REDUCTION-RTX-%",
    "REDUCTION-SPEED-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseRetransmissionsReduction = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    "REDUCTION-RTX-%": "reduction",
  };
  const skipped = [
    "BYTES-DN-NEAR-LIMIT-%",
    "BYTES-DN-CONGESTED-%",
    "BYTES-DN-ACM-ACTIVE-%",
    "REDUCTION-RTT-MS",
    "REDUCTION-SPEED-%",
  ];
  const keys = head
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};
