import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const LinkStateHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("open-help", function () {
        setOpen(true);
      }),
    []
  );
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="NETWORK INTERFACES"
      onClose={doClose}
      content={() => (
        <>
          <p>List of configured wires:</p>
          <ul>
            <li>
              <b>ACCESS INTERFACE</b>: Access side interface.
            </li>
            <li>
              <b>PCAP</b>: Access side interface network traffic capture using
              PCAP.
            </li>
            <li>
              <b>INTERNET INTERFACE</b>: Internet side interface.
            </li>
            <li>
              <b>PCAP</b>: Internet side interface network traffic capture using
              PCAP.
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on the corresponding wire.
            </li>
          </ul>
          <p>
            PCAP capture is slower, but may be required when the interface does
            not support DPDK.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete an already configured wire.
          </p>
          <p>
            Click on the{" "}
            <i className="material-icons valign-icon">swap_horiz</i> icon to
            swap access and internet interfaces of the corresponding wire.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add Wire...</b> option to add a new
            wire.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh wires information. Current pending changes, if any,
            will be lost.
          </p>
        </>
      )}
    />
  );
};

export default LinkStateHelpModal;
