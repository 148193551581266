import React, { useState } from "react";
import TextInput from "common/TextInput";

const AskTag = ({ tag, onChange, onApply, disabled = false }) => (
  <TextInput
    type="text"
    name="tag"
    label="Add this VLAN tag to bypass:"
    className="input-control align-right"
    defaultValue={tag}
    onChange={onChange}
    disabled={disabled}
    placeholder={"1-4094"}
    addon={
      <a
        href="#"
        onClick={onApply}
        className={`action shaded`}
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="Add tag"
      >
        <i className="material-icons actions-icon-color">add</i>
      </a>
    }
  />
);

const doesNothing = () => {};

const CreateVLAN = ({ doValidate = doesNothing, doSave = doesNothing }) => {
  const [vlan, setVLAN] = useState({ tag: "" });
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    try {
      const tag = parseInt(event.target.value);
      const vlan = { tag };
      doValidate(vlan);
      setError(null);
      setVLAN(vlan);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    try {
      doValidate(vlan);
      doSave(vlan);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <div>
        <AskTag
          tag={vlan.tag}
          onChange={handleChange}
          onApply={doApply}
          error={error}
        />
      </div>
      {error !== null && (
        <div className="modal-err-msg color-red align-left">{error}</div>
      )}
    </>
  );
};
export default CreateVLAN;
