import React, { useContext } from "react";
import styled from "styled-components";
import Report from "./Report";
import ActionsContext from "common/ActionsContext";
import ProtocolsThroughputHelpModal from "./Help";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const ProtocolsThroughput = ({directions = undefined }) => (
  <div id="viewThroughputProtocols" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div className="card">
      <HeaderDiv className="header">
        <h2 className="title">Throughput by protocols</h2>
        <ContextMenu />
      </HeaderDiv>
      <div className="body flex-panel-column">
        <Report />
      </div>
      <ProtocolsThroughputHelpModal directions={directions} />
    </div>
  </div>
);

export default ProtocolsThroughput;
