import { InvalidFieldValue } from "common/SettingsView";
import { safeStr } from 'common/api';

const _WARNING_HEAD = "%WARN-ENOENT: ";

const _DEFAULT_SETTINGS = {
  blocked: false,
  ipv4url: null,
  ipv6url: null,
};

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input.split(/\s+/).map(lowerFirstUpperOthers).join("");

const fieldTranslations = {
  "Auto-Congestion-Management": "acmEnabled",
  "Subscriber ID source": "subscriberIdSource",
};

const composeFieldAndValue = (previous, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);

  return {
    ...previous,
    [fieldTranslations[srcField] || asJSAttribute(srcField)]: srcValue,
  };
};

const quotaRelated = (input) => input.startsWith("Quota ");

export const parseFromTabs = (input) =>
  input
    .trim("\n")
    .split("\n")
    .filter(quotaRelated)
    .reduce(composeFieldAndValue, {});

const valueOrNA = (input) => (input === "n/a" ? null : input);

export const configToForm = ({
  quotaInitialStatus,
  quotaRedirectionIpv4Url,
  quotaRedirectionIpv6Url,
}) => ({
  initial: quotaInitialStatus,
  ipv4url: valueOrNA(quotaRedirectionIpv4Url),
  ipv6url: valueOrNA(quotaRedirectionIpv6Url),
});

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

const setInialBlocking = (previous, { initial = false }) =>
  initial === previous.initial
    ? ""
    : initial === "blocked"
    ? "quota blocking initial"
    : "no quota blocking initial";

const preventWhiteSpaces = (value, fieldName) => {
  if (value.includes(" ") === true) {
    throw new InvalidFieldValue(fieldName, `value "${value}" contains spaces`);
  }
  return value;
};

const safeURL = (value, fieldName) => safeStr(preventWhiteSpaces(value, fieldName));

const setIPV4URL = (previous, { ipv4url = "" }) =>
  ipv4url === previous.ipv4url
    ? ""
    : ipv4url === null || ipv4url.length === 0
    ? "no quota redirect ipv4"
    : `quota redirect ipv4 ${safeURL(ipv4url, "IPv4 Redirect URL")}`;

const setIPV6URL = (previous, { ipv6url = "" }) =>
  ipv6url === previous.ipv6url
    ? ""
    : ipv6url === null || ipv6url.length === 0
    ? "no quota redirect ipv6"
    : `quota redirect ipv6 ${safeURL(ipv6url, "IPv6 Redirect URL")}`;

const updatePreviousConfig = (previous, expected) => {
  const settings = [
    setInialBlocking(previous, expected),
    setIPV4URL(previous, expected),
    setIPV6URL(previous, expected),
  ].filter((content) => content.length > 0);
  return settings.length > 0 ? newBlock("pkteng", ...settings, "exit") : "";
};

export const composeApplyCommand = (previous, expected) =>
  newBlock(
    "configure",
    updatePreviousConfig(previous, expected),
    "commit"
  ).trimLeft("\n");
