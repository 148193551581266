import React, {useContext} from 'react';
import styled from 'styled-components';
import ActionsContext from "common/ActionsContext";

const Create = () => {
  const actions = useContext(ActionsContext);
  return (
    <button
      type="button"
      className="btn btn-default waves-effect shadow-none margin-b-30 hidden-to-operators"
      onClick={() => actions.send('create-quota')}
    >
      <i className="material-icons">add_circle</i>
      <span>Add quotas to subscriber...</span>
    </button>
  ) 
}
 
const ActionsLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Actions = () =>
  <ActionsLayout>
    <Create />
  </ActionsLayout>
  


export default Actions;
