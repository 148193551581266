import React, { useRef } from "react";
import NumberInput from "common/NumberInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const SystemSettings = ({ onChange, defaults = {} }) => {
  const form = useRef(null);
  const onInputChange = () => {
    const settings = getFormData(form.current);
    onChange(settings);
  };
  return (
    <form ref={form}>
      <ArrangeInColumns>
        <NumberInput
          name="port"
          label="Port:"
          defaultValue={defaults.port}
          onChange={onInputChange}
          className="input-control align-left"
          autocomplete="off"
          hideArrows={true}
        />
      </ArrangeInColumns>
    </form>
  );
};

export default SystemSettings;
