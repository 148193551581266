import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { retrieveRange, isInsideRange } from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import NVStackedAreaChart, {
  tooltipContentGenerator,
} from "common/graphs/NVStackedAreaChart";
import { PERIOD_TO_HOURS, periodScopeChoices, categoryChoices } from "common/constants";
import { parseShareInSpeed } from "../DPI/api";
import { getServiceFields, servicesSpeedAsFields } from "../SubscriberDetail/api";

const Header = styled.div`
  .context-menu.refresh {
    .material-icons {
      font-size: 20px;
      color: #999;
    }
  }
`;

const HeaderTitle = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const ChartContainer = styled.div`
  width: 100%;
`;
const DPIChartContainer = styled.div`
  width: 100%;
`;

const RangeContainer = styled.div``;

const ContextMenuWrapper = styled.div`
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const _directionChoices = [
  { value: "all", label: "Both." },
  { value: "uplink", label: "Uplink" },
  { value: "downlink", label: "Downlink" },
];

const ContextMenu = ({period, setPeriod, categories, setCategories, direction, setDirection}) => {
  return (
    <ContextMenuWrapper>
      <ColumnsGrid
        columns={3}
        className={"no-print"}
        minWidth="15rem"
        rowGap={"0"}
      >
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={period}
          onChange={({target})=> setPeriod(target.value)}
          options={periodScopeChoices}
        />
        <SelectInputWithIcon
          title="Categories"
          name="Categories"
          icon="format_line_spacing"
          selected={categories}
          onChange={({target})=> setCategories(target.value)}
          options={categoryChoices}
        />
        <SelectInputWithIcon
          title="Direction"
          name="Direction"
          icon="import_export"
          selected={direction}
          onChange={({target})=> setDirection(target.value)}
          options={_directionChoices}
        />
      </ColumnsGrid>
    </ContextMenuWrapper>
  );
};

const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const mbToGb = ({ total, dpi }) => ({
  total: (total / 1000).toFixed(2),
  dpi: (dpi / 1000).toFixed(2),
});

const sumTotalAndDPI = (items) =>
  items.reduce(
    (prev, { total, dpi }) => ({
      total: prev.total + total,
      dpi: prev.dpi + dpi,
    }),
    { total: 0, dpi: 0 }
  );

const TotalVolumeVsDPI = ({ total = "--", dpi = "--" }) => {
  return (
    <div className="align-left">
      <h4 className="chart-title">
        Total volume: {total} GB - Dpi sample: {dpi} GB
      </h4>
    </div>
  );
};

const periodInitMap = {
  oneDay: "Day",
  oneWeek: "Week",
  oneMonth: "Month",
  threeMonths: "Month",
}

const SubscriberGroupDPI = ({ subscriberGroup, period: periodInit }) => {
  const [request, setRequest] = useState(null);
  const [initialRange, setInitialRange] = useState();
  const [period, setPeriod] = useState(periodInitMap[periodInit]);
  const [categories, setCategories] = useState(10);
  const [direction, setDirection] = useState("all");
  const [range, setRange] = useState();
  const actions = useContext(ActionsContext);

  const doRequest = (period) => {
    const hours = PERIOD_TO_HOURS[period];
    return retrieveRange(hours).then(({ from, to }) => {
      setInitialRange({ from, to });
      return ifCl
        .run(
          `show statistics dpi speed group '${subscriberGroup}' hours ${hours} interval 60 categories ${categories -1} direction ${direction} interval-speed 10`
        )
        .then(parseShareInSpeed)
        .then((response) => {
          return { items: response };
        });
    });
  };

  const doLoad = () => {
    setRange(undefined);
    setRequest(doRequest(period));
  };

  useEffect(() => {
    doLoad();
    return actions.recv("do-load-dpi", () =>
      doLoad()
    );
  }, [period, direction, categories]);

  return (
    <div className="card">
      <Header className="header block-header">
        <HeaderTitle>
          <h4>DPI Service Breakdown Over Time</h4>
          <div className="context-menu refresh">
            <a
              onClick={() => actions.send("do-load-dpi")}
              data-toggle="cardloading"
              data-loading-effect="pulse"
              title="Refresh"
            >
              <i className="material-icons">refresh</i>
            </a>
          </div>
        </HeaderTitle>
        <ContextMenu
          period={period}
          setPeriod={setPeriod}
          direction={direction}
          setDirection={setDirection}
          categories={categories}
          setCategories={setCategories}
        />
      </Header>
      <div className="body flex-panel-row">
        <Request during={request}>
          {({ items }) => {
            const selectedRange = range || initialRange;
            return (
              <DPIChartContainer>
                <ChartContainer className="group chart-container">
                  <NVStackedAreaChart
                    className="dpi-usage-chart"
                    items={servicesSpeedAsFields(
                      items.filter(isInsideRange(selectedRange))
                    )}
                    xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                    xField="time"
                    fields={getServiceFields(items)}
                    tooltipContentGenerator={tooltipContentGenerator}
                    yAxisUnits="Mbps"
                    yAxisTopGap={0.05}
                  />
                  <TotalVolumeVsDPI {...mbToGb(sumTotalAndDPI(items))} />
                </ChartContainer>
                <RangeContainer>
                  {initialRange && (
                    <RangeSliderInput
                      {...initialRange}
                      onChange={(value) => setRange(value)}
                    />
                  )}
                </RangeContainer>
              </DPIChartContainer>
            );
          }}
        </Request>
      </div>
    </div>
  );
};

export default SubscriberGroupDPI;
