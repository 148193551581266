const numberOrNA = (input) => (input === "n/a" ? null : parseInt(input));

const stringOrNA = (input) => (input === "n/a" ? null : String(input));

const _LIST_FIELDS = [
  { name: "name", parse: (d) => String(d) },
  { name: "id", parse: stringOrNA },
  { name: "source", parse: (d) => String(d) },
  { name: "totalSubs", parse: numberOrNA },
  { name: "activeSubs", parse: numberOrNA },
  { name: "currentSubs", parse: numberOrNA },
  { name: "inactivity", parse: numberOrNA },
];

const fieldValue = (source, { name, parse }) => [name, parse(source)];

const asPolicyListItem = (row) =>
  Object.fromEntries(
    row
      .split(/\s+/)
      .map((source, index) => fieldValue(source, _LIST_FIELDS[index]))
  );

export const parsePolicyList = (response) => {
  const [_head, ...rows] = response.trim("\n").split("\n");
  return rows.map(asPolicyListItem);
};

const _DETAIL_FIELDS = [
  { from: "Blocking", name: "blocking", parse: (d) => String(d) },
  { from: "Auto-Congestion-Management", name: "acm", parse: (d) => String(d) },
  {
    from: "RatePerSubs downlink",
    name: "ratePerSubsDown",
    parse: (d) => String(d),
  },
  {
    from: "RatePerSubs uplink",
    name: "ratePerSubsUp",
    parse: (d) => String(d),
  },
  {
    from: "  Rate",
    name: (_value, prev) =>
      // Asumes that rate will be inmediatelly after RatePerSubs <Any>.
      prev.ratePerSubsUp === "yes"
        ? "ratePerSubsUp"
        : prev.ratePerSubsDown === "yes"
        ? "ratePerSubsDown"
        : "rate",
    parse: (d) => (isNaN(Number(d)) ? d : Number(d)),
  },
];

const stateField = ({ name, parse }, value, prev) => {
  return {
    [typeof name === "function" ? name(value, prev) : name]: parse(value, prev),
  };
};

const asTuple = (line) => line.split(/:\s*/);

const givenFields = (fields = _DETAIL_FIELDS) => {
  const fieldByFrom = Object.fromEntries(
    fields.map(({ from, ...field }) => [from, field])
  );
  const fillFields = (prev, [from, value]) =>
    from in fieldByFrom === false
      ? prev
      : { ...prev, ...stateField(fieldByFrom[from], value, prev) };
  const extract = (response) =>
    response.trim("\n").split("\n").map(asTuple).reduce(fillFields, {});
  return { extract };
};

export const parsePolicyDetails = (response) => givenFields().extract(response);

const warningRE = new RegExp("%WARN-EINVAL:\\s(.*)");

export const handleWarnings = (input) => {
  const match = input.match(warningRE);
  if (match !== null) {
    const [_all, message] = match;
    throw message;
  }
  return input;
};
