import React, { useMemo } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import colors from "common/graphs/colors";
import { useDoubleClick } from "common/hooks";
import { getMeanValue } from "common/api";
import { getShortName, getColorValue } from "./utils";

const LABEL_X_POS = -64;
const WIDTH_MEDIAN = 60;
const formatDate = d3.timeFormat("%m/%d %H:%M");

const paddingChart = 8;

function getYpos(fields, y, yScale, MARGIN) {
  let yPos;
  if (fields.length === 1) {
    yPos = y - 1.8 * yScale.bandwidth() + MARGIN.top;
  } else if (fields.length < 6) {
    yPos = y - 0.65 * yScale.bandwidth() + MARGIN.top;
  } else if (fields.length < 8) {
    yPos = y - 1.4 * yScale.bandwidth() + MARGIN.top;
  } else if (fields.length < 15) {
    yPos = y - 2 * yScale.bandwidth() + MARGIN.top;
  } else if (fields.length < 22) {
    yPos = y - 2.4 * yScale.bandwidth() + MARGIN.top;
  } else {
    yPos = y - 3.5 * yScale.bandwidth() + MARGIN.top;
  }
  return yPos;
}

const XAxisEntry = ({ x, heightChart, label, showTick }) => {
  return (
    <>
      {showTick ? (
        <line
          x1={x}
          x2={x}
          y1={heightChart - 2}
          y2={heightChart + 4}
          stroke="var(--chart-axis-text-color)"
        />
      ) : null}
      <text
        x={x}
        y={heightChart + 20}
        fontSize={9}
        textAnchor="middle"
        className="heatMapAxis"
      >
        {label}
      </text>
    </>
  );
};

const HeatMapCell = ({
  r,
  x,
  y,
  width,
  height,
  fill,
  onMouseEnter,
  onMouseLeave,
  handleSelect,
  group,
  opacity,
  onContextMenu,
}) => {
  const onClick = () => handleSelect(group, "single");
  const onDoubleClick = () => handleSelect(group, "double");
  const handleCellClick = useDoubleClick(onClick, onDoubleClick);
  return (
    <rect
      r={0}
      x={x}
      y={y}
      width={width}
      height={height}
      opacity={opacity}
      fill={fill}
      stroke={fill}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor="pointer"
      onClick={handleCellClick}
      onContextMenu={onContextMenu}
    />
  );
};

const Chart = ({
  width,
  height,
  data,
  setHoveredCell,
  colorScale,
  items,
  fields,
  margin,
  legendSet,
  setLegendFieldsSet,
  onSeriesInspect,
  sampledValues,
  heatmapFormat,
  sorting,
}) => {
  const MARGIN = {
    top: margin.top,
    right: margin.right,
    bottom: margin.bottom,
    left: margin.left,
  };

  const domain = colorScale.domain();
  const colorScaleMax = domain[domain.length - 1];
  const colorScaleMin = domain[0];
  const colorMax = colorScale(colorScaleMax);
  const colorMin = colorScale(colorScaleMin);

  const widthChart = width - MARGIN.left - MARGIN.right;
  const heightChart = height - MARGIN.top - MARGIN.bottom - paddingChart;
  const backgroundColorEmpty = login.isTheme("light") ? "#F8F8F8" : "#1c1c1e";

  function handleSelect(fieldName, selection) {
    if (selection === "single") {
      if (legendSet.size === 1) {
        const newFilterSet = new Set(fields.map(({ name }) => name));
        setLegendFieldsSet(newFilterSet);
      } else {
        const newFilterSet = new Set(legendSet);
        if (newFilterSet.has(fieldName)) {
          newFilterSet.delete(fieldName);
        } else {
          newFilterSet.add(fieldName);
        }
        setLegendFieldsSet(newFilterSet);
      }
    } else {
      const newFilterSet = new Set([fieldName]);
      setLegendFieldsSet(newFilterSet);
    }
  }

  const handleContextClick = (e, name) => {
    if (onSeriesInspect) {
      e.preventDefault();
      onSeriesInspect(name);
    }
  };

  const {fieldsMeanMap, fieldsWithoutValues, fieldsWithValues} = fields.reduce((acc, field) => {
    const {name} = field;
    if(sampledValues[name]!== undefined){
      acc.fieldsMeanMap[name] = getMeanValue(sampledValues[name]);
      acc["fieldsWithValues"].push(field);
    } else {
      acc["fieldsWithoutValues"].push(field);
    }
    return acc;
  }, {fieldsMeanMap: {}, fieldsWithoutValues: [], fieldsWithValues:[]});

  const {
    fieldsValues,
    fieldsColors,
    fieldsColorsMap: fieldsColorsMap,
  } = useMemo(() => {
    let sortedFields;
    let sortWNAFields;
    if (sorting !== "default") {
      sortedFields = [...fieldsWithValues].sort((a, b) => {
        if (sorting === "asc") {
          return fieldsMeanMap[a.name] - fieldsMeanMap[b.name];
        } else {
          return fieldsMeanMap[b.name] - fieldsMeanMap[a.name];
        }
      });
      sortWNAFields = [...sortedFields, ...fieldsWithoutValues];
    } else {
      sortWNAFields = [...fields];
    }
    return sortWNAFields.reduce(
      (acc, value) => {
        acc.fieldsValues.push(value.name);
        acc.fieldsColors.push(value.color);
        acc.fieldsColorsMap[value.name] = value.color;
        return acc;
      },
      { fieldsValues: [], fieldsColors: [], fieldsColorsMap: {} }
    );
  }, [fields, sorting, fieldsMeanMap]);

  const allYGroups = fieldsValues;
  const allXGroups = useMemo(
    () => [
      ...new Set(
        items.map((d) => {
          return d.time;
        })
      ),
    ],
    [items]
  );

  // x and y scales
  const xScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([0, widthChart])
      .domain(allXGroups)
      .padding(0.01);
  }, [items, width]);

  const xScaleA = useMemo(() => {
    return d3
      .scaleTime()
      .range([0, widthChart])
      .domain([items[0].time, items[items.length - 1].time]);
  }, [items, width]);

  const allTicks = xScaleA.ticks(5).map((tick, i) => {
    if (xScaleA(tick) > 30 && xScaleA(tick) < widthChart - 50) {
      return (
        <XAxisEntry
          x={xScaleA(tick)}
          heightChart={heightChart}
          label={formatDate(tick)}
          showTick={true}
          key={formatDate(tick)}
        />
      );
    }

    return null;
  });

  const yScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([heightChart - paddingChart / 2, paddingChart / 2])
      .domain(allYGroups.reverse())
      .padding(0.01);
  }, [fieldsValues, height]);

  const prepData = items.flatMap((item) => {
    const { time } = item;

    return Object.keys(item).reduce((acc, value) => {
      if (fieldsValues.includes(value)) {
        const itemValue = item[value] ? item[value] : 0;
        const newItem = { time, group: value, value: item[value] };
        acc.push(newItem);
      }
      return acc;
    }, []);
  });

  // Build the rectangles
  const allShapes = prepData.map((d, i) => {
    const x = xScale(d.time);
    const y = yScale(d.group);
    if (d.value === null || !x || !y) {
      return;
    }
    
    const yPos = getYpos(fields, y, yScale, MARGIN);
    const colorValue =getColorValue({value: d.value, min: colorScaleMin, max:colorScaleMax, colorScale, colorEmpty: backgroundColorEmpty, colorMin, colorMax})

    if (legendSet.has(d.group)) {
      return (
        <HeatMapCell
          key={i}
          r={4}
          x={x}
          y={y}
          width={xScale.bandwidth()}
          height={yScale.bandwidth() - 1}
          fill={colorValue}
          onMouseEnter={(e) => {
            setHoveredCell({
              type: "cell",
              xLabel: formatDate(d.time),
              yLabel: d.group.replace(/\[sg\]/, ''),
              yColor: fieldsColorsMap[d.group],
              xPos: x + xScale.bandwidth() + MARGIN.left,
              xPosHighLight: xScaleA(d.time) + MARGIN.left,
              yPos,
              value: heatmapFormat(d.value),
              mean:
                fieldsMeanMap[d.group] === undefined
                  ? "n/a"
                  : heatmapFormat(fieldsMeanMap[d.group]),
            });
          }}
          onMouseLeave={() => setHoveredCell(null)}
          handleSelect={handleSelect}
          group={d.group}
          onContextMenu={(e) => handleContextClick(e, d.group)}
          opacity={1}
        />
      );
    }

    return (
      <HeatMapCell
        key={i}
        r={4}
        x={x}
        y={y}
        width={xScale.bandwidth()}
        height={yScale.bandwidth()}
        fill={d.value !== "n/a" ? colorScale(d.value) : backgroundColorEmpty}
        stroke={"none"}
        handleSelect={handleSelect}
        group={d.group}
        opacity={0}
      />
    );
  });

  const yLabels = allYGroups.map((name, i) => {
    const nameDisplayed = name.replace(/\[sg\]/, '');
    const y = yScale(name);

    if (!y) {
      return null;
    }

    const shortName = nameDisplayed.length <= 29 ? nameDisplayed : getShortName(nameDisplayed);

    return (
      <g
        key={y}
        onClick={(e) => handleContextClick(e, name)}
        onContextMenu={(e) => handleContextClick(e, name)}
        onMouseEnter={(e) => {
          setHoveredCell({
            type: "yLabel",
            yLabel: nameDisplayed + ' (Click for details)',
            yColor: fieldsColorsMap[name],
            xPos: MARGIN.left,
            yPos: y + yScale.bandwidth() / 2 + MARGIN.top,
          });
        }}
        onMouseLeave={() => setHoveredCell(null)}
      >
        <text
          key={i}
          x={LABEL_X_POS - 8}
          y={y + yScale.bandwidth() / 2}
          textAnchor="end"
          dominantBaseline="middle"
          className="heatMapAxis axisSelect"
        >
          {shortName}
        </text>
        <circle
          cx={LABEL_X_POS}
          cy={y + yScale.bandwidth() / 2 - 1}
          r="4"
          stroke={fieldsColorsMap[name]}
          fill={
            legendSet.has(name) ? fieldsColorsMap[name] : backgroundColorEmpty
          }
          onClick={() => handleSelect(name, "single")}
          onDoubleClick={() => handleSelect(name, "double")}
          onContextMenu={(e) => handleContextClick(e, name)}
          className="heatMapAxisCircle axisSelect"
        />
        <rect
          x={0}
          y={y}
          width={widthChart}
          height={yScale.bandwidth()}
          fill={"transparent"}
          onClick={() => handleSelect(name, "single")}
          onDoubleClick={() => handleSelect(name, "double")}
        />
      </g>
    );
  });

  const meanLabels = allYGroups.map((name, i) => {
    if (!sampledValues) {
      return null;
    }
    const meanLabel =
      fieldsMeanMap[name] === undefined
        ? "n/a"
        : heatmapFormat(fieldsMeanMap[name]);
    const y = yScale(name);

    return (
      <text
        key={i}
        x={LABEL_X_POS + WIDTH_MEDIAN}
        y={y + yScale.bandwidth() / 2}
        textAnchor="end"
        dominantBaseline="middle"
        className="heatMapAxis axisSelect"
      >
        {meanLabel}
      </text>
    );
  });

  return (
    <svg>
      <g
        width={widthChart}
        height={heightChart}
        transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
      >
        <g className="heatmapYAxis">{yLabels}</g>
        <g className="heatmapCells">{allShapes}</g>
        <g className="heatmapXAxis">
          {allTicks}
          <XAxisEntry
            x={0}
            heightChart={heightChart}
            label={formatDate(items[0].time)}
            showTick={false}
            key={"x-Axis-first"}
          />
          <XAxisEntry
            x={widthChart}
            heightChart={heightChart}
            label={formatDate(items[items.length - 1].time)}
            showTick={false}
            key={"x-Axis-last"}
          />
        </g>
        <g className="meanValues">{meanLabels}</g>
      </g>

      <g className="heatmapBackground">
        <rect
          width={widthChart}
          height={heightChart}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
          rx="9"
        ></rect>
      </g>
    </svg>
  );
};

export default Chart;
