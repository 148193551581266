import React, { useEffect, useState } from "react";
import Request from "common/Request";
import ListDevices from "./List";
import { parseBypassList } from "../api";

const doRequest = () =>
  ifCl
    .run("show high-availability hid")
    .then(parseBypassList)
    .then((devices) => ({ devices }));

const Devices = () => {
  const [request, setRequest] = useState(null);

  useEffect(() => {
    setRequest(doRequest());
  }, []);

  return request === null ? null : (
    <Request during={request}>
      {({ devices = [] }) => <ListDevices items={devices} />}
    </Request>
  );
};

export default Devices;
