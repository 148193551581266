import React, { useState, useEffect } from "react";
import Modal from "common/Modal";
import Request from "common/Request";

const NotPermittedRE = /Operation not permitted/;

const mayFailByPermissions = (result) => {
  if (result.match(NotPermittedRE) !== null) {
    throw result;
  }
  return result;
};

const waitForSeconds = (seconds) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), seconds * 1000);
  });

const willDownloadFrom = (filepath) => (_response) => {
  return ifCl.download(filepath);
};

const willClearFileAt = (filepath) => (_response) =>
  ifCl.run(`file rm ${filepath} force`);

const doesNothing = () => {};

const Capturing = () => <span>Capturing...</span>;
const Done = ({ filename }) => (
  <h4>Results have been downloaded in file {filename}</h4>
);

const WaitForResults = ({ request, filename }) => (
  <Request during={request} Loading={Capturing}>
    {(_result) => <Done filename={filename} />}
  </Request>
);

const CaptureProcessModal = ({
  request = null,
  target = {},
  filename,
  filepath,
  stop = doesNothing,
  doClose = doesNothing,
}) => {
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  const [cancel, setCancel] = useState(false);
  const doStop = () => {
    if (done === false && error === null) {
      stop();
    }
    setDone(true);
  };
  const stopAndClose = () => {
    setCancel(true);
    doStop();
    doClose();
  };

  const userMayCancel = (response) => {
    if (cancel === true) {
      throw "user cancelled";
    }
    return response;
  };
  const failed = (reason) => {
    setError(reason);
    return null;
  };

  useEffect(() => setDone(false), [request]);
  const disposeOutcomeFile = () =>
    waitForSeconds(2)
      .then(userMayCancel)
      .then(() => setDone(true))
      .then(willDownloadFrom(filepath))
      .finally(willClearFileAt(filepath));

  const process = (request) =>
    request.then(mayFailByPermissions).then(disposeOutcomeFile).catch(failed);

  return request === null ? null : (
    <Modal
      superIcon={done ? "info" : "search"}
      title={`${done ? "Captured" : "Capturing"} packets in ${target.name}`}
      id="interface-status-capture-process"
      large={done ? true : false}
      content={() =>
        done ? (
          <Done filename={filename} />
        ) : (
          <WaitForResults request={process(request)} filename={filename} />
        )
      }
      applyLabel={done || error ? "STOPPED" : "STOP"}
      onApply={doStop}
      applyDisabled={done === true || error !== null}
      closeLabel="CLOSE"
      onClose={stopAndClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) || (
          <h5 className="modal-err-msg align-left bold">
            {done === true ? "Done." : "Capturing..."}
          </h5>
        )
      }
    />
  );
};

export default CaptureProcessModal;
