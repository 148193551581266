import React, { useRef } from "react";
import NumberInput from "common/NumberInputWithSpinner";
import { getFormData, rankAndParseFields } from "./utils";

const DownlinkVolumeSettings = ({
  onChange,
  defaults = {},
  isOperatorProfile = false,
}) => {
  const form = useRef(null);
  const onInputChange = () => {
    const values = getFormData(form.current);
    onChange(rankAndParseFields(values, defaults));
  };
  return (
    <form ref={form}>
      <h2 className="card-inside-title">Downlink Volume Attacks</h2>
      <p>
        The threshold to consider a volume attack is when a subscriber
        receives more than the specified minimum rate and, when subject to a
        rate limitation, the speed is above that limit times the multiplier. A{" "}
        <span className="col-red">zero</span> value (click the reset default
        icon) in both parameters will disable the functionality.
      </p>
      <NumberInput
        name="volumeMinRate"
        label="Minimum rate"
        defaultValue={Number(defaults.volumeMinRate)/1000}
        onChange={onInputChange}
        className="input-control align-left"
        autocomplete="off"
        units="Mbps"
        min={0}
        step={1}
        hideArrows={false}
        disabled={isOperatorProfile}
        backspaceValue={0}
      />
      <NumberInput
        name="volumeRateMult"
        label="Multiplier of subscriber rate policy"
        defaultValue={defaults.volumeRateMult}
        onChange={onInputChange}
        className="input-control align-left"
        autocomplete="off"
        units="Times"
        min={0}
        step={0.01}
        hideArrows={false}
        disabled={isOperatorProfile}
        backspaceValue={0}
        precision={2}
        onBlur={onInputChange}
      />
    </form>
  );
};

export default DownlinkVolumeSettings;
