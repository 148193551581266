import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
/*****************************************************
 * Help Modal
 * ****************************************************/
const FlowsPerSubscriberHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="FLOWS PER SUBSCRIBER"
      content={() => (
        <>
          <p>Global data and filters.</p>
          <ul>
            <li>
              <b>Active flows</b>: Number of current active flows.
            </li>
            <li>
              <b>Flows shown</b>: Max. number of flows on display.
            </li>
            <li>
              <b>Address filter</b>: Filter for flows address on display.
            </li>
          </ul>
          <p>Flows per subscriber status.</p>
          <ul>
            <li>
              <b>TYPE</b>: Flow type.
            </li>
            <li>
              <b>ACC-ADDR</b>: Access side IP address.
            </li>
            <li>
              <b>APORT</b>: Access side port.
            </li>
            <li>
              <b>INET-ADDR</b>: Internet side IP address.
            </li>
            <li>
              <b>IPORT</b>: Internet side port.
            </li>
            <li>
              <b>PROTO</b>: Flow protocol.
            </li>
            <li>
              <b>ST</b>: Flow status.
              <ul>
                <li>SY: SYN (TCP handshake state).</li>
                <li>S2: SYN2 (TCP handshake state).</li>
                <li>SS: SYN-SYNACK (TCP handshake state).</li>
                <li>SA: SYNACK (TCP handshake state).</li>
                <li>ES: ESTABLISHED. Open optimezed TCP flow.</li>
                <li>CL: CLOSING. TCP flow the process of finishing.</li>
                <li>
                  PH: PROCESS-HEALING. Non-optimized and non-metered TCP flow.
                </li>
                <li>EH: ERROR-HEALING. TCP flow being reset.</li>
                <li>HD: HEALED. Non-optimized and non-metered TCP flow.</li>
                <li>TR: TRACKING. Non-optimized but metered TCP flow.</li>
                <li>BL: BLOCKED. Flow blocked by a policy.</li>
                <li>AT: Bi-directional UDP or IP flow.</li>
              </ul>
            </li>
            <li>
              <b>INS</b>: Process in which this flow is being processed.
            </li>
            <li>
              <b>FLOW-POLICY</b>: Flow policy.
            </li>
            <li>
              <b>MBYTES-UP</b>: MBytes received in uplink direction.
            </li>
            <li>
              <b>MBYTES-DN</b>: MBytes received in downlink direction.
            </li>
            <li>
              <b>LIFETIME</b>: Time since flow creation.
            </li>
            <li>
              <b>DPI-DOMAIN</b>: Domain extracted by DPI (if any).
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh data.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default FlowsPerSubscriberHelpModal;
