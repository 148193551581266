import React, { useState } from "react";
import styled from "styled-components";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import AddressInput from "./AddressInput";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const _defaultSettings = {
  direction: "downlink",
  timePeriod: "oneDay",
  metric: "averageSpeed",
  ipAddress: "",
  use: 'ipAddress'
};

const doesNothing = () => {};

const _directionOptions = [
  { label: "Downlink", value: "downlink" },
  { label: "Uplink", value: "uplink" },
];

const _timePeriodOptions = [
  { label: "1 Day", value: "oneDay" },
  { label: "1 Week", value: "oneWeek" },
  { label: "1 Month", value: "oneMonth" },
  { label: "3 Months", value: "threeMonths" },
];

const _metricOptions = [
  { label: "Average Speed", value: "averageSpeed" },
  { label: "Active Flows", value: "activeFlows" },
  { label: "Flows Created Per Minute", value: "flowsCreated" },
  { label: "Latency", value: "latency" },
  { label: "Retransmissions", value: "retransmissions" },
  { label: "Congestion", value: "congestion" },
  { label: "% Traffic at Max Speed", value: "traffic" },
];

const _useOptions = [
  { label: "Use filtered IP for metrics", value: "ipAddress" },
  { label: "Use subs-id for filtered IP", value: "subsId" }
];

const ControlsAndResultsLayout = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FilteringControls = ({
  defaults = {},
  ipAddress = undefined,
  className = "",
  children,
}) => {
  defaults = {
    ..._defaultSettings,
    ...defaults,
    ...(ipAddress === undefined ? {} : { ipAddress }),
  };
  const [settings, setSettings] = useState(defaults);
  const onChange = (event) => {
    const { target } = event;
    const value =
      target.type !== "checkbox"
        ? target.value
        : target.value === "on" && target.checked === true;
    setSettings((settings) => ({
      ...settings,
      [target.name]: value,
    }));
  };
  return (
    <ControlsAndResultsLayout className={className}>
    <div className={`${className}-grid`}>
      <ColumnsGrid columns={5} minWidth="15rem" rowGap="0">
        <SelectInputWithIcon
          title="Direction"
          name="direction"
          icon="import_export"
          selected={settings.direction}
          onChange={onChange}
          options={_directionOptions}
        />
        <AddressInput
          value={settings.ipAddress}
          name="ipAddress"
          onChange={onChange}
          allowIpRanges={false}
        />
        <SelectInputWithIcon
          title="Time Period"
          name="timePeriod"
          icon="date_range"
          selected={settings.timePeriod}
          onChange={onChange}
          options={_timePeriodOptions}
        />
        <SelectInputWithIcon
          title="metric"
          name="metric"
          icon="insert_chart"
          selected={settings.metric}
          onChange={onChange}
          options={_metricOptions}
        />
        {settings.ipAddress ? 
          <SelectInputWithIcon
            title="use"
            name="use"
            icon="label_outline"
            selected={settings.use}
            onChange={onChange}
            options={_useOptions}
          />: 
          <div></div>
        }
      </ColumnsGrid>
      </div>
      {children(settings)}
    </ControlsAndResultsLayout>
  );
};
export default FilteringControls;
