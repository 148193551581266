import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TargetInput from "common/TargetInput";
import { validateIPv4IPv6Range } from "common/api";
import FoldablePanel from "common/FoldablePanel";
import useProfile from "common/hooks/useProfile";

const doesNothing = () => {};

const TargetInputWrapper = styled.div`
  display: flex;
  justify-contents: center;
  flex-flow: nowrap;
  & > input,
  & > select,
  & > .input-group-addon {
    width: auto !important;
    display: flex;
  }
  .floating.error {
    position: absolute;
    top: 2em;
    color: red;
    z-index: 3;
  }
`;

const DestinationInput = ({ defaultValue = null, onChange = doesNothing }) => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState(defaultValue || "");
  const updateValidAddress = (address) => {
    if (address.length === 0) {
      setValue(null);
      setError(false);
    } else if (validateIPv4IPv6Range(address) === true) {
      setValue(address);
      setError(false);
    } else {
      setValue(null);
      setError("Value is not a valid IP address");
    }
  };
  const updateAddressValue = (event) => {
    const address = event.target.value;
    updateValidAddress(address);
  };
  useEffect(() => {
    onChange(value === "" ? null : value);
  }, [value]);
  return (
    <TargetInputWrapper className="input-group">
      <input
        type="text"
        name="addressFilterValue"
        className="form-control"
        placeholder="Ex: 10.1.0.0/16 or 2001:db8:80::/48"
        defaultValue={defaultValue}
        onKeyUp={updateAddressValue}
      />
      {error !== false && <div className="floating error">{error}</div>}
    </TargetInputWrapper>
  );
};

const SubmitButtonLayout = styled.div`
  text-align: right;
`;
const SubmitButton = ({ onClick = doesNothing, disabled, className }) => (
  <SubmitButtonLayout className={className}>
    <button
      className="btn btn-link waves-effect"
      disabled={disabled}
      onClick={onClick}
    >
      Filter
    </button>
  </SubmitButtonLayout>
);

const anythingSet = ({ target, destination }) =>
  target.addr !== null || target.subsId !== null || destination !== null;

const _defaultValue = {
  target: { addr: null, subsId: null },
  destination: null,
};

const TargetAndDestinationLayout = styled.div`
  .input-group button {
    display: none;
  }
`;

const InputsWrapper = styled.div`
 display: flex;

 > div {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
 }

 span.field-label {
  padding-right: 10px;
 }

 .input-group {
  margin-bottom: 0px;
 }

 > div:first-child {
  margin-right: 20px;
 }

 > div:first-child .input-group {
  flex-flow:nowrap;
 }


 @media screen and (max-width: 900px) {
  flex-direction: column;

  > div {
    margin-bottom: 20px;
  }
 }
}
`;

const TargetAndDestinationFilter = ({
  defaultValues = _defaultValue,
  onChange = doesNothing,
  className,
}) => {
  const profile = useProfile() || {};
  const [values, setValues] = useState(defaultValues);
  const handleChangeOn = (name) => (value) =>
    setValues((previous) => ({
      ...previous,
      ...(name !== undefined ? { [name]: value } : value),
    }));
  const submit = () => onChange(values);
  return (
    <FoldablePanel
      opened={anythingSet(defaultValues)}
      title="Filters"
      className={`${className} access-filters`}
    >
      <TargetAndDestinationLayout className="target-filter">
        <InputsWrapper>
          {profile.dpiEnabled === false ? null : 
            <div>
              <span className="field-label">Access</span>
              <TargetInput
                defaultValue={defaultValues.target}
                onChange={handleChangeOn()}
                submitButton={false}
              />
            </div>
          }
          <div>
            <span className="field-label">Internet IP:</span>
            <DestinationInput
              defaultValue={defaultValues.destination}
              onChange={handleChangeOn("destination")}
            />
          </div>
          </InputsWrapper>
          <SubmitButton className="wide align-right" onClick={submit} />
      </TargetAndDestinationLayout>
    </FoldablePanel>
  );
};

export default TargetAndDestinationFilter;
