import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import TargetInput from "common/TargetInput";
import { expressTarget, geDateWithOffset } from "common/api";
import { TIMEWINDOW_MAX_MAP } from "common/constants";
import { useTimeOffset } from "common/hooks/useTimeOffset";
import Switch from "@mui/material/Switch";
import Chart from "./Chart";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ContextMenuWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .targetInput .input-group {
    margin-bottom: 0px;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;

    .targetInput {
      width: 100%;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const SelectsWrapper = styled.div`
  display: flex;
`;

const LiveModeWrapper = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .live-mode-switch.MuiSwitch-root {
    & .Mui-checked {
      color: var(--switch-checked-green);
    }

    & .Mui-checked + .MuiSwitch-track {
      background-color: var(--switch-checked-light-green);
    }
  }
`;

const LabelSwitch = styled.div`
  font-family: AllianceNo2-SemiBold !important;
  font-weight: 100;
`;

const ChartContainer = styled.div`
  display: flex;
  min-height: 500px;
  margin-top: 20px;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const _outputScopeChoices = [
  { value: "ip-addresses", label: "IP-Addr" },
  { value: "subscriber-ids", label: "Subs-Ids" },
];

const _timeWindowChoices = [
  { value: "2", label: "2 min. time span" },
  { value: "5", label: "5 min. time span" },
  { value: "10", label: "10 min. time span" },
];

const ContextMenu = ({
  timeWindow,
  setTimeWindow,
  liveMode,
  setLiveMode,
  output,
  setOutput,
}) => {
  return (
    <ContextMenuWrapper>
      <SelectsWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Outcome grouping"
            name="output"
            selected={output}
            onChange={({ target }) => setOutput(target.value)}
            options={_outputScopeChoices}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Time Span"
            name="Time Span"
            icon="crop_16_9"
            selected={timeWindow}
            onChange={({ target }) => setTimeWindow(parseInt(target.value))}
            options={_timeWindowChoices}
          />
        </SelectWrapper>
      </SelectsWrapper>
      <LiveModeWrapper>
        <LabelSwitch>Live mode</LabelSwitch>
        <Switch
          className="live-mode-switch"
          checked={liveMode}
          onChange={(e) => setLiveMode(e.target.checked)}
        />
      </LiveModeWrapper>
    </ContextMenuWrapper>
  );
};

function getFieldsAndMap(
  newFieldNamesSet,
  oldFieldsMap,
  colorsSet,
  refColorIndex,
  parsedResponse,
  time,
  timeWindow
) {
  const { map, fields } = [...newFieldNamesSet].reduce(
    (acc, subscriber) => {
      const entry = oldFieldsMap[subscriber];
      if (!entry) {
        let newColor;
        if (subscriber === "rest") {
          newColor = colorsSet[colors.length - 1];
        } else {
          newColor = colorsSet[refColorIndex.current];
        }
        refColorIndex.current =
          (refColorIndex.current + 1) % (colorsSet.length - 1);
        const { downlink, uplink } = parsedResponse[subscriber];
        const total = Number(downlink) + Number(uplink);
        const newField = {
          name: subscriber,
          label: subscriber,
          color: newColor,
          value: total,
        };
        acc.fields.push(newField);
        acc.map[subscriber] = {
          color: newColor,
          time,
          total,
          totalUp: Number(uplink),
          totalDown: Number(downlink),
        };
      } else {
        const timeFieldNotFound = time.getTime() - entry.time.getTime();
        if (parsedResponse[subscriber]) {
          const { downlink, uplink } = parsedResponse[subscriber];
          const total = Number(downlink) + Number(uplink);
          acc.map[subscriber] = {
            ...entry,
            time,
            total: oldFieldsMap[subscriber].total + total,
            totalUp: oldFieldsMap[subscriber].totalUp + Number(uplink),
            totalDown: oldFieldsMap[subscriber].totalDown + Number(downlink),
          };
          const newField = {
            name: subscriber,
            label: subscriber,
            color: entry.color,
            value: total,
          };
          acc.fields.push(newField);
        } else if (timeFieldNotFound <= TIMEWINDOW_MAX_MAP[timeWindow]) {
          acc.map[subscriber] = entry;
          const newField = {
            name: subscriber,
            label: subscriber,
            color: entry.color,
            value: 0,
          };
          acc.fields.push(newField);
        }
      }
      return acc;
    },
    { map: {}, fields: []}
  );

  return { map, fields: fields.sort() };
}

const getValue = (item, type) => {
  if (item === null) {
    return null;
  }
  const { time } = item;
  return Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (key !== "time") {
        acc[key] = getChartValue(value[type]);
      }

      return acc;
    },
    { time }
  );
};

function getChartValue(value) {
  return value === "n/a" ? null : Number(value);
}

function getLiveThrougputData({ output }) {
  if (output === "subscriber-ids") {
    return ifCl.run(
      `show subscriber all rates subscriber-id lines 19`
    );
  }
  return ifCl.run(`show subscriber all rates lines 19`);
}

function parseThroughputResponse(response) {
  if (!response) {
    return {};
  }
  const [header, ...rows] = response.trim(/\s/).split("\n");
  return rows.reduce((acc, value) => {
    const [field, downlink, uplink] = value?.split(/[ ,]+/);
    const key = field === "Rest" ? "rest" : field;
    acc[key] = { downlink, uplink };
    return acc;
  }, {});
}

const useTime = (counting) => {
  const [seconds, setSeconds] = useState(0);

  let interval;
  useEffect(() => {
    if (!counting){
      clearInterval(interval)
    } else {
      setSeconds(0);
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [counting]);

  return [seconds, setSeconds];
};

const SubscriberThroughputLiveReport = () => {
  const [output, setOutput] = useState("ip-addresses");
  const [timeWindow, setTimeWindow] = useState(2);
  const [isFetching, setIsFetching] = useState(false);
  const { timeOffset } = useTimeOffset();
  const [subs, setSubs] = useState({ map: {}, fields: [] });
  const colorsSet = login.isTheme("light") ? colors : colorsDark;
  const [mouseEvent, setMouseEvent] = useState();
  const [liveMode, setLiveMode] = useState(true);
  const [item, setItem] = useState(null);
  const [initTime, setInitTime] = useState();
  const [seconds, setSeconds] = useTime(liveMode);
  const [times, setTimes] = useTime(0);

  const refColorIndex = useRef(0);

  const doLoad = () => {
    if (!isFetching || !liveMode) {
      return;
    }
    getLiveThrougputData({ output })
      .then((response) => {
        const time = new Date();
        time.setMilliseconds(500);
        time.setHours(time.getHours() + timeOffset);
        const parsedResponse = parseThroughputResponse(response);
        setItem({ ...parsedResponse, time });

        const oldFieldsMap = { ...subs.map };
        const newFieldKeys = Object.keys(parsedResponse);
        const newFieldNamesSet = new Set([
          ...newFieldKeys,
          ...Object.keys(oldFieldsMap),
        ]);
        setSubs(
          getFieldsAndMap(
            newFieldNamesSet,
            oldFieldsMap,
            colorsSet,
            refColorIndex,
            parsedResponse,
            time,
            timeWindow
          )
        );
      })
      .catch((error) => showModalError(error));
  };

  useEffect(() => {
    setIsFetching(false);
    setSubs({ map: {}, fields: [] });
    setItem(null);
    setInitTime(Date.now());
    if (timeOffset !== undefined) {
      setIsFetching(true);
      setInitTime(Date.now());
    }
  }, [timeOffset, output]);

  useEffect(() => {
    if (initTime && liveMode && Date.now() - initTime > 5 * 60 * 1000) {
      setLiveMode(false);
    }
    doLoad();
  }, [seconds]);

  useEffect(() => {
    if (liveMode) {
      setItem(null);
      setSubs({ map: {}, fields: [] });
      setInitTime(Date.now());
    }
  }, [liveMode]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      let timeoutId;
      if (document.visibilityState === "hidden") {
        timeoutId = setTimeout(() => {
          if (document.visibilityState === "hidden") {
            setLiveMode(liveMode);
          }
        }, 60000);
      } else {
        clearTimeout(timeoutId);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setLiveMode, liveMode]);

  const { map, fields } = subs || {};

  const handleMouseEvent = (event) => {
    if (event) {
      setMouseEvent({ ...event });
    } else {
      setMouseEvent(null);
    }
  };

  return (
    <Wrapper>
      <ContextMenu
        timeWindow={timeWindow}
        setTimeWindow={setTimeWindow}
        liveMode={liveMode}
        setLiveMode={setLiveMode}
        output={output}
        setOutput={setOutput}
      />
      <div>
        <Chart
          title={"Downlink"}
          fields={fields}
          fieldsMap={map}
          value={getValue(item, "downlink")}
          timeOffset={timeOffset}
          timeWindow={timeWindow}
          onMouseEvent={handleMouseEvent}
          mouseEvent={mouseEvent}
          liveMode={liveMode}
          output={output}
          times={seconds}
        />
        <Chart
          title={"Uplink"}
          fields={fields}
          fieldsMap={map}
          value={getValue(item, "uplink")}
          timeOffset={timeOffset}
          timeWindow={timeWindow}
          onMouseEvent={handleMouseEvent}
          mouseEvent={mouseEvent}
          liveMode={liveMode}
          output={output}
          times={seconds}
        />
      </div>
    </Wrapper>
  );
};

export default SubscriberThroughputLiveReport;
