import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import SubscriberGroupDashboard from "./SubscriberGroupDashboard";
import TargetContext from "./TargetContext";
import SubscriberGroupDPI from "./SubscriberGroupDPI";
import SubscriberDetailHelpModal from "./Help";
import { TitleWithNav } from "common/TitleWithNav";

const ViewContainer = styled.div``;

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <div className="context-menu">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help-dashboard")}
      >
        <i className="material-icons">
          <small>help_outline</small>
        </i>
      </a>
      <a
        onClick={() => {
          actions.send("do-load")
          actions.send("do-load-dpi")}
        }
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">
          <small>refresh</small>
        </i>
      </a>
    </div>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  & .title {
    flex: 100% 1 1;
    padding-right: 1ch;
    text-transform: uppercase;
    color: #666;
  }
  & .context-menu {
    flex: 30px 0 0;
    display: flex;
    flex-direction: row;
    a {
      color: #949494;
      font-size: 0.8em;
    }
  }
`;

const Footer = styled.div`
  background-color: var(--Background-Block);
  margin-top: -30px;
  padding-bottom: 30px;
`

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const Panel = ({ children, ...props }) => (
  <div className="card">
    <div className="body">{children({ ...props })}</div>
  </div>
);

const container = ({
  subscriberGroup,
  returnView,
  direction,
  timePeriod,
  metric,
  ipAddress,
}) => {
  const settingsSubscriberGroups = {
    direction,
    timePeriod,
    metric,
    ipAddress,
  };

  useEffect(() => {
    $("html")[0].scrollTop = 0;
  }, []);

  return (
    <>
      <HeaderDiv className="block-header">
        <TitleWithNav returnView={returnView}>
          <h2 className="title">
            Subscriber Group Dashboard:&nbsp;
            <span className="case-sensitive"> {subscriberGroup} </span>
          </h2>
        </TitleWithNav>
        <p className="padding-t-20"></p>
        <ContextMenu />
      </HeaderDiv>
      <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
        <TargetContext subscriberGroup={subscriberGroup}>
          <div />
          <Panel name="analytics">
            {(props) => (
              <>
                <SubscriberGroupDashboard
                  {...props}
                  subscriberGroup={subscriberGroup}
                  settingsSubscriberGroups={settingsSubscriberGroups}
                />
              </>
            )}
          </Panel>
        </TargetContext>
        <SubscriberGroupDPI subscriberGroup={subscriberGroup} period={timePeriod} />
        <SubscriberDetailHelpModal />
      </ViewContainer>
    </>
  );
};

export default container;
