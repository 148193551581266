import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import NumberInput from "common/NumberInput";

const doesNothing = () => {};

const LinesInput = ({ defaultValue = 100, onChange = doesNothing }) => {
  const [lines, setLines] = useState(defaultValue);

  const updateLines = () => {
    lines !== null && onChange({ lines });
  };
  return (
    <div className="input-group-with-button">
      <NumberInput
        defaultValue={defaultValue}
        onChange={setLines}
        onEnter={updateLines}
        label="Number of lines"
        icon="format_line_spacing"
        hideArrows={true}
      />
      <div className="input-group-add">
        <button
          className="btn btn-link waves-effect"
          onClick={updateLines}
          disabled={lines === null}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

const LoggingContextControlsLayout = styled.div`
  & .input-group-with-button {
    display: flex;
    flex-direction: row;
  }
`;
const LoggingContextControls = ({
  lines,
  className,
  onChange = doesNothing,
  children,
}) => (
  <LoggingContextControlsLayout>
    <ColumnsGrid columns={4} className={className} maxWidth="15rem" rowGap="0">
      <LinesInput defaultValue={lines} onChange={onChange} />
    </ColumnsGrid>
    {children}
  </LoggingContextControlsLayout>
);

const LoggingResultLayout = styled.div`
  textarea {
    font-family: "GT-america-mono";
    font-size: 10px;
    line-height: 16px;
    min-height: 15cm;
    width: 100%;
    border: solid 1px #a4a4a4;
    padding: 0.25em 0.75em;
    overflow-x: auto;
    white-space: pre;
    resize: vertical;
  }
`;
const ButtonWithIcons = styled.button`
  & > * {
    line-height: 24px;
    vertical-align: middle;
  }
`
const Download = ({ name, content }) => {
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    const moment = new Date().toISOString().replace(/\D/g, "").slice(0, 14);
    element.href = URL.createObjectURL(file);
    element.download = `${name}-${moment}-logs.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (
    <div className="align-right">
      <ButtonWithIcons
        type="button"
        onClick={downloadTxtFile}
        className="btn btn-link waves-effect"
      >
        <span>Download</span>
        <i className="material-icons">file_download</i>
      </ButtonWithIcons>
    </div>
  );
};

const LoggingResult = ({ name, response }) => (
  <LoggingResultLayout>
    <Download name={name} content={response} />
    <textarea>{response}</textarea>
  </LoggingResultLayout>
);

const LoggingRequest = ({ lines, provider, name = "log" }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    console.warn("do load", lines);
    const doingRetrieval = provider({ lines }).catch((error) => error);
    setRequest(doingRetrieval);
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [lines]);
  return request === null ? null : (
    <Request during={request.then((response) => ({ response }))}>
      {(result) => <LoggingResult name={name} {...result} />}
    </Request>
  );
};

const _defaultContext = {
  lines: 100,
};

const LoggingContext = ({name, provider }) => {
  const [context, setContext] = useState(_defaultContext);
  const updateContext = ({ lines }) =>
    setContext((prev) => ({
      ...prev,
      ...(lines === undefined ? {} : { lines }),
    }));

  return (
    <>
      <LoggingContextControls {...context} onChange={updateContext}>
        <LoggingRequest name={name} {...context} provider={provider} />
      </LoggingContextControls>
    </>
  );
};

export default LoggingContext;
