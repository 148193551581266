const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat(value.trim("%")) / 100
    : parseFloat(value);

const parsedValues = (track, opt, accel) => ({
  track: parseFloatOrNull(track),
  opt: parseFloatOrNull(opt),
  accel: parseFloatOrNull(accel),
});

export const buildLabelByRTT = (zero = 0, small = 10, large = 60) => ({
  "Global-RttiLo": `${zero}-${small}ms`,
  "Global-RttiMd": `${small}-${large}ms`,
  "Global-RttiHi": `>${large}ms`,
});

const _defaultLabelByRTT = buildLabelByRTT();

const nameByRTT = {
  "Global-RttiLo": "rtt-lo",
  "Global-RttiMd": "rtt-md",
  "Global-RttiHi": "rtt-hi",
};

const incorporateRTTIInto = (
  previous,
  type,
  values,
  labels = _defaultLabelByRTT
) =>
  values.opt === null && values.track === null
    ? previous
    : {
        ...previous,
        rtti: [
          ...previous.rtti,
          { label: labels[type], name: nameByRTT[type], ...values },
        ],
      };

const incorporateServiceInto = (previous, name, values) =>
  values.opt === null && values.track === null
    ? previous
    : {
        ...previous,
        services: [
          ...previous.services,
          { name: name.replace(/^tcp:/, ""), ...values },
        ],
      };

const incorporateInto = (
  previous,
  { type, track, opt, accel },
  labelByRTT = _defaultLabelByRTT
) =>
  type === "Global"
    ? { ...previous, average: parsedValues(track, opt, accel) }
    : type.startsWith("Global-Rtti")
    ? incorporateRTTIInto(
        previous,
        type,
        {
          ...parsedValues(track, opt, accel),
        },
        labelByRTT
      )
    : incorporateServiceInto(previous, type, {
        ...parsedValues(track, opt, accel),
      });

const includeRowData = (result, row, labelByRTT = _defaultLabelByRTT) => {
  const [type, dir, track, opt, accel] = row.split(/\s+/);
  return dir === "Down"
    ? {
        ...result,
        download: incorporateInto(
          result.download,
          { type, track, opt, accel },
          labelByRTT
        ),
      }
    : {
        ...result,
        upload: incorporateInto(
          result.upload,
          { type, track, opt, accel },
          labelByRTT
        ),
      };
};

const _SCAFFOLD_ = {
  download: { average: null, rtti: [], services: [] },
  upload: { average: null, rtti: [], services: [] },
};

export const parseResult = (input, labelByRTT = _defaultLabelByRTT) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  return rows.reduce((prev, row) => includeRowData(prev, row, labelByRTT), {
    ..._SCAFFOLD_,
  });
};
const speedParamRegEx = /Speed\ RTTI\ (large|small)\:\s+(.*)$/;

export const parseRangesFromParams = (input) => {
  let [small, large] = [0, 0];
  input
    .trim("\n")
    .split("\n")
    .map((line) => line.match(speedParamRegEx))
    .filter((item) => item !== null)
    .map(([_str, part, valueStr]) => {
      const value = parseFloat(valueStr) * 1000;
      if (part === "small") {
        small = value;
      }
      if (part === "large") {
        large = value;
      }
    });
  return [0, small, large];
};
