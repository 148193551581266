import React from "react";
import SelectInput from "common/SelectInput";
import CheckboxInput from "common/CheckboxInput";

const asLabelAndValue = ({ name }) => ({ label: name, value: name });

const AskInterface = ({
  end,
  interfaces = [],
  onChange,
  defaultValue = null,
}) => (
  <>
    <SelectInput
      name={`${end}Iface`}
      label={""}
      defaultValue={defaultValue}
      onChange={onChange}
      options={interfaces.map(asLabelAndValue)}
      className="input-control align-left"
      icon="location_on"
    />
    <CheckboxInput name={`${end}Pcap`} label="PCAP" />
  </>
);

const interfaceCatAndNumRE = /(.*[a-z])([0-9]+)$/;

const byCategory = (one, another) =>
  one < another ? -1 : another < one ? 1 : 0;

const byId = (one, another) => {
  const [valueOne, valueAnother] = [Number(one), Number(another)];
  return valueOne < valueAnother ? -1 : valueAnother < valueOne ? 1 : 0;
};

const byDeviceName = ({ name: one }, { name: another }) => {
  const [_allOne, catOne, idOne] = one.match(interfaceCatAndNumRE) || [];
  const [_allAnother, catAnother, idAnother] =
    another.match(interfaceCatAndNumRE) || [];
  return byCategory(catOne, catAnother) || byId(idOne, idAnother) || 0;
};

const Fields = ({ onChange, stored = false, interfaces = [] }) => {
  const [defaultAccIf, defaultInetIf] = interfaces.sort(byDeviceName);
  return (
    <>
      <h5>Access interface:</h5>
      <AskInterface
        end="acc"
        interfaces={interfaces}
        onChange={onChange}
        disabled={stored}
        defaultValue={defaultAccIf.name}
      />
      <h5>Internet interface:</h5>
      <AskInterface
        end="inet"
        interfaces={interfaces}
        onChange={onChange}
        disabled={stored}
        defaultValue={defaultInetIf.name}
      />
    </>
  );
};
export default Fields;
