import styled from "styled-components";

const TooltipWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 6px;
  transform: translate(-50%, -50%);
  font-family: "AllianceNo2-Regular" !important;
`;

const TooltipHeader = styled.div`
  font-family: "AllianceNo2-SemiBold" !important;
  font-size: 13px;
  font-weight: 100 !important;
`;

const TooltipContent = styled.div`
  margin: 4px 0;
  display: flex;
  align-items: center;
`;

const TooltipKey = styled.div`
  margin: 0 10px;
`;

const TooltipLabel = styled.div`
  margin: 0 0px;
  width: 80px;
`;

const TooltipValues = styled.div`
  min-width: 280px;
  display: flex;
  margin-top: 10px;
`;

const TooltipValueWrapper = styled.div`
  width: 140px;
`;

const TooltipValue = styled.div`
  font-family: "AllianceNo2-SemiBold" !important;
`;

const TooltipColor = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 12px;
  height: 12px;
  border: 1px solid #999;
`;

export const Tooltip = ({ interactionData, width, height, unit }) => {
  if (!interactionData) {
    return null;
  }

  const { type, xLabel, yLabel, yColor, xPos, yPos, value, mean } =
    interactionData;

  if (type === "yLabel") {
    return (
      <div
        style={{
          height,
          width: width,
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
        }}
      >
        <TooltipWrapper
          style={{
            position: "absolute",
            width: "auto",
            left: xPos,
            top: yPos,
          }}
        >
          <TooltipHeader>{yLabel}</TooltipHeader>
        </TooltipWrapper>
      </div>
    );
  }

  let xCorrection = 0;

  if (interactionData.xPos / width > 0.75) {
    xCorrection = -200;
  }
  if(width < 450){
    xCorrection = -100;
  }

  return (
    <div
      style={{
        height,
        width: width,
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      <TooltipWrapper
        style={{
          position: "absolute",
          width: "auto",
          left: xPos + xCorrection,
          top: yPos,
        }}
      >
        <TooltipHeader>{xLabel}</TooltipHeader>
        <TooltipContent>
          <TooltipColor bgColor={yColor}></TooltipColor>
          <TooltipKey>{yLabel}</TooltipKey>
        </TooltipContent>
        <TooltipValues>
          <TooltipValueWrapper>
          <TooltipLabel>Value</TooltipLabel>
            <TooltipValue>
              {value} {unit}
            </TooltipValue>
          </TooltipValueWrapper>
          <TooltipValueWrapper>
          <TooltipLabel>Mean</TooltipLabel>
            <TooltipValue>
              {mean} {unit}
            </TooltipValue>
          </TooltipValueWrapper>
        </TooltipValues>
      </TooltipWrapper>
    </div>
  );
};

export default Tooltip;
