import React, { useState } from "react";
import styled from "styled-components";
import Request from "common/Request";
import { parseNames } from "../api";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import MeanThresholdInput from "./MeanThresholdInput";
import AddressInput from "./AddressInput";
import SwitchInput from "common/SwitchInput";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const _defaultSettings = {
  lines: 100000,
  direction: "downlink",
  policy: "all",
  subnet: "",
  colorizeQuartiles: false,
  subscriberGroup: "all"
};

const doesNothing = () => {};

const _linesOptions = [
  { label: "100", value: 100 },
  { label: "1000", value: 1000 },
  { label: "10000", value: 10000 },
  { label: "100000", value: 100000 },
  { label: "1000000", value: 1000000 },
];
const _directionOptions = [
  { label: "Downlink", value: "downlink" },
  { label: "Uplink", value: "uplink" },
];
const nameAsOption = (name) => ({ label: name, value: name });
const requestRatePolicies = () =>
  ifCl.run("show policy rate").then(parseNames);

const requestSubscriberGroups = () =>
  ifCl.run("show api subscriber-group").then(parseNames);

const ControlsAndResultsLayout = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .input-group {
    margin-bottom: 0;
  }

`;

const allPolicies = { label: "All rate policies", value: "all" };
const allSubscriberGroups = {label: 'All subscriber-groups', value: 'all'};

const FilteringControls = ({
  defaults = {},
  subnet = undefined,
  policy = undefined,
  subscriberGroup = undefined,
  direction = undefined,
  children,
}) => {
  defaults = {
    ..._defaultSettings,
    ...defaults,
    ...(subnet === undefined ? {} : { subnet }),
    ...(policy === undefined ? {} : { policy }),
    ...(subscriberGroup === undefined ? {} : { subscriberGroup }),
    ...(direction === undefined ? {} : { direction }),
  };
  const [settings, setSettings] = useState(defaults);
  const onChange = (event) => {
    const { target } = event;
    const value =
      target.type !== "checkbox"
        ? target.value
        : target.value === "on" && target.checked === true;
    setSettings((settings) => ({
      ...settings,
      [target.name]: value,
    }));
  };
  return (
    <ControlsAndResultsLayout>
      <ColumnsGrid columns={4} minWidth="15rem" gap="3rem" rowGap="10px" className="subscribers-report-controls">
        <SelectInputWithIcon
          title="Lines"
          name="lines"
          icon="format_line_spacing"
          selected={settings.lines}
          onChange={onChange}
          options={_linesOptions}
        />
        <SelectInputWithIcon
          title="Direction"
          name="direction"
          icon="import_export"
          selected={settings.direction}
          onChange={onChange}
          options={_directionOptions}
        />
        <Request during={requestRatePolicies()}>
          {({ names }) => (
            <SelectInputWithIcon
              title="Policies"
              name="policy"
              icon="folder_open"
              selected={settings.policy}
              onChange={onChange}
              options={[allPolicies, ...names.map(nameAsOption)]}
            />
          )}
        </Request>
        <Request during={requestSubscriberGroups()}>
          {({ names }) => (
            <SelectInputWithIcon
              title="Subscriber groups"
              name="subscriberGroup"
              icon="group"
              selected={settings.subscriberGroup}
              onChange={onChange}
              options={[allSubscriberGroups, ...names.map(nameAsOption)]}
            />
          )}
        </Request>
        <AddressInput
          value={settings.subnet}
          name="subnet"
          onChange={onChange}
          className="input-group"
        />
        <MeanThresholdInput name="meanthreshold" />
        <SwitchInput
          defaultChecked={settings.colorizeQuartiles}
          name="colorizeQuartiles"
          label={`Colorize: ${
            settings.colorizeQuartiles ? "by quartiles" : "by warning threshold"
          }`}
          onChange={onChange}
        />
        
      </ColumnsGrid>
      {children(settings)}
    </ControlsAndResultsLayout>
  );
};
export default FilteringControls;
