import { validateIPv4IPv6, validateIPv4IPv6Range } from "common/api";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export const InlineInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: center;

  & .input-group {
    margin: 0;
  }
  & .input-group,
  & .input-group-add {
    flex-shrink: 1;
    width: auto;

    .btn-link{
      padding: 10px 20px 0px 20px !important;
    }
  }
  & .break {
    margin-right: auto;
    flex: 1 0 100%;
  }
  & .error {
    font-size: 0.9em;
    color: red;
    position: absolute;
    top: 40px;
  }
  & .input-section {
    display: flex;
    align-items: start;
  }
  & > span {
    white-space: nowrap;
    &.as-label {
      align-self: center;
    }
  }
  @media screen and (max-width: 700px) {
    & {
      grid-column: 1 / -1;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const AddressInput = ({
  value,
  name = "value",
  className = "",
  onChange = doesNothing,
  allowIpRanges,
}) => {
  const input = useRef(null);
  const [error, setError] = useState(false);

  const isValidIPValue = (value) =>
    value.length === 0 || 
  ((allowIpRanges === undefined || allowIpRanges === true) && validateIPv4IPv6Range(value)) === true || 
  (allowIpRanges === false && validateIPv4IPv6(value));

  const updateAddr = () => {
    const newAddress = (input.current !== null && input.current.value) || "";
    if (isValidIPValue(newAddress)) {
      onChange({ target: input.current });
    }
  };

  const handleKeyPressed = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      error === false && updateAddr();
    }else{
      if(allowIpRanges === false && event.target.value.indexOf('/') !== -1){
        setError('IP ranges not accepted');
      }else if (isValidIPValue(event.target.value)) {
        setError(false);
      } else {
        setError("Value is not a valid IP value");
      }
    }
  };

  return (
    <InlineInputDiv className="wide">
      <div className="input-section">
        <div className="input-group">
          <span className="input-group-addon">
            <i className="material-icons" title="Address range">
              devices
            </i>
          </span>
          <div className="form-line">
            <input
              type="text"
              ref={input}
              name={name}
              defaultValue={value}
              className="form-control"
              placeholder="Ex: 10.1.0.0 or 2001:db8:80::"
              onKeyUp={handleKeyPressed}
            />
          </div>
          {error !== false && <div className="error break">{error}</div>}
        </div>
        {!error && <div className="input-group-add">
          <button
            id="subsMetricsSubnetBtn"
            className="btn btn-link waves-effect"
            onClick={updateAddr}
          >
            Filter
          </button>
        </div>}
      </div>
    </InlineInputDiv>
  );
};

export default AddressInput;
