import React, { useEffect, useReducer } from "react";
import CreateStatus from "./Create";
import ListStatus from "./List";
import { validBlockingStatusName } from "views/Config/BillingView/api";
import styled from "styled-components";

const doesNothing = () => {};

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;
const anyNameCollisionWith = (__id, name) => (existing) =>
  existing.name === name && existing.__id !== __id;

const doVerification = (item, list) => {
  const { name, __id } = item;
  validBlockingStatusName(name);
  if (list.find(anyNameCollisionWith(__id, name)) !== undefined) {
    throw "name already exists.";
  }
};
function statusReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.status,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...status }) =>
          __id !== action.id
            ? [{ __id, stored, ...status }]
            : stored === true
            ? [{ __id, stored, ...status, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const SingleColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StatusSettings = ({
  defaults = { list: [], unnameged: true },
  onChange = doesNothing,
  className,
}) => {
  const [status, dispatch] = useReducer(statusReducer, {
    changed: false,
    list: [...defaults.list],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createStatus = (item) => {
    doVerification(item, status.list);
    dispatch({
      type: "added",
      status: {...item, name: item.name.replaceAll(' ','_')},
    });
  };
  const validateNewItem = (item) => {
    doVerification(item, status.list);
  };
  useEffect(() => {
    status.changed === true && onChange(status);
  }, [status]);

  return (
    <SingleColumn className={className}>
      <CreateStatus doSave={createStatus} doValidate={validateNewItem} />
      <ListStatus items={status.list} onDelete={handleDeleteId} />
    </SingleColumn>
  );
};

export default StatusSettings;
