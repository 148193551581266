import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import Result from "./Result";
import { parseResult, parseRangesFromParams, buildLabelByRTT } from "./api";
import ActionsContext from "common/ActionsContext";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const doesNothing = () => {};

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
  }
`;

export const labelRangesRetrieval = () =>
  ifCl
    .run("show pkteng parameters")
    .then(parseRangesFromParams)
    .then(([...ranges]) => buildLabelByRTT(...ranges));

const speedInfoRetrieval = (time) =>
  ifCl.run(`show pkteng speed domain all hours ${time} max 10`, {
    timeout: DPI_STATS_TMOUT_MS,
  });

const acquireParamsAndData = ({ hours, activeTab }) =>
  Promise.all([labelRangesRetrieval(), speedInfoRetrieval(hours)])
    .then(([labelByRTT, speedInfo]) => {
      const result = parseResult(speedInfo, labelByRTT);
      result.download.services = result.download.services.slice(0, 5);
      result.upload.services = result.upload.services.slice(0, 5);
      return result;
    })
    .then((result) => ({ result }));

const timeOptions = [
  { label: "1 day", value: 24 },
  { label: "2 days", value: 48 },
  { label: "3 days", value: 72 },
  { label: "1 week", value: 168 },
];

const TCPOComparison = ({ hours = 24, activeTab = "download" }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);
  const [params, setParams] = useState({ hours });
  const [local, setLocal] = useState({ activeTab });

  const doLoad = (params) => {
    setRequest(acquireParamsAndData(params));
  };

  useEffect(
    () => doLoad(params) || actions.recv("do-load", () => doLoad(params)),
    [params]
  );

  const onHoursChange = (value) => {
    const hours = parseInt(value);
    setParams({ ...params, hours });
  };
  const onTabChange = (value) => {
    setLocal({ ...local, activeTab: value });
  };

  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">TCP Speed & Acceleration</h2>
          <small>
            Comparison of TCP connection average speed with and without TCP
            Optimization (TCPO).
          </small>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Request during={request}>
            {(response) => (
              <Result
                {...response}
                onTabChange={onTabChange}
                onHoursChange={onHoursChange}
                timeOptions={timeOptions}
                {...params}
                {...local}
              />
            )}
          </Request>
        </div>
      </div>
    </ViewContainer>
  );
};

export default TCPOComparison;
