import * as d3 from "d3";

export const formats = {
  noDecimals: d3.format(".0f"),
  oneDecimal: d3.format(".1f"),
  twoDecimals: d3.format(".2f"),
  threeDecimals: d3.format(".3f"),
};

const mayAddItem = (item, list) => {
  const { value } = item;
  return (!isNaN(value) && value !== null)
    ? [...list, item] : list;
}

class InvalidItemValue extends Error {};

const wontAllowValueZeroOrLess = (item) => {
  if (item.value <= 0) {
    throw new InvalidItemValue(item)
  }
  return item;
}

const extractRestTotalAndSeries = ({total=null, series=[]}, item) => {
  const key = item.key && item.key.toUpperCase() || null;
  return (key === "REST") ? {total, series: [{...item, key}, ...series]} :
    (key  === "TOTAL") ? {series, total: wontAllowValueZeroOrLess({...item, key}) } :
    {total, series: mayAddItem(item, series)}
};

const renderDate = (value, format=d3.timeFormat("%Y-%m-%d  %H:%M:%S")) =>
`<div class="date bold text-wont-wrap">${format(value)}</div>`

const renderItem = ({key, color, value}, format, options={}) => 
`<div class="series-item ${options.className || ''}">
  <div class="name-color">
    <div class="color"
      style="background-color:${color || 'trasparent'};">
    </div>
    <div class="name">${key}</div>
  </div>
  <div class="value">${format(value)}</div>
</div>`

const formatItemValueGiven = ({showPercentage=false, ...settings}) => 
  showPercentage === true
    ? formatsValueWithPercentage({...settings})
    : formatsValue({...settings})

const formatsValue = ({format=formats.twoDecimals, unit='' }) =>
  (value) => `${format(value)} ${unit}`

const formatsValueWithPercentage = ({total=undefined, ...settings}) => {
  const formatValue = formatsValue(settings);
  return total === undefined
    ? formatsValueWithUnknownPercentage('100', {formatValue, ...settings})
    : total <= 0
    ? formatsValueWithUnknownPercentage('0', {formatValue, ...settings})
    : formatsValueWithSomePercentage({formatValue, total, ...settings})
}

const formatsValueWithUnknownPercentage = (percentage, {percentSymbol='%', formatValue}) =>
  (value) => `${percentage}${percentSymbol} ${formatValue(value)}`

const formatsValueWithSomePercentage = ({total=1, percentSymbol='%', formatValue}) => {
  const formatPercentValue = formats.twoDecimals;
  return (value) =>
    `${formatPercentValue((100 * value)/total)}${percentSymbol} ${formatValue(value)}`
}

const indentBy = (identation=2) => text =>
  text.split('\n')
    .map( line => (' '.repeat(identation) + line).trimEnd(' '))
      .join('\n');

const renderContainer = (contents=[], {tag='div', className='not-here', indentation}) => `
<${tag} class="${className}">
${contents.map(indentBy(indentation)).join('')}
</${tag}>`;

const allItems = () => true;

const notRestOrZeroValueItems = ({key, value}) => key === 'REST' || value !== 0;

const wideOrNarrow = series => series.length > 7 ? 'wide' : 'narrow';

const initial = {total: null, series: []};

const renderTooltipData = ({value, series: items}, {unit, format, showZeroValue, showPercentage}) => {
  const {total=null, series=[]} = items.reduce(extractRestTotalAndSeries, initial);
  const formatItemValue = formatItemValueGiven({
    format,
    showPercentage,
    unit,
    total: total && total.value || undefined,
  });
  const formatTotal = formatItemValueGiven({unit, format})
  const validItems = showZeroValue === true ? allItems : notRestOrZeroValueItems;
  return renderContainer([
    renderDate(new Date(value)),
    renderContainer([
      ...series.filter(validItems).map( item => renderItem(item, formatItemValue, {indentation: 2})),
    ], {className: `series arrange-into-columns ${wideOrNarrow(series)}`}),
    total === null ? '' :
      renderContainer([
        renderItem(total, formatTotal)
      ], {className:'total align-right'}),
  ], {className: 'tooltip-stacked-area-wrapper'});
}

const timeAndValuesTooltipForUnits = (
  unit = 'Mbps',
  format = formats.twoDecimals,
  showZeroValue = true,
  showPercentage = true,
) =>
  function (d) {

    try {
      return renderTooltipData(d, {unit, format, showZeroValue, showPercentage});
    }
    catch (error) {
      console.error(error)
      if (error instanceof InvalidItemValue) {
        return renderDate(new Date(d.value))
      }
      throw error;
    }
  };

export default timeAndValuesTooltipForUnits  ;
