/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import useDebouncer from "common/hooks/debouncer";
import { LegendsAndGraph, LegendsList, useSelection } from "./Legends";

const _defaultMargin = { top: 30, right: 50, bottom: 30, left: 70 };

const doesNothing = () => {};

const WrapperDIV = styled.div`
  position: relative;
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  & > svg {
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}px` : "auto")};
    text.clickable tspan,
    text.clickable {
      font-size: 11px;
      text-decoration: underline;
    }
    .clickable {
      cursor: pointer;
    }
    .nvd3 .nv-multibarHorizontal .nv-groups text {
      dominant-baseline: middle;
      &.embeded-left {
        text-anchor: end;
        fill: white;
      }
    }
  }
`;

export const formats = {
  noDecimals: d3.format(".0f"),
  oneDecimal: d3.format(".1f"),
  twoDecimals: d3.format(".2f"),
  threeDecimals: d3.format(".3f"),
};

const atLeast = min => value => min > value ? min : value;

const waitFor = (delay) =>
  new Promise( resolve => {
    window.setTimeout(resolve, delay)
  })

const defineRectRoundedClip = (target, {id, radius, left, right}) => {
  const group = d3.select(target.node().parentNode);
  let definition = group.select(`clipPath#${id}`)
  if (definition.empty()) {
    definition = group.append('clipPath')
      .attr('id',id )
      .classed('roundedCorners', true)
      .append('rect')
      .attr('rx', radius)
      .attr('ry', radius);
  }
  definition
    .attr('height', target.attr('height'))
    .attr('width',
      atLeast(radius)(
        parseInt(target.attr('width')
      )+ (left && right ? 0 : radius))
    )
    .attr('x', left ? 0: -radius );
}

const clearRoundCorners = main =>
  main.selectAll('.roundedCorners').remove();

const applyRoundCorners = (selection, {radius=5, stacked=false}) =>
  selection.each( function updateRoundCornersOnItem(d, index){
    const current = d3.select(this);
    const {left, right} = stacked === false ? {left: true, right: true} : d;
    if (left !== true && right !== true) {
      current.attr('clip-path', null);
      return
    }
    const id =`rounded-shape-${index}${left ?'-left':''}${right ?'-right':''}`
    current.call(defineRectRoundedClip, {id, left, right, radius, stacked});
    current.attr('clip-path', `url(#${id})`)
  });

const markFirstAndLast = () => {
  let firsts = null;
  let lasts = null;
  const initIfWerent = d =>{
    firsts = firsts || d.values.map( () => null );
    lasts = lasts ||d.values.map( () => null );
  }
  const consider = ({values=[]}) => {
    values.forEach(value => {value.left = false; value.right = false;});
    firsts = firsts.map( (current, index) =>
      current === null && values[index].value > 0 && values[index] || current
    )
    lasts = lasts.map( (current, index) =>
      values[index].value > 0 ? values[index] : current
    )
  }
  const apply = () => {
    firsts.forEach( current => current && Object.assign(current, {left: true}))
    lasts.forEach( current => current && Object.assign(current, {right: true}))
  }

  return function evalGroup(d, index, all) {
    initIfWerent(d);
    if (d.rounded !== false) {
      consider(d);
    }
    if (index >= (all.length - 1) ) {
      apply();
    }
  }
}

const useOrClone = (from, selector, source) => {
  const target = from.select(selector);
  return target.node() === null
    ? source.clone()
    : target.attr("d", source.attr("d"));
};

const callSetRoundCorners = (main, options) =>
  main.call(clearRoundCorners)
    .selectAll(".nv-groups .nv-group .nv-bar rect")
    .call(applyRoundCorners, options)


const callDrawLimits = (main) => {
  const target = d3.select(main.node());
  const yDomainPath = target.select(".nv-y .domain");
  if (yDomainPath.node() === null) {
    return main;
  }
  const xDomainPath = target.select(".nv-x .domain");
  const { width } = yDomainPath.node().getBBox();
  const { height } = xDomainPath.node().getBBox();
  useOrClone(target, ".nv-x .limit", xDomainPath)
    .classed("domain", false)
    .classed("limit", true)
    .style("transform", `translate(${width}px, 0)`);
  useOrClone(target, ".nv-y .limit", yDomainPath)
    .classed("domain", false)
    .classed("limit", true)
    .style("transform", `translate(0, ${-height}px)`);
};

const NVHorizontalBarChart = ({
  className,
  items,
  height=undefined,
  margin = _defaultMargin,
  animationDuration = 200,
  tooltipValueFormatter = undefined,
  valueUnits = "",
  valueFormat = formats.oneDecimal,
  showMaxMin = true,
  onClick = undefined,
  showValues = false,
  showBorder = false,
  showColors = false,
  showControls = false,
  showRoundCorners = false,
  roundCornerRadius = 5,
  showLegend = true,
  stacked = true,
  valuePadding = undefined,
  yAxisLabel = undefined,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const debounce = useDebouncer(1000);
  const chartRef = useRef(null);
  let resizeObserver = null;
  const render = (target) => {
    console.warn('NVHorizontalBarChart', stacked);
    nv.addGraph(function () {
      const chart = nv.models
        .multiBarHorizontalChart()
        .margin(margin)
        .x(function (d) {
          return d.label;
        })
        .y(function (d) {
          return d.value;
        })
        .stacked(stacked)
        .showControls(showControls)
        .showLegend(showLegend)
        .showValues(showValues)
        .noData(NO_DATA_AVAILABLE);
      chart.tooltip.valueFormatter(
        tooltipValueFormatter === undefined
          ? (value) => `${formats.oneDecimal(value)} ${valueUnits}`
          : tooltipValueFormatter
      );
      if (valuePadding) {
        chart.valuePadding(valuePadding);
      }
      chart.yAxis
        .showMaxMin(showMaxMin)
        .ticks(7)
        .tickFormat(
          valueFormat !== undefined
            ? valueFormat
            : (value) => `${formats.oneDecimal(value)} ${valueUnits}`
        );
      if (yAxisLabel !== undefined) {
        chart.yAxis.axisLabel(yAxisLabel)
      }
      function calcPosition(_d) {
        const height = chart.xAxis.rangeBand();
        return `${height / 2}px`;
      }
      function calcEmbedded(d) {
        const { width } = this.getBoundingClientRect();
        const { width: barWidth } =
          this.previousElementSibling.getBoundingClientRect();
        const [_, totalWidth] = chart.yAxis.range();
        return (d.embedded = width + barWidth > totalWidth);
      }
      const renderValues =
        showValues === true
          ? (main) => {
              main
                .selectAll(".nv-bar text")
                .text((d) => valueFormat(d.value))
                .attr("dy", calcPosition)
                .classed("embeded-left", calcEmbedded)
                .attr("dx", (d) => (d.embedded ? "-0.5em" : "0.5em"))
                .attr("x", (d) => chart.yScale()(d.value));
            }
          : () => {};
      const main = d3.select(canvas.current);
      main
        .datum(items)
        .transition()
        .duration(animationDuration)
        .call(chart);
      d3.select(canvas.current).call(renderValues);
      // Detection of mouseleave is not needed

      const adjustRoundCorners =
        (showRoundCorners === true)
          ? callSetRoundCorners
          : doesNothing

      const drawLimits =
        showBorder === true
          ? callDrawLimits
          : function (d) {
              return d;
            };
      const enableClick = (onClick !== undefined)
        ? (selection, onClick) => {
          const handleBarClick = function () {
            const [data] = d3.select(this).data();
            chart.tooltip.hidden(true);
            onClick(data.label);
          };
          const handleTextClick = function () {
            const [label] = d3.select(this).data();
            chart.tooltip.hidden(true);
            onClick(label);
          };;
          main.selectAll(".nv-x .nv-axis").style("pointer-events", "all");
          main
            .selectAll(".nv-bar rect")
            .on("click", handleBarClick)
            .classed("clickable", true);
          main.selectAll(".nv-axis.nv-x text")
            .on('click', handleTextClick)
            .classed("clickable", true);
        } : doesNothing;

      nv.utils.windowResize(() => {
        debounce(() => {
          chart.update();
          setTimeout(
            () => d3.select(canvas.current)
              .call(renderValues)
              .call(drawLimits)
              .call(adjustRoundCorners, {radius: roundCornerRadius, stacked}),
            1000
          );
        });
      });
      chart.details = () => {
        main.call(renderValues).call(drawLimits);

        if (showColors) {
          main.selectAll("rect").attr("fill", (d) => d.color);
        }
        if (showRoundCorners) {
          main.selectAll(".nv-groups .nv-group").each(markFirstAndLast());
          main.call(adjustRoundCorners, {radius: roundCornerRadius, stacked});
        }
        
        main.call(enableClick, onClick);
      }
      chart.dispatch.on("renderEnd", function () {
        chart.details()
      });
      chartRef.current = chart;
      return chart;
    });

    return () => {
      resizeObserver !== null && resizeObserver.unobserve(target);
    };
  };
  useEffect(() => {
    const chart = chartRef.current;
    if (chart !== null ) {
      chart.stacked(stacked);
      const main = d3.select(canvas.current);
      main.datum(items);
      chart.update()
      waitFor(animationDuration + 50).then( () => chart.details());
      return ;
    }
    const target = wrapper.current;
    return items ? render(target) : () => {};
  }, [items, stacked]);

  useEffect(() => {
    const chart = chartRef.current;
    return () => {
      console.log("nv-lineChart destroy");
      if (chart !== null) {
        chart.tooltip.hidden(true);
        // chart.destroy();
      }
    };
  }, []);
  return (
    <WrapperDIV
      height={height}
      ref={wrapper}
      className={className}
    >
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

const takeLongerSeriesValuesLength = (higher, {values=[]}) =>
  higher > values.length ? higher : values.length;

const countSeriesIn = (series) =>
  series.reduce(takeLongerSeriesValuesLength, 0)

const calcHeight = ({height=undefined, barHeight=55, items=[], margin=_defaultMargin}) =>
  height !== undefined ? height : 
  barHeight !== undefined ? (countSeriesIn(items) * barHeight) + (margin.top + margin.bottom) :
  undefined

export const calcBarHeight = length =>
  length < 6 ? 55 :
  length < 10 ? 45 :
  length < 20 ? 35 :
  length < 30 ? 30 :
  length < 40 ? 20 :
  15

const LegendsAndNVHorizontalBarChart = ({
  items, onClick, prependLegends=[], hideLegends=false, stacked=true, ...rest
}) => {
  const [selection, doToggle] = useSelection(items);
  return (
    <LegendsAndGraph>
      {hideLegends === true ? null :
        <LegendsList prepend={prependLegends} items={selection}
          doToggle={doToggle} onLegendClick={onClick} />
      }
      <div className="horizontal-bars">
        <NVHorizontalBarChart 
          height={calcHeight({items: selection, ...rest})}
          items={selection} {...rest} showLegend={false} onClick={onClick}
          stacked={stacked}
        />
      </div>
    </LegendsAndGraph>
  );
};

export default LegendsAndNVHorizontalBarChart;
