import React, { useEffect, useReducer } from "react";
import CreateClient from "./Create";
import ListClients from "./List";
import { validateIPv4 } from "common/api";

const doesNothing = () => {};

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;

const doVerification = (item, list) => {
  const { address } = item;

  if (list.find((existing) => existing.address === address) !== undefined) {
    throw "address already exists.";
  }
  if (validateIPv4(address) !== true) {
    throw "address is not a valid IPv4 address";
  }
};

function clientsReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.client,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...client }) =>
          __id !== action.id
            ? [{ __id, stored, ...client }]
            : stored === true
            ? [{ __id, stored, ...client, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const Clients = ({ initial = [], onChange = doesNothing }) => {
  const [clients, dispatch] = useReducer(clientsReducer, {
    changed: false,
    list: [...initial],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createClients = (client) => {
    doVerification(client, clients.list);
    dispatch({
      type: "added",
      client,
    });
  };
  const validateNewItem = (client) => {
    doVerification(client, clients.list);
  };
  useEffect(() => {
    clients.changed === true && onChange(clients.list);
  }, [clients]);

  return (
    <>
      <ListClients items={clients.list} onDelete={handleDeleteId} />
      <CreateClient doSave={createClients} doValidate={validateNewItem} />
    </>
  );
};

export default Clients;
