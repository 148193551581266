const renderInput = () => {
  const input = document.createElement("input");
  input.style.cssText = "display:none;";
  input.setAttribute("type", "file");
  input.setAttribute("name", "requested");
  document.body.appendChild(input);
  return input;
};

const readFileFrom = (input) =>
  new Promise((resolve, reject) => {
    if (input === null) {
      reject("Can not retrieve file control");
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    input.onchange = (event) => {
      const { files } = event.target;
      const [file] = files;
      reader.readAsText(file);
    };
    input.click();
  });

const disposeInput = (input) => {
  document.body.removeChild(input);
};

const requestUserFile = () => {
  const input = renderInput();
  return readFileFrom(input).finally(() => disposeInput(input));
};

export default requestUserFile;
