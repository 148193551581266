import React, { useState, useContext, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import { enumerate } from "../api";
import { EditRanges } from "./Ranges";

const doesNothing = () => {};

const EditModal = ({ profiles = [], doSave = doesNothing }) => {
  const [open, setOpen] = useState(false);
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [index, setIndex] = useState(null);
  const actions = useContext(ActionsContext);
  actions.recv("profile-settings", function (index) {
    setProfile(index === null ? null : { ...profiles[index] });
    setIndex(index);
    setOpen(index !== null);
    setError(null);
  });
  const updateField = (field) => (value) => {
    setProfile({ ...profile, [field]: value, modified: true });
  };
  const doClose = () => setOpen(false);
  const doApply = () => {
    doSave(index, profile);
    doClose();
  };
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title={`Profile ${profile.name} settings`}
      large={true}
      content={() => (
        <form ref={form}>
          <EditRanges
            ranges={enumerate(0)(profile.ranges)}
            onChange={updateField("ranges")}
          />
        </form>
      )}
      onApply={doApply}
      applyLabel="Update"
      applyDisabled={profile.modified !== true || error !== null}
      onClose={doClose}
      closeLabel="Discard changes"
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

export default EditModal;
