import React from "react";

const doesNothing = () => {};

const DeviceItem = ({ mode, model, sn, links, deleted, even = false }) => {
  if (deleted === true) {
    return null;
  }

  return (
    <React.Fragment>
      <tr className={`${even ? "even" : "odd"}`}>
        <td>{model}</td>
        <td>{sn}</td>
        <td>{mode}</td>
        <td>{links}</td>
      </tr>
    </React.Fragment>
  );
};
const DevicesList = ({
  items = [],
  onDelete = doesNothing,
  onEdit = doesNothing,
}) =>
  items.length === 0 ? (
    <h5>No devices found</h5>
  ) : (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>MODEL</th>
          <th>SERIAL</th>
          <th>MODE</th>
          <th>LINKS-IN-BYPASS</th>
        </tr>
      </thead>
      <tbody>
        {items
          .filter(({ deleted }) => deleted !== true)
          .map(({ __id, ...client }, index) => (
            <DeviceItem
              id={index}
              key={index}
              {...client}
              even={(index + 1) % 2 === 0}
              onDelete={() => onDelete(__id)}
              onEdit={() => onEdit(__id)}
            />
          ))}
      </tbody>
    </table>
  );
export default DevicesList;
