import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const TotalVolumeDPIHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="TOP SUBSCRIBERS"
      onClose={doClose}
      content={() => (
        <>
          <p> Total traffic by subscriber origin.</p>

          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 2 days, 3
              days or 1 week.
            </li>
            <li>
              <i className="material-icons valign-icon">format_line_spacing</i>{" "}
              Selects the number of subscribers displayed in the chart.
            </li>
            <li>
              <i className="material-icons valign-icon">import_export</i>{" "}
              Selects the direction of traffic (Uplink, Downlink or Both).
            </li>
            <li>
              IP-Addr/Subs-Id. output grouping, Determines how the subscribers
              are identified, whether by IP address or by subscriber-ID.
            </li>
            <li>
              IP-Addr/Subs-Id. When present, filters the output for the
              specified IP address (range) or subscriber Id. Click{" "}
              <code>Filter</code> button to update chart.
            </li>
          </ul>
          <p>Click on any subscriber to open that subcriber details.</p>
          <p>
            Click on <i className="material-icons valign-icon">refresh</i> icon
            to refresh information.
          </p>
        </>
      )}
    />
  );
};
export default TotalVolumeDPIHelpModal;
