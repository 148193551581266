import React from "react";
import styled from "styled-components";
import TextInput from "common/TextInput";
import SelectInput from "common/SelectInput";
import PasswordInput from "common/PasswordInput";
import FoldablePanel from "common/FoldablePanel";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const doesNothing = () => {};

const Wrapper = styled.div`
  > div {
    height: auto;
    margin-bottom: 20px;
  }
`;

const powercodeBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer-ID", value: "customer-id" },
  { label: "Equipment-ID", value: "equipment-id" },
  { label: "MAC", value: "mac" },
  { label: "Name", value: "name" },
];

const dictionary = {
  'user-name': 'name',
  'calling-station-id': 'mac',
  'nas-id': 'equipment-id'
}

function translate(input){
  return dictionary[input] ?? input;
}

const PowerCodeBillingSettings = ({
  onChange,
  defaults = {},
  state,
  local = {},
  setLocal = doesNothing,
}) => (
  <>
    <TextInput
      type="text"
      label="User:"
      name="username"
      className="input-group"
      defaultValue={defaults.username}
      onChange={onChange}
    />
    <PasswordInput
      defaultValue={defaults.password}
      className="input-group"
      name="password"
      onChange={onChange}
    />
    <FoldablePanel
      title="MySQL Credentials"
      className="full-width padding-b-10"
      opened={local.mysqlFoldedPanelOpen === true}
      onChange={(mysqlFoldedPanelOpen) =>
        setLocal((previous) => ({ ...previous, mysqlFoldedPanelOpen }))
      }
    >
      <ArrangeInColumns rowGap="0.2em">
        <div className="full-width padding-b-10">
          <small>
            The <code>Username</code> and <code>Password</code> above will used
            if these fields are empty in order to access the MySQL database.
          </small>
        </div>
        <TextInput
          type="text"
          label="User:"
          name="mysqlUsername"
          className="input-group"
          defaultValue={defaults.mysqlUsername}
          onChange={onChange}
        />
        <PasswordInput
          defaultValue={defaults.mysqlPassword}
          className="input-group"
          name="mysqlPassword"
          onChange={onChange}
        />
      </ArrangeInColumns>
    </FoldablePanel>
    <Wrapper>
      <SelectInput
        label="Subscriber-ID source"
        name="subscriberIdSource"
        onChange={onChange}
        defaultValue={defaults.subscriberIdSource ==="n/a" || defaults.subscriberIdSource === undefined ? "unchanged" : translate(defaults.subscriberIdSource)}
        options={powercodeBillingSubscribers}
      />
    </Wrapper>
  </>
);

export default PowerCodeBillingSettings;
