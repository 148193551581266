import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help-dashboard", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Subscriber Group Dashboard"
      onClose={doClose}
      content={() => (
        <>
         <p>
            Shows current information and statistics for the selected
            subscriber group.
            <ul>
              <li>Click on the refresh <i className="material-icons valign-icon">refresh</i> icon to refresh the data. </li>
            </ul>
          </p>
          <br />
          <h5>Historical Metrics</h5>
          <p>
            The page shows the evolution over the selected period of time of a
            number of metrics:
            <ul>
              <li>
                The average speed (measured over 10-minute intervals) in the
                downlink and uplink directions.
              </li>
              <li>
                The number of active flows and the number of flows created in 10
                minute intervals.
              </li>
              <li>
                The average access latency, from this node to the subscriber
                group members, not counting the path from this node to the
                content services in Internet. As a reference, the average
                latency for all the network subscriber groups is included.
              </li>
              <li>
                The average packet retransmissions on the access side. As a
                reference, the average retransmissions for all the network
                subscribers groups are included.
              </li>
              <li>
                The average percent of downlink traffic that is detected as
                congested by the ACM functionality.
              </li>
              <li>
                The average percent of downlink traffic that goes at a speed
                near the maximum.
              </li>
              <li>
                Latency vs Average Speed: scatter plot of latency-speed pairs.
              </li>
              <li>
                Packet Retransmissions vs Average Speed: scatter plot of
                retransmissions-speed pairs.
              </li>
              <li>
                Congestion vs Average Speed: scatter plot of congestion-speed
                pairs.
              </li>
            </ul>
          </p>
          <br />
          <h5>Subscriber group editing </h5>
          <ul>
            <li>
              Click on the {" "}<i className="material-icons valign-icon">edit</i> Edit subscriber group members button to edit
              the subscriber group.
            </li>
            <li>
              Click on the {" "}<i className="material-icons valign-icon">multiline_chart</i> See main subscribers in group
              button to see the active subscribers.
            </li>
            <li>
              Click on the {" "}<i className="material-icons valign-icon">edit</i> icon next to Rate-Policy to select a rate policy for this subscriber group.
            </li>
            <li>
              If a rate policy is already assigned, click on its name to see its details, or to modify it if it is configurable.
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
