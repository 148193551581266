import React from "react";
import { parseFromTabs, composeApplyCommand, configToForm } from "./api";

import SelectInput from "common/SelectInput";
import TextInput from "common/TextInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SettingsView from "common/SettingsView";

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const initialQuotaChoices = [
  { label: "blocked", value: "blocked" },
  { label: "disabled", value: "disabled" },
];

const QuotasSettings = (props) => {
  const { onChange, defaults = {}, state } = props;

  return (
  <ArrangeInColumns rowGap="0" minWidth="7cm" columns={3}>
    <SelectInput
      label="Subscriber initial quota status"
      name="initial"
      className="input-group"
      defaultValue={defaults.initial}
      onChange={onChange}
      options={initialQuotaChoices}
    />
    <TextInput
      type="text"
      name="ipv4url"
      label="IPv4/HTTP redirect URL:"
      defaultValue={defaults.ipv4url}
      onChange={onChange}
      className="input-control margin-b-0"
    />
    <TextInput
      type="text"
      name="ipv6url"
      label="IPv6/HTTP redirect URL:"
      defaultValue={defaults.ipv6url}
      onChange={onChange}
      className="input-control margin-b-0"
    />
  </ArrangeInColumns>
)};

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const AdvancedSettings = () => {

  const load = () =>
    ifCl.run("show pkteng parameters").then(parseFromTabs).then(configToForm);

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };
  const convertToBoolean = (value, defaultValue = undefined) =>
    value === undefined && defaultValue !== undefined
      ? defaultValue
      : typeof value === "boolean"
      ? value
      : value === "on";

  const normalizeForm = ({ blocked, ...settings }) => {
    const result = {
      blocked: convertToBoolean(blocked),
      ...settings,
    };
    return result;
  };

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () =>
      composeApplyCommand(originalSettings, normalizeForm(settings));
    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };

  return (
    <div className="panel panel-default">
      <div
        className="panel-heading"
        data-toggle="collapse"
        href="#advancedQuotaParameters"
      >
        <h4 className="panel-title" style={{marginBottom: 0}}>
          <a
            role="button"
            className="collapsed-arrow collapsed"
            data-toggle="collapse"
            href="#advancedQuotaParameters"
            aria-expanded="true"
            aria-controls="advancedQuotaParameters"
          >
            Advanced quotas parameters
          </a>
        </h4>
      </div>
      <div id="advancedQuotaParameters" className="panel-collapse collapse">
        <div className="panel-body">
          <SettingsView
            load={load}
            apply={apply}
            clear={clear}
            noHeader={true}
            className=""
          >
            <QuotasSettings />
          </SettingsView>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettings;
