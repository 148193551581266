import React, {useContext} from 'react';
import useProfile from './hooks/useProfile';

const NotForOperators = ({children}) => {
  const profile = useProfile() || {};
  return profile === null || profile.isOperator === true
    ? <></>
    : children;
}

export default NotForOperators;
