/* global DPI_STATS_TMOUT_MS */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import Result from "./Result";
import { parseRootUsage } from "./api";
import ActionsContext from "common/ActionsContext";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={() => {
            actions.send("do-load");
          }}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
  }
`;

export const diskUsageRetrieval = () =>
  ifCl.run("show system filesystem").then(parseRootUsage);

const DiskStatus = () => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    setRequest(diskUsageRetrieval());
  };

  useEffect(() => doLoad() || actions.recv("do-load", doLoad), []);

  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Disk Usage</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Request during={request}>
            {(response) => <Result {...response} />}
          </Request>
        </div>
      </div>
    </ViewContainer>
  );
};

export default DiskStatus;
