import * as d3 from "d3";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
} from "common/constants";

export const parseSystemDate = (input) => new Date(input);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? fixedPrecision(parseFloat(value.trim("%")) / 100, 6)
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const parseDateTime = (input) => {
  const value = dateTimeParser(input);
  if (value === null) {
    throw "Expected time value";
  }
  return value;
};

export const parseKeys = (head, translations = {}, skipped = []) =>
  head
    .split(/\s+/)
    .flatMap((key) =>
      skipped.includes(key) ? [] : [translations[key] || key]
    );

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : parseDateTime(value),
          ]
    )
    .filter((item) => item !== null);

const appendToList = (list = {}, attr, policy, value) =>
  list[policy] === undefined
    ? { ...list, [policy]: { [attr]: value } }
    : { ...list, [policy]: { ...list[policy], [attr]: value } };

const fieldsIntoPolicies = (
  policies,
  attrsPerPolicy = 3,
  skipped = [],
  listName = "policies"
) => {
  const policyAt = (attrIndex, _attr) =>
    policies[Math.floor((attrIndex - 1) / attrsPerPolicy)];
  return (reading, [key, value], index) =>
    skipped.includes(key) === true
      ? reading
      : key === "time"
      ? { ...reading, [key]: value }
      : {
          ...reading,
          [listName]: appendToList(
            reading[listName],
            key,
            policyAt(index, key),
            value
          ),
        };
};
export const namePolicies = ({ policies = {}, ...rest }) => ({
  ...rest,
  policies: Object.entries(policies).map(([name, values]) => ({
    name,
    ...values,
  })),
});

const thosePoliciesActiveInTimeline = (timeline) => (policy) =>
  timeline.find(({ policies }) =>
    policies.some(
      ({ name, ...fields }) =>
        name === policy && Object.values(fields).some((value) => value > 0)
    )
  ) !== undefined;

export const dynamicHeadersParsing = (
  policies,
  fieldsPerPolicy = 3,
  keys = [],
  skipped = [],
  translations = {}
) => {
  let involvedPolicies = new Set(policies);
  let intoThesePolicies = fieldsIntoPolicies(
    policies,
    fieldsPerPolicy,
    skipped
  );
  let rowKeys = keys;
  const voidKeys = () => {
    rowKeys = null;
  };
  const reassignPolicies = (row) => {
    const policies = parseKeys(row.trim(" "), translations);
    intoThesePolicies = fieldsIntoPolicies(policies, fieldsPerPolicy, skipped);
    involvedPolicies = new Set([...involvedPolicies, ...policies]);
    voidKeys();
  };
  return {
    parseRow: (row) => {
      if (rowKeys === null) {
        rowKeys = parseKeys(row.trim(" "), translations, skipped);
        return [];
      }
      try {
        const result = timeAndNumbers(
          row,
          rowKeys,
          skipped,
          translations
        ).reduce(intoThesePolicies, {});
        return [result];
      } catch (exception) {
        if (exception === "Expected time value") {
          reassignPolicies(row);
          return [];
        }
        throw exception;
      }
    },
    getInvolvedPolicies: () => Array.from(involvedPolicies),
    getActivePolicies: (timeline) =>
      Array.from(involvedPolicies).filter(
        thosePoliciesActiveInTimeline(timeline)
      ),
  };
};

export const parsePoliciesFlows = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [policiesHead, head, ...rows] = lines;
  const translations = {
    TIME: "time",
    FLOWS: "flows",
    "MBPS-RX-UP": "upSpeed",
    "MBPS-RX-DOWN": "downSpeed",
  };
  const policies = parseKeys(policiesHead.trim(" "), translations);
  const keys = parseKeys(head.trim(" "), translations);
  const skipped = [];
  const digestDynamicHeaders = dynamicHeadersParsing(
    policies,
    3 /*keys per policy*/,
    keys,
    skipped,
    translations
  );
  const items = rows.flatMap(digestDynamicHeaders.parseRow).map(namePolicies);
  console.error(digestDynamicHeaders.getInvolvedPolicies());
  return {
    timeline: items,
    participants: digestDynamicHeaders.getActivePolicies(items),
  };
};

const PREFIXES = ["TCP", "UDP", "IP"];

export function getKeysSeries(header) {
  const [, ...namesResponse] = header.trim(/\s/).split(/\s+/);
  return namesResponse.map((name) => {
    const chunks = name.split("-");
    return chunks
      .map((chunk) => {
        const prefix = PREFIXES.find((prefix) => chunk.includes(prefix));
        if (prefix) {
          return (
            prefix +
            chunk.substring(prefix.length).charAt(0).toUpperCase() +
            chunk.slice(prefix.length + 1).toLowerCase()
          );
        } else {
          return chunk.charAt(0).toUpperCase() + chunk.slice(1).toLowerCase();
        }
      })
      .join("-");
  });
}

export const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

export const parseFlowsPerProtocolData = (
  response,
  period,
  beginRange,
  endRange
) => {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const keysSeries = getKeysSeries(header);
  const newKeys = ["time", ...keysSeries];

  if (rows.length === 0) {
    return { fields: keysSeries, items: [] };
  }

  const newRows = rows.reduce((acc, value) => {
    const [timeAt, ...attrs] = value.split(/\s+/);
    const time = parseDate(timeAt);
    if (beginRange <= time && time <= endRange) {
      const item = attrs.reduce(
        (accItem, valueItem, index) => {
          accItem[newKeys[index + 1]] = valueItem === "n/a" ? null : Number(valueItem);
          return accItem;
        },
        { time }
      );
      acc.push(item);
    }
    return acc;
  }, []);

  return { fields: keysSeries, items: newRows};
};

export function getInRangeItems(items, range, fields, sampleSize) {
  if (items.length === 0 || fields.length === 0) {
    return [];
  }

  const { from, to } = range;
  let inrangeItems = [];
  let maxItems;
  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (!maxItems) {
        maxItems = { ...items[i] };
      }
      if (i % sampleSize === 0) {
        inrangeItems.push(maxItems);
        maxItems = { ...items[i] };
      } else {
        const newMax = fields.reduce((acc, value) => {
          if (items[i][value] > acc[value]) {
            acc[value] = items[i][value];
          }
          return acc;
        }, maxItems);
        maxItems = newMax;
      }
    }
  }

  if (inrangeItems.length === 0) {
    return [];
  }

  if (
    inrangeItems[inrangeItems.length - 1]?.time &&
    items[items.length - 1]?.time &&
    inrangeItems[inrangeItems.length - 1].time !== items[items.length - 1]?.time
  ) {
    const { time } = items[items.length - 1];
    if (from <= time && time <= to) {
      inrangeItems.push(items[items.length - 1]);
    }
  }

  let extendedItems = [...inrangeItems];
  if (extendedItems.length !== 0) {
    while (extendedItems[0].time >= from) {
      const emptyElement = fields.reduce((acc, value) => {
        acc[value] = null;
        return acc;
      }, {});
      const time = new Date(
        Date.parse(extendedItems[0].time) - 5 * 60000 * sampleSize
      );
      extendedItems.unshift({ ...emptyElement, time });
    }
  }
  return extendedItems;
}