import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="THROUGHPUT BY PROTOCOLS"
      onClose={doClose}
      content={() => (
        <>
          <p>
            Throughput over time, in Mbps, including all interfaces, broken down
            by protocol.
          </p>
          <p>
            A horizontal <span className="col-red">red line</span> will indicate
            the license limit if the total traffic is close to or above that
            limit, but only in the Uplink+Downlink view (because the licence
            limit refers to the sum of uplink and downlink traffic).
          </p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part
            </li>
            <li>
              <i className="material-icons valign-icon">import_export</i>{" "}
              Selects what is inlcuded in the chart:
              <ul>
                <li>
                  Uplink+Downlink: all uplink and downlink traffic together in
                  one chart (including the license limit, if close to the
                  maximum traffic).
                </li>
                <li>
                  Both: uplink and downlink traffic in two independent charts.
                </li>
                <li>Downlink: one chart with the downlink traffic only.</li>
                <li>Uplink: one chart with the uplink traffic only.</li>
              </ul>
            </li>
            <li>
              <i className="material-icons valign-icon">format_line_spacing</i>{" "}
              Selects the protocol breakdown, apart from Bypass traffic (traffic
              that is being bypassed, when so configured in CLI, may include any
              protocol).
              <ul>
                <li>
                  All: will break down traffic by all combinations of L3 and L4
                  protocols.
                </li>
                <li>
                  L3-Protocol: will break down traffic by L3 protocol (IPv4,
                  IPv6 and Non-IP traffic).
                </li>
                <li>
                  L4-Protocol: will break down traffic by L4 protocol (TCP, UDP
                  and Other protocols different from TCP and UDP).
                </li>
              </ul>
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
