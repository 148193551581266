import React, { useState } from "react";
import styled from "styled-components";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import AddressInput from "./AddressInput";

const _defaultSettings = {
  lines: 100000,
  policy: "all",
  subnet: "",
};

const doesNothing = () => {};

const _linesOptions = [
  { label: "100", value: 100 },
  { label: "1000", value: 1000 },
  { label: "10000", value: 10000 },
  { label: "100000", value: 100000 },
  { label: "1000000", value: 1000000 },
];

const ControlsAndResultsLayout = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FilteringControls = ({
  defaults = {},
  subnet = undefined,
  children,
}) => {
  defaults = {
    ..._defaultSettings,
    ...defaults,
    ...(subnet === undefined ? {} : { subnet }),
  };
  const [settings, setSettings] = useState(defaults);
  const onChange = (event) => {
    const { target } = event;
    const value =
      target.type !== "checkbox"
        ? target.value
        : target.value === "on" && target.checked === true;
    setSettings((settings) => ({
      ...settings,
      [target.name]: value,
    }));
  };
  return (
    <ControlsAndResultsLayout>
      <ColumnsGrid columns={3} minWidth="15rem" gap="3rem" rowGap="0">
        <SelectInputWithIcon
          title="Lines"
          name="lines"
          icon="format_line_spacing"
          selected={settings.lines}
          onChange={onChange}
          options={_linesOptions}
        />
        <AddressInput
          value={settings.subnet}
          name="subnet"
          onChange={onChange}
        />
      </ColumnsGrid>
      {children(settings)}
    </ControlsAndResultsLayout>
  );
};
export default FilteringControls;
