import { styled } from "@mui/material/styles";
import SubscriberGroups from "views/Status/SubscriberGroups/component";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";
import { SubscribersAndDetails } from "../Subscribers/SubscribersAndDetails";
import SubscriberGroupEdit from "views/Status/SubscriberGroupsEdit/component";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
  
  const DetailsLayer = styled("div")(() => ({
    position: 'absolute',
  }));
  
  export function SubscriberGroupsAndDashboard({ returnView, ipAddress, addr, subsId, dpiEnabled, direction, timePeriod, metric, subscriberGroup, edit, view, use}){
    const params = { returnView, ipAddress, addr, subsId, dpiEnabled, direction, timePeriod, metric, subscriberGroup, use};
    const showDashboard = !!subscriberGroup && view !== 'qoe';
    const showSubscribers = (returnView === 'viewSubscriberGroupDashboard' || view==='qoe') && !edit;
    const showEdit = edit;
    const showSubscriberDetail = !subscriberGroup && (!!addr || !!subsId) && returnView !== 'viewSubsDetail';
    const shouldHideDashboard = showSubscribers || edit;

    return <>
    <div style={{ opacity:  (showDashboard || showSubscribers || showSubscriberDetail) ? 0:1}}>
      <SubscriberGroups {...params}/>
    </div>
    {showDashboard && 
      <DetailsLayer 
        style={{ display:  shouldHideDashboard ? 'none':'block'}} 
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <SubscriberGroupDashboard {...params}/>
      </DetailsLayer>
    }
    {showSubscribers && 
      <DetailsLayer 
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0">
        <SubscribersAndDetails {...params}/>
      </DetailsLayer>
    }
    {showEdit && 
      <DetailsLayer 
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <SubscriberGroupEdit {...params}/>
      </DetailsLayer>
    }
    {showSubscriberDetail && 
      <DetailsLayer 
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0">
        <VerifiedSubcriberDetail {...params}/>
      </DetailsLayer>
    }
    </>
  }