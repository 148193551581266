import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import RangeSliderInput from "common/RangeSliderInput";
import NVLineChart from "common/graphs/NVLineChart";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import { parseSystemDate, parseRTTTable, requestRTT, mergeWith } from "./api";
import { labelRangesRetrieval } from "views/Status/TCPOComparison/component";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const SelectWrapper = styled.div`
  width: 150px;
`

const TileContainer = styled.div`
  min-height: 9.5cm;
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    display: flex;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    max-width: 20ch;
    margin-bottom: 0;
  }
`;

export const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const doesNothing = () => {};

const SingleColumnGrid = styled.div`
  display: grid;
  flex: 1 1 100%;
`;

export const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const _hoursScopeOptions = [
  { value: 1 * 24, label: "1 Day" },
  { value: 7 * 24, label: "1 Week" },
  { value: 30 * 24, label: "1 Month" },
  //{ value: 24 * 90, label: "3 Months" },
];

const requestTrackedAndOptimized = (direction = "down", days) =>
  Promise.all([
    requestRTT({ type: "optimized", direction, days }).then(parseRTTTable),
    requestRTT({ type: "tracked", direction, days }).then(parseRTTTable),
  ])
    .then(([optimized, tracked]) => mergeWith("optimized", optimized, tracked))
    .catch(debug);

const requestAcceleration = (direction = "down", days) =>
  requestRTT({ type: "acceleration", direction, days })
    .then(parseRTTTable)
    .catch(debug);

const responseAsResult = (response) => ({ result: response });

export const FilteringSettings = ({
  direction = "down",
  hours = 24,
  onHoursChange = doesNothing,
  children,
  type = null,
}) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);
  const doLoad = (hours = 24) => {
    const days = hours / 24;
    setRequest(
      (type === null
        ? requestTrackedAndOptimized(direction, days)
        : requestAcceleration(direction, days)
      ).then(responseAsResult)
    );
  };
  useEffect(() =>
    doLoad(hours) || actions.recv("do-load", () => doLoad(hours))
  , [hours]);

  const handleChange = ({ target }) => {
    const hours = parseInt(target.value);
    onHoursChange(hours);
  };

  return (
    <>
      <TileContainer className="can-compress">
        <SelectWrapper>
          <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={hours || 24}
            onChange={handleChange}
            options={_hoursScopeOptions}
          />
        </SelectWrapper>
      </TileContainer>
      {request === null ? null : (
        <Request during={request}>
          {({ result }) => children({ result, days: hours / 24 })}
        </Request>
      )}
    </>
  );
};

export const HeaderDiv = styled.div`
  display: flex & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
  }
`;

const skipTimeOffset = (input) => input.split(" ").splice(0, 5).join(" ");

const requestCurrentTime = () =>
  ifCl
    .run("show system date")
    .then(skipTimeOffset)
    .then((input) => new Date(input))
    .catch(
      (error) =>
        console.warn("failed to parse current time:", error) || new Date()
    );

const substractHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
};

const getTimeExtent = (hours) =>
  requestCurrentTime().then((current) => ({
    to: current,
    from: substractHours(new Date(current), hours),
  }));

const alonsideRange = (range, items) =>
  range === null || items.length === 0
    ? items
    : extentToRangeLimits(range, items.filter(isInsideRange(range)));

const extentToRangeLimits = (range, items) => [
  { time: range.from },
  ...items,
  { time: range.to },
];

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const formatUnit = (units) => (value) =>
  value === null || value === undefined
    ? "n/a"
    : `${value.toFixed(2)} ${units}`;

const oneForlittleWidth = (width) => (width < 400 ? 1 : width < 600 ? 2 : 3);

const FacetGraph = ({
  facet,
  target,
  title,
  hours = 24,
  range,
  interval,
  units = "Mbps",
  fields,
  noDataMessage = "Not enought samples",
  parse,
  items = [],
  yTooltipFormat = undefined,
}) => {
  const wrapper = useRef(null);
  return (
    <TileContainer>
      <h4>{title}</h4>
      <div className="graph" ref={wrapper}>
        <NVLineChart
          facet={facet}
          items={alonsideRange(range, items)}
          xField="time"
          yAxisUnits={units}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          yTooltipFormat={formatUnit(units)}
          yAxisTopGap={0.05}
          yAxisBottomGap={0.05}
          yAxisBottomValue={0}
          fields={fields}
          noDataMessage={noDataMessage}
          skipVoid={true}
        />
      </div>
    </TileContainer>
  );
};

const GraphWrapper = styled.div`
  min-height: 9.5cm;
  display: flex;
  flex-direction: column;
`;

const Graph = ({ data = [], fields = [], title, range, units, ...rest }) => (
  <GraphWrapper>
    <FacetGraph
      items={data}
      fields={fields}
      title={title}
      range={range}
      units={units}
      {...rest}
    />
  </GraphWrapper>
);
const Graphs = ({ data = [], range = null, facets = [] }) => (
  <>
    <ColumnsGrid gap="1rem" columns={2} minWidth="9cm" rowGap="2rem">
      {range !== null
        ? facets.map(({ fields, label, units, ...rest }) => (
            <Graph
              key={label}
              data={data}
              title={label}
              range={range}
              fields={fields}
              units={units}
              {...rest}
            />
          ))
        : null}
    </ColumnsGrid>
  </>
);
const debug = (error) => {
  console.error(error);
  debugger;
};

export const DaysContext = ({ days, ...rest }) => {
  const [range, setRange] = useState(null);
  useEffect(() => {
    getTimeExtent(days * 24).then(setRange);
  }, [days]);
  return range === null ? null : (
    <Report availableRange={range} days={days} {...rest} />
  );
};

const Report = ({ result = [], days = 1, facets = [], availableRange }) => {
  const [range, setRange] = useState(availableRange && { ...availableRange });
  useEffect(() => {
    setRange(availableRange);
  }, [availableRange]);
  return (
    <SingleColumnGrid>
      <TileContainer className="full-width can-compress">
        {range === null ? null : (
          <Graphs data={result} range={range} facets={facets} />
        )}
        {availableRange !== undefined ? (
          <RangeSliderInput {...availableRange} onChange={setRange} className="margin-t-30"/>
        ) : null}
      </TileContainer>
    </SingleColumnGrid>
  );
};

const removeGlobal = (source) =>
  Object.fromEntries(
    Object.entries(source).map(([field, value]) => [
      field.replace(/^Global-/, ""),
      value,
    ])
  );

export const LabelRetrieval = ({ children }) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);
  const doLoad = () => setRequest(labelRangesRetrieval().then(removeGlobal));
  useEffect(() => {
    doLoad();
  }, []);
  return request === null ? null : (
    <Request during={request}>{(labelRanges) => children(labelRanges)}</Request>
  );
};
