import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import {getColorScale, getColorScaleReds, getDataValues} from './utils';

const LegendBackground = styled("div")(() => ({
  backgroundColor: 'var(--Timeline-Background)'
}));

const Slider = styled("div")(() => ({
  width: 70,
  height: 15,
  backgroundColor: 'transparent',
  position: 'absolute',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
}));

const SliderLabel = styled.div`
  position: absolute;
  height: 17px;
  user-select: none;
  cursor: pointer;
  padding-left: 18px;

  ::after{
    display: none;
  }

  i{
    position: absolute;
    font-size: 12px;
    left: 0px;
    top: 3px;
    transform: rotate(90deg);
    font-weight: bold;
  }
`;

const ContainerFullHeight = styled.div`
  position: absolute;
  height: calc(100% + 30px);
  user-select: none;
  padding-left: 30px;

  .heatMapAxis {
    fill: var(--chart-axis-text-color);
    color: #929295;
    font-family: var(--chart-font-family, Arial, sans-serif);
    font-size: 9px;

    &.axisSelect {
      cursor: pointer;
    }
  }
`;

export const ColorLegendVertical = ({
  yScale,
  yScaleInverse,
  min, 
  max,
  width,
  height,
  interactionData,
  onMaxChanged,
  onMinChanged,
  precision,
  yAxisTopGap = 0,
  colorScaleMaxOverride,
  colorScaleMinOverride,
  tickFormat
}) => {
  const [userColorScaleMin, setUserColorScaleMin] = useState(null);
  const [userColorScaleMax, setUserColorScaleMax] = useState(null);
  const canvasRef = useRef(null);
  const sliderSelected = useRef(null);
  const containerRef = useRef(null);
  const topGap = yScale(max) - yScale(max + max * yAxisTopGap);
  const colorScaleLight = getColorScaleReds(userColorScaleMin ? userColorScaleMin : min, userColorScaleMax ? userColorScaleMax : max);
  const colorScaleDark = getColorScale(userColorScaleMin ? userColorScaleMin : min, userColorScaleMax ? userColorScaleMax : max, "#031d49", "#cdd8f3")
  const colorScale = login.isTheme("light") ? colorScaleLight : colorScaleDark;
  const canvasOffset = Math.max(yScale(colorScale.domain()[1]), 0);
  const canvasHeight = yScale(colorScale.domain()[0]) - canvasOffset;
  const domain = colorScale.domain();
  const colorScaleMax = domain[domain.length - 1];
  const colorScaleMin = domain[0];


  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!context) {
      return;
    }

    for (let i = 0; i < canvasHeight; ++i) {
      const value = ((colorScaleMax - colorScaleMin)/(canvasHeight - 0))*(i - 0) + colorScaleMin;
      context.fillStyle = colorScale(value);
      context.fillRect(0, canvasHeight-i, width, 1);
    }
  }, [canvasHeight]);

  useEffect(() => {
    if(colorScaleMaxOverride !== userColorScaleMax){
      setUserColorScaleMax(colorScaleMaxOverride)
    }
  }, [colorScaleMaxOverride]);

  useEffect(() => {
    if(colorScaleMinOverride !== userColorScaleMin){
      setUserColorScaleMin(colorScaleMinOverride)
    }
  }, [colorScaleMinOverride]);

  const allTicks = yScale.ticks(3).map((tick, index) => {
    const tickLabel = tickFormat ? tickFormat(tick) : tick;
    return (
      <g key={`${tickLabel}-${index}`}>
        <line
          y1={yScale(tick)}
          y2={yScale(tick)}
          x1={-3}
          x2={width}
          stroke="var(--chart-axis-text-color)"
        />
        <text
          y={yScale(tick) + 3}
          x={-5}
          fontSize={9}
          textAnchor="end"
          className="heatMapAxis"
        >
          {tickLabel}
        </text>
      </g>
    );
  });

  const hoveredValue = interactionData?.value;
  const y = hoveredValue ? yScale(Number(hoveredValue)) : null;
  const X = -40;
  const indicatorWidth = 8;
  const indicatorHeight = 13;
  const indicator =
    y !== undefined ? (
      <polygon
        points={`${X},${y - indicatorHeight / 2} ${
          X + indicatorWidth
        },${y} ${X},${indicatorHeight / 2 + y}`}
        fill="grey"
      />
    ) : null;

  function onMouseMove(e){
    if(sliderSelected.current === null){
      return;
    }

    const offset = Math.max(e.nativeEvent.clientY- containerRef.current.getBoundingClientRect().y, 0);
    const  value = yScaleInverse(offset - topGap);
    const separation = 20;

    if(sliderSelected.current === 0){
      const result = value >= min ? value : min;

      if(yScale(result) > (userColorScaleMax ? yScale(userColorScaleMax) : yScale(max)) + separation){
        setUserColorScaleMin(result)
      }
    }else if(sliderSelected.current === 1){
      const result = value <= max ? value : max;

      if(yScale(result) < (userColorScaleMin ? yScale(userColorScaleMin) : yScale(min)) - separation){
        setUserColorScaleMax(result)
      }
    }
  }

  function onInteractionEnded(){
    if(sliderSelected.current === 0){
      onMinChanged(userColorScaleMin)
    }else if(sliderSelected.current === 1){
      onMaxChanged(userColorScaleMax)
    }

    sliderSelected.current = null;
  }

  return (
    <ContainerFullHeight 
      style={{ width: 120 }} 
      onMouseMove={(e) => onMouseMove(e)} 
      onMouseUp={() => onInteractionEnded()}
      onMouseLeave={() => onInteractionEnded()}
      ref={containerRef}
    >
      <div
        style={{
          position: "relative",
          transform: `translate(0px,${topGap}px`,
        }}
      >
        <LegendBackground style={{width, height: height}}>
          <canvas ref={canvasRef} width={width} height={canvasHeight} style={{
          transform: `translate(0px, ${canvasOffset}px)`,
          height: canvasHeight,
          width,
        }}/>
        </LegendBackground>
        <svg
          width={width}
          height={height}
          style={{ position: "absolute", top: 0, left: 0, overflow: "visible" }}
        >
          {allTicks}
          <g className="triangle">{indicator}</g>
        </svg>
      </div>
      <Slider 
        style={{top: yScale(colorScaleMin), left: 30}} 
        onMouseDown={() => {sliderSelected.current = 0}}
        onMouseLeave={(e) => e.stopPropagation()}
      >
        <SliderLabel className='irs-from'> 
        <i className="material-icons">code</i>
        {tickFormat ? tickFormat(colorScaleMin.toFixed(precision)) : colorScaleMin.toFixed(precision)}
        </SliderLabel>
      </Slider>
      <Slider 
        style={{top: Math.max(yScale(colorScaleMax),0), left: 30}}
        onMouseDown={() => {sliderSelected.current = 1}}
        onMouseLeave={(e) => e.stopPropagation()}
      >
        <SliderLabel className='irs-from'> 
        <i className="material-icons">code</i>
        {tickFormat ? tickFormat(colorScaleMax.toFixed(precision)) : colorScaleMax.toFixed(precision)}
        </SliderLabel>
      </Slider>
    </ContainerFullHeight>
  );
};