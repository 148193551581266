export function getLineData(items) {
    if (items.length === 0) {
      return null;
    }
    const itemsSorted = [...items]
      .filter(({ x, y }) => x !== null && y !== null)
      .sort((a, b) => {
        return a.x - b.x;
      });
    if (itemsSorted.length === 0) {
      return null;
    }
  
    const sampleLength = Math.ceil(0.05 * itemsSorted.length);
  
    let linePoints = [];
    let sampleTotX = 0;
    let sampleTotY = 0;
    let sampleSize = 0;
    itemsSorted.forEach((sample, index) => {
      const { x, y } = sample;
      
      if (index % sampleLength === 0 && index !== 0) {
        linePoints.push({
          x: sampleTotX / sampleSize,
          y: sampleTotY / sampleSize,
        });

        sampleTotX = 0;
        sampleTotY = 0;
        sampleSize = 0;
      }
  
      sampleTotX = sampleTotX + x;
      sampleTotY = sampleTotY + y;
      sampleSize = sampleSize + 1;
  
      if (index === itemsSorted.length - 1) {
        linePoints.push({
          x: sampleTotX/sampleSize,
          y: sampleTotY/sampleSize,
        });
      }
    });
  
    return linePoints;
  }