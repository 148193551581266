import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

const ConfigBillingHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="BILLING SYSTEMS INTEGRATION"
      onClose={doClose}
      content={() => (
        <>
          <p>
            This page allows you to configure the Bequant BQN so that it can
            take the subscribers rate plan information from a number of billing
            systems in an automated way.
          </p>
          <p>
            To configure a billing systems, you should first chose it from the
            drop-down list of available billing systems. This will show the
            status of that billing system integration, whether it is enabled or
            disabled at this moment, and will allow enabling it with the
            corresponding switch. Please bear in mind that only one billing
            system integration can be enabled at one time, and that enabling one
            implies disabling the rest. Following are some common parameters for
            all billing systems and and some specific parameters that must be
            configured for each billing system:
          </p>
          <ul>
            <li>
              <b>SERVER</b>: Billing system IP address.
            </li>
            <li>
              <b>SUBSCRIBER-ID-SOURCE</b>: use a parameter obtained from the
              biling system to fill the subscriber session ID, or leave it
              unchanged.
            </li>
            <li>
              <b>AUTOMATIC-CONGESTION-MANAGEMENT</b>: enable/disable ACM in
              subscriber sessions under the control of this billing system.
            </li>
          </ul>
          <p>
            <b>AZOTEL:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Azotel server port (normally 443).
            </li>
            <li>
              <b>USER</b>: username to log into Azotel server.
            </li>
            <li>
              <b>PASSWORD</b>: password to log into Azotel server.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers with customer.status different from
              &quot;current&quot; in Azotel system.
            </li>
            <li>
              <b>CREATE SUBS-GROUPS WITH THE CPE PARENT NICKNAME</b>: Create a subscriber group
              for every CPE PARENT NICKNAME from Azotel
            </li>
          </ul>
          <p>
            <b>POWERCODE:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Powercode server SSH port (normally 22).
            </li>
            <li>
              <b>USER</b>: username to log into Powercode server via SSH.
            </li>
            <li>
              <b>PASSWORD</b>: password to log into Powercode server.
            </li>
            <li>
              <b>MYSQL USER</b>: username to log into MySQL, if differs from
              USER.
            </li>
            <li>
              <b>MYSQL PASSWORD</b>: password to log into MySQL, if differs from
              PASSWORD.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers with customer.status different from
              &quot;Active&quot; in Powercode system.
            </li>
            <li>
              <b>CREATE SUBS-GROUPS WITH THE EQUIPMENT LOCATIONID</b>: Create a subscriber group
              for every EQUIPMENT LOCATIONID from Powercode
            </li>
          </ul>

          <p>
            <b>POWERCODE-API:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Powercode server port for API (normally 444).
            </li>
            <li>
              <b>API KEY</b>: Powercode-API API Key.
            </li>
            <li>
              <b>EQUIPMENT CATEGORY</b>: Powercode-API Equipment Category.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers with customer.status different from "Active" in
              Powercode system.
            </li>
          </ul>
          <p>
            <b>SPLYNX:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Splynx server port for API (normally 443).
            </li>
            <li>
              <b>API KEY</b>: Splynx API Key.
            </li>
            <li>
              <b>API SECRET</b>: Splynx API Secret.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers with customer.blocked equal to "1" in Splynx
              system.
            </li>
            <li>
              <b>IPv6 DUAL-STACK GROUP NAMES</b>: what Splynx parameters to use when creating IPv6 dual-stack groups, if they are created at all.
            </li>
          </ul>
          <p>
            <b>SONAR:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Sonar server port for API (normally 443).
            </li>
            <li>
              <b>API KEY</b>: Sonar API Key.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers. If block selected, which subscribers are blocked
              will be determined by the following elements.
            </li>
            <li>
              <b>
                BLOCKING BASED ON SONAR STATUS/BLOCKING BASED ON SONAR
                DELINQUENCY STATE
              </b>
              : if "Block Inactive/Not Paying Subscribers" is enabled, select
              how we determine if a subscriber needs to be blocked
            </li>
            <li>
              <b>NON-BLOCK STATUS</b>: table with Sonar status which will not be
              blocked (only in case Blocking based on Sonar Status is selected).
              New status labels can be added or removed
            </li>
            <li>
              <b>POLICY NAMES FROM SONAR SERVICE NAMES</b>: if disabled, standard
              unique names will be assigned to policies. If enabled, the policy
              name will be built from the Sonar service name and id
            </li>
            <li>
              <b>CREATE SUBS-GROUPS WITH THE PARENT INVENTORY ITEMS</b>: Create a
              subscriber-group for every parent inventory item from Sonar, using the description.
            </li>
            <li>
              <b>IPv6 DUAL-STACK GROUP NAMES</b>: what Sonar parameters to use when creating IPv6 dual-stack groups, if they are created at all.
            </li>
          </ul>

          <p>
            <b>VISP:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Visp server port for API (normally 443).
            </li>
            <li>
              <b>USER</b>: Visp valid user name.
            </li>
            <li>
              <b>PASSWORD</b>: Visp user password.
            </li>
            <li>
              <b>CLIENT ID</b>: Identifier of Visp client (to obtain API token).
            </li>
            <li>
              <b>CLIENT SECRET</b>: secret of Visp client (to obtain API token).
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or not
               subscribers. If block selected, which subscribers are blocked will be
               determined by the following option.
            </li>
            <li>
              <b>BLOCKING BASED ON</b>: chose whether blocked subscribers are determined
              by VISP Customer Status or by their VISP Package and Service Instance Status
            </li>
            <li>
              <b>CREATE SUBS-GROUPS WITH THE CPE ACCESS POINT IDENTIFIER</b>: Create a subscriber group
              for every CPE Access point identifier from Visp.
            </li>
          </ul>
          <p>
            <b>WISPRO:</b>
          </p>
          <ul>
            <li>
              <b>PORT</b>: Wispro server port for API (normally 443).
            </li>
            <li>
              <b>API KEY</b>: Wispro API Key.
            </li>
            <li>
              <b>BLOCK INACTIVE/NOT PAYING SUBSCRIBERS</b>: whether to block or
              not subscribers with customer.account_status different from
              "Active" in Wispro system.
            </li>
            <li>
              <b>CREATE SUBS-GROUPS WITH CONTRACT NODE NAME</b>: create a subscriber group
              for every contract mode name from Wispro.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to reload the current parameters.
          </p>
          <p>
            Change the parameters and the click Apply to make those changes
            effective.
          </p>
        </>
      )}
    />
  );
};

export default ConfigBillingHelpModal;
