import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import Result from "./Result";
import {
  parseSystemCPUUsage,
} from "./api.js";
import ActionsContext from "common/ActionsContext";
import useDebouncer from "common/hooks/debouncer";

const ViewContainer = styled.div`
  min-height: 50vh;
  max-height: 90vh;
  &.card {
    height: 100%;
  }
`;

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={() => {
            actions.send("do-load");
          }}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
  }
`;

export const cpuUsageRetrieval = () =>
  ifCl.run("show system cpu").then(parseSystemCPUUsage).then( cpus => ({cpus}));

const CPUStatus = () => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    setRequest(cpuUsageRetrieval());
  };
  const debounce = useDebouncer(500 /*ms*/);


  useEffect(() => doLoad() || actions.recv("do-load", () => debounce(doLoad)), []);

  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">CPU Usage</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Request during={request}>
            {(response) => <Result {...response} />}
          </Request>
        </div>
      </div>
    </ViewContainer>
  );
};

export default CPUStatus;
