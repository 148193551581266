import React, { useState, useEffect, useContext } from "react";
import { WEEKDAYS } from "../../api";
const doesNothing = () => {};

const RangeDays = ({ value = "", onChange = doesNothing, disabled = true }) => {
  const doToggle = (event) => {
    const toggled = event.target.value;
    const values = value.length === 0 ? [] : value.split(", ");
    const newValues = values.includes(toggled)
      ? values.filter((current) => current !== toggled)
      : [...values, toggled];
    onChange(newValues.join(", "));
  };
  const choices = WEEKDAYS;
  const groups = [
    choices.slice(0, -2), // Labour days
    choices.slice(-2), // Weekend
  ];
  return (
    <div
      className="btn-toolbar nowrap align-center0"
      role="toolbar"
      aria-label="Range active days"
    >
      {groups.map((group, index) => (
        <div
          className={`btn-group nowrap ${disabled ? "disabled" : ""}`}
          role="group"
          key={index}
        >
          {group.map((day) => (
            <button
              value={day}
              key={day}
              alt={day}
              type="button"
              onClick={disabled ? doesNothing : doToggle}
              aria-label={day}
              disabled={disabled}
              className={`btn btn-${
                (value.includes(day) && "primary") || "default"
              } waves-effect`}
            >
              {day[0].toUpperCase()}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};
export default RangeDays;
