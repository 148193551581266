import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SysStatsCpuHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="CPU Usage Over Time"
      onClose={doClose}
      content={() => (
        <>
        <p>CPU usage as a percentage of total capacity.</p>
        <p>Chart selectors:</p>
        <ul>
          <li><i class="material-icons valign-icon">zoom_out_map</i> Selects the vertical axis scale. "Full" shows up to 100%, "adaptive" will adapt to the maximum CPU usage value.</li>
          <li><i class="material-icons valign-icon">timeline</i> Selects the type of chart (explained above).</li>
            <ul>
              <li><b>Busy</b>: Overall CPU usage (complement of Idle).</li>
              <li><b>Idle</b>: Overall CPU available (complement of busy).</li>
              <li><b>System</b>: Kernel-space CPU usage (operating system).</li>
              <li><b>User</b>: User-space CPU usage (applications).</li>
              <li><b>Soft-IRQ</b>: CPU usage to handle network port interruptions.</li>
            </ul>
          <li><i class="material-icons valign-icon">memory</i> Selects which CPU to show (all by default).</li>
          <li><i class="material-icons valign-icon">date_range</i> Selects the range of time values shown starting from now: 1 day, 1 week or 1 month. The slider at the bottom of the graph allows refining the range of date values shown: a shorter interval by dragging any of the dated labels and, once done, by dragging the central part.</li>
        </ul>

        <p>Click on the coloured dots on the top-right corner of the graph to optionally select or de-select which CPUs are shown.</p>
        <p>Double-click on the coloured dots on the top-right corner of the graph to optionally isolate the selected CPU.</p>
        <p>Click on the <i class="material-icons valign-icon">refresh</i> icon to refresh the CPU usage.</p>

        </>
      )}
      onClose={doClose}
    />
  );
};
export default SysStatsCpuHelpModal;
