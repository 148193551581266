/* globals showModalConfirm */
import React, { useContext } from "react";
import { parseMode, composeApplyCommand } from "./api";
import { parseManagementInterface } from "views/Config/Interfaces/Management/api";
import SettingsView from "common/SettingsView";
import useProfile from "common/hooks/useProfile";
import Radio from "common/Radio.jsx";
import ActionsContext from "common/ActionsContext";
import ManagementBypassConfigHelpModal from "./Help";
import styled from "styled-components";
import Devices from "./Devices";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getMode = () => ifCl.run("show high-availability").then(parseMode);

const getManagementBypassSettings = () => getMode();

class RequiresConfirmation extends Error {
  constructor(reason) {
    super();
    this.reason = reason;
  }
}
class UserDeclined extends Error {}

const confirmWarning = (message, content) => {
  const done = new Promise((resolve) =>
    $("#modalConfirm").on("hidden.bs.modal", resolve)
  );
  return new Promise((resolve, reject) =>
    showModalConfirm(
      message,
      content,
      "warning",
      () => done.then(() => resolve(true)),
      () => done.then(() => reject(new UserDeclined(content))),
      "OK",
      "CANCEL"
    )
  );
};

const ManagementBypassContextMenu = ({ doLoad = doesNothing }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const mayRequireConfirmation = (warning) => {
  console.error("mayRequireConfirmation", warning);
  if (warning instanceof RequiresConfirmation) {
    return confirmWarning("Do you want to proceed?", warning.reason);
  }
  throw warning;
};

const checkForChanges = (previous, { mode, ...settings }) => {
  if (previous.mode === mode) {
    return { mode, ...settings };
  }
  if (mode === "disabled") {
    const reason = `You are about to set the device to Disabled mode.
      All configuration about the device will be lost and the mode of the device will be undetermined.
      It is not recommended.`;
    throw new RequiresConfirmation(reason);
  }
  if (mode === "normal") {
    const reason = `You are about to set the device to Normal mode. 
      The device will divert traffic through the BQN server and activate the bypass when a
      failure is detected.`;
    throw new RequiresConfirmation(reason);
  }
  if (mode === "bypass") {
    const reason = `You are about to set the device to Forced Bypass.
     The device will activate the bypass path and the BQN server will not process any traffic`;
    throw new RequiresConfirmation(reason);
  }
  return true;
};
const passInterface = (result) => ({ interface: result.interface });
const getManagementInterface = () =>
  ifCl
    .run("show interface management detail")
    .then(parseManagementInterface)
    .then(passInterface);

const doChecksOnBeforeProceed = (originalSettings, settings) => (commands) =>
  Promise.resolve(commands)
    .then(() => checkForChanges(originalSettings, settings))
    .catch(mayRequireConfirmation)
    .then(() => commands);

const ManagementBypassConfig = () => {
  const load = () => getManagementBypassSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    if (error instanceof UserDeclined) {
      return { error: null };
    }
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(getManagementInterface)
      .then((interfaceSettings) =>
        composeApplyCommand(originalSettings, {
          ...interfaceSettings,
          ...settings,
        })
      )
      .then(doChecksOnBeforeProceed(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={ManagementBypassContextMenu}
      title="External Bypass Devices"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
      id="viewInterfacesBypassConf"
    >
      <ShowManagementBypassSettings />
    </SettingsView>
  );
};

const modeOptions = [
  { value: "normal", label: "Normal", radioClassName: "radio-col-green" },
  { value: "bypass", label: "Forced Bypass", radioClassName: "radio-col-blue" },
  { value: "disabled", label: "Disabled", radioClassName: "radio-col-grey" },
];

const ModeOption = styled.div`
  display: inline-block;
  label {
    font-size: 14px !important;
  }
`;
const ShowManagementBypassSettings = ({
  defaults,
  state,
  onStateUpdate = doesNothing,
}) => {
  const { isOperator } = useProfile() || {};

  const onModeChange = (mode) => {
    onStateUpdate({ mode });
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <form>
            <h5>Mode</h5>
            <Radio
              options={modeOptions}
              name="mode"
              defaultValue={defaults.mode}
              itemWrapper={ModeOption}
              onChange={(event) => onModeChange(event.target.value)}
              disabled={isOperator}
            />
          </form>
          {state.mode === "disabled" ? null : <Devices />}
        </div>
      </div>
      <ManagementBypassConfigHelpModal />
    </>
  );
};

export default ManagementBypassConfig;
