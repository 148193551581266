import React, { useContext } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/Hourly/component";
import ActionsContext from "common/ActionsContext";
import SubscribersHourlyVolumeHelpModal from "./Help";
import { parsePoliciesFlows } from "../api";
import { safeStr } from "common/api";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const doFlowsShareRetrieval = (hours = 24, type = "flow") =>
  ifCl.run(`show statistics policy ${safeStr(type)} speed lines ${hours * 12}`);

const retrieval = ({ hours, type }) =>
  doFlowsShareRetrieval(hours, type)
    .then(parsePoliciesFlows)
    .catch((error) => console.error(error) || error);

const enabled = {
  hours: true,
  directions: false,
  categories: false,
  target: false,
  output: false,
  filter: false,
};

const FlowsPerLPolicyHourly = () => {
  const actions = useContext(ActionsContext);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Flows per policy over time</h2>
          <ContextMenu/>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            from="timeline"
            itemsFrom="policies"
            itemsValueFrom="flows"
            yAxisUnits="Flows"
            yAxisPrecision={0}
            provider={retrieval}
            actions={actions}
            enabled={enabled}
          />
        </div>
        <SubscribersHourlyVolumeHelpModal />
      </div>
    </div>
  );
};

export default FlowsPerLPolicyHourly;
