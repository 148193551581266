import React from "react";
import TextInput from "common/TextInput";
import SelectInput from "common/SelectInput";
import PasswordInput from "common/PasswordInput";

const vispBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer ID", value: "customer-id" },
  { label: "MAC", value: "mac" },
  { label: "User name", value: "username" },
  { label: "First+Last name", value: "nickname" },
];

const cliValueToValue = {
  'user-name': 'username',
  'calling-station-id': 'mac',
  'login': 'nickname'
}

const getVISPDefaultSubscriberIdSource = ({subscriberIdSource}) => 
   subscriberIdSource ==="n/a" || subscriberIdSource === undefined ? "unchanged" :
   cliValueToValue[subscriberIdSource] || subscriberIdSource

const vispBlockingOptions = [
  { label: "Customer status", value: true },
  { label: "Package & Service Instance Status", value: false },
];

const VispBillingSettingsInputs = ({ onChange, defaults = {} }) => (
  <>
    <TextInput
      type="text"
      label="User:"
      name="username"
      className="input-group"
      defaultValue={defaults.username}
      onChange={onChange}
    />
    <PasswordInput
      defaultValue={defaults.password}
      className="input-group"
      name="password"
      onChange={onChange}
    />
    <TextInput
      defaultValue={defaults.clientid}
      className="input-group"
      name="clientid"
      label="Client ID"
      onChange={onChange}
    />
    <PasswordInput
      defaultValue={defaults.clientsecret}
      className="input-group"
      name="clientsecret"
      label="Client Secret"
      onChange={onChange}
    />
    <SelectInput
      label="Subscriber-ID source"
      name="subscriberIdSource"
      className="input-group"
      onChange={onChange}
      defaultValue={getVISPDefaultSubscriberIdSource(defaults)}
      options={vispBillingSubscribers}
    />
    <SelectInput
      label="Blocking based on"
      name="vispBlockingBasedOnStatus"
      className="input-group"
      onChange={onChange}
      defaultValue={defaults.vispBlockingBasedOnStatus || false}
      options={vispBlockingOptions}
    />
  </>
);

export default VispBillingSettingsInputs;
