import React from "react";
import TimeInput from "./TimeInput";
import RangeDays from "./RangeDays";

export const EditRanges = ({ ranges = [], onChange }) => {
  const removeRangeAt = (position) => {
    onChange([...ranges].filter((item, index) => index !== position));
  };
  const updateRangeAt = (position, update) => {
    onChange(
      [...ranges].map((item, index) =>
        index !== position ? item : { ...item, ...update }
      )
    );
  };
  return (
    <table
      className="ranges table table-striped no-footer"
      aria-describedby="Ranges of time"
    >
      <thead>
        <tr>
          <th width="110" className="align-right">
            Starts
          </th>
          <th width="110" className="align-right">
            Ends
          </th>
          <th className="text-center">Days</th>
          <th width="30" className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {ranges.map(({ days, starts, ends, __id }, index) => (
          <tr key={__id}>
            <td className="align-right">
              <TimeInput
                defaultValue={starts}
                onChange={(starts) => updateRangeAt(index, { starts })}
              />
            </td>
            <td className="align-right">
              <TimeInput
                defaultValue={ends}
                onChange={(ends) => updateRangeAt(index, { ends })}
              />
            </td>
            <td className="align-center">
              <RangeDays
                value={days}
                disabled={false}
                onChange={(days) => updateRangeAt(index, { days })}
              />
            </td>
            <td className="align-center">
              <button
                type="button"
                className="btn btn-default btn-circle waves-effect borderless"
                onClick={() => removeRangeAt(index)}
              >
                <i className="material-icons">delete</i>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
