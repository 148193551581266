import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const HourlyVolumeDPIHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="FLOWS PER POLICY OVER TIME"
      onClose={doClose}
      content={() => (
        <>
          <p>Flows per policy over time.</p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by moving the central part.
            </li>
          </ul>
          <p>
            Double-click on the coloured dots on the top-right corner of the
            graph to optionally isolate the selected policy.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh information.
          </p>
        </>
      )}
    />
  );
};
export default HourlyVolumeDPIHelpModal;
