import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import Fields from "./Fields";

const doesNothing = () => {};

const EditClient = ({
  others = [],
  doSave = doesNothing,
  doValidate = doesNothing,
}) => {
  const actions = useContext(ActionsContext);
  const [clientEdition, setClientEdition] = useState(null);
  const showClientEdition = (client) => setClientEdition(client);
  const hideClientEdition = () => setClientEdition(null);

  useEffect(() => {
    actions.recv("edit-client", (client) => showClientEdition(client));
  }, []);

  return (
    <React.Fragment>
      {clientEdition !== null ? (
        <EditClientModal
          active={clientEdition !== null}
          client={clientEdition}
          others={others}
          doSave={doSave}
          doValidate={doValidate}
          onClose={hideClientEdition}
        />
      ) : null}
    </React.Fragment>
  );
};

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};

const EditClientModal = ({
  doValidate = doesNothing,
  doSave = doesNothing,
  onClose = doesNothing,
  client,
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const doClose = () => setOpen(false);

  if (open === false) {
    return null;
  }

  const handleChange = () => {
    try {
      const currently = getFormData(form.current);
      const { __id, address } = client;
      doValidate({ __id, address, ...currently });
      setError(null);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    try {
      const currently = getFormData(form.current);
      const { __id, address } = client;
      doSave({ __id, address, ...currently, updated: true });
      setError(null);
      doClose();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Modal
      title="Edit Client"
      superIcon="edit"
      large={false}
      content={() => (
        <form ref={form}>
          <Fields onChange={handleChange} {...client} />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

export default EditClient;
