/* globals login */

import React, { useContext } from "react";
import ProfileContext from "common/ProfileContext";

const useProfile = () => {
  const profile = useContext(ProfileContext)
  return profile;
}

export default useProfile;
