/*globals views, components, functionality*/
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { safeStr } from "common/api";

const createPlaceholder = () =>
  `ADDRESS         SUBSCRIBER-ID            ACTIVE`;

const ensurePlaceholder = () => (_error) => {
  return { response: createPlaceholder() };
};

const doIpAddressesRequest = (subscriberGroup) =>
  ifCl.run(`show api subscriber-group name ${safeStr(subscriberGroup)} active`)
  .then((response) => { return {response}})
  .catch(ensurePlaceholder());

const ResultsLayout = styled.div`
  margin-left: 5px;
  flex: 100%;
  overflow: hidden; /*list will scroll*/
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  --is-highest: #3d85c6;
  --is-higher: #6aa84f;
  --is-lower: #e69138;
  --is-lowest: #cc0000;
  --warning: #cc0000;
  .inverted {
    --is-lowest: #3d85c6;
    --is-lower: #6aa84f;
    --is-higher: #e69138;
    --is-highest: #cc0000;
  }
  .dataTable tbody td {
    transition: color 1s, background-color: 1s;
  }
  &.colorize-warnings .dataTable tbody td {
    &.warning {
      color: white;
      background: var(--warning);
    }
  }
`;

const ActiveIpAddressesDatatable = ({ request }) => {
  const openSubscriberDetails = (target) => {
    const returnTo = 'viewStatusSubscriberGroupsEdit';
    views.doKeep(returnTo);
    globalNavigate("viewSubsDetail", { returnView: returnTo, ...target});
  };
  
  return <TableContainer
    className={"colorize-warnings"}
  >
    <Request during={request}>
      {({response}) => (
        <DataTable
          pageLength={25}
          exportAsCSV={true}
          content={response}
          columns={[
            Column.Text({
              label: "ADDRESS",
              idx: 0,
              title: "Address",
              cellClassName: "hyperlink-text",
              onClick: (addr) => openSubscriberDetails({ addr }),
            }),
            Column.Text({
              label: "ACTIVE",
              idx: 2,
              title: "Active",
            }),
          ]}
        />
      )}
    </Request>
  </TableContainer>
};

export const ActiveIpAddressesTable = ({subscriberGroup}) => {
  const [request, setRequest] = useState(null);
  const actions = useContext(ActionsContext);

  const doLoad = () => setRequest(doIpAddressesRequest(subscriberGroup));

  useEffect(() => {
    doLoad();
    return actions.recv("do-load-subscriber-groups-edit", doLoad);
  }, []);
  
  return request === null ? null : (
      <ResultsLayout>
        <ActiveIpAddressesDatatable request={request} />
      </ResultsLayout>
  );
};
