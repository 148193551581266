import React, { useContext } from "react";
import styled from "styled-components";
import Status from "./Status";
import ActionsContext from "common/ActionsContext";
import LinkStateHelpModal from "./Help";
import DetailModal from "./Detail";
import CaptureModal from "./Capture";
import { parseInterfaces } from "./api";

const requestFactsAndMoment = () =>
  ifCl
    .run("show interface raw")
    .then(parseInterfaces)
    .then((interfaces) => ({ interfaces }));

const ContextMenu = ({ goBack = null }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      {goBack === null ? null : (
        <a
          onClick={goBack}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Back to list"
        >
          <i className="material-icons">
            <small>exit_to_app</small>
          </i>
        </a>
      )}
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const LinkState = ({ returnView }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card container-lg">
        <HeaderDiv className="header block-header">
          <h2 className="title">Network Interfaces</h2>
          <ContextMenu goBack={returnView || null} />
        </HeaderDiv>
        <div className="body flex-panel-column padding-20 expect-overflow">
          <Status provider={requestFactsAndMoment} />
        </div>
      </div>
      <CaptureModal />
      <DetailModal />
      <LinkStateHelpModal />
    </div>
  );
};

export default LinkState;
