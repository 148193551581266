import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const FlowsPerProtocolHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Active Flows Over Time"
      onClose={doClose}
      content={() => (
        <>
          <p>Active connections over time, broken down by type:</p>
          <ul>
            <li>
              <b>TCP-Handshake</b>: TCP connections being established (in
              initial handshake).
            </li>
            <li>
              <b>TCP-Established</b>: TCP connections established and being
              optimized.
            </li>
            <li>
              <b>TCP-Tracking</b>: TCP connections established and not being
              optimized (simply tracked to obtained metrics).
            </li>
            <li>
              <b>TCP-Other</b>: Rest of TCP connections (for example, being
              finished or not being optimized nor tracked).
            </li>
            <li>
              <b>UDP-All</b>: All of UDP flows.
            </li>
            <li>
              <b>IPOther-All</b>: Rest of IP flows.
            </li>
          </ul>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i class="material-icons valign-icon">date_range</i> Selects the
              range of time values shown starting from now: 1 day, 1 week or 1
              month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part.
            </li>
          </ul>

          <p>
            Click on the coloured dots on the top-right corner of the graph to
            optionally select and de-select which types are shown.
          </p>
          <p>
            Double-click on the coloured dots on the top-right corner of the
            graph to optionally isolate the selected type.
          </p>
          <p>
            Click on the <i class="material-icons valign-icon">refresh</i> icon
            to refresh the TCP connections values.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default FlowsPerProtocolHelpModal;
