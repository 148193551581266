import React from "react";

export const Tab = ({ label, children, id = undefined , active=false}) => (
  <div id={id || label} role="tabpanel" className={`tab-pane fade in ${active ? 'active': ''}`}>
    {children}
  </div>
);

const Tabs = ({ children }) => (
  <>
    <ul
      className="nav nav-tabs tab-nav-right"
      role="tablist">
      {children.map(({props}, index) => (
        <li
          key={props.id || index}
          role="presentation"
          data-toggle="tab"
          className={props.active === true ? "active" : ""}
        >
          <a href={`#${props.id}`} data-toggle="tab" >
            {props.label} {props.active}
          </a>
        </li>
      ))}
    </ul>
    <div className={`tab-content`}>
      {children}
    </div>
  </>
);

export default Tabs;
