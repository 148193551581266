import { getKeysSeries, parseDate } from "../utils";

export const parseStatsRtxData = (response, period, beginRange, endRange) => {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const keysSeries = getKeysSeries(header);
  const newKeys = ["time", ...keysSeries];

  if (rows.length === 0) {
    return { fields: keysSeries, items: [] };
  }

  const newRows = rows.reduce((acc, value) => {
    const [timeAt, ...attrs] = value.split(/\s+/);
    const time = parseDate(timeAt);
    if (beginRange < time && time < endRange) {
      const item = attrs.reduce(
        (acc, value, index) => {
          acc[newKeys[index + 1]] = value === "n/a" ? null : Number(value);
          return acc;
        },
        { time }
      );
      acc.push(item);
    }
    return acc;
  }, []);

  return { fields: keysSeries, items: newRows };
};