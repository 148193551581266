import React from "react";
import {
  parseFromTabs,
  composeApplyCommand,
  configToForm,
  defaultPort,
  isTypeSubscriberGroupDataAllowed,
} from "./api";

import SwitchInput from "common/SwitchInput";
import BillingProviderSettings, { billingProviders } from "./Providers";
import TextInput from "common/TextInput";
import NumberInput from "common/NumberInputWithSpinner";
import SelectInput from "common/SelectInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import ConfigBillingHelpModal from "./Help";
import SettingsView from "common/SettingsView";

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const dualStackSubscriberGroupOptions = {
  splynx: [
    { label: "No IPv6 dual-stack groups", value: "disabled" },
    { label: "Username", value: "username" },
    { label: "Login", value: "login" },
    { label: "Customer-Id", value: "customer-id" },
    { label: "Username + Customer-Id", value: "username + customer-id" },
    { label: "Login + Customer-Id", value: "login + customer-id" },
  ],
  sonar: [
    { label: "No IPv6 dual-stack groups", value: "disabled" },
    { label: "Name", value: "name" },
    { label: "Customer-Id", value: "customer-id" },
    { label: "Name + Customer-Id", value: "name + customer-id" },
  ]
};

const AutoCongestionManagementInput = ({
  onChange,
  defaults = {},
  className,
}) => (
  <>
    <SwitchInput
      defaultChecked={defaults.acmEnabled}
      className={`input-group margin-t-5 ${className ?? ""}`}
      name="acmEnabled"
      label={(checked) =>
        `Automatic-Congestion-Management: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onChange}
    />
  </>
);

const RateLimitInput = ({
  name = "rateLimitPercentage",
  onChange,
  defaults = {},
  className,
}) => (
  <>
    <NumberInput
      max={200}
      min={1}
      defaultValue={defaults[name] || 100}
      className={`input-group ${className ?? ""}`}
      name={name}
      label="Rate-Limit Scaling (%)"
      onChange={onChange}
    />
  </>
);

const subscriberGroupDataLabelsForProvider = {
  'azotel': "Create subs-groups with the CPE parent nickname",
  'powercode': "Create subs-groups with the Equipment LocationID",
  'sonar': "Create subs-groups with the parent inventory items",
  'visp': "Create subs-groups with the CPE access point identifier",
  'wispro': "Create subs-groups with contract node name",
}

const getSGProviderLabel = provider =>
  subscriberGroupDataLabelsForProvider[provider] || 'Obtain subscriber-group information'

const SubscriberGroupDataInput = ({
  onChange,
  state = {},
  defaults = {},
  className,
}) =>
  isTypeSubscriberGroupDataAllowed(state.type) ? (
    <SwitchInput
      defaultChecked={defaults.subscriberGroupData}
      className={`input-group margin-t-5 ${className ?? ""}`}
      name="subscriberGroupData"
      label={(checked) =>
        `${getSGProviderLabel(state.type)}: ${checked ? "enabled" : "disabled"}`
      }
      onChange={onChange}
    />
  ) : (
    <div></div>
  );

const BlockSubscribersInput = ({ onChange, defaults = {}, className }) => (
  <>
    <SwitchInput
      defaultChecked={defaults.blocking}
      className={`input-group ${className ?? ""}`}
      name="blocking"
      label={(checked) =>
        `Block Inactive/Not Paying Subscribers: ${
          checked ? "enabled" : "disabled"
        }`
      }
      onChange={onChange}
    />
  </>
);

const ServerAndPortSettingsInput = ({ onChange, defaults = {}, state }) => (
  <>
    <TextInput
      type="text"
      name="server"
      label="Server:"
      defaultValue={defaults.server}
      onChange={onChange}
      className="input-control"
    />
    <TextInput
      type="text"
      name="port"
      label="Port:"
      placeHolder={defaultPort(state.type)}
      className="input-control"
      defaultValue={defaults.port}
      onChange={onChange}
    />
  </>
);

const EnabledBillingSettings = ({
  onChange,
  defaults = {},
  state = {},
  ...settings
}) => (
  <>
    {state.type !== undefined ? (
      <>
        <ServerAndPortSettingsInput
          onChange={onChange}
          defaults={defaults}
          state={state}
        />
      </>
    ) : null}
    <BillingProviderSettings
      onChange={onChange}
      defaults={defaults}
      state={state}
      {...settings}
    />
    <AutoCongestionManagementInput
      onChange={onChange}
      defaults={defaults}
      state={state}
      className="input-group align-top"
    />
    {state.type === 'splynx' && 
      <SelectInput
        label="IPv6 dual-stack group name"
        name="dualStackSubscriberGroup"
        className="input-group"
        onChange={onChange}
        defaultValue={defaults.dualStackSubscriberGroup}
        options={dualStackSubscriberGroupOptions.splynx}
      />
    }
    <SubscriberGroupDataInput
      onChange={onChange}
      defaults={defaults}
      state={state}
      className="input-group align-top"
    />
    {state.type === 'sonar' && 
      <SelectInput
        label="IPv6 dual-stack group name"
        name="dualStackSubscriberGroup"
        className="margin-b-20"
        onChange={onChange}
        defaultValue={defaults.dualStackSubscriberGroup}
        options={dualStackSubscriberGroupOptions.sonar}
      />
    }
  </>
);

const BillingSettings = ({
  onChange,
  defaults = {},
  state = {},
  ...settings
}) => (
  <ArrangeInColumns columns={2} rowGap={0}>
    <SwitchInput
      name="enabled"
      className="input-group billing-view"
      defaultChecked={defaults.enabled}
      onChange={onChange}
      label={`Integration with billing system: ${
        state.enabled ? "enabled" : "disabled"
      }`}
    />
    {state.enabled === true ? (
      <>
        <div />
        <SelectInput
          name="type"
          className="input-group"
          onChange={onChange}
          title="Billing"
          defaultValue={defaults.type || "azotel"}
          options={billingProviders}
          label="Billing type:"
        />
        <div />
        <RateLimitInput
          onChange={onChange}
          defaults={defaults}
          state={state}
          className="margin-b-20"
        />
        <BlockSubscribersInput
          onChange={onChange}
          defaults={defaults}
          state={state}
          className="input-group"
        />
        <EnabledBillingSettings
          onChange={onChange}
          defaults={defaults}
          state={state}
          {...settings}
        />
      </>
    ) : null}
  </ArrangeInColumns>
);

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const convertOnOffToBoolean = (value, defaultValue = undefined) =>
  value === undefined && defaultValue !== undefined
    ? defaultValue
    : typeof value === "boolean"
    ? value
    : value === "on";

const convertTrueFalseToBoolean = (value, defaultValue = undefined) =>
  value === undefined && defaultValue !== undefined
    ? defaultValue
    : typeof value === "boolean"
    ? value
    : value === "true";

const ConfigBilling = () => {
  const load = () =>
    ifCl
      .run("show api billing parameters")
      .then(parseFromTabs)
      .then(configToForm);
  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };
  const normalizeForm = ({
    enabled,
    acmEnabled,
    blocking,
    sonarDelinquent,
    sonarPolicyName,
    vispBlockingBasedOnStatus,
    subscriberGroupData,
    ...settings
  }) => {
    const result = {
      enabled: convertOnOffToBoolean(enabled),
      acmEnabled: convertOnOffToBoolean(acmEnabled),
      blocking: convertOnOffToBoolean(blocking),
      subscriberGroupData: convertOnOffToBoolean(subscriberGroupData),
      sonarDelinquent: convertTrueFalseToBoolean(sonarDelinquent),
      sonarPolicyName: convertOnOffToBoolean(sonarPolicyName),
      vispBlockingBasedOnStatus: convertTrueFalseToBoolean(
        vispBlockingBasedOnStatus
      ),
      ...settings,
    };
    return result;
  };

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () =>
      composeApplyCommand(originalSettings, normalizeForm(settings));

    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };

  return (
    <SettingsView
      load={load}
      apply={apply}
      clear={clear}
      title="Billing systems integrations"
      applyActionClassName="hidden-to-operators"
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <BillingSettings />
      <ConfigBillingHelpModal />
    </SettingsView>
  );
};

export default ConfigBilling;
