import React, { useRef, useState } from "react";
import styled from "styled-components";

const PasswordInputDiv = styled.div``;

const MaskDiv = styled.div`
  position: absolute;
  z-index: 9;
  top: 9px;
  left: 15px;
  font-size: 12px;
  letter-spacing: 2px;
  width: 90%;
  overflow: hidden;
`;

const Input = styled("input")(({hidden}) => ({
  color: hidden ? 'transparent !important' : "var(--Text-Primary)",
}));

const PasswordInput = ({
  onChange,
  name,
  label = "Password",
  disabled = false,
  className = "",
  defaultValue,
}) => {
  const [hidden, setHidden] = useState(defaultValue?.length > 0);
  const toggleVisiblity = () => setHidden((previous) => !previous);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <PasswordInputDiv className="input-group">
      <span className="input-group-addon">
        <label className="margin-0">{label}:</label>
      </span>
      <div className="form-line">
        <Input
          type="text"
          name={name}
          onKeyUp={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          className="form-control"
          hidden={hidden}
          ref={inputRef}
          onClick={() => {
            setHidden(false);
          }}
        />
        {hidden && defaultValue && 
          <MaskDiv onClick={() => {
            setHidden(false);
            if(inputRef.current){
              inputRef.current.focus();
            }
            }}> 
            { new Array(defaultValue.length).fill('*').join('') } 
          </MaskDiv>
        }
      </div>
      <span className="input-group-addon">
        <i className="material-icons hyperlink" onClick={toggleVisiblity}>
          {hidden ? 'visibility' : 'visibility_off'}
        </i>
      </span>
    </PasswordInputDiv>
  );
};

export default PasswordInput;
