import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";

const COLOR_LEGEND_MARGIN = { top: 0, right: 0, bottom: 30, left: 0 };

const LegendBackground = styled("div")(() => ({
  backgroundColor: 'var(--Timeline-Background)'
}));

const Slider = styled("div")(() => ({
  height: 50,
  backgroundColor: 'transparent',
  position: 'absolute',
  top: 15,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
}));

const SliderLabel = styled("div")(() => ({
  position: 'relative',
  height: 15,
  userSelect: 'none',
  cursor: 'pointer',
}));

export const ColorLegend = ({
  height,
  colorScale,
  width,
  interactionData,
  unit,
  max,
  onMaxChanged,
  onMinChanged,
  showUnit,
  precision,
  onInteractionEnded,
  tickFormat,
}) => {
  const boundsWidth =
    width - COLOR_LEGEND_MARGIN.right - COLOR_LEGEND_MARGIN.left;
  const boundsHeight =
    height - COLOR_LEGEND_MARGIN.top - COLOR_LEGEND_MARGIN.bottom
  const canvasRef = useRef(null);
  const sliderSelected = useRef(null);
  const containerRef = useRef(null);
  const sliderRightRef = useRef(null);
  const sliderLeftRef = useRef(null);
  const xScale = d3.scaleLinear().range([0, boundsWidth]).domain([0, max]);
  const xScaleInverse = d3.scaleLinear().range([0, max]).domain([0, boundsWidth]);
  const canvasOffset = xScale(colorScale.domain()[0])
  const canvasWidth = xScale(colorScale.domain()[1]) - canvasOffset;
  const [canvasWidthInitial, setCanvasWidthInitial] = useState(0);
  const domain = colorScale.domain();
  const colorScaleMax = domain[domain.length - 1];
  const colorScaleMin = domain[0];

  const allTicks = xScale.ticks(3).map((tick) => {
    return (
      <React.Fragment key={xScale(tick)}>
        <line
          x1={xScale(tick)}
          x2={xScale(tick)}
          y1={0}
          y2={boundsHeight + 10}
          stroke="var(--chart-axis-text-color)"
        />
        <text
          x={xScale(tick)}
          y={boundsHeight + 20}
          fontSize={9}
          textAnchor="middle"
          className="heatMapAxis"
        >
          {tickFormat ? tickFormat(tick) : tick}
        </text>
      </React.Fragment>
    );
  });

  const unitLabel = (
    <text
      x={boundsWidth + 40}
      y={boundsHeight + 20}
      fontSize={9}
      textAnchor="left"
      className="heatMapAxis"
    >
      {unit}
    </text>
  );

  const hoveredValue = interactionData?.value;
  const x = hoveredValue ? xScale(hoveredValue) : null;
  const triangleWidth = 9;
  const triangleHeight = 6;
  const triangle = x ? (
    <polygon
      points={`${x},0 ${x - triangleWidth / 2},${-triangleHeight} ${
        x + triangleWidth / 2
      },${-triangleHeight}`}
      fill="grey"
    />
  ) : null;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!context) {
      return;
    }

    for (let i = 0; i < canvasWidthInitial; ++i) {
      const value = ((colorScaleMax - colorScaleMin)/(canvasWidthInitial - 0))*(i - 0) + colorScaleMin;
      context.fillStyle = colorScale(value);
      context.fillRect(i, 0, 1, boundsHeight);
    }
  }, [canvasWidthInitial]);

  useEffect(() => {
    if(canvasWidth > 0 && canvasWidthInitial === 0  ){
      setCanvasWidthInitial(canvasWidth);
    }
  }, [canvasWidth])

  function onMouseMove(e){
    if(sliderSelected.current === null){
      return;
    }

    const offset = e.nativeEvent.clientX- containerRef.current.getBoundingClientRect().x;
    const  value = xScaleInverse(offset);

    if(value < 0 || value > max ){
      sliderSelected.current = null;
      onInteractionEnded();
      return;
    }

    if(sliderSelected.current === 0 && value >= 0 && value < colorScaleMax){
      onMinChanged(value)
    }else if(sliderSelected.current === 1 && value <= max && value > colorScaleMin){
      onMaxChanged(value)
    }
  }

  return (
    <div 
      style={{ width, height: 100, paddingTop: 40, position: 'relative', userSelect: 'none' }} 
      onMouseMove={(e) => onMouseMove(e)} 
      onMouseUp={() => {
        sliderSelected.current = null;
        onInteractionEnded();
      }}
      onMouseLeave={() => {
        sliderSelected.current = null;
        onInteractionEnded();
      }} 
      ref={containerRef}
    >
      <div
        style={{
          position: "relative",
          transform: `translate(${COLOR_LEGEND_MARGIN.left}px,
            ${COLOR_LEGEND_MARGIN.top}px`,
        }}
      >
        <LegendBackground style={{width: boundsWidth, height: boundsHeight}}>
          <canvas ref={canvasRef} width={canvasWidthInitial} height={boundsHeight} style={{
          transform: `translate(${canvasOffset}px, -2px)`,
          height: boundsHeight,
          width: canvasWidth,
        }}/>
        </LegendBackground>
        <svg
          width={boundsWidth}
          height={boundsHeight}
          style={{ position: "absolute", top: 0, left: 0, overflow: "visible" }}
        >
          {allTicks}
          {showUnit && unitLabel}
          {triangle}
        </svg>
      </div>
      <Slider 
        ref={sliderLeftRef}
        style={{left: xScale(colorScaleMin)- (sliderLeftRef.current ? sliderLeftRef.current.getBoundingClientRect().width/2 : 0)}} 
        onMouseDown={() => {sliderSelected.current = 0}}
        onMouseLeave={(e) => e.stopPropagation()}
      >
        <SliderLabel className='irs-from'> {colorScaleMin.toFixed(precision)}</SliderLabel>
      </Slider>
      <Slider 
        ref={sliderRightRef}
        style={{left: xScale(colorScaleMax) - (sliderRightRef.current ? sliderRightRef.current.getBoundingClientRect().width/2 : 0)}} 
        onMouseDown={() => {sliderSelected.current = 1}}
        onMouseLeave={(e) => e.stopPropagation()}
      >
        <SliderLabel className='irs-from'> {colorScaleMax.toFixed(precision)}</SliderLabel>
      </Slider>
    </div>
  );
};

export default ColorLegend;
