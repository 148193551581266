import React from "react";

const doesNothing = () => {};

const DeleteRangeButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const RangeItem = ({
  id,
  range = "",
  from = undefined,
  deleted,
  even = false,
  onDelete = doesNothing,
}) => {
  if (deleted === true) {
    return null;
  }
  const doRangeDelete = () => {
    onDelete(id);
  };

  return (
    <React.Fragment>
      <tr className={`${even ? "even" : "odd"}`}>
        <td>
          {range}
          {"  "}
          {from === undefined ? null : (
            <span className="col-red">
              Suggested to allow traffic from {from}
            </span>
          )}
        </td>
        <td className="text-center hides-content-to-operators">
          <DeleteRangeButton onClick={doRangeDelete} />
        </td>
      </tr>
    </React.Fragment>
  );
};
const RangesList = ({
  items = [],
  onDelete = doesNothing,
  onEdit = doesNothing,
}) =>
  items.length === 0 ? (
    <h5>No firewall rules configured: all IP addresses allowed.</h5>
  ) : (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>ALLOWED IP RANGES</th>
          <th className="text-center">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {items
          .filter(({ deleted }) => deleted !== true)
          .map(({ __id, ...client }, index) => (
            <RangeItem
              id={__id}
              key={__id}
              {...client}
              even={(index + 1) % 2 === 0}
              onDelete={() => onDelete(__id)}
              onEdit={() => onEdit(__id)}
            />
          ))}
      </tbody>
    </table>
  );
export default RangesList;
