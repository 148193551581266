import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import * as d3 from "d3";
import {
  parseProtocolsVolume,
  getFieldsOnDirection,
  getLicenseLimit,
} from "./api";
import RangeSliderInput from "common/RangeSliderInput";
import NVStackedAreaChart from "common/graphs/NVStackedAreaChart";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {hoursScopeChoices} from "common/constants";

const doesNothing = () => {};

const intervalForHours = (hours, interval = 10) =>
  hours === 24 ? 2 : hours === 24 * 30 ? 4 : interval;

const _protocolsChoices = [
  { value: "none", label: "All" },
  { value: "l3proto", label: "L3-Protocol" },
  { value: "l4proto", label: "L4-Protocol" },
];

const _directionChoices = [
  { value: "all", label: "Uplink+Downlink" },
  { value: "both", label: "Both" },
  { value: "downlink", label: "Downlink" },
  { value: "uplink", label: "Uplink" },
];

const ProtocolsThroughputContextControlsLayout = styled.div`
  & .input-group {
  }
`;

const ProtocolsThroughputContextControls = ({
  hours,
  directions,
  protocols,
  className,
  onChange = doesNothing,
  children,
}) => {
  const handleChange = ({target}) => {
    const {value, name} = target;
    switch (name) {
      case "Date Range":
        const hours = parseInt(target.value);
        onChange({ hours, interval: intervalForHours(hours) });
        break;
      case "Direction":
        onChange({directions: value});
        break;
      case "Protocols":
        onChange({protocols: value});
        break;
      default:
        break;
    }
  };
  return (
    <ProtocolsThroughputContextControlsLayout>
      <ColumnsGrid
        columns={6}
        className={className}
        minWidth="15rem"
        rowGap="0"
      >
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={hours}
          onChange={handleChange}
          options={hoursScopeChoices}
        />
        <SelectInputWithIcon
          title="Direction"
          name="Direction"
          icon="import_export"
          selected={directions}
          onChange={handleChange}
          options={_directionChoices}
        />
        <SelectInputWithIcon
          title="Protocols"
          name="Protocols"
          icon="format_line_spacing"
          selected={protocols}
          onChange={handleChange}
          options={_protocolsChoices}
        />
      </ColumnsGrid>
      {children}
    </ProtocolsThroughputContextControlsLayout>
  );};

const calcTimeRange = (items) => {
  const [from, to] = d3.extent(items.map((item) => item.time));
  return { from, to };
};

const BigGraph = styled.div`
  & {
    display: flex;
    &.big {
      div,
      svg {
        height: 15cm !important;
        width: 100%;
      }
    }
    &.split {
      div,
      svg {
        width: 100%;
      }
    }
  }
  @media print {
    width: 100% !important;
    overflow: hidden;
  }
`;

const UsageGraphLayout = styled.div`
  h4 {
    text-align: center;
  }
`;
const threeDecimals = d3.format(".3f");

const UsageGraph = ({
  fields,
  thresholds,
  items,
  range,
  splitted = false,
  title = null,
}) => (
  <UsageGraphLayout className="group margin-t-15">
    {title === null ? null : <h4 className="chart-title" style={{ marginTop: 20 }}>{title}</h4>}
    <BigGraph className={`${splitted ? "split" : "big"} graph`}>
      <NVStackedAreaChart
        fields={fields}
        items={items.filter(isInsideRange(range))}
        thresholds={thresholds}
        xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
        xField="time"
        yTooltipFormat={(value) => `${threeDecimals(value)} Mbps`}
        yAxisUnits="Mbps"
        yAxisTopGap={0.05}
      />
    </BigGraph>
  </UsageGraphLayout>
);

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const ProtocolsThroughputResult = ({ items, groups, ...rest }) => {
  const initialRange = calcTimeRange(items);
  const expandRange = () => {
    setRange(calcTimeRange(items));
  };
  const [range, setRange] = useState(null);
  const applyRange = (newRange) => setRange(newRange);
  useEffect(expandRange, [items]);
  return range === null ? null : (
    <>
      {[
        ...groups.map(({ fields, directions, thresholds, title = null }) => (
          <UsageGraph
            key={directions}
            fields={fields}
            items={items}
            thresholds={thresholds}
            range={range}
            splitted={groups.length > 1}
            title={title}
            {...rest}
          />
        )),
        null,
      ]}
      <div style={{marginTop: 17}}></div>
      
      <RangeSliderInput
        {...initialRange}
        className="full-width"
        onChange={applyRange}
      />
    </>
  );
};

const hourlyInteval = (hours) =>
  hours === 24 ? 5 : hours === 24 * 30 ? 60 : 10;

const doVolumeRetrieval = (hours = 72, protocols = "none") =>
  ifCl.run(
    `show statistics volume mbps-max hours ${hours} interval ${hourlyInteval(
      hours
    )} grouped-by ${protocols}`
  );

const retrieval = ({ hours, protocols }) => doVolumeRetrieval(hours, protocols);

const requestLicenseLimit = () =>
  ifCl.run("show license").then(getLicenseLimit);

const sumFieldsTotal = (total, [field, value]) =>
  field === "time" ? total : total + value;

const getRecordTotal = (record) =>
  Object.entries(record).reduce(sumFieldsTotal, 0);

const getMaxTotal = (items) => d3.max(items.map(getRecordTotal));

const parseFieldsAndItems = (directions) => (response) => {
  const items = parseProtocolsVolume(response);
  return directions === "both"
    ? {
        items,
        groups: [
          {
            title: "Downlink",
            directions: "downlink",
            fields: getFieldsOnDirection("downlink")(response),
          },
          {
            title: "Uplink",
            directions: "uplink",
            fields: getFieldsOnDirection("uplink")(response),
          },
        ],
      }
    : directions === "all"
    ? requestLicenseLimit()
        .then((limit) => ({
          items,
          groups: [
            {
              directions,
              thresholds:
                limit === null || limit > getMaxTotal(items) * 1.2
                  ? []
                  : [{ value: limit, label: "License" }],
              fields: getFieldsOnDirection(directions)(response),
            },
          ],
        }))
        .catch((error) => console.error(error))
    : {
        items,
        groups: [
          { directions, fields: getFieldsOnDirection(directions)(response) },
        ],
      };
};

const _defaultContext = {
  hours: 24,
  protocols: "none",
};

const _defaultLocalContext = {
  directions: "both",
};

const ProtocolsThroughputRequest = ({
  hours,
  protocols,
  directions,
  ...rest
}) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    const doingRetrieval = retrieval({ hours, protocols });
    setRequest(doingRetrieval);
  };
  useEffect(
    () => doLoad() || actions.recv("do-load", () => doLoad()),
    [hours, protocols]
  );
  return request === null ? null : (
    <Request during={request.then(parseFieldsAndItems(directions))}>
      {(result) => <ProtocolsThroughputResult {...result} {...rest} />}
    </Request>
  );
};

const ProtocolsThroughputContext = () => {
  const [context, setContext] = useState(_defaultContext);
  const [localContext, setLocalContext] = useState(_defaultLocalContext);
  const updateContext = ({ hours, protocols, directions, ...context }) =>
    directions === undefined
      ? setContext((prev) => ({
          ...prev,
          ...(hours === undefined ? {} : { hours }),
          ...(protocols === undefined ? {} : { protocols }),
          ...context,
        }))
      : setLocalContext((prev) => ({
          ...prev,
          ...{ directions },
        }));

  return (
    <ProtocolsThroughputContextControls
      {...context}
      {...localContext}
      onChange={updateContext}
    >
      <ProtocolsThroughputRequest {...context} {...localContext} />
    </ProtocolsThroughputContextControls>
  );
};
export default ProtocolsThroughputContext;
