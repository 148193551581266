import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const StatsLatencyHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Latency Over Time"
      onClose={doClose}
      content={() => (
        <>
          <p>Average latency, measured with TCP acknowledgements.</p>
          <ul>
            <li>
              <b>Rtt-Access</b>: Average latency (Round-Trip-Time) between the
              BQN and the subscribers on the acess side.
            </li>
          </ul>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <i class="material-icons valign-icon">date_range</i> Selects the
              range of time values shown starting from now: 1 day, 1 week or 1
              month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part.
            </li>
          </ul>

          <p>
            Click on the <i class="material-icons valign-icon">refresh</i> icon
            to refresh the data.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default StatsLatencyHelpModal;
