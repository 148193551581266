import React, { useRef } from "react";
import NumberInput from "common/NumberInputWithSpinner";
import { getFormData, rankAndParseFields } from "./utils";

const SynSettings = ({
  onChange,
  defaults = {},
  isOperatorProfile = false,
}) => {
  const form = useRef(null);
  const onInputChange = () => {
    const values = getFormData(form.current);
    onChange(rankAndParseFields(values, defaults));
  };
  return (
    <form ref={form}>
      <h2 className="card-inside-title">SYN Attacks</h2>
      <p>
        In order to detect SYN DoS attacks, failed TCP handshake Downlink Rate
        and Uplink Rate thresholds have to be specified. A{" "}
        <span className="col-red">zero</span> value (click the reset default
        icon) in each parameter will disable the corresponding functionality.
      </p>
      <NumberInput
        name="handshakeFailRateDown"
        label="Downlink failed handshake rate"
        defaultValue={defaults.handshakeFailRateDown}
        onChange={onInputChange}
        className="input-control align-left"
        autocomplete="off"
        units="SYN/Sec"
        min={0}
        step={1}
        hideArrows={false}
        disabled={isOperatorProfile}
        backspaceValue={0}
      />
      <NumberInput
        name="handshakeFailRateUp"
        label="Upwnlink failed handshake rate"
        defaultValue={defaults.handshakeFailRateUp}
        onChange={onInputChange}
        className="input-control align-left"
        autocomplete="off"
        units="SYN/Sec"
        min={0}
        step={1}
        hideArrows={false}
        disabled={isOperatorProfile}
        backspaceValue={0}
      />
    </form>
  );
};

export default SynSettings;
