import React, { useState } from "react";
import TextInput from "common/TextInput";

const AskName = ({ name, onChange, onApply, disabled = false }) => (
  <TextInput
    type="text"
    name="name"
    label="Add Non-Block Status:"
    className="input-control align-right"
    defaultValue={name}
    onChange={onChange}
    disabled={disabled}
    addon={
      <a
        href="#"
        onClick={onApply}
        className={`action shaded`}
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="Add name"
      >
        <i className="material-icons actions-icon-color">add</i>
      </a>
    }
  />
);

const doesNothing = () => {};

const CreateStatus = ({ doValidate = doesNothing, doSave = doesNothing }) => {
  const [status, setStatus] = useState({ name: "" });
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    try {
      const name = event.target.value;
      const status = { name };
      doValidate(status);
      setError(null);
      setStatus(status);
    } catch (error) {
      console.error(error);
      setError(String(error));
    }
  };

  const doApply = () => {
    try {
      doValidate(status);
      doSave(status);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <AskName
        name={status.name}
        onChange={handleChange}
        onApply={doApply}
        error={error}
      />
      {error !== null && (
        <div className="color-red align-center margin-t-5 margin-b-5">{error}</div>
      )}
    </>
  );
};
export default CreateStatus;
