import React, { useState, useEffect } from "react";
import SpinnerInput from "common/SpinnerInput.jsx";
import styled from "styled-components";
import useFirstTime from "common/hooks/firstTime";

const doesNothing = () => {};

const splitHourMinute = (input) => {
  const [hour, minute] = input.split(":");
  return { hour, minute };
};

const TimeInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const TimeInput = ({ defaultValue = "00:00", onChange = doesNothing }) => {
  const [time, setTime] = useState(splitHourMinute(defaultValue));
  const isFirstTime = useFirstTime();
  const updateField = (field) => (source) => {
    const value = source.target === undefined ? source : source.target.value;
    setTime({ ...time, [field]: parseInt(value, 10) });
  };
  useEffect(() => {
    isFirstTime() ||
    onChange(
      `${String(time.hour).padStart(2, "0")}:${String(time.minute).padStart(
        2,
        "0"
      )}`
    );
  }, [time]);
  return (
    <TimeInputWrapper className="form-line inline hour-and-minute nowrap">
      <SpinnerInput
        defaultValue={time.hour}
        onChange={updateField("hour")}
        size="2"
        min={0}
        max={23}
        type="number"
        className="form-control"
        placeHolder="hours (00-23)"
      />
      <SpinnerInput
        defaultValue={time.minute}
        onChange={updateField("minute")}
        size="2"
        min={0}
        max={59}
        type="number"
        className="form-control"
        placeHolder="minutes (00-59)"
      />
    </TimeInputWrapper>
  );
};

export default TimeInput;
