import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

const ConfigBillingHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Management Interface Firewall"
      onClose={doClose}
      content={() => (
        <>
          <p>List of allowed ip ranges for management interface:</p>
          <ul>
            <li>
              <b>ALLOWED IP RANGES</b>: Allowed IP ranges.
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on this IP address range (e.g.
              delete).
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete an an already configured IP address range.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add IP Address Range...</b> option to
            add a new IP address range.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh IP address information.
          </p>
        </>
      )}
    />
  );
};

export default ConfigBillingHelpModal;
