import React, { useRef, useEffect } from "react";

const genUnique = () => (Math.random() + 1).toString(36).substring(7);

const FoldablePanel = ({
  opened = false,
  title = "title",
  className,
  children,
  onChange = () => {},
}) => {
  const content = useRef(null);
  const unique = useRef("unique-" + genUnique());
  useEffect(() => {
    $(content.current).collapse(opened ? "show" : "hide");
  }, [content]);
  useEffect(() => {
    if (content.current === null) { return; }
    $(content.current).on('hidden.bs.collapse', () => onChange(false))
    $(content.current).on('shown.bs.collapse', () => onChange(true))
  }, [content.current])
  return (
    <div className={`panel panel-default ${className}`}>
      <div className="panel-heading" role="tab">
        <h4 className="panel-title font-size-14">
          <a
            id={`collapsable-parent-${unique.current}`}
            role="button"
            data-toggle="collapse"
            data-parent={`#collapsable-parent-${unique.current}`}
            href={`#collapsable-content-${unique.current}`}
            aria-expanded={opened}
            aria-controls={`collapsable-content-${unique.current}`}
            className="collapsed-arrow"
          >
            {title} {opened}
          </a>
        </h4>
      </div>
      <div
        ref={content}
        id={`collapsable-content-${unique.current}`}
        className={`panel-collapse collapse ${opened === true ? "in" : ""}`}
        role="tabpanel"
        aria-labelledby="headingOne_2"
        aria-expanded={opened ? "true" : "false"}
        style={{ height: opened ? undefined : "0px" }}
      >
        <div className="panel-body">{children}</div>
      </div>
    </div>
  );
};

export default FoldablePanel;
