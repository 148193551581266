const parseKeys = (head, translations = {}) =>
  head
    .split(/\s+/)
    .map((key) =>
      translations[key] !== undefined ? translations[key] : key.toLowerCase()
    );

const fieldAndValue = (field, value, transform) =>
  field === null ? [] : [[field, transform ? transform(value) : value]];

const translations = {
  INTERFACE: "name",
  "PKTS-RX-Kpps": null,
  "BYTES-RX-Mbps": "rx",
  "PKTS-TX-Kpps": null,
  "BYTES-TX-Mbps": "tx",
};

const asFloat = (value) => parseFloat(value);

const throughputTransform = {
  2: asFloat,
  4: asFloat,
};

const throughput = (row, keys, transform = throughputTransform) =>
  Object.fromEntries(
    row
      .trim(" ")
      .split(/\s+/)
      .flatMap((value, index) =>
        fieldAndValue(keys[index], value, transform[index])
      )
  );

export const parseThroughputUsage = (input) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");

  if (lines.length === 0) {
    return null;
  }
  const [head, ...rows] = lines;
  const keys = parseKeys(head.trim(" "), translations);
  return rows.map(
    (row) => (row.length > 0 ? throughput(row, keys) : null) // empty line
  );
};
