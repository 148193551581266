import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import * as d3 from "d3";
import RangeSliderInput from "common/RangeSliderInput";
import NVStackedAreaChart, {
  tooltipContentGenerator,
} from "common/graphs/NVStackedAreaChart";
import { expressTarget } from "common/api";
import DPILatencies from "../DPI/Latency/Result/index";
import { expressLatencyTarget } from "../DPI/Latency/api";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {hoursScopeChoices, categoryChoices} from "common/constants";
import { parseServiceLatencies } from "../DPI/Latency/api";
import { parseShareInSpeed } from "../DPI/api";
import { getServiceFields, servicesSpeedAsFields } from "./api";
import { useSearchParams } from "react-router-dom";

const doesNothing = () => {};

const intervalForHours = (hours, interval = 10) =>
  hours === 24 ? 2 : hours === 24 * 30 ? 4 : interval;

export const doStatsRetrieval = ({
  target,
  facet = "volume",
  hours = 24,
  interval = 10,
}) =>
  expressTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics subscribers ${facet} ${expressedTarget} hours ${hours} interval ${interval}`
    )
  );

const _directionChoices = [
  { value: "all", label: "Both." },
  { value: "uplink", label: "Uplink" },
  { value: "downlink", label: "Downlink" },
];

const hoursMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30
}

const DPIAnalyticsContextControlsLayout = styled.div`
  & .input-group {
    max-width: 15rem;
  }
`;

const DPIAnalyticsContextControls = ({
  range,
  hours,
  categories,
  directions,
  className,
  onChange = doesNothing,
  children,
}) => {
  const handleChange = ({target}) => {
    const {value, name} = target;
    switch (name) {
      case "Date Range":
        onChange({hours: parseInt(value)});
        break;
      case "Categories":
        onChange({categories: value});
        break;
      case "Direction":
        onChange({directions: value});
        break;
      default:
        break;
    }
  };

  return (
    <DPIAnalyticsContextControlsLayout>
      <ColumnsGrid
        columns={3}
        className={"no-print " + className ?? "no-print"}
        minWidth="15rem"
        rowGap={"0"}
      >
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={hours}
          onChange={handleChange}
          options={hoursScopeChoices}
        />
        <SelectInputWithIcon
          title="Categories"
          name="Categories"
          icon="format_line_spacing"
          selected={categories}
          onChange={handleChange}
          options={categoryChoices}
        />
        <SelectInputWithIcon
          title="Direction"
          name="Direction"
          icon="import_export"
          selected={directions}
          onChange={handleChange}
          options={_directionChoices}
        />
      </ColumnsGrid>
      {children[0]}
      <RangeSliderInput
        {...range}
        className="full-width no-print dpi-fixed-volume"
        onChange={onChange}
      />
      {children[1]}
    </DPIAnalyticsContextControlsLayout>
  );};

const calcTimeRange = (items) => {
  const [from, to] = d3.extent(items.map((item) => item.time));
  return { from, to };
};

const BigGraph = styled.div`
  min-height: 9cm;
  display: flex;
  flex-flow: column nowrap;
  @media print {
    width: calc(100% + 0.55cm) !important;
    overflow: hidden;

    svg .nv-focus {
      transform: translate(0px, 10px);
    }
  }
`;

const UsageGraphLayout = styled.div`
  min-height: 9cm;
  display: flex;
  flex-flow: column nowrap;
  .dpi-usage-chart {
    flex: 100%;
    .legends {
      flex: none;
    }
  }
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
`;

const ContextMenuDiv = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: end;
  position: absolute;
  right: 20px;
  top: 20px;

  i {
    color: #999;
    font-size: 20px;

    &:hover {
      color: #000;
    }
  }
`;

const TotalVolumeTitle = styled.div`
  margin-bottom: 0;
  text-align: var(--chart-title-text-align, center);
  color: var(--chart-title-color, #232325);
  font-size: 18px;
  text-transform: var(--chart-title-text-transform, capitalize);
  font-weight: 500;

  h4 {
    padding: 0;
  }
`;

const TotalVolumeVsDPI = ({ total = "--", dpi = "--" }) => {
  return (
    <TotalVolumeTitle className="align-left">
      <h4 className="chart-title">
        Total volume: {total} GB - Dpi sample: {dpi} GB
      </h4>
    </TotalVolumeTitle>
  );
};

const UsageGraph = ({ items, range }) => {
  return (
    <UsageGraphLayout className="group dpi chart-container">
      <NVStackedAreaChart
        className="dpi-usage-chart"
        items={servicesSpeedAsFields(items.filter(isInsideRange(range)))}
        xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
        xField="time"
        fields={getServiceFields(items)}
        tooltipContentGenerator={tooltipContentGenerator}
        yAxisUnits="Mbps"
        yAxisTopGap={0.05}
      />
      <TotalVolumeVsDPI {...mbToGb(sumTotalAndDPI(items))} />
    </UsageGraphLayout>
  );
};

const mbToGb = ({ total, dpi }) => ({
  total: (total / 1000).toFixed(2),
  dpi: (dpi / 1000).toFixed(2),
});

const sumTotalAndDPI = (items) =>
  items.reduce(
    (prev, { total, dpi }) => ({
      total: prev.total + total,
      dpi: prev.dpi + dpi,
    }),
    { total: 0, dpi: 0 }
  );

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const DPIAnalyticsResult = ({
  target,
  speeds,
  latencies=[],
  latenciesAsContent="",
  systemDate,
  interval = 10,
  hours = 24,
  directions = "all",
  categories = 5,
  onContextChange = doesNothing,
}) => {
  const initialRange = calcTimeRange(speeds);
  const [range, setRange] = useState(initialRange);
  const applyRange = (newRange) => setRange(newRange);
  const applyChange = ({ hours, directions, categories, ...range }) => {
    if (hours || directions || categories) {
      onContextChange({ hours, directions, categories });
    } else {
      applyRange(range);
    }
  };
  const context = { target, interval, hours, directions, categories };
  return (
    <div className="dpi-fixed-content">
      <h4 className="subtitle">DPI Service breakdown over time</h4>
      <DPIAnalyticsContextControls
        systemDate={systemDate}
        range={initialRange}
        hours={hours}
        categories={categories}
        directions={directions}
        onChange={applyChange}
        className="dpi-fixed-controls"
      >
        <UsageGraph items={speeds} range={range} />
        <DPILatencies latencies={latencies} range={range} {...context} />
      </DPIAnalyticsContextControls>
    </div>
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
  categories: 10,
  directions: "all",
};

const doLatenciesRetrieval = (target, hours = 72, categories = 20) =>
  console.warn('doLatenciesRetrieval', target ) ||
  expressLatencyTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics dpi latency top hours ${hours} categories ${
        categories - 1
      }\
     ${expressedTarget}`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const doSpeedShareRetrieval = (
  target,
  hours = 72,
  categories = 20,
  directions = "all"
) =>
  expressTarget(target).then((expressedTarget) =>
    ifCl.run(
      `show statistics dpi speed ${expressedTarget} hours ${hours} interval 60 categories ${
        categories - 1
      } direction ${directions} interval-speed 10`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const retrieval = ({ target, hours, categories, directions }) =>
  Promise.all([
    doSpeedShareRetrieval(target, hours, categories, directions).then(
      parseShareInSpeed
    ),
    doLatenciesRetrieval(target, hours, categories, directions).then(
      parseServiceLatencies
    ),
  ]).then(([speeds, latencies]) => {
    return {
      speeds,
      latencies,
    };
  });

const DPIAnalyticsContext = ({ target }) => {
  const [request, setRequest] = useState(null);

  let [searchParams, _] = useSearchParams();

  const [context, setContext] = useState({..._defaultContext, hours: hoursMap[searchParams.get('timePeriod')] ?? _defaultContext.hours});

  const updateContext = ({ hours, categories, directions, ...context }) =>
    setContext((prev) => ({
      ...prev,
      ...(hours === undefined
        ? {}
        : { hours, interval: intervalForHours(hours) }),
      ...(categories === undefined ? {} : { categories }),
      ...(directions === undefined ? {} : { directions }),
      ...context,
    }));
  const doLoad = (params = { target, ...context }) => {
    const doingRetrieval = retrieval({ facet: "volume", ...params });
    setRequest(doingRetrieval);
  };

  useEffect(() => doLoad(), [JSON.stringify(target), JSON.stringify(context)]);

  return request === null ? null : (
    <>
      <Request during={request}>
        {(result) => (
          <div className="dpi-fixed">  
            <ContextMenuDiv className="context-menu">
              <a
                onClick={() => doLoad()}
                data-toggle="cardloading"
                data-loading-effect="pulse"
                title="Refresh"
              >
                <i className="material-icons">refresh</i>
              </a>
            </ContextMenuDiv>
            <DPIAnalyticsResult
              {...result}
              {...context}
              target={target}
              onContextChange={updateContext}
            />
          </div>
        )}
      </Request>
    </>
  );
};
export default DPIAnalyticsContext;
