import React, { useRef } from "react";
import NumberInput from "common/NumberInput";
import { getFormData } from "./utils";
import TextInput from "common/TextInput";
import styled from "styled-components";

const AskGatewayAddress = ({ defaults, onChange, disabled = false }) => (
  <TextInput
    type="text"
    name="gateway"
    label="IP Address:"
    className="input-control"
    defaultValue={defaults.gateway}
    onChange={onChange}
    disabled={disabled}
    placeholder={"Ex: 10.1.0.1"}
  />
);

const AskDNSAddress = ({ defaults, onChange, disabled = false }) => (
  <TextInput
    type="text"
    name="nameserver"
    label="IP Address:"
    className="input-control"
    defaultValue={defaults.nameserver || ""}
    onChange={onChange}
    disabled={disabled}
    placeholder={"Ex: 10.1.0.2"}
  />
);

const AskAddressAndMaskLayout = styled.div`
  display: flex;
  flew-flow: row nowrap;
  .align-left.hide-arrows {
    flex: 9ch 0 0;
  }
`;

const AskAddressAndMask = ({ defaults, onChange, disabled = false }) => (
  <AskAddressAndMaskLayout>
    <TextInput
      type="text"
      name="address"
      label="IP Address:"
      className="input-control"
      defaultValue={defaults.address}
      onChange={onChange}
      disabled={disabled}
      placeholder={"Ex: 10.1.0.2"}
    />
    <NumberInput
      name="mask"
      label={() => (
        <span className="label-wrapper input-group-addon padding-t-0">/</span>
      )}
      className="input-control align-left"
      defaultValue={defaults.mask}
      onChange={onChange}
      autocomplete="off"
      min={0}
      max={255}
      step={1}
      precision={0}
      hideArrows={true}
      disabled={disabled}
    />
  </AskAddressAndMaskLayout>
);

const DownlinkVolumeSettings = ({
  onChange,
  defaults = {},
  isOperatorProfile = false,
}) => {
  const form = useRef(null);
  const onInputChange = () => {
    const values = getFormData(form.current);
    onChange(values);
  };
  return (
    <form ref={form}>
      <h2 className="card-inside-title">Static IP address and mask</h2>
      <p>Management network static IP address and mask.</p>
      <AskAddressAndMask
        defaults={defaults}
        onChange={onInputChange}
        disabled={isOperatorProfile}
      />
      <h2 className="card-inside-title">Default gateway</h2>
      <p>
        Optiona. Defaylt gateway and static IP address must be in the same
        subnet.
      </p>
      <AskGatewayAddress
        defaults={defaults}
        onChange={onInputChange}
        disabled={isOperatorProfile}
      />
      <h2 className="card-inside-title">DNS server</h2>
      <p>
        Optiona. If DNS server and static IP address are not in the same subnet,
        default gateway is mandatory
      </p>
      <AskDNSAddress
        defaults={defaults}
        onChange={onInputChange}
        disabled={isOperatorProfile}
      />
    </form>
  );
};

export default DownlinkVolumeSettings;
