import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="DoS VOLUME ATTACKS OVER TIME"
      onClose={doClose}
      content={() => (
        <>
          <p>Detected DoS volume attacks.</p>
          <ul>
            <li>
              <b>TIME</b>: Time of detected attack.
            </li>
            <li>
              <b>IP-ADDRESS</b>: IP address being attacked.
            </li>
            <li>
              <b>MBYTES</b>: Total volume in MB received by this IP address (not
              just in this attack).
            </li>
            <li>
              <b>RATE</b>: Attack rate in Mbps.
            </li>
            <li>
              <b>DURATION</b>: Attack duration.
            </li>
            <li>
              <b>TOP-CONTRIBUTOR</b>: Top contributor attacker IP address.
            </li>
            <li>
              <b>TOP-MBYTES</b>: Volume in MB received by this IP address in top
              attack.
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
