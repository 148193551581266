import React, { useState, useContext, useEffect } from "react";
import { expressTarget } from "common/api";
import provideUserFile from "common/ProvideFile";
import { YYYYMMDDHHmmssSSS } from "common/utils";
import { parseSubscriberBandwidthShare } from "../api";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import SelectInputWithIcon from "common/SelectInputWithIcon";

const LabelWithIcon = styled.a`
  white-space: nowrap;
  cursor: pointer;
  display: inline;
  color: #333;
  .material-icons {
    line-height: 1.2em;
    vertical-align: middle;
  }
`;

const createCVSFilename = (prefix = "top-volume-subscribers") =>
  `${prefix}-${YYYYMMDDHHmmssSSS(new Date())}.csv`;

const _no_target = {
  addr: null,
  subsId: null,
};

const parseOptions = { omitTimeline: true, omitTotals: false, precision: 8 };

const renderCSVOutput = ({ subscribers }) =>
  [
    `SUBSCRIBER,GIGABYTES`,
    ...subscribers.map(({ name, volume }) => `${name},${volume.toFixed(8)}`),
  ].join("\n");

const askForGreaterCategories = (actions, categories) =>
  new Promise((resolve, reject) => {
    actions.recv("total-categories-response", (response) => {
      resolve(response === null ? categories : response);
    });
    actions.recv("total-categories-cancel", () => {
      reject();
    });
    actions.send("total-categories-request");
  });

const wholeInterval = (hours, base=60/*minutes*/) =>
  hours * base;

const requestCSVExport = ({
  hours,
  categories,
  output,
  directions,
  target = _no_target,
}) =>
  expressTarget(target)
    .then((expressedTarget) =>
      ifCl.run(
        `show statistics subscribers top ${expressedTarget} ${
          output} direction ${directions} categories ${
            categories} hours ${hours} interval ${wholeInterval(hours)}`
      )
    )
    .then((input) => parseSubscriberBandwidthShare(input, parseOptions))
    .then(({ totals }) => renderCSVOutput(totals))
    .then((content) => provideUserFile(createCVSFilename(), content));

const ExportButton = ({ context, className = "" }) => {
  const actions = useContext(ActionsContext);
  return (
    <>
      <LabelWithIcon
        className={`${className}`}
        onClick={() =>
          askForGreaterCategories(actions, context.categories).then(
            (categories) =>
              console.warn(categories) ||
              requestCSVExport({ ...context, categories })
          )
        }
      >
        <span>Export:</span>
        <i className="material-icons">file_download</i>
      </LabelWithIcon>
      <AksForGreaterCategoriesModal current={context.categories} />
    </>
  );
};

const categoriesLimitOptions = (current) => [
  { value: current, label: `${current} subscribers` },
  { value: 100, label: "100 subscribers" },
  { value: 200, label: "200 subscribers" },
  { value: 300, label: "300 subscribers" },
  { value: 400, label: "400 subscribers" },
  { value: 500, label: "500 subscribers" },
];

const AksForGreaterCategoriesModal = ({ current }) => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState(current);

  const doClose = () => setOpen(false);
  const actions = useContext(ActionsContext);

  const applyChoice = () => {
    actions.send("total-categories-response", categories);
    doClose();
  };
  useEffect(
    () => actions.recv("total-categories-request", () => setOpen(true)),
    []
  );
  const onClose = () => {
    actions.send("total-categories-cancel");
    doClose();
  };
  const onChange = setCategories;

  return open === false ? null : (
    <Modal
      title="How many top subscribers?"
      superIcon="file_download"
      content={() => (
        <SelectInputWithIcon
          title="New subscribers limit"
          name="New subscribers limit"
          selected={categories}
          onChange={({ target }) => {
            onChange(parseInt(target.value));
          }}
          options={categoriesLimitOptions(current)}
        />
      )}
      applyLabel="OK"
      onApply={applyChoice}
      closeLabel="CANCEL"
      onClose={onClose}
    />
  );
};

export default ExportButton;
