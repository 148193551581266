import React, { useState } from "react";
import TextInput from "common/TextInput";

const AskTag = ({ address, onChange, onApply, disabled = false }) => (
  <TextInput
    type="text"
    name="address"
    label="Add this IP address range to bypass:"
    className="input-control align-right"
    defaultValue={address}
    onChange={onChange}
    disabled={disabled}
    placeholder="1.1.1.1/24"
    addon={
      <a
        href="#"
        onClick={onApply}
        className={`action shaded`}
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="Add address"
      >
        <i className="material-icons actions-icon-color">add</i>
      </a>
    }
  />
);

const doesNothing = () => {};

const CreateIPRange = ({ doValidate = doesNothing, doSave = doesNothing }) => {
  const [range, setIPRange] = useState({ address: "" });
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    try {
      const address = event.target.value;
      const range = { address };
      doValidate(range);
      setError(null);
      setIPRange(range);
    } catch (error) {
      setError(error);
    }
  };

  const doApply = () => {
    try {
      doValidate(range);
      doSave(range);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <div>
        <AskTag
          address={range.address}
          onChange={handleChange}
          onApply={doApply}
          error={error}
        />
      </div>
      {error !== null && (
        <div className="modal-err-msg color-red align-left">{error}</div>
      )}
    </>
  );
};
export default CreateIPRange;
