import React, {useContext} from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import StatsRtxHelpModal from "./Help";
import StatsRtxReport from "./Report";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const doesNothing = () => {};

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">
          refresh
        </i>
      </a>
    </ul>
  );
};

const StatsRtx = () => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
     <div className="card">
        <div className="header block-header">
          <h2>Average TCP Retransmissions Over Time</h2>
          <ContextMenu />
        </div>
        <div className="body flex-panel-row">
          <StatsRtxReport />
        </div>
      </div>
      <StatsRtxHelpModal />
    </div>
  );
};

export default StatsRtx;
