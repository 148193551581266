/* global DPI_STATS_TMOUT_MS */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import Result from "./Result";
import {
  parseSystemMemoryUsage,
  parsePoolMemoryUsage,
  parseDPDKMemoryUsage,
} from "./api.js";
import ActionsContext from "common/ActionsContext";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={() => {
            actions.send("do-load");
          }}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};

const HeaderDiv = styled.div`
  display: flex & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
  }
`;

export const memoryUsageRetrieval = () =>
  Promise.all([
    ifCl.run("show system memory").then(parseSystemMemoryUsage),
    ifCl.run("show pkteng memory").then(parsePoolMemoryUsage),
    ifCl.run("show pkteng memory dpdk").then(parseDPDKMemoryUsage),
  ]).then(([System, Mpool, Dpdk]) => ({ System, Mpool, Dpdk }));

const MemoryStatus = () => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    setRequest(memoryUsageRetrieval());
  };

  useEffect(() => doLoad() || actions.recv("do-load", doLoad), []);

  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Memory Usage</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Request during={request}>
            {(response) => <Result {...response} />}
          </Request>
        </div>
      </div>
    </ViewContainer>
  );
};

export default MemoryStatus;
