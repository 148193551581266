import React from "react";
import TextInput from "common/TextInput";
import PasswordInput from "common/PasswordInput";

const AskAddress = ({ address, onChange, disabled = false }) => (
  <TextInput
    type="text"
    name="address"
    label="IP Address:"
    className="input-control"
    defaultValue={address}
    onChange={onChange}
    icon="cloud_queue"
    disabled={disabled}
    placeholder={"Ex: 10.1.0.1 or 2001:db8:80::"}
  />
);

const AskDescription = ({ description, onChange }) => (
  <TextInput
    type="text"
    name="description"
    label="Description:"
    className="input-control"
    defaultValue={description}
    onChange={onChange}
    icon="note"
  />
);

const AskSecret = ({ secret, onChange }) => (
  <PasswordInput
    name="secret"
    label="Secret:"
    className="input-control"
    defaultValue={secret}
    onChange={onChange}
    icon="lock"
  />
);
const Fields = ({
  onChange,
  __id,
  address,
  description,
  secret,
  stored = false,
}) => (
  <>
    <AskAddress address={address} onChange={onChange} disabled={stored} />
    <AskDescription description={description} onChange={onChange} />
    <AskSecret secret={secret} onChange={onChange} />
  </>
);
export default Fields;
