import React from "react";
import FoldablePanel from "common/FoldablePanel";
import { avpNameAsField } from "./api";
import styled from "styled-components";

const doesNothing = () => {};

const AVPFieldSetting = ({
  name,
  columns = [],
  label,
  onChange = doesNothing,
  values = {},
  state,
}) => {
  const updateColumn = (column) => (enabled) =>
    onChange({ ...values, [column]: enabled });
  return (
    <>
      <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 margin-0">
        <span title={name} className="font-bold">
          {label}
        </span>
      </div>
      {columns.map((column) => {
        const isDisabled =
          name === "sessionTimeoutIfZeroAVP" &&
          ((column === "ignore" && state["sessionTimeoutAVP"].ignore) ||
            (column === "remove" && state["sessionTimeoutAVP"].remove));
        return (
          <div key={column} className={columnClassName(columns)}>
            <IconCheckbox
              checked={
                values[column] ||
                (name === "sessionTimeoutIfZeroAVP" &&
                  ((column === "ignore" && state["sessionTimeoutAVP"].ignore) ||
                    (column === "remove" && state["sessionTimeoutAVP"].remove)))
              }
              onChange={isDisabled ? undefined : updateColumn(column)}
              disabled={isDisabled}
            />
          </div>
        );
      })}
    </>
  );
};

const IconCheckbox = ({
  checked = true,
  onChange = doesNothing,
  disabled = false,
}) => {
  const doToggle = () => onChange(checked === false);
  return (
    <RelativeDiv>
      <i
        className="material-icons font-16 hyperlink"
        name={name}
        onClick={doToggle}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        {checked === true ? "check_box" : "check_box_outline_blank"}
      </i>
      {disabled && <GrayDiv />}
    </RelativeDiv>
  );
};
const nameAsLabelFieldAsName = ([name, field]) => ({
  name: field,
  label: name,
});

const AVPSettingsList = Object.entries(avpNameAsField).map(
  nameAsLabelFieldAsName
);
const AVPColumnsForMode = {
  proxy: ["ignore", "remove"],
  server: ["ignore"],
};
const HeaderLayout = styled.div`
  margin: 0.5em 0;
  .capitalize {
    text-transform: capitalize;
  }
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const GrayDiv = styled.div`
  position: absolute;
  background-color: gray;
  width: 9px;
  border-radius: 2px;
  height: 11px;
  top: 2px;
  right: 3px;
  opacity: 0.3;
`;

const columnClassName = (columns = []) =>
  columns.length === 1
    ? "col-lg-4 col-md-4 col-sm-4 col-xs-4 align-right margin-0"
    : "col-lg-2 col-md-2 col-sm-2 col-xs-2 align-right margin-0";

const AVPSettings = ({
  settingsList = AVPSettingsList,
  state = {},
  onChange = doesNothing,
  radiusMode = "server",
  local = {},
  setLocal = () => {},
}) => {
  const onSettingChange = (name) => (value) => {
    const update = { ...state, [name]: value };
    onChange(update);
    return update;
  };
  const columns = AVPColumnsForMode[radiusMode];
  return (
    <FoldablePanel
      title="AVP Selection"
      opened={local.avpFoldedPanelOpen === true}
      onChange={(avpFoldedPanelOpen) =>
        setLocal((previous) => ({ ...previous, avpFoldedPanelOpen }))
      }
    >
      <HeaderLayout className="row">
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 margin-0"></div>
        {columns.map((column) => (
          <div
            key={column}
            className={`padding-0 capitalize bold ${columnClassName(columns)}`}
          >
            {column}
          </div>
        ))}
      </HeaderLayout>
      {settingsList.map(({ name, label }) => (
        <div key={name} className="row">
          <AVPFieldSetting
            columns={columns}
            name={name}
            label={label}
            values={state[name]}
            onChange={onSettingChange(name)}
            state={state}
          />
        </div>
      ))}
    </FoldablePanel>
  );
};

export default AVPSettings;
