import React from "react";
import AzotelSettings from "./Azotel";
import PowercodeSettings from "./Powercode";
import PowercodeAPISettings from "./PowercodeAPI";
import SplynxBillingSettings from "./Splynx";
import VispBillingSettings from "./Visp";
import SonarBillingSettings from "./Sonar";
import WisproBillingSettings from "./Wispro";

export const billingProviders = [
  { label: "Azotel", value: "azotel", settings: AzotelSettings },
  { label: "Powercode", value: "powercode", settings: PowercodeSettings },
  {
    label: "Powercode-API",
    value: "powercode-api",
    settings: PowercodeAPISettings,
  },
  { label: "Sonar", value: "sonar", settings: SonarBillingSettings },
  { label: "Splynx", value: "splynx", settings: SplynxBillingSettings },
  { label: "Visp", value: "visp", settings: VispBillingSettings },
  { label: "Wispro", value: "wispro", settings: WisproBillingSettings },
];

const _DEFAULT_ = 0;

const ProviderSettings = ({
  onChange,
  defaults = {},
  state = {},
  ...settings
}) => {
  const provider =
    billingProviders.find((provider) => provider.value === state.type) ||
    billingProviders[_DEFAULT_];
  const Settings = provider !== undefined ? provider.settings : null;
  return Settings !== null ? (
    <Settings
      onChange={onChange}
      defaults={defaults}
      state={state}
      {...settings}
    />
  ) : null;
};

export default ProviderSettings;
