import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / 100).toFixed(4))
    : parseFloat(value);

export const parseProtocolsVolume = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const keys = head.split(/\s+/).map(asJSAttribute);
  return rows.map((row) => Object.fromEntries(timeAndNumbers(row, keys)));
};

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

const listFieldNameAndLabel = (response) => {
  const [head] = response.trim("\n").split("\n", 1);
  return head
    .split(/\s+/)
    .map((label) => ({ label, name: asJSAttribute(label) }));
};

const polarizeByDirection = (direction) =>
  direction === "all"
    ? (item) => item
    : direction === "downlink"
    ? ({ name, label }) =>
        name.startsWith("up")
          ? null
          : { name, label: label.replace(/^DN-/, "") }
    : ({ name, label }) =>
        name.startsWith("dn")
          ? null
          : { name, label: label.replace(/^UP-/, "") };

const notTimeOrNull = (item) => item !== null && item.name !== "time";

export const getFieldsOnDirection = (direction) => (response) =>
  listFieldNameAndLabel(response)
    .map(polarizeByDirection(direction))
    .filter(notTimeOrNull);

export const getLicenseLimit = (input) => {
  const [_head, values] = input.trim("\n").split("\n");
  const [_feat, _exp, _valid, maxUsage, _static] = values.split(/\s+/);
  return maxUsage === "n/a" ? null : parseInt(maxUsage);
};
