import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

/*****************************************************
 * Help Modal
 * ****************************************************/
const ConfigRADIUSHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="RADIUS API CONFIGURATION"
      onClose={doClose}
      content={() => (
        <>
          <p>
            <i className="material-icons valign-icon">hdr_strong</i> The RADIUS
            switch allows enabling or disabling the RADIUS interface.
          </p>
          <p>
            <i className="material-icons valign-icon">list</i> The RADIUS
            proxy/Accounting server dropdown allows to change the RADIUS mode.
          </p>
          <p>
            <i className="material-icons valign-icon">
              check_box_outline_blank
            </i>{" "}
            The AVP Selection drop-down box includes the specification of which
            RADIUS AVPs to ignore (i.e. not even decode) and, when in Radius
            proxy mode, which AVPs to remove before forwarding the Access-Accept
            Radius messages (except for Mikrotik-Total-Limit and
            Session-Timeout, which will also be removed from Accounting-Request
            messages).
          </p>
          <ul>
            <li>Mikrotik-Rate-Limit: specifies the rate policy.</li>
            <li>
              Mikrotik-Total-Limit: specifies a volume quota (together with
              Mikrotik-Total-Limit-Gigawords).
            </li>
            <li>
              Ascend-Data-Rate: Specifies the rate policy (together with
              Ascend-Xmit-Rate, if present).
            </li>
            <li>Ascend-Xmit-Rate: determines the downlink rate.</li>
            <li>Mikrotik-Address-List: specifies the rate policy.</li>
            <li>Cisco-AVPair-sub-qos-policy: specifies the rate policy.</li>
            <li>
              Class: can determine the rate policy if it contains a "Policy=..."
              string, with the appropriate syntax.
            </li>
            <li>
              Connect-Info: will determine the rate limits according to RFC2869.
            </li>
            <li>NetElastic-Qos-Profile-Name: specifies the rate policy.</li>
            <li>Session-Timeout: specifies a time quota.</li>
            <li>Filter-Id: specifies a subscriber-group.</li>
            <li>Huawei-Output-Peak-Rate: specifies the downlink limit of the rate policy in bps.</li>
            <li>Huawei-Input-Peak-Rate: specifies the uplink limit of the rate policy in bps.</li>
          </ul>
          <p>
            Note on AVP priority: when several AVPs specifying the rate
            policy/limits are present, only the one higher up in the list above
            (and not ignored) will apply.
          </p>
          <p>
            Note AVP format: please refer to online{" "}
            <a href="https://www.bequant.com/docs/radius" target="_blank">
              User Guide
            </a>{" "}
            for details on the format expected in AVPs.
          </p>
          <p>
            The Rate-Limit scaling parameter determines a factor that will be
            applied to the rate limit defined by Radius.
          </p>
          <p>
            When Automatic Congestion Managment is enabled, all policies created
            from RADIUS AVP, with the rate limits set by the RADIS AVP string,
            will have Automatic Congestion Managment enabled. This will not
            apply to the policies selected via RADIUS just by the name (with the
            Mikrotik-List-Address or the Cisco-AVPair-sub-qos-policy AVPs, for
            example), for which you have to go to the Rate Policy definition in
            the GUI.
          </p>
          <p> Server IP address lets set the IP address of the RADIUS proxy.</p>
          <p> Server secret lets you set the secret of the RADIUS proxy.</p>
          <p>
            {" "}
            Server disconnect port specifies the UDP port where RADIUS
            Disconnect messages are expected from the RADIUS server by the BQN
            proxy. The BQN proxy will send those Disconnects transparently to
            the RADIUS clients using that same UDP port.
          </p>

          <p>
            The RADIUS client table includes all RADIUS clients from which the
            BQN will accept RADIUS messages, with each entry having:
          </p>
          <ul>
            <li>
              <b>ADDRESS</b>: RADIUS client IP address.
            </li>
            <li>
              <b>DESCRIPTION</b>: Description.
            </li>
            <li>
              <b>SECRET</b>: Optional secret string.
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on this policy (e.g. delete or
              edit).
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">edit</i> icon
            to edit an already configured radius client entry.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete the corresponding radius client entry.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add Client...</b> option to add a new
            radius client entry.
          </p>
        </>
      )}
    />
  );
};

export default ConfigRADIUSHelpModal;
