/* global functionality */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { expressTarget, safeStr } from "common/api";
import colors from 'common/graphs/colors';
import { useColorMap } from "./livemetrics.utils";
import useProfile from "common/hooks/useProfile";

const HIGHLIGHT_COLOR = "#3d85c6";
const returnTo = "viewSubsDetail";
const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

const doEditPolicy = (policy, target) => {
  ifCl
    .run(`show policy ${safeStr(policy)}`)
    .then((response) => {
      const flowPolicies = rulesFlow.data.policies;
      const policy = flowPolicies.parsePolicy(response);
      flowPolicies.editPolicy(policy, returnTo, target);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;

  @media print {
    width: 40cm;
    transform: scale(0.5, 0.5);
    overflow-x: hidden;
    margin-left: -400px;
    margin-top: -350px;
    .dataTables_length,
    .dataTables_filter {
      display: none;
    }
  }

  table {
    width: 100% !important;
    max-width: 100% !important;
  }

  &.liveMode {
    .dataTables_length,
    .dataTables_filter > label {
      display: none;
    }

    table.dataTable thead {
      .sorting_desc,
      .sorting_asc,
      .sorting {
        background-image: none !important;
      }
    }

    table.dataTable thead th {
      cursor: default;
    }
  }

  .domain-column {
    text-overflow: ellipsis;
    width: 250px !important;
    max-width: 250px;
    overflow: hidden;
  }
`;

const HeaderWithControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h4 {
    margin-top: 0px !important;
  }
`;

export const SubscriberFlowsResult = ({response, liveMode, setLiveMode, pageLength=50, dpiFields, target, dpiEnabled }) => {
  const profile = useProfile() || {};
  const [colorMap, setColorMap] = useColorMap({response, liveMode, dpiFields});

  const columns = useMemo(() => {
    return [
      ...(profile.dpiEnabled === true
        ? [
            Column.TextColor({
              label: "DPI",
              idx: 13,
              color: (value) => colorMap?.[value] || colors[0],
            }),
            Column.Text({
              label: "DOMAIN",
              idx: 12,
              colClassName: "domain-column",
            }),
            Column.Text({
              label: "FLOW-POLICY",
              idx: 11,
              cellClassName: profile.hideRates ? null :  "hyperlink-text",
              onClick: profile.hideRates ? null : (value) => {
                setLiveMode(false);
                doEditPolicy(value, {...target, dpiEnabled});
              },
            }),
          ]
        : []),
      Column.NumberColor({
        label: "MBPS-DN",
        idx: 8,
        precision: 4,
        cellClassName: "align-right",
        color: (value) => (value > 0 ? HIGHLIGHT_COLOR : "transparent"),
      }),
      Column.NumberColor({
        label: "MBPS-UP",
        idx: 9,
        precision: 4,
        cellClassName: "align-right",
        color: (value) => (value > 0 ? HIGHLIGHT_COLOR : "transparent"),
      }),
      Column.Number({
        label: "MBYTES-DN",
        idx: 6,
        precision: 4,
        cellClassName: "align-right",
      }),
      Column.Number({
        label: "MBYTES-UP",
        idx: 7,
        precision: 4,
        cellClassName: "align-right",
      }),
      Column.Text({
        label: "PROTO",
        idx: 0,
      }),
      Column.Text({
        label: "AC-ADDR",
        idx: 1,
      }),
      Column.NumberOrNA({
        label: "APORT",
        idx: 2,
        thousands: false,
        cellClassName: "align-right",
      }),
      Column.IPAddress({
        label: "INET-ADDR",
        idx: 3,
      }),
      Column.NumberOrNA({
        label: "IPORT",
        idx: 4,
        thousands: false,
        cellClassName: "align-right",
      }),
      Column.Text({
        label: "ST",
        idx: 5,
      }),
      Column.Time({
        label: "lifetime",
        idx: 10,
      }),
    ];
  }, [response, setLiveMode, colorMap, profile]);

  return (
    <TableContainer className={liveMode ? "liveMode subscriberflows-table" : "subscriberflows-table"}>
      <DataTable
        pageLength={pageLength}
        content={response}
        exportAsCSV={true}
        columns={columns}
      />
    </TableContainer>
  );
};

const HeaderDiv = styled.div`
  display: flex;
  padding: 0 !important;
  &.header {
    border-color: transparent;
  }
  & .title {
    flex: 100% 1 1;
    padding-right: 2.5ch;
    text-align: center;
  }
`;

const doesNothing = () => {};
const SubscriberFlows = ({
  target,
  liveMode,
  title,
  setLiveMode,
  dpiEnabled
}) => {
  const [request, setRequest] = useState(null);

  const doLoad = () => {
    console.warn("Subcriber flows doLoad", target);
    setRequest(doRequest({ target }));
  };

  const doRequest = ({ target }) =>
    expressTarget(target).then((expressedTarget) =>
      ifCl
        .run(`show flow all ${expressedTarget} speed`)
        .then((response) => ({ response }))
    );

  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";

  useEffect(() => doLoad(), [targetStr]);

  const ContextMenu = ({ doLoad = doesNothing }) => {
    if (liveMode) {
      return null;
    }

    return (
      <ul className="context-menu header-dropdown">
        <li>
          <a
            onClick={doLoad}
            data-toggle="cardloading"
            data-loading-effect="pulse"
            title="Refresh"
          >
            <i className="material-icons">refresh</i>
          </a>
        </li>
      </ul>
    );
  };

  const headerClassName = "header subscriber-flows-header";

  return (
    <>
      <HeaderWithControls className={headerClassName}>
        <h4 className="title subtitle margin-0">{title ?? "Active Flows"}</h4>
        <ContextMenu doLoad={doLoad} />
      </HeaderWithControls>
      {request === null ? null : (
        <Request during={request}>
          {(result) => (
            <SubscriberFlowsResult
              {...result}
              liveMode={liveMode}
              setLiveMode={setLiveMode}
              target={target}
              dpiEnabled={dpiEnabled}
            />
          )}
        </Request>
      )}
    </>
  );
};

export default SubscriberFlows;
