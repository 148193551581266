import { safeStr } from "common/api";

const samePeriod = (starts, ends) => (current) =>
  current.starts === starts && current.ends === ends;

const mergeRange = ({ days, starts, ends }, ranges) => {
  const matchingIndex = ranges.findIndex(samePeriod(starts, ends));
  if (matchingIndex === -1) {
    return [...ranges, { days, starts, ends }];
  }
  const matching = ranges[matchingIndex];
  const composed = {
    starts: matching.starts,
    ends: matching.ends,
    days: `${matching.days}, ${days}`,
  };
  ranges.splice(matchingIndex, 1);
  ranges.push(composed);
  return ranges;
};

export const collectAsProfiles = (resp) => {
  const [_head, ...rows] = resp.trim("\n").split("\n");
  const rangesByName = rows.reduce((profiles, row) => {
    const [name, days, starts, ends] = row.trim().split(/\s+/);
    const ranges = profiles[name] || [];
    return {
      ...profiles,
      [name]: mergeRange({ days, starts, ends }, ranges),
    };
  }, {});
  return Object.entries(rangesByName).map(([name, ranges]) => ({
    name,
    ranges,
  }));
};

export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const enumerate =
  (start = 1, extraParams) =>
  (list) =>
    list.map((item, index) => ({
      ...item,
      __id: start + index,
      ...extraParams,
    }));
const asSuppresionCommand = ({ name }) => `clear profile time ${safeStr(name)}`;

const daysAsBitMask = (days = "") =>
  days
    .split(/,\s*/)
    .reduce(
      (mask, day) =>
        Math.pow(2, WEEKDAYS.length - WEEKDAYS.indexOf(day) - 1) | mask,
      0
    )
    .toString(16)
    .padStart(2, "0");

const runAllDays = (mask) =>
  WEEKDAYS.filter(
    (_day, index, days) => (mask & Math.pow(2, days.length - index - 1)) > 0
  );

const bitMaskAsDays = (mask) => runAllDays(parseInt(mask, 16)).join(", ");

const asRangeParam = ({ starts, ends, days }) => `
  range ${starts} ${ends} 0x${daysAsBitMask(days)}
`;

const asClearRangeParam = ({ starts, ends, days }) => `
  no range ${starts} ${ends} 0x${daysAsBitMask(days)}
`;

const asAdditionCommand = ({ name, ranges = [] }) => {
  if (ranges.length === 0) {
    throw new Error(`Period ${name} has no ranges`);
  }
  return `
  profile time ${safeStr(name)}
  ${ranges.map(asRangeParam).join("\n")}\n
  root`;
};

const getOriginalProfile = ({name, __id=undefined}, previous=[]) =>
  previous.find( current => 
    (current.__id !== undefined && current.__id === __id) ||
    (current.name !== undefined && current.name === name)
  ) || {ranges: []}

const asModificationCommandGiven = (previous=[]) => ({ name, ranges = [], __id}) => {
  if (ranges.length === 0) {
    throw new Error(`Period ${name} has no ranges`);
  }
  return `
  profile time ${safeStr(name)}
  ${getOriginalProfile({name, __id}, previous).ranges.map(asClearRangeParam).join("\n")}\n
  ${ranges.map(asRangeParam).join("\n")}\n
  root`;
};

const expressedAsCommands = (previous, {
  additions = [],
  suppressions = [],
  modifications = [],
}) => `
  ${suppressions.map(asSuppresionCommand).join("\n")}
  configure
    ${modifications.map(asModificationCommandGiven(previous)).join("\n")}
    ${additions.map(asAdditionCommand).join("\n")}
  commit
`;

const markedAsDeletedNotCreated = ({ deleted, created }) =>
  deleted === true && created !== true;

const markedAsModifiedNotDeletedOrCreated = ({ modified, deleted, created }) =>
  deleted !== true && created !== true && modified === true;

const markedAsCreatedNotDeleted = ({ created, deleted }) =>
  deleted !== true && created === true;

export const composeApplyCommand = (previous, expected) => {
  const suppressions = expected.filter(markedAsDeletedNotCreated);
  const modifications = expected.filter(markedAsModifiedNotDeletedOrCreated);
  const additions = expected.filter(markedAsCreatedNotDeleted);
  return expressedAsCommands(previous, { additions, suppressions, modifications })
    .replace(/\n\n/g, "\n")
    .replace(/\n\s+/g, "\n");
};

const profilesConfigRE = new RegExp(
  "profile time (?<name>.+)\\n" +
    "|" +
    "range (?<starts>(\\d?\\d):(\\d\\d)) (?<ends>(\\d?\\d):(\\d\\d)) 0x(?<days>[0-9a-fA-F]{1,2})\\n",
  "g"
);

const profilesCollector = () => {
  let current = null;
  let result = [];
  const collect = (iterator) => {
    for (const item of iterator) {
      const { name, starts, ends, days } = item.groups;
      if (name !== undefined) {
        current !== null && result.push(current);
        current = { name, ranges: [] };
      } else {
        current.ranges.push({ starts, ends, days: bitMaskAsDays(days) });
      }
    }
    current !== null && result.push(current);
    return { result };
  };
  return { collect, result };
};
export const parseConfiguredTimeProfiles = (response) =>
  profilesCollector().collect(response.matchAll(profilesConfigRE)).result;

const formatZeroInTime = (time) =>
  time
    .split(":")
    .map((part) => part.padStart(2, "0"))
    .join(":");

export const formatZeroInRangeTimes = ({ ranges, ...profile }) => ({
  ...profile,
  ranges: ranges.map(({ days, starts, ends }) => ({
    starts: formatZeroInTime(starts),
    ends: formatZeroInTime(ends),
    days: days,
  })),
});
