import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import styled from "styled-components";

const ListWithIcons = styled.ul`
  & li span,
  i.material-icons {
    line-height: 24px;
    vertical-align: middle;
  }
  i.material-icons {
    margin-right: 0.2em;
  }
`;

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);

  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title="Subscriber Quotas"
      onClose={doClose}
      content={() => (
        <>
          <p>
            <strong> Quotas table </strong>
          </p>
          <p>
            Click on{" "}
            <strong>
              <i class="material-icons valign-icon">add_circle</i> Add quotas to
              new subscriber...{" "}
            </strong>
            to add a new quota.
          </p>
          <ul>
            <li>
              <b>IP-ADDRESS</b>: Subscriber IP address.
            </li>
            <li>
              <b>SUBSCRIBER-ID</b>: Subscriber ID.
            </li>
            <li>
              <b>QUOTA-BLOCK</b>: Subscriber blocked due to reaching the time or
              volume quota limits.
            </li>
            <li>
              <b>QUOTA-TIME-LIMIT</b>: Time at which the quota expires.
            </li>
            <li>
              <b>QUOTA-GBYTES-USED</b>: GBytes of quota already used.
            </li>
            <li>
              <b>QUOTA-GBYTE-LIMIT</b>: GBytes of quota..
            </li>
            <li>
              <b>ACTIONS</b>: Allowed operations on this subscriber (e.g. edit
              and delete).
            </li>
          </ul>
          <p>
            <strong>Advanced quotas parameters</strong>. This section allows
            configuring aspects of subscriber volume and time quotas that affect
            all subscribers and all flows. Other quota-related parameters are
            configured by flow policy, so that different flows can behave in
            different ways with respect to quotas.
          </p>
          <ul>
            <li>
              <b>Subscriber initial quota status:</b> When set to <strong>disabled</strong>,
              subscribers will not be initially blocked when they are created
              (traffic will be allowed), and only when they are assigned a quota
              limit, will they be subject to that quota limit.
              When this parameter is set to <strong>blocked</strong>,
              all subscribers will be blocked by quota unless they have a
              valid quota assigned.
            </li>
            <li>
              <b>IPv4/HTTP redirect URL:</b> URL used for http redirection of
              IPv4 traffic in cases where the subscribers are blocked due to
              quota exhaustion.
            </li>
            <li>
              <b>IPv6/HTTP redirect URL:</b> URL used for http redirection of
              IPv6 traffic in cases where the subscribers are blocked due to
              quota exhaustion.
            </li>
          </ul>
          <p>
            Change the parameters and the click Apply to make those changes
            effective.
          </p>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
