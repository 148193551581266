import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const ThroughputLiveHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);

  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-help", function () {
      setOpen(true);
    });
  }, []);
  return open !== true ? null : (
    <Modal
      title="Subscriber Throughput"
      onClose={doClose}
      content={() => (
        <>
          <p>
            This page shows the throughput in real time of the twenty
            subscribers with the biggest traffic usage, plus the throughput of
            the remaining subscribers (rest category). There are two charts, one
            for downlink and another for uplink traffic.
          </p>
          <p>
            To stop the real time update of the charts, disable the Live mode
            switch.
          </p>

          <p>
            Subscribers can be labeled by their IP addresses (IP-Addr) or by
            their identifiers (Subs-Ids). The live mode will show by default up
            to 2 minutes (2 min. time span). Choices of 5 and 10 minutes are
            also available.
          </p>
          <p>
            Placing the mouse over a chart will show a tootip with the current
            throughput of the subscribers and a total and average throughput at
            the bottom. The total throughput is the sum of all subscriber
            current throughputs, not just of the heavier ones. The average
            throughput is the calculated since the live mode started.
          </p>
        </>
      )}
    />
  );
};
export default ThroughputLiveHelpModal;
