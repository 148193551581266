import { validateIPv4WithMask } from "common/api";

const significantCode = (code) =>
  code !== null && code !== undefined && code.length > 0;

const newBlock = (...codes) =>
  `\n${codes
    .filter(significantCode)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

export const _DEFAULT_SETTINGS = {
  handshakeFailRateDown: 0,
  handshakeFailRateUp: 0,
  volumeMinRate: 0,
  volumeRateMult: 0,
};
const includeParameters = (settings, [field, value]) => ({
  ...settings,
  [field]: value,
});

const translations = {
  "DoS SYN client egress rate": "handshakeFailRateDown",
  "DoS SYN client ingress rate": "handshakeFailRateUp",
  "DoS volume minimum rate": "volumeMinRate",
  "DoS volume multiplier rate": "volumeRateMult",
};
const parseNumber = (value) =>
  value.includes(".") ? parseFloat(value) : parseInt(value);

const parseInvolvedParameterItem = (row) => {
  const [name, value] = row.trim(/\s/).split(/:\s+/);
  const field = translations[name];
  return field === undefined ? [] : [[field, parseNumber(value)]];
};

const thoseAboutDoS = (line) => line.startsWith("DoS ");

export const parseParameters = (input) => {
  return input
    .trim("\n")
    .split("\n")
    .filter(thoseAboutDoS)
    .flatMap(parseInvolvedParameterItem)
    .reduce(includeParameters, _DEFAULT_SETTINGS);
};

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}

const newDoSConfigurationBlock = (...commands) =>
  newBlock("configure", "pkteng", ...commands, "commit").trim("\n");

const ensureSomeSettingsChanged = (permisive = false) =>
  permisive === true
    ? (...parts) => parts
    : (...parts) => {
        if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
          throw "No changes available to commit.";
        }
        return parts;
      };

const notEmpty = (value) => {
  if (value === undefined || value.length === 0) {
    throw "can't be empty";
  }
  return value;
};

const verified = (label, value, rule) => {
  try {
    return rule(value);
  } catch (cause) {
    throw `${label} ${cause}`;
  }
};

const validSynValue = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseInt(value);
  if (isNaN(parsed)) {
    throw "has to be a number";
  }
  if (0 > parsed) {
    throw "can't be below 0";
  }
  return parsed;
};

const validVolumeValue = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    throw "has to be a number";
  }
  if (0 > parsed) {
    throw "can't be below 0";
  }
  return parsed.toFixed(0);
};

const validMultiplierValue = (value) => {
  if (notEmpty(value) === false) {
    throw "can't be empty";
  }
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    throw "has to be a number";
  }
  if (0 > parsed) {
    throw "can't be below 0";
  }
  return parsed.toFixed(3);
};

const applySynSettings = (
  previous,
  { handshakeFailRateDown, handshakeFailRateUp }
) => [
  ...(handshakeFailRateDown === undefined ||
  Number(handshakeFailRateDown) === Number(previous.handshakeFailRateDown)
    ? []
    : [
        Number(handshakeFailRateDown) !== 0
          ? `dos syn rate client egress ${verified(
              "Received Syn handshake failure rate down parameter",
              handshakeFailRateDown,
              validSynValue
            )}`
          : `no dos syn rate client egress ${previous.handshakeFailRateDown}`,
      ]),
  ...(handshakeFailRateUp === undefined ||
  Number(handshakeFailRateUp) === Number(previous.handshakeFailRateUp)
    ? []
    : [
        Number(handshakeFailRateUp) !== 0
          ? `dos syn rate client ingress ${verified(
              "Received Syn handshake failure rate up parameter",
              handshakeFailRateUp,
              validSynValue
            )}`
          : `no dos syn rate client ingress ${previous.handshakeFailRateUp}`,
      ]),
];

const applyVolumeSettings = (previous, { volumeMinRate, volumeRateMult }) => [
  ...(volumeMinRate === undefined ||
  Number(volumeMinRate) === Number(previous.volumeMinRate)
    ? []
    : [
        Number(volumeMinRate) !== 0
          ? `dos volume min ${verified(
              "Received Downlink minimum rate",
              Number(volumeMinRate) * 1000,
              validVolumeValue
            )}`
          : `no dos volume min ${previous.volumeMinRate}`,
      ]),
  ...(volumeRateMult === undefined ||
  Number(volumeRateMult) === Number(previous.volumeRateMult)
    ? []
    : [
        Number(volumeRateMult) !== 0
          ? `dos volume multiplier ${verified(
              "Received Downlink multiplier",
              volumeRateMult,
              validMultiplierValue
            )}`
          : `no dos volume multiplier ${previous.volumeRateMult}`,
      ]),
];
export const composeApplyCommand = (previous, settings) =>
  newDoSConfigurationBlock(
    ...ensureSomeSettingsChanged(previous.enabled !== settings.enabled)(
      ...applySynSettings(previous, settings),
      ...applyVolumeSettings(previous, settings)
    )
  );
