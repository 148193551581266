/* global login */
import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="AVERAGE TCP CONNECTIONS SPEED OVER TIME"
      onClose={doClose}
      content={() => (
        <>
          <p>
            Average measured speed of TCP connections over time. Grey lines show
            speed for TCP connections without acceleration (no TCPO), while the
            {` ${login.isPlatform("cambium") ? "blue" : "red"} `}lines show
            speed for TCP connections with acceleration (TCPO). The TCP
            connection speeds are calculated globally and also broken down by
            the Internet-side latency (measured from the BQN node to the
            servers). The thresholds for small, medium and large Internet
            latency are defined in the Configuration{"->"}General Settings page.
          </p>
          <p>Chart selectors:</p>
          <ul>
            <li>
              <b>DOWNLOADS</b>: Selects data for TCP downloads.
            </li>
            <li>
              <b>UPLOADS</b>: Selects data for TCP uploads.
            </li>
            <li>
              <i className="material-icons valign-icon">date_range</i> Selects
              the range of time values shown starting from now: 1 day, 1 week or
              1 month. The slider at the bottom of the graph allows refining the
              range of date values shown: a shorter interval by dragging any of
              the dated labels and, once done, by dragging the central part.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh the TCP speed values.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SubscriberDetailHelpModal;
