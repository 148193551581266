import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const LinkStateHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("open-help", function () {
        setOpen(true);
      }),
    []
  );
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="NETWORK INTERFACES"
      onClose={doClose}
      content={() => (
        <>
          <p>Network interfaces details at the time of loading the page.</p>
          <ul>
            <li>
              <b>NAME</b>: Interface name.
            </li>
            <li>
              <b>TYPE</b>: Interface port type. Physical ports are of type
              "ethernet".
            </li>
            <li>
              <b>MAC</b>: Ethernet MAC address.
            </li>
            <li>
              <b>UP</b>: <span className="col-green">Checked</span> if network
              interface is up. <span className="col-red">Unchecked</span> if
              down or not configured.
            </li>
            <li>
              <b>LINK</b>: <span className="col-green">Checked</span> if link
              detected. <span className="col-red">Unchecked</span> if undetected
              (e.g. the cable is not connected, the peer port is down, etc).
            </li>
            <li>
              <b>MGT</b>: Indicates interface used for Management.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh interface information.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">search</i>{" "}
            icon to set up a tcpdump packet capture.
          </p>
        </>
      )}
    />
  );
};

export default LinkStateHelpModal;
