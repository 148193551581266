/* globals login */
import React, { useEffect, useReducer, useContext } from "react";
import CreateRange from "./Create";
import ListRanges from "./List";
import ActionsContext from "common/ActionsContext";
import { parseNTPRanges, parseDeviceIPRanges, getLicenseServers } from "../api";
import { parseClients as parseRADIUSClients } from "views/Config/RADIUS/api";
import { parseClients as parseRESTClients } from "views/Config/REST/api";
import { parseManagementInterface } from "views/Config/Interfaces/Management/api";
import IpV4 from "common/ipv4";

const doesNothing = () => {};

const higherId = (current = 0, { __id }) => (current > __id ? current : __id);

const areFrom = (source) => (item) => ({ ...item, from: source });

const addressesAsRangeWithSuffix =
  (suffix = 32) =>
  (list) =>
    list.map(({ address, mask = suffix }) => ({ range: `${address}/${mask}` }));

const wrapAsList = (item) => (item === undefined ? [] : [item]);

const itsRange = ({ range }) => range;

const supressRepetitions = (initial = []) => {
  const repetitions = new Set(initial.map(itsRange));
  return (list) =>
    list.flatMap(({ range, ...rest }) =>
      repetitions.has(range)
        ? []
        : repetitions.add(range) && [{ range, ...rest }]
    );
};

const dropLoopbackDevice = ([_loopback, ...items]) =>
  console.warn("filtering out", _loopback) || items === undefined ? [] : items;

const getClientConnectionAddress = () =>
  Promise.resolve(login.localAddr)
    .then((address) => ({ range: IpV4.normalizeCidr(address) }))
    .then(wrapAsList);

const loadSuggestions = (previous = []) =>
  Promise.all([
    ifCl
      .run("show system ntp")
      .then(parseNTPRanges)
      .catch(() => {
        return [];
      }),
    ifCl
      .run("show ip address")
      .then(parseDeviceIPRanges)
      .then(dropLoopbackDevice)
      .catch(() => {
        return [];
      }),
    ifCl
      .run("show interface management detail")
      .then(parseManagementInterface)
      .then(wrapAsList)
      .then(addressesAsRangeWithSuffix(32))
      .catch(() => {
        return [];
      }),
    ifCl
      .run("show api rest client")
      .then(parseRESTClients)
      .then(addressesAsRangeWithSuffix(32))
      .catch(() => {
        return [];
      }),
    ifCl
      .run("show api radius client")
      .then(parseRADIUSClients)
      .then(addressesAsRangeWithSuffix(32))
      .catch(() => {
        return [];
      }),
    getLicenseServers(),
    getClientConnectionAddress(),
  ])
    .then(
      ([
        ntpServers,
        deviceIPs,
        managementRanges,
        restRanges,
        radiusRanges,
        licenseServers,
        connectionClient,
      ]) => [
        ...ntpServers.map(areFrom("NTP")),
        ...connectionClient.map(areFrom("your current IP address")),
        ...licenseServers.map(areFrom("license servers")),
        ...managementRanges.map(areFrom("management I/F network")),
        ...deviceIPs.map(areFrom("your current IP address")),
        ...restRanges.map(areFrom("REST API clients")),
        ...radiusRanges.map(areFrom("RADIUS API clients")),
      ]
    )
    .then(supressRepetitions(previous));

const nextId = (items) => items.reduce(higherId, 0) + 1;
const anyRangeCollisionWith = (__id, range) => (existing) =>
  existing.range === range && existing.__id !== __id;

const doVerification = (item, list) => {
  const { range, __id } = item;

  if (IpV4.isValidCidrOrAddr(item.range) !== true) {
    throw "not a valid IPv4 address or range";
  }
  if (
    list.find(anyRangeCollisionWith(__id, IpV4.toCidr(range))) !== undefined
  ) {
    throw "range already exists.";
  }
};

const enumerate = (start = 1, extraParams) => {
  let current = start;
  return (item) => {
    const result = {
      ...item,
      __id: current,
      ...extraParams,
    };
    current += 1;
    return result;
  };
};

const mergeSuggestions = (list = [], suggestions = []) => {
  const enumeration = enumerate(nextId(list), { stored: false });
  return [
    ...list,
    ...suggestions.flatMap((suggestion) =>
      list.every((item) => notEqual(suggestion, item))
        ? [enumeration(suggestion)]
        : []
    ),
  ];
};
const notEqual = (one, other) => one.range !== other.range;

function rangesReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.item,
            range: IpV4.toCidr(action.item.range),
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...range }) =>
          __id !== action.id
            ? [{ __id, stored, ...range }]
            : stored === true
            ? [{ __id, stored, ...range, deleted: true }]
            : []
        ),
      };
    }
    case "merge": {
      return {
        changed: true,
        list: mergeSuggestions(list, action.list),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const Ranges = ({ initial = [], onChange = doesNothing }) => {
  const actions = useContext(ActionsContext);
  const [ranges, dispatch] = useReducer(rangesReducer, {
    changed: false,
    list: [...initial],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createRanges = (item) => {
    doVerification(item, ranges.list);
    loadAndMergeSuggestions([item]).then(() =>
      dispatch({
        type: "added",
        item,
      })
    );
  };
  const validateNewItem = (range) => {
    doVerification(range, ranges.list);
  };
  const loadAndMergeSuggestions = (previous = []) =>
    loadSuggestions(previous).then((suggestions) =>
      dispatch({ type: "merge", list: suggestions })
    );

  useEffect(() => {
    ranges.changed === true && onChange(ranges.list);
  }, [ranges]);

  useEffect(
    () => actions.recv("load-suggestions", loadAndMergeSuggestions),
    []
  );

  return (
    <>
      <ListRanges items={ranges.list} onDelete={handleDeleteId} />
      <CreateRange doSave={createRanges} doValidate={validateNewItem} />
    </>
  );
};

export default Ranges;
