import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

/*****************************************************
 * Help Modal
 * ****************************************************/
const RatePoliciesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="RATE POLICIES"
      onClose={doClose}
      content={() => (
        <>
          <p>Assignment of rate policies.</p>
          <ul>
            <li>
              <b>POLICY</b>: Policy name.
            </li>
            <li>
              <b>SUBS-PROVISIONED</b>: Number of subscribers assigned to this
              policy by RADIUS, REST or a Billing system. It does not count
              subscribers indirectly assigned as part of a subscriber-group
              using this policy.
            </li>
            <li>
              <b>SUBS-ACTIVE</b>: Number of active subscribers using this rate
              policy. It does not count subscribers indirectly using this policy
              as part of a subscriber-group using this policy.
            </li>
            <li>
              <b>MBYTES-DOWNLINK</b>: Traffic in downlink direction by
              subscribers directly assigned to this policy. It does not count
              the traffic from subscribers indirectly assigned as part of a
              subscriber-group using this policy.
            </li>
            <li>
              <b>MBYTES-UPLINK</b>: Traffic in uplink direction by subscribers
              directly assigned to this policy. It does not count the traffic
              from subscribers indirectly assigned as part of a subscriber-group
              using this policy.
            </li>
            <li>
              <b>ID</b>: Policy ID.
            </li>
            <li>
              <b>CONFIGURED:</b> Whether the policy was configured through the
              GUI or CLI. If "no", it means it has been defined by REST, RADIUS
              or by a Billing system. When it is undefined, it means that some
              subscribers have been assigned this policy via REST, RADIUS or a
              Billing System, but the policy has not been defined yet. You can
              define it by clicking on its name.
            </li>
            <li>
              <b>BLOCK</b>: "yes" if policy blocks traffic. This setting will
              not affect a subscriber-group when assigned this policy, only to
              subscribers directly assigned to this policy.
            </li>
            <li>
              <b>RATE-LIMIT-DOWN</b>: speed limit for all the subscriber\'s
              traffic in the downlink direction (in Mbps).
            </li>
            <li>
              <b>RATE-LIMIT-UP</b>: speed limit for all the subscriber\'s
              traffic in the uplink direction (in Mbps).
            </li>
            <li>
              <b>FLOW-LIMIT</b>: maximum number of flow per subscriber enforced.
              Flows include TCP connections, UDP connections and IP-level
              flows (not TCP or UDP).
            </li>
            <li>
              <b>ACM</b>: Automatic Congestion Management feature, "enabled" if
              active. This setting will not affect a subscriber-group when
              assigned this policy, only to subscribers directly assigned to
              this policy.
            </li>
          </ul>
          <p>
            Click on the corresponding{" "}
            <span className="col-blue">policy name</span> to show policy
            content. If the type is <i>config</i> or <i>undefined</i>, it will
            let you configure it.
          </p>
          <p>
            Click on the corresponding provisioned subscribers number to show
            provisioned subscribers list.
          </p>
          <p>
            Click on the corresponding active subscribers number to show active
            subscribers list.
          </p>
        </>
      )}
    />
  );
};
export default RatePoliciesHelpModal;
