import React, { useContext } from "react";
import ActionsContext from "common/ActionsContext";
import DataWiresStatusHelpModal from "./Help";
import { parseWires, mergeInterfacesState } from "./api";
import { parseInterfaces } from "views/Status/Interfaces/LinkState/api";
import { enumerate } from "common/utils";
import DetailModal from "views/Status/Interfaces/LinkState/Detail";
import CaptureModal from "views/Status/Interfaces/LinkState/Capture/index.jsx";
import Status from "./Status";

const filterNotManagementOrLoopback = (devices) =>
  devices.filter(
    ({ management, type }) => type !== "loopback" && management !== true
  );

export const load = () =>
  Promise.all([
    ifCl
      .run("show interface raw")
      .then(parseInterfaces)
      .then(filterNotManagementOrLoopback),
    ifCl.run("show pkteng wire")
      .then(parseWires),
  ]).then(([interfaces, wires]) => ({
    interfaces,
    wires: enumerate(1, { stored: true })(
      mergeInterfacesState(wires, interfaces)
    ),
  }));

const ContextMenu = ({ goBack = null }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      {goBack === null ? null : (
        <a
          onClick={goBack}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Back to list"
        >
          <i className="material-icons">
            <small>exit_to_app</small>
          </i>
        </a>
      )}
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const DataWiresStatus = ({ returnView }) => {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card container-lg">
        <div className="header block-header">
          <h2 className="title">Wires Status</h2>
          <ContextMenu goBack={returnView || null} />
        </div>
        <div className="body flex-panel-column padding-20">
          <Status provider={load} />
        </div>
      </div>
      <DataWiresStatusHelpModal />
      <CaptureModal />
      <DetailModal />
    </div>
  );
};

export default DataWiresStatus;
