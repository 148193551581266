/* globals functionality, login, rulesFlow, views, showModalError, showModalConfirm */
import React, {
  useState,
  useEffect,
} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { handleWarnings } from "./api";
import { safeStr } from "common/api";

const doesNothing = () => {};

const returnView = "viewRulesFlow";
const returnTab = "rulesFlowTabPolicies";

/*****************************************************
 * Radius-Rest Policies View
 * ****************************************************/
export const SubscriberFlowPoliciesTable = () => {
  const [request, setRequest] = useState(null);

  const doRequest = () => ifCl.run("show policy flow web").then(response => ({response}))

  const doLoad = () => {
    setRequest(doRequest());
  };

  const enableViewReload = (main) => {
    if (main === null || main.current === null) {
      return;
    }
    const tab = main.closest(`#${returnTab}`);
    $(tab).one("view:show", () => doLoad());
    const view = tab.closest(`#${returnView}`);
    $(view).one("view:show", () => doLoad());
    console.log("enabled reload on show", main);
  };

  useEffect(() => {
    doLoad();
  }, []);

  return request === null ? null : (
    <div className="row clearfix" ref={enableViewReload}>
      <div className="body table-responsive">
        <Request during={request}>
          {(result) => <ShowPolicies {...result} reload={doLoad} />}
        </Request>
      </div>
    </div>
  );
};

const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

/*****************************************************
 * Policies Table
 * ****************************************************/

const flowPolicyActions = ([name, ...row], whenDone) => [
  {
    id: "edit-config",
    label: "Edit",
    icon: "edit",
    onClick: (_value, [name, _id, _source, ..._rest]) =>
      doEditPolicy(name, whenDone),
  },
  ...( 
    name === 'flow-default'
      ? []
      : [{
          id: "delete-config",
          label: "Delete",
          icon: "delete",
          onClick: (_value, [name, _id, _source, ..._rest]) =>
            doDeletePolicy(name, whenDone),
        }]
  )
];
const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const optionallyBlocking = () =>
  functionality.isEnabled("dpi-per-user")
    ? [
        Column.Text({
          label: "BLOCK",
          idx: 1,
        }),
      ]
    : [];

const optionallyCompression = () =>
  functionality.isEnabled("compression")
    ? [
        Column.Text({
          label: "COMPRESS",
          idx: 3,
        }),
      ]
    : [];

const mutableOptimization = () =>
  login.isPlatform("cambium")
    ? [
        Column.Value((d) => (d === "no" ? "no" : "yes"), {
          label: "TCPO",
          idx: 2,
        }),
    ]
    : [
        Column.NumberOrYesNo({
          label: "TCPO",
          percent: true,
          idx: 2,
        }),
      ];


const ShowPolicies = ({ response, reload = doesNothing }) => 
  <TableContainer>
    <DataTable
      pageLength={50}
      exportAsCSV={true}
      content={response}
      columns={[
        Column.Text({
          label: "NAME",
          idx: 0,
        }),
        ...optionallyBlocking(),
        ...mutableOptimization(),
        ...optionallyCompression(),
        Column.SpeedOrNo({
          label: "SHAPE-SUBS-DN",
          idx: 4,
          colClassName: "align-right",
        }),
        Column.SpeedOrNo({
          label: "SHAPE-SUBS-UP",
          idx: 5,
          colClassName: "align-right",
        }),
        Column.SpeedOrNo({
          label: "SHAPE-FLOW-DN",
          idx: 6,
          colClassName: "align-right",
        }),
        Column.SpeedOrNo({
          label: "SHAPE-FLOW-UP",
          idx: 7,
          colClassName: "align-right",
        }),
        Column.Number({
          label: "PRIORITY",
          idx: 8,
          colClassName: "align-right",
        }),
        Column.Text({
          label: "DROP-INCOMING",
          idx: 9,
        }),
        Column.Value(
          input => input === 'yes' ? 'no' : 'yes',
          {
            label: "SKIP-QUOTA",
            idx: 12,
          }),
        Column.Text({
          label: "SKIP-RATE",
          idx: 13,
        }),
        Column.Text({
          label: "SKIP-SUBGR",
          idx: 14,
        }),
        Column.Actions({
          label: "ACTIONS",
          idx: 0,
          are: (source, row) => flowPolicyActions(row, reload),
          colClassName: "text-center action",
          cellClassName: "hides-content-to-operators",
        }),
      ]}
    />
  </TableContainer>

const doEditPolicy = (policy) => {
  ifCl
    .run(`show policy ${safeStr(policy)}`)
    .then((response) => {
      const ratePolicies = rulesFlow.data.policies;
      const policy = ratePolicies.parsePolicy(response);
      views.doKeep(returnView);
      ratePolicies.editPolicy(policy, returnView);
    })
    .catch((error) => {
      const notFound = String(error).match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const doDeletePolicy = (name, whenDone = doesNothing) =>
  showModalConfirm(
    `Remove "${name}" policy?`,
    'WARNING: All policy data will be lost and all subscribers assigned to this policy will be reassigned to a default rate policy following the BQN configured rules (normally default policy is "rate-default").',
    "delete_forever",
    () => {
      ifCl
        .run(`clear policy flow ${safeStr(name)}`)
        .then(handleWarnings)
        .then(whenDone)
        .catch((error) => showModalError(error));
    }
  );

