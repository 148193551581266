import React from "react";
import styled from "styled-components";

const NumberInputDiv = styled.div`
  .suffix {
    display: table-cell;
    padding: 6px 0;
  }
  &.hide-arrows {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  &.align-left .form-line input {
    text-align: left;
  }
`;

const isEnterKey = (event) =>
  event.code === "Enter" || event.code === "NumpadEnter";

const isAcceptable = (event) => {
  const key = event.key.toLowerCase();
  return (
    key.length !== 1 ||
    ((key === "." && event.target.value.includes(".") === false) ||
      (key >= "0" && key <= "9"))
  );
};

export const onlyNumbersKeyHandler = (
  onChange = doesNothing,
  onEnter = doesNothing
) => {
  let ignore = false;
  const onKeyDown = (event) => {
    ignore = false;
    if (isEnterKey(event)) {
      event.preventDefault();
      return onEnter(event);
    }
    if (isAcceptable(event) !== true) {
      ignore = true;
      event.preventDefault();
      return false;
    }
  };

  const onKeyUp = (event) => {
    if (ignore) {
      return;
    }
    const value = parseInt(event.target.value);
    return onChange(event, isNaN(value) === true ? null : value);
  };

  return {
    onKeyDown,
    onKeyUp,
  };
};

const doesNothing = () => {};

const NumberInputLabel = ({ type = "String", label, icon }) =>
  type === "function" ? (
    label({ label, icon })
  ) : (
    <>
      {icon === undefined ? null : (
        <div className="icon-label">
          <i className="material-icons">{icon}</i>
        </div>
      )}
      <div className={icon === undefined ? "no-class" : "label-icon"}>
        {label}
      </div>
    </>
  );

const NumberInput = ({
  onChange,
  onEnter = doesNothing,
  name,
  label = undefined,
  type = "number",
  disabled = false,
  className = "",
  placeHolder = null,
  defaultValue,
  icon = undefined,
  autocomplete = "off",
  suffix = undefined,
  max = undefined,
  min = undefined,
  hideArrows = false,
}) => (
  <NumberInputDiv
    className={`input-group ${className} ${hideArrows ? "hide-arrows" : ""}`}
  >
    <span className="input-group-addon padding-0">
      <NumberInputLabel icon={icon} label={label} type={typeof label} />
    </span>
    <div className="form-line">
      <input
        type={type}
        name={name}
        {...onlyNumbersKeyHandler(onChange, onEnter)}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        className="form-control"
        autoComplete={autocomplete}
        {...(min === undefined ? {} : { min })}
        {...(max === undefined ? {} : { max })}
      />
      {(suffix !== undefined && <span className="suffix">{suffix}</span>) ||
        null}
    </div>
  </NumberInputDiv>
);

export default NumberInput;
