const warningBillingDisabledRE = /%WARN-ENOENT: Billing API disabled/;

const labelAndValueFrom = (row) => {
  const [label, value] = row.split(/: /);
  return { label, value };
};

const extractFacts = (input) =>
  input
    .trim("\n")
    .split("\n")
    .flatMap((row) => (row.length === 0 ? [] : [labelAndValueFrom(row)]));

export const parseBillingStatus = (input) =>
  input.match(warningBillingDisabledRE) ? null : extractFacts(input);

export const isSyncDone = (facts) => {
  const syncEndedFact = facts.find(({ label }) => label === "Sync in progress");
  return syncEndedFact === undefined || syncEndedFact.value !== "no"
    ? false
    : true;
};

const parseMoment = (input) => new Date(input.trim("\n"));

const requestBillingStatus = () =>
  ifCl.run(`show api billing status`).then(parseBillingStatus);

export const requestMoment = () => ifCl.run("show system date").then(parseMoment);

export const requestFactsAndMoment = () =>
  Promise.all([requestMoment(), requestBillingStatus()]).then(
    ([moment, facts]) => ({
      moment,
      facts,
      ready: isSyncDone(facts),
    })
  );

const disconnectAndConnect = () =>
  ifCl
    .run(`api billing disconnect`)
    .then(() => ifCl.run("api billing connect"));

const warnBusyAndReturnData = (callback, data) => {
  try {
    callback();
  } catch (error) {
    console.warn("failed to run warning callback");
  }
  return data;
};

export const reconnect = (warningCallback) =>
  requestFactsAndMoment().then(({ ready, ...response }) =>
    ready === true
      ? disconnectAndConnect().then(requestFactsAndMoment)
      : warnBusyAndReturnData(warningCallback, { ready, ...response })
  );
