import React from "react";
import AdvancedSettings from "./component";
import styled from "styled-components";

const AdvancedSettingsLayout = styled.div`
  .col-lg-12 {
    margin-bottom: 0 !important;
  }
  .panel-body {
    padding: 0;
  }
  .card {
    box-shadow: none;
    margin-bottom: 0;
  }
  .modal-footer{
    padding-bottom: 0 !important;
  }
`;
const Advanced = () =>
  <AdvancedSettingsLayout>
    <AdvancedSettings />
  </AdvancedSettingsLayout>


export default Advanced;