/*globals login*/
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import ListSubscribers, { getColumns } from "./ListSubscribers";
import Statistics from "./Statistics";
import { extendWithStatistics } from "./common";
import { parseThresholds } from "../api";
import { safeStr } from "common/api";
import ThresholdsSettingsModal from "./ThresholdsSettingsModal";
import TimeEvolutionMetrics from "./TimeEvolutionMetrics";
import useDebouncer from "common/hooks/debouncer";
import useFirstTime from "common/hooks/firstTime";
import ActionsContext from "common/ActionsContext";
import { getIPv6Prefix } from "common/api";

const ensureCIDR = (subnet) =>
  Promise.resolve(
    subnet.length === 0 || subnet.includes("/") ? subnet : addCIDR(subnet)
  );

const addCIDR = (subnet) =>
  subnet.includes(":")
    ? /*ipv6*/ getIPv6Prefix().then((prefix) => `${subnet}/${prefix}`)
    : `${subnet}/32`;

const stateSubscriber = (subnet) =>
  "subscriber " + (subnet.length === 0 ? "all" : subnet);

const statePolicy = (policy) => (policy === "all" ? "" : `policy ${safeStr(policy)}`);

const stateSubscriberGroup = (subscriberGroup) =>
  subscriberGroup && subscriberGroup !== "all"
    ? `subscriber-group ${safeStr(subscriberGroup)}`
    : "";

const responseMustContainAtLeastOneRecord = (subnet) => (response) => {
  if (subnet.length > 0) {
    const [_head, ...rows] = response.trim("\n").split("\n");
    if (rows === undefined || rows.length < 1) {
      throw `%ERR-ENOENT: Cannot find subscriber with ip ${subnet}`;
    }
  }
  return { response };
};

const createPlaceholder = (addr) =>
  [
    "ADDR            SUBSCRIBER-ID     BLOCK      FL-ACTIVE FL-CREATED         MBYTES  CURR-Mbps  MEAN-Mbps   MAX-Mbps   LIM-Mbps    RTT-ms      RTX POLICY-RATE              MAX-SPEED  CONGEST   WARN   LIFETIME",
    `${addr}         n/a               n/a        0         n/a                n/a     n/a        n/a        n/a        n/a       n/a    0.00%  n/a                 0.00%    0.00%   0   0:00:00`,
  ].join("\n");

const ensurePlaceholder = (subnet) => (error) => {
  if (error.startsWith("%ERR-ENOENT: Cannot find subscriber with")) {
    const addr = subnet.replace(/\/.*$/, "");
    return { response: createPlaceholder(addr) };
  }
  throw error;
};

const doSubscriberMetricsRequest = ({
  policy,
  subnet,
  direction,
  lines,
  subscriberGroup,
}) =>
  ensureCIDR(subnet)
    .then((cidrSubnet) =>
      ifCl.run(
        `show ${statePolicy(policy)} ${stateSubscriber(
          cidrSubnet
        )} ${direction} ${stateSubscriberGroup(subscriberGroup)} lines ${lines}`
      )
    )
    .then(responseMustContainAtLeastOneRecord(subnet))
    .catch(ensurePlaceholder(subnet));

const ResultsLayout = styled.div`
  flex: 100%;
  overflow: hidden; /*list will scroll*/
`;

const getGroups = () => [
  {
    field: "MAX-Mbps",
    label: "MAX-Mbps",
    units: "Mbps",
    legend: "Max speed last 24h",
    tooltip:
      "maximum speed in Mbps during the previous 24 hours or since last subs-id/policy change",
    percentage: true,
  },
  {
    field: "RTT-ms",
    label: "RTT-ms",
    inverted: true,
    units: "ms",
    legend: login.isPlatform("cambium")
      ? "Latency from QoE"
      : "Latency from bqn",
    tooltip:
      "Moving average of latency, in milliseconds. If downlink, latency from this node to subs, if uplink, latency from this node to Internet server",
  },
  {
    field: "RTX",
    label: "RTX",
    inverted: true,
    units: "% retransmisions",
    legend: "TCP packet retransmission",
    tooltip:
      "Moving average of the percentage of TCP retransmissions (which reflect packet losses in this direction)",
    percentage: true,
  },
  {
    field: "MAX-SPEED",
    label: "MAX-SPEED-%",
    inverted: true,
    units: "% of traffic",
    legend: "% traffic sent at max speed",
    tooltip:
      "Moving average of the percentage of traffic sent with a speed close to the maximum",
    percentage: true,
  },
  {
    field: "CONGEST",
    label: "CONGESTION",
    tooltip: "Moving average of percentage of traffic suffering congestion",
    inverted: true,
    units: "% of traffic",
    legend: "% traffic under congestion",
    percentage: true,
  },
];

const combineGroupsAndThresholds = ([groups, thresholds]) =>
  groups.map(({ field, ...group }) =>
    thresholds[field] === undefined
      ? { field, ...group }
      : {
          field,
          threshold: thresholds[field],
          meaningful: thresholds["MEAN-Mbps"],
          ...group,
        }
  );

const getGroupsWithThresholds = () =>
  Promise.all([
    Promise.resolve(getGroups()),
    ifCl.run("show subscriber metrics-thresholds").then(parseThresholds),
  ]).then(combineGroupsAndThresholds);

const subscribersToPlotLimit = 50;

const SwitchInputDiv = styled.div`
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #bcbcbc;
 
  span {
    font-weight: 100;
    font-size: 18px;
  }
`;

const SwitchInputContainer = styled.div`
  width: 100%;
  padding: 0 2em;
`;

const Report = ({
  policy,
  subnet,
  direction,
  lines,
  subscriberGroup,
  timePeriod,
  metric,
  ...otherSettings
}) => {
  const [request, setRequest] = useState(null);
  const [selected, setSelected] = useState(null);
  const shouldInitMetrics = useRef(false);
  const debounce = useDebouncer(2100 /*ms*/);
  const isFirstTime = useFirstTime();
  const [groups, setGroups] = useState([]);
  const metricsResponse = useRef();
  const actions = useContext(ActionsContext);

  useEffect(() => {
    doLoad();
  }, [policy, subnet, direction, lines, subscriberGroup]);

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", () => {
      shouldInitMetrics.current = true;
      doLoad();
    });
  }, []);

  useEffect(() => {
    if(!request){
      return;
    }
    
    request.then(() => {
      shouldInitMetrics.current = false;
    })
  }, [request]);

  function doDelayedLoad() {
    setTimeout(() => {
      doLoad(true);
    }, 1000);
  }

  const columns = getColumns();
  const doLoad = (cache) => {
    setRequest(
      Promise.all([
        cache
          ? metricsResponse.current
          : doSubscriberMetricsRequest({
              policy,
              subnet,
              direction,
              lines,
              subscriberGroup,
            }),
        cache
          ? extendWithStatistics(groups, columns)
          : getGroupsWithThresholds().then((groups) => {
              setGroups(groups);
              return extendWithStatistics(groups, columns);
            }),
      ]).then(([response, extendStatisticsWithGroups]) => {
        metricsResponse.current = response;
        return extendStatisticsWithGroups(response);
      })
    );
  };
  
  const updateSelected = (newSelection) =>{
    const assign = () => {
      if (newSelection.length > subscribersToPlotLimit) {
        showModalInfo(
          `Cannot select more than ${subscribersToPlotLimit} subscribers to plot. De-select some subscribers to select new ones.`
        );
        return;
      }
      setSelected(newSelection)
    }
    isFirstTime()
    ? assign() 
    : debounce(assign)
  }

  return request === null ? null : (
    <>
      <Statistics request={request} {...otherSettings} />
      <TimeEvolutionMetrics
        request={request}
        direction={direction}
        onEntityChanged={doDelayedLoad}
        subscribersToPlot={selected}
        shouldInit={shouldInitMetrics.current}
        timePeriod={timePeriod}
        metric={metric}
      />
      <ListSubscribers
        request={request}
        subnet={subnet}
        {...otherSettings}
        showPlotColumns={true}
        onPlotSelection={updateSelected}
      />
      <ThresholdsSettingsModal groups={groups} />
    </>
  );
};

export default Report;
