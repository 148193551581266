import React, { useState, useEffect } from "react";
import { getSystemDate } from "common/api";


export function calculateTimeOffset() {
  return getSystemDate().then((response) => {
    const systemDate = new Date(response.split(" ").splice(0, 5).join(" "));
    return systemDate.getHours() - new Date().getHours();
  });
}

export function useTimeOffset() {
    const [timeOffset, setTimeOffset] = useState();
    useEffect(() => {
      calculateTimeOffset().then((result) => {
        setTimeOffset(result);
      });
    }, []);
  
    return { timeOffset };
  }