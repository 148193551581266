/* globals describe, it, expect */

import * as d3 from "d3";
import { calcRangeExtent, parseSystemDate } from "common/api";
import { threeSignificantDigitsFormat } from "common/utils";

export const hoursByTimePeriodMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 30,
  threeMonths: 24 * 30 * 3,
};

export const PERIOD_TO_LINES = {
  oneDay: 145,
  oneWeek: 1009,
  oneMonth: 721,
  threeMonths: 2161,
};

const allSpecialSymbols = /[#'|\\]/g;
const escapedSymbol = "\\$&";

export const safeStrStats = (input) => {
  const newInput = input
    .trim(/\s/)
    .replace(/\s/g, "_")
    .replace('"', '""')
    .replace(allSpecialSymbols, escapedSymbol);
  return `"${String(newInput)}"`;
};

export const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

export function getMaxValue(item) {
  const itemCheck = { ...item };
  delete itemCheck.time;
  const maxValue = Math.max(...Object.values(itemCheck));
  return maxValue;
}

export function getInRangeItems(items, range, fields, sampleSize, initLimits) {
  if (!items || items.length === 0 || fields.length === 0) {
    return { items: [], limits: initLimits };
  }

  const { from, to } = range;
  const checkLimits =
    range === undefined || initLimits.isZeroOrNull ? false : true;

  let { inrangeItems, isZeroOrNull, maxTotal, sampledValues } = getItemsLimitsInRange(
    items,
    from,
    to,
    checkLimits,
    sampleSize,
    fields
  );

  if (inrangeItems.length === 0) {
    return { items: [], limits: { isZeroOrNull: true, max: 0 } };
  }

  const limits = checkLimits ? { isZeroOrNull, max: maxTotal } : initLimits;
  return { items: inrangeItems, limits, sampledValues };
}

export const getMetricDir = (metric, direction) => {
  let column;
  switch (metric) {
    case "averageSpeed":
      column = direction === "downlink" ? "speed-down" : "speed-up";
      break;
    case "maxSpeed":
      column = direction === "downlink" ? "max-speed-down" : "max-speed-up";
      break;
    case "activeFlows":
      column = "flows-active";
      break;
    case "flowsCreated":
      column = "flows-created";
      break;
    case "latency":
      column = direction === "downlink" ? "latency-down" : "latency-up";
      break;
    case "retransmissions":
      column =
        direction === "downlink" ? "retransmission-down" : "retransmission-up";
      break;
    case "congestion":
      column = "congested-traffic";
      break;
    case "traffic":
      column = "max-traffic";
      break;
    default:
      column = null;
  }

  return column;
};

export const parseStatsResponse = (
  response,
  metric,
  from,
  to,
  colorsSet,
  type
) => {
  const [head, ...rows] = response.trim("\n").split("\n");
  const [, ...keys] = head.split(/\s+/);

  if (
    type !== "groups" &&
    (metric === "latency" || metric === "retransmissions")
  ) {
    keys.push("network");
  }

  const fields = keys.map((name, index) => {
    const colorIndex = index % colorsSet.length;
    return {
      name,
      label: name === "network" ? "Whole-network" : name,
      color: colorsSet[colorIndex],
    };
  });

  if (rows.length === 0) {
    return { fields, items: [] };
  }

  const { items, limits } = rows.reduce(
    (acc, value) => {
      const [timeAt, ...attrs] = value.split(/\s+/);
      const time = dateTimeParser(timeAt);
      if (from < time && time < to) {
        const item = attrs.reduce(
          (accItem, valueItem, index) => {
            const newValue = valueItem === "n/a" ? null : Number(valueItem);
            accItem[fields[index].name] = newValue;
            if (newValue !== 0 && newValue !== null) {
              acc.limits.isZeroOrNull = false;
            }
            if (newValue > acc.limits.max) {
              acc.limits.max = newValue;
            }
            return accItem;
          },
          { time }
        );
        acc.items.push(item);
      }
      return acc;
    },
    { items: [], limits: { isZeroOrNull: true, max: 0 } }
  );

  return { fields, items, limits };
};

const getSampledValues = (item, sampledValues) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    if (key !== 'time' && value !== null) {
      if (!acc[key]) {
        acc[key] = [value];
      } else {
        acc[key].push(value)
      }
    }
    return acc
  }, {...sampledValues});
}

export function getItemsLimitsInRange(
  items,
  from,
  to,
  checkLimits,
  sampleSize,
  fields
) {
  let maxItems;
  let inrangeItems = [];
  let isZeroOrNull = true;
  let maxTotal = 0;
  let sampledValues = {};

  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (i === 0) {
        maxItems = { ...items[i] };
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue > maxTotal) {
            maxTotal = maxValue;
          }
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      }
      if (i % sampleSize === 0 && i !== 0) {
        if (maxItems) {
          inrangeItems.push(maxItems);
          sampledValues = getSampledValues(maxItems, sampledValues);
        }
        maxItems = { ...items[i] };
        if (maxItems && i === items.length - 1) {
          inrangeItems.push(maxItems);
          sampledValues = getSampledValues(maxItems, sampledValues);
        }
        if (checkLimits) {
          const maxValue = getMaxValue(maxItems);
          if (maxValue > maxTotal) {
            maxTotal = maxValue;
          }
          if (maxValue !== null && maxValue > 0) {
            isZeroOrNull = false;
          }
        }
      } else {
        const newMax = fields.reduce(
          (acc, { name: value }) => {
            const newValue = items[i][value];
            if (items[i][value] > acc[value]) {
              acc[value] = newValue;
              if (checkLimits) {
                if (isZeroOrNull && newValue !== 0 && newValue !== null) {
                  isZeroOrNull = false;
                }
                if (newValue > maxTotal) {
                  maxTotal = newValue;
                }
              }
            }
            return acc;
          },
          { ...maxItems }
        );
        maxItems = { ...newMax, time };
        if (maxItems && i === items.length - 1) {
          inrangeItems.push(maxItems);
          sampledValues = getSampledValues(maxItems, sampledValues);
        }
      }
    }
  }

  return { inrangeItems, isZeroOrNull, maxTotal, sampledValues };
}

export function getSampleSize(range, POINTS_PER_TILE_CHART, period) {
  if (!range) {
    return 1;
  }
  const { from, to } = range;
  const begin = new Date(from);
  const end = new Date(to);
  const timeDiffinHours = (end.getTime() - begin.getTime()) / (1000 * 60 * 60);
  const lines =
    (timeDiffinHours / hoursByTimePeriodMap[period]) * PERIOD_TO_LINES[period];
  const sampleSize = Math.floor(lines / POINTS_PER_TILE_CHART) + 1;
  return sampleSize;
}

const doSystemDateRetrieval = () => ifCl.run("show system date");

export const retrieveRange = (hours) =>
  doSystemDateRetrieval()
    .then(parseSystemDate)
    .then((now) => calcRangeExtent(now, hours));

export const getTargets = (entity, subscribersToPlot) => {
  if (entity === "ip-address") {
    return subscribersToPlot.map(({ addr }) => addr);
  }

  const adressesNotExcluded = Array.from(
    new Set(subscribersToPlot.map(({ subsId }) => safeStrStats(subsId)))
  );
  const adressesExcluded = subscribersToPlot.reduce((acc, value) => {
    const { subsId } = value;
    if (subsId !== "::") {
      acc.push(safeStrStats(subsId));
    } else {
      throw new Error("Invalid subscriber list given");
    }
    return acc;
  }, []);

  if (adressesExcluded.length > 0) {
    return adressesNotExcluded;
  }

  return Array.from(adressesExcluded);
};

const formatDependentNumber = (value) => {
  if (value < 10) {
    return Number(value).toFixed(3);
  }
  if (value < 100 && value >= 10) {
    return Number(value).toFixed(2);
  }
  if (value < 1000 && value >= 100) {
    return Number(value).toFixed(1);
  }
  return Number(value).toFixed(0);
};

export const heatmapFormatMap = {
  averageSpeed: formatDependentNumber,
  maxSpeed: formatDependentNumber,
  activeFlows: (value) => threeSignificantDigitsFormat(value),
  flowsCreated: (value) => threeSignificantDigitsFormat(value),
  latency: formatDependentNumber,
  retransmissions: (value) => Number(value).toFixed(2),
  congestion: (value) => Number(value).toFixed(2),
  traffic: (value) => Number(value).toFixed(2),
};

export const metricMap = {
  averageSpeed: "Average Speed",
  maxSpeed: "Maximum Speed",
  activeFlows: "Active Flows",
  flowsCreated: "Flows Created Per Minute",
  latency: "Latency",
  retransmissions: "Retransmissions",
  congestion: "% Traffic in Congestion",
  traffic: "% Traffic at Max Speed",
};

export const tooltipContentGeneratorForUnits = (
  unit = CONST.UNITS,
  format = formats.twoDecimals,
  showZeroValue = true
) =>
  function (d) {
    $(".nvtooltip").each(function () {
      this.style.opacity = 0;
    });

    const ROWS_SINGLE_COLUMN_MAX = 10;
    const date = d3.timeFormat("%Y-%m-%d  %H:%M:%S")(new Date(d.value));
    const dateHTML = `<b style="white-space: pre;">${date}</b>`;

    if (d.series.length <= 0) {
      return dateHTML;
    }
    const hasManySeries = d.series.length >= ROWS_SINGLE_COLUMN_MAX ? " large" : "";
    const seriesHTML = d.series.reduce((acc, value) => {
      const { color, value: valuePoint, key } = value;
      const html = `<div class="series-tooltip-wide-line">
                          <div class="series-tooltip-wide-line-name-color">
                            <div class="series-tooltip-wide-line-color square" style="background-color:${color};"></div>
                            <div class="series-tooltip-wide-line-name">${key}</div>
                          </div>
                          <div class="series-tooltip-wide-line-value bold">${format(
                            valuePoint
                          )}</div>
                        </div>`;
      acc.push(html);
      return acc;
    }, []);
    const legendsHTML = `<div class="tooltip-wide-line-wrapper${hasManySeries}">${dateHTML}<div class="series-tooltip-wide-line-wrapper series${hasManySeries} heatmap">${seriesHTML.join(
      ""
    )}</div></div>`;

    return legendsHTML;
  };

  export const getToppedValue = ({value, min, max, yScale, height, topGap, totalHeight})=> {
    if( min && value <= min){
      if(min === 0){
        return totalHeight;
      }
      if(yScale(value) > totalHeight - topGap && yScale(value) < totalHeight){
        return yScale(value) + topGap;
      }
      return totalHeight;
    }
    if( max && value >= max){
      if(Math.abs(yScale(value)) < topGap){
        yScale(value) + topGap;
      }
      return 0;
    }
    return yScale(value) + topGap;
  }
