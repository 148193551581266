import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="CONGESTION & ACM"
      content={() => (
        <>
          <p>
            {" "}
            Current sent and received traffic throughput for network interfaces.
          </p>
          <ul>
            <li>
              <b>Tx</b>: Transmitted throughput.
            </li>
            <li>
              <b>Rx</b>: Received throughput.
            </li>
          </ul>
          <p>
            Click on the coloured dots on the top-left corner of the graph to
            optionally select or de-select which throughput is shown (Tx or Rx).
          </p>
          <p>
            Click on <i className="material-icons valign-icon">refresh</i> icon
            to refresh throughput information.
          </p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default SubscriberDetailHelpModal;
