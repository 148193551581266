import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const DataWiresHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("open-help", function () {
        setOpen(true);
      }),
    []
  );
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Wires Status"
      onClose={doClose}
      content={() => (
        <>
          <p>List of configured wires:</p>
          <ul>
            <li>
              <b>ACCESS INTERFACE</b>: Access side interface.
            </li>
            <li>
              <b>PCAP</b>: Access side interface network traffic capture using
              PCAP.
            </li>
            <li>
              <b>INTERNET INTERFACE</b>: Internet side interface.
            </li>
            <li>
              <b>PCAP</b>: Internet side interface network traffic capture using
              PCAP.
            </li>
          </ul>
          <p>
            PCAP capture is slower, but may be required when the interface does
            not support DPDK.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh wires information.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">search</i>{" "}
            icon to set up a tcpdump packet capture.
          </p>
        </>
      )}
    />
  );
};

export default DataWiresHelpModal;
