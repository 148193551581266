import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

/*****************************************************
 * Help Modal
 * ****************************************************/
const ConfigSNMPHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="SNMP configuration"
      onClose={doClose}
      content={() => (
        <>
          <p>
            <b>SNMP status:</b> shows the current status of SNMP. If disabled,
            it is necessary to configure at least one community entry.
          </p>
          <p>
            SNMP System Parameters enable you to configure information about
            this node that will be accessible via SNMP.
          </p>
          <ul>
            <li>
              <b>NAME</b>: configure a name for this system.
            </li>
            <li>
              <b>DESCRIPTION</b>: configure a description for this system.
            </li>

            <li>
              <b>CONTACT</b>: configure an email or phone number of someone
              responsible for this system.
            </li>
            <li>
              <b>LOCATION</b>: location of the system.
            </li>
          </ul>
          <p>
            <b>Community table</b>: A list of community strings that will be
            required for access from different IP addresses. It is also possible
            to set a community that can be used by any IP addresses by leaving
            the NMS IP Address empty. New community entries are added clicking
            on the menu icon and selecting &quot;Add community entry...&quot;.
            Communities can be deleted from the table by clicking on the trash
            icon.
          </p>
          <p>
            <b>Traps table</b>: a list of systems towards which the BQN will
            send SNMP traps. It is necessary to configure the destination IP
            address, the community string for that destination and the version
            of SNMP to use. The community strings must be added in the community
            table. New trap entries can be added clicking on the menu icon and
            selecting &quot;Add trap...&quot;, and they can be deleted from the
            table by clicking on the trash icon.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to reload the current parameters.
          </p>
          <p>
            Change the parameters and the click Apply to make those changes
            effective.
          </p>
        </>
      )}
    />
  );
};

export default ConfigSNMPHelpModal;
