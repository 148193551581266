import React from "react";

const doesNothing = () => {};

const DeleteCommunityButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const CommunityItem = ({
  id,
  name = "",
  nms = "",
  deleted,
  even = false,
  onDelete = doesNothing,
}) => {
  if (deleted === true) {
    return null;
  }
  const [_first, ...rest] = name;
  const doCommunityDelete = () => {
    onDelete(id);
  };

  return (
    <React.Fragment>
      <tr
        className={`${even ? "even" : "odd"} ${
          rest.length === 0 ? "single" : "has-many"
        }`}
      >
        <td>{nms}</td>
        <td>{name}</td>
        <td className="text-center hides-content-to-operators">
          <DeleteCommunityButton onClick={doCommunityDelete} />
        </td>
      </tr>
    </React.Fragment>
  );
};
const CommunitiesList = ({ items = [], onDelete = doesNothing }) => (
  <table id="SNMPCommunityDestinationTbl" className="table table-striped">
    <thead>
      <tr>
        <th>NMS</th>
        <th>COMMUNITY</th>
        <th className="text-center">ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {items
        .filter(({ deleted }) => deleted !== true)
        .map(({ __id, ...community }, index) => (
          <CommunityItem
            id={__id}
            key={__id}
            {...community}
            even={(index + 1) % 2 === 0}
            onDelete={() => onDelete(__id)}
          />
        ))}
    </tbody>
  </table>
);
export default CommunitiesList;
