import React, { useState, useEffect, useContext, useRef } from "react";
import ActionsContext from "common/ActionsContext";
import CreateProfile from "./Create";
import { RangeAsRow, CreateRangeModal } from "./Ranges";
import EditModal from "./Edit";
import useFirstTime from "common/hooks/firstTime";

const doesNothing = () => {};

const DeleteProfileButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const EditButton = ({ onClick }) => (
  <a className="waves-effect borderless" onClick={onClick} title="edit ranges">
    <i className="material-icons">edit</i>
  </a>
);

const CreateRangeButton = ({ onClick }) => (
  <a className="waves-effect borderless" onClick={onClick} title="add range">
    <i className="material-icons">playlist_add</i>
  </a>
);


const ProfileItem = ({
  name,
  ranges,
  deleted,
  even = false,
  onDelete = doesNothing,
  index = undefined,
}) => {
  if (deleted === true) {
    return null;
  }
  const actions = useContext(ActionsContext);
  const [first, ...rest] = ranges;
  const doProfileDelete = (event) => {
    console.log("called doProfileDelete", index, event);
    onDelete(index);
  };
  const openEdit = () => {
    actions.send("profile-settings", index);
  };
  const openCreateRange = () => {
    actions.send("create-range", index);
  };
  return (
    <React.Fragment>
      <tr
        className={`${even ? "even" : "odd"} ${
          rest.length === 0 ? "single" : "has-many"
        }`}
      >
        <td rowSpan={ranges.length || ""}>{name}</td>
        {first !== undefined ? (
          <RangeAsRow {...first} />
        ) : (
          <td className="text-center" colSpan="3">
            -- No ranges --
          </td>
        )}
        <td
          className="text-center hides-content-to-operators"
          rowSpan={ranges.length || ""}
        >
          <CreateRangeButton onClick={openCreateRange} />
          <EditButton onClick={openEdit} />
          <DeleteProfileButton onClick={doProfileDelete} />
        </td>
      </tr>
      {rest.map((range, index) => (
        <tr
          className={`merge-top ${even ? "even" : "odd"}`}
          key={`${name}-${range.starts}-${range.ends}-${index}`}
        >
          <RangeAsRow {...range} />
        </tr>
      ))}
    </React.Fragment>
  );
};

const ShowProfiles = ({ defaults = {}, onChange = doesNothing }) => {
  const [profiles, setProfiles] = useState(defaults.profiles || []);
  const isFirstTime = useFirstTime();
  const handleDeletePosition = (index) => {
    const update = [...profiles];
    update[index].deleted = true;
    setProfiles(update);
  };
  const createProfile = (newProfile) => {
    setProfiles([...profiles, { created: true, ...newProfile }]);
  };
  const handleChangeOn = (position, updatedProfile) => {
    const update = profiles.map((profile, index) =>
      index === position ? { ...updatedProfile, modified: true } : { ...profile }
    );
    setProfiles(update);
  };
  useEffect(() => {
    isFirstTime() || onChange(null, { name: "profiles", value: profiles });
  }, [profiles]);
  return (
    <React.Fragment>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <CreateProfile
          others={profiles.map(({ name }) => name)}
          doSave={createProfile}
        />
        <table className="profiles table table-class-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th width="110px" className="align-right">
                Starts
              </th>
              <th width="110px" className="align-right">
                Ends
              </th>
              <th className="align-center">Days</th>
              <th className="text-center" width="100px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {profiles.map(({ name, ranges, deleted }, index) => (
              <ProfileItem
                key={name}
                index={index}
                name={name}
                ranges={ranges}
                deleted={deleted}
                even={(index + 1) % 2 === 0}
                onDelete={handleDeletePosition}
              />
            ))}
          </tbody>
        </table>
      </div>
      <EditModal profiles={profiles} doSave={handleChangeOn} />
      <CreateRangeModal profiles={profiles} doSave={handleChangeOn} />
    </React.Fragment>
  );
};

export default ShowProfiles;
