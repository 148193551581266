import React from "react";
import styled from "styled-components";
import TextInput from "common/TextInput";
import PasswordInput from "common/PasswordInput";
import SelectInput from "common/SelectInput";

const azotelBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer ID", value: "customer-id" },
  { label: "Name", value: "name" },
  { label: "Nickname", value: "nickname" },
];

const dictionary = {
  'user-name': 'name',
  'login': 'nickname',
}

function translate(input){
  return dictionary[input] ?? input;
}

const AzotelBillingSettingsInputs = ({ onChange, defaults = {} }) => (
  <>
    <TextInput
      type="text"
      label="User:"
      name="username"
      className="input-group"
      defaultValue={defaults.username}
      onChange={onChange}
    />
    <PasswordInput
      defaultValue={defaults.password}
      className="input-group"
      name="password"
      onChange={onChange}
    />
    <SelectInput
      label="Subscriber-ID source"
      name="subscriberIdSource"
      className="input-group margin-b-20"
      defaultValue={defaults.subscriberIdSource ==="n/a" || defaults.subscriberIdSource === undefined ? "unchanged" : translate(defaults.subscriberIdSource)}
      onChange={onChange}
      options={azotelBillingSubscribers}
    />
  </>
);

export default AzotelBillingSettingsInputs;
