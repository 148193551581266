import React from "react";
import SelectInput from "common/SelectInput";
import PasswordInput from "common/PasswordInput";
import NonBlockingStatus from "./NonBlockingStatus";
import SwitchInput from "common/SwitchInput";
import styled from "styled-components";

const Grouped = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  &.align-bottom {
    justify-content: flex-end;
    & > * {
      flex: 0;
    }
  }
`;

const sonarBillingSubscribers = [
  { label: "Leave unchanged", value: "unchanged" },
  { label: "Customer ID", value: "customer-id" },
  { label: "Name", value: "name" },
];

const dictionary = {
  'user-name': 'name',
}

function translate(input){
  return dictionary[input] ?? input;
}

const sonarDelinquentOptions = [
  { label: "Sonar Status", value: false },
  { label: "Sonar Delinquency state", value: true },
];

const PolicyNameFromSonarInput = ({
  onChange,
  defaults = {},
  className = "",
}) => (
  <>
    <SwitchInput
      defaultChecked={defaults.sonarPolicyName}
      className={className}
      name="sonarPolicyName"
      label={(checked) =>
        `Policy names from Sonar service names: ${
          checked ? "enabled" : "disabled"
        }`
      }
      onChange={onChange}
    />
  </>
);

const SonarBillingSettings = ({ onChange, defaults = {}, state }) => {
  const { subscriberIdSource } = defaults;
  const selectInputValue =
    subscriberIdSource === "n/a" || subscriberIdSource === undefined
      ? "unchanged"
      : subscriberIdSource;
  return (
  <>
    <PasswordInput
      defaultValue={defaults.apikey}
      className="input-group"
      name="apikey"
      label="API key"
      onChange={onChange}
    />
    <SelectInput
      label="Subscriber-ID source"
      name="subscriberIdSource"
      className="input-group"
      onChange={onChange}
      defaultValue={translate(selectInputValue)}
      options={sonarBillingSubscribers}
    />
    <Grouped>
      <SelectInput
        label="Blocking based on"
        name="sonarDelinquent"
        className="input-group column-prefer-left"
        onChange={(event) =>
          onChange(null, {
            name: "sonarDelinquent",
            value: event.target.value === "true",
          })
        }
        defaultValue={defaults.sonarDelinquent || false}
        options={sonarDelinquentOptions}
      />
      {state.sonarDelinquent === true ? null : (
        <NonBlockingStatus
          className="has-input-groups column-prefer-left"
          onChange={({ list }) =>
            onChange(null, { name: "sonarNonBlockingStatus", value: list })
          }
          state={state}
          defaults={{ list: defaults.sonarNonBlockingStatus || [] }}
        />
      )}
    </Grouped>
    <Grouped className="align-bottom">
      <PolicyNameFromSonarInput
        onChange={onChange}
        defaults={defaults}
        state={state}
        className="input-group"
      />
    </Grouped>
  </>
)};

export default SonarBillingSettings;
