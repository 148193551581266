const _WARNING_HEAD = "%WARN-ENOENT: ";
export const _DEFAULT_SETTINGS = {
  enabled: false,
  name: "",
  contact: "",
  description: "",
  location: "",
  tableCommunity: [],
  tableTrap: [],
};

const lowerWord = (word) => word.toLowerCase();

const asJSAttribute = (input) => input.split(/\s+/).map(lowerWord).join(" ");

const newBlock = (...codes) =>
  `\n${codes
    .filter((code) => code !== undefined && code.length > 0)
    .map((code) => code.trim("\n"))
    .join("\n")}\n`;

/************************************************
 * Status
 ************************************************/
const parseSystemStatusRow = (row) => {
  const [_instance, running, state] = row.trim().split(/\s+/);
  return {
    state: state === "running" || state === "init",
    running: running === "yes",
  };
};

const includeSystemStatusRow = (current, { state }) => ({
  enabled: current.enabled || state,
});

const parseSystemStatus = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseSystemStatusRow).reduce(includeSystemStatusRow, {});
};
/************************************************
 * Fields
 ************************************************/
const _DEFAULT_SETTINGS_ = {
  contact: "",
  description: "",
  name: "",
  location: "",
};

const includeSystemSetting = (settings, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);

  return {
    ...settings,
    [asJSAttribute(srcField)]: srcValue === "n/a" ? "" : srcValue,
  };
};

const parseSystemSettingFact = (input) => {
  const [...rows] = input.trim("\n").split("\n");
  return rows.reduce(includeSystemSetting, _DEFAULT_SETTINGS_);
};

const preventNoEnt =
  (func, placeholder = []) =>
  (input, ...args) =>
    input.startsWith(_WARNING_HEAD) ? placeholder : func(input, ...args);

export const parseSystemSettings = preventNoEnt(parseSystemSettingFact, {
  ..._DEFAULT_SETTINGS,
});

const parseCommityItem = (row) => {
  const [nms, name] = row.trim().split(/\s+ /);
  const traslateNMS = nms.trim().split(/\*/g);
  return {
    name: name,
    nms: traslateNMS[0],
  };
};

const parseCommunitiesItems = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseCommityItem);
};

const parseTrapItem = (row) => {
  const [nms, type, community] = row.trim().split(/\s+ /);
  return {
    nms: nms,
    type: type,
    community: community === "n/a" ? "" : community,
  };
};

const parseTrapsItems = (input) => {
  const [_head, ...rows] = input.trim("\n").split("\n");
  return rows.map(parseTrapItem);
};

export const parseTraps = preventNoEnt(parseTrapsItems);

export const parseCommunities = preventNoEnt(parseCommunitiesItems);

export const parseStatus = preventNoEnt(parseSystemStatus);

/**************************************************
 * Build command
 **************************************************/
export class InvalidFieldValue extends Error {
  constructor(field, reason) {
    super(`the "${field}" field ${reason}`);
  }
}
const disableCommand = () =>
  newBlock("configure", "no snmp", "commit").trim("\n");

const updateSetting = (field, previous = "", value = "") =>
  value === previous
    ? null /*No changes*/
    : value.length === 0 && previous.length > 0
    ? `no sys ${field} "${previous}"`
    : `sys ${field} "${value}"`;

const systemSettings = (previous, { contact, description, location, name }) =>
  [
    updateSetting("contact", previous.contact, contact),
    updateSetting("description", previous.description, description),
    updateSetting("location", previous.location, location),
    updateSetting("name", previous.name, name),
  ].filter((term) => term !== null);

const ensureSomeSettingsChanged = (...parts) => {
  if (parts.flatMap((part) => (part === null ? [] : part)).length === 0) {
    throw "No changes available to commit.";
  }
  return parts;
};

const composeSaveCommunities = (communities) =>
  communities.map(({ name, nms }) => `community "${name}" ${nms}`);

const composeClearCommunities = (communities) =>
  communities.map(({ name, nms }) => `no community "${name}" ${nms}`);

const normalizeTrapType = (type) =>
  type === "SNMPv1" || type === "trap-v1" ? "trap-v1" : "trap-v2c";

const composeSaveTraps = (traps) =>
  traps.map(
    ({ nms, community, type }) =>
      `${normalizeTrapType(type)} ${nms} "${community}"`
  );

const composeClearTraps = (traps) =>
  traps.map(
    ({ nms, community, type }) =>
      `no ${normalizeTrapType(type)} ${nms} "${community}"`
  );

const isNotSavedOrDeleted = ({ stored, deleted }) =>
  stored !== true && deleted !== true;

const isSavedAndDeleted = ({ stored, deleted }) =>
  stored === true && deleted === true;

const isNotDeleted = ({ deleted }) => deleted !== true;

const isNMSEmpty = ({ nms }) => nms === undefined || nms === "";

const composeCommunitiesUpdate = (previous, communities) => [
  ...composeSaveCommunities(communities.filter(isNotSavedOrDeleted)),
  ...composeClearCommunities(communities.filter(isSavedAndDeleted)),
];

const composeTrapsUpdate = (previous, traps) => [
  ...composeSaveTraps(traps.filter(isNotSavedOrDeleted)),
  ...composeClearTraps(traps.filter(isSavedAndDeleted)),
];

const communitiesSettings = (previous, { communities = [] }) => {
  const remaining = communities.filter(isNotDeleted);
  if (remaining.length === 0) {
    throw "SNMP Community name not found to apply changes. Please, add at least one community to continue...";
  }
  const empty = remaining.filter(isNMSEmpty);
  if (empty.length > 1) {
    throw "Already exists a community entry without addresses.";
  }
  return composeCommunitiesUpdate(previous.communities || [], communities);
};

const trapsSettings = (previous, { communities = [], traps = [] }) => {
  const communityNames = communities.map(({ name }) => name);
  traps.find(({ community }) => {
    if (communityNames.includes(community) !== true) {
      throw `SNMP Trap community "${community}" name not found`;
    }
    return true;
  });
  return composeTrapsUpdate(previous.traps || [], traps);
};

const enableCommand = (previous, settings) =>
  newBlock(
    "configure",
    "snmp",
    ...ensureSomeSettingsChanged(
      ...systemSettings(previous, settings),
      ...communitiesSettings(previous, settings),
      ...trapsSettings(previous, settings)
    ),
    "commit"
  ).trim("\n");

export const composeApplyCommand = (previous, settings) =>
  settings.enabled === false && previous.enabled === true
    ? disableCommand()
    : enableCommand(previous, settings);
