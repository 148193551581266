import React from "react";
import TextInput from "common/TextInput";
import NumberInputWithSpinner from "common/NumberInputWithSpinner";
import SwitchInput from "common/SwitchInput";

const doesNothing = () => {};

const AskFilter = ({ onChange = doesNothing }) => (
  <TextInput
    name="filter"
    label="Filter (tcpdump-like filter):"
    className="input-control"
    defaultValue={""}
    onChange={onChange}
    icon="location_on"
  />
);

const AskVLAN = ({ onChange = doesNothing }) => (
  <SwitchInput
    name="vlan"
    label="VLAN (set if filtering in network with VLANs)"
    className="input-control"
    defaultValue={""}
    onChange={onChange}
  />
);

const AskPPPoE = ({ onChange = doesNothing }) => (
  <SwitchInput
    name="pppoe"
    label="PPPoE (set if filtering in network with PPPoE)"
    className="input-control"
    defaultValue={""}
    onChange={onChange}
  />
);

const AskSize = ({ onChange = doesNothing }) => (
  <NumberInputWithSpinner
    name="size"
    label="Maximum capture file size (Mbytes):"
    className="input-control"
    defaultValue={1}
    onChange={onChange}
    icon="cloud_queue"
    max={499}
    min={1}
    required={true}
  />
);

const AskTimeout = ({ onChange = doesNothing }) => (
  <NumberInputWithSpinner
    name="timeout"
    label="Capture timeout (seconds):"
    className="input-control"
    defaultValue={30}
    onChange={onChange}
    icon="timer"
    max={600}
    min={1}
    required={true}
  />
);

const Fields = ({ onChange = doesNothing }) => (
  <>
    <AskFilter onChange={onChange} />
    <AskVLAN onChange={onChange} />
    <AskPPPoE onChange={onChange} />
    <AskSize onChange={onChange} />
    <AskTimeout onChange={onChange} />
  </>
);

export default Fields;
