import React from "react";
import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseFloatOrNull = (value, ponderation = 1) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? parseFloat((parseFloat(value.trim("%")) / ponderation).toFixed(4))
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const capitalizeFirstLetter = (word) =>
  word[0].toUpperCase() + word.slice(1).toLowerCase();

const lowerFirstUpperOthers = (word, index) =>
  index === 0 ? word.toLowerCase() : capitalizeFirstLetter(word);

const asJSAttribute = (input) =>
  input
    .split(/[\s-]+/)
    .map(lowerFirstUpperOthers)
    .join("");

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

const timeAndPercents = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);

export const parseRTTTable = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    TIME: "time",
    GLOBAL: "global",
    "SMALL-RTTi": "small",
    "MEDIUM-RTTi": "medium",
    "LARGE-RTTi": "large",
  };
  const skipped = [];
  const keys = head
    .trim(" ")
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndNumbers(row, keys, skipped))
  );
};

export const parseRTTPercentsTable = (input) => {
  const [head, ...rows] = input.trim("\n").split("\n");
  const translations = {
    TIME: "time",
    GLOBAL: "global",
    "SMALL-RTTi": "small",
    "MEDIUM-RTTi": "medium",
    "LARGE-RTTi": "large",
  };
  const skipped = [];
  const keys = head
    .trim(" ")
    .split(/\s+/)
    .map((key) =>
      skipped.includes(key) ? key : translations[key] || asJSAttribute(key)
    );
  return rows.map((row) =>
    Object.fromEntries(timeAndPercents(row, keys, skipped))
  );
};

const expressLink = (direction) =>
  direction === "down" ? "downlink" : direction === "up" ? "uplink" : "all";

const expressType = (type) =>
  type === "optimized"
    ? "optimized"
    : type === "tracked"
    ? "tracked"
    : type === "acceleration"
    ? "acceleration"
    : "all";

export const requestRTT = (settings = {}) => {
  const { direction = "down", type = "tracked", days = 1 } = settings;
  return ifCl.run(
    `show statistics speed ${expressType(type)} ${expressLink(direction)}` +
      ` aggregated lines ${24 * 12 * days}`
  );
};

const prefixedWith = (prefix, data, excluding = ["time"]) =>
  Object.fromEntries(
    Object.entries(data).map(([field, value]) =>
      excluding.includes(field) ? [field, value] : [`${prefix}-${field}`, value]
    )
  );

const decideInclusion = (prefix, inclusion, { time, ...data }) => {
  const targetAsSeconds = time.getTime();
  const inclusionAsSeconds = inclusion.time.getTime();
  const prefixedInclusion = prefixedWith(prefix, inclusion);
  return targetAsSeconds === inclusionAsSeconds
    ? [{ time, ...data, ...prefixedInclusion }]
    : targetAsSeconds < inclusionAsSeconds
    ? [{ time, ...data }, prefixedInclusion]
    : [prefixedInclusion, { time, ...data }];
};

export const mergeWith = (prefix, includes, main) => {
  let result = [];
  do {
    const next = main.shift();
    const inclusion = includes.shift();
    result =
      next === undefined && inclusion === undefined
        ? result
        : inclusion === undefined
        ? [...result, next, ...main]
        : next === undefined
        ? [
            ...result,
            prefixedWith(prefix, inclusion),
            ...includes.map((item) => prefixedWith(prefix, item)),
          ]
        : [...result, ...decideInclusion(prefix, inclusion, next)];
  } while (includes.length > 1 && main.length > 1);
  return result;
};
