/*globals showModalError, views, rulesRate, showModalConfirm*/

import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { safeStr } from 'common/api';

const doesNothing = () => {};

const returnView = "viewRulesRate";
const returnTab = "rulesRateTabPolicies";

/*****************************************************
 * Radius-Rest Policies View
 * ****************************************************/

export const SubscriberRatesPoliciesTable = () => {
  const [request, setRequest] = useState(null);

  const doRequest = () => ifCl.run("show policy rate full").then( response => ({response}));

  const doLoad = () => {
    setRequest(doRequest());
  };

  const enableViewReload = (main) => {
    if (main === null || main.current === null) {
      return;
    }
    const tab = main.closest(`#${returnTab}`);
    $(tab).one("view:show", () => doLoad());
    const view = tab.closest(`#${returnView}`);
    $(view).one("view:show", () => doLoad());
    console.log("enabled reload on show", main);
  };

  useEffect(() => doLoad(), []);

  return request === null ? null : (
    <div className="row clearfix" ref={enableViewReload}>
      <div className="body table-responsive">
        <Request during={request}>
          {(result) => console.warn(result) ||  <ShowPolicies {...result} reload={doLoad} />}
        </Request>
      </div>
    </div>
  );
};

const notFoundRE = /%ERR-ENOENT: Cannot find "(.*)" policy/;

const requestPolicy = policy =>
  ifCl
    .run(`show policy ${safeStr(policy)}`)

const doShowDynamicPolicy = (name) => {
  const policyRate = rulesRate.data.policies;
  requestPolicy(name)
    .then( response  => policyRate.parsePolicy(response))
    .then( policy => {
      views.doKeep(returnView);
      policyRate.showDynPolicy(policy);
    });
};
/*****************************************************
 * Policies Table
 * ****************************************************/

const configPolicyActions = ([name, row], whenDone) => [
  {
    id: "edit-config",
    label: "Edit",
    icon: "edit",
    onClick: (_value, [name, _id, source, ..._rest]) => doEditPolicy(name, whenDone),
  },
  ...( 
    name === 'rate-default'
      ? []
      : [{
        id: "delete-config",
        label: "Delete",
        icon: "delete",
        disabled: name === 'rate-default',
        onClick: (_value, [name, _id, source, ..._rest]) => doDeletePolicy(name, whenDone),
      }]
  ),
];
const otherPolicyActions = (row, whenDone) => [
  {
    id: "view-others",
    label: "View",
    icon: "search",
    onClick: (_value, [name, ..._rest]) => doShowDynamicPolicy(name),
  },
  {
    id: "delete-others",
    label: "Delete",
    icon: "delete",
    onClick: (_value, [name, _id, source, ..._rest]) => doDeleteOtherPolicy(name, whenDone),
  }
];
const undefinedPolicyActions = (row) => [
  {
    id: "add-undefined",
    label: "Add",
    icon: "add_circle",
    onClick: (_value, [name, ..._rest]) => doAddPolicy(name),
  },
];

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;
const ShowPolicies = ({ response, reload=doesNothing }) => (
  <>
    <TableContainer>
      <DataTable
        pageLength={50}
        exportAsCSV={true}
        content={response}
        columns={[
          Column.Text({
            label: "NAME",
            idx: 0,
          }),
          Column.Text({
            label: "BLOCK",
            idx: 1,
          }),
          Column.SpeedOrNo({
            label: "RATE-LIMIT-DOWN",
            idx: 5,
            colClassName: "align-right",
          }),
          Column.SpeedOrNo({
            label: "RATE-LIMIT-UP",
            idx: 6,
            colClassName: "align-right",
          }),
          Column.Value(
            (flowLimit) => (flowLimit === 'n/a' || flowLimit === 'no') ? 'no': flowLimit,
            {
              label: "FLOW-LIMIT",
              idx: 7,
              colClassName: "align-right",
            }),
          Column.Text({
            label: "ACM",
            idx: 3,
          }),
          Column.Value(
            (source) => source === 'dynamic' ? 'no': 'yes',
            {
              label: "CONFIGURED",
              idx: 4,
            }),
          Column.Actions({
            label: "ACTIONS",
            idx: 4,
            are: (source, row) =>
              source === "static"
                ? configPolicyActions(row, reload)
                : source === "undefined"
                ? undefinedPolicyActions(row, reload)
                : otherPolicyActions(row, reload),
            colClassName: "text-center action",
            cellClassName: "hides-content-to-operators",
          }),
        ]}
      />
    </TableContainer>
  </>
);

const doEditPolicy = (policy) => {
  requestPolicy(policy)
    .then((response) => {
      const ratePolicies = rulesRate.data.policies;
      const policy = ratePolicies.parsePolicy(response);
      views.doKeep(returnView);
      ratePolicies.editPolicy(policy, returnView);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        showModalError("Error:", "Unknow policy " + missingName);
      } else {
        throw error;
      }
    });
};

const doAddPolicy = (policy) => {
  requestPolicy()
    .then((_resp) => {
      showModalError("Error:", "Already defined policy " + policy);
    })
    .catch((error) => {
      const notFound = error.match(notFoundRE);
      if (notFound !== null) {
        const [_all, missingName, ..._rest] = notFound;
        return { missing: missingName };
      }
      showModalError("Error:", error);
    })
    .then(({ missing }) => {
      if (missing !== undefined) {
        views.doKeep(returnView);
        rulesRate.data.policies.addPolicy(returnView, missing);
      } else {
        console.error("missing policy to create is missinh");
      }
    });
};

const doDeletePolicy = (name, whenDone=doesNothing) =>
  showModalConfirm(
    `Remove "${name}" policy?`,
    'WARNING: All subscribers assigned to this policy will be reassigned to a default rate policy following the BQN configured rules (normally default policy is "rate-default").',
    "delete_forever",
    function () {
      window.setTimeout(function () {
        //TODO Fix this code
        ifCl.execute(`clear policy rate ${safeStr(name)}`, function (resp) {
          var response = resp.CmdResponse.response;
          if (
            response.length == 0 ||
            response.indexOf("Conflict between ") > -1
          ) {
            whenDone();
          } else {
            showModalError("Warning:", resp.CmdResponse.response);
          }
        });
      }, 32);
    }
  );

  const doDeleteOtherPolicy = (name, whenDone=doesNothing) =>
    showModalConfirm(
      `Remove "${name}" policy?`,
      'WARNING: All subscribers assigned to this policy will be reassigned to a default rate policy following the BQN configured rules (normally default policy is "rate-default").',
      "delete_forever",
      function () {
        window.setTimeout(function () {
          //TODO Fix this code
          ifCl.execute(`clear api policy ${safeStr(name)}`, function (resp) {
            var response = resp.CmdResponse.response;
            if (
              response.length == 0 ||
              response.indexOf("Conflict between ") > -1
            ) {
              whenDone();
            } else {
              showModalError("Warning:", resp.CmdResponse.response);
            }
          });
        }, 32);
      }
    );

const doOpenActiveSubscribers = (name, row) => {
  const activeSubs = row.at(8);
  if (Number(activeSubs) > 0) {
    view.doKeep(returnView);
    globalNavigate("viewStatusSubscribers", { returnView, policy: name });
  }
};
