import React from "react";
import TextInput from "common/TextInput";

const AskRange = ({ range, onChange, disabled = false }) => (
  <TextInput
    type="text"
    name="range"
    label="IP Network or Range:"
    className="input-control"
    defaultValue={range}
    onChange={onChange}
    icon="cloud_queue"
    disabled={disabled}
    placeholder={"Ex: 10.1.0.1 or 2001:db8:80::"}
  />
);

const Fields = ({ onChange, __id, range, stored = false }) => (
  <>
    <AskRange range={range} onChange={onChange} disabled={stored} />
  </>
);
export default Fields;
