import React, { useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { getTooltipTitle } from "../../Statistics/SubscriberDetail/livemetrics.utils";

const StyledTooltipContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 6px;
  transform: translate(-50%, -50%);
  font-family: "AllianceNo2-Regular" !important;
  z-index: 2000;
`;

const TooltipHeader = styled.div`
  font-family: "AllianceNo2-SemiBold" !important;
  font-size: 13px;
  font-weight: 100 !important;
`;

const TooltipContent = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TooltipColor = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 12px;
  height: 12px;
  border: 1px solid #999;
`;

const TooltipKey = styled.div`
  margin: 0 4px;
  text-overflow: ellipsis;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
`;

const TooltipValue = styled.div`
  margin-right: 10px;
  text-align: right;
  width: 80px;
  white-space: nowrap;
`;

const TooltipLabel = styled.div`
  width: 190px;
  display: flex;
  align-items: center;
`;

const TooltipLabelTotal = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 10px;
`;

const TooltipContentWrapper = styled.div`
  width: ${(props) => (props.large ? "560px" : "280px")};
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 1200px) {
    width: 280px;
  }
`;

const TooltipContentTotal = styled.div`
  margin: 6px 0 2px;
  display: block;
  width: 100%;
`;

const getCorrection = (mouseX, chartWidth, isSmallScreen) => {
  if (isSmallScreen) {
    const isLeftSide = mouseX < chartWidth * 0.4;
    return isLeftSide ? 260 : -100;
  }
  const isLeftSide = mouseX < chartWidth * 0.25;
  if (isLeftSide) {
    return chartWidth / 2 > 400 ? 400 : chartWidth / 2;
  }
  return -290;
};

const EntryTotal = ({ valueAverage, valueTotal }) => {
  return (
    <TooltipContentTotal>
      <TooltipLabelTotal key="tootltip-total">
        Total {d3.format(".3f")(valueTotal)} Mbps
      </TooltipLabelTotal>
    </TooltipContentTotal>
  );
};

const Entry = ({ label, value, color }) => {
  return (
    <TooltipContent key={`${label}-value`}>
      <TooltipLabel>
        <TooltipColor bgColor={color}></TooltipColor>
        <TooltipKey>{label}</TooltipKey>
      </TooltipLabel>
      <TooltipValue>{d3.format(".3f")(value)} Mbps</TooltipValue>
    </TooltipContent>
  );
};

const Tooltip = ({
  dataPoint,
  mouseX,
  mouseY,
  title,
  mouseEvent,
  unit,
  chartWidth,
  fieldsMap,
  filterFields,
  times,
  type,
}) => {
  const { time, fields, value, totalUp, totalDown } = dataPoint || {};
  if (!time) {
    return null;
  }
  const totalSpeedValue =
    type === "Downlink" ? totalDown : totalUp;
  const currentFields = Object.entries(value).reduce(
    (acc, [fieldName, fieldValue]) => {
      if (
        fieldName !== "time" &&
        fieldName !== "rest" &&
        filterFields.has(fieldName)
      ) {
        const { color } = fieldsMap[fieldName];
        acc.total = acc.total + fieldValue;
        acc.series.push({ color, name: fieldName, value: fieldValue });
      }
      if (fieldName === "rest" && filterFields.has("rest")) {
        const { color } = fieldsMap["rest"];
        acc.total = acc.total + fieldValue;
        acc.rest = { color, value: fieldValue };
      }
      return acc;
    },
    { series: [], rest: undefined, total: 0 }
  );

  const isSmallScreen = chartWidth < 1000;
  const correction = getCorrection(mouseX, chartWidth, isSmallScreen);

  function sortSeries(input){
    const tmp =  input.sort((a,b) => {
      if(a.value > b.value){
        return 1;
      }else if(a.value < b.value){
        return -1;
      }else{
        return 0;
      }
    })

    const center = Math.floor(input.length/2);
    const output = [];
    for(let i = 0; i<center; i++){
      output.push(input[i], input[center + i])
    }

    if(center % 2 === 1){
      output.push(input[input.length-1])
    }

    return tmp;
  }



  return (
    <StyledTooltipContainer
      style={{
        top: mouseY - 105 + "px",
        left: mouseX + correction + "px",
      }}
      className="tooltip-live"
    >
      <TooltipHeader>{getTooltipTitle(title)}</TooltipHeader>
      <TooltipContentWrapper large={!isSmallScreen}>
      {currentFields.rest ? (
          <Entry
            label="REST"
            color={currentFields.rest.color}
            value={currentFields.rest.value}
            key="tooltip-rest"
          />
        ) : null}
        {sortSeries(currentFields.series).map(({ name, color, value }, index) => {
          if (value === null || value === 0 || (isSmallScreen && index > 9)) {
            return null;
          }
          return (
            <Entry
              label={name}
              value={value}
              color={color}
              key={`tooltip-${name}`}
            />
          );
        })}
      </TooltipContentWrapper>
      {currentFields.series.length > 0 ? (
        <EntryTotal
          valueTotal={currentFields.total}
          valueAverage={totalSpeedValue / (times-1)}
          key="tooltip-total"
        />
      ) : null}
    </StyledTooltipContainer>
  );
};

export default Tooltip;
