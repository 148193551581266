import React, { useState, useEffect, useContext } from "react";
import {
  parseStatus,
  parseUsers,
  parseClients,
  parseManagementDetail,
  composeApplyCommand,
} from "./api";
import SwitchInput from "common/SwitchInput";
import ActionsContext from "common/ActionsContext";
import ConfigRESTHelpModal from "./Help";
import SystemSettings from "./SystemSettings";
import Users from "./Users";
import Clients from "./Clients";
import SettingsView from "common/SettingsView";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const _DEFAULT_PORT = 3443;

const provideConfiguredPortWhenStable = ({state, port, ...rest}) =>
  ({
    state,
    port: state !== 'ready' ? _DEFAULT_PORT : port,
    ...rest
  });

const provideAddressAndInterfaceFrom = (provider) =>
  (previous) =>
    provider().then( result => ({
      ...previous,
      'address': result.address,
      'interface': result.interface,
    }))

const getManagementDetail = () =>
  ifCl.run("show interface management detail").then(parseManagementDetail);

const getRESTStatus = () =>
  ifCl
    .run("show api rest server")
    .then(parseStatus)
    .then(provideConfiguredPortWhenStable)
    .then(provideAddressAndInterfaceFrom(getManagementDetail))

const enumerate =
  (start = 1, extraParams) =>
  (list) =>
    list.map((item, index) => ({
      ...item,
      __id: start + index,
      ...extraParams,
    }));

const getClients = () =>
  ifCl
    .run("show api rest client")
    .then(parseClients)
    .then(enumerate(1, { stored: true }));

const getUsers = () =>
  ifCl
    .run("show api rest user")
    .then(parseUsers)
    .then(enumerate(1, { stored: true }));

const arrangeIntoSettings = ([
  status = {},
  clients = [],
  users = [],
]) => ({
  ...status,
  clients,
  users,
});

const getRESTSettings = () =>
  Promise.all([
    getRESTStatus(),
    getClients(),
    getUsers(),
  ]).then(arrangeIntoSettings);

const RESTContextMenu = ({ doLoad = doesNothing }) => {
  const [optionsEnabled, setOptionsEnabled] = useState(true);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("state-changed", (state) =>
        setOptionsEnabled(state.enabled === true)
      ),
    []
  );

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      {optionsEnabled === true ? (
        <li className="dropdown hidden-to-operators">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a onClick={() => actions.send("new-client")}>
                Add a Client Entry...
              </a>
            </li>
            <li>
              <a onClick={() => actions.send("new-user")}>
                Add a User Entry...
              </a>
            </li>
          </ul>
        </li>
      ) : null}
    </ul>
  );
};

const RESTConfig = () => {
  const load = () => getRESTSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () => composeApplyCommand(originalSettings, settings);
    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };

  return (
    <SettingsView
      className="container-xl"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={RESTContextMenu}
      title="REST settings"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowRESTSettings />
      <ConfigRESTHelpModal />
    </SettingsView>
  );
};

const ShowRESTSettings = ({
  isOperatorProfile = false,
  defaults,
  state,
  onStateUpdate = doesNothing,
}) => {
  const handleEnabledSwitch = (event) => {
    const { type } = event.target;
    let { value } = event.target;
    value = type === "checkbox" ? event.target.checked === true : value;
    onStateUpdate({ enabled: value });
  };

  const updateUsers = (update) => {
    onStateUpdate({ users: update });
  };

  const updateClients = (update) => {
    onStateUpdate({ clients: update });
  };

  const updateSystemSettings = (update) => {
    onStateUpdate({ ...update });
  };

  return (
    <div className="body">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin-0 padding-0 padding-l-15 padding-r-15">
          <SwitchInput
            defaultChecked={defaults.enabled}
            disabled={isOperatorProfile}
            className={"sw-input"}
            name="enabled"
            label={`REST API Service: ${
              state.enabled === false ? "disabled" : "enabled"
            }`}
            onChange={handleEnabledSwitch}
          />
        </div>
      </div>
      {state.enabled === true ? (
        <>
          <SystemSettings
            onChange={updateSystemSettings}
            defaults={defaults}
            state={state}
          />
          <ArrangeInColumns>
            <div>
              <h4>Clients</h4>
              <Clients initial={defaults.clients} onChange={updateClients} />
            </div>
            <div>
              <h4>Users</h4>
              <Users initial={defaults.users} onChange={updateUsers} />
            </div>
          </ArrangeInColumns>
        </>
      ) : null}
    </div>
  );
};

export default RESTConfig;
