import React, { useState, useEffect, useContext } from "react";
import {
  parseStatus,
  parseSystemSettings,
  parseTraps,
  parseCommunities,
  composeApplyCommand,
} from "./api";
import SwitchInput from "common/SwitchInput";
import ActionsContext from "common/ActionsContext";
import ConfigSNMPHelpModal from "./Help";
import SystemSettings from "./SystemSettings";
import Traps from "./Traps";
import Communities from "./Communities";
import SettingsView from "common/SettingsView";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getSNMPStatus = () => ifCl.run("show snmp state").then(parseStatus);

const getSystemSettings = () =>
  ifCl.run("show snmp system").then(parseSystemSettings);

const getCommunities = () =>
  ifCl.run("show snmp community").then(parseCommunities);

const getTraps = () => ifCl.run("show snmp trap").then(parseTraps);

const arrangeIntoSettings = ([
  status = {},
  settings = {},
  communities = [],
  traps = [],
]) => ({
  enabled: status.enabled || false,
  ...settings,
  communities,
  traps,
});

const getSNMPSettings = () =>
  Promise.all([
    getSNMPStatus(),
    getSystemSettings(),
    getCommunities(),
    getTraps(),
  ])
    .then(arrangeIntoSettings)
    .then((result) => console.log("log", result) || result);

const SNMPContextMenu = ({ doLoad = doesNothing }) => {
  const [optionsEnabled, setOptionsEnabled] = useState(true);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("state-changed", (state) =>
        setOptionsEnabled(state.enabled === true)
      ),
    []
  );

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      {optionsEnabled === true ? (
        <li className="dropdown hidden-to-operators">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a onClick={() => actions.send("new-community")}>
                Add a Community Entry...
              </a>
            </li>
            <li>
              <a onClick={() => actions.send("new-trap")}>
                Add a Trap Entry...
              </a>
            </li>
          </ul>
        </li>
      ) : null}
    </ul>
  );
};

const AdminSNMP = () => {
  const load = () => getSNMPSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () => composeApplyCommand(originalSettings, settings);
    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };

  return (
    <SettingsView
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={SNMPContextMenu}
      title="SNMP settings"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
      id="viewSNMPConf"
    >
      <ShowAdminSNMPSettings />
      <ConfigSNMPHelpModal />
    </SettingsView>
  );
};

const ShowAdminSNMPSettings = ({
  isOperatorProfile = false,
  defaults,
  state,
  onStateUpdate = doesNothing,
}) => {
  const handleEnabledSwitch = (event) => {
    const { type } = event.target;
    let { value } = event.target;
    value = type === "checkbox" ? event.target.checked === true : value;
    onStateUpdate({ enabled: value });
  };

  const updateTraps = (update) => {
    onStateUpdate({ traps: update });
  };

  const updateCommunities = (update) => {
    onStateUpdate({ communities: update });
  };

  const updateSystemSettings = (update) => {
    onStateUpdate({ ...update });
  };

  return (
    <div className="body">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 margin-0 padding-0 padding-l-15 padding-r-15">
          <SwitchInput
            defaultChecked={defaults.enabled}
            disabled={isOperatorProfile}
            className={"sw-input"}
            name="snmpEnabled"
            label={`SNMP Service: ${
              state.enabled === false ? "disabled" : "enabled"
            }`}
            onChange={handleEnabledSwitch}
          />
        </div>
      </div>
      {state.enabled === true ? (
        <>
          <h4>System Parameters</h4>
          <SystemSettings onChange={updateSystemSettings} defaults={defaults} />
          <ArrangeInColumns rowGap="0">
            <div>
              <h4>Communities</h4>
              <Communities
                initial={defaults.communities}
                onChange={updateCommunities}
              />
            </div>
            <div>
              <h4>Traps</h4>
              <Traps
                initial={defaults.traps}
                communities={defaults.communities}
                onChange={updateTraps}
              />
            </div>
          </ArrangeInColumns>
        </>
      ) : null}
    </div>
  );
};

export default AdminSNMP;
