import { parseDate } from "../utils";

export function getKeysSeries(header) {
  const [, ...keys] = header.split(/\s+/);
  return keys.map((key) => {
    const chunks = key.split("-").map((item) => {
      return item.toUpperCase();
    });
    return chunks.join("-");
  });
}

export const parseStatsCpuData = (response, period, beginRange, endRange) => {
  if (!response) {
    return { fields: [], items: [] };
  }
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const [, ...keys] = getKeysSeries(header);
  const keysSeries = ["Average", ...keys];
  const newKeys = ["time", ...keysSeries];

  if (rows.length === 0) {
    return { fields: keysSeries, items: [] };
  }

  const newRows = rows.reduce((acc, value) => {
    const [timeAt, ...attrs] = value.split(/\s+/);
    const time = parseDate(timeAt);
    if (beginRange < time && time < endRange) {
      const item = attrs.reduce(
        (acc, value, index) => {
          acc[newKeys[index + 1]] = value === "n/a" ? null : Number(value);
          return acc;
        },
        { time }
      );
      acc.push(item);
    }
    return acc;
  }, []);

  return { fields: keysSeries, items: newRows };
};

export function getInRangeItems({
  items,
  range,
  fields,
  sampleSize,
  cpu,
  chart,
}) {
  if (items.length === 0 || fields.length === 0) {
    return [];
  }
  const { from, to } = range;
  let inrangeItems = [];
  let maxItems;
  const selFields = cpu === "All" ? fields.map((field) => field.name) : [cpu];

  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (!maxItems) {
        maxItems = getMaxItems(chart, maxItems, selFields, items, i, time);
      }
      if (i % sampleSize === 0) {
        inrangeItems.push(maxItems);
        maxItems = getMaxItems(chart, maxItems, selFields, items, i, time);
      } else {
        if (chart === "busy") {
          const newMax = selFields.reduce((acc, value) => {
            if (items[i][value] && 100 - items[i][value] > acc[value]) {
              acc[value] = 100 - items[i][value];
            }
            return acc;
          }, maxItems);
          maxItems = newMax;
        } else {
          const newMax = selFields.reduce((acc, value) => {
            if (items[i][value] > acc[value]) {
              acc[value] = items[i][value];
            }
            return acc;
          }, maxItems);
          maxItems = newMax;
        }
      }
    }
  }

  if (inrangeItems.length === 0) {
    return [];
  }

  if (
    inrangeItems[inrangeItems.length - 1]?.time &&
    items[items.length - 1]?.time &&
    inrangeItems[inrangeItems.length - 1].time !== items[items.length - 1]?.time
  ) {
    const { time } = items[items.length - 1];
    if (from <= time && time <= to) {
      const endValue = getMaxItems(
        chart,
        undefined,
        selFields,
        items,
        items.length - 1,
        time
      );
      inrangeItems.push(endValue);
    }
  }

  let extendedItems = [...inrangeItems];
  if (extendedItems.length !== 0) {
    while (extendedItems[0].time >= from) {
      const emptyElement = selFields.reduce((acc, value) => {
        acc[value] = null;
        return acc;
      }, {});
      const time = new Date(
        Date.parse(extendedItems[0].time) - 5 * 60000 * sampleSize
      );
      extendedItems.unshift({ ...emptyElement, time });
    }
  }
  return extendedItems;
}

function getMaxItems(chart, maxItems, fields, items, i, time) {
  if (chart === "busy") {
    maxItems = fields.reduce(
      (acc, value) => {
        acc[value] = items[i][value] ? 100 - items[i][value] : null;
        return acc;
      },
      { time }
    );
  } else {
    maxItems = fields.reduce(
      (acc, value) => {
        acc[value] = items[i][value];
        return acc;
      },
      { time }
    );
  }
  return maxItems;
}
