import React, {useContext, useState, useEffect} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { parsePoliciesIntoSets } from "../api";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import SelectInput from "common/SelectInput";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { isValidIPValue, safeStr } from "common/api";
import { createPortal } from 'react-dom';

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-top: 26px;
  }
`;

const returnTo = "viewSubscriberQuotas";
const notifyIfPolicyNotFound = (error) => {
  const notFound = error.match(/%ERR-ENOENT: Cannot find "(.*)" policy/);
  if (notFound !== null) {
    const [_add, policyName] = notFound;
    const message = `Assigned policy ${policyName} is not defined. \
    A policy with this name needs to be configured, or the subscriber will be assigned\
    a configured policy according to the existing rules`;
    showModalError("Error:", message);
  }
  throw error;
};

const openPolicy = (name) => {
  const policiesInSets = ifCl.run("show policy").then(parsePoliciesIntoSets);
  policiesInSets.then(({ types, sources }) => {
    views.doKeep(returnTo);
    ifCl
      .run(`show policy ${safeStr(name)}`)
      .catch(notifyIfPolicyNotFound)
      .then((response) =>
        types.rate.has(name)
          ? rulesRate.data.policies.parsePolicy(response)
          : types.flow.has(name)
          ? rulesFlow.data.policies.parsePolicy(response)
          : rulesMonitor.data.policies.parsePolicy(response)
      )
      .then((policy) =>
        sources.static.has(name) !== true
          ? rulesRate.data.policies.showDynPolicy(policy)
          : types.rate.has(name)
          ? rulesRate.data.policies.editPolicy(policy, returnTo)
          : types.flow.has(name)
          ? rulesFlow.data.policies.editPolicy(policy, returnTo)
          : rulesMonitor.data.policies.editPolicy(policy, returnTo)
      );
  });
};

const openSubscriberDetails = (target, row) => {
  views.doKeep(returnTo);
  globalNavigate("viewSubsDetail", {returnView: returnTo, ...target});
};

function filterQuoteIsNotNA(row){
  return row[2]!=='n/a';
}

const VOLUME_LIMIT = 1000 * 1000 /*TB*/;

const EditQuotasModal = ({
  onOk = doesNothing,
  onClose = doesNothing,
  params,
}) => {
  const isVolumeDefined = params?!isNaN(params.currentVolumeLimit):false;
  const isTimeLimitDefined = !!params?.currentTimeLimit;
  const initTimeLimit = isTimeLimitDefined?new Date(params.currentTimeLimit):'';
  
  const [timeLimit, setTimeLimit] = useState(initTimeLimit);
  const [currentVolumeLimit, setCurrentVolumeLimit] = useState(isVolumeDefined?params.currentVolumeLimit:'');
  const [currentVolumeUsed, setCurrentVolumeUsed] = useState(isVolumeDefined?params.currentVolumeUsed:'');
  const [newVolumeLimit, setNewVolumeLimit] = useState(isVolumeDefined?0:'');
  const [volumeLimitUpdateChoice, setVolumeLimitUpdateChoice] = useState(isVolumeDefined?'increment':'no');
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const [ipAddress, setIpAddress] = useState('');
  const timeDateStr = timeLimit
  ? moment(timeLimit).format("YYYY-MM-DDTHH:mm")
  : "";
  const [date="", time="" ] = timeDateStr?.split('T');

  async function getSystemDate(){
    const result = await ifCl.run('show system date');
    return new Date(result);
  }

  const doClose = () => setOpen(false);
  if (open === false) {
    return null;
  }

  async function doApply(){
    const ipAddressTarget = params?params.ipAddress:ipAddress;
    if(!isValidIPValue(ipAddressTarget)){
      return setError('Invalid IP address');
    }

    if(timeLimit && moment(timeLimit).isBefore(moment())){
      return setError('Dates in the past')
    }

    if(isNaN(newVolumeLimit)){
      return setError('Invalid volume')
    }

    if(volumeLimitUpdateChoice === 'set' && Number(newVolumeLimit) === 0){
      return setError('Volume limit of 0')
    }

    if((volumeLimitUpdateChoice === 'set' || volumeLimitUpdateChoice === 'increment') && Number(newVolumeLimit) < 0){
      return setError('Volume limit negative')
    }

    if((volumeLimitUpdateChoice === 'set' && Number(newVolumeLimit) > VOLUME_LIMIT)|| (volumeLimitUpdateChoice === 'increment') && Number(newVolumeLimit) + Number(currentVolumeLimit) > VOLUME_LIMIT){
      return setError('Volume limit bigger than 1000 TB')
    }

    try{
      if(timeLimit){
        const systemDate = await getSystemDate();
        const diffInSeconds = moment(timeLimit).diff(systemDate, 'seconds')
        if(diffInSeconds > 0){
          await ifCl.run(`api subscriber ${ipAddressTarget} quota time assign ${diffInSeconds}`)
        }
      }else{
        await ifCl.run(`api subscriber ${ipAddressTarget} quota time disabled`)
      }

      if (volumeLimitUpdateChoice === "no") {
        await ifCl.run(
          `api subscriber ${ipAddressTarget} quota volume disabled`
        );
      } else if (!isNaN(newVolumeLimit)) {
        const kbytes = Number(newVolumeLimit) * 1000000;
        if (volumeLimitUpdateChoice === "set") {
          await ifCl.run(
            `api subscriber ${ipAddressTarget} quota volume assign ${kbytes}`
          );
        } else if (volumeLimitUpdateChoice === "increment" && kbytes > 0) {
          await ifCl.run(
            `api subscriber ${ipAddressTarget} quota volume increment ${kbytes}`
          );
        }
      }
    }catch(e){
      setError(e)
      return;
    }

    onOk();
    doClose();
  };

  async function onTimeLimitExtensionChanged(value){
    const systemDate = await getSystemDate()
    switch(value){
      case 'oneHour':
        setTimeLimit(moment(systemDate).add(1, 'hour').toDate())
        break;
      case 'oneDay':
        setTimeLimit(moment(systemDate).add(1, 'day').toDate())
        break;
      case 'oneMonth':
        setTimeLimit(moment(systemDate).add(1, 'month').toDate())
        break;
      case 'threeMonths':
        setTimeLimit(moment(systemDate).add(3, 'months').toDate())
        break;
    }
  }

  return <Modal
        title="Set New Quota Limits"
        superIcon="add_circle"
        large={true}
        content={() => {
          return (
            <div>
              <div className="row clearfix margin-b-40">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-group margin-b-0">
                    <label>IP Address:</label>
                    <div className="input-group margin-b-0">
                      <span className="input-group-addon">
                        <i className="material-icons">cloud_queue</i>
                      </span>
                      <div className="form-line">
                        <input
                          type="text"
                          className="form-control align-left"
                          required
                          autofocus
                          onChange={(event) => {
                            setError(null);
                            setIpAddress(event.target.value);
                          }}
                          value={params ? params.ipAddress : ipAddress}
                          readOnly={params !== undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Time Quota </h4>

              <div className="row clearfix margin-b-5">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-group margin-b-0">
                    <label>Time Limit:</label>
                    <div className="input-group margin-b-0">
                      <span className="input-group-addon">
                        <i className="material-icons">date_range</i>
                      </span>
                      <div className="form-line">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="date"
                            autofocus
                            value={date}
                            onChange={(e) => {
                              if (!timeLimit) {
                                setTimeLimit(
                                  new Date(`${e.target.value}T00:00`)
                                );
                              } else {
                                setTimeLimit(
                                  new Date(`${e.target.value}T${time}`)
                                );
                              }
                            }}
                          />
                        </div>
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="time"
                            autofocus
                            value={time}
                            onChange={(e) => {
                              if (!timeLimit) {
                                setTimeLimit(
                                  new Date(
                                    `${moment(Date.now()).format(
                                      "YYYY-MM-DD"
                                    )}T${e.target.value}`
                                  )
                                );
                              } else {
                                setTimeLimit(
                                  new Date(`${date}T${e.target.value}`)
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <button
                    className="btn btn-link waves-effect margin-t-25 small-screen-no-top-margin"
                    onClick={() => setTimeLimit("")}
                    type="button"
                  >
                    Remove Time Limit
                  </button>
                </div>
              </div>

              <div className="row clearfix margin-b-40">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-group margin-b-0">
                    <label>Time Limit Extension:</label>
                    <div className="input-group margin-b-0">
                      <span className="input-group-addon">
                        <i className="material-icons">add</i>
                      </span>
                      <SelectInput
                        className="margin-b-0 time-limit-select"
                        name="type"
                        onChange={(e) =>
                          onTimeLimitExtensionChanged(e.target.value)
                        }
                        title="Time Limit Extension"
                        options={[
                          { label: "Select extension...", value: "empty" },
                          { label: "+1 hour", value: "oneHour" },
                          { label: "+1 day", value: "oneDay" },
                          { label: "+1 month", value: "oneMonth" },
                          { label: "+3 months", value: "threeMonths" },
                        ]}
                        label=""
                        defaultValue="empty"
                        placeholder="Select extension..."
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h4>Volume Quota </h4>

              <div className="row clearfix">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-group margin-b-0">
                    <label>Current Volume Limit:</label>
                    <div className="input-group margin-b-0">
                      <span className="input-group-addon">
                        <i className="material-icons">data_usage</i>
                      </span>
                      <div className="form-line">
                        <input
                          type="text"
                          className="form-control align-left"
                          autofocus
                          onChange={(e) =>
                            setCurrentVolumeLimit(e.target.value)
                          }
                          value={currentVolumeLimit}
                          readOnly
                        />
                      </div>
                      <span className="input-group-addon">
                        <label>GB</label>
                      </span>
                    </div>
                  </div>

                  <div className="form-group margin-b-0">
                    <label>Current Volume Used:</label>
                    <div className="input-group margin-b-0">
                      <span className="input-group-addon">
                        <i className="material-icons">data_usage</i>
                      </span>
                      <div className="form-line">
                        <input
                          type="text"
                          className="form-control align-left"
                          autofocus
                          onChange={(e) => setCurrentVolumeUsed(e.target.value)}
                          value={currentVolumeUsed}
                          readOnly
                        />
                      </div>
                      <span className="input-group-addon">
                        <label>GB</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <SelectInputWithIcon
                    title="Operation"
                    name="type"
                    selected={volumeLimitUpdateChoice}
                    onChange={(e) => setVolumeLimitUpdateChoice(e.target.value)}
                    options={[
                      { label: "No volume limit", value: "no" },
                      { label: "Set this new limit", value: "set" },
                      {
                        label: "Increment limit by this amount",
                        value: "increment",
                      },
                    ]}
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                  <div className="input-group margin-b-0">
                    <span className="input-group-addon">
                      <i className="material-icons">data_usage</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="text"
                        className="form-control align-left"
                        autofocus
                        onChange={(e) => setNewVolumeLimit(e.target.value)}
                        value={newVolumeLimit}
                      />
                    </div>
                    <span className="input-group-addon">
                      <label>GB</label>
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <button
                    className="btn btn-link waves-effect"
                    onClick={() => {
                      setVolumeLimitUpdateChoice("no");
                      setNewVolumeLimit("");
                    }}
                    type="button"
                  >
                    Remove Volume Limit
                  </button>
                </div>
              </div>
            </div>
          );
        }}
        applyLabel="OK"
        onApply={doApply}
        closeLabel="CANCEL"
        onClose={onClose}
        footerMessage={
          (error !== null && (
            <span className="modal-err-msg color-red align-left">{error}</span>
          )) ||
          null
        }
      />
};

const ListSubscribers = ({ request}) => {
  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");
  const [showEditQuotasModal, setShowEditQuotasModal] = useState(false);
  useEffect(() => 
    actions.recv('create-quota', () => setShowEditQuotasModal({show: true}))
  , [])
  
  return (
    <>
      <TableContainer>
        <Request during={request}>
          {({ response }) => (
            <DataTable
              pageLength={100}
              exportAsCSV={true}
              content={response}
              filters={[filterQuoteIsNotNA]}
              columns={[
                Column.Text({
                  label: "IP-ADDRESS",
                  idx: 0,
                  cellClassName: "hyperlink-text",
                  title: "Subscriber’s IP address",
                  onClick: (addr, _row) => openSubscriberDetails({ addr }),
                }),
                Column.Text({
                  label: "SUBSCRIBER-ID",
                  idx: 1,
                  cellClassName: "hyperlink-text",
                  title: "Subscriber ID",
                  onClick: (subsId, [_addr, ..._row], _trigger) =>
                    openSubscriberDetails({ subsId }),
                }),
                Column.Text({
                  label: "QUOTA-BLOCK",
                  idx: 11,
                  colClassName: "align-left",
                  parse: (value) =>
                    value === "n/a"
                      ? value
                      : value === "blocked"
                      ? "yes"
                      : "no",
                }),
                Column.DateTimeOrNA({
                  label: "QUOTA-TIME-LIMIT",
                  idx: 12,
                  colClassName: "align-left",
                }),
                Column.Text({
                  label: "QUOTA-GBYTES-USED",
                  idx: 13,
                  colClassName: "align-left",
                  parse: (value) => {
                    if (value === "n/a") return "n/a";
                    return value
                      ? (Number(value.split("/")[0]) / 1000).toFixed(3)
                      : "n/a";
                  },
                }),
                Column.Text({
                  label: "QUOTA-GBYTE-LIMIT",
                  idx: 13,
                  colClassName: "align-left",
                  parse: (value) => {
                    if (value === "n/a") return "n/a";
                    return value
                      ? (Number(value.split("/")[1]) / 1000).toFixed(3)
                      : "n/a";
                  },
                }),
                Column.Actions({
                  label: "ACTIONS",
                  idx: 0,
                  colClassName: "text-center action",
                  cellClassName: "hides-content-to-operators",
                  are: (name) => [
                    {
                      id: "edit-config",
                      label: "Edit",
                      icon: "edit",
                      onClick: (_, row) => {
                        setShowEditQuotasModal({
                          show: true,
                          params: {
                            currentVolumeLimit: row[5],
                            currentVolumeUsed: row[4],
                            ipAddress: row[0],
                            currentTimeLimit: row[3],
                          },
                        });
                      },
                    },
                    {
                      id: "delete",
                      label: "Delete",
                      icon: "delete",
                      onClick: (ipAddress) => {
                        showModalConfirm(
                          `Eliminate time and volume quotas for IP Address ${ipAddress}?`,
                          "",
                          "delete_forever",
                          () => {
                            Promise.all([
                              ifCl.run(
                                `api subscriber ${ipAddress} quota volume disabled`
                              ),
                              ifCl.run(
                                `api subscriber ${ipAddress} quota time disabled`
                              ),
                            ])
                              .then(reloadWhenDone)
                              .catch((error) => showModalError(error));
                          }
                        );
                      },
                    },
                  ],
                }),
              ]}
            />
          )}
        </Request>
      </TableContainer>
      {showEditQuotasModal &&
        showEditQuotasModal.show &&
        createPortal(
          <EditQuotasModal
            onOk={reloadWhenDone}
            onClose={() => setShowEditQuotasModal({ show: false })}
            params={showEditQuotasModal.params}
          />,
          document.body
        )}
    </>
  ); 
}

export default ListSubscribers;
