import React from "react";
import styled from "styled-components";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import TargetAndDestinationFilter from "./AccessFilter";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {hoursScopeChoices, categoryChoices} from "common/constants";

const doesNothing = () => {};

const _filterChoices = [
  { value: "all", label: "All UDRs." },
  { value: "auto", label: "Only auto UDR" },
];

const ReportContextControlsLayout = styled.div`
  margin: 0.5em 0;
  .wide {
    grid-column: 1 / -1;
    margin: 0.5em 0;
  }
`;
const Controls = ({
  hours,
  categories,
  filter,
  target,
  destination,
  className,
  settings = {},
  onChange = doesNothing,
  children,
}) => {
  const handleChange = ({ target }) => {
    const { value, name } = target;

    if (name === "hours") {
      onChange({ [name]: parseInt(value) });
    } else {
      onChange({ [name]: value });
    }
  };

  return (
    <>
      <ReportContextControlsLayout>
        <ColumnsGrid
          columns={14}
          className={className}
          maxWidth="15rem"
          minWidth="4cm"
          rowGap="0"
        >
          <SelectInputWithIcon
            title="Date Range"
            name="hours"
            icon="date_range"
            selected={hours || 24}
            onChange={handleChange}
            options={hoursScopeChoices}
          />
          <SelectInputWithIcon
            title="Categories"
            name="categories"
            icon="format_line_spacing"
            selected={categories || 10}
            onChange={handleChange}
            options={categoryChoices}
          />
          <SelectInputWithIcon
            title="filter"
            name="filter"
            icon="import_export"
            selected={filter || "all"}
            onChange={handleChange}
            options={_filterChoices}
          />
          <TargetAndDestinationFilter
            className="wide"
            defaultValues={{ destination, target }}
            onChange={onChange}
          />
        </ColumnsGrid>
      </ReportContextControlsLayout>
      {children}
    </>
  );
};
export default Controls;
