const yesOrNoOrValue = (value) =>
  value === "yes" ? true : value === "no" ? false : value;

const intoNestedWireObject = (result, [path, value, index]) => {
  if (path === undefined) {
    console.warn("no path for index", index, "value", value);
    return result;
  }
  const [end, field] = path.split(".");
  return {
    ...result,
    [end]: {
      ...(result[end] || {}),
      [field]: yesOrNoOrValue(value),
    },
  };
};

const intoWireObjectGiven = (keys) => (row) =>
  row
    .trim(" ")
    .split(/\s+/)
    .map((value, index) => [keys[index], value, index])
    .reduce(intoNestedWireObject, {});

export const parseWires = (response) => {
  const [head, ...rows] = response.trim("\n").split("\n");
  const keys = head
    .split(/\s+/)
    .map((key) => key.toLowerCase().replace("-", "."));
  return rows.map(intoWireObjectGiven(keys));
};

const stateOfDevice = ({ connected, enabled }) => ({ connected, enabled });

export const mergeStateOfDevices =
  (devices = {}) =>
  ({ acc, inet }) => ({
    acc: { ...acc, ...stateOfDevice(devices[acc.iface]) },
    inet: { ...inet, ...stateOfDevice(devices[inet.iface]) },
  });

export const mergeInterfacesState = (wires, devices) => {
  const devicesByName = Object.fromEntries(
    devices.map((device) => [device.name, device])
  );
  return wires.map(mergeStateOfDevices(devicesByName));
};
