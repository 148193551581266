import * as d3 from "d3";

export const parseSystemDate = (input) => new Date(input);

export const fixedPrecision = (value, decimals = 4) =>
  parseFloat(value.toFixed(decimals));

export const parseFloatOrNull = (value) =>
  value === "n/a"
    ? null
    : value.endsWith("%") === true
    ? fixedPrecision(parseFloat(value.trim("%")) / 100, 6)
    : parseFloat(value);

const dateTimeParser = d3.timeParse("%Y-%m-%dT%H:%M:%S");

const parseKeys = (head, translations = {}) =>
  head.split(/\s+/).map((key) => translations[key] || key);

const timeAndNumbers = (row, keys, skipped = []) =>
  row
    .split(/\s+/)
    .map((value, index) =>
      skipped.length > 0 && skipped.includes(keys[index])
        ? null
        : [
            keys[index],
            index > 0 ? parseFloatOrNull(value) : dateTimeParser(value),
          ]
    )
    .filter((item) => item !== null);
const appendToList = (list = [], key, value) => [
  ...list,
  { name: key, speed: value },
];

const toSpeedsList = ({
  skipped,
  defaultInterval = 3600,
  listName = "subscribers",
  end = null,
}) => {
  let first = null,
    start = null;
  end = end.getTime() / 1000;
  let interval = defaultInterval;
  let volume = 0;
  const mind = (key, value) => {
    if (key === "time") {
      if (value !== null) {
        const time = value.getTime() / 1000;
        start = time;
        first = first || time;
        interval = start === null ? interval : end - start;
        interval =
          interval > defaultInterval || interval < 0
            ? defaultInterval
            : interval;
      } else {
        /* Totals row */
        interval = end - first;
      }
    }
    if (key === "volume") {
      volume = value /*in GB*/ * 8 * 1000;
      return value;
    }
    return value;
  };

  const calcSpeed = (value) /*GB*/ =>
    fixedPrecision((volume * value) / interval, 4);

  return (reading, [key, value]) =>
    skipped.includes(key) === true
      ? reading
      : key === "time" || key === "volume"
      ? { ...reading, [key]: mind(key, value) }
      : {
          ...reading,
          [listName]: appendToList(reading[listName], key, calcSpeed(value)),
        };
};

export const parseSubscribersSpeed = (input, currentDate) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const [head, ...rows] = lines;
  const translations = {
    REST: "REST",
    TIME: "time",
    GIGABYTES: "volume",
  };
  const keys = parseKeys(head, translations);
  const skipped = [];
  const end = currentDate === undefined ? new Date() : currentDate;
  const parseTheseValues = toSpeedsList({ skipped, end });
  const items = rows.map((row) =>
    timeAndNumbers(row, keys, skipped, translations).reduce(
      parseTheseValues,
      {}
    )
  );
  const { time, ...totals } = items.pop();
  return { timeline: items, totals };
};

export const parseSubscriberBandwidthShare = (
  input,
  options = { omitTimeline: false, omitTotals: false, precision: 4 }
) => {
  const lines = input.length === 0 ? [] : input.trim("\n").split("\n");
  if (lines.length === 0) {
    return [];
  }
  const last = lines.pop();
  const [head, ...rows] = lines;
  const translations = {
    GIGABYTES: "volume",
    REST: "REST",
    TIME: "time",
    TOTAL: "total",
  };
  const keys = parseKeys(head, translations);
  const skipped = [];
  const appendtoSubscribers = (
    { subscribers = [], volume = 0 },
    key,
    value
  ) => [
    ...subscribers,
    { name: key, share: value, volume: fixedPrecision(volume * value, options.precision) },
  ];
  const toSubscribersList = (reading, [key, value]) =>
    skipped.includes(key) === true
      ? reading
      : key === "time" || key === "volume"
      ? { ...reading, [key]: value }
      : {
          ...reading,
          subscribers: appendtoSubscribers(reading, key, value),
        };

  const parseTimeline = () =>
    rows.map((row) =>
      timeAndNumbers(row, keys, skipped).reduce(toSubscribersList, {})
    );
  const totalsSkipped = ["time"];
  const parseTotals = () =>
    timeAndNumbers(last, keys, totalsSkipped).reduce(toSubscribersList, {});
  return {
    ...(options.omitTimeline ? {} : { timeline: parseTimeline() }),
    ...(options.omitTotals ? {} : { totals: parseTotals() }),
  };
};
