import React, { useEffect, useState } from "react";

import {
  Container,
  Box,
  Grid,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/material/styles";

const StyledWrapped = styled("div")(({ theme }) => ({
  height: "100vh",
  overflow: "hidden",
  fontFamily: "inherit",
}));

const StyledForm = styled("form")(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const LoginContainer = ({url, ...params}) => {
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    username: "",
    pass: "",
    showPass: false,
  });

  useEffect(() => {
    login.displayLogo(login.platform);
    $("#loginHostname").text(login.remoteHostname);
    $(".login-view").css({
      "background-size": `${document.body.clientWidth}px 100%`,
    });
    $('#sectSidebars').hide();
  }, []);

  const handleSubmit = (e) => {
    setError(false);
    e.preventDefault();
    const { elements } = e.target;
    const [username, password] = [
      elements.item(0).value,
      elements.item(1).value,
    ];
  };

  const handlePassVisibilty = () => {
    setValues({
      ...values,
      showPass: !values.showPass,
    });
  };

  return (
    <StyledWrapped className="login login-view" id="viewLogin">
      <Paper
        elevation={3}
        sx={{
          padding: "7px 21px 15px 7px",
          backgroundColor: "#EFEFEF",
        }}
        className="login-box"
      >
        <div className="logo-container">
          <img id="logoLoginImg" src="" alt="Logo" />
          <span id="loginHostname" class="font-bold">
            unknown
          </span>
        </div>
        <hr />
        <div className="form-container">
          <Typography align="center" className="sign-in-label">
            Sign in to start session
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    style={{ marginRight: 15, marginBottom: 7 }}
                  >
                    <circle
                      cx="8.00006"
                      cy="4.09088"
                      r="3.59088"
                      stroke="#161618"
                    />
                    <path
                      d="M1.13647 16.7562C1.13647 12.7239 4.23357 9.5 8.00007 9.5C11.7666 9.5 14.8637 12.7239 14.8637 16.7562C14.8637 17.167 14.5307 17.4999 14.1199 17.4999H1.88019C1.46945 17.4999 1.13647 17.167 1.13647 16.7562Z"
                      stroke="#161618"
                    />
                  </svg>
                  <TextField
                    id="loginUser"
                    type="username"
                    fullWidth
                    label="Username"
                    autoComplete="username"
                    variant="standard"
                    required
                    onChange={(e) =>
                      setValues({ ...values, username: e.target.value })
                    }
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    style={{ marginRight: 15, marginBottom: 7 }}
                  >
                    <path
                      d="M11.2841 8.184V4.28412C11.2841 3.41311 10.938 2.57779 10.3222 1.9619C9.70627 1.346 8.87094 1 7.99994 1C7.12893 1 6.29361 1.346 5.67772 1.9619C5.06182 2.57779 4.71582 3.41311 4.71582 4.28412V8.184"
                      stroke="#161618"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5978 8.18396H3.40223C2.31397 8.18396 1.43176 9.06617 1.43176 10.1544V17.3795C1.43176 18.4677 2.31397 19.35 3.40223 19.35H12.5978C13.686 19.35 14.5682 18.4677 14.5682 17.3795V10.1544C14.5682 9.06617 13.686 8.18396 12.5978 8.18396Z"
                      stroke="#161618"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <TextField
                    id="loginPasswd"
                    type={values.showPass ? "text" : "password"}
                    fullWidth
                    label="Password"
                    variant="standard"
                    autoComplete="password"
                    required
                    onChange={(e) =>
                      setValues({ ...values, pass: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePassVisibilty}
                            aria-label="toggle password"
                            edge="end"
                          >
                            {values.showPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <button
                  className="login-button"
                  type="submit"
                  onClick={() => login.doLogin(url, params)}
                >
                  Sign in
                </button>
                <p id="loginAlert">&nbsp;</p>
              </Grid>
            </Grid>
          </StyledForm>

          <Typography
            variant="subtitle2"
            align="left"
            component="p"
            gutterBottom
            style={{ fontSize: "11px", color: "#929295" }}
          >
            By logging in and using this software you accept the terms and
            conditions of the{" "}
            <Link
              title="License Agreement"
              style={{ fontSize: "11px", color: "var(--text-primary-login)", textDecoration: "underline var(--text-primary-login)", cursor: "pointer" }}
              id="eulaLnk"
              onClick={() => eula.show()}
            >
              Bequant End User License Agreement
            </Link>
            . If you do not agree with them, please do not use the software.
            ©{" "}
            <Link
              href="http://www.bequant.com"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "11px", color: "var(--text-primary-login)", textDecoration: "underline var(--text-primary-login)" }}
            >
              Bequant S.L.
            </Link>
          </Typography>
        </div>
      </Paper>
    </StyledWrapped>
  );
};

export default LoginContainer;
