/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import LegendsList, {LegendsAndGraph, useSelection} from "./Legends";
import useDebouncer from "common/hooks/debouncer";
import timeAndValuesTooltipForUnits from "./timeAndValuesTooltip";

export const formats = {
  noDecimals: d3.format(".0f"),
  oneDecimal: d3.format(".1f"),
  twoDecimals: d3.format(".2f"),
  threeDecimals: d3.format(".3f"),
};

const WrapperDIV = styled.div`
  position: relative;
  height: 100%;
  & > svg {
    width: 100%;
    height: 100%;
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    text.threshold-label {
      text-anchor: end;
      dominant-baseline: text-after-edge;
    }
    min-height: ${(props) => props.minHeight || "6cm"};
  }
`;

const extendDomain = ([min, max], { topGap, bottomGap, bottomValue = 0 }) => [
  min < bottomValue ? min - min * bottomGap : bottomValue,
  max + max * topGap,
];

const expandLinearScale = (scale, params) => {
  const result = scale.copy();
  const originalDomain = result.domain.bind(result);
  result.domain = (...args) =>
    args.length === 0
      ? originalDomain()
      : originalDomain(extendDomain(...args, params));
  return result;
};

export const mayExpandLinearScale = (scale, params) =>
  params.bottomGap === undefined && params.topGap === undefined
    ? scale
    : expandLinearScale(scale, params);

const endOfChunkOrList = (index, chunkSize, total) =>
  index === total - 1 || index % chunkSize === 0;

const TIME_TOLERANCE = 20 * 60 * 1000 + 1;
let bestOfBest = 0;

const groupsMax = (chunkSize, name, xField = "time") => {
  let best = null;
  let lastTime = null;
  const mind = ([time, value], drop) => {
    if (lastTime !== null && time - lastTime > TIME_TOLERANCE) {
      // Data received is a padding from the time range, ends continuity.
      best = value;
      lastTime = time;
      return [
        [time, value],
      ];
    }

    best = value > best ? value : best;
    bestOfBest = value > bestOfBest ? value : bestOfBest;
    const result = drop ? [[time, best]] : [];
    best = drop ? 0 : best;
    lastTime = time;
    return result;
  };
  const reduce = (items) =>
    items.flatMap((item, index, { length: total }, itemIndex) =>
      mind(
        [item[xField].getTime(), item[name]],
        endOfChunkOrList(index, chunkSize, total),
        itemIndex
      )
    );
  return { reduce };
};
const onlyThoseWithNonZeroValues = ({ values }) =>
  values.length > 1 && values.some(([_time, value]) => value > 0);

const intoTimeValueTuples = (items, name, xField = "time", defaults = 0) =>
  items.map((item) => [item[xField].getTime(), item[name] || defaults]);

export const splitByFields = ([xField, ...fields], items, ratio, skipVoid) => {
  const newFields = fields.map(({ name, label, color, disabled }, index) => ({
    key: label,
    color,
    ratio,
    disabled,
    values:
      ratio > 1
        ? groupsMax(ratio, name, xField).reduce(items)
        : intoTimeValueTuples(items, name, xField),
  }));

  if (!skipVoid) {
    return newFields;
  }

  return newFields.filter(onlyThoseWithNonZeroValues);
};
export const calcItemsRatio = (count, canvas) => {
  if (canvas === null) {
    return 1;
  }
  const { width } = canvas.getBoundingClientRect();
  return (count > width && Math.floor(count / (width * 0.4))) || 1;
};

const defaultTimeFormat = d3.timeFormat("%Y-%m-%d %H:%M");


export const defaultYAxisFormat = (d) =>
  d === 0 || d >= 10.0
    ? formats.noDecimals(d)
    : d >= 1.0
    ? formats.oneDecimal(d)
    : formats.twoDecimals(d);

const LoadingOverlayWrapper = styled.div`
  background: white;
  color: rgb(85, 85, 85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  /* And then, position it at will */
  top: 0;
  left: 0;
`;

export const LoadingOverlay = ({ loading = false }) => {
  useEffect(() => {
    console.log("LoadingOverlay", loading);
  }, [loading]);
  return loading !== true ? null : (
    <LoadingOverlayWrapper>Loading</LoadingOverlayWrapper>
  );
};
const NVStackedAreaChart = ({
  margin = { top: 5, right: 50, bottom: 30, left: 70 },
  className,
  items,
  xField,
  fields,
  thresholds = [],
  tooltipContentGenerator = undefined,
  xAxisFormat = defaultTimeFormat,
  yAxisUnits = "",
  yAxisFormat = defaultYAxisFormat,
  yAxisTopGap = undefined,
  yTooltipFormat = null,
  onRenderFinished = () => {
    return;
  },
  itemsRatio = undefined,
  xAxisTicksNumber = undefined,
  onSeriesInspect = undefined,
  durationAnimation = undefined,
  showLegend = true,
  tooltipEnabled = true,
  fixedYRange,
  skipVoid = true,
  onAreaClick = undefined,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const isLoading = useRef(true);
  let resizeObserver = null;
  let chart = null;
  const debounce = useDebouncer(100 /*ms*/);

  const render = (target) => {
    isLoading.current = true;
    nv.addGraph(function () {
      const start = new Date().getTime();
      if (chart === null) {
        chart = nv.models
          .stackedAreaChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .showControls(false)
          .noData(NO_DATA_AVAILABLE)
          .duration(durationAnimation)
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: yAxisTopGap,
            })
          )
          .xScale(d3.scaleTime())
          .showLegend(showLegend)
          .clipEdge(true);
          chart.interactiveLayer.tooltip.enabled(tooltipEnabled);
        if (tooltipEnabled) { 
          chart.interactiveLayer.tooltip.hideDelay(0);
          chart.interactiveLayer.tooltip.gravity("w"); // 's', 'n', 'w', 'e'
          if (typeof tooltipContentGenerator === "function") {
            chart.interactiveLayer.tooltip.contentGenerator(
              tooltipContentGenerator
            );
          } else if (yTooltipFormat !== null) {
            chart.interactiveLayer.tooltip.valueFormatter(yTooltipFormat);
          }
        }
      
        chart.xAxis.tickFormat(xAxisFormat).ticks(xAxisTicksNumber);
        chart.yAxis
          .axisLabel(yAxisUnits)
          .showMaxMin(false)
          .tickFormat(yAxisFormat);
        if (resizeObserver === null) {
          const updateChart = () => chart.update()
          resizeObserver = new ResizeObserver(() => debounce(updateChart));
          resizeObserver.observe(target);
        }
      }
      const ratio = itemsRatio || calcItemsRatio(items.length, canvas.current);
      const datum = splitByFields([xField, ...fields], items, ratio, skipVoid);
      const end = new Date().getTime();
      console.log(
        "took ",
        (end - start) / 1000,
        datum,
        items.length,
        bestOfBest
      );
      bestOfBest = 0;
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.yAxis.domain();
      if (fixedYRange) {
        chart.forceY(fixedYRange);
      } else {
        chart.forceY([
          0,
          d3.max([yDomainMax, ...thresholds.map((t) => t.value)]),
        ]);
      }
      if (onAreaClick !== undefined) {
        chart.stacked.dispatch.on('areaClick', function(event)  {
          const {series} = event;
          onAreaClick(series)
        });
      }
      d3.select(canvas.current).call(chart);
      d3.select(canvas.current).select(".nv-background rect").attr("rx", 9)
      const main = d3.select(canvas.current);
      const linesArea = main.select(".nvd3.nv-stackedarea");
      const thresholdsGroup = linesArea.select(".thresholds").node()
        ? linesArea.select(".thresholds")
        : linesArea.append("g").attr("class", "thresholds");
      const handleThresholds = (target) => {
        const groups = target
          .selectAll(".threshold")
          .data(thresholds, (d) => d.label);
        const newGroups = groups.enter();
        newGroups.append("g").attr("class", "threshold");
        newGroups
          .select(".threshold")
          .append("line")
          .attr("class", "threshold-line");
        newGroups
          .select(".threshold")
          .append("text")
          .attr("class", "threshold-label");
        groups
          .select("line")
          .attr("x1", chart.xAxis.range()[0])
          .attr("x2", chart.xAxis.range()[1])
          .attr("y1", ({ value }) => chart.yAxis.scale()(value))
          .attr("y2", ({ value }) => chart.yAxis.scale()(value))
          .style("stroke", (d) => d.color || "red");
        groups
          .select("text")
          .text((d) => d.label)
          .attr("x", chart.xAxis.range()[1])
          .attr("y", ({ value }) => chart.yAxis.scale()(value))
          .attr("dx", "-.5em")
          .attr("dy", "-.5em")
          .style("fill", (d) => d.color || "red");
        groups.exit().remove();
      };

      thresholdsGroup.call(handleThresholds);

      chart.dispatch.on("stateChange", () => {
        setTimeout(() => thresholdsGroup.call(handleThresholds), 400);
      });

      if (onRenderFinished) {
        onRenderFinished();
      }
      chart.dispatch.on("renderEnd", function () {
        isLoading.current = false;
      });
      if (onSeriesInspect !== undefined) {
        const handleInspect = (event, d) => {
          event.preventDefault();
           // react on right-clicking
          onSeriesInspect({...d, name: d.key});
          if (tooltipEnabled) {
            setTimeout(() => chart.interactiveLayer.tooltip.hidden(true), 100);
          }
        }
        d3.select(canvas.current).select(".nv-legendWrap").selectAll("text")
          .on("contextmenu", handleInspect);
        linesArea.on("contextmenu", (event) => {
          if (event.target.tagName !== 'path') {
            return;
          }
          const [data] = d3.select(event.target).data();
          return handleInspect(event, data);
        });
      }

      return chart;
    });

    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  };
  useEffect(() => {
    const target = wrapper.current;
    return items ? render(target) : () => {};
  }, [items, fields]);

  useEffect(() => {
    return () => {
      console.log("nv-lineChart destroy");
      if (chart !== null && tooltipEnabled) {
        chart.interactiveLayer.tooltip.hidden(true);
        // chart.destroy();
      }
    };
  }, []);

  return (
    <WrapperDIV ref={wrapper} className={className}>
      <svg ref={canvas}></svg>
      <LoadingOverlay isLoading={isLoading.current} />
    </WrapperDIV>
  );
};

const NVStackedAreaChartWithLegends = ({ fields, onClick, className, previousLegends=[], onSeriesInspect=undefined, ...rest }) => {
  const [selection, doToggle] = useSelection(fields);
  const doSelectOne = key => 
    doToggle(key, false/*was disabled*/, true/*only this*/);
  return (
    <LegendsAndGraph className={className}>
      <LegendsList items={selection}  
       doToggle={doToggle} previous={previousLegends}
       onLabelClick={onClick} onLabelInspect={onSeriesInspect} />
      <NVStackedAreaChart fields={selection} {...rest} showLegend={false}
        onAreaClick={doSelectOne}
        onSeriesInspect={onSeriesInspect} />
    </LegendsAndGraph>
  );
};

export default NVStackedAreaChartWithLegends;

const hideOtherTooltipsBefore = renderer => (...args) => {
  $(".nvtooltip").each(function () {
      this.style.opacity = 0;
  });
  return renderer(...args);
}

export const tooltipContentGenerator = hideOtherTooltipsBefore(timeAndValuesTooltipForUnits("Mbps"));

export const tooltipContentGeneratorForUnits = (...args) =>
  hideOtherTooltipsBefore(timeAndValuesTooltipForUnits(...args));
