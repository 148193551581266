import React from "react";
import FilteringControls from "./FilteringControls";
import TimeEvolutionMetricsResult from "./Result";
import styled from "styled-components";
import Request from "common/Request";

const ResultsLayout = styled.div`
  flex: 100%;
`;

const InnerTimeEvolutionMetrics = ({
  direction,
  timePeriod,
  metric,
  refreshSignal,
  entity,
  subscribersToPlot,
}) => {
  return (
    <ResultsLayout>
      <TimeEvolutionMetricsResult
        direction={direction}
        timePeriod={timePeriod}
        metric={metric}
        entity={entity}
        subscribersToPlot={subscribersToPlot}
        refreshSignal={refreshSignal}
      />
    </ResultsLayout>
  );
};

const TimeEvolutionMetrics = ({
  request,
  direction,
  subscribersToPlot,
  shouldInit = false,
  timePeriod,
  metric
}) => {

  const defaults = {};

  if(timePeriod){
    defaults.timePeriod = timePeriod;
  }

  if(metric){
    defaults.metric = metric;
  }

  return <Request during={request} keepContentWhileLoading={!shouldInit}>
    {() => (
      <FilteringControls defaults={defaults}>
        {(settings) => (
          <InnerTimeEvolutionMetrics
            direction={direction}
            subscribersToPlot={subscribersToPlot}
            {...settings}
          />
        )}
      </FilteringControls>
    )}
  </Request>
}

export default TimeEvolutionMetrics;
