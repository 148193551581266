import React from "react";
import NVHorizontalBarChart, {
  formats,
} from "common/graphs/NVHorizontalBarChart";
import styled from "styled-components";

const UsageGraphLayout = styled.div`
  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
  .centered {
    text-align: center;
  }
`;

const extractFacet = (field, result) =>
  Object.entries(result).map(([facet, data]) => ({
    label: facet,
    value: data[field],
  }));

const usedAndFreeFrom = (result, barColor) => {
  console.warn(result, extractFacet("used", result))
  return [
    { key: "Used", color: barColor, values: extractFacet("used", result) },
    { key: "Free", color: `${login.isTheme("light")?"#cacaca":"#3A3A3E"}`, values: extractFacet("free", result) },
  ];
}

const Result = ({ ...result }) => {
  const barColor = "#006AB7";
  return (
    <UsageGraphLayout>
      <NVHorizontalBarChart
        valueUnits=""
        tooltipValueFormatter={(value) => `${formats.threeDecimals(value)} GB`}
        valueFormat={formats.threeDecimals}
        items={usedAndFreeFrom(result, barColor)}
        showRoundCorners = {true}
        showColors = {true}
      />
      <div className="centered">Gbytes</div>
    </UsageGraphLayout>
  );
};

export default Result;
