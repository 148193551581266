import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import SwitchInput from "common/SwitchInput";
import { useTimeOffset } from "common/hooks/useTimeOffset";
import LiveMetricsDials from "./LiveMetricsDials";
import IconsToggle from "common/IconsToggle";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import { TIMEWINDOW_MAX_MAP } from "common/constants";
import DPIAnalyticsLive from "./DPIAnalyticsLive";
import {
  doSubcriberInformationRetrieval,
  getLiveFlowsData,
  parseSubscriberInformation,
  parseSubscriberData,
  parseDPIsResponse,
  MARGIN_FACET_CHART,
  MARGIN_DPI_CHART,
  parseSpeedResponse,
} from "./livemetrics.utils";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import { FacetGraphLive } from "common/graphs/FacetGraphLive";
import { SubscriberFlowsResult } from "./SubscriberFlows";
import { expressTarget } from "common/api";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import useProfile from "common/hooks/useProfile";

const _timeWindowChoices = [
  { value: "2", label: "2 min. time span" },
  { value: "5", label: "5 min. time span" },
  { value: "10", label: "10 min. time span" },
];

const flowsFields = [
  { name: "flows", label: "Active" },
  {
    name: "flowsCreated",
    label: "Created per minute",
  },
];

const rtxFields = [
  { name: "down", label: "Rtx-down" },
  { name: "up", label: "Rtx-up" },
];

const latencyFields = [
  { name: "down", label: "Rtt-down" },
  { name: "up", label: "Rtt-up" },
];

const speedFields = [
  { name: "down", label: "Mbps-down" },
  { name: "up", label: "Mbps-up" },
];

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  jusitfy-content: space-between;
  padding: 20px 20px 0 20px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ControlsLiveMode = styled.div`
  width: 100%;
  padding-right: 20px;
  display: flex;
  align-items: center;
`;

const LiveModeSwitch = styled(SwitchInput)`
  width: 130px;
`;

const LayoutToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FacetGraphLiveContainer = styled.div`
  width: 100%;
`;

const WideContainer = styled.div`
  width: 100%;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 20px;
`;

const HeaderDiv = styled.div`
  display: flex;
  padding: 0 !important;
  align-items: center;
  justify-content: space-between;
  &.header {
    border-color: transparent;
  }
`;

const DEFAULT_CAT_NR = 10;

const LiveMetrics = ({ target, liveMode, setLiveMode, dpiEnabled }) => {
  const { timeOffset } = useTimeOffset();
  const isLiveMode = liveMode !== undefined;
  const [columns, setColumns] = useState(4);
  const [timeWindow, setTimeWindow] = useState(2);
  const [direction, setDirection] = useState("all");
  const [resetSignal, setResetSignal] = useState(false);

  const [itemsSpeed, setItemsSpeed] = useState(null);
  const [itemsDPITable, setItemsDPITable] = useState(null);
  const [itemsDPI, setItemsDPI] = useState(null);

  const [mouseEvent, setMouseEvent] = useState();
  const [dpiValue, setDPIValue] = useState(null);
  const [speedValue, setSpeedValue] = useState();
  const [latencyValue, setLatencyValue] = useState();
  const [rtxValue, setRtxValue] = useState();
  const [flowsValue, setFlowsValue] = useState();

  const [dpiFields, setDpiFields] = useState({ map: {}, fields: [] });

  const [chartSizes, setChartSizes] = useState({
    wDPI: 1100,
    hDPI: 227,
    wFacet: 525,
    hFacet: 190,
  });

  const refDPIGraph = useRef();
  const refFacetGraph = useRef();
  const refTimeStart = useRef();
  const refTimeLast = useRef();
  const refColorIndex = useRef(0);
  const refTarget = useRef();
  const refTargetCmd = useRef();
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  const profile = useProfile() || {};

  async function doLoad() {
    try {
      refTarget.current = target;
      if (!refTargetCmd.current) {
        refTargetCmd.current = await expressTarget(target);
      }
      const response = await getLiveFlowsData({
        targetCmd: refTargetCmd.current,
        direction,
        catNr: DEFAULT_CAT_NR,
      });
      const [, resSpeed, resDpisTable, resDpis] = response.split("-----\n");
      const dataSpeed = parseSpeedResponse(resSpeed);
      const epocTime = Number(dataSpeed["EpocTime"]);
      if (!refTimeStart.current) {
        refTimeStart.current = epocTime;
      }
      if (epocTime - refTimeStart.current > 15 * 60 * 1000) {
        setLiveMode(false);
      } else {
        if (target === refTarget.current) {
          setItemsDPI(parseDPIsResponse(resDpis));
          setItemsDPITable(resDpisTable);
          setItemsSpeed((dataPrevious) => {
            const epocTimePrevious = dataPrevious
              ? Number(dataPrevious["EpocTime"])
              : undefined;
            const flowsCreatedPrevious = dataPrevious
              ? Number(dataPrevious["Flows-created"])
              : undefined;

            return parseSubscriberData(
              dataSpeed,
              epocTimePrevious,
              flowsCreatedPrevious
            );
          });
        }
      }
    } catch {
      setItemsSpeed(null);
      setDPIValue(null);
    }
  }

  useEffect(() => {
    if (liveMode) {
      setItemsSpeed(null);
      setDPIValue(null);
      setItemsDPITable(null);
      setItemsDPI(null);
      setDpiFields({ map: {}, fields: [] });
      setResetSignal(true);
      refTimeStart.current = undefined;
      refColorIndex.current = 0;
      refTargetCmd.current = undefined;
      refTimeLast.current = undefined;
      doLoad();
    }
  }, [liveMode, target, direction, timeWindow]);

  useEffect(() => {
    if (itemsSpeed && itemsDPI && liveMode) {
      doLoad();
      const time = new Date();
      time.setMilliseconds(500);
      time.setHours(time.getHours() + timeOffset);

      if (refTimeLast.current && mouseEvent) {
        const newValueTime = new Date(time).getTime();
        const lastValueTime = new Date(refTimeLast.current).getTime();
        const timeDiff = newValueTime - lastValueTime;
        setMouseEvent({
          ...mouseEvent,
          pointXValue: new Date(mouseEvent.pointXValue.getTime() + timeDiff),
        });
      }
      refTimeLast.current = time;

      if (itemsSpeed) {
        setSpeedValue({
          time,
          down: Number(itemsSpeed["Mbps-down"]),
          up: Number(itemsSpeed["Mbps-up"]),
        });

        setLatencyValue({
          time,
          down: Number(itemsSpeed["Rtt-down(ms)"]),
          up: Number(itemsSpeed["Rtt-up(ms)"]),
        });

        setRtxValue({
          time,
          down: Number(itemsSpeed["Rtx-down"]),
          up: Number(itemsSpeed["Rtx-up"]),
        });

        setFlowsValue({
          time,
          flows: Number(itemsSpeed["Flows-active"]),
          flowsCreated: Number(itemsSpeed["Flows-created-per-min"]),
        });
      }
      if (itemsDPI) {
        const dpiValueNew = Object.entries(itemsDPI).reduce((acc, value) => {
          const [field, fieldValue] = value;
          acc[field] = Number(fieldValue);
          return acc;
        }, {});
        setDPIValue({ ...dpiValueNew, time });

        setDpiFields((oldFields) => {
          const oldMap = { ...oldFields.map };
          const newFieldKeys = Object.keys(dpiValueNew);
          const newFieldNamesSet = new Set([
            ...newFieldKeys,
            ...Object.keys(oldMap),
          ]);

          const { map: newFieldsMap, fields } = [...newFieldNamesSet].reduce(
            (acc, value) => {
              const entry = oldMap[value];
              if (!entry) {
                let newColor;
                if (value === "rest") {
                  newColor = colorsSet[colors.length - 1];
                } else {
                  newColor = colorsSet[refColorIndex.current];
                }
                refColorIndex.current =
                  (refColorIndex.current + 1) % (colorsSet.length - 1);
                const newField = {
                  name: value,
                  label: value,
                  color: newColor,
                  value: dpiValueNew[value],
                };
                acc.map[value] = {
                  color: newColor,
                  time,
                  total: dpiValueNew[value],
                };
                acc.fields.push(newField);
              } else {
                const timeFieldNotFound = time.getTime() - entry.time.getTime();
                if (dpiValueNew[value]) {
                  acc.map[value] = {
                    ...entry,
                    time,
                    total: oldMap[value].total + dpiValueNew[value],
                  };
                  const newField = {
                    name: value,
                    label: value,
                    color: entry.color,
                    value: dpiValueNew[value],
                  };
                  acc.fields.push(newField);
                } else if (
                  timeFieldNotFound <= TIMEWINDOW_MAX_MAP[timeWindow]
                ) {
                  acc.map[value] = entry;
                  const newField = {
                    name: value,
                    label: value,
                    color: entry.color,
                    value: 0,
                  };
                  acc.fields.push(newField);
                }
              }
              return acc;
            },
            { map: {}, fields: [] }
          );
          fields.sort();

          return { map: newFieldsMap, fields };
        });
      }
    }
  }, [itemsSpeed, itemsDPI]);

  const handleResize = useCallback(() => {
    if (
      refDPIGraph?.current?.clientWidth &&
      refDPIGraph?.current?.clientHeight &&
      refFacetGraph?.current?.clientWidth &&
      refFacetGraph?.current?.clientHeight
    ) {
      setChartSizes({
        wDPI: refDPIGraph.current.clientWidth,
        hDPI: refDPIGraph.current.clientHeight,
        wFacet: refFacetGraph?.current?.clientWidth,
        hFacet: refFacetGraph?.current?.clientHeight,
      });
    }
  }, [
    refDPIGraph?.current?.clientWidth,
    refDPIGraph?.current?.clientHeight,
    refFacetGraph?.current?.clientHeight,
    refFacetGraph?.current?.clientWidth,
  ]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      let timeoutId;
      if (document.visibilityState === "hidden") {
        timeoutId = setTimeout(() => {
          if (document.visibilityState === "hidden") {
            setLiveMode(liveMode);
          }
        }, 60000);
      } else {
        clearTimeout(timeoutId);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setLiveMode, liveMode]);

  useEffect(() => {
    handleResize();
  }, [
    refDPIGraph?.current?.clientWidth,
    refDPIGraph?.current?.clientHeight,
    refFacetGraph?.current?.clientHeight,
    refFacetGraph?.current?.clientWidth,
  ]);

  const handleMouseEventDPI = (event) => {
    if (event) {
      const { mouseX, mouseY } = event;
      const wDPI =
        chartSizes.wDPI - MARGIN_DPI_CHART.left - MARGIN_DPI_CHART.right;
      const hDPI =
        chartSizes.hDPI - MARGIN_DPI_CHART.top - MARGIN_DPI_CHART.bottom;
      const wFacet =
        chartSizes.wFacet - MARGIN_FACET_CHART.left - MARGIN_FACET_CHART.right;
      const hFacet =
        chartSizes.hFacet - MARGIN_FACET_CHART.top - MARGIN_FACET_CHART.bottom;

      const newMouseX = (wFacet / wDPI) * mouseX;
      const newMouseY = (hFacet / hDPI) * mouseY;
      setMouseEvent({ ...event, mouseX: newMouseX, mouseY: newMouseY });
    } else {
      setMouseEvent(null);
    }
  };

  const handleSelectTimeWindowChange = (event)=> {
    const timeWindowNew = parseInt(event.target.value)
    setTimeWindow(timeWindowNew);
  }

  return (
    <>
      <div className="card">
        <div className="body padding-1">
          <ControlsContainer className="header-with-controls">
            <div className="subtitle-controls">Current Metrics</div>
            <ControlsLiveMode className="subscriber-controls">
              <LiveModeSwitch
                defaultChecked={liveMode}
                value={liveMode}
                className="sw-input margin-b-0 live-mode-switch"
                name="live"
                label="Live mode"
                onChange={(e) => setLiveMode(e.target.checked)}
              />
              <SelectInputWithIcon
                className="subscriber-controls-time"
                title="Time Span"
                name="Time Span"
                icon="crop_16_9"
                selected={timeWindow}
                onChange={handleSelectTimeWindowChange}
                options={_timeWindowChoices}
              />
            </ControlsLiveMode>
            {isLiveMode && timeOffset !== undefined && (
              <LayoutToggleContainer>
                <IconsToggle
                  label="Layout"
                  options={[
                    { icon: "unfold_less", value: 4 },
                    { icon: "unfold_more", value: 2 },
                  ]}
                  onChange={(columns) => setColumns(columns)}
                  value={columns}
                />
              </LayoutToggleContainer>
            )}
          </ControlsContainer>
          {!isLiveMode && (
            <LiveMetricsDials target={target} setLiveMode={setLiveMode} />
          )}
          {isLiveMode && timeOffset !== undefined && (
            <>
              <ArrangeInColumns
                columns={columns}
                gap="10px"
                minWidth="350px"
                className={`margin-l-30 margin-r-30 live-metrics-container ${
                  isLiveMode ? "is-live-mode" : ""
                }`}
              >
                <FacetGraphLiveContainer>
                  <FacetGraphLive
                    title="Current Speed"
                    units="Mbps"
                    fields={speedFields}
                    value={speedValue}
                    yTooltipFormat={d3.format(".3f")}
                    yAxisTicksNumber={3}
                    resetSignal={resetSignal}
                    margin={MARGIN_FACET_CHART}
                    onMouseEvent={(e) => setMouseEvent(e)}
                    mouseEvent={mouseEvent}
                    timeOffset={timeOffset}
                    timeWindow={timeWindow}
                    ref={refFacetGraph}
                  />
                </FacetGraphLiveContainer>
                <FacetGraphLiveContainer>
                  <FacetGraphLive
                    title="Latency"
                    units="ms"
                    fields={latencyFields}
                    value={latencyValue}
                    yTooltipFormat={d3.format(".2f")}
                    yAxisTicksNumber={3}
                    resetSignal={resetSignal}
                    margin={MARGIN_FACET_CHART}
                    onMouseEvent={(e) => setMouseEvent(e)}
                    mouseEvent={mouseEvent}
                    timeOffset={timeOffset}
                    timeWindow={timeWindow}
                  />
                </FacetGraphLiveContainer>
                <FacetGraphLiveContainer>
                  <FacetGraphLive
                    title="Packet Retransmissions"
                    units="%"
                    fields={rtxFields}
                    value={rtxValue}
                    yTooltipFormat={d3.format(".2f")}
                    yAxisTicksNumber={3}
                    resetSignal={resetSignal}
                    yAxisBottomGap={0}
                    margin={MARGIN_FACET_CHART}
                    onMouseEvent={(e) => setMouseEvent(e)}
                    mouseEvent={mouseEvent}
                    timeOffset={timeOffset}
                    timeWindow={timeWindow}
                  />
                </FacetGraphLiveContainer>
                <FacetGraphLiveContainer>
                  <FacetGraphLive
                    title="Flows"
                    units="Flows"
                    fields={flowsFields}
                    value={flowsValue}
                    yTooltipFormat={d3.format(".0f")}
                    yAxisTicksNumber={3}
                    resetSignal={resetSignal}
                    margin={MARGIN_FACET_CHART}
                    onMouseEvent={(e) => setMouseEvent(e)}
                    mouseEvent={mouseEvent}
                    timeOffset={timeOffset}
                    timeWindow={timeWindow}
                  />
                </FacetGraphLiveContainer>
              </ArrangeInColumns>
              {profile.dpiEnabled === true ? (
                <WideContainer>
                  <DPIAnalyticsLive
                    title="DPI Service breakdown over time"
                    resetSignal={resetSignal}
                    value={dpiValue}
                    direction={direction}
                    setDirection={setDirection}
                    fields={dpiFields?.fields}
                    fieldsMap={dpiFields?.map}
                    setResetSignal={setResetSignal}
                    timeOffset={timeOffset}
                    timeWindow={timeWindow}
                    onMouseEvent={handleMouseEventDPI}
                    mouseEvent={mouseEvent}
                    ref={refDPIGraph}
                    margin={MARGIN_DPI_CHART}
                    chartSizes={chartSizes}
                  />
                </WideContainer>
              ) : (
                <EmptyContainer />
              )}
            </>
          )}
        </div>
      </div>
      {liveMode && itemsDPITable ? (
        <div className="card">
          <div className="body">
            <WideContainer className="live-subscriberflows">
              <HeaderDiv className="header">
                <h4 className="title subtitle margin-0 dpi-table-header">
                  Active Flows
                </h4>
              </HeaderDiv>
              <SubscriberFlowsResult
                liveMode={liveMode}
                setLiveMode={setLiveMode}
                response={itemsDPITable}
                pageLength={15}
                dpiFields={dpiFields.map}
                target={target}
                dpiEnabled={dpiEnabled}
              />
            </WideContainer>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LiveMetrics;
