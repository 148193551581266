import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(() => {
    return actions.recv("open-help-subscriber-groups-edit", function () {
      setOpen(true);
    });
  }, []);

  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }
  return (
    <Modal
      title="Edit Subscriber Group"
      onClose={doClose}
      content={() => (
          <div>
            <p>Edit subscriber groups. Edit the set of IP Addresses/Ranges, Subscriber IDs and VLAN IDs that belong to this group.</p>
            <ul>
              <li><b>TYPE</b>: One of IP-ADDRESS, IP-ADDR-RANGE, SUBSCRIBER-ID, VLAN-ID.</li>
              <li><b>VALUE</b>: IP address/range/subscriber ID/VLAN ID included in the group.</li>
              <li><b>ADDRESS</b>: IP addresses included in the group.</li>
              <li><b>ACTIVE</b>: Indicates whether the IP address is active or not.</li>
            </ul>
            <p>Click on the <i class="material-icons valign-icon">delete</i> icon to delete the corresponding IP address/range/subscriber ID/VLAN ID.</p>
            <p>Press the <b>Add IP Address/Range...</b> button to add a new IP address to the group.</p>
            <p>Press the <b>Add Subscriber ID...</b> button to add a new subscriber ID to the group.</p>
            <p>Press the <b>Add VLAN ID...</b> button to add a new VLAN ID to the group.</p>
            <p>Click on the <i class="material-icons valign-icon">more_vert</i> icon to do the following actions:</p>
            <ul>
              <li><b>Add IP Address/Range</b>: Add a new IP address to the group.</li>
              <li><b>Merge IP Adresses from File</b>: Add new IP addresses from a file.</li>
              <li><b>Replace IP Adresses with File</b>: Remove existing IP addresses and replace them with those in the file. Note: Existing Subscriber IDs will be removed.</li>
              <li><b>Add Subscriber ID</b>: Add a new Subscriber ID to the group.</li>
              <li><b>Merge Subscriber IDs from File</b>: Add new Subscriber IDs from a file.</li>
              <li><b>Replace Subscriber IDs with File</b>: Remove existing Subscriber IDs and replace them with those in the file. Note: Existing IP Addresses will be removed.</li>
              <li><b>Add VLAN ID...</b>: Add a new VLAN to the group.</li>
            </ul>
            <p>Format of the file: <strong>.txt</strong>. It must contain IP addresses/Subscriber IDs in new lines with no headers. </p>
            <p><strong>Example:</strong><br></br> 10.10.0.70 <br></br> 10.10.0.71 </p>
          </div>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
