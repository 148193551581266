/*global DPI_STATS_TMOUT_MS */
import React, { useContext } from "react";
import styled from "styled-components";
import Report from "views/Statistics/Report/Hourly/component";
import ActionsContext from "common/ActionsContext";
import DPIHourlyVolumeHelpModal from "./Help";
import CategoryDetailsModal from "../CategoryDetails";
import {
  expressTarget,
} from "common/api";
import { parseShareInSpeed } from "../api";
import * as d3 from "d3";
import useProfile from "common/hooks/useProfile";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;
const doSpeedShareRetrieval = (
  target,
  hours = 72,
  categories = 20,
  directions = "all",
  filter = "all"
) =>
  expressTarget( target ).then( expressedTarget =>
    ifCl.run(
      `show statistics dpi speed ${expressedTarget} hours ${hours} interval 60 categories ${
        categories - 1
      } direction ${directions} interval-speed 5 ${filter === "auto" ? "udrs-auto" : ""}`,
      { timeout: DPI_STATS_TMOUT_MS }
    )
  );

const retrieval = ({ target, hours, categories, directions, filter }) =>
  doSpeedShareRetrieval(target, hours, categories, directions, filter)
    .then(parseShareInSpeed)
    .then((speeds) => ({ speeds }))
    .catch((error) => console.error(error) || error);

const mbToGb = ({ total, dpi }) => ({
  total: (total / 1000).toFixed(2),
  dpi: (dpi / 1000).toFixed(2),
});

const sumTotalAndDPI = (items) =>
  items.reduce(
    (prev, { total = 0, dpi = 0 }) => ({
      total: prev.total + total,
      dpi: prev.dpi + dpi,
    }),
    { total: 0, dpi: 0 }
  );

export const TotalVolumeVsDPI = ({ speeds }) => (
  <TotalVolumeVsDPIResult {...mbToGb(sumTotalAndDPI(speeds))} />
);

const formatOnlyDecimals = d3.format(",.2f");

const formatDecimals = (value) =>
  value === undefined ? "--" : formatOnlyDecimals(value);

const TotalVolumeVsDPIResult = ({ total, dpi }) => {
  return (
    <h4 className="align-left">
      Total volume: {formatDecimals(total)} GB - DPI sample:{" "}
      {formatDecimals(dpi)} GB
    </h4>
  );
};

const enabledFeatures = (dpiPerUserEnabled) => ({
  policyType: false,
  hours: true,
  directions: true,
  categories: true,
  filter: true,
  target: dpiPerUserEnabled === true,
  output: false,
});

const DPIHourlyVolume = () => {
  const profile = useProfile() || {};
  const areClientsInspectable = profile.dpiEnabled === true;
  const actions = useContext(ActionsContext);
  const openCategoryDetail = (category, params) =>
    category.name !== 'REST' &&
    actions.send("open-category", {
      category,
      params,
    });
  return (
    <div id="viewDPIHourlyVolume" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">DPI hourly statistics</h2>
          <ContextMenu/>
          <small><code>right-click</code> in the area for a DPI category inside the chart or in the labels for each category above the chart</small>
        </HeaderDiv>
        <div className="body flex-panel-column">
          <Report
            graphTitle={null}
            provider={retrieval}
            onSeriesInspect={openCategoryDetail}
            enabled={enabledFeatures(areClientsInspectable)}
            actions={actions}
            Totals={TotalVolumeVsDPI}
          />
        </div>
        <DPIHourlyVolumeHelpModal />
        <CategoryDetailsModal sourceView="viewDPIHourlyVolume" />
      </div>
    </div>
  );
};

export default DPIHourlyVolume;
