import React, { useRef, useState, useEffect } from "react";
import Canvas from "./Canvas";
import * as d3 from "d3";
import styled, { css } from "styled-components";
import Axis, { remaining } from "./Axis";

const StyledG = styled.g`
  & line,
  & text,
  & path {
  }
`;

const _DEFAULT_MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

const LabelAxis = ({
  color = "#333",
  from,
  place = "bottom",
  axis,
  dimensions,
  margin = _DEFAULT_MARGIN,
}) => {
  const label = useRef(null);
  useEffect(() => {
    const { width, height } = remaining(dimensions, margin);
    const { scale } = axis[from];
    const g = d3.select(label.current);
    g.selectAll("*").remove();
    if (place === "bottom") {
      g.attr("transform", `translate(${margin.left}, ${height + margin.top})`);
      g.call(d3.axisBottom(scale)); // Create an axis component with d3.axisBottom
    } else if (place === "left") {
      g.attr("transform", `translate(${margin.left}, ${margin.top})`);
      g.call(d3.axisLeft(scale)); // Create an axis component with d3.axisLeft
    } else if (place === "right") {
      g.attr("transform", `translate(${margin.right + width}, ${margin.top})`);
      g.call(d3.axisRight(scale)); // Create an axis component with d3.axisLeft
    }
  }, [label, axis]);

  return (
    <StyledG className={`axis ${from}`} color={color} ref={label}></StyledG>
  );
};

export default LabelAxis;
