import { Column } from "common/DataTable";

const CreatePolicyButton = ({ onClick=doesNothing }) => (
  <button
    type="button"
    className="btn btn-default waves-effect shadow-none"
    onClick={onClick}
  >
    <i className="material-icons">add_circle</i>
    <span>Add Policy...</span>
  </button>
);

export const ActionsRow = ({doCreatePolicy=doesNothing}) =>
  <div className="row margin-t-5 margin-b-30 margin-l-0">
    <CreatePolicyButton onClick={doCreatePolicy} />
  </div>


const showNA = () => 'n/a';

const asCensoredColumn = ({label, idx, colClassName, cellClassName}) =>
  Column.Value(showNA, {label, idx, colClassName, cellClassName})

const applyCensorship = (censorLabels) => column =>
  censorLabels.has(column.label) ? asCensoredColumn(column) : column;

export const someColumnsMayBeCensored = (targetSet=null) =>
  targetSet === null || targetSet.size === 0 ? (...columns) => columns :
    (...columns) => columns.map(applyCensorship(targetSet)); 
