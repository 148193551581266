import React, { useEffect, useReducer } from "react";
const doesNothing = () => {};
import CreateTrap from "./Create";
import ListTraps from "./List";
import { validateIPv4 } from "common/api";

/*****************************************************
 * Trap : some code ahead is crap.
 * ****************************************************/
const enumerate =
  (start = 1, extraParams) =>
  (item, index) => ({ ...item, __id: start + index, ...extraParams });

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;

function trapsReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.trap,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...trap }) =>
          __id !== action.id
            ? [{ __id, stored, ...trap }]
            : stored === true
            ? [{ __id, stored, ...trap, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const validates = (candidate, traps = [], communities = []) => {
  const { nms, community, type } = candidate;
  if (nms.length === 0) {
    throw "NMS can not be empty.";
  } else {
    if (validateIPv4(nms) === false) {
      throw "NMS not is a valid IPv4.";
    }
  }
  if (type.length === 0) {
    throw "Select a trap type option.";
  } else {
    if (
      traps.some(
        (trap) =>
          trap.deleted !== true && trap.nms === nms && trap.type === type
      )
    ) {
      throw "Entry with the same IP address and type already exists.";
    }
  }
  if (communities.find(({ name }) => community === name) === undefined) {
    throw `No community found with name: ${community}`;
  }
};

const Traps = ({ initial = [], onChange = doesNothing, communities = [] }) => {
  const [traps, dispatch] = useReducer(trapsReducer, {
    changed: false,
    list: [...initial].map(enumerate(1, { stored: true })),
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createTrap = (trap) => {
    dispatch({
      type: "added",
      trap,
    });
  };
  useEffect(() => {
    traps.changed === true && onChange(traps.list);
  }, [traps]);

  const doValidate = (trap) => validates(trap, traps.list, communities);

  return (
    <>
      <ListTraps items={traps.list} onDelete={handleDeleteId} />
      <CreateTrap doValidate={doValidate} doSave={createTrap} />
    </>
  );
};

export default Traps;
