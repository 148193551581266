import React, { useRef, useEffect, useState, useMemo, memo } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { getLineData } from "./utils";

const RegressionLine = ({ xScale, yScale, items, width }) => {
  const lineData = getLineData(items);
  if (lineData === null) {
    return null;
  }

  const lineBuilder = d3
    .line()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y))
    .curve(d3.curveBundle.beta(0.5)); // https://d3js.org/d3-shape/curve

  const linePlotPath = lineBuilder(lineData);
  const linePlot = (
    <path d={linePlotPath} stroke="#fc3136" fill="none" strokeWidth={2} />
  );

  return <g className="scatterplotLine">{linePlot}</g>;
};

const MemoRegressionLine = memo(RegressionLine, (_prev, _next) => {
  const { items, width } = _prev;
  const { items: itemsNext, width: widthNext } = _next;
  if (
    width !== widthNext ||
    JSON.stringify(items) !== JSON.stringify(itemsNext)
  ) {
    return false;
  }
  return true;
});

export default MemoRegressionLine;
