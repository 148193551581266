import * as d3 from "d3";
import { parseSystemDate } from "common/api";
import {
  PERIOD_TO_LINES,
} from "common/constants";
import { parseDate } from "../utils";

export function parseMemSystemTotal(response) {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const attrs = rows[0].split(/\s+/);
  return Number(attrs[1]) / 1024;
}

export function parseMemUsagePoolTotal(response) {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const total = rows.reduce((acc, value) => {
    const attrs = value.split(/\s+/);
    if (attrs[0] == "") attrs.shift();
    acc += Number(attrs[2]);
    return acc;
  }, 0);
  return total / 1024;
}

export function parseMemUsageDpdkTotal(response) {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  return rows.map((row) => {
    const attrs = row.split(/\s+/);
    if (attrs[0] == "") attrs.shift();
    if (attrs[2] == "n/a") {
      return 0.0;
    } else {
      return Number(attrs[2]);
    }
  });
}

function parserUserItemF(it, i, totals) {
  return (it * 100) / totals[i - 1];
}

export const parseStatsMemData = (response, period, totals) => {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const [, ...keys] = header.split(/\s+/);
  const keysSeries = keys.map((key) => {
    const chunks = key.split("-").map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });
    chunks.pop();
    return chunks.join("-");
  });

  const newKeys = ["time", ...keysSeries];
  const { System, Mpool, Dpdk } = totals;

  if (rows.length === 0) {
    return { fields: keysSeries, items: [] };
  }

  const newRows = rows.map((row) => {
    return row.split(/\s+/).reduce((acc, value, index) => {
      if (index === 0) {
        acc[newKeys[index]] = parseDate(value);
      } else {
        acc[newKeys[index]] = parserUserItemF(value, index, [
          System,
          Mpool,
          ...Dpdk,
        ]);
      }
      return acc;
    }, {});
  });

  return { fields: keysSeries, items: newRows };
};

export function getMemoryStatsLines(lines = 288) {
  return ifCl.run(`show statistics memory lines ${lines}`);
}

export const memoryUsageSystemDateRetrieval = () =>
  Promise.all([
    ifCl.run("show system memory").then(parseMemSystemTotal),
    ifCl.run("show pkteng memory").then(parseMemUsagePoolTotal),
    ifCl.run("show pkteng memory dpdk").then(parseMemUsageDpdkTotal),
    ifCl.run("show system date").then(parseSystemDate)
  ]);
