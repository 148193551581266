const splitColumns = (line) => line.split(/\s+/);

const buildFlowsObject = (fields, values) =>
  Object.fromEntries(
    fields.map((field, position) => [
      field.toLowerCase(),
      position > 0 ? parseInt(values[position]) : values[position],
    ])
  );

const sumActiveRecords = (total, { active }) => total + active;

export const parseTotalFlows = (response) => {
  const [head, ...lines] = response.trim("m").split("\n");
  if (lines === undefined) {
    return 0;
  }
  const fields = splitColumns(head);
  return lines
    .map((line) => buildFlowsObject(fields, splitColumns(line)))
    .reduce(sumActiveRecords, 0);
};
