/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { mayExpandLinearScale } from "common/graphs/NVLineChart";
import Loading from "../../../../common/Loading";

const WrapperDIV = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  .row {
    margin: 0 !important;
    position: absolute;
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > svg {
    width: 100%;
    height: 100%;
    min-height: 500px;
   
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    .nv-background rect {
      pointer-events: all;
    }
`;
const givenExtent = (min = null, max = null) => ({
  annotate: (value) => {
    if (value === null) {
      return value;
    }

    min = min === null || value < min ? value : min;
    max = max === null || value > max ? value : max;
    return value;
  },
  range: () => [min, max],
});

const splitByFields = ([xField, ...fields], items, range = [0, null]) => {
  const extent = givenExtent(...range);
  const lanes = fields.map(({ name, label, color = undefined, area }) => ({
    key: label,
    values: items.map((item) => [item[xField], extent.annotate(item[name])]),
    ...(color === undefined ? {} : { color }),
    area,
  }));
  return [lanes, extent.range()];
};

const Chart = ({
  margin = { top: 20, right: 40, bottom: 30, left: 60 },
  items,
  fields,
  fixedYRange,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const [loading, setLoading] = useState(false);
  let chart = null;
  let _initialDimmensions = null;
  let resizeObserver = null;
  const xField = "time";
  const tooltipGen = tooltipContentGeneratorItems();

  const render = (target) => {
    if (items.length > 0) {
      setLoading(true);
    }
    nv.addGraph(function () {
      if (chart === null) {
        chart = nv.models
          .lineChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .duration(0)
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .noData(NO_DATA_AVAILABLE)
          .duration(0)
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: 0.05,
            })
          )
          .xScale(d3.scaleTime())
          .showLegend(false);
        chart.interactiveLayer.tooltip.enabled(true);
        chart.interactiveLayer.tooltip.hideDelay(0);
        chart.interactiveLayer.tooltip.gravity("w");
        chart.xAxis.tickFormat(d3.timeFormat("%m/%d %H:%M")).ticks(8);
        chart.yAxis.axisLabel("%");
        chart.yAxis.ticks(6);
        chart.forceY(fixedYRange);
        chart.dispatch.on('renderEnd', () => {
          setLoading(false);
        })
        chart.interactiveLayer.tooltip.contentGenerator(tooltipGen);
      }
      const [datum, [yMin, yMax]] = splitByFields([xField, ...fields], items, [
        null,
        0,
      ]);
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.yAxis.domain();
      d3.select(canvas.current).call(chart);
      if (resizeObserver === null) {
        resizeObserver = new ResizeObserver(() => chart.update());
        if(wrapper.current){
          resizeObserver.observe(wrapper.current);
        }
      }
      
      d3.select(canvas.current).select(".nv-background rect").attr("rx", 9);

      const main = d3.select(canvas.current);

      return chart;
    });
  };
  useEffect(() => {
    const target = wrapper.current;
    if (items) {
      return render(target);
    } else {
      () => {};
    }
    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  }, [items, fields]);

  return (
    <WrapperDIV ref={wrapper}>
      {loading ? <Loading /> : null}
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

export default Chart;

function tooltipContentGeneratorItems() {
  return (d) => {
    const notEmptySeries = d.series.filter(serie=>serie.value!==undefined && serie.value!==null) || [];
    const ROW_MAX = 20;
    let total = 0;
    let newSeries;
    const averageValue = (notEmptySeries.reduce((acc, series)=> {
      return acc+ series.value;
    }, 0))/notEmptySeries.length;
    const averageValueDisplay = notEmptySeries.length > 0 ? `<div class="date">Average value: ${d3.format(".1f")(averageValue)} %</div>`: "";

    const date = d3.timeFormat("%Y-%m-%d  %H:%M:%S")(new Date(d.value));
    let html = "";

    if (notEmptySeries.length > ROW_MAX) {
      const sorttedSeries = notEmptySeries.sort((a, b) => {
        return b.value - a.value;
      });
      newSeries = sorttedSeries.slice(0, ROW_MAX);
      html += `<div class="SysStatsCPU-info"><div class="date">${date}</div></div><div class="SysStatsCPU-info"><div class="total"> Top ${ROW_MAX} / Total ${
        notEmptySeries.length - 1
      }  CPU's</div>${averageValueDisplay}</div>`;
    } else {
      newSeries = notEmptySeries;
      html += `<div class="SysStatsCPU-info"><div class="date">${date}</div>${averageValueDisplay}</div>`;
    }
    html += `<div class="SysStatsCPU-tooltip">`;
    
    let value,
      rowIdx,
      rowCnt = newSeries.length,
      row = [];

    for (var i = 0; i < rowCnt; i++) {
      row[i] = '<div class="SysStatsCPU-tooltip-row">';
    }

    for (var i = 0; i < rowCnt; i++) {
      value = newSeries[i].value;
      if (isNaN(value) || value === null) continue;
      rowIdx = i % ROW_MAX;
      row[rowIdx] +=
        '<div class="legend-color-guide"><div style="background-color:' +
        newSeries[i].color +
        ';"></div></div>';
      row[rowIdx] +=
        '<div class="legend-name">' +
        newSeries[i].key +
        '</div><div><b>' +
        " " +
        d3.format(".1f")(value) +
        '%' +
        "</b></div>";
    }

    for (var i = 0; i < rowCnt; i++) {
      row[i] += "</div>";
      html += row[i];
    }
    html += "</div>";

    return html;
  };
}