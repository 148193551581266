import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import { parseLinkStateResponse, parseLinkStateDeviceResponse } from "./api";
import styled from "styled-components";

const emptyInformation = { link: [], device: [] };

const requestLinkInformation = (name) =>
  ifCl.run(`show interface ${name}`).then(parseLinkStateResponse);

const requestDevicenformation = (name) =>
  ifCl.run(`show interface ${name} device`).then(parseLinkStateDeviceResponse);

const doLoad = ({ name, type = "loopback" }) =>
  type === "loopback"
    ? Promise.resolve(emptyInformation)
    : Promise.all([
        requestLinkInformation(name),
        requestDevicenformation(name),
      ]).then(([link, device]) => ({ link, device }));

const higherLabelLength = (max, { label = "" }) =>
  max > label.length ? max : label.length;

const classForFact = (label, value) =>
  label === "rx_bytes"
    ? "first-row"
    : label === "Failures since last sync" && parseInt(value) > 0
    ? "warning"
    : "";

const StatusResultLayout = styled.div`
  padding: 0 15px;
  .fact {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    color: #777;
    .field {
      text-align: left;
      max-width: 25ch;
    }
    .value {
      text-align: left;
      font-family: "AllianceNo2-SemiBold";
      font-weight: 100;
    }
    .field,
    .value {
      font-size: 1em;
      flex: 3ch 1 0;
    }
    &.warning {
      color: red;
    }
    &.first-row {
      grid-column: 1;
    }
  }
`;
const StatusResult = ({
  facts = [],
  className = "",
  factClassName = "",
  columns = 2,
}) => {
  return (
    <StatusResultLayout
      className={className}
      maxLabelChars={1 + facts.reduce(higherLabelLength, 0)}
    >
      <ColumnsGrid
        columns={columns}
        maxWidth="15rem"
        gap="2.5px"
        minWidth="6cm"
        rowGap="0"
      >
        {facts.map(({ label, value }) => (
          <div
            className={`fact ${factClassName} ${classForFact(label, value)}`}
            key={label}
          >
            <span className="field">{label}:</span>
            <span className="value">{value}</span>
          </div>
        ))}
      </ColumnsGrid>
    </StatusResultLayout>
  );
};

const LinkStateDetailModal = () => {
  const [open, setOpen] = useState(false);
  const [information, setInformation] = useState(emptyInformation);
  const actions = useContext(ActionsContext);
  const doOpen = () => setOpen(true);
  useEffect(
    () =>
      actions.recv("show-interface-details", (params) => {
        doLoad(params).then(setInformation).then(doOpen);
      }),
    []
  );
  const doClose = () => setOpen(false);
  return open === true ? (
    <Modal
      title="Interface Details"
      id="interface-status-details"
      large={true}
      onClose={doClose}
      content={() =>
        console.warn("show content", information) || (
          <>
            <StatusResult facts={information.link} className="row" />
            <StatusResult
              facts={information.device}
              columns={1}
              className="row margin-t-20"
              factClassName="first-row"
            />
          </>
        )
      }
    />
  ) : null;
};

export default LinkStateDetailModal;
