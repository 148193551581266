import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "common/Modal.jsx";
import NotForOperators from "common/NotForOperators";
import ActionsContext from "common/ActionsContext";

const doesNothing = () => {};

const AskProfileName = ({ name, onChange }) => (
  <div className="input-group">
    <span className="input-group-addon">&emsp;Profile name</span>
    <div className="form-line">
      <input
        defaultValue={name}
        onKeyUp={(event) => onChange(event.target.value)}
        type="text"
        className="form-control align-left"
      />
    </div>
  </div>
);

const CreateProfileModal = ({
  others = [],
  doSave = doesNothing,
  onClose = doesNothing,
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const doClose = () => setOpen(false);
  if (open === false) {
    return null;
  }
  const updateName = (name) => {
    if (name.length === 0) {
      setError("Profile name can not be empty.");
    } else if (others.includes(name)) {
      setError("Profile name already exists.");
    } else {
      setError(null);
    }
    setName(name);
  };
  const doApply = (_event) => {
    if (name.length > 0 && error === null) {
      doSave({ name, ranges: [] });
      doClose();
    }
  };
  return (
    <Modal
      title="Create profile"
      superIcon="add_circle"
      large={true}
      content={() => <AskProfileName name={name} onChange={updateName} />}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={name.length === 0 || error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

const CreateProfileButton = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-default waves-effect shadow-none"
    onClick={onClick}
  >
    <i className="material-icons">add_circle</i>
    <span>Add Profile...</span>
  </button>
);

const CreateProfile = ({ others = [], doSave = doesNothing }) => {
  const actions = useContext(ActionsContext);
  const [profileCreation, setProfileCreation] = useState(false);
  const showProfileCreation = () => setProfileCreation(true);
  const hideProfileCreation = () => setProfileCreation(false);
  useEffect(() => {
    actions.recv("new-profile", () => showProfileCreation());
  }, []);
  return (
    <React.Fragment>
      {profileCreation === true ? (
        <CreateProfileModal
          active={profileCreation}
          others={others}
          doSave={doSave}
          onClose={hideProfileCreation}
        />
      ) : null}
      <NotForOperators>
        <CreateProfileButton onClick={showProfileCreation} />
      </NotForOperators>
    </React.Fragment>
  );
};

export default CreateProfile;
