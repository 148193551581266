import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Request from "common/Request";
import NVPieChart from "common/graphs/NVPieChart";
import * as d3 from "d3";
import ReportContextControls from "../ReportContextControls";

const CONST = {
  UNITS: "GB",
  TOOLTIP_UNITS: "%",
  NON_APPLICABLE_VALUE: "n/a",
};

const formatDecimals = d3.format(",.2f");

const tooltipContentGenerator = ({
  targetTooltip,
  total = 0,
  valueIsShare = false,
}) => {
  return (d, i, { key }) => {
    let value = "N/A",
      share = "N/A";
    if (d !== null) {
      if (valueIsShare) {
        value = formatDecimals(total * d);
        share = formatDecimals(d * 100);
      } else {
        value = formatDecimals(d);
        share = formatDecimals((d / total) * 100);
      }
    }
    return (
      `${share} ${CONST.TOOLTIP_UNITS}  - ` +
      `${value} ${CONST.UNITS}` +
      (key === "REST" ? "" : `<br>${targetTooltip || ""}`)
    );
  };
};

const UsageGraphLayout = styled.div`
  .title-with-button {
    display: flex;
    flex-flow: row nowrap;
    & h4 {
      flex: 100% 1 1;
      margin: 0;
    }
    margin: .5em 0;
  }

  h4 {
    text-align: center;
    &.align-left {
      text-align: left;
    }
  }
  .align-right { float: right; }
`;
const ReportResult = ({ totals,  ...rest }) =>
  (<UsageGraph {...totals} {...rest} />);

const BigGraph = styled.div`
  & svg {
    min-height: 12cm;
  }
  @media print {
    width: 100% !important;
    overflow: hidden;
  }
`;

const NoTotals = () => null;

const valueFrom = (field) => (d) => d[field];

const UsageGraph = ({
  onCategoryClick,
  Totals = NoTotals,
  itemsFrom = "services",
  itemsValueFrom = "speed",
  itemsTotalFrom = "total",
  targetTooltip = "Click to see section details",
  valueIsShare = true,
  Export=null, context={},
  graphTitle = null,
  ...result
}) => {
  return (
    <UsageGraphLayout className="group margin-t-20">
      <div className="title-with-button">
        {graphTitle === null ? null : <h4>{graphTitle}</h4>}
        {Export === null ? null : <Export className="align-right" context={context} />}
      </div>
      <BigGraph className="big graph">
        <NVPieChart
          items={result[itemsFrom]}
          xFrom={(d) => d.name}
          yFrom={valueFrom(itemsValueFrom)}
          yTooltipFormat={tooltipContentGenerator({
            targetTooltip,
            total: result[itemsTotalFrom],
            valueIsShare,
          })}
          yAxisUnits="Mbps"
          disabledElements={["REST"]}
          onClick={onCategoryClick}
        />
      </BigGraph>
      <Totals {...result} />
    </UsageGraphLayout>
  );
};

const ReportRequest = ({
  actions,
  hours,
  directions,
  categories,
  filter,
  output,
  target,
  retrieval,
  ...rest
}) => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    const doingRetrieval = retrieval({
      hours,
      directions,
      categories,
      filter,
      output,
      target,
    });
    setRequest(doingRetrieval);
  };
  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [hours, directions, categories, filter, output, target]);
  const openCategoryDetails = (category) =>
    actions.send("open-category", {
      category,
      params: { hours, directions, categories, target, output },
    });

  return request === null ? null : (
    <Request during={request}>
      {(result) => (
        <ReportResult
          onCategoryClick={openCategoryDetails}
          context={{hours, directions, categories, filter, output, target}}
          {...result}
          {...rest}
        />
      )}
    </Request>
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
  categories: 20,
  directions: "all",
  filter: "all",
  output: "ip-addresses",
  target: { subsId: null, addr: null },
};

const ReportContext = ({ provider, actions, enabled, ...rest }) => {
  const [context, setContext] = useState(_defaultContext);
  const updateContext = ({
    hours,
    directions,
    categories,
    filter,
    target,
    output,
  }) =>
    setContext((prev) => ({
      ...prev,
      ...(hours === undefined ? {} : { hours }),
      ...(directions === undefined ? {} : { directions }),
      ...(categories === undefined ? {} : { categories }),
      ...(filter === undefined ? {} : { filter }),
      ...(output === undefined ? {} : { output }),
      ...(target === undefined ? {} : { target }),
    }));

  return (
    <>
      <ReportContextControls
        enabled={enabled}
        {...context}
        onChange={updateContext}
      >
        <ReportRequest
          {...context}
          retrieval={provider}
          actions={actions}
          {...rest}
        />
      </ReportContextControls>
    </>
  );
};

export default ReportContext;
